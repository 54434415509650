import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import { LocalImages } from '../Constant';
import { useLocation } from 'react-router-dom';
import { getOrderDetails } from '../Redux/Actions/homeAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function OrderDetails(props) {
  const location = useLocation();
  const _id = location?.state?.orderId;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [orderDetails, setOrderDetails] = useState('');
  const [trackingIdDetails, setTrackingIdDetails] = useState('');
  const [remainingBookDetails, setRemainingBookDetails] = useState('');

  const [bannerImage, setBannerImage] = useState('');
  const [copied, setcopied] = useState(false);
  const { firstName, lastName } = useSelector((state) => ({
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName
  }));

  useEffect(() => {
    setLoader(true);
    dispatch(
      getOrderDetails(`${_id}`, (data) => {
        setOrderDetails(data?._data);
        setTrackingIdDetails(data?._data?.trackingIdDetails);
        setRemainingBookDetails(data?._data?.remainingBookDetails);
        setBannerImage(data?.imageUrl);
        setLoader(false);
      })
    );
  }, []);

 

  const handleOpenPDF = () => {
    // window.open(orderDetails?.invoicePdfUrl,'_blank')
    <a href={orderDetails?.invoicePdfUrl} class="colorwhite boldfont"></a>;
  };

  const handleOpen = () => {
    window.open(
      'https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx'
    );
  };
 console.log("ggdsgfgdfg",orderDetails);
  return (
    <>
      {orderDetails ? (
        <div className="rightPanel">
          <div className="d-flex justify-content-between ">
            <h5 className="">
              <b className="fw-bold boldfont">Order History:</b>{' '}
              <span className="grey70">#{orderDetails?.orderNo}</span>
            </h5>
            <div className="d-grid d-md-flex justify-content-center">
              {orderDetails?.invoicePdfUrl || orderDetails?.invoicePdfUrl2? (
                <button type="button" className="btn btn-primary py-2 px-4 font12 me-md-1">
                  <a href={orderDetails?.invoicePdfUrl ? orderDetails?.invoicePdfUrl : orderDetails?.invoicePdfUrl2} class="nav-link colorwhite boldfont">
                    Download Invoice
                    <ArrowForwardIosIcon className="colorwhite " fontSize="10px" />
                  </a>
                </button>
              ) : (
                ''
              )}
            </div>

            {/* <div className="d-grid d-md-flex justify-content-center">
            {orderDetails.status === 2 ?<button
                  type="button"
                  className="btn btn-primary py-2 px-4 font12 me-md-1"
                  onClick={handleOpenPDF}>
                  Download Invoice<ArrowForwardIosIcon className="colorwhite " fontSize='20px'/>
                </button>:''}
            </div> */}
          </div>

          <div className="row mt-4">
            {orderDetails?.courses?.map((item, index) => (
              <div key={index.toString()} className="col-12 col-md-6 col-lg-4 mb-2">
                <div className="card boxshadow p-2 h-100 border-0">
                  <div className="row ">
                    <div className="col-md-9">
                      {item?.booksList && item.ComboData?.sellType === 1 ? (
                        item?.booksList?.map((data, index) => (
                          <b className="font11">{data?.name}</b>
                        ))
                      ) : item.ComboData?.sellType === 2 || item.ComboData?.sellType === 3 ? (
                        <b className="font11">{item.ComboData.comboName}</b>
                      ) :item.sectionType===5?(<b className="font11">{item?.subPackageName}</b>) :(
                        <b className="font11">{item?.packageName}</b>
                      )}
                      <p className="font12 mb-0 mt-1 ">
                        Language: {item?.courseId?.language?.name}
                      </p>
                      {/* {item?.subPackageName ? (
                        <p className="font11 mb-0 mt-1">{item?.subPackageName}</p>
                      ) : null} */}
                    </div>
                    <div className="col-md-3 p-0 ">
                      <img
                        type="image/svg+xml"
                        className="img-fluid img-thumbnail border-0 h-100 "
                        src={ item?.courseId?.bannerImage}
                        alt='banner'
                        // src={bannerImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {orderDetails?.status === 2 && orderDetails?.hasStudyMaterial ? (
              <div className="col-md-4 mb-2">
                <div className="card boxshadow p-2 h-100">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between">
                        <b className="font12 colorblue">Order Tracking</b>
                        {orderDetails?.trackingStatus === 0 ? (
                          ''
                        ) : (
                          <div>
                            <button
                              type="button"
                              className="btn bg-green  text-center font12 btn-success btn-sm"
                              onClick={handleOpen}>
                              Track Order
                            </button>
                          </div>
                        )}
                      </div>

                      {trackingIdDetails?.map((c, index) => (
                        <>
                          {c?.trackingStatus === 1 || c?.trackingStatus === 2 ? (
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="font12 mb-0">
                                  Tracking ID {index + 1} :
                                  <span className="successcolor">#{c?.trackingId}</span>
                                  <CopyToClipboard
                                    text={c?.trackingId}
                                    onCopy={() => setcopied(true)}>
                                    <button className="btn btn-font font12">
                                      Copy ID{' '}
                                      <img className="ps-1 copyicon" src={LocalImages.CopyLink} alt='copy'/>
                                    </button>
                                  </CopyToClipboard>
                                </p>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      ))}

                      <div className=" d-flex justify-content-between">
                        <p className="font12 mb-0 ">Indian Post</p>
                        {orderDetails?.trackingStatus === 0 ? (
                          <p className="font12 mb-0">Not dispatched</p>
                        ) : (
                          ''
                        )}
                        {orderDetails?.trackingStatus === 1 ? (
                          <p className="font12 mb-0">Partial dispatched</p>
                        ) : (
                          ''
                        )}
                        {orderDetails?.trackingStatus === 2 ? (
                          <p className="font12 mb-0">Complete dispatched</p>
                        ) : (
                          ''
                        )}
                        {orderDetails?.trackingStatus === 3 ? (
                          <p className="font12 mb-0">Returned</p>
                        ) : (
                          ''
                        )}
                        {orderDetails?.trackingStatus === 4 ? (
                          <p className="font12 mb-0">delievered</p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {remainingBookDetails > 0 ? (
              <div className="col-md-4 mb-2">
                <div className="card boxshadow p-2 h-100 border-0">
                  <div className="col-md-12">
                    <b className="font12 colorblue">Remaining Books</b>
                  </div>
                  {remainingBookDetails?.map((c, index) => (
                    <div className="row">
                      <div className="col-md-9">
                        <b className="font11 ">{c?.name}</b>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
            {orderDetails?.status === 2 ? (
              <div className="col-md-4 mb-2">
                <div className="card boxshadow p-2 h-100 border-0">
                  <div className="row">
                    <div className="col-md-12">
                      <b className="font12 colorblue">Payment Information</b>
                      <p className="font12 mb-0">Payment Method</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="font12 mb-0">{orderDetails?.paymentInfo?.details}</p>
                      <p className="font12 mb-0">{orderDetails?.paymentInfo?.method}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
 
          <div className="row boxshadow mt-4 py-2 p-md-4 bgwhite">
            {orderDetails?.hasStudyMaterial ? (
              <div className="col-md-4 justify-content-right">
                <p className="font12 colorblue">
                  <b>Shipping Address</b>
                </p>
                <p className="font12">
                  <b>
                    {firstName} {lastName}
                  </b>
                </p>
                <p className="font12 mb-0">
                  {orderDetails?.address?.line1}, {orderDetails?.address?.line2},{' '}
                  {orderDetails?.address?.landmark},{' '}
                </p>
                <p className="font12 mb-0">
                  {orderDetails?.address?.city} {orderDetails?.address?.pincode}
                </p>

                {orderDetails?.address?.alternatePhoneNumber ? (
                  <p className="font12 mb-0">
                    Phone Number: +91-{orderDetails?.address?.alternatePhoneNumber}
                  </p>
                ) : null}
              </div>
            ) : null}
            <div className="col-md-4 justify-content-center">
              <p className="font12 colorblue">
                <b>Order Details</b>
              </p>
              <p className="font12 mb-0">{moment(orderDetails?.date).format('ddd  DD MMM YYYY')}</p>
              <p className="font12 mb-0">Order ID: {orderDetails?.orderNo}</p>

              {orderDetails?.status === 2 ? (
                <p className="font12 mb-0">Amount Paid:{orderDetails?.netPayableAmount}</p>
              ) : (
                <p className="font12 mb-0">Order status: Cancelled</p>
              )}
              <p className="font12 mb-0">{orderDetails?.courses?.length} items</p>
              {orderDetails?.installmentData ? (
                <>
                  <p className="font12 mb-0">
                    Remaining Amount :{orderDetails?.installmentData?.installment2}
                  </p>
                  <p className="font12 mb-0">
                    Due Date:- :
                    {moment(orderDetails?.installmentPayments?.dueDate).format('ddd  DD MMM YYYY')}
                  </p>
                </>
              ) : (
                ''
              )}

              {orderDetails?.remainingBookDetails && orderDetails?.trackingStatus === 1 ? (
                <p className="font12 mb-0">
                  Remaining Books :{' '}
                  {orderDetails?.remainingBookDetails?.map((book,index) => (
                    <span> {book?.name} {index==orderDetails?.remainingBookDetails.length-1?"":","}</span>
                  ))}
                </p>
              ) : (
                ''
              )}
              {orderDetails?.nextDispatchedDate && orderDetails?.trackingStatus === 1 ? (
                <p className="font12 mb-0">
                  {' '}
                  Next Dispatch Date :{' '}
                  {moment(orderDetails?.nextDispatchedDate).format('ddd  DD MMM YYYY')}
                </p>
              ) : (
                ''
              )}
            </div>
            <div className="col-md-4 justify-content-center">
              <p className="font12 colorblue">
                <b>Order Summary</b>
              </p>

              <div className="d-flex justify-content-between">
                <p className="font12 mb-0">Selling Price</p>
                <p className="font12 mb-0">{orderDetails?.totalPrice}</p>
              </div>

              <div className="d-flex justify-content-between">
                <p className="font12 mb-0">Packing & Delivery Fee</p>
                <p className="font12 mb-0">{orderDetails?.deliveryFee}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font12 mb-0">Coupon Discount </p>
                <p className="font12 mb-0 colorgreen">-{orderDetails?.totalDiscountAmount}</p>
              </div>
              {orderDetails?.installmentData ? (
                <div className="d-flex justify-content-between">
                  <p className="font12 mb-0">Additional Price </p>
                  <p className="font12 mb-0 ">{orderDetails?.installmentData?.additionalPrice}</p>
                </div>
              ) : (
                ''
              )}
              <div className="d-flex justify-content-between">
                <p className="font12 mb-0">Total Before Tax</p>
                <p className="font12 mb-0">
                  {orderDetails?.totalPrice +
                    orderDetails?.deliveryFee -
                    orderDetails?.totalDiscountAmount}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font12 mb-0">Total Taxable Amount</p>
                <p className="font12 mb-0">{orderDetails?.totalTaxableAmount}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font12 ">Tax Amount ({orderDetails?.taxPercentage}%)</p>
                <p className="font12">{orderDetails?.totalTaxAmount}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font12 mb-0">
                  Total Amount <span className="colorgreen">(Including all Taxes)</span>
                </p>

                <p className="font12 mb-0">{orderDetails?.netPayableAmount}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default OrderDetails;
