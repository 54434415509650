import React, { useState } from 'react';
import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Lottie from 'react-lottie';
import orderPending from '../Assets/Images/JSON/orderPending.json';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { deleteUser } from '../Redux/Actions/AuthAction';
import { showSuccessSnackbar } from '../Redux/Actions/snackbarAction';
function DeleteProfilePopup(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orderPending,
    renderer: 'svg'
  };
  
  const {
    firstName,
  } = useSelector((state) => ({
    firstName: state?.AuthReducer?.firstName,
    
  }));
  const deleteAccount=()=>{ 
   
    dispatch(
      deleteUser(
        "",
        (res) => {         
          dispatch(showSuccessSnackbar(res?.msg));
          navigate('../SignIn');
        },
        () => {}
      )
    );    
    
  }
 
  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={props.openPopup}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={props.openPopup}>
          <div className="modeldiv editprofEdit ">
            <div className="col d-flex mt-0 justify-content-between border-bottom">
              <h6 className="colorblue fw-bold">Delete profile</h6>
              <ClearOutlinedIcon className="colorblue cursor" onClick={props.handleClose} />
              
            </div>
            <div className='text-center py-5'>
              <p className='fw-bold' ><span className='text-primary'>{firstName}</span> you Are sure you want to delete account from <span className='text-primary'>IFAS </span></p>
              <p className='text-secondary'> you will permenently lose all your data, self-notes, and profile info. After this , there is no turning back </p>

            <button className='btn btn-outline-danger btn-sm' onClick={props.deleteAccount}><DeleteIcon fontSize='small'/>Confirm Deletion</button>
            </div>
       
           
          </div>
        </Fade>
      </Modal>
  );
}

export default DeleteProfilePopup;
