import React from 'react';
import '../App.css';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

function PremiervedioEndedPopUp(props) {
  const backToHome = () => {
    opener.location.reload();
    window.close();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      //onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="modeldiv p-4 py-5 talktoexpertModel">
          <div>
            {/* <div className="col mb-2 d-flex justify-content-between ">
              <h5 className="colorblue"></h5>
              <ClearOutlinedIcon className="colorblue cursor" onClick={props.handleClose} />
            </div> */}
            <div>
              <h4 className="ms-3 colorblue fw-bold text-center">Premier Lecture Ended</h4>
              <p className="ms-3 mt-3 text-center">
                Your Premier Lecture has been end please click on the below button to redirect to
                home
              </p>
            </div>
            <div className="d-flex justify-content-evenly mt-5">
              <button type="button" className="btn btn-primary px-5" onClick={backToHome}>
                Back
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default PremiervedioEndedPopUp;
