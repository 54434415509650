import React, { useState, useEffect } from 'react';
import '../Assets/CSS/home.css';
import PhoneInput from '../Component/phoneInput';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import appAchievementvideo from '../Assets/Video/IFAS-Achievements.mp4';
import trustdbyStudentvideo from '../Assets/Video/Trusted-By-Students.mp4';
import Footer from '../Component/footer';
import ClassroomInPocket from '../Component/classroomInPocket';
import { LocalImages } from '../Constant';
import Lottie from 'react-lottie';
import Expert from '../Assets/Images/JSON/expert.json';
import TalktoExpert from '../Module/talktoExpert';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  getCountry,
  getExamList,
  setInitialState,
  setParentCourse
} from '../Redux/Actions/AuthAction';
import { useNavigate } from 'react-router-dom';
import HeaderHome from '../Module/headerHome';
import PredictionPopup from '../Module/predictionPopup';

const Home = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const [openPrediction, setopenPrediction] = useState(false);
  const [isHidden, setisHidden] = useState(true);
  const [examlist, setExamList] = useState([]);
  const [number, setNumber] = useState('');
  const [countryCode, setCountryCode] = useState({
    _id: '',
    name: '',
    callingCode: '',
    flag: ''
  });
  const [countryData, setCountryData] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Expert,
    renderer: 'svg'
  };

  const handleOnChangePhoneText = (prevPhoneInputText) => {
    setNumber(prevPhoneInputText.target.value);
  };

  useEffect(() => {
    // document.title = props.title;
    (async () => {
      await dispatch(setInitialState());
      await dispatch(
        getCountry(
          (data) => {
            setCountryData(data);
            const filteredCountries = data.filter((item) => {
              const countryCodeNew = item.callingCode.toLowerCase();
              return countryCodeNew.indexOf('+91') > -1;
            });
            setCountryCode(filteredCountries[0]);
            setNumber('');
          },
          () => {}
        )
      );
      await dispatch(setParentCourse('examListNew', []));
      await dispatch(
        getExamList((data) => {
          const newData = [
            ...data,
            {
              name: 'ACT',
              _id: 'lurnigo',
              image: LocalImages.act
            },
            {
              name: 'SAT',
              _id: 'lurnigo',
              image: LocalImages.sat
            }
          ];
          setExamList(newData);
        })
      );
    })();
  }, []);

  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    if (window.innerWidth >= 768) {
      var y = window.scrollY;
      if (y >= 300) {
        setisHidden(false);
      } else {
        setisHidden(true);
      }
    } else {
      setisHidden(false);
    }
  };
  window.addEventListener('scroll', myScrollFunc);

  const signInroute = () => navigate('/SignIn', { state: { IsclassRoom: false } });

  const handleClose = () => setopen(false);

  const handleClick = (event) => setopen(true);

  const boostYour = [
    {
      name: 'Online Class',
      path: LocalImages.onlineClassImg
    },
    {
      name: 'Classroom Coaching',
      path: LocalImages.classroomImg
    },
    {
      name: 'Study Materials',
      path: LocalImages.studymaterialImg
    },
    {
      name: 'Online Test Series',
      path: LocalImages.testseriesImg
    }
  ];

  const topperList = [
    {
      Title: 'CSIR NET 2022 TOPPERS',
      students: [
        {
          name: 'Ramika Singla',
          path: LocalImages.ramikaSingla
        },
        {
          name: 'Ajay Dangi',
          path: LocalImages.AjayDangi
        },
        {
          name: 'Subhamoy Bhowmik',
          path: LocalImages.shubhamoy
        },
        {
          name: 'Arti Rathore',
          path: LocalImages.artiRathore
        }
      ]
    },
    {
      Title: 'GATE 2022 TOPPERS',
      students: [
        {
          name: 'Royal Pradhan',
          path: LocalImages.royalPradhan
        },
        {
          name: 'Saireet Misra',
          path: LocalImages.saireetMishra
        },
        {
          name: 'Khushbu Pilania',
          path: LocalImages.KhushbuPilania
        },
        {
          name: 'Rohan Chowdhury',
          path: LocalImages.rohanChowdhary
        }
      ]
    },
    {
      Title: 'SET 2023 TOPPERS',
      students: [
        {
          name: 'Jeel Patil',
          path: LocalImages.jeelPatil
        },
        {
          name: 'Bhushan Milmile',
          path: LocalImages.bhushanMilmile
        },
        {
          name: 'Ajay Kaslod',
          path: LocalImages.ajayKaslod
        },
        {
          name: 'Aishwarya Wani',
          path: LocalImages.aishwaryaWani
        }
      ]
    },
    {
      Title: 'IIT JAM 2022 TOPPERS',
      students: [
        {
          name: 'Aviral Agarwal',
          path: LocalImages.aviralAgarwal
        },
        {
          name: 'Ayushi Srivastava',
          path: LocalImages.ayushiSrivastava
        },
        {
          name: 'Sailesh S',
          path: LocalImages.saileshS
        },
        {
          name: 'Minhaaz Suhail',
          path: LocalImages.minhaazSuhail
        }
      ]
    },
    {
      Title: 'GAT B 2022 TOPPERS',
      students: [
        {
          name: 'ASHUTOSH VASISTH',
          path: LocalImages.ashutoshVasisth
        },
        {
          name: 'PARTHO PRATIM',
          path: LocalImages.parthiPratim
        },
        {
          name: 'MANAS KUNDU',
          path: LocalImages.manasKundu
        },
        {
          name: 'BAIVABI BHATTACHARYA',
          path: LocalImages.baivabiBhattacharya
        }
      ]
    },
    {
      Title: 'RPSC 2022 TOPPERS',
      students: [
        {
          name: 'Komal Sharma',
          path: LocalImages.ashutoshVasisth
        },
        {
          name: 'Aarti Paliwal',
          path: LocalImages.aartiPaliwal
        },
        {
          name: 'Kavita Bhati',
          path: LocalImages.kavitaBhati
        },
        {
          name: 'Rashmi Khedar',
          path: LocalImages.rashmiKhedar
        }
      ]
    },
    {
      Title: 'CUET 2022 TOPPERS',
      students: [
        {
          name: 'Shalini Sinha',
          path: LocalImages.shaliniSinha
        },
        {
          name: 'Shreyanshi Ratnakar',
          path: LocalImages.shreyanshiRatnakar
        }
      ]
    },
    {
      Title: 'NTA UGC-NET 2022 TOPPERS',
      students: [
        {
          name: 'Manoj Kumar',
          path: LocalImages.manojKumar
        },
        {
          name: 'Saba Shireen',
          path: LocalImages.sabashireen
        },
        {
          name: 'Sonu Gupya',
          path: LocalImages.sonugupya
        },
        {
          name: 'Vaishali Sharma',
          path: LocalImages.vaishaliSharma
        }
      ]
    },
    {
      Title: 'MPSC FORENSIC SCIENCE TOPPER',
      students: [
        {
          name: 'Birajdar',
          path: LocalImages.mpsc_1
        },
        {
          name: 'Hrushikesh',
          path: LocalImages.mpsc_2
        },
        {
          name: 'Jukte Anjali',
          path: LocalImages.mpsc_3
        },
        {
          name: 'Vijay Suvarnkar',
          path: LocalImages.mpsc_4
        }
      ]
    },
    {
      Title: 'RPSC GRADE 1 TOPPER',
      students: [
        {
          name: 'Ajay Kumar',
          path: LocalImages.rpsc_grade_1_1
        },
        {
          name: 'Rajat Yadav',
          path: LocalImages.rpsc_grade_1_2
        },
        {
          name: 'Sarita Gurjar',
          path: LocalImages.rpsc_grade_1_3
        }
      ]
    },
    {
      Title: 'ACT TOPPER',
      students: [
        {
          name: 'Heena',
          path: LocalImages.lurnigo1
        },
        {
          name: 'Mary',
          path: LocalImages.lurnigo2
        },
        {
          name: 'Nainika',
          path: LocalImages.lurnigo3
        },
        {
          name: 'Robert',
          path: LocalImages.lurnigo4
        }
      ]
    },
    {
      Title: 'SAT TOPPER',
      students: [
        {
          name: 'David',
          path: LocalImages.lurnigo5
        },
        {
          name: 'Karan',
          path: LocalImages.lurnigo6
        },
        {
          name: 'Laasyasri',
          path: LocalImages.lurnigo7
        },
        {
          name: 'Varun',
          path: LocalImages.lurnigo8
        }
      ]
    }
  ];

  const ExamClick = (data) => {
    const { isStatewise, childrenId, _id, name, seoName } = data;
    if (isStatewise) navigate('/AboutExamState/' + seoName + '/' + _id + '/' + name);
    else if (childrenId?.length > 0) navigate('/AboutSubExam/' + seoName + '/' + _id + '/' + name);
    else navigate('/' + seoName);
  };

  const closePrediction = () => setopenPrediction(false);

  const gotocard = () => navigate('/KailashChoudhary');

  return (
    <div className="">
      <HeaderHome />
      <Helmet>
        <title>IFAS - India's No.1 AI App for IIT-JAM, CSIR, GATE & More</title>
        <meta
          name="discription"
          content="Discover IFAS, India's leading AI-based app for IIT-JAM, CSIR-UGC NET, GATE, and competitive exams. Access top-notch study materials and excel in your exams!"
        />
      </Helmet>
      <div
        className="homebanner common-pad wow fadeIn pb-0"
        data-wow-duration="2s"
        data-wow-delay="0s">
        <div className="row mb-0 align-items-center w-100">
          <div className="col-12 col-xl-6">
            <h1
              className="wow fadeIn colorblue"
              data-wow-duration="2s"
              data-wow-delay="0s"
              onClick={gotocard}>
              <span className="colorblue">Grow</span> Your Career
            </h1>
            <h1 className="colorblue">
              With <span className="colorblue">IFAS</span>
            </h1>
            <div className="skillset wow fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
              <p className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
                <span className="checkSym">
                  <DoneIcon />
                </span>{' '}
                Skilled and Experienced Educators
              </p>
              <p className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                <span className="checkSym">
                  <DoneIcon />
                </span>{' '}
                Supportive 1:1 Coaching
              </p>
              <p className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
                <span className="checkSym">
                  <DoneIcon />
                </span>{' '}
                Trusted by <span className="colorblue">10 M+</span> Students
              </p>
              {/* <p className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
                <span className="checkSym">
                  <DoneIcon />
                </span>{' '}
                Predict Your <span className='fw-bold'>Rank & Selection</span> Chances <span className="colorblue cursor" onClick={()=>{setopenPrediction(true)}}>here</span>
              </p> */}
              {/* <p className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.5s">
                <span className="checkSym">
                  <DoneIcon />
                </span>{' '}
                For Classroom Register{' '}
                <span className="colorblue cursor" onClick={classroomsignIn}>
                  <u>here</u>
                </span>
              </p> */}
              {/* <div className='mt-3'>
            Predict Your <span className='fw-bold text-danger'>Rank & Selection</span> Chances <span className="colorblue cursor" ></span>
            <button className='ms-3 btn btn-warning rounded predictbtn fw-bold' onClick={()=>{setopenPrediction(true)}}>Know Your Rank</button>
            </div> */}
            </div>

            <div
              className="mt-4 position-relative homeinputeDiv wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="2s">
              <PhoneInput
                setCountryCode={(value) => {
                  setCountryCode(value);
                }}
                countryCode={countryCode}
                countryData={countryData}
                placeholder="Enter Your Phone Number"
                value={number}
                handleOnChangePhoneText={handleOnChangePhoneText}
              />
              <div
                className="mt-4 bannerfootertxt wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="1s">
                <p className="">
                  India's <span className="colorblue fw600 "> No. 1 AI Based App</span> For IIT-JAM,
                  CSIR - UGC NET, GATE & Other Competitive Examination
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-6 homebannerimg">
            <img className="homeimg" src={LocalImages.kcimg} alt="KC Sir" />
          </div>
          {/* <div className='col-12 col-lg-5 mt-4 d-none d-lg-block position-relative'> */}
          {/* <div className='col-12 col-lg-5 mt-4 position-relative'>
                        <PhoneInput />
                        <div className="mt-4 bannerfootertxt">
                            <p className='font16'>India's <span className="colorblue fw-bold "> No. 1 AI Based App</span> For
                                IIT-JAM, CSIR - UGC NET, GATE & Other Competitive Examination</p>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="boostknow common-pad wow fadeIn" data-wow-duration="2s" data-wow-delay="0s">
        <h1 className="text-center m-auto w-100 colorblue fw-normal">
          Boost Your Knowledge & Achieve Your{' '}
          <span className="position-relative">
            Dreams <img src={LocalImages.strock} className="dreamstrock" />
          </span>
        </h1>
        <p className="subtext">
          Unlimited Access To World Class Lectures From Your Laptop, Tablet, Or Mobile Phone Join
          Over <span className="colorblue fw-bold">10M+ Students</span>.
        </p>

        <div className="d-flex bootfeat flex-wrap justify-content-between mt-4">
          {boostYour.map((c, index) => (
            <div
              key={index.toString()}
              className="d-flex bootcard flex-nowrap justify-content-between align-items-center m-3 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.5s">
              <div className="text-center d-block w-100">
                <img src={c.path} alt={c.name} />
                <p className="colorblue mt-2 mb-0" style={{ cursor: 'default' }}>
                  {c.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="courses common-pad pt-3 pb-4 wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0s">
        <h1 className="colorblue">
          Courses <img className="coursestroke" src={LocalImages.courseStroke} alt="Courses" />
        </h1>
        <p className="mt-4 courseSubhead">
          The Most Comprehensive Packages Created By The Best Teachers Of India
        </p>

        <div className="d-flex flex-wrap mt-2 ">
          {examlist?.map((c, index) => (
            <div
              key={index.toString()}
              className="courseDiv d-flex text-center border rounded cursor wow fadeIn"
              data-wow-duration="2s"
              onClick={() => {
                if (c._id === 'lurnigo') window.open('https://lurnigo.com/');
                else ExamClick(c);
              }}>
              <img
                src={c._id === 'lurnigo' ? c.image : c.file}
                alt={c.name}
                className="examLogoimg"
              />
              <span className="d-flex align-items-center">{c.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="trustedstud common-pad wow fadeIn" data-wow-duration="2s">
        <div className="text-center">
          <h1
            className="colorblue d-inline-block position-relative text-center wow fadeIn"
            data-wow-duration="2s">
            Trusted By Students <CheckCircleIcon />
            <img
              className="trustedstudstroke"
              src={LocalImages.truestByStroke}
              alt="Meet IFAS Expert"
            />
          </h1>
        </div>

        <p className="mt-4 my-3 courseSubhead text-center">
          Thousand Of student already achieved their targets with IFAS
        </p>
        <div className="row">
          <div className="col-lg-8 order-2 order-lg-1">
            {topperList.map((item, indexMain) => {
              return (
                <div key={indexMain.toString()}>
                  <h5 className="colorblue mb-2 mt-3">{item.Title}</h5>
                  <div className="d-flex flex-wrap mt-2">
                    {item.students.map((c, indexInner) => (
                      <div
                        key={indexInner.toString()}
                        className="trustedstudcard rounded d-flex align-items-center wow fadeIn"
                        data-wow-duration="2s"
                        data-wow-delay="0.3s">
                        <img className="img-fluid" src={c.path} alt={c.name} />
                        <span className="d-block">{c.name}</span>
                        {/* <span className='colorblue rank'>{c.rank} | {c.cname}</span>
                            <span className='d-block citytxt'>{c.city}</span> */}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="col-lg-4 order-1 order-lg-2 d-flex align-items-center wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0s">
            <video muted autoPlay loop className="videotag m-auto" id="videoApp">
              <source src={trustdbyStudentvideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <ClassroomInPocket />
      <div
        className="app-achiev common-pad pb-2 wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0s">
        <h1 className="text-center">
          IFAS APP{' '}
          <span className="colorblue position-relative">
            Achievement
            <img
              className="meetifasstroke"
              src={LocalImages.ifasachievmentStroke}
              alt="Achievement"
            />
          </span>
        </h1>
        <p className="subhead mt-4">Recognised by the Best Names in Education and Technology</p>
        <div className="text-center">
          <video muted autoPlay loop className="videotag m-auto" id="videoApp">
            <source src={appAchievementvideo} type="video/mp4" />
          </video>
        </div>
      </div>
      {/* <div className='common-pad justify-content-center m-3 p-5 pt-0'>
                <div className='row m-5 p-5 boxshadow'>
                    <div className='col-12 col-md-4 d-grid px-0'>
                        <div>
                            <img src={LocalImages.silverbtn} alt="Silver button" className='w-100' />
                        </div>
                        <div className='p-5 pt-1'>
                            <h3 className='fw-bold mb-3'>IFAS The Learning APP</h3>
                            <h5 className='text-capitalize colorgrey'>India's No. 1 ai based app for IIT-JAM, CSIR-UGC NET,GATE & other Competitive Examination</h5>
                            <button className='btn btn-dark mt-5' onClick={signInroute} >Know more</button>
                        </div>

                    </div>
                    <div className='col-12 col-md-5 px-0'>
                        <div className='row'>
                            <div className='d-flex homefeatures'>
                                <img src={LocalImages.rating} alt="App Rating" />
                                <img src={LocalImages.liveLearning} alt="live Learning" />
                            </div>
                            <div className='d-flex homefeatures'>
                                <img src={LocalImages.bestresult} alt="bestresult" />
                                <img src={LocalImages.happyStudent} alt="happy Student " />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-3 d-flex align-items-center px-0'>
                        <div><img src={LocalImages.cities} alt="cities" className='w-100' /></div>
                    </div>
                </div>
            </div> */}
      <div
        className="common-pad justify-content-center mt-0  py-0 position-relative wow fadeIn"
        data-wow-duration="2s">
        <img src={LocalImages.aboutifas} alt="about" className="img-fluid " />
        <button className="btn btn-dark knowmorebtn px-5 position-relative" onClick={signInroute}>
          Know more
        </button>
      </div>
      <Footer />
      {!isHidden ? (
        <div className="fixedFooter font24">
          <div className="FooterInner">
            <div className="col-md-12 mx-0">
              <div className="d-flex justify-content-between mb-2 mb-md-0">
                <div className="d-flex">
                  <img
                    src={LocalImages.logoCircle}
                    alt="IFAS Logo"
                    className="landinglogo me-1 me-md-3"
                  />
                  <p className="fw-bold mb-0 pt-3">
                    For any query or Career guidance kindly contact at{' '}
                    <span className="landingPhone boldfont cursor me-1"> +91-9172266888 </span>
                  </p>
                </div>
                <a href="tel:+91-9172266888" className="mt-2">
                  <button className="btn btn-primary btn-sm boxshadow px-5">Contact Us</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div
        className="position-fixed backcolor rounded-circle cursor"
        style={{ right: '20px', bottom: '75px' }}>
        <a className="d-block d-md-none talkexpertbtn" href="tel:+919172266888">
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </a>
        <span className="d-none d-md-block" onClick={handleClick}>
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </span>
      </div>
      <TalktoExpert open={open} handleClose={handleClose} />
      <PredictionPopup open={openPrediction} handleClose={closePrediction} />
      {/* <CourseDropdown
        open={openCourse}
        isClassroomProgram={isClassroomProgram}
        handleClose={closePopup}
      /> */}
    </div>
  );
};

export default Home;
