import React from 'react';
import { Suspense } from 'react';
import '../App.css';
import Sidebar from '../Module/sidebar';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './dashboard';
import OnlineCourse from './onlineCourse';
import Learn from './learn';
import Lectures from './lectures';
import TeacherWiselectures from './teacherWiselectures';
import RecordedLectures from './recordedLectures';
import AllLectureVideo from './allLectureVideo';
import OnlineTest from './onlineTest';
import OnlineTestFree from './onlineTestFree';
import SubjectTest from './subjectTest';
import OrderHistory from './orderHistory';
import OrderDetails from './orderDetails';
import Header from '../Module/header';
import MyAddress from './myAddress';
import MyProfile from './myProfile';
import MyLectures from './myLectures';
import PaidDashboard from './paidDashboard';
import PlaceOrderInstallment from './placeOrderInstallment';
import { SidebarData } from '../Module/sidebarData';
import AllAbout from './allAbout';
import FreeLectures from './freeLectures';
import LiveClass from './liveClass';
import LectureScheduleFree from './lectureScheduleFree';
import ClassroomCoachingDashbord from './classroomCoachingDashbord';
import ClassroomCoachingDetails from './classroomCoachingDetails';
import TopicListFree from './topicListFree';
import ReferEarn from './referEarn';
import MyReferral from './myReferral';
// import MediaPlayer from './mediaPlayer';


function DashboardMenu(Props) {
  
  return (
    <div>
      <Header />
      <div className="dashboard d-flex backimg">
        <Sidebar data={SidebarData} />
        <Routes>

          <Route path="/" element={<Dashboard/>} />
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="OnlineCourse" element={<OnlineCourse />} />
          <Route path="Learn" element={<Learn />} />
          <Route path="AllAbout" element={<AllAbout />} />
          <Route path="Lectures" element={<Lectures />} />
          <Route path="TeacherWiselectures" element={<TeacherWiselectures />} />
          <Route path="RecordedLectures" element={<RecordedLectures />} />
          <Route path="AllLectureVideo" element={<AllLectureVideo />} />
          <Route path="OnlineTest" element={<OnlineTest />} />
          <Route path="OnlineTestFree" element={<OnlineTestFree />} />
          <Route path="SubjectTest" element={<SubjectTest />} />
          <Route path="FreeSubjectTest" element={<SubjectTest />} />
          <Route path="FreeTopicList" element={<TopicListFree />} />
          <Route path="OrderHistory" element={<OrderHistory />} />
          <Route path="ReferEarn" element={<ReferEarn/>} />
          <Route path=":ReferEarn/:MyReferral/" element={<MyReferral/>} />
          <Route path="OrderDetails" element={<OrderDetails />} />
          <Route path="MyAddress" element={<MyAddress />} />
          <Route path="MyProfile" element={<MyProfile />} />
          <Route path="PlaceOrderInstallment" element={<PlaceOrderInstallment />} />
          <Route path="LectureScheduleFree" element={<LectureScheduleFree />} />
        {/* <Route path="MediaPlayer" element={<MediaPlayer />} /> */}
          <Route path="FreeLectures" element={<FreeLectures />} />
          <Route path="LiveClass" element={<LiveClass />} />
          <Route path="ClassroomCoachingDashbord" element={<ClassroomCoachingDashbord/>} />
          <Route path="ClassroomCoachingDetails" element={<ClassroomCoachingDetails />} />
          
        </Routes>
      </div>
    </div>
  );
}

export default DashboardMenu;
