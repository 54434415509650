import React from 'react';

function LsPriceCard(props) {
  let index 
  if (props.isSelected) {
    index = props.data.subscriptionPlan.findIndex((c) => c.isSelected);
    if(index==-1){
    index = props.data.subscriptionPlan.findIndex((c) => c.hasSelected);
    }
  }
  return (
    <div
      className={
        props.data.isSelected
          ? 'borderBlue boxshadow p-3 rounded lectCard'
          : 'border boxshadow p-3 rounded lectCard'
      }>
      <div className="d-flex justify-content-between mt-2">
        <div className="d-flex lstitlebox align-items-start">
          <input
            style={{ marginTop: '2px' }}
            value={props?.data?._id}
            type="checkbox"
            className="cursor"
            onChange={props.onchange}
            checked={props.isSelected}
          />
          {props.data.isSelected ? (
            <span className="ms-2 fw-bold lstitle colorblue">{props?.data?.name}</span>
          ) : (
            <span className="ms-2 fw-bold lstitle">{props?.data?.name}</span>
          )}
        </div>
        <div>
          {props.data.isSelected && props?.data?.subscriptionPlan[index]?.price ? (
            <span className="ms-5 colorblue fw-bold">
              &#8377;
              {Math.floor(
                props?.data?.subscriptionPlan[index]?.price /
                  props?.data?.subscriptionPlan[index]?.months
              )}
              /mo
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <span className="ms-4">{props?.data?.lectureData?.teacherName}</span>
        {props.data.isSelected && props?.data?.subscriptionPlan[index]?.price ? (
          <span>Total &#8377;{props?.data?.subscriptionPlan[index]?.price}</span>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default LsPriceCard;
