import React,{useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setExamQuestionSelectedAction,
  setOptionValueFalseAction
} from '../Redux/Actions/examActions';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { maxAttemptReachedMsg } from '../Utils/errormsg';
import { convertFromHtmlToPlainText, getQuestionDescription } from '../Utils/utils';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ReactHtmlParser from 'react-html-parser';

function Mcq(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [popimg, setpopimg] = useState('');
  const selectedQuestionIndex = props.selectedQuestionIndex;
  const questionData = props.questionData;
  const questionSelected = questionData[selectedQuestionIndex];
  const isQuestionSelected = useSelector(
    (state) => state.SetOptionValueFalseReducer.isQuestionSelected
  );
  const optionsData = questionSelected.en.options;
  const firstOption = questionSelected.en.options[0]?.description;
  const secondOption = questionSelected.en.options[1]?.description;
  const thirdOption = questionSelected.en?.options[2]?.description;
  const fourthOption = questionSelected.en?.options[3]?.description;
  const marks = questionSelected.marks;
  const negativeMarks = questionSelected.negativeMarks;
  let questionTypeDescription = 'MCQ';
  const [firstOptionSelected, setFirstOptionSelected] = useState(props.clearOptionSelection);
  const [secondOptionSelected, setSecondOptionSelected] = useState(props.clearOptionSelection);
  const [thirdOptionSelected, setThirdOptionSelected] = useState(props.clearOptionSelection);
  const [fourthOptionSelected, setFourthOptionSelected] = useState(props.clearOptionSelection);
  const clearOptions = useSelector((state) => state.SetOptionValueFalseReducer.isTrue);
  const {
    isMaxAttemptReached,
    isLastSection,
    isNormalExamFlow,
    findNextQuestionNumberLegend,
    findPreviousQuestionNumberLegend,
    filterQuestionData
  } = props;
  const handleimage = (val) => {
    setpopimg(val);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    setFirstOptionSelected(false);
    setSecondOptionSelected(false);
    setThirdOptionSelected(false);
    setFourthOptionSelected(false);
    if (optionsData[0]?.isAnswer === true) {
      setFirstOptionSelected((prevState) => true);
    } else if (optionsData[1]?.isAnswer === true) {
      setSecondOptionSelected((prevState) => true);
    } else if (optionsData[2]?.isAnswer === true) {
      setThirdOptionSelected((prevState) => true);
    } else if (optionsData[3]?.isAnswer === true) {
      setFourthOptionSelected((prevState) => true);
    } else {
      setFirstOptionSelected((prevState) => false);
      setSecondOptionSelected((prevState) => false);
      setThirdOptionSelected((prevState) => false);
      setFourthOptionSelected((prevState) => false);
    }
    dispatch(setExamQuestionSelectedAction(false));

    if (clearOptions) {
      if (
        optionsData[0]?.isAnswer === false &&
        optionsData[1]?.isAnswer === false &&
        optionsData[2]?.isAnswer === false &&
        optionsData[3]?.isAnswer === false
      ) {
        setFirstOptionSelected((prevState) => false);
        setSecondOptionSelected((prevState) => false);
        setThirdOptionSelected((prevState) => false);
        setFourthOptionSelected((prevState) => false);
      }
      dispatch(setOptionValueFalseAction(false));
    }
  }, [clearOptions, isQuestionSelected, optionsData]);

  const handleOnPressAnswerOption = (answerIndex) => {
    props?.handleOnPressAnswer(answerIndex);
    setFirstOptionSelected(false);
    setSecondOptionSelected(false);
    setThirdOptionSelected(false);
    setFourthOptionSelected(false);
    if (answerIndex === 0) {
      setFirstOptionSelected((prevState) => true);
    } else if (answerIndex === 1) {
      setSecondOptionSelected((prevState) => true);
    } else if (answerIndex === 2) {
      setThirdOptionSelected((prevState) => true);
    } else {
      setFourthOptionSelected((prevState) => true);
    }
  };

  const getQuestionDescription = (question) => {
    return question.en?.title?.map((item) => {
      return item?.descType === 'text' ? (
        <div className="" key={item?._id}>
          {ReactHtmlParser(item?.description)}
        </div>
      ) : (
        <img
          className="qusimg cursor"
          alt={item?.description}
          key={item?._id}
          src={item?.description}
          onClick={() => handleimage(item?.description)}
        />
      );
    });
  };

  return (
    <div className="p-lg-1 h-100">
      <span style={{ color: 'red' }}>{isMaxAttemptReached ? maxAttemptReachedMsg : ''}</span>
      <div className="questionBox boxshadow p-2 p-lg-3 rounded">
        <div className="d-flex justify-content-between">
          <div>
            {isNormalExamFlow ? (
              <>
                {questionSelected?.questionNumber != '1' ? (
                  <span className="leftarrow">
                    <span
                      className="position-absolute"
                      onClick={(e) => props.handleQuestionNoClick(selectedQuestionIndex - 1)}>
                      {questionSelected?.questionNumber - 1}
                    </span>
                  </span>
                ) : null}
              </>
            ) : filterQuestionData &&
              filterQuestionData[0].questionNumber != questionSelected?.questionNumber ? (
              <span className="leftarrow">
                <span
                  className="position-absolute"
                  onClick={(e) =>
                    props.handleNextQuestionLegend(questionSelected?.questionNumber, false)
                  }>
                  {findPreviousQuestionNumberLegend(questionSelected?.questionNumber)}
                </span>
              </span>
            ) : null}
            <span>
              Q. {questionSelected?.questionNumber}/{props.totalNoOfQuestion}
            </span>{' '}
            <span className="greentxt p-1 rounded mx-1">{marks}</span>{' '}
            <span className="redtxt p-1 rounded mx-1">
              {negativeMarks === 0 ? `${negativeMarks}` : `-${negativeMarks}`}
            </span>
            <span className="greentxt p-1 rounded mx-1">{questionTypeDescription}</span>
          </div>
          {isNormalExamFlow ? (
            <>
              {!(questionData.length - 1 === selectedQuestionIndex && isLastSection) ? (
                <span className="rightarrow">
                  <span
                    className="position-absolute"
                    onClick={(e) => props.handleQuestionNoClick(selectedQuestionIndex + 1)}>
                    {questionSelected?.questionNumber + 1}
                  </span>
                </span>
              ) : null}
            </>
          ) : filterQuestionData &&
            filterQuestionData[filterQuestionData.length - 1].questionNumber !=
              questionSelected?.questionNumber ? (
            <span className="rightarrow">
              <span
                className="position-absolute"
                onClick={(e) => props.handleNextQuestionLegend(questionSelected?.questionNumber)}>
                {findNextQuestionNumberLegend(questionSelected?.questionNumber)}
              </span>
            </span>
          ) : null}
        </div>
        <div className="question fw-bold mt-2 p-2">{getQuestionDescription(questionSelected)}</div>
      </div>
      <div className="optionBox mt-4">
        {/* ***********************option-1******************** */}
        {optionsData[0] != undefined ? (
          <>
            {optionsData[0]?.descType === 'img' ? (
              <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
                <div
                  className={
                    firstOptionSelected
                      ? 'd-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm'
                      : 'd-flex align-items-center py-2 px-4 mb-2 '
                  }
                  onClick={() => handleOnPressAnswerOption(0)}>
                  <label className="radiocontainer">
                    <input type="radio" name="radio" checked={firstOptionSelected} />

                    <span className="checkmark">1</span>
                  </label>
                </div>

                <span className="ms-3 otiontxt  ">
                  {optionsData[0]?.descType === 'img' ? (
                    <img
                      src={firstOption}
                      className="optionimg cursor"
                      alt="option"
                      onClick={() => handleimage(firstOption)}
                    />
                  ) : (
                    ReactHtmlParser(firstOption)
                  )}
                </span>
              </div>
            ) : (
              <div
                className={
                  firstOptionSelected
                    ? ' checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm'
                    : 'd-flex align-items-center border rounded  px-4 mb-2'
                }
                onClick={() => handleOnPressAnswerOption(0)}>
                <label className="radiocontainer">
                  <input type="radio" name="radio" checked={firstOptionSelected} />

                  <span className="checkmark">1</span>
                </label>
                <span className="ms-3 otiontxt mt-3 ">
                  {optionsData[0]?.descType === 'img' ? (
                    <img
                      src={firstOption}
                      className="optionimg cursor"
                      alt="option"
                      onClick={() => handleimage(firstOption)}
                    />
                  ) : (
                    ReactHtmlParser(firstOption)
                  )}
                </span>
              </div>
            )}
          </>
        ) : null}

        {/* ***********************option-2******************** */}

        {optionsData[1] != undefined ? (
          <>
            {optionsData[0]?.descType === 'img' ? (
              <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
                <div
                  className={
                    secondOptionSelected
                      ? 'd-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm'
                      : 'd-flex align-items-center py-2 px-4 mb-2 '
                  }
                  onClick={() => {
                    handleOnPressAnswerOption(1);
                  }}>
                  <label className="radiocontainer">
                    <input type="radio" name="radio" checked={secondOptionSelected} />

                    <span className="checkmark">2</span>
                  </label>
                </div>
                <span className="ms-3 otiontxt">
                  {optionsData[1]?.descType === 'img' ? (
                    <img
                      src={secondOption}
                      className="optionimg cursor"
                      alt="option"
                      onClick={() => handleimage(secondOption)}
                    />
                  ) : (
                    ReactHtmlParser(secondOption)
                  )}
                </span>
              </div>
            ) : (
              <div
                className={
                  secondOptionSelected
                    ? 'checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm'
                    : 'd-flex align-items-center border rounded  px-4  mb-2 '
                }
                onClick={() => {
                  handleOnPressAnswerOption(1);
                }}>
                <label className="radiocontainer">
                  <input type="radio" name="radio" checked={secondOptionSelected} />

                  <span className="checkmark">2</span>
                </label>
                <span className="ms-3 otiontxt mt-3">
                  {optionsData[1]?.descType === 'img' ? (
                    <img
                      src={secondOption}
                      className="optionimg cursor"
                      alt="option"
                      onClick={() => handleimage(secondOption)}
                    />
                  ) : (
                    ReactHtmlParser(secondOption)
                  )}
                </span>
              </div>
            )}
          </>
        ) : null}

        {/* ***********************option-3******************** */}

        {optionsData[2] != undefined ? (
          <>
            {optionsData[0]?.descType === 'img' ? (
              <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
                <div
                  className={
                    thirdOptionSelected
                      ? 'd-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm'
                      : 'd-flex align-items-center py-2 px-4 mb-2 '
                  }
                  onClick={() => {
                    handleOnPressAnswerOption(2);
                  }}>
                  <label className="radiocontainer">
                    <input type="radio" name="radio" checked={thirdOptionSelected} />
                    <span className="checkmark">3</span>
                  </label>
                </div>
                <span className="ms-3 otiontxt">
                  {optionsData[2]?.descType === 'img' ? (
                    <img
                      src={thirdOption}
                      className="optionimg cursor"
                      alt="option"
                      onClick={() => handleimage(thirdOption)}
                    />
                  ) : (
                    ReactHtmlParser(thirdOption)
                  )}
                </span>
              </div>
            ) : (
              <div
                className={
                  thirdOptionSelected
                    ? 'checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm'
                    : 'd-flex align-items-center border rounded  px-4  mb-2 '
                }
                onClick={() => {
                  handleOnPressAnswerOption(2);
                }}>
                <label className="radiocontainer">
                  <input type="radio" name="radio" checked={thirdOptionSelected} />
                  <span className="checkmark">3</span>
                </label>
                <span className="ms-3 otiontxt mt-3">
                  {optionsData[2]?.descType === 'img' ? (
                    <img
                      src={thirdOption}
                      className="optionimg cursor"
                      alt="option"
                      onClick={() => handleimage(thirdOption)}
                    />
                  ) : (
                    ReactHtmlParser(thirdOption)
                  )}
                </span>
              </div>
            )}
          </>
        ) : null}

        {/* ***********************option-4******************** */}

        {optionsData[3] != undefined ? (
          <>
            {optionsData[0]?.descType === 'img' ? (
              <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
                <div
                  className={
                    fourthOptionSelected
                      ? 'd-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm'
                      : 'd-flex align-items-center py-2 px-4 mb-2 '
                  }
                  onClick={() => handleOnPressAnswerOption(3)}>
                  <label className="radiocontainer">
                    <input type="radio" name="radio" checked={fourthOptionSelected} />

                    <span className="checkmark">4</span>
                  </label>
                </div>
                <span className="ms-3 otiontxt ">
                  {optionsData[3]?.descType === 'img' ? (
                    <img
                      src={fourthOption}
                      className="optionimg cursor"
                      alt="option"
                      onClick={() => handleimage(fourthOption)}
                    />
                  ) : (
                    ReactHtmlParser(fourthOption)
                  )}
                </span>
              </div>
            ) : (
              <div
                className={
                  fourthOptionSelected
                    ? 'checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm'
                    : 'd-flex align-items-center border rounded  px-4  mb-2 '
                }
                onClick={() => handleOnPressAnswerOption(3)}>
                <label className="radiocontainer">
                  <input type="radio" name="radio" checked={fourthOptionSelected} />

                  <span className="checkmark">4</span>
                </label>
                <span className="ms-3 otiontxt mt-3">
                  {optionsData[3]?.descType === 'img' ? (
                    <img
                      src={fourthOption}
                      className="optionimg cursor"
                      alt="option"
                      onClick={() => handleimage(fourthOption)}
                    />
                  ) : (
                    ReactHtmlParser(fourthOption)
                  )}
                </span>
              </div>
            )}
          </>
        ) : null}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="modeldiv editprofEdit">
            <div className="col d-flex mt-0 justify-content-end ">
              <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
            </div>
            <div className="">
              <img className="img-fluid " src={popimg} alt="images" />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Mcq;
