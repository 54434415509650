import React, { useState } from 'react';
import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Lottie from 'react-lottie';
import orderCompleted from '../Assets/Images/JSON/ordercompleted.json';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getCartList } from '../Redux/Actions/homeAction';
import moment from 'moment/moment';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

function ScholershipTestAnalysis(props) {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orderCompleted,
    renderer: 'svg'
  };

  const { hasActivePaidPlan } = useSelector((state) => ({
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan
  }));
  const dispatch = useDispatch();

  const me = props?.examData?.examData
  const resultDate = me?.length > 0 ? me[0]?.resultDate : ""

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}>
      <Fade in={props.open}>
        <div className="modeldiv p-5 text-center">
          <>
            <div className="col mb-2 d-flex justify-content-between ">
              <Lottie options={defaultOptions} height={130} width={200}></Lottie>
              <ClearOutlinedIcon className="colorblue cursor" onClick={props.handleClose} />
            </div>

            <p className="text-center colorblue font14">
              <b>Thank You!</b>
            </p>
            <p className="text-center colorblue font14 " >
              <b>For Participating In A Contest!</b>
            </p>
            <p className="text-center font12 ">The result will be displayed here </p>
            <p className="text-center font12 mt-0">By {moment.utc(resultDate).format('MMMM Do YYYY, h:mm:ss a')}</p>
            <div className='pt-4'>

            </div>
          </>

        </div>
      </Fade>
    </Modal>
  );
}

export default ScholershipTestAnalysis;
