import React, { useEffect, useState } from 'react';
import '../App.css';
import doubts from '../Assets/Images/Help/doubts.json';
import Lottie from 'react-lottie';
import { LocalImages, i18n } from '../Constant';
import { GiftedChat } from 'react-web-gifted-chat';

class Chat extends React.Component {
  state = {
    messages: []
  };

  componentWillMount() {
    this.setState({
      messages: [
        {
          id: 1,
          text: 'Hello developer',
          createdAt: new Date(),
          user: {
            id: 2,
            name: 'React',
            avatar: 'https://facebook.github.io/react/img/logo_og.png'
          }
        }
      ]
    });
  }

  onSend(messages = []) {
    this.setState((previousState) => ({
      messages: GiftedChat.append(previousState.messages, messages)
    }));
  }

  render() {
    return (
      <div style={{ background: 'red' }}>
        abcbcbbc
        <GiftedChat
          messages={this.state.messages}
          onSend={(messages) => this.onSend(messages)}
          user={{
            id: 1
          }}
        />
      </div>
    );
  }
}

export default Chat;
