import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import CustomDropdown from '../Component/customDropdown';
import { LocalImages } from '../Constant';
import {
  getAddList,
  getCourseSubscribed,
  getSubjectList,
  getTopicList,
  updateHomeMoreValues
} from '../Redux/Actions/homeAction';
import endpoint from '../Constant/endpoint';
import axios from 'axios';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { useLocation, useNavigate } from 'react-router-dom';

function AddcourseSupport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [batch, setbatch] = useState('');
  const [examTypeId, setExamTypeId] = useState('');
  const [childrenId, setChildrenId] = useState('');
  const [Topic, setTopic] = useState('');
  const [subjectList, setsubjectList] = useState([]);
  const [topicList, settopicList] = useState([]);
  const [Title, setTitle] = useState('');
  const [Msg, setMsg] = useState('');
  const [uploadimg, setuploadimg] = useState();
  const location = useLocation();
  const paidTiles = location.state.paidTiles;

  const [batchName, setBatchName] = useState('');
  const [subjectName, setSubjectName] = useState('');
  const [topicName, setTopicName] = useState('');

  const { authToken } = useSelector((state) => ({
    authToken: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));

  const { baseCourseId } = useSelector((state) => ({
    baseCourseId: state?.AuthReducer?.baseCourseId
  }));

  const onChangebatch = (val) => {
    setChildrenId(val?.subExamTypeId)
    setbatch(val?.courseId);
    setExamTypeId(val?.examTypeId);
    setBatchName(val);
    dispatch(
      getSubjectList(
        val?.courseId,
        (res) => {
          setsubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (val) => {
    setSubjectName(val);
    dispatch(
      getTopicList(
        val?._id,
        (res) => {
          settopicList(res);
        },
        () => {}
      )
    );
  };

  const onChangeTopic = (val) => {
    setTopic(val);
    setTopicName(val);
  };

  const handleChange = (e) => {
    const fileSizeKiloBytes = e.target.files[0].size / 1024;
    if (fileSizeKiloBytes > 15360) {
      dispatch(showErrorSnackbar('Image size should be less than 15 MB!!!'));
    } else if (e?.target?.files?.length) {
      if (e?.target?.files?.length) {
        setImage({
          preview: URL.createObjectURL(e?.target?.files[0]),
          raw: e?.target?.files[0]
        });
      }
      functionTwo(e?.target?.files[0]);
       
    }
  };

  const functionTwo = async (val) => {
    const res1 = await fileUpload(val);
    dispatch(updateHomeMoreValues({ isLoader: false }));
    if (res1?.data?.data) {
      dispatch(showSuccessSnackbar(res1?.data?.msg));
      const data = res1.data.data;
      Object.assign(data, { type: 1 });
      setuploadimg(data);
    } else {
      dispatch(showErrorSnackbar(res1?.data?.msg));
    }
  };

  const fileUpload = (file) => {
    // dispatch(showSuccessSnackbar('File Uploaded successfully'));
    dispatch(updateHomeMoreValues({ isLoader: true }));

    const url = process.env.REACT_APP_BASEURL + endpoint.home.uploadSupportMedia + '1/';

    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'content-type': 'multipart/form-data'
      }
    };
    return axios.post(url, formData, config);
  };

  const handleSubmit = (e) => {
    // if(batchName === ""){
    //   dispatch(showErrorSnackbar('Please input batch name'));
    // }
    // else if(subjectName === ""){
    //   dispatch(showErrorSnackbar('Please input subject name'));
    // }
    // else if(topicName === ""){
    //   dispatch(showErrorSnackbar('Please input topic name'));
    // }
    // else if(Title === ""){
    //   dispatch(showErrorSnackbar('Please input title'));
    // }
    // else if(Msg === ""){
    //   dispatch(showErrorSnackbar('Please input message'));
    // }
    // else if(uploadimg === ""){
    //   dispatch(showErrorSnackbar('Please upload image'));
    // }
     
    if (Title?.target?.value.length <= 3 || Title?.target?.value.length >= 50) {
      dispatch(showErrorSnackbar('Title character length should be min 3 to max 50'));
    } else {
      const postdata = {
        baseCourseId: baseCourseId,
        examTypeId: examTypeId,
        subExamTypeId: childrenId,
        courseId: batch,
        supportType: 2,
        description: Msg?.target?.value,
        name: Title?.target?.value,
        media: uploadimg,
        topicId: Topic?._id,
        subjectId: subjectName?._id
      };
      if (childrenId === '') delete postdata['subExamTypeId'];
      dispatch(
        getAddList(
          postdata,
          (res) => {
            
            navigate('../SupportChat', { state: { id: res._id } });
          },
          () => {}
        )
      );
    }
  };
  return (
    <div className="rightPanel">
      <h3>Course Support</h3>
      <div className="row mt-4">
        <div className="col-sm-7 bgwhite">
          <div className="boxshadow rounded p-4">
            <div className="pb-2 boxshadow p-2 mb-2">
              <CustomDropdown
                lableName="Select Batch"
                value={batchName}
                options={paidTiles}
                Isrequired={true}
                setInputText={(value) => onChangebatch(value)}
              />
            </div>

            <div className="pb-2 boxshadow p-2 mb-2">
              <CustomDropdown
                lableName="Select Subject"
                value={subjectName}
                options={subjectList}
                Isrequired={true}
                setInputText={(value) => onChangeSubject(value)}
              />
            </div>
            <div className="pb-2  boxshadow p-2 mb-2">
              <CustomDropdown
                lableName="Select Topic"
                className=""
                value={topicName}
                options={topicList}
                Isrequired={true}
                //setInputText={(value) => setTopic(value)}
                setInputText={(value) => onChangeTopic(value)}
              />
            </div>

            <div className="mb-2 d-flex boxshadow p-2">
              <input
                type="text"
                className="form-control border-0 border-bottom"
                placeholder="Title"
                onChange={(value) => setTitle(value)}
                maxLength={50}
                minLength={3}
              />
              <span className="required ms-2 fs-6">*</span>
            </div>
            <div className="d-flex boxshadow p-2">
              <textarea
                className="form-control border-0 border-bottom "
                aria-label="With textarea"
                placeholder="Enter your message"
                maxLength={1000}
                onChange={(value) => setMsg(value)}></textarea>
            </div>
            <div className="mt-3  boxshadow p-2">
              <label htmlFor="upload-button">
                {image?.preview ? (
                  <img src={image?.preview} alt="ulpoad" width="100" />
                ) : (
                  <div className="d-flex align-items-center cursor">
                    <img src={LocalImages.ulpoad} alt="ulpoad" width="50" height="50" />
                    <p className="text-center mb-0 ms-5">Upload photo</p>
                  </div>
                )}
              </label>
              <input
                type="file"
                accept="image/png, image/jpeg"
                id="upload-button"
                style={{ display: 'none' }}
                onChange={handleChange}
              />
              <br />
            </div>
            <div className="mt-4 w-25 m-auto">
              <button
                type="submit"
                className={
                  !batchName ||
                  !subjectName ||
                  !topicName ||
                  !Title ||
                  Title?.target?.value.length < 3 ||
                  !Msg
                    ? 'btn btn-secondary mb-3 submitbtn'
                    : 'btn btn-primary mb-3 submitbtn'
                }
                disabled={
                  !batchName ||
                  !subjectName ||
                  !topicName ||
                  !Title ||
                  Title?.target?.value.length < 3 ||
                  !Msg
                }
                onClick={handleSubmit}>
                Send{' '}
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-5"></div>
      </div>
    </div>
  );
}

export default AddcourseSupport;
