import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  notSyncedDataAction,
  examIdDataAction,
  setInstructionsAction
} from '../Redux/Actions/examActions';
import { useNavigate } from 'react-router-dom';
import { getExam } from '../Redux/Actions/examActions';
import service from '../Constant/service';
import { useLocation } from 'react-router-dom';
import { updateMoreValues } from '../Redux/Actions/AuthAction';

const SelectTest = () => {
  let selectedQuestionIndex = 0;
  const [loader, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notSyncedDataFromStore = useSelector((state) => state.ExamSelectReducer.notSyncedData);
  const examIdDataFromStoreObject = useSelector((state) => state.ExamSelectReducer.examIdData);
  const examIdDataFromStore = examIdDataFromStoreObject[0];

  const ACCESS_TOKEN_NEW =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzM2YjNmOGQ2ZDJiYjQ5MzkxNGFmNjciLCJtb2JpbGVObyI6Ijc3NzEwNzAwOTAiLCJpYXQiOjE2Njc3OTk2MDEsImV4cCI6MTY2NzgyODQwMX0.aPeOtNRdLNJ1CopXRP1YTygB6cXf80zt6jNqAIpmR0k';

  const _retrieveData = async (examId) => {
    try {
      let strNotSyncedData = notSyncedDataFromStore || '{}';

      let notSyncedData = JSON.parse(strNotSyncedData);
      selectedQuestionIndex = +(notSyncedData[examId] || 0);
      dispatch(notSyncedDataAction(JSON.stringify(notSyncedData)));
    } catch (error) {}

    try {
      const value = examIdDataFromStore;

      if (value !== null) {
        let examData = JSON.parse(value);
        let examDataT = JSON.parse(`{"examData": [${value}]}`);

        dispatch(setInstructionsAction(examDataT));
        if (examData.isSubmitted === true) {
          navigate('/DetailResult', { state: { examId: examId, examData: examData } });
        } else {
          let tmp = [examData];
          navigate('/ExamInstruction', { state: { examId: examId, examData: tmp } });
          
          
        }
      } else {
        getExamAPI(examId);
      }
    } catch (error) {
      getExamAPI(examId);
    }
  };

  const getExamAPI = (examId) => {
    setLoading(true);
    dispatch(
      getExam(
        ACCESS_TOKEN_NEW,
        examId,
        (data) => {
          setLoading(false);

          let examData = data.examData;

          let examStatus = examData[0].status;
          let isContestTest = examData[0].isContestTest;
          if (examStatus === 2) {
            if (isContestTest === true) {
              let currentDate = +new Date();
              let contestEndDate = +new Date(examData.contestEndDate);
              if (currentDate > contestEndDate) {
                // Result Screen
                // navigate('/DetailResult', { state: { examId: examId, examData: examData[0] } });
                sessionStorage.setItem("examId",examId)
                sessionStorage.setItem("examData",JSON.stringify(examData[0]))
                window.open('/DetailResult',"_blank","width="+screen.availWidth+",height="+screen.availHeight );
                // window.open('/DetailResult',"_blank","width=1280,height=570");
              } else {
              }
            } else {
              // navigate('/DetailResult', { state: { examId: examId, examData: examData[0] } });
              sessionStorage.setItem("examId",examId)
              sessionStorage.setItem("examData",JSON.stringify(examData[0]))
              window.open('/DetailResult',"_blank","width="+screen.availWidth+",height="+screen.availHeight );
              // window.open('/DetailResult',"_blank","width=1280,height=570");
            }
          } else {
            // navigate('/ExamInstruction', { state: { examId: examId, examData: examData } });
            sessionStorage.setItem("examId",examId)
            sessionStorage.setItem("examData",JSON.stringify(examData))
            window.open('/ExamInstruction', "_blank","width="+screen.availWidth+",height="+screen.availHeight);
            // window.open('/ExamInstruction', "_blank","width=1280,height=570");
          }
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  return (
    <div>
      <button
        className="btn btn-outline-primary"
        onClick={() => {
          _retrieveData('62ecf8e2ada4a6e998d4bea5');
        }}>
        Start Test 1
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => {
          _retrieveData('627503981fe38d827a93b211');
        }}>
        Start Test 2
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => {
          _retrieveData('63689e00ddb54accc9e0b913');
        }}>
        Start Test 3
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => {
          _retrieveData('6360a74f432e2816ddbe3e4e');
        }}>
        Start Test 4
      </button>
    </div>
  );
};

export default SelectTest;
