import React, { useEffect, useState } from 'react';
import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SupportCard from '../Module/supportCard';
import { LocalImages } from '../Constant';
import { getListSupport } from '../Redux/Actions/homeAction';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NoIssueFound from '../Component/noIssueFound';
function TechnicalSupport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [issueList, setIssueList] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setIssueList([]);
    dispatch(
      getListSupport(
        '1/1',
        (res) => {
          setIssueList(res);
        },
        () => {}
      )
    );
  }, []);

  const techSupport = (type) => {
    navigate('../AddTechnicalSupport', {
      state: { techtype: type }
    });
  };

  const chat1 = (id,status) => {
    navigate('../SupportChat', { state: { id: id,status:status, screeName: 'TechnicalSupport' } });
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const newData = () => {
    setIssueList([]);
    dispatch(
      getListSupport(
        '1/1',
        (res) => {
 
          setIssueList(res);
        },
        () => {}
      )
    );
  };

  const inprogressData = () => {
    setIssueList([]);
    dispatch(
      getListSupport(
        '2/1',
        (res) => {
 
          setIssueList(res);
        },
        () => {}
      )
    );
  };

  const ResolvedData = () => {
    setIssueList([]);
    dispatch(
      getListSupport(
        '3/1',
        (res) => {
          setIssueList(res);
        },
        () => {}
      )
    );
  };

  return (
    <div className="rightPanel">
      <h3>Technical Support</h3>
      <div className="row">
        <div className="col-lg-10 col-xl-7 border rounded p-3 bgwhite">
          <h5 className="fw-bold mb-3">Talk To Customer Executive</h5>
          <div className="customtab">
            <ul className="nav mb-3 w-100 p-2 rounded" id="pills-tab" role="tablist">
              <li className="nav-item flex-fill" role="presentation" onClick={newData}>
                <button
                  className="nav-link active border-0 w-100 rounded-start"
                  id="pills-new-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-new"
                  type="button"
                  role="tab"
                  aria-controls="pills-new"
                  aria-selected="true">
                  New Issue
                </button>
              </li>
              <li className="nav-item flex-fill" role="presentation" onClick={inprogressData}>
                <button
                  className="nav-link border-0 w-100"
                  id="pills-inprogress-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-inprogress"
                  type="button"
                  role="tab"
                  aria-controls="pills-inprogress"
                  aria-selected="true">
                  Inprogress
                </button>
              </li>
              <li className="nav-item flex-fill" role="presentation" onClick={ResolvedData}>
                <button
                  className="nav-link border-0 w-100 rounded-end"
                  id="pills-resolved-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-resolved"
                  type="button"
                  role="tab"
                  aria-controls="pills-resolved"
                  aria-selected="true">
                  Resolved
                </button>
              </li>
            </ul>
            {issueList.length !== 0?<div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade active show"
                id="pills-new"
                role="tabpanel"
                aria-labelledby="pills-new-tab"
                tabIndex="0">
                {issueList?.map((item, index) => {
                  return (
                    <SupportCard data={item} key={index} clickhandle={() => chat1(item._id,item.status)} />
                  );
                })}
                
              </div>
              <div
                className="tab-pane fade"
                id="pills-inprogress"
                role="tabpanel"
                aria-labelledby="pills-inprogress-tab"
                tabIndex="0">
                {issueList?.map((item, index) => {
                  return (
                    <SupportCard data={item} key={index} clickhandle={() => chat1(item._id,item.status)} />
                  );
                })}
              </div>
              <div
                className="tab-pane fade"
                id="pills-resolved"
                role="tabpanel"
                aria-labelledby="pills-resolved-tab"
                tabIndex="0">
                {issueList?.map((item, index) => {
                  return <SupportCard key={index} data={item} />;
                })}
              </div>
            </div>:<NoIssueFound />}
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="modeldiv p-5">
            <div className="col mb-4 d-flex justify-content-between ">
              <h5 className="colorblue fw-bold">Select Support Topic</h5>
              <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
            </div>
            <div className="row ps-0 form-check">
              <div className="d-flex align-items-center border-bottom p-2">
                <div className="border border-primary rounded-circle p-2">
                  <img
                    src={LocalImages.courseSupport}
                    alt="courseSupport"
                    style={{ width: '20px' }}
                  />
                </div>
                <div className="flex-fill colorblue ms-3">Course Related Issue</div>
                <button
                  type="button"
                  className="btn btn-outline-secondary py-1 font12"
                  onClick={() => techSupport(2)}>
                  Ask Doubts
                </button>
              </div>
              <div className="d-flex align-items-center border-bottom p-2">
                <div className="border border-primary rounded-circle p-2">
                  <img
                    src={LocalImages.techSupport}
                    alt="courseSupport"
                    style={{ width: '20px' }}
                  />
                </div>
                <div className="flex-fill colorblue ms-3">Web App Related Issue</div>

                <button
                  type="button"
                  className="btn btn-outline-secondary py-1 font12"
                  onClick={() => techSupport(1)}>
                  Ask Doubts
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <div
        className="position-fixed cursor" style={{ right: '20px', bottom: '30px' }}>
         <span className="addbtn cursor mt-0" onClick={handleOpen}>
          +
         </span>
      </div>
    </div>
  );
}

export default TechnicalSupport;
