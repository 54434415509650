import React, { useEffect, useState, useRef } from 'react';
import '../App.css';
import { LocalImages } from '../Constant';
import Modal from '@material-ui/core/Modal';
import profile from '../Assets/Images/Dashboard/profile.png';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { editProfile, uploadProfilePic, updateHomeMoreValues, getReferLink } from '../Redux/Actions/homeAction';
import { updateMoreValues,deleteUser } from '../Redux/Actions/AuthAction';
import endpoint from '../Constant/endpoint';
import axios from 'axios';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import DeleteProfilePopup from '../Module/deleteProfilePopup';


function MyProfile() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openPopup ,SetopenPopup]= useState(false);
  const [profilePic, setProfilePicture] = useState('');
  const [referData, setReferData] = useState({})
  const [uploadimg, setuploadimg] = useState({});
  const { authToken } = useSelector((state) => ({
    authToken: state.AuthReducer.ACCESS_TOKEN_NEW
  }));
  const dispatch = useDispatch();
  const inputRefs = useRef([]);

  const {
    firstName,
    lastName,
    email,
    mobileNo,
    profilePicture,
    dob,
    stateName,
    countryName,
    countryCode
  } = useSelector((state) => ({
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    email: state?.AuthReducer?.email,
    mobileNo: state?.AuthReducer?.mobileNo,
    profilePicture: state?.AuthReducer?.profilePicture,
    dob: state?.AuthReducer?.dob,
    stateName: state?.AuthReducer?.stateName,
    countryCode: state?.AuthReducer?.countryCode,
    countryName: state?.AuthReducer?.countryName
  }));
  const [image, setImage] = useState('');
  const [emailAddress, setEmailAddress] = useState(email);
  const [dateOfBirth, setDateOfBirth] = useState(moment(dob).format('YYYY-MM-DD'));
  const [imageOutside, setimageOutside] = useState('');
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setImage('');
    setDateOfBirth(dob);
  };
  const handleChange = (e) => {
    const fileSizeKiloBytes = e.target.files[0].size / 1024;
    if (fileSizeKiloBytes > 1024)
      dispatch(showErrorSnackbar('File size should be less than 1 MB!!!'));
    else {
      if (e.target.files.length) {
        if (e.target.files.length) {
          setImage(e.target.files[0]);
        }
        // functionTwo(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    dispatch(updateMoreValues({ isLoader: true }));
    setimageOutside(profilePicture);
  }, [profilePicture]);

  function allemail(inputtxt) {
    var emails =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (inputtxt.match(emails)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input valid email'));
      return false;
    }
  }


  const handleOnChangeEmailText = (prevEmailInputText) => {
    setEmailAddress(prevEmailInputText.target.value);
  };

  const functionTwo = async (val) => {
    const res1 = await fileUpload(val);
    if (res1.data.data) {
      dispatch(showSuccessSnackbar(res1.data.msg));
      setuploadimg(res1.data.data);
      dispatch(updateMoreValues(res1.data.data));
    } else {
      dispatch(showErrorSnackbar(res1.data.msg));
    }
  };
  const fileUpload = (file) => {
    dispatch(showSuccessSnackbar('File Uploading ...'));
    const url = process.env.REACT_APP_BASEURL + endpoint.setting.uploadProfilePic;

    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'content-type': 'multipart/form-data'
      }
    };
    return axios.post(url, formData, config);
  };

  const twelveYearFromNow = new Date();
  twelveYearFromNow.setFullYear(twelveYearFromNow.getFullYear() - 12);

  useEffect(() => {
    get_refer_link()
    if (profilePic.file) {
      dispatch(
        uploadProfilePic(
          profilePic.file,
          (res) => {
            dispatch(updateMoreValues({ profilePicture: res.profilePicture }));
          },
          () => {}
        )
      );
    }
    return () => {};
  }, [profilePic]);

  const updateProfile = () => {
  
    if (!emailAddress) dispatch(updateMoreValues({ email: emailAddress }));

    if (!dateOfBirth) dispatch(updateMoreValues({ dob: dateOfBirth }));

    setOpen(false);
    if (image) {
      functionTwo(image);
    }
    if (emailAddress && dateOfBirth) {
      const data = {
        email: emailAddress,
        dob: dateOfBirth
      };
      dispatch(
        editProfile(
          data,
          (res) => {
            dispatch(
              updateMoreValues({
                email: res?.data?.email,
                dob: res?.data?.dob
              })
            );
            dispatch(showSuccessSnackbar(res?.msg));
          },
          () => {}
        )
      );
    } else if (emailAddress) {
      const data = {
        email: emailAddress
      };
      dispatch(
        editProfile(
          data,
          (res) => {
            dispatch(updateMoreValues({ email: res.email }));
            dispatch(showSuccessSnackbar(res?.msg));
          },
          () => {}
        )
      );
    } else if (dateOfBirth) {
      const data = {
        dob: dateOfBirth
      };
      dispatch(
        editProfile(
          data,
          (res) => {
            dispatch(updateMoreValues({ dob: res?.data?.dob }));
            dispatch(showSuccessSnackbar(res?.msg));
          },
          () => {}
        )
      );
    }
    
  };
  const opendeleteAccount=()=>{
    SetopenPopup(true);
  }
  const deleteAccount=()=>{
  
    
    dispatch(
      deleteUser(
        {},
        (res) => {
          navigate('/home');
          dispatch(showSuccessSnackbar(res?.msg));
        },
        () => {}
      )
    );
    
    
  }
  const handleDeleteProfile=()=>{
    SetopenPopup(false);
  }
  const get_refer_link = async() => {
    dispatch(getReferLink('',async(response) => {
        await setReferData(response)
    }))
}

// useEffect(()=>{},[])



  return (
    <div className="rightPanel">
      <h4>My Profile</h4>
      <div className="row Addressleftside p-2 p-lg-4 boxshadow col-lg-9 mt-4 bgwhite">
        <div className="col-sm-12">
          <div className="row mt-1">
            <div className="col">
              <h6>
                <b>Personal Details</b>
              </h6>
            </div>
            <div className="col d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-outline-danger py-1 border-0 font12 "
                onClick={handleOpen}>
                Edit Profile
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 md-5">
          <div className="row">
            <div className="col-sm-3 col-xl-2 d-flex align-items-center">
              {profilePicture ? (
                <img
                  src={profilePicture}
                  loading="lazy"
                  key={Date.now()}
                  className="profImg"
                  alt="profile"
                />
              ) : (
                <img src={profile} key={Date.now()} className="profImg" alt="profile" />
              )}
            </div>
            <div className="col-sm-9 col-xl-10">
              <p className="mt-2 mb-1">
                <b>
                  {firstName} {lastName}
                </b>{' '}
              </p>
              <p className="mb-1">
                {countryCode}-{mobileNo}
              </p>
              <p className="mb-1">{email} </p>
              <p className="mb-1">
                {stateName}, {countryName}
              </p>
              <p className="mb-1">
                Referral Code: {referData?.referralCode}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="modeldiv editprofEdit">
            <div className="col d-flex mt-0 justify-content-between ">
              <h6 className="colorblue fw-bold">Edit Personal Details</h6>
              <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
              <button className='btn btn-outline-danger btn-sm' onClick={opendeleteAccount}><DeleteIcon fontSize='small'/>Delete Profile</button>
            </div>
            <div className="row mb-5 p-3 mt-3 boxshadow ">
              <div className="col-sm-12 text-center position-relative ">
                <label htmlFor="upload-button">
                  {image ? (
                    <div className="d-flex align-items-center cursor position-relative ">
                      {/* <img src={image} key={Date.now()} className="profImg" alt="profile"/> */}
                      <img
                        className="cursor"
                        src={URL.createObjectURL(image)}
                        alt="dummy"
                        width="100"
                      />
                      <img
                        src={LocalImages.edit}
                        className="cursor"
                        alt="Edit"
                        style={{ width: '13px', position: 'absolute', right: '0', bottom: '3px' }}
                      />
                    </div>
                  ) : profilePicture ? (
                    <div className="d-flex align-items-center cursor position-relative ">
                      {/* <img src={image} key={Date.now()} className="profImg" alt="profile"/> */}
                      <img className="cursor" src={imageOutside} alt="dummy" width="100" />
                      <img
                        src={LocalImages.edit}
                        className="cursor"
                        alt="Edit"
                        style={{ width: '13px', position: 'absolute', right: '0', bottom: '3px' }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center cursor position-relative ">
                      <img
                        src={LocalImages.ulpoad}
                        className="cursor"
                        alt="dummy"
                        width="50"
                        height="50"
                      />
                      {/* <p className="text-center mb-0 ms-5">Upload photo</p> */}
                    </div>
                  )}
                </label>

                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-button"
                  style={{ display: 'none' }}
                  onChange={handleChange}
                />
                <br />
              </div>

              <div className="col-sm-12 p-3 font12">
                <div className="col-sm-12 ps-0 mt-2">
                  <label className="mb-2 d-block">First Name</label>
                  <input
                    type="text"
                    placeholder="Enter First Name "
                    className="border-0 border-bottom w-100"
                    value={firstName}
                    disabled="disabled"
                  />
                </div>
                <div className="col-sm-12 ps-0 mt-2">
                  <label className="mb-2 d-block">Last Name</label>
                  <input
                    type="text"
                    placeholder="Enter Last Name"
                    className="border-0 border-bottom w-100"
                    value={lastName}
                    disabled="disabled"
                  />
                </div>
                <div className="col-sm-12 ps-0 mt-2">
                  <label className="mb-2 d-block">Mobile Number</label>
                  <input
                    type="text"
                    placeholder="Enter Mobile Number"
                    className="border-0 border-bottom w-100"
                    value={mobileNo}
                    disabled="disabled"
                  />
                </div>
                <div className="col-sm-12 ps-0 mt-2 position-relative">
                  <label className="mb-2 d-block">Email Address</label>
                  <input
                    type="email"
                    placeholder="Enter Mail ID"
                    ref={(ref) => (inputRefs.current[0] = ref)}
                    className="border-0 border-bottom w-100 "
                    value={emailAddress}
                    maxLength="40"
                    onChange={(value) => handleOnChangeEmailText(value)}
                  />
                  <img
                    src={LocalImages.edit}
                    className="cursor"
                    onClick={() => inputRefs?.current[0].focus()}
                    alt="Edit"
                    style={{ width: '10px', position: 'absolute', right: '17px', bottom: '4px' }}
                  />
                </div>
                <div className="col-sm-12 mt-2 ps-0">
                  <label className="mb-2 d-block">Date Of Birth</label>
                  <input
                    type="date"
                    max={moment(twelveYearFromNow).format('YYYY-MM-DD')}
                    placeholder="dv@gmail.com"
                    className="border-0 border-bottom w-100"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="d-grid mx-auto">
              <button type="button" className="btn btn-primary " onClick={updateProfile}>
                Save and Update
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
      {openPopup ? <DeleteProfilePopup    openPopup={openPopup}  handleClose={handleDeleteProfile}  deleteAccount={deleteAccount}/> :""}
    </div>
  );
}

export default MyProfile;
