import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function SignInbtn(props) {
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const currentURLarr = useLocation().pathname.split('/');
  let link = currentURLarr[currentURLarr.length - 1];
  const signInroute = () => {
    setDisable(true);
    if (link === 'ClassroomCoachingDetails') window.location.href = 'ClassroomSignIn';
    else navigate('/SignIn', { state: { IsclassRoom: false } });
  };
  return (
    <div className="py-1">
      <button
        className="btn btn-primary boxshadow loginbtn"
        disabled={disable}
        onClick={signInroute}>
        Login
      </button>
    </div>
  );
}

export default SignInbtn;
