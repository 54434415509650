import React from 'react';
import '../App.css';
import VideoLecture from '../Component/videoLecture';
import { useLocation, useNavigate } from 'react-router-dom';
import NoRecord from '../Component/noRecord';
function LectureSubject() {
  const location = useLocation();
  const navigate = useNavigate();
  const LectureData = location.state.item;
  const Title = location.state.title;
  const subjectTopicInfo = location.state.subjectTopicInfo;
  const onPressVideo = (topics, title) => {
    navigate('/PaidUser/Topics', { state: { topics: topics, title: title, subjectTopicInfo:subjectTopicInfo}});
  }

  return (
    <div className="rightPanel">
      <div>
        <h4>{Title}</h4>
      </div>
      <div className="mt-3 lectureheight p-3 cursor w-100 scrollbar">
        <div className='width75'>
        {LectureData?.length > 0 ? (
          LectureData?.map((c) => {
            const { _id, name, videoCount, duration, topics } = c;
            if (name || duration)
              return (
                <VideoLecture
                  key={_id}
                  title={name}
                  lectcount={videoCount}
                  duration={duration}
                  onpress={() => onPressVideo(topics, name)}
                />
              );
            else return <NoRecord />;
          })
        ) : (
          <NoRecord />
        )}
      </div>
      </div>
    </div>
  );
}

export default LectureSubject;
