import React, { useState, useEffect } from 'react';
import '../App.css';
import { LocalImages } from '../Constant';
import Lottie from 'react-lottie';
import studymaterial from '../Assets/Images/Dashboard/studyMaterial.json';
import lecture from '../Assets/Images/Dashboard/lecture.json';
import LectureCard from '../Component/lectureCard';
import CourseCard from '../Module/courseCard';
import MoreBtn from '../Component/moreBtn';
import InstallmentPopUp from '../Module/installmentPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Expert from '../Assets/Images/JSON/expert.json';
import subscribed from '../Assets/Images/JSON/subscription.json';
import liveText from '../Assets/Images/Dashboard/liveNew.json';

import TalktoExpert from '../Module/talktoExpert';
import { Fragment } from 'react';
import {
  getPaidCourseList,
  getBanner,
  getCourseHome,
  getFreeLecture,
  updateHomeValues,
  getQuote,
  getLiveVideo,
  startEndLiveStatus,
  getNotificationUnread,
  getUnActiveCourse,
  getUnActiveSubscribedCourse
} from '../Redux/Actions/homeAction';
import Carousel from 'react-grid-carousel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CourseList from '../Module/courseList';
import { getName, updateMoreValues } from '../Redux/Actions/AuthAction';
import BbbVideo from './bbbVideo';
import { updateCourseDetailValues } from '../Redux/Actions/courseDetailAction';
import BbbVideoPaidTiles from './bbbVideoPaidTiles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import live from '../Assets/Images/Learn/livesession.json';
import BannerCard from '../Module/bannerCard';
import PredictionPopup from '../Module/predictionPopup';

function PaidDashboard() {
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const [openLive, setOpenLive] = useState(false);
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [expertopen, setexpertopen] = useState(false);
  const [installmentData, setInstallmentData] = useState();
  const [date, setDate] = useState('');
  const [openCourse, setopenCourse] = useState(false);
  const [openVideo, setopenVideo] = useState(false);
  const [openVideoPaidTiles, setOpenVideoPaidTiles] = useState(false);
  const [openPrediction, setopenPrediction] = useState(false);
  const closePrediction = () => {
    setopenPrediction(false);
  };

  const dispatch = useDispatch();
  const {
    upcoming,
    freeVideo,
    firstName,
    courseHome,
    moreFree,
    paidTiles,
    installments,
    homeQuote,
    userId,
    selectedTiles,
    paidTilesInactive,
    paidTilesSubscribed,
    installmentsInactive
  } = useSelector((state) => ({
    upcoming: state?.HomeReducer?.upcoming,
    freeVideo: state?.HomeReducer?.freeVideo,
    firstName: state?.AuthReducer?.firstName,
    paidTilesSubscribed: state?.HomeReducer?.paidTilesSubscribed,
    homeQuote: state?.HomeReducer?.homeQuote,
    talkToExpert: state?.AuthReducer?.talkToExpert,
    profilePicture: state?.AuthReducer?.profilePicture,
    courseHome: state?.HomeReducer?.courseHome,
    courseSelect: state?.HomeReducer?.courseSelect,
    moreFree: state?.HomeReducer?.moreFree,
    paidTiles: state?.HomeReducer?.paidTiles,
    installments: state?.HomeReducer?.installments,
    paidTilesInactive: state?.HomeReducer?.paidTilesInactive,
    installmentsInactive: state?.HomeReducer?.installmentsInactive,
    userId: state?.AuthReducer?._id,
    liveVideoData: state?.AuthReducer?.liveVideoData,
    selectedTiles: state?.HomeReducer?.selectedTiles
  }));

  const { examTypeId, baseCourseName, baseCourseId, examTypeName, childrenId } = useSelector(
    (state) => ({
      examTypeId: state?.AuthReducer?.examTypeId,
      baseCourseId: state?.AuthReducer?.baseCourseId,
      childrenId: state?.AuthReducer?.childrenId,
      baseCourseName: state?.AuthReducer?.baseCourseName,
      examTypeName: state?.AuthReducer?.examTypeName
    })
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Expert,
    renderer: 'svg'
  };

  const defaultOptionsLive = {
    loop: true,
    autoplay: true,
    animationData: live,
    renderer: 'svg'
  };

  const defaultOptionsLiveText = {
    loop: true,
    autoplay: true,
    animationData: liveText,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const defaultOptionsSubscribed = {
    loop: true,
    autoplay: true,
    animationData: subscribed,
    renderer: 'svg'
  };

  const courseId = selectedTiles?.courseId;
  const sessionId = selectedTiles?.sessionId;
  useEffect(() => {
    let reqData = {
      courseId,
      dateAndTime: moment(new Date()).format('YYYY-MM-DD')
    };
    if (sessionId)
      reqData = {
        courseId,
        dateAndTime: moment(new Date()).format('YYYY-MM-DD'),
        sessionId
      };
    dispatch(
      getLiveVideo(reqData, (data) => {
        dispatch(updateMoreValues({ liveVideoData: { ...data, liveType: 1 } }));
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getNotificationUnread());
    dispatch(getPaidCourseList());
    dispatch(getUnActiveCourse());
    dispatch(getUnActiveSubscribedCourse());
    dispatch(
      updateHomeValues({
        courseHome: [],
        upcoming: [],
        freeVideo: [],
        courseList: [],
        courseSelect: ''
      })
    );
    if (examTypeId) {
      let url3 = `/${examTypeId}/${baseCourseId}/3`;
      if (childrenId) {
        url3 = `/${examTypeId}/${baseCourseId}/3/${childrenId}`;
      }
      dispatch(
        getFreeLecture(url3, (data) => {
          const moreData = data.lecturesData['3'];
          dispatch(
            updateHomeValues({
              moreFree: moreData,
              isLoader: false
            })
          );
        })
      );

      let url0 = `/${examTypeId}/${baseCourseId}/0`;
      let requestData = {
        baseCourseId: baseCourseId,
        examTypeId: examTypeId
      };

      if (childrenId) {
        url0 = `/${examTypeId}/${baseCourseId}/0/${childrenId}`;
        requestData.subExamTypeId = childrenId;
      }

      dispatch(
        getFreeLecture(url0, async (data) => {
          dispatch(getBanner(requestData));
          if (data.lecturesData['2']) {
            dispatch(
              updateHomeValues({ upcoming: [...data.lecturesData['2'], ...data.lecturesData['1']] })
            );
          } else dispatch(updateHomeValues({ upcoming: data.lecturesData['1'] }));
          dispatch(updateHomeValues({ freeVideo: data.lecturesData['3'] }));

          if (childrenId) {
            dispatch(
              getCourseHome(`/${baseCourseId}/${examTypeId}/${childrenId}`, (dataCourse) => {
                dispatch(updateHomeValues({ courseHome: dataCourse ? dataCourse : [] }));
              })
            );
          } else {
            dispatch(
              getCourseHome(`/${baseCourseId}/${examTypeId}`, (dataCourse) => {
                dispatch(updateHomeValues({ courseHome: dataCourse ? dataCourse : [] }));
              })
            );
          }
        })
      );
    } else {
      dispatch(
        updateHomeValues({
          courseHome: [],
          upcoming: [],
          freeVideo: [],
          courseList: [],
          courseSelect: ''
        })
      );
    }
  }, [examTypeId, childrenId, baseCourseId]);

  useEffect(() => {
    dispatch(getQuote(`/2/en`, true));
  }, []);

  const upcomingArray = [];
  for (let i = 0; i < 3; i++) {
    upcomingArray.push(upcoming[i]);
  }

  const courseroute = () => {
    navigate('/PaidUser/OnlineCourse');
  };
  const allClassroute = () => {
    navigate('/PaidUser/LiveClass', { state: { upcoming: upcoming } });
  };

  const allFreeLectures = () => {
    navigate('/PaidUser/FreeLectures', { state: { free: moreFree } });
  };
  const smRoute = (dataStudyMaterial) => {
    const { _id, baseCourseId, examTypeId, subExamTypeId } = dataStudyMaterial;
    dispatch(
      updateCourseDetailValues({
        packageSection: [],
        sessions: [],
        courseDetail: {}
      })
    );
    const data = {
      baseCourseId,
      examTypeId
    };
    if (subExamTypeId) data.subExamTypeId = subExamTypeId;
    dispatch(
      getName(
        data,
        (res) => {
          const { seoBaseCourseName, seoSubExamName, seoExamTypeName } = res;
          navigate(
            '/' +
              `${seoSubExamName ? seoSubExamName : seoExamTypeName}` +
              '/' +
              seoBaseCourseName +
              '-study-material' +
              '/' +
              _id
          );
        },
        () => {}
      )
    );
  };
  const lsRoute = (courseID, courseName) => {
    navigate('/LectureSeries/' + courseName + '/' + courseID, { state: { courseID: courseID } });
  };

  const livelectroute = (item) => {
    const {
      classType,
      timeType,
      startDate,
      orderId,
      courseId,
      isInactive,
      lectureData,
      isSubscribed
    } = item;
    if (isSubscribed) {
      setDate(moment.utc(startDate).format('Do MMM YYYY'));
      setOpenSubscribe(true);
    } else if (isInactive) {
      const newInstallmentData = [...installmentsInactive, ...installments];

      const index = newInstallmentData?.findIndex((item) => item?.parentOrderId === orderId);

      if (index > -1) {
        setInstallmentData(newInstallmentData[index]);
        handleClick();
      }
    } else {
      dispatch(updateHomeValues({ selectedTiles: item }));
      if (timeType === 1) {
        dispatch(updateMoreValues({ liveVideoData: lectureData }));
        if (status === 2) {
          if (classType === 1) {
            if (lectureData?.isRTMPEnvironment) {
              dispatch(
                startEndLiveStatus({
                  userId: userId,
                  liveId: lectureData.liveId,
                  liveType: 1,
                  isLive: true
                })
              );
              window.open(
                '/MediaPlayer',
                '_blank',
                'width=' + screen.availWidth + ',height=' + screen.availHeight
              );
            } else {
              if (
                !lectureData?.isRTMPEnvironment &&
                lectureData?.status === 2 &&
                lectureData?.classType === 1
              ) {
                handleBbbVideoPaidTilesClick();
              }
            }
          } else if (classType === 2) {
            dispatch(
              startEndLiveStatus({
                userId: userId,
                liveId: lectureData.liveId,
                liveType: 2,
                isLive: true
              })
            );
            window.open(
              '/VideoPlayer',
              '_blank',
              'width=' + screen.availWidth + ',height=' + screen.availHeight
            );
            navigate(0);
          }
        } else if (status === 1) {
          if (
            moment.utc(lectureData?.sessionTime, 'hh:mm:ss').format('LT') <
            moment(new Date()).format('LT')
          )
            setOpenLive(true);
          else navigate('/PaidUser/LectureSchedule', { state: { courseId: courseId } });
        } else navigate('/PaidUser/MyLearning');
      } else navigate('/PaidUser/MyLearning');
    }
  };
  const CourseDetailsTest = (courseID, courseName) => {
    navigate('/CourseDetails/' + courseName + '/' + courseID, {
      state: { courseID: courseID, isextended: false }
    });
  };

  const CourseDetails = (courseID, courseName) => {
    navigate('/CourseDetails/' + courseName + '/' + courseID, {
      state: { courseID: courseID, isextended: true }
    });
  };

  const LectureDetail = (courseID) => {
    navigate('/LectureSeries', {
      state: {
        courseID: courseID,
        isextended: true
      }
    });
  };

  const handleClose = () => {
    setopen(false);
  };
  const handleClick = () => {
    setopen(true);
  };
  const Click = () => {
    setexpertopen(true);
  };
  const Close = () => {
    setexpertopen(false);
  };

  const handleBbbVideoClose = () => {
    setopenVideo(false);
  };

  const handleBbbVideoPaidTilesClose = () => {
    setOpenVideoPaidTiles(false);
  };

  const handleBbbVideoPaidTilesClick = () => {
    setOpenVideoPaidTiles(true);
  };

  const payNow = (orderId) => {
    const newInstallmentData = [...installmentsInactive, ...installments];

    const index = newInstallmentData?.findIndex((item) => item?.parentOrderId === orderId);

    if (index > -1) {
      return (
        <div className="d-flex flex-column align-items-end">
          <button
            type="button"
            key={index.toString()}
            className="btn btn-primary font12 rounded px-2 py-1 d-inline mt-2"
            onClick={() => {
              setInstallmentData(newInstallmentData[index]);
              handleClick();
            }}>
            Pay Installment
          </button>
        </div>
      );
    }
    return null;
  };
  const study = [
    {
      name: 'Study Material',
      path: studymaterial,
      subtextsm: 'Best IIT JAM Life Science Study Material Package in Printed Book Version',
      defaultOptions: {
        loop: true,
        autoplay: true,
        animationData: studymaterial,
        renderer: 'svg',
        rendererSettings: { className: 'customsize' }
      },
      link: smRoute
    },
    {
      name: 'Lecture Series',
      subtextls: 'Best IIT JAM Life Science Lecture Series',
      defaultOptions: {
        loop: true,
        autoplay: true,
        animationData: lecture,
        renderer: 'svg',
        rendererSettings: { className: 'customsize' }
      },
      link: lsRoute
    }
  ];
  const ExamList = () => {
    // window.location.href = "../CourseList";
    setopenCourse(true);
  };
  const closePopup = () => {
    setopenCourse(false);
  };

  const index = courseHome?.findIndex(
    (obj) => (obj?.type === 2 && obj?.packages) || (obj?.type === 5 && obj?.packages)
  );

  return (
    <div className="rightPanel">
      <div className="">
        <div className="bgwhite customcontainer">
          <div className="bg p-4 rounded mb-5 customcontainer">
            <h6 className="fw-bold mb-2">Hello, {firstName}</h6>
            <p className="mb-0">{homeQuote}</p>
          </div>
        </div>

        <div
          id="carouselExampleDark"
          className="carousel carousel-dark slide customcontainer "
          data-bs-ride="carousel">
          <div className="carousel-indicators">
            {[...paidTilesInactive, ...paidTiles, ...paidTilesSubscribed]?.map((c, index) => {
              return (
                <button
                  type="button"
                  key={index}
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to={index}
                  className={index === 0 ? 'active' : null}
                  aria-current="true"
                  aria-label={'Slide ' + index}></button>
              );
            })}
          </div>
          <div className="carousel-inner">
            {[...paidTilesInactive, ...paidTiles, ...paidTilesSubscribed]?.map((c, index) => {
              const {
                name,
                endDate,
                timeType,
                classType,
                startDate,
                isInactive = false,
                isSubscribed = false,
                packageType,
                lectureData = {}
              } = c;

              const days = moment(endDate).diff(moment(new Date()), 'days');

              const { status, fromDate, sessionTime, sessionDate } = lectureData;
              return (
                <div
                  key={index}
                  className={
                    index === 0
                      ? 'carousel-item active position-relative'
                      : 'carousel-item  position-relative'
                  }
                  data-bs-interval="100000">
                  <div
                    className="boxshadow rounded mb-4 paidTilesBox bgwhite w-75 cursor"
                    onClick={() => livelectroute(c)}>
                    <div className="d-flex justify-content-between p-3">
                      <div className="d-flex align-items-center">
                        <img
                          src={LocalImages.courselogo}
                          style={{ width: '40px' }}
                          alt="Course Logo"
                        />
                        <span className="ms-3 font12">{name}</span>
                      </div>
                      <div className="d-flex flex-column align-items-end font12">
                        {isInactive ? (
                          <div>
                            {isSubscribed
                              ? `Batch Start From : ${moment
                                  .utc(startDate)
                                  .format(' Do MMM YYYY - HH:MM')}`
                              : 'Deactivated'}
                          </div>
                        ) : (
                          <div>
                            {days > 0 ? 'Expiring In: ' : 'Expired'}
                            <span className="successcolor">
                              {days > 15
                                ? moment.utc(endDate).format(' Do MMM YYYY')
                                : days + 'days'}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {
                      <div className="p-1 px-5 ps-3">
                        {(classType === 1 || classType === 2) && status === 1 ? (
                          <div className="upcomingClass d-flex justify-content-between ">
                            <div className="font12">
                              {moment.utc(sessionTime, 'hh:mm:ss').format('LT') <
                              moment(new Date()).format('LT')
                                ? `Live session starting soon`
                                : `Upcoming Class: `}
                              <span className="successcolor ms-2">
                                {moment.utc(sessionTime, 'hh:mm:ss').format('LT') <
                                moment(new Date()).format('LT')
                                  ? ''
                                  : moment
                                      .utc(classType === 1 ? fromDate : sessionDate, 'YYYY-MM-DD')
                                      .format(' Do MMMM YYYY,') +
                                    moment.utc(sessionTime, 'hh:mm:ss').format(' LT')}
                              </span>
                            </div>
                          </div>
                        ) : null}

                        <div className="d-flex">
                          {timeType === 2 ? (
                            <button
                              key={index.toString()}
                              type="button"
                              className="btn btn-primary submitbtn  font12 rounded px-2 py-1 d-inline mt-2 ">
                              Recorded
                            </button>
                          ) : null}
                        </div>
                        <div className="d-flex justify-content-between p-3 px-0">
                          {timeType === 1 && classType === 1 ? (
                            <div className="d-flex">
                              <div className="liveTiles redtxt font12 rounded px-2 py-1 me-3 d-flex align-items-center">
                                Live
                              </div>
                              {/* <a href="#"  className="fw-bold font12" onclick="handleBbbVideoClick();return false;"> */}
                              {status === 2 ? (
                                <a
                                  href="#"
                                  className="fw-bold font12"
                                  onClick="handleBbbVideoPaidTilesClick();return false;">
                                  <Lottie
                                    options={defaultOptionsLiveText}
                                    height={30}
                                    width={100}
                                  />
                                </a>
                              ) : null}
                            </div>
                          ) : timeType === 1 && classType === 2 ? (
                            <div className="d-flex">
                              <div className="bg-green font12 rounded px-2 py-1 me-3 d-flex align-items-center">
                                Premier
                              </div>
                              {status === 2 ? (
                                <a href="#" target="_blank" className="fw-bold font12">
                                  Premier Now
                                </a>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    }
                  </div>
                  <div className="position-absolute tilebuttons">
                    {c.hasExtendOption && days <= 30 ? (
                      <div className="d-flex flex-column align-items-end">
                        <button
                          type="button"
                          className="btn btn-primary submitbtn  font12 rounded px-2 py-1 d-inline mt-2 "
                          onClick={() => {
                            if (packageType === 5) {
                              LectureDetail(c.courseId);
                            } else {
                              CourseDetails(c.courseId, c.name);
                            }
                          }}>
                          Extend
                        </button>
                      </div>
                    ) : null}
                    <div className="d-flex flex-column align-items-end">{payNow(c.orderId)}</div>
                  </div>
                </div>
              );
            })}
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            key={'Previous'}
            data-bs-target="#carouselExampleDark"
            data-bs-slide="prev">
            <span
              className="carousel-control-prev-icon"
              style={{ background: 'none', color: '#328aed' }}
              aria-hidden="true">
              <ArrowBackIosNewIcon />
            </span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            key={'Next'}
            className="carousel-control-next "
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="next">
            <span
              className="carousel-control-next-icon"
              style={{ background: 'none', color: '#328aed' }}
              aria-hidden="true">
              <ArrowForwardIosIcon />
            </span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div className="d-flex mt-3 customcontainer px-md-3">
          <p className="py-1 fw-bold">Recommended Exams</p>
          <div className="ms-2 ms-md-4 py-1" style={{ cursor: 'pointer' }} onClick={ExamList}>
            {examTypeName}
            {window.location.pathname !== '/CourseList' ? (
              openCourse ? (
                <KeyboardArrowUpIcon className="text-dark" />
              ) : (
                <KeyboardArrowDownIcon className="text-dark" />
              )
            ) : (
              ''
            )}
          </div>
        </div>

        {courseHome?.length === 0 ? (
          <div className="paiduserrightsidepanel bgwhite">
            <div className="bg examcommonpad backgroundimage ">
              <>
                <span className="colorblue fw-bold" style={{ fontSize: '20px' }}>
                  {' '}
                  Program Coming Soon
                </span>
                <p className="">Please, select another exam to explore more courses</p>
              </>
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="mt-3 bannerDiv">
          <BannerCard />
        </div>

        {upcoming != '' ? (
          <div className="customcontainer py-4 boxshadow bgwhite  p-3">
            <div className="d-flex justify-content-between cursor">
              <h3 className="fw-bold ms-md-4">Live Classes</h3>
              <MoreBtn gotoLink={allClassroute} />
            </div>
            <div className="mt-5">
              <div className="row">
                {upcomingArray &&
                  upcomingArray?.map((c, index) => {
                    if (c !== undefined) {
                      return <LectureCard key={index} data={c} />;
                    }
                  })}
              </div>
            </div>
          </div>
        ) : null}

        {courseHome?.map((obj, index) => (
          <Fragment key={index}>
            {obj?.type === 1 ? (
              <div className="customcontainer py-4 mt-4 boxshadow bgwhite  p-3">
                <div className="d-flex justify-content-between mb-3">
                  {/* <h3 className="fw-bold ms-md-4 boldfont">Courses</h3> */}
                  <h3 className="fw-bold ms-md-4 boldfont">{obj.name}</h3>
                  <MoreBtn gotoLink={courseroute} />
                </div>
                <Carousel
                  cols={3}
                  rows={1}
                  gap={10}
                  responsiveLayout={[
                    { breakpoint: 1024, cols: 3 },
                    { breakpoint: 768, cols: 2, rows: 1, gap: 5 },
                    { breakpoint: 499, autoplay: 2000, loop: true }
                  ]}>
                  {obj &&
                    obj.packages.map((c, index) => (
                      <Carousel.Item key={index}>
                        <div className="mx-4 mb-3" key={index}>
                          <CourseCard
                            path={c.bannerImage}
                            course={c.name}
                            sectionType={c?.sectionType}
                            courseType={obj.type}
                            baseCourseId={c?.baseCourseId}
                            examTypeId={c?.examTypeId}
                            subExamTypeId={c?.subExamTypeId}
                            batch={c.startDate}
                            courseID={c._id}
                            language={c.language.icon}
                            timeType={c.timeType}
                            classType={c.classType}
                          />
                        </div>
                      </Carousel.Item>
                    ))}
                </Carousel>
              </div>
            ) : (
              ''
            )}
          </Fragment>
        ))}
        {courseHome?.map((obj, index) => (
          <Fragment key={index}>
            {obj?.type === 6 ? (
              <div className="customcontainer py-4 mt-4 boxshadow bgwhite  p-3">
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="fw-bold ms-md-4 boldfont">{obj.name}</h3>
                  <MoreBtn gotoLink={courseroute} />
                </div>
                <Carousel
                  cols={3}
                  rows={1}
                  gap={10}
                  responsiveLayout={[
                    { breakpoint: 1024, cols: 3 },
                    { breakpoint: 768, cols: 2, rows: 1, gap: 5 },
                    { breakpoint: 499, autoplay: 2000, loop: true }
                  ]}>
                  {obj &&
                    obj.packages.map((c, index) => (
                      <Carousel.Item key={index}>
                        <div className="mx-4 mb-3" key={index}>
                          <CourseCard
                            path={c.bannerImage}
                            course={c.name}
                            sectionType={c?.sectionType}
                            courseType={obj.type}
                            baseCourseId={c?.baseCourseId}
                            examTypeId={c?.examTypeId}
                            subExamTypeId={c?.subExamTypeId}
                            batch={c.startDate}
                            courseID={c._id}
                            language={c.language.icon}
                            timeType={c.timeType}
                            classType={c.classType}
                          />
                        </div>
                      </Carousel.Item>
                    ))}
                </Carousel>
              </div>
            ) : (
              ''
            )}
          </Fragment>
        ))}
        {courseHome?.map((obj, index) => {
          return (
            <Fragment key={index}>
              {obj.type === 3 ? (
                <div className="customcontainer  py-2 boxshadow  p-3 bgwhite">
                  <div className="d-flex justify-content-between mb-3">
                    <h3 className="fw-bold ms-md-4 boldfont">{obj?.name}</h3>
                    <MoreBtn gotoLink={courseroute} />
                  </div>
                  <div className="mt-3">
                    <div className="row mx-4">
                      {obj &&
                        obj.packages.map((c, index) => {
                          if (index <= 2) {
                            return (
                              <div
                                className="col-sm-6 col-lg-4 col-xl-4 ps-0 pe-0 mb-3"
                                key={index}>
                                <div className="card p-3 shadow me-sm-4">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="ellipsis fw-bold">{c.name}</h6>
                                    {/* <div className="ms-1">
                                <span className="tslang colorblue border p-1">
                                  {c?.language?.abbreviation}
                                </span>
                              </div> */}
                                    <div className="testserieslangimg">
                                      <img
                                        className="w-50 position-absolute bgwhite"
                                        src={c?.language?.icon}
                                      />
                                    </div>
                                  </div>

                                  <p className="mb-4 font12">
                                    Unit Test: {c.testCount.unit}, Mock Test: {c.testCount.mock}
                                  </p>
                                  <button
                                    key={index.toString()}
                                    className="btn btn-primary w-75 py-md-1 py-lg-1 font12 mx-auto"
                                    onClick={() => {
                                      CourseDetailsTest(c._id, c.name);
                                    }}>
                                    View Details
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </Fragment>
          );
        })}
        <div className=" mt-3 ">
          {courseHome?.map((obj, index) =>
            obj?.type === 5 && obj?.packages ? (
              <div
                key={index}
                className="col-sm-12 "
                onClick={() => {
                  lsRoute(obj?.packages[0]?._id, obj?.packages[0]?.name);
                }}>
                <div className="lectureseriesbgclr customcontainer studymaterialLectureseriesdiv text-white card border-0 p-3 pt-1 pb-0 d-flex flex-row flex-wrap align-items-center my-md-3 boxshadow mx-auto">
                  <div className="col-12 col-md-8 cursor">
                    <h6 className="font12 fw-bold">{obj?.name}</h6>
                    <p className="font12 mt-1">
                      Best {examTypeName} {baseCourseName} Lecture Series
                    </p>
                  </div>
                  <div className="col-12 col-md-4 animateImges">
                    <Lottie
                      className="me-0"
                      options={study[1].defaultOptions}
                      height={110}
                      width={165}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          )}
        </div>
        {courseHome?.map((obj, index) => {
          return (
            <Fragment key={index}>
              {obj.type === 7 ? (
                <div className="customcontainer  py-2 boxshadow  p-3 bgwhite">
                  <div className="d-flex justify-content-between mb-3">
                    <h3 className="fw-bold ms-md-4 boldfont">{obj?.name}</h3>
                    <MoreBtn gotoLink={courseroute} />
                  </div>
                  <div className="mt-3">
                    <div className="row mx-4">
                      {obj &&
                        obj.packages.map((c, index) => {
                          if (index <= 2) {
                            return (
                              <div
                                className="col-sm-6 col-lg-4 col-xl-4 ps-0 pe-0 mb-3"
                                key={index}>
                                <div className="card p-3 shadow me-sm-4">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="ellipsis fw-bold">{c.name}</h6>
                                    {/* <div className="ms-1">
                                <span className="tslang colorblue border p-1">
                                  {c?.language?.abbreviation}
                                </span>
                              </div> */}
                                    <div className="testserieslangimg">
                                      <img
                                        className="w-50 position-absolute bgwhite"
                                        src={c?.language?.icon}
                                      />
                                    </div>
                                  </div>

                                  <p className="mb-4 font12">
                                    Unit Test: {c.testCount.unit}, Mock Test: {c.testCount.mock}
                                  </p>
                                  <button
                                    key={index.toString()}
                                    className="btn btn-primary w-75 py-md-1 py-lg-1 font12 mx-auto"
                                    onClick={() => {
                                      CourseDetailsTest(c._id, c.name);
                                    }}>
                                    View Details
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </Fragment>
          );
        })}
        {courseHome?.map((obj, index) => {
          return (
            <Fragment key={index}>
              {obj.type === 8 ? (
                <div className="customcontainer  py-2 boxshadow  p-3 bgwhite">
                  <div className="d-flex justify-content-between mb-3">
                    <h3 className="fw-bold ms-md-4 boldfont">{obj?.name}</h3>
                    <MoreBtn gotoLink={courseroute} />
                  </div>
                  <div className="mt-3">
                    <div className="row mx-4">
                      {obj &&
                        obj.packages.map((c, index) => {
                          if (index <= 2) {
                            return (
                              <div
                                className="col-sm-6 col-lg-4 col-xl-4 ps-0 pe-0 mb-3"
                                key={index}>
                                <div className="card p-3 shadow me-sm-4">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="ellipsis fw-bold">{c.name}</h6>
                                    {/* <div className="ms-1">
                                <span className="tslang colorblue border p-1">
                                  {c?.language?.abbreviation}
                                </span>
                              </div> */}
                                    <div className="testserieslangimg">
                                      <img
                                        className="w-50 position-absolute bgwhite"
                                        src={c?.language?.icon}
                                      />
                                    </div>
                                  </div>

                                  <p className="mb-4 font12">
                                    Unit Test: {c.testCount.unit}, Mock Test: {c.testCount.mock}
                                  </p>
                                  <button
                                    key={index.toString()}
                                    className="btn btn-primary w-75 py-md-1 py-lg-1 font12 mx-auto"
                                    onClick={() => {
                                      CourseDetailsTest(c._id, c.name);
                                    }}>
                                    View Details
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </Fragment>
          );
        })}

        {index > -1 ? (
          <div className="ps-md-0 mb-3  mt-3">
            {courseHome?.map((obj, index) =>
              obj?.type === 2 && obj?.packages ? (
                <div
                  key={index}
                  className="col-sm-12 col-md-12 col-lg-12 "
                  onClick={() => smRoute(obj?.packages[0])}>
                  <div className="studymaterialbgclr customcontainer  studymaterialLectureseriesdiv text-white card border-0 p-3 pt-1 pb-0 d-flex flex-row flex-wrap align-items-center my-md-3 mx-auto boxshadow">
                    <div className="col-12 col-md-8 cursor">
                      <h6 className="font12 fw-bold mt-2">{obj?.name}</h6>
                      <p className="font12 mt-1">
                        Best {examTypeName} {baseCourseName} Study Material Package in Printed Book
                        Version
                      </p>
                    </div>
                    <div className="col-12 col-md-4 animateImges">
                      <Lottie options={study[0].defaultOptions} height={110} width={165} />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )
            )}
          </div>
        ) : (
          ''
        )}

        {freeVideo?.length != 0 ? (
          <div className="customcontainer py-4 boxshadow  p-3 bgwhite">
            <div className="d-flex justify-content-between cursor">
              <h3 className="fw-bold ms-md-4">Free Lectures</h3>
              <MoreBtn gotoLink={allFreeLectures} />
            </div>
            <div className="mt-4">
              <div className="row">
                {freeVideo &&
                  freeVideo?.map((c, indexLecture) => {
                    if ((c !== undefined) & (indexLecture <= 2)) {
                      return <LectureCard key={c?._id} data={c} />;
                    }
                  })}
              </div>
            </div>
          </div>
        ) : null}
        <div
          className="position-fixed bg-light boxshadow rounded-circle"
          style={{ right: '20px', bottom: '30px' }}
          onClick={Click}>
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </div>
        <TalktoExpert open={expertopen} handleClose={Close} />
        {installmentData ? (
          <InstallmentPopUp
            open={open}
            data={installmentData}
            handleClose={handleClose}
            handleClick={handleClick}
          />
        ) : null}
      </div>
      <CourseList open={openCourse} handleClose={closePopup} />
      {<BbbVideo open={openVideo} handleClose={handleBbbVideoClose} />}
      {<BbbVideoPaidTiles open={openVideoPaidTiles} handleClose={handleBbbVideoPaidTilesClose} />}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={openLive}
        onClose={() => setOpenLive(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={openLive}>
          <div className="modeldiv p-md-4 py-md-4 talktoexpertModel">
            <div className="m-2 insttxtDiv bgwhite">
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon
                  className="colorblue cursor"
                  onClick={() => setOpenLive(false)}
                />
              </div>
              <div className="col justify-content-center  py-5 ">
                <Lottie options={defaultOptionsLive} height={100} width={100}></Lottie>
                <p className="text-center">
                  <b>Please wait for a while , lecture will start soon</b>
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={openSubscribe}
        onClose={() => setOpenSubscribe(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={openSubscribe}>
          <div className="modeldiv p-md-4 py-md-4 talktoexpertModel">
            <div className="m-2 insttxtDiv bgwhite">
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon
                  className="colorblue cursor"
                  onClick={() => setOpenSubscribe(false)}
                />
              </div>
              <div className="col justify-content-center   ">
                <Lottie options={defaultOptionsSubscribed} height={200} width={200}></Lottie>
                <p className="text-center">
                  <b>{`Course not started yet, Please wait upto ${date}`}</b>
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <PredictionPopup open={openPrediction} handleClose={closePrediction} />
    </div>
  );
}

export default PaidDashboard;
