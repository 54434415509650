import React, { useEffect, useState } from 'react';
import { LocalImages } from '../Constant';
import '../Assets/CSS/signIn.css';
import Phoneno from '../Component/phoneno';
import Submitbtn from '../Component/submitBtn';
import { sendOTP, getCountry } from '../Redux/Actions/AuthAction';
import service from '../Constant/service';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

function SignIn(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [style, setStyle] = useState(1);
  const [number, setNumber] = useState('');
  const [numberError, setNumberError] = useState('');
  const [countryData, setCountryData] = useState([]);
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState({ _id: '', name: '', callingCode: '', flag: '' });
  const [blank, setblank] = useState(true);
  const { ACCESS_TOKEN_NEW } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));

  //const classRoom = location.state?.classRoom;
  const IsclassRoom = location?.state?.IsclassRoom ? location.state.IsclassRoom : false;

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const navigatelink = (e) => {
    window.location.href = 'UserVerification';
  };
  const PrivacyPolicy = () => {
    window.location.href = 'privacy-policy';
  };

  const Terms = () => {
    window.location.href = 'terms-conditions';
  };
  const homepage = (e) => {
    window.location.href = '/';
  };

  useEffect(() => {
    //document.title = props.title;
    if (ACCESS_TOKEN_NEW) {
      window.location.href = '/';
    }
  }, []);

  useEffect(() => {
    if (number?.length == 10) {
      setblank(false);
    } else {
      setblank(true);
    }
  }, [number]);

  const handleNavigate = () => {
    navigate('/OTPVerification', {
      state: { mobileNo: number, countryCode: countryCode, isVerified: true, userId: '' }
    });
  };
  function allnumeric(inputtxt) {
    var numbers = /^[0-9]+$/;
    if (inputtxt.match(numbers)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input numeric characters only'));
      return false;
    }
  }
  const checkDisable = () =>
    numberError !== '' ||
    number === '' ||
    countryCode._id === '' ||
    !allnumeric(number) ||
    number.length !== 10;

  const handleOnChangePhoneText = (prevPhoneInputText) => {
    const numbersNew = /^[0-9]+$/;
    if (!prevPhoneInputText.target.value) setNumber(prevPhoneInputText.target.value);
    else if (prevPhoneInputText.target.value.match(numbersNew))
      setNumber(prevPhoneInputText.target.value);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
  };

  const onPressSendOTP = () => {
    if (!checkDisable()) {
      setStyle(0.7);
      const payload = {
        mobileNo: number,
        countryId: countryCode._id,
        deviceType: 'web'
      };

      dispatch(
        sendOTP(
          payload,
          (response) => {
            if (response?.statusCode === service.status_code.success) {
              dispatch(showSuccessSnackbar(response?.msg));

              navigate('/OTPVerification', {
                state: {
                  mobileNo: number,
                  countryCode: countryCode,
                  isVerified: true,
                  userId: '',
                  IsclassRoom
                }
              });
            } else {
              dispatch(showErrorSnackbar('wentWrong'));
            }
          },
          (error) => {
            if (error?.response?.data?.msg) dispatch(showErrorSnackbar(error.response.data.msg));
            else dispatch(showErrorSnackbar('Please Enter Valid Mobile Number'));
          }
        )
      );
    } else {
      dispatch(showErrorSnackbar('please fill mobile number'));
    }
  };

  useEffect(() => {
    dispatch(
      getCountry(
        (data) => {
          setCountryData(data);
          const filteredCountries = data.filter((item) => {
            const countryCodeNew = item.callingCode.toLowerCase();
            return countryCodeNew.indexOf('+91') > -1;
          });
          setCountryCode(filteredCountries[0]);
          setNumber('');
        },
        () => {}
      )
    );
  }, []);

  return (
    <div className="bgmob">
      <div className="d-block d-lg-none loginHeader text-start">
        <Helmet>
          <title>IFAS Sign In</title>
          <meta
            name="discription"
            content="IFAS Login: Secure access to your account. Sign in with ease to manage your IFAS services. Streamlined login process for convenience."
          />
        </Helmet>
        <img
          src={LocalImages.logo}
          alt="IFAS Logo"
          className="logo cursor"
          onClick={homepage}
          style={{ width: '120px' }}
        />
      </div>
      <div className="row authDiv">
        <div className="d-none d-lg-block col-lg-7 leftSidediv">
          <div className="loginHeader ">
            <img
              src={LocalImages.logo}
              alt="IFAS Logo"
              className="logo cursor"
              onClick={homepage}
              style={{ width: '120px' }}
            />
          </div>
          <div className="imgDiv">
            <img src={LocalImages.signInimg} alt="signIn" className="leftImg" />
          </div>
        </div>
        <div className="col-lg-5 rightDivPad position-relative">
          <h3 className="text-center colorblue fw-bold boldfont">Welcome to IFAS</h3>
          <p className="text-center colorblue">Let's Get Started</p>
          <p className="text-center txt">To get started, please verify mobile number</p>

          <div className="mobileField mt-5 px-2">
            <form onSubmit={handleSubmit}>
              <label className="mb-2 ">Mobile number</label>

              <Phoneno
                setCountryCode={(value) => setCountryCode(value)}
                countryCode={countryCode}
                countryData={countryData}
                placeholder="Enter Your Phone Number"
                maxLength="10"
                minLength={10}
                value={number}
                handleOnChangePhoneText={handleOnChangePhoneText}
              />

              <div className="mt-4 text-center bgwhite">
                {/* <Submitbtn name="Send OTP" mobileNo = {inputText}/> */}
                <Submitbtn name="Send OTP" disabled={blank} onPress={onPressSendOTP} />
              </div>
            </form>
          </div>
          <p className="text-center mb-2 mt-4">
            Have an account with a <span className="fw-bold">Username?</span>
          </p>

          <p className="text-center fw-bold colorblue">
            <NavLink className="d-inline-block" onClick={navigatelink}>
              Click Here
            </NavLink>
          </p>
          <div className="mb-o tncdiv bottom-0 start-0 end-0 m-auto">
            <p className="text-center ">
              <span className="colorblue cursor" onClick={Terms}>
                T&C
              </span>{' '}
              and
              <span className="colorblue cursor" onClick={PrivacyPolicy}>
                {' '}
                Privacy Policy
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
