import React, { useEffect, useState } from 'react';

import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, getCartList } from '../Redux/Actions/homeAction';
import { updateSessionValues } from '../Redux/Actions/sessionAction';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import InputField from '../Component/inputField';
import CustomDropdown from '../Component/customDropdown';
import {
  getCategoriesPredictor,
  getPredictRank,
  getSubjectPredictor
} from '../Redux/Actions/AuthAction';
import { LocalImages } from '../Constant';
import Lottie from 'react-lottie';
import congratulation from '../Assets/Images/JSON/congratulation.json';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';

function PredictionPopup(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subjectData, setSubjectData] = useState([]);
  const [subject, setSubject] = useState({ _id: '', name: '' });
  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState({ _id: '', name: '' });
  const [isSubmit, setisSubmit] = useState(false);
  const [mobno, setmobno] = useState('');
  const [percentile, setpercentile] = useState('');
  const [rollNo, setrollNo] = useState('');
  const [status, setstatus] = useState();
  const [Rank, setRank] = useState('');
  const [name, setname] = useState('');
  const { firstName, lastName, mobileNo } = useSelector((state) => ({
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    mobileNo: state?.AuthReducer?.mobileNo
  }));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: congratulation,
    renderer: 'svg'
  };

  const handleClose = () => {
    clearData();
    props.handleClose();
    setisSubmit(false);
  };

  useEffect(() => {
    dispatch(
      getSubjectPredictor(
        (data) => {
          setSubjectData(data);
        },
        () => {}
      )
    );
    dispatch(
      getCategoriesPredictor(
        (data) => {
          setCategoryData(data);
        },
        () => {}
      )
    );
  }, []);

  const submitData = () => {
    const postdata = {
      studentName: firstName + ' ' + lastName,
      mobileNo: mobileNo,
      percentile: percentile,
      rollNo: rollNo,
      rankCategoryId: category._id,
      rankSubjectId: subject._id
    };
    if (parseFloat(percentile) > 0) {
      // if(parseFloat(percentile)>0 && parseFloat(percentile) <=100){
      dispatch(
        getPredictRank(
          postdata,
          (res) => {
            // if (res.data?.rank >= 0) {
            setRank(res.data.rank);
            // }
            setstatus(res.data.status);
            setisSubmit(true);
            // clearData()
          },
          () => {}
        )
      );
    } else {
      dispatch(showErrorSnackbar('Percentile Should be greater than 0 and less than 100'));
      // dispatch(showErrorSnackbar('Percentile Should be greater than 0 and less than 100'));
    }
  };
  const clearData = () => {
    setSubject({ _id: '', name: '' });
    setCategory({ _id: '', name: '' });
    setrollNo('');
    setpercentile('');
    setmobno('');
    setname('');
    setRank('');
    setstatus('');
  };
  const handleMobno = (prevPhoneInputText) => {
    const numbersNew = /^[0-9]+$/;
    if (!prevPhoneInputText) setmobno(prevPhoneInputText);
    else if (prevPhoneInputText.match(numbersNew)) setmobno(prevPhoneInputText);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
  };

  const validations = () => {
    return (
      percentile === '' ||
      rollNo === '' ||
      rollNo.length < 10 ||
      category._id === '' ||
      subject._id === ''
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="modeldiv p-4">
          <div className="px-4 position-absolute end-0" style={{ zIndex: '1' }}>
            <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
          </div>

          {isSubmit ? (
            <div className="justify-content-center py-5 ">
              {status != '4' ? (
                <Lottie options={defaultOptions} height={200} width={300}></Lottie>
              ) : null}
              {status == '1' || status == '2' ? (
                <h4 className="boldfont text-center">
                  You're Qualified For {status == '1' ? 'JRF' : 'LS'}
                </h4>
              ) : null}
              {/* {status==="3"? <h4 className='boldfont text-center mb-4'>"You did it!!!"</h4>:null} */}
              {status == '4' ? (
                <h4 className="boldfont text-center mb-4">
                  "There are still chances miracles happen at any time."
                </h4>
              ) : null}
              {Rank ? (
                <h4 className="boldfont text-center">Your Rank is {Rank == '0' ? '1' : Rank}</h4>
              ) : null}
            </div>
          ) : (
            <div className="row ps-0">
              <div className="col-md-4 d-flex align-items-end">
                <img src={LocalImages.predictimg1} className="predictLeftimg w-100" />
              </div>
              <div className="col-md-4">
                <h5 className="colorblue text-center fw-bold">
                  Predict Your Rank & Selection Chances
                </h5>
                <h5 className="text-center fw-bold">Input Your Exam Details</h5>
                <div className="mt-4">
                  {/* <div>
                    <label className="mb-2">Enter your Name</label>
                    <InputField
                      type="text"
                      placeholder="Enter your Name"
                      value={name}
                      setInputText={setname}
                    />
                  </div> */}
                  {/* <div>
                    <label className="mb-2">Enter your phone number</label>
                    <InputField
                      type="text"
                      placeholder="Enter your Phone number"
                      value={mobno}
                      setInputText={handleMobno}
                      maxLength="10"
                    />
                  </div> */}
                  <div>
                    <label className="mb-2">Enter your percentile or marks</label>
                    <InputField
                      type="text"
                      placeholder="Enter your percentile or marks"
                      value={percentile}
                      setInputText={setpercentile}
                      maxLength="5"
                    />
                  </div>
                  <div>
                    <label className="mb-2">Enter your roll number</label>
                    <InputField
                      type="text"
                      placeholder="Enter your roll number"
                      value={rollNo}
                      setInputText={setrollNo}
                      maxLength="10"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="mb-2 ">Select Category</label>
                    <CustomDropdown
                      setInputText={(value) => setCategory(value)}
                      value={category?._id}
                      lableName="Select Category"
                      options={categoryData}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="mb-2 ">Select Subject</label>
                    <CustomDropdown
                      setInputText={(value) => setSubject(value)}
                      Value={subject?._id}
                      lableName="Select Subject"
                      options={subjectData}
                    />
                  </div>
                  <div className="my-4 text-center">
                    <button
                      type="button"
                      className={`btn px-5 rounded-pill ${
                        validations() ? 'btn-secondary' : 'btn-primary'
                      }`}
                      onClick={submitData}
                      disabled={validations()}>
                      Predict My Rank
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-none d-sm-block col-md-4">
                <img
                  src={LocalImages.predictimg2}
                  className="predictRightimg img-fluid position-absolute top-1 end-0"
                />
              </div>
            </div>
          )}
          <div className="row ps-0">
            <div className="col-md-10 text-center mx-auto" style={{ fontSize: '0.9em' }}>
              <span className="text-danger">Disclaimer *</span> The Rank Prediction will show your
              possible or probable rank of the given sources of info. It must be noted that the rank
              is demonstrative and indicative only, whereas original rank announced by the exam
              conducting body May totally differ from rank predicted here.
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default PredictionPopup;
