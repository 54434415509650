import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
export default function PageNotFound() {
  return (
    <section className="page-not-found">
      <div className="container">
        <div className="text">
          <h1>Oops!</h1>
          <p>We can't seem to find the page you're looking for.</p>
          <h2>Error Code: 404</h2>
          <br></br>
          <Link to="/home">
            <button className="backBtn" type="button">
              Back to Home
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}
