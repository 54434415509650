import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchIcon from '@mui/icons-material/Search';
import InputField from '../Component/inputField';
function Phoneno(props) {
  const [countrycodeData, setCountries] = useState(props.countryData);
  const [inputText, setInputText] = useState('');
  const [show, setShow] = useState(false);
  const country = () => {
    setShow(!show);
    setInputText('');
    setCountries(props.countryData);
  };
  const handleClose = () => {
    setShow(false);
    setInputText('');
    setCountries(props.countryData);
  };
  const onSearchTextChange = (value) => {
    const filteredCountries = countrycodeData.filter((item) => {
      const countryName = item.name.toLowerCase();
      const countryCodeNew = item.callingCode.toLowerCase();
      const queryString = value.toLowerCase();
      return countryName.indexOf(queryString) > -1 || countryCodeNew.indexOf(queryString) > -1;
    });

    setInputText(value);
    setCountries(filteredCountries);
  };

  return (
    <div className="position-relative">
      {/* <span className="indcode boldfont cursor" onClick={country}>
        {props.countryCode.callingCode}{' '}
        {show ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />}
      </span>
      {show ? (
        <div className="boxshadow position-absolute p-3 rounded font12 bgwhite countrypopup">
          <div className="d-flex justify-content-between">
            <p className="colorblue fw-bold">Select a Country</p>
            <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
          </div>
          <div className="position-relative d-flex W-100 inputpadding">
            <SearchIcon className="position-absolute text-secondary m-2" />
            <InputField
              value={inputText}
              setInputText={(value) => onSearchTextChange(value)}
              className="w-100"
            />
          </div>
          <div className="row font11">
            <div className="col-sm-8 countryflow">
              {countrycodeData.map((c) => (
                <div
                  onClick={() => {
                    props.setCountryCode(c);
                    setShow(false);
                    setInputText('');
                    setCountries(props.countryData);
                  }}
                  className="row d-flex cursor">
                  <div className="col-3">
                    <img src={c.flag} className="m-1 img-fluid" />{' '}
                  </div>
                  <div
                    className={
                      props.countryCode.callingCode == c.callingCode ? 'colorblue col-3' : 'col-3'
                    }>
                    {' '}
                    {c.callingCode}
                  </div>
                  <div
                    className={
                      props.countryCode.callingCode == c.callingCode ? 'colorblue col-6' : 'col-6'
                    }>
                    {' '}
                    {c.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null} */}
       <span className="indcodesign fw600 cursor me-1">
        + 91
        {show ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />}
      </span>
      <input
        type="text"
        className="form-control phoneno required"
        placeholder={props.placeholder}
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
        id="phone"
        minLength="10"
        maxLength="10"
        onChange={(e) => props.handleOnChangePhoneText(e)}
        value={props.value}
      />
    </div>
  );
}

export default Phoneno;
