export default {
  tifrImg: require('../Assets/Images/Home/tifr.png'),
  logo: require('../Assets/Images/Home/logo.png'),
  logoCircle: require('../Assets/Images/Home/logo1.png'),
  kcimg: require('../Assets/Images/Home/KC-Sir-Web-Banner.webp'),
  onlineClassImg: require('../Assets/Images/Home/onlineClass.png'),
  classroomImg: require('../Assets/Images/Home/classroom-coaching.png'),
  studymaterialImg: require('../Assets/Images/Home/study-Materials.png'),
  testseriesImg: require('../Assets/Images/Home/test-series.png'),
  csirImg: require('../Assets/Images/Home/CSIR-NET.png'),
  gateImg: require('../Assets/Images/Home/gate.png'),
  aptitudeImg: require('../Assets/Images/Home/Aptitude.png'),
  barcImg: require('../Assets/Images/Home/BARC.png'),
  bhuImg: require('../Assets/Images/Home/BHU.png'),
  cucetImg: require('../Assets/Images/Home/CUCET.png'),
  bdtImg: require('../Assets/Images/Home/DBT.png'),
  duImg: require('../Assets/Images/Home/DU.png'),
  gatbImg: require('../Assets/Images/Home/GAT-B.png'),
  gpscImg: require('../Assets/Images/Home/GOA-PSC.png'),
  icmrImg: require('../Assets/Images/Home/ICMR.png'),
  iitjamImg: require('../Assets/Images/Home/IIT-JAM.png'),
  jestImg: require('../Assets/Images/Home/JEST.png'),
  jnuImg: require('../Assets/Images/Home/JNU.png'),
  mhsetImg: require('../Assets/Images/Home/MH-SET.png'),
  gjsetimg: require('../Assets/Images/Home/gjSet.jpg'),
  wbsetimg: require('../Assets/Images/Home/wbSet.jpg'),
  mpscImg: require('../Assets/Images/Home/MPSC.png'),
  nbhmImg: require('../Assets/Images/Home/NBHM.png'),
  rpscImg: require('../Assets/Images/Home/RPSC.png'),
  tnsetImg: require('../Assets/Images/Home/TN-SET.png'),
  ugcnetImg: require('../Assets/Images/Home/UGC-NET.png'),
  uphescImg: require('../Assets/Images/Home/UPHESC.png'),
  uppscImg: require('../Assets/Images/Home/uppsc.png'),
  goapscImg: require('../Assets/Images/Home/goapsc.png'),
  HPSCImg: require('../Assets/Images/Home/HPSC.png'),
  MPPSCImg: require('../Assets/Images/Home/MPPSC.jpg'),
  jkpscImg: require('../Assets/Images/Home/jkpsc.jpg'),
  img: require('../Assets/Images/Home/img.png'),
  appAchievementvideo: require('../Assets/Video/IFAS-Achievements.mp4'),
  liveLearning: require('../Assets/Images/Home/live-learning.png'),
  cities: require('../Assets/Images/Home/cities.png'),
  happyStudent: require('../Assets/Images/Home/happy-students.png'),
  bestresult: require('../Assets/Images/Home/best-result.png'),
  rating: require('../Assets/Images/Home/rating.png'),
  silverbtn: require('../Assets/Images/Home/silverbtn.png'),
  cart: require('../Assets/Images/Dashboard/cart.png'),
  img1: require('../Assets/Images/Dashboard/img1.png'),
  img2: require('../Assets/Images/Dashboard/img2.png'),
  liveimg: require('../Assets/Images/Dashboard/liveimg.png'),
  downarrow: require('../Assets/Images/Dashboard/downarrow.png'),
  ulpoad: require('../Assets/Images/Help/upload-image.png'),
  edit: require('../Assets/Images/LiveLectureVideo/edit-grey.png'),
  examdate1: require('../Assets/Images/Learn/img1.png'),
  examdate2: require('../Assets/Images/Learn/img2.png'),
  examdate3: require('../Assets/Images/Learn/img3.png'),
  examdate4: require('../Assets/Images/Learn/img4.png'),
  examdate5: require('../Assets/Images/Learn/img5.png'),
  examdate6: require('../Assets/Images/Learn/img5.png'),
  duration: require('../Assets/Images/TestExam/duration.png'),
  language: require('../Assets/Images/TestExam/language.png'),
  maxMarks: require('../Assets/Images/TestExam/maximum-Marks.png'),
  totalQuestions: require('../Assets/Images/TestExam/totalQuestions.png'),
  bannerimg: require('../Assets/Images/banner.jpg'),
  include: require('../Assets/Images/CourseDetails/include.png'),
  exclude: require('../Assets/Images/CourseDetails/exclude.png'),
  ebook: require('../Assets/Images/CourseDetails/ebook.png'),
  live: require('../Assets/Images/CourseDetails/live.png'),
  mocktest: require('../Assets/Images/CourseDetails/mocktest.png'),
  practicebook: require('../Assets/Images/CourseDetails/practicebook.png'),
  pyq: require('../Assets/Images/CourseDetails/pyq.png'),
  recorded: require('../Assets/Images/CourseDetails/recorded.png'),
  teacherhandout: require('../Assets/Images/CourseDetails/teacherhandout.png'),
  theory: require('../Assets/Images/CourseDetails/theory.png'),
  unitTest: require('../Assets/Images/CourseDetails/unitTest.png'),
  offerimg: require('../Assets/Images/CourseDetails/offer.png'),
  pdff: require('../Assets/Images/CourseDetails/pdf.png'),
  createAccountimg: require('../Assets/Images/SignIn/createAccountimg.png'),
  notVisited: require('../Assets/Images/TestExam/notVisited.png'),
  notAnswered: require('../Assets/Images/TestExam/notAnswered.png'),
  saveAns: require('../Assets/Images/TestExam/saveAns.png'),
  marked: require('../Assets/Images/TestExam/marked.png'),
  marknsave: require('../Assets/Images/TestExam/marknsave.png'),
  courseSupport: require('../Assets/Images/Help/courseSupport.png'),
  techSupport: require('../Assets/Images/Help/techSupport.png'),
  lecture: require('../Assets/Images/Learn/lectures.png'),
  onlineTest: require('../Assets/Images/Learn/onlinetest.png'),
  allAbout: require('../Assets/Images/Learn/allAbout.png'),
  about: require('../Assets/Images/LiveLectureVideo/about.png'),
  dppNew: require('../Assets/Images/LiveLectureVideo/dppNew.png'),
  teacherHandout: require('../Assets/Images/LiveLectureVideo/teacherHandout.png'),
  dpp: require('../Assets/Images/LiveLectureVideo/dpp.png'),
  dppFull: require('../Assets/Images/LiveLectureVideo/dppFull.png'),
  rate: require('../Assets/Images/LiveLectureVideo/rate.png'),
  mobVerfiimg: require('../Assets/Images/SignIn/mobVerification.png'),
  otpimg: require('../Assets/Images/SignIn/mobOTPVerificationimg.png'),
  deleteimg: require('../Assets/Images/Learn/Delete.png'),
  ProfileImg: require('../Assets/Images/Dashboard/Dummy Profile.png'),
  bannerimg: require('../Assets/Images/Dashboard/liveimg.png'),
  forwardarrow: require('../Assets/Images/Dashboard/forward_arrow.png'),
  forwardarrow1: require('../Assets/Images/Dashboard/forward.png'),
  otperrorimg: require('../Assets/Images/SignIn/otperror.png'),
  otpsuccessimg: require('../Assets/Images/SignIn/otpsuccess.png'),
  courselogo: require('../Assets/Images/Home/CSIR-NET.png'),
  signInimg: require('../Assets/Images/SignIn/signInbg.png'),
  userVerfyimg: require('../Assets/Images/SignIn/existingUser.png'),
  durationImagePath: require('../Assets/Images/TestExam/duration.png'),
  languageImagePath: require('../Assets/Images/TestExam/language.png'),
  maxMarksImagePath: require('../Assets/Images/TestExam/maximum-Marks.png'),
  totalQuestionsImagePath: require('../Assets/Images/TestExam/totalQuestions.png'),
  videoicon: require('../Assets/Images/Learn/videoicon.png'),
  book1: require('../Assets/Images/books/1.jpg'),
  savebtn: require('../Assets/Images/TestExam/save_Next.png'),
  marknsavebtnbtn: require('../Assets/Images/TestExam/mark-Save.png'),
  markreviewbtn: require('../Assets/Images/TestExam/mark-for-Review.png'),
  clearbtn: require('../Assets/Images/TestExam/clearResponse.png'),
  listbtn: require('../Assets/Images/TestExam/listView.png'),
  clogo: require('../Assets/Images/Help/CSIR-NET.png'),
  downarrowSmall: require('../Assets/Images/Home/downarrow-small.png'),
  aboutifas: require('../Assets/Images/Home/about.png'),
  strock: require('../Assets/Images/Home/Your-Dreamsstroke.png'),
  courseStroke: require('../Assets/Images/Home/Coursesstroke.png'),
  truestByStroke: require('../Assets/Images/Home/Trusted-by-Students.png'),
  ifasachievmentStroke: require('../Assets/Images/Home/Meet-IFAS-Expertsstroke.png'),
  ramikaSingla: require('../Assets/Images/topperList/R1.png'),
  AjayDangi: require('../Assets/Images/topperList/R2.png'),
  shubhamoy: require('../Assets/Images/topperList/R3.png'),
  artiRathore: require('../Assets/Images/topperList/R4.png'),
  royalPradhan: require('../Assets/Images/topperList/RoyalPradhan.png'),
  saireetMishra: require('../Assets/Images/topperList/SaireetMishra.png'),
  KhushbuPilania: require('../Assets/Images/topperList/KhushbuPilania.png'),
  rohanChowdhary: require('../Assets/Images/topperList/RohanChowdhury.png'),
  aviralAgarwal: require('../Assets/Images/topperList/AviralAgarwal.png'),
  ayushiSrivastava: require('../Assets/Images/topperList/AyushiSrivastava.png'),
  saileshS: require('../Assets/Images/topperList/SaileshS.png'),
  minhaazSuhail: require('../Assets/Images/topperList/MinhaazSuhail.png'),
  ashutoshVasisth: require('../Assets/Images/topperList/ASHUTOSH-VASISTH-AIR-1.png'),
  parthiPratim: require('../Assets/Images/topperList/PARTHO-PRATIM-DAS-AIR-1.png'),
  manasKundu: require('../Assets/Images/topperList/MANAS-KUNDU-AIR-1.png'),
  baivabiBhattacharya: require('../Assets/Images/topperList/BAIVABI-BHATTACHARYA-AIR-2.png'),
  komalSharma: require('../Assets/Images/topperList/Komal Sharma Rank1.png'),
  aartiPaliwal: require('../Assets/Images/topperList/Aarti Paliwal Rank2.png'),
  kavitaBhati: require('../Assets/Images/topperList/Kavita Bhati Rank3.png'),
  rashmiKhedar: require('../Assets/Images/topperList/RASHMI KHEDAR Rank4.png'),
  shaliniSinha: require('../Assets/Images/topperList/Shalini Sinha Rank1.png'),
  shreyanshiRatnakar: require('../Assets/Images/topperList/Shreyanshi Ratnakar Rank1.png'),
  manojKumar: require('../Assets/Images/topperList/MANOJ KUMAR.png'),
  sabashireen: require('../Assets/Images/topperList/downloadSabaShireen.png'),
  sonugupya: require('../Assets/Images/topperList/SONU GUPYA.png'),
  vaishaliSharma: require('../Assets/Images/topperList/VAISHALI SHARMA.png'),
  vijaySuvarnkar: require('../Assets/Images/topperList/VijaySuvarnkar.png'),
  CopyLink: require('../Assets/Images/copylink.png'),
  AboutUsBanner: require('../Assets/Images/AboutUs/About-Us-Banner-Image.png'),
  QuoteSymbol: require('../Assets/Images/AboutUs/quote-icon.png'),
  OurJourney: require('../Assets/Images/AboutUs/ourJourney.png'),
  WAW: require('../Assets/Images/AboutUs/waw.png'),
  WAWGROUP: require('../Assets/Images/AboutUs/wawgroup.png'),
  WAW1: require('../Assets/Images/AboutUs/img1.png'),
  WAW2: require('../Assets/Images/AboutUs/img2.png'),
  WAW3: require('../Assets/Images/AboutUs/img3.png'),
  WAW4: require('../Assets/Images/AboutUs/img4.png'),
  WAW5: require('../Assets/Images/AboutUs/img5.png'),
  WAW6: require('../Assets/Images/AboutUs/img6.png'),
  WAW7: require('../Assets/Images/AboutUs/img7.png'),
  WAW8: require('../Assets/Images/AboutUs/img8.png'),
  Vision: require('../Assets/Images/AboutUs/vision.png'),
  speed: require('../Assets/Images/LiveLectureVideo/speed.png'),
  favicon: require('../Assets/Images/Menu/favicon.png'),
  videos: require('../Assets/Images/Learn/Video.png'),
  dpp: require('../Assets/Images/Learn/dpp.png'),
  modules: require('../Assets/Images/Learn/module.png'),
  tests: require('../Assets/Images/Learn/test.png'),
  user: require('../Assets/Images/user.png'),
  ls_classroom: require('../Assets/Images/ClassroomDetails/csir-net-life-science-classroom-coaching.webp'),
  ps_classroom: require('../Assets/Images/ClassroomDetails/csir-net-physical-science-classroom-coaching.webp'),
  ms_classroom: require('../Assets/Images/ClassroomDetails/csir-net-mathematical-science-classroom-coaching.webp'),
  cs_classroom: require('../Assets/Images/ClassroomDetails/csir-net-chemical-science-classroom-coaching.webp'),

  doubtsolving: require('../Assets/Images/ClassroomDetails/doubtSolving.png'),
  libraryFacility: require('../Assets/Images/ClassroomDetails/libraryFacility.png'),
  mentorship: require('../Assets/Images/ClassroomDetails/mentorship.png'),
  onlineTestSeries: require('../Assets/Images/ClassroomDetails/onlineTestSeries.png'),
  studymaterial: require('../Assets/Images/ClassroomDetails/studymaterial.png'),
  testclass: require('../Assets/Images/ClassroomDetails/testclass.png'),
  batchStart: require('../Assets/Images/ClassroomDetails/batchStart.png'),
  duretion: require('../Assets/Images/ClassroomDetails/duretion.png'),
  Fees: require('../Assets/Images/ClassroomDetails/Fees.png'),
  location: require('../Assets/Images/ClassroomDetails/location.png'),
  Vikkymahto: require('../Assets/Images/Home/Vikky Mahtoh.png'),
  DebaparnaDutta: require('../Assets/Images/Home/Debaparna Dutta.png'),
  DharmendraYadav: require('../Assets/Images/Home/Dharmendra Yadav.png'),
  GarimaPandey: require('../Assets/Images/Home/Garima Pandey.png'),
  JanviGarg: require('../Assets/Images/Home/Janvi Garg.png'),
  JayashreeLadke: require('../Assets/Images/Home/Jayashree Ladke.png'),
  MadhuKabra: require('../Assets/Images/Home/Madhu Kabra.png'),
  MridushreeBasak: require('../Assets/Images/Home/Mridushree Basak.png'),
  NutanG: require('../Assets/Images/Home/Nutan G.png'),
  OmkarKinkar: require('../Assets/Images/Home/Omkar Kinkar.png'),
  RohitNalawade: require('../Assets/Images/Home/Rohit Nalawade.png'),
  AjoyPaul: require('../Assets/Images/Home/Ajoy Paul.png'),
  ShwetaChaudhary: require('../Assets/Images/Home/Shweta Chaudhary.png'),
  Seeyouhere: require('../Assets/Images/Home/seeyouhere.png'),

  /************************************* TOPPERS LIST****************************************/
  //CSIT NET
  Aaiyas_Mujawar: require('../Assets/Images/topperList/CSIRNET/Aaiyas_Mujawar.png'),
  Ajoy_Paul: require('../Assets/Images/topperList/CSIRNET/Ajoy_Paul.png'),
  Bhushan_Choudhary: require('../Assets/Images/topperList/CSIRNET/Bhushan_Choudhary.png'),
  Debaparna_Dutta: require('../Assets/Images/topperList/CSIRNET/Debaparna_Dutta.png'),
  Dharmendra_Yadav: require('../Assets/Images/topperList/CSIRNET/Dharmendra_Yadav.png'),
  Garima_Pandey: require('../Assets/Images/topperList/CSIRNET/Garima_Pandey.png'),
  Jayashree_Ladke: require('../Assets/Images/topperList/CSIRNET/Jayashree_Ladke.png'),
  Jhanvi_Garg: require('../Assets/Images/topperList/CSIRNET/Jhanvi_Garg.png'),
  Madhu_Kabra: require('../Assets/Images/topperList/CSIRNET/Madhu_Kabra.png'),
  Mridushree_Basak: require('../Assets/Images/topperList/CSIRNET/Mridushree_Basak.png'),
  Nutan_G: require('../Assets/Images/topperList/CSIRNET/Nutan_G.png'),
  Omkar_Kinkar: require('../Assets/Images/topperList/CSIRNET/Omkar_Kinkar.png'),
  Rohit_Nalawade: require('../Assets/Images/topperList/CSIRNET/Rohit_Nalawade.png'),
  Sagar_Jawla: require('../Assets/Images/topperList/CSIRNET/Sagar_Jawla.png'),
  Shweta_Chaudhary: require('../Assets/Images/topperList/CSIRNET/Shweta_Chaudhary.png'),
  Vikky_Mahto: require('../Assets/Images/topperList/CSIRNET/Vikky_Mahto.png'),
  Vipul_Namewar: require('../Assets/Images/topperList/CSIRNET/Vipul_Namewar.png'),
  //GATE
  KhushbuPilania: require('../Assets/Images/topperList/GATE/KhushbuPilania.png'),
  RohanChowdhury: require('../Assets/Images/topperList/GATE/RohanChowdhury.png'),
  RoyalPradhan: require('../Assets/Images/topperList/GATE/RoyalPradhan.png'),
  SaireetMishra: require('../Assets/Images/topperList/GATE/SaireetMishra.png'),
  //UGC NET
  MANOJKUMAR: require('../Assets/Images/topperList/UGC-NET/MANOJ KUMAR.png'),
  SabaShireen: require('../Assets/Images/topperList/UGC-NET/Saba Shireen.png'),
  SAMRADNIPINGALE: require('../Assets/Images/topperList/UGC-NET/SAMRADNI PINGALE.png'),
  SANDEEPKUMAR: require('../Assets/Images/topperList/UGC-NET/SANDEEP KUMAR.png'),
  Sanjaychavan: require('../Assets/Images/topperList/UGC-NET/Sanjay chavan.png'),
  SaswatiPattnaik: require('../Assets/Images/topperList/UGC-NET/Saswati Pattnaik.png'),
  SAVITACHOUDHARY: require('../Assets/Images/topperList/UGC-NET/SAVITA CHOUDHARY.png'),
  Shalugupta: require('../Assets/Images/topperList/UGC-NET/Shalu gupta.png'),
  SHEFALIPARASHAR: require('../Assets/Images/topperList/UGC-NET/SHEFALI PARASHAR.png'),
  Shitalmaindale: require('../Assets/Images/topperList/UGC-NET/Shital maindale.png'),
  ShrabontiChatterjee: require('../Assets/Images/topperList/UGC-NET/Shrabonti Chatterjee NET 54.png'),
  ShubhamMeshram: require('../Assets/Images/topperList/UGC-NET/Shubham Meshram 137 ls.png'),
  SHUBHANGIBANSUDE: require('../Assets/Images/topperList/UGC-NET/SHUBHANGI BANSUDE.png'),
  SOMENDRANARAYAN: require('../Assets/Images/topperList/UGC-NET/SOMENDRA NARAYAN.png'),
  SONUGUPYA: require('../Assets/Images/topperList/UGC-NET/SONU GUPYA.png'),
  VAISHALISHARMA: require('../Assets/Images/topperList/UGC-NET/VAISHALI SHARMA.png'),
  SantoshKumardas: require('../Assets/Images/topperList/UGC-NET/Santosh Kumar das.png'),
  //IIT JAM
  AviralAgarwal: require('../Assets/Images/topperList/IITJAM/AviralAgarwal.png'),
  AyushiSrivastava: require('../Assets/Images/topperList/IITJAM/AyushiSrivastava.png'),
  MinhaazSuhail: require('../Assets/Images/topperList/IITJAM/MinhaazSuhail.png'),
  SaileshS: require('../Assets/Images/topperList/IITJAM/SaileshS.png'),
  tifrImg: require('../Assets/Images/Home/tifr.png'),
  //cuet
  cuetlogo: require('../Assets/Images/topperList/CUET/cuet.png'),
  Shalini: require('../Assets/Images/topperList/CUET/Shalini.png'),
  Shreyanshi: require('../Assets/Images/topperList/CUET/Shreyanshi.png'),
  //PSC
  psclogo: require('../Assets/Images/topperList/PSC/PSCTeaching.png'),
  arvindgaur: require('../Assets/Images/topperList/PSC/arvind gaur.png'),
  DEEPIKALODHA: require('../Assets/Images/topperList/PSC/DEEPIKA LODHA.png'),
  DEEPIKAVAISHANV: require('../Assets/Images/topperList/PSC/DEEPIKA VAISHANV.png'),
  JAISHRISHARMA: require('../Assets/Images/topperList/PSC/JAISHRI SHARMA.png'),
  JYOTSANABHATI: require('../Assets/Images/topperList/PSC/JYOTSANA BHATI.png'),
  MANOJKUMAR: require('../Assets/Images/topperList/PSC/MANOJ KUMAR.png'),
  NANDARAM: require('../Assets/Images/topperList/PSC/NANDA RAM.png'),
  NEERAJSHRIMALI: require('../Assets/Images/topperList/PSC/NEERAJ SHRIMALI.png'),
  RAMKISHORE: require('../Assets/Images/topperList/PSC/RAM KISHORE.png'),
  SANDEEPDEVAL: require('../Assets/Images/topperList/PSC/SANDEEP DEVAL.png'),
  SAROJCHOUDHARY: require('../Assets/Images/topperList/PSC/SAROJ CHOUDHARY.png'),
  //MPPSC
  mppsc: require('../Assets/Images/topperList/MPPSC/MPPSC.jpg'),
  KomalSharma: require('../Assets/Images/topperList/MPPSC/1Komal Sharma.png'),
  AartiPaliwal: require('../Assets/Images/topperList/MPPSC/2Aarti Paliwal.png'),
  kavitabhatiya: require('../Assets/Images/topperList/MPPSC/3RPSC_AIR Rank1.png'),
  RASHMIKHEDAR: require('../Assets/Images/topperList/MPPSC/4RASHMI  KHEDAR.png'),
  PRIYANKADHAKA: require('../Assets/Images/topperList/MPPSC/5PRIYANKA DHAKA.png'),
  SANGEETAKUMARI: require('../Assets/Images/topperList/MPPSC/6SANGEETA KUMARI.png'),
  rohitgenwa: require('../Assets/Images/topperList/MPPSC/7rohit genwa.png'),
  TAPESHBHAMI: require('../Assets/Images/topperList/MPPSC/8TAPESH BHAMI.png'),
  SarikaBhura: require('../Assets/Images/topperList/MPPSC/9Sarika Bhura.png'),
  YogitaChoudhary: require('../Assets/Images/topperList/MPPSC/10Yogita Choudhary.png'),
  PAWANKANSOTIYA: require('../Assets/Images/topperList/MPPSC/11 PAWAN KANSOTIYA.png'),
  RohitashSharma: require('../Assets/Images/topperList/MPPSC/12 Rohitash Sharma.png'),
  SumitraJatoliya: require('../Assets/Images/topperList/MPPSC/13 Sumitra Jatoliya.png'),
  //rppsc
  rppsc: require('../Assets/Images/topperList/RPPSC/rpsc.png'),
  //TIFR
  AKASHJYOTI: require('../Assets/Images/topperList/TIFR/AKASHJYOTI_4.png'),
  ARCHIT: require('../Assets/Images/topperList/TIFR/ARCHIT_1.png'),
  DEVACHANDANA: require('../Assets/Images/topperList/TIFR/DEVACHANDANA_9.png'),
  HIRANMOY: require('../Assets/Images/topperList/TIFR/HIRANMOY_8.png'),
  JUNAID: require('../Assets/Images/topperList/TIFR/JUNAID_5.png'),
  SAMRIDDHI: require('../Assets/Images/topperList/TIFR/SAMRIDDHI_2.png'),
  SHUVARGHYA: require('../Assets/Images/topperList/TIFR/SHUVARGHYA_1.png'),
  SIDDHI: require('../Assets/Images/topperList/TIFR/SIDDHI_6.png'),
  SUMIT: require('../Assets/Images/topperList/TIFR/SUMIT_1.png'),
  VarunaArora: require('../Assets/Images/topperList/TIFR/Varuna Arora_7.png'),
  Vinod: require('../Assets/Images/topperList/TIFR/Vinod_3.png'),
  //aptitude
  aptitudeImg: require('../Assets/Images/topperList/TIFR/aptitudeImg.png'),
  AhallyaJaladeep: require('../Assets/Images/topperList/aptitude/Ahallya Jaladeep.png'),
  GourangMaity: require('../Assets/Images/topperList/aptitude/GourangMaity.png'),
  pranavjoshi: require('../Assets/Images/topperList/aptitude/pranav joshi.png'),
  //sarkari
  ssccgl: require('../Assets/Images/topperList/aptitude/ssccgl.png'),
  neet: require('../Assets/Images/topperList/aptitude/neet.png'),
  //SET
  amolatak: require('../Assets/Images/topperList/SET/AMOL ATAK.png'),
  BHARATPANDE: require('../Assets/Images/topperList/SET/BHARAT PANDE.png'),
  JOHNGEORGE: require('../Assets/Images/topperList/SET/JOHN GEORGE.png'),
  JYOTIRANDHAWANE: require('../Assets/Images/topperList/SET/JYOTI RANDHAWANE.png'),
  KHOMESHLANJEWAR: require('../Assets/Images/topperList/SET/KHOMESH LANJEWAR.png'),
  KISHORASABE: require('../Assets/Images/topperList/SET/KISHOR ASABE.png'),
  NIKITALOKHANDE: require('../Assets/Images/topperList/SET/NIKITA LOKHANDE.png'),
  RUPALISHINDE: require('../Assets/Images/topperList/SET/RUPALI SHINDE.png'),
  SHAHAJIPALASKAR: require('../Assets/Images/topperList/SET/SHAHAJI PALASKAR.png'),
  SHIVAJIBAGAL: require('../Assets/Images/topperList/SET/SHIVAJI BAGAL.png'),
  SHIVSHANKARKORE: require('../Assets/Images/topperList/SET/SHIVSHANKAR KORE.png'),
  //GAT B
  ASHUTOSHVASISTH: require('../Assets/Images/topperList/GAT-B/ASHUTOSH-VASISTH-AIR-1.png'),
  MANASKUNDU: require('../Assets/Images/topperList/GAT-B/MANAS-KUNDU-AIR-1.png'),
  PARTHOPRATIM: require('../Assets/Images/topperList/GAT-B/PARTHO-PRATIM-DAS-AIR-1.png'),
  BAIVABIBHATTACHARYA: require('../Assets/Images/topperList/GAT-B/BAIVABI-BHATTACHARYA-AIR-2.png'),
  //icmr
  Mitalipradhan: require('../Assets/Images/topperList/ICMR/Mitali pradhan.png'),
  //DBT
  Darshini: require('../Assets/Images/topperList/DBT/Darshini.png'),
  Kiran: require('../Assets/Images/topperList/DBT/Kiran.png'),
  Kritika: require('../Assets/Images/topperList/DBT/Kritika.png'),
  MAHIMA: require('../Assets/Images/topperList/DBT/MAHIMA.png'),
  MEGHNA: require('../Assets/Images/topperList/DBT/MEGHNA.png'),
  MONIKA: require('../Assets/Images/topperList/DBT/MONIKA.png'),
  PARTHA: require('../Assets/Images/topperList/DBT/PARTHA.png'),
  anisha: require('../Assets/Images/topperList/DBT/anisha.png'),
  arjun: require('../Assets/Images/topperList/DBT/arjun.png'),
  bhagyashree: require('../Assets/Images/topperList/DBT/bhagyashree.png'),
  bindu: require('../Assets/Images/topperList/DBT/bindu.png'),
  chirasmita: require('../Assets/Images/topperList/DBT/chirasmita.png'),
  girish: require('../Assets/Images/topperList/DBT/girish.png'),
  gunjan: require('../Assets/Images/topperList/DBT/gunjan.png'),
  janhavi: require('../Assets/Images/topperList/DBT/janhavi.png'),
  jyojit: require('../Assets/Images/topperList/DBT/jyojit.png'),
  mayur: require('../Assets/Images/topperList/DBT/mayur.png'),
  nisha: require('../Assets/Images/topperList/DBT/nisha.png'),
  //SET TOPPER
  aishwaryaWani: require('../Assets/Images/topperList/SET/aishwaryaWani.png'),
  ajayKaslod: require('../Assets/Images/topperList/SET/ajayKaslod.png'),
  bhushanMilmile: require('../Assets/Images/topperList/SET/bhushanMilmile.png'),
  jeelPatil: require('../Assets/Images/topperList/SET/jeelPatil.png'),
  /****************************Jobs********************** */
  graduate: require('../Assets/Images/Jobs/graduate.png'),
  location: require('../Assets/Images/Jobs/location.png'),
  jobskill: require('../Assets/Images/Jobs/skills.png'),
  suitcase: require('../Assets/Images/Jobs/suitcase.png'),
  wallet: require('../Assets/Images/Jobs/wallet.png'),
  /*******************RPSC LOGO*****************/
  sat: require('../Assets/Images/Home/sat.png'),
  act: require('../Assets/Images/Home/act.png'),
  apti: require('../Assets/Images/Home/aptitudeImg.png'),
  Chem: require('../Assets/Images/Home/csirnetcs.png'),
  maths: require('../Assets/Images/Home/netMath.png'),
  phy: require('../Assets/Images/Home/netPhy.png'),
  sci: require('../Assets/Images/Home/csirnetls.png'),
  zology: require('../Assets/Images/Home/gatebiotech.png'),
  mpset: require('../Assets/Images/Home/mpset.jpg'),
  jkset: require('../Assets/Images/Home/jkset.png'),

  //about us
  AboutUsBannerold: require('../Assets/Images/AboutUs/About Us-Banner.jpg'),
  Ourmission: require('../Assets/Images/AboutUs/Our-Mission.png'),
  Ourvision: require('../Assets/Images/AboutUs/Our-Vision.png'),
  StudentHappyness: require('../Assets/Images/AboutUs/Student-Happyness.png'),
  ThinkBig: require('../Assets/Images/AboutUs/Think-Big.png'),
  Reform: require('../Assets/Images/AboutUs/Reform.png'),
  Nurturing: require('../Assets/Images/AboutUs/Nurturing.png'),
  RespectEveryone: require('../Assets/Images/AboutUs/Respect-Everyone.png'),
  videoicon: require('../Assets/Images/Learn/videoicon.png'),

  //Predict Rank Popup
  predictimg1: require('../Assets/Images/PredictRank/predictimg1.png'),
  predictimg2: require('../Assets/Images/PredictRank/predictimg2.png'),

  //Bussiness card
  bussinessCardKCsir: require('../Assets/Images/BussinessCard/KCSir.jpg'),
  bussinessCardDJsir: require('../Assets/Images/BussinessCard/DigambarSir.png'),
  lurnigo1: require('../Assets/Images/Lurnigo/lurnigo1.png'),
  lurnigo2: require('../Assets/Images/Lurnigo/lurnigo2.png'),
  lurnigo3: require('../Assets/Images/Lurnigo/lurnigo3.png'),
  lurnigo4: require('../Assets/Images/Lurnigo/lurnigo4.png'),
  lurnigo5: require('../Assets/Images/Lurnigo/lurnigo5.png'),
  lurnigo6: require('../Assets/Images/Lurnigo/lurnigo6.png'),
  lurnigo7: require('../Assets/Images/Lurnigo/lurnigo7.png'),
  lurnigo8: require('../Assets/Images/Lurnigo/lurnigo8.png'),
  mpsc_1: require('../Assets/Images/Home/mpsc_1.png'),
  mpsc_2: require('../Assets/Images/Home/mpsc_2.png'),
  mpsc_3: require('../Assets/Images/Home/mpsc_3.png'),
  mpsc_4: require('../Assets/Images/Home/mpsc_4.png'),
  rpsc_grade_1_1: require('../Assets/Images/Home/rpsc_grade_1_1.png'),
  rpsc_grade_1_2: require('../Assets/Images/Home/rpsc_grade_1_2.png'),
  rpsc_grade_1_3: require('../Assets/Images/Home/rpsc_grade_1_3.png')
};
