import React, { useState, useRef, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import '../App.css';

import Header from '../Module/header';
import PriceCard from '../Module/priceCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateHomeValues,
  getCourseDetail,
  addToCart,
  getExtendedCourseDetail,
  getfreeCourseDetail
} from '../Redux/Actions/homeAction';
import CouponCard from '../Module/couponCard';
import BatchTiming from '../Module/batchTiming';
import { updateSubscriptionValues } from '../Redux/Actions/subscriptionPlanAction';
import { updateCourseDetailValues } from '../Redux/Actions/courseDetailAction';
import { useLocation } from 'react-router-dom';
import { LocalImages } from '../Constant';
import YouTubeIframe from 'react-youtube-iframe';
import { updateSessionValues } from '../Redux/Actions/sessionAction';
import LoginErrorPopup from '../Module/loginErrorPopup';
import HeaderHome from '../Module/headerHome';
import moment from 'moment';
import SEODetails from '../Module/metaTitle';
function CourseDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { courseID, courseName } = useParams();
  // const courseID = location?.state?.courseID;
  const baseCoursename = location?.state?.baseCoursename;
  const baseCourseId = location?.state?.baseCourseId;
  const examName = location?.state?.examName;
  const navigate = useNavigate();
  const isextended = location.state?.isextended;
  const [ind, setInd] = useState(-1);
  const [vidID, setvidID] = useState(0);
  const [couponCode, setCouponCode] = useState('');
  const [isHideInput, setIsHideInput] = useState(true);
  const [seoData, setSeoData] = useState({});

  const {
    subCategories,
    _id,
    name,
    sectionType,
    pdf,
    packageSection,
    sessions,
    courseDetail,
    selectedCourse
  } = useSelector((state) => ({
    subCategories: state?.CourseDetailreducer?.subCategories,
    _id: state?.CourseDetailreducer?._id,
    name: state?.CourseDetailreducer?.name,
    sectionType: state?.CourseDetailreducer?.sectionType,
    pdf: state?.CourseDetailreducer?.pdf,
    packageSection: state?.CourseDetailreducer?.packageSection,
    sessions: state?.CourseDetailreducer?.sessions,
    courseDetail: state?.CourseDetailreducer?.courseDetail,
    selectedCourse: state?.CourseDetailreducer?.selectedCourse
  }));

  const { subscriptionID, CouponData } = useSelector((state) => ({
    subscriptionID: state?.HomeReducer?.subscriptionID,
    CouponData: state?.HomeReducer?.CouponData
  }));

  const { price } = useSelector((state) => ({
    price: state?.subscriptionPlanReducer?.price
  }));

  const { isLoader, ACCESS_TOKEN_NEW } = useSelector((state) => ({
    isLoader: state?.HomeReducer?.isLoader,
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));

  const [packages, setPackage] = useState({
    _id: packageSection?.length > 1 && packageSection[0]?._id,
    name: packageSection?.length > 1 && packageSection[0]?.name
  });
  const [key, setKey] = useState(0);
  useEffect(() => {
    dispatch(
      updateHomeValues({
        subscriptionID: '',
        selectedPackages: [],
        CouponData: [],
        couponCode: '',
        showRemoveCoupon: false
      })
    );
    if (isextended === true) {
      dispatch(
        getExtendedCourseDetail(`/${courseID}`, async (dataCourse) => {
          if (dataCourse?.couponName) setCouponCode(dataCourse.couponName);
          const index = dataCourse?.packageSection?.findIndex((item) => item?.hasSelected);
          setCourseIncludes(
            index > -1
              ? dataCourse?.packageSection[index]?.subCategoriesIncluded
              : dataCourse?.packageSection[0]?.subCategoriesIncluded
          );
          setPackage(
            index > -1 ? dataCourse?.packageSection[index] : dataCourse?.packageSection[0]
          );

          dispatch(
            updateCourseDetailValues({
              selectedCourse: [
                index > -1 ? dataCourse?.packageSection[index] : dataCourse.packageSection[0]
              ]
            })
          );

          if (index > -1) {
            setKey(index);
            setInd(index);
            const selectedIndex = dataCourse?.packageSection[index]?.subscriptionPlan?.findIndex(
              (item) => item?.hasSelected
            );
            if (selectedIndex > -1) {
              dispatch(
                updateSubscriptionValues({
                  price: dataCourse?.packageSection[index]?.subscriptionPlan[selectedIndex]?.price
                })
              );
              dispatch(
                updateHomeValues({
                  OriginalPrice:
                    dataCourse?.packageSection[index]?.subscriptionPlan[selectedIndex]?.price
                })
              );
            }
            dispatch(
              updateHomeValues({
                subscriptionID: dataCourse.packageSection[index].subscriptionPlan[selectedIndex]._id
              })
            );
          } else {
            let recommendedPrice = dataCourse?.packageSection[0]?.subscriptionPlan?.filter(
              (object) => object?.isRecommended === true
            )[0]?.price;
            dispatch(updateSubscriptionValues({ price: recommendedPrice }));
            let subId = dataCourse?.packageSection[0]?.subscriptionPlan?.filter(
              (object) => object?.isRecommended === true
            )[0]?._id;
            dispatch(updateHomeValues({ subscriptionID: subId, OriginalPrice: recommendedPrice }));
          }
          dispatch(
            updateCourseDetailValues({
              subCategories: dataCourse?.subCategories,
              language: dataCourse?.language,
              _id: dataCourse?._id,
              name: dataCourse?.name,
              sectionId: dataCourse?.sectionId,
              mainCategoryId: dataCourse?.sectionId,
              sectionType: dataCourse?.sectionType,
              startDate: dataCourse?.startDate,
              bannerImage: dataCourse?.bannerImage,
              video: dataCourse?.video,
              pdf: dataCourse?.pdf,
              duration: dataCourse?.duration,
              packageSection: dataCourse?.packageSection,
              sessions: dataCourse?.sessions,
              courseDetail: dataCourse
            })
          );
          dispatch(updateSessionValues({ sessionId: dataCourse?.sessions[0]?._id }));
        })
      );
    } else {
      if (ACCESS_TOKEN_NEW) {
        dispatch(
          getCourseDetail(`/${courseID}`, async (dataCourse) => {
            if (dataCourse?.couponName) setCouponCode(dataCourse.couponName);
            const index = dataCourse?.packageSection?.findIndex((item) => item?.hasSelected);
            setPackage(
              index > -1 ? dataCourse?.packageSection[index] : dataCourse?.packageSection[0]
            );
            setCourseIncludes(
              index > -1
                ? dataCourse?.packageSection[index]?.subCategoriesIncluded
                : dataCourse?.packageSection[0]?.subCategoriesIncluded
            );

            dispatch(
              updateCourseDetailValues({
                selectedCourse: [
                  index > -1 ? dataCourse?.packageSection[index] : dataCourse.packageSection[0]
                ]
              })
            );

            if (index > -1) {
              setKey(index);
              setInd(index);
              const selectedIndex = dataCourse?.packageSection[index]?.subscriptionPlan?.findIndex(
                (item) => item?.hasSelected
              );
              if (selectedIndex > -1)
                dispatch(
                  updateSubscriptionValues({
                    price: dataCourse?.packageSection[index]?.subscriptionPlan[selectedIndex]?.price
                  })
                );
              dispatch(
                updateHomeValues({
                  subscriptionID:
                    dataCourse.packageSection[index].subscriptionPlan[selectedIndex]._id,
                  OriginalPrice:
                    dataCourse?.packageSection[index]?.subscriptionPlan[selectedIndex]?.price
                })
              );
            } else {
              let recommendedPrice = dataCourse?.packageSection[0]?.subscriptionPlan?.filter(
                (object) => object?.isRecommended === true
              )[0]?.price;
              dispatch(updateSubscriptionValues({ price: recommendedPrice }));
              let subId = dataCourse?.packageSection[0]?.subscriptionPlan?.filter(
                (object) => object?.isRecommended === true
              )[0]?._id;
              dispatch(
                updateHomeValues({ subscriptionID: subId, OriginalPrice: recommendedPrice })
              );
            }
            dispatch(
              updateCourseDetailValues({
                subCategories: dataCourse?.subCategories,
                language: dataCourse?.language,
                _id: dataCourse?._id,
                name: dataCourse?.name,
                sectionId: dataCourse?.sectionId,
                mainCategoryId: dataCourse?.sectionId,
                sectionType: dataCourse?.sectionType,
                startDate: dataCourse?.startDate,
                bannerImage: dataCourse?.bannerImage,
                video: dataCourse?.video,
                pdf: dataCourse?.pdf,
                duration: dataCourse?.duration,
                packageSection: dataCourse?.packageSection,
                sessions: dataCourse?.sessions,
                courseDetail: dataCourse
              })
            );
            dispatch(updateSessionValues({ sessionId: dataCourse?.sessions[0]?._id }));
          })
        );
      } else {
        dispatch(
          getfreeCourseDetail(`/${courseID}`, async (dataCourse) => {
            setSeoData(dataCourse?.courseTypeSeo ? dataCourse?.courseTypeSeo : {});
            if (dataCourse?.couponName) setCouponCode(dataCourse.couponName);
            const index = dataCourse?.packageSection?.findIndex((item) => item?.hasSelected);
            setPackage(
              index > -1 ? dataCourse?.packageSection[index] : dataCourse?.packageSection[0]
            );
            setCourseIncludes(
              index > -1
                ? dataCourse?.packageSection[index]?.subCategoriesIncluded
                : dataCourse?.packageSection[0]?.subCategoriesIncluded
            );

            dispatch(
              updateCourseDetailValues({
                selectedCourse: [
                  index > -1 ? dataCourse?.packageSection[index] : dataCourse.packageSection[0]
                ]
              })
            );

            if (index > -1) {
              setKey(index);
              setInd(index);
              const selectedIndex = dataCourse?.packageSection[index]?.subscriptionPlan?.findIndex(
                (item) => item?.hasSelected
              );
              if (selectedIndex > -1) {
                dispatch(
                  updateSubscriptionValues({
                    price: dataCourse?.packageSection[index]?.subscriptionPlan[selectedIndex]?.price
                  })
                );
                dispatch(
                  updateHomeValues({
                    OriginalPrice:
                      dataCourse?.packageSection[index]?.subscriptionPlan[selectedIndex]?.price
                  })
                );
              }
              dispatch(
                updateHomeValues({
                  subscriptionID:
                    dataCourse.packageSection[index].subscriptionPlan[selectedIndex]._id
                })
              );
            } else {
              let recommendedPrice = dataCourse?.packageSection[0]?.subscriptionPlan?.filter(
                (object) => object?.isRecommended === true
              )[0]?.price;
              dispatch(updateSubscriptionValues({ price: recommendedPrice }));
              let subId = dataCourse?.packageSection[0]?.subscriptionPlan?.filter(
                (object) => object?.isRecommended === true
              )[0]?._id;
              dispatch(
                updateHomeValues({ subscriptionID: subId, OriginalPrice: recommendedPrice })
              );
            }
            dispatch(
              updateCourseDetailValues({
                subCategories: dataCourse?.subCategories,
                language: dataCourse?.language,
                _id: dataCourse?._id,
                name: dataCourse?.name,
                sectionId: dataCourse?.sectionId,
                mainCategoryId: dataCourse?.sectionId,
                sectionType: dataCourse?.sectionType,
                startDate: dataCourse?.startDate,
                bannerImage: dataCourse?.bannerImage,
                video: dataCourse?.video,
                pdf: dataCourse?.pdf,
                duration: dataCourse?.duration,
                packageSection: dataCourse?.packageSection,
                sessions: dataCourse?.sessions,
                courseDetail: dataCourse
              })
            );
            dispatch(updateSessionValues({ sessionId: dataCourse?.sessions[0]?._id }));
          })
        );
      }
    }
  }, [courseID]);

  const courseId = _id;
  const packageSectionId = packages && packages._id ? packages._id : packageSection[key]?._id;

  const subscriptionPlanId = subscriptionID;

  const [show, setShow] = useState(false);
  const [courseIncludes, setCourseIncludes] = useState([]);
  const setOnClick = () => {
    if (ACCESS_TOKEN_NEW) {
      if (sessions?.length !== 0) {
        if (!show) setShow(true);
        else setShow(false);
      } else {
        const postData = {
          sectionType,
          courseId,
          packageSectionId,
          subscriptionPlanId,
          isExetended: isextended === true ? true : false
        };
        if (CouponData[0]?.isCouponApply) {
          postData['couponId'] = CouponData[0]?.couponId;
        }
        dispatch(
          addToCart(
            {
              package: [postData],
              extendedOption: isextended === true ? true : false
            },
            () => {
              navigate('/MyCart');
            },
            () => {}
          )
        );
      }
    } else {
      navigate('/SignIn', { state: { IsclassRoom: false } });
    }
  };

  const dataForApplyCoupon = {
    sectionType,
    courseId,
    packageSectionId,
    subscriptionPlanId
  };

  const onSelectPackageSection = (e) => {
    setCourseIncludes(packageSection[e]?.subCategoriesIncluded);
    dispatch(updateHomeValues({ CouponData: '', couponCode: '' }));
    dispatch(updateHomeValues({ showRemoveCoupon: false }));
    setPackage({ _id: packageSection[e]?._id, name: packageSection[e]?.name });
    setKey(e);
    if (e != ind) {
      // setIsHideInput(false)
      let recommendedPrice = packageSection[e]?.subscriptionPlan?.filter(
        (object) => object?.isRecommended === true
      )[0]?.price;
      dispatch(updateSubscriptionValues({ price: recommendedPrice }));
      let subId = packageSection[e]?.subscriptionPlan?.filter(
        (object) => object?.isRecommended === true
      )[0]?._id;
      dispatch(updateHomeValues({ subscriptionID: subId, OriginalPrice: recommendedPrice }));
    } else {
      // setIsHideInput(true)
      let hasSelectedPriceObj = packageSection[e]?.subscriptionPlan?.filter(
        (object) => object?.hasSelected === true
      )[0];
      dispatch(
        updateSubscriptionValues({
          price: hasSelectedPriceObj?.discountedPrice
            ? hasSelectedPriceObj?.discountedPrice
            : hasSelectedPriceObj?.price
        })
      );
      let subId1 = packageSection[e]?.subscriptionPlan?.filter(
        (object) => object?.hasSelected === true
      )[0]?._id;
      dispatch(
        updateHomeValues({
          subscriptionID: subId1,
          OriginalPrice: hasSelectedPriceObj?.discountedPrice
            ? hasSelectedPriceObj?.discountedPrice
            : hasSelectedPriceObj?.price
        })
      );
    }
  };

  useEffect(() => {
    const youtubeParse = (url) => {
      var regExp =
        /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
      var match = url.match(regExp);
      return match && match[1].length == 11 ? match[1] : '';
    };
    if (courseDetail?.video !== undefined) {
      const id = youtubeParse(courseDetail.video);

      setvidID(id);
    }
  }, [courseDetail?.video]);
  const [wrongParent, setwrongParent] = useState(false);
  const setErrormsg = (val) => {
    setwrongParent(val.popup);
  };
  return (
    <div className="">
      {ACCESS_TOKEN_NEW ? <Header /> : <HeaderHome />}
      <SEODetails title={seoData?.metaTitle} description={seoData?.metaDescrition} />

      <div className="backimg">
        <div className="customcontainer">
          <h3 className="text-uppercase mt-3 mb-3 text-center fw-bold">{name}</h3>
          <div className="row">
            <div className="col-md-6 courseLeftside">
              <div className="mb-3 mt-3 text-center position-relative youtubeframe">
                <YouTubeIframe videoId={vidID} />
              </div>
              <div className="boxshadow rounded py-3 px-4 mb-2 bgwhite">
                {courseDetail?.timeType === 2 ? null : courseDetail?.startDate &&
                  courseDetail?.sectionType === 1 ? (
                  <div className="d-flex">
                    <p className="fw-bold">Batch Start :</p>
                    <p className="colorblue ms-1">
                      {moment(courseDetail?.startDate).format('Do MMM YYYY')}
                    </p>
                  </div>
                ) : null}

                <div className="d-flex justify-content-between">
                  <h4 className="fw-bold">Course Includes</h4>
                  <a
                    className="btn btn-outline-primary rounded font12 d-flex align-items-center"
                    href={pdf}
                    target="_blank"
                    rel="noreferrer">
                    Course Details{' '}
                    <img
                      src={LocalImages.pdff}
                      alt="pdf"
                      style={{ width: '13px' }}
                      className="ms-2"
                    />
                  </a>
                </div>

                <div className="row d-flex justify-content-evenly flex-fill">
                  <div className="col-sm-6 font12 flex-fill">
                    <p className="successcolor fw-bold mb-2">
                      <span className="me-1">
                        <img src={LocalImages.include} alt="include" style={{ width: '15px' }} />
                      </span>{' '}
                      Included
                    </p>
                    {courseDetail?.sectionType === 5
                      ? subCategories?.included?.map((c, index) => (
                          <p key={index} className="ms-3 mb-1">
                            <span className="me-2">
                              <img src={c?.icon} alt="Live" style={{ width: '12px' }} />
                            </span>
                            {c?.name}
                          </p>
                        ))
                      : courseIncludes?.map((c, index) => (
                          <p key={index} className="ms-3 mb-1">
                            <span className="me-2">
                              <img src={c?.icon} alt="Live" style={{ width: '12px' }} />
                            </span>
                            {c?.name}
                          </p>
                        ))}

                    {}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <Tabs
                id="tinyTab"
                className="coursedetails rounded font11"
                activekey={key}
                onSelect={(e) => onSelectPackageSection(e)}
                fill
                variant="pills">
                {packageSection?.map((c, index) => {
                  const NewIndex = packageSection[index]?.subscriptionPlan.findIndex(
                    (item) => item.hasSelected
                  );
                  return (
                    <Tab key={index.toString()} eventKey={index} activekey={index} title={c?.name}>
                      <form>
                        {' '}
                        {c?.isOffer ? (
                          <div className="px-md-2 offerBox">{c?.offer}</div>
                        ) : (
                          <div className="pt-4 pb-3"></div>
                        )}
                        <div className="pb-md-4 px-md-5 pricecardBox">
                          {packageSection[index] &&
                            packageSection[index]?.subscriptionPlan?.map((c, indexInternal) => (
                              <PriceCard
                                isLecture={false}
                                isHasSelected={NewIndex > -1}
                                isDefaultCheck={true}
                                hasSelected={true}
                                data={c}
                                key={indexInternal}
                                packageSectionID={packageSectionId}
                                index={indexInternal}
                              />
                            ))}
                        </div>
                      </form>

                      <CouponCard
                        Price={price}
                        couponCode={couponCode}
                        isextended={isextended === true ? true : false}
                        btnname={ACCESS_TOKEN_NEW ? 'Buy now' : 'Login'}
                        setOnClick={setOnClick}
                        dataForApplyCoupon={dataForApplyCoupon}
                        hideInput={isHideInput}
                        itemCount="1"
                        index={key}
                      />
                    </Tab>
                  );
                })}
              </Tabs>
              {show ? (
                <BatchTiming
                  show={setShow}
                  extendedOption={isextended === true ? true : false}
                  setKey={key}
                  setErrormsg={(value) => setErrormsg(value)}
                />
              ) : (
                ''
              )}
              {wrongParent ? (
                <LoginErrorPopup
                  wrongParent={setwrongParent}
                  setKey={key}
                  baseCoursesname={baseCoursename}
                  examName={examName}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseDetails;
