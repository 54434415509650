import React from 'react';
const OverAllResult = (props) => {
    return (<>
        <div className='all-result d-flex justify-content-between text-center'>
            <p>{props.noOfTest}<span className='d-block'>Total Test</span></p>
            <p>{props.AvgPercentile}% <span className='d-block'>Avg. Percentile</span></p>
            <p>{props.overAllRank}<sup>th</sup><span className='d-block'>Overall Rank</span></p>
        </div>
    </>);
}

export default OverAllResult;