import React, { useState, useEffect } from 'react';
import '../App.css';
import Lottie from 'react-lottie';
import studymaterial from '../Assets/Images/Dashboard/studyMaterial.json';
import Expert from '../Assets/Images/JSON/expert.json';
import TalktoExpert from '../Module/talktoExpert';
import lecture from '../Assets/Images/Dashboard/lecture.json';
import LectureCard from '../Component/lectureCard';
import CourseCard from '../Module/courseCard';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LocalImages } from '../Constant';
import MoreBtn from '../Component/moreBtn';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Carousel from 'react-grid-carousel';
import subscribed from '../Assets/Images/JSON/subscription.json';

import {
  getBanner,
  getCourseHome,
  getFreeLecture,
  getNotificationUnread,
  getUnActiveCourse,
  getUnActiveSubscribedCourse,
  updateHomeValues
} from '../Redux/Actions/homeAction';
import { updateCourseDetailValues } from '../Redux/Actions/courseDetailAction';
import moment from 'moment';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import InstallmentPopUp from '../Module/installmentPopUp';
import topperListData from '../Assets/DummyData/topperData.json';
import BannerCard from '../Module/bannerCard';
import PredictionPopup from '../Module/predictionPopup';
import { getName } from '../Redux/Actions/AuthAction';
function Dashboard() {
  const [installmentData, setInstallmentData] = useState();

  const [openInstall, setOpenInstall] = useState(false);
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [date, setDate] = useState('');

  const [open, setopen] = useState(false);
  const [openPrediction, setopenPrediction] = useState(false);
  const closePrediction = () => {
    setopenPrediction(false);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Expert,
    renderer: 'svg'
  };

  const defaultOptionsSubscribed = {
    loop: true,
    autoplay: true,
    animationData: subscribed,
    renderer: 'svg'
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasActivePaidPlan } = useSelector((state) => ({
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan
  }));

  const {
    upcoming,
    freeVideo,
    courseHome,
    moreFree,
    paidTilesInactive,
    paidTilesSubscribed,
    installmentsInactive
  } = useSelector((state) => ({
    loader: state?.HomeReducer?.loader,
    upcoming: state?.HomeReducer?.upcoming,
    freeVideo: state?.HomeReducer?.freeVideo,
    banner: state?.HomeReducer?.banner,
    continueWatch: state?.AuthReducer?.continueWatch,
    firstName: state?.AuthReducer?.firstName,
    homeQuote: state?.HomeReducer?.homeQuote,
    talkToExpert: state?.AuthReducer?.talkToExpert,
    profilePicture: state?.AuthReducer?.profilePicture,
    courseHome: state?.HomeReducer?.courseHome,
    paidTilesInactive: state?.HomeReducer?.paidTilesInactive,
    paidTilesSubscribed: state?.HomeReducer?.paidTilesSubscribed,
    installmentsInactive: state?.HomeReducer?.installmentsInactive,
    courseList: state?.HomeReducer?.courseList,
    courseSelect: state?.HomeReducer?.courseSelect,
    moreFree: state?.HomeReducer?.moreFree
  }));

  const { examTypeId, baseCourseId, childrenId, examTypeName, baseCourseName } = useSelector(
    (state) => ({
      examTypeId: state?.AuthReducer?.examTypeId,
      baseCourseId: state?.AuthReducer?.baseCourseId,
      childrenId: state?.AuthReducer?.childrenId,
      examTypeName: state?.AuthReducer?.examTypeName,
      baseCourseName: state?.AuthReducer?.baseCourseName
    })
  );

  useEffect(() => {
    dispatch(getNotificationUnread());
    dispatch(getUnActiveSubscribedCourse());
    dispatch(getUnActiveCourse());
    dispatch(
      updateHomeValues({
        courseHome: [],
        upcoming: [],
        freeVideo: [],
        courseList: [],
        courseSelect: ''
      })
    );

    if (examTypeId) {
      dispatch(updateHomeValues({ isLoader: false }));

      let url3 = `/${examTypeId}/${baseCourseId}/3`;
      if (childrenId) {
        url3 = `/${examTypeId}/${baseCourseId}/3/${childrenId}`;
      }
      dispatch(
        getFreeLecture(url3, (data) => {
          const moreData = data.lecturesData['3'];
          dispatch(
            updateHomeValues({
              moreFree: moreData,
              isLoader: false
            })
          );
        })
      );

      let url0 = `/${examTypeId}/${baseCourseId}/0`;
      let requestData = {
        baseCourseId: baseCourseId,
        examTypeId: examTypeId
      };
      if (childrenId) {
        url0 = `/${examTypeId}/${baseCourseId}/0/${childrenId}`;
        requestData.subExamTypeId = childrenId;
      }

      dispatch(
        getFreeLecture(url0, async (data) => {
          dispatch(getBanner(requestData));
          if (data.lecturesData['2']) {
            dispatch(
              updateHomeValues({ upcoming: [...data.lecturesData['2'], ...data.lecturesData['1']] })
            );
          } else {
            dispatch(updateHomeValues({ upcoming: data.lecturesData['1'] }));
          }
          dispatch(updateHomeValues({ freeVideo: data.lecturesData['3'] }));

          if (childrenId) {
            dispatch(
              getCourseHome(`/${baseCourseId}/${examTypeId}/${childrenId}`, (dataCourse) => {
                dispatch(updateHomeValues({ courseHome: dataCourse }));
              })
            );
          } else {
            dispatch(
              getCourseHome(`/${baseCourseId}/${examTypeId}`, (dataCourse) => {
                dispatch(updateHomeValues({ courseHome: dataCourse }));
              })
            );
          }
        })
      );
    } else {
      dispatch(
        updateHomeValues({
          courseHome: [],
          upcoming: [],
          freeVideo: [],
          courseList: [],
          courseSelect: ''
        })
      );
    }
  }, [examTypeId, childrenId, baseCourseId]);

  const upcomingArray = [];
  for (let i = 0; i < 3; i++) {
    upcomingArray.push(upcoming[i]);
  }

  const handleCloseInstall = () => {
    setOpenInstall(false);
  };
  const handleClickInstall = () => {
    setOpenInstall(true);
  };

  const courseroute = () => {
    if (hasActivePaidPlan) {
      navigate('/PaidUser/OnlineCourse');
    } else {
      navigate('/DashboardMenu/OnlineCourse');
    }
  };
  const allClassroute = () => {
    navigate('/DashboardMenu/LiveClass', { state: { upcoming: upcoming } });
  };

  const allFreeLectures = () => {
    navigate('/DashboardMenu/FreeLectures', { state: { free: moreFree } });
  };
  const smRoute = (dataStudyMaterial) => {
    const { _id, baseCourseId, examTypeId, subExamTypeId } = dataStudyMaterial;
    dispatch(
      updateCourseDetailValues({
        packageSection: [],
        sessions: [],
        courseDetail: {}
      })
    );
    const data = {
      baseCourseId,
      examTypeId
    };
    if (subExamTypeId) data.subExamTypeId = subExamTypeId;
    dispatch(
      getName(
        data,
        (res) => {
          const { seoBaseCourseName, seoSubExamName, seoExamTypeName } = res;
          navigate(
            '/' +
              `${seoSubExamName ? seoSubExamName : seoExamTypeName}` +
              '/' +
              seoBaseCourseName +
              '-study-material' +
              '/' +
              _id
          );
        },
        () => {}
      )
    );
  };
  const lsRoute = (courseID, courseName) => {
    navigate('/LectureSeries/' + courseName + '/' + courseID, {
      state: { courseID: courseID, isextended: false }
    });
  };
  const courseDetails = (courseID, courseName) => {
    navigate('/CourseDetails/' + courseName + '/' + courseID, {
      state: { courseID: courseID, isextended: false }
    });
  };
  const handleClose = () => {
    setopen(false);
  };
  const handleClick = () => {
    setopen(true);
  };
  window.onpopstate = function (event) {
    if (event) {
      window.location.href = '/';
      // Code to handle back button or prevent from navigation
    }
  };
  const study = [
    {
      name: 'Study Material',
      path: studymaterial,
      subtextsm: 'Best IIT JAM Life Science Study Material Package in Printed Book Version',
      defaultOptions: {
        loop: true,
        autoplay: true,
        animationData: studymaterial,
        renderer: 'svg'
      },
      link: smRoute
    },
    {
      name: 'Lecture Series',
      subtextls: 'Best IIT JAM Life Science Lecture Series',
      defaultOptions: {
        loop: true,
        autoplay: true,
        animationData: lecture,
        renderer: 'svg'
      },
      link: lsRoute
    }
  ];
  const index = courseHome.findIndex(
    (obj) => (obj?.type === 2 && obj?.packages) || (obj?.type === 5 && obj?.packages)
  );

  const livelectroute = (item) => {
    const { startDate, isInactive, isSubscribed, orderId } = item;
    if (isSubscribed) {
      setDate(moment.utc(startDate).format('Do MMM YYYY'));
      setOpenSubscribe(true);
    } else if (isInactive) {
      const index = installmentsInactive?.findIndex((item) => item?.parentOrderId === orderId);

      if (index > -1) {
        setInstallmentData(installmentsInactive[index]);
        handleClick();
      }
    }
  };

  const payNow = (orderId) => {
    const index = installmentsInactive?.findIndex((item) => item?.parentOrderId === orderId);

    if (index > -1) {
      return (
        <div className="d-flex flex-column align-items-end">
          <button
            type="button"
            key={index.toString()}
            className="btn btn-primary font12 rounded px-2 py-1 d-inline mt-2"
            onClick={() => {
              setInstallmentData(installmentsInactive[index]);
              handleClickInstall();
            }}>
            Pay Installment
          </button>
        </div>
      );
    }
    return null;
  };

  const CourseDetailsEx = (courseID, courseName) => {
    navigate('/CourseDetails/' + courseName + '/' + courseID, {
      state: { courseID: courseID, isextended: true }
    });
  };

  const LectureDetail = (courseID) => {
    navigate('/LectureSeries', {
      state: {
        courseID: courseID,
        isextended: true
      }
    });
  };

  const [topperList, settopperList] = useState();
  useEffect(() => {
    const List = topperListData.toppers.filter((item) => item?.id === examTypeId);
    settopperList(List);
  }, [examTypeName, examTypeId]);

  return (
    <div className="freeuserrightsidepanel bgwhite">
      <div
        className={
          examTypeId || courseHome.length !== 0
            ? ' row d-flex bg common-pad backgroundimage px-0 '
            : 'bg examcommonpad backgroundimage '
        }>
        <div
          className={
            examTypeId || courseHome.length !== 0
              ? 'col-lg-6 col-sm-12 p-4'
              : 'col-lg-12 col-sm-12 '
          }>
          {courseHome.length !== 0 ? (
            <h1 className="letterSpacing">
              {/* Boost your <span className="colorblue"> {examTypeName}</span> Exam Preparation with */}
              Boost your Exam Preparation with IFAS
            </h1>
          ) : (
            <>
              <h1 className="letterSpacing mb-0">Please, Visit Again!!</h1>
              <span className="colorblue fw-bold" style={{ fontSize: '61px' }}>
                {' '}
                Program Coming Soon
              </span>
            </>
          )}
          <div className="mt-5 ">
            <p className=" w-75 letterSpacing mb-0">
              India's <span className="colorblue fw-bold"> No. 1 AI Based App</span>
            </p>
            <p className=" w-75 letterSpacing mb-0">For IIT-JAM,CSIR - UGC NET,</p>
            <p className=" w-75 letterSpacing">GATE & Other Competitive Examination</p>
          </div>
        </div>

        {examTypeId || courseHome.length !== 0 ? (
          <div className="col-lg-6 col-sm-12 pt-5">
            {topperList?.map((item, itemIndex) => {
              return (
                <div key={itemIndex} className="row d-flex">
                  {item?.topperList?.map((c, indexInner) => (
                    <div key={indexInner} className="col-3 hometopperimg align-items-center">
                      <img className="img-fluid" src={LocalImages[c.path]} alt={c.name}></img>
                      <p className="d-block font10 text-center fw-bold">{c.name}</p>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </div>

      {paidTilesInactive.length + paidTilesSubscribed.length > 0 && (
        <div
          id="carouselExampleDark"
          className="carousel carousel-dark slide customcontainer paiddiv mt-4"
          data-bs-ride="carousel">
          <div className="carousel-indicators">
            {[...paidTilesInactive, ...paidTilesSubscribed]?.map((c, index) => {
              return (
                <button
                  type="button"
                  key={index}
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to={index}
                  className={index === 0 ? 'active' : null}
                  aria-current="true"
                  aria-label={'Slide ' + index}></button>
              );
            })}
          </div>
          <div className="carousel-inner">
            {[...paidTilesInactive, ...paidTilesSubscribed]?.map((c, index) => {
              const { name, startDate, isSubscribed = false, packageType } = c;

              return (
                <div
                  key={index}
                  className={
                    index === 0
                      ? 'carousel-item active position-relative'
                      : 'carousel-item  position-relative'
                  }
                  data-bs-interval="100000">
                  <div
                    className="boxshadow rounded mb-4 paidTilesBox bgwhite w-75 cursor"
                    onClick={() => livelectroute(c)}>
                    <div className="d-flex justify-content-between p-3">
                      <div className="d-flex align-items-center">
                        <img
                          src={LocalImages.courselogo}
                          style={{ width: '40px' }}
                          alt="Course Logo"
                        />
                        <span className="ms-3 font12">{name}</span>
                      </div>
                      <div className="d-flex flex-column align-items-end font12">
                        <div>
                          {isSubscribed
                            ? `Batch Start From : ${moment
                                .utc(startDate)
                                .format(' Do MMM YYYY - HH:MM')}`
                            : 'Deactivated'}
                        </div>
                        {c.hasExtendOption ? (
                          <div className="d-flex flex-column align-items-end">
                            <button
                              type="button"
                              className="btn btn-primary submitbtn  font12 rounded px-2 py-1 d-inline mt-2 "
                              onClick={() => {
                                if (packageType === 5) {
                                  LectureDetail(c.courseId);
                                } else {
                                  CourseDetailsEx(c.courseId, c.name);
                                }
                              }}>
                              Extend
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-end">{payNow(c.orderId)}</div>
                  </div>
                </div>
              );
            })}
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            key={'Previous'}
            data-bs-target="#carouselExampleDark"
            data-bs-slide="prev">
            <span
              className="carousel-control-prev-icon"
              style={{ background: 'none', color: '#328aed', marginRight: '172px' }}
              aria-hidden="true">
              <ArrowBackIosNewIcon />
            </span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            key={'Next'}
            className="carousel-control-next "
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="next">
            <span
              className="carousel-control-next-icon"
              style={{ background: 'none', color: '#328aed', marginLeft: '172px' }}
              aria-hidden="true">
              <ArrowForwardIosIcon />
            </span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      )}
      {/* ********************Banner************************** */}
      <div className="mt-3 bannerDiv">
        <BannerCard />
      </div>

      {upcoming != '' ? (
        <div className="boxshadow liveclassdiv bgwhite">
          <div className="customcontainer py-4">
            <div className="d-flex justify-content-between">
              <h3 className="fw-bold  ms-4 boldfont">Live Classes</h3>
              <MoreBtn gotoLink={allClassroute} />
            </div>
            <div className="mt-4">
              <div className="row">
                {upcomingArray &&
                  upcomingArray.map((c, index) =>
                    c !== undefined ? <LectureCard key={index.toString()} data={c} /> : null
                  )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="boxshadow freeuserdiv">
        {courseHome.map((obj, indexCourseHome) => (
          <div key={indexCourseHome}>
            {obj.type === 1 ? (
              <div className="customcontainer py-4 mt-4 courseMainDiv">
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="fw-bold  ms-4 boldfont">{obj.name}</h3>
                  <MoreBtn gotoLink={courseroute} />
                </div>
                <Carousel cols={3} rows={1} gap={10}>
                  {obj &&
                    obj.packages.map((c, indexCarousel) => (
                      <Carousel.Item key={indexCarousel}>
                        <div className="mx-4 mb-3">
                          <CourseCard
                            path={c.bannerImage}
                            course={c.name}
                            sectionType={c?.sectionType}
                            courseType={obj.type}
                            batch={c.startDate}
                            courseID={c._id}
                            language={c.language.icon}
                            timeType={c.timeType}
                            classType={c.classType}
                            baseCourseId={c?.baseCourseId}
                            examTypeId={c?.examTypeId}
                            subExamTypeId={c?.subExamTypeId}
                          />
                        </div>
                      </Carousel.Item>
                    ))}
                </Carousel>
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>

      <div className="boxshadow freeuserdiv">
        {courseHome.map((obj, indexCourseHome) => (
          <div key={indexCourseHome}>
            {obj.type === 6 ? (
              <div className="customcontainer py-4 mt-4 courseMainDiv">
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="fw-bold  ms-4 boldfont">{obj.name}</h3>
                  <MoreBtn gotoLink={courseroute} />
                </div>
                <Carousel cols={3} rows={1} gap={10}>
                  {obj &&
                    obj.packages.map((c, indexCarousel) => (
                      <Carousel.Item key={indexCarousel}>
                        <div className="mx-4 mb-3">
                          <CourseCard
                            path={c.bannerImage}
                            course={c.name}
                            sectionType={c?.sectionType}
                            courseType={obj.type}
                            batch={c.startDate}
                            courseID={c._id}
                            language={c.language.icon}
                            timeType={c.timeType}
                            classType={c.classType}
                            baseCourseId={c?.baseCourseId}
                            examTypeId={c?.examTypeId}
                            subExamTypeId={c?.subExamTypeId}
                          />
                        </div>
                      </Carousel.Item>
                    ))}
                </Carousel>
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>

      <div className="boxshadow freeuserdiv">
        {courseHome.map((obj, indexMain) => {
          return (
            <div key={indexMain}>
              {obj.type === 3 ? (
                <div className="customcontainer py-4 boxshadow freeuserdiv">
                  <div className="d-flex justify-content-between mb-3">
                    <h3 className="fw-bold ms-4 boldfont">{obj?.name}</h3>
                    <MoreBtn gotoLink={courseroute} />
                  </div>
                  <div className="mt-4">
                    <div className="row mx-4">
                      {obj &&
                        obj.packages.map((c, indexInner) => {
                          if (indexInner <= 2) {
                            return (
                              <div
                                key={indexInner}
                                className="col-sm-4 col-md-4 col-xl-4 ps-0 pe-0 mb-3">
                                <div className="card border-0 p-4 shadow-lg me-sm-4">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="ellipsis fw-bold">{c.name}</h6>
                                    <div className="testserieslangimg">
                                      <img
                                        className="w-50 position-absolute bgwhite"
                                        src={c?.language?.icon}
                                        alt={c.name}
                                      />
                                    </div>
                                  </div>

                                  <p className="mb-4 font12">
                                    Unit Test: {c.testCount.unit}, Mock Test: {c.testCount.mock}
                                  </p>
                                  <button
                                    className="btn btn-primary w-75 py-md-1 py-lg-0 font-12 mx-auto"
                                    onClick={() => {
                                      courseDetails(c._id, c.name);
                                    }}>
                                    View Details
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>

      {index > -1 ? (
        <div className=" freeuserdiv mt-3 ">
          {courseHome.map((obj, indexPackages) =>
            obj?.type === 5 && obj?.packages ? (
              <div
                key={indexPackages}
                className="col-sm-12 "
                onClick={() => {
                  lsRoute(obj?.packages[0]?._id, obj?.packages[0]?.name);
                }}>
                <div className="lectureseriesbgclr studymaterialLectureseriesdiv text-white card border-0 p-3 pt-1 pb-0 d-flex flex-row flex-wrap align-items-center my-md-3 boxshadow">
                  <div className="col-12 col-md-8 cursor">
                    <h6 className="font12 fw-bold">{obj?.name}</h6>
                    <p className="font12 mt-1">
                      Best {examTypeName} {baseCourseName} Lecture Series
                    </p>
                  </div>
                  <div className="col-12 col-md-4 animateImges">
                    <Lottie
                      className="me-0"
                      options={study[1].defaultOptions}
                      height={110}
                      width={165}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          )}
        </div>
      ) : (
        ''
      )}
      <div className="boxshadow freeuserdiv">
        {courseHome.map((obj, indexMain) => {
          return (
            <div key={indexMain}>
              {obj.type === 7 ? (
                <div className="customcontainer py-4 boxshadow freeuserdiv">
                  <div className="d-flex justify-content-between mb-3">
                    <h3 className="fw-bold ms-4 boldfont">{obj?.name}</h3>
                    <MoreBtn gotoLink={courseroute} />
                  </div>
                  <div className="mt-4">
                    <div className="row mx-4">
                      {obj &&
                        obj.packages.map((c, indexInner) => {
                          if (indexInner <= 2) {
                            return (
                              <div
                                key={indexInner}
                                className="col-sm-4 col-md-4 col-xl-4 ps-0 pe-0 mb-3">
                                <div className="card border-0 p-4 shadow-lg me-sm-4">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="ellipsis fw-bold">{c.name}</h6>
                                    <div className="testserieslangimg">
                                      <img
                                        className="w-50 position-absolute bgwhite"
                                        src={c?.language?.icon}
                                        alt={c.name}
                                      />
                                    </div>
                                  </div>

                                  <p className="mb-4 font12">
                                    Unit Test: {c.testCount.unit}, Mock Test: {c.testCount.mock}
                                  </p>
                                  <button
                                    className="btn btn-primary w-75 py-md-1 py-lg-0 font-12 mx-auto"
                                    onClick={() => {
                                      courseDetails(c._id, c.name);
                                    }}>
                                    View Details
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
      <div className="boxshadow freeuserdiv">
        {courseHome.map((obj, indexMain) => {
          return (
            <div key={indexMain}>
              {obj.type === 8 ? (
                <div className="customcontainer py-4 boxshadow freeuserdiv">
                  <div className="d-flex justify-content-between mb-3">
                    <h3 className="fw-bold ms-4 boldfont">{obj?.name}</h3>
                    <MoreBtn gotoLink={courseroute} />
                  </div>
                  <div className="mt-4">
                    <div className="row mx-4">
                      {obj &&
                        obj.packages.map((c, indexInner) => {
                          if (indexInner <= 2) {
                            return (
                              <div
                                key={indexInner}
                                className="col-sm-4 col-md-4 col-xl-4 ps-0 pe-0 mb-3">
                                <div className="card border-0 p-4 shadow-lg me-sm-4">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="ellipsis fw-bold">{c.name}</h6>
                                    <div className="testserieslangimg">
                                      <img
                                        className="w-50 position-absolute bgwhite"
                                        src={c?.language?.icon}
                                        alt={c.name}
                                      />
                                    </div>
                                  </div>

                                  <p className="mb-4 font12">
                                    Unit Test: {c.testCount.unit}, Mock Test: {c.testCount.mock}
                                  </p>
                                  <button
                                    className="btn btn-primary w-75 py-md-1 py-lg-0 font-12 mx-auto"
                                    onClick={() => {
                                      courseDetails(c._id, c.name);
                                    }}>
                                    View Details
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
      {index > -1 ? (
        <div className="ps-md-0 mb-3 freeuserdiv mt-3 ">
          {courseHome?.map((obj, indexObj) =>
            obj?.type === 2 && obj?.packages ? (
              <div
                key={indexObj}
                className="col-sm-12 "
                onClick={() => {
                  smRoute(obj?.packages[0]);
                }}>
                <div className="studymaterialbgclr studymaterialLectureseriesdiv text-white card border-0 p-3 pt-1 pb-0 d-flex flex-row flex-wrap align-items-center my-md-3 me-0 boxshadow ">
                  <div className="col-12 col-md-8 cursor">
                    <h6 className="font12 fw-bold mt-2">{obj?.name}</h6>
                    <p className="font12 mt-1">
                      Best {examTypeName} {baseCourseName} Study Material Package in Printed Book
                      Version
                    </p>
                  </div>
                  <div className="col-12 col-md-4 animateImges">
                    <Lottie options={study[0].defaultOptions} height={110} width={165} />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          )}
        </div>
      ) : (
        ''
      )}

      {freeVideo.length != 0 ? (
        <div className="customcontainer py-4 boxshadow freeuserdiv">
          <div className="d-flex justify-content-between">
            <h3 className="fw-bold ms-4 boldfont">Free Lectures</h3>

            <MoreBtn gotoLink={allFreeLectures} />
          </div>
          <div className="mt-4 d-flex">
            {freeVideo &&
              freeVideo.map((c, indexLecture) => {
                if (c !== undefined && indexLecture <= 2) {
                  return <LectureCard data={c} key={indexLecture} />;
                }
              })}
          </div>
        </div>
      ) : null}

      <div
        className="position-fixed backcolor rounded-circle"
        style={{ right: '20px', bottom: '30px' }}
        onClick={handleClick}>
        <Lottie options={defaultOptions} height={70} width={70}></Lottie>
      </div>
      <TalktoExpert open={open} handleClose={handleClose} />
      <PredictionPopup open={openPrediction} handleClose={closePrediction} />
      {installmentData ? (
        <InstallmentPopUp
          open={openInstall}
          data={installmentData}
          handleClose={handleCloseInstall}
          handleClick={handleClickInstall}
        />
      ) : null}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={openSubscribe}
        onClose={() => setOpenSubscribe(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={openSubscribe}>
          <div className="modeldiv p-md-4 py-md-4 talktoexpertModel">
            <div className="m-2 insttxtDiv bgwhite">
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon
                  className="colorblue cursor"
                  onClick={() => setOpenSubscribe(false)}
                />
              </div>
              <div className="col justify-content-center   ">
                <Lottie options={defaultOptionsSubscribed} height={200} width={200}></Lottie>
                <p className="text-center">
                  <b>{`Course not started yet, Please wait upto ${date}`}</b>
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Dashboard;
