import React, { useEffect, useState } from 'react';
import '../App.css';
import VideoLecture from '../Component/videoLecture';
import { LocalImages } from '../Constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { timeConvert } from '../Utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  addVideoRatings,
  getRecordedPaidDetails,
  getVideoRating,
  updateVideoRating,
  videoWatchStatus,
} from '../Redux/Actions/homeAction';
import { updateMoreValues } from '../Redux/Actions/AuthAction';

function RecordedLectures() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const topics = location.state.topics;
  const subjectId = location.state.subjectId;
  const title = location.state.title;
  const { hasActivePaidPlan, selectedTiles } = useSelector((state) => ({
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
    selectedTiles: state?.HomeReducer?.selectedTiles
  }));
  const onPressVideo = () => {
    // window.location.href = 'Topics';
  };
  const goVideo = (topicId, item) => {
    navigate(hasActivePaidPlan ? '/PaidUser/AllLectureVideo' : '/DashboardMenu/AllLectureVideo', {
      state: { topicId: topicId, subjectId, teacherId: item?.teacherId }
    });
  };

  return (
    <div className="rightPanel scrollbar me-1">
      <div>
        <h5>{title}</h5>
      </div>
      {topics.map((c) => (
        <div className="row" key={c?._id}>
          <div className="col-md-5">
            <div className="mt-3 lectureheight p-2 cursor ">
              <VideoLecture
                title={c.topicId?.name}
                lectcount={c.topicVideosCount}
                duration={c.duration}
                onpress={onPressVideo}
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="boxshadow lectureheight mt-4 p-3">
              <div className="accordion" id={"accordionExample" + c?._id}>
                {c.teachers?.map((d) => (
                  <div className="accordion-item border mb-2" key={d?._id}>
                    <h2 className="accordion-header" id={"headingOne" + d?._id}>
                      <button
                        className="accordion-button py-2 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapseOne" + d?._id}
                        aria-expanded="true"
                        aria-controls={"collapseOne" + d?._id}
                        onClick={() => {
                          goVideo(c?.topicId, d);
                        }}
                      >
                        <div>
                          <div className="">{d?.teacherId?.name}</div>
                          <div className="font12 mt-2">
                            {' '}
                            <img src={LocalImages.videoicon} style={{ width: '15px' }} alt='Video' />
                            <span className="ms-2">
                              {d.teacherVideosCount} Video
                            </span>
                          </div>
                        </div>
                      </button>
                    </h2>
                    {/* <div
                      id={"collapseOne"+d?._id}
                      className="accordion-collapse collapse"
                      aria-labelledby={"headingOne"+d?._id}
                      data-bs-parent={"#accordionExample"+c?._id}>
                      <div className="accordion-body">
                        {d.lectureTitle?.map((e) => (
                          <div
                            key={e?.lectureId}
                            className="boxshadow rounded p-2 px-4 mb-3 cursor"
                            onClick={() => {
                              goVideo(e.lectureId);
                            }}>
                            <div className="colorblue">{e.lectureTitle}</div>
                            <div className="font12">{timeConvert(parseInt(e.duration))}</div>
                          </div>
                        ))}
                      </div>
                    </div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default RecordedLectures;
