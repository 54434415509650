// import {
//   Keyboard, Pressable, StatusBar, StyleSheet, Text, View, Dimensions, Platform, Button,
// } from 'react-native';
import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
// import { useTheme } from '@react-navigation/native';
import { connect, useDispatch } from 'react-redux';
// import { Colors, deviceId, fontFamily, i18n, LocalImages, normalize, ScreenNames, vh, vw, screenHeight } from '@ifas/constants';
// import Loader from '@ifas/views/atoms/Loader';
// import CustomButton from '@ifas/views/atoms/CustomButton';
// import { CommonFunction } from '@ifas/utils';
// import DateTimePicker, { DateTimePickerEvent } from '@react-native-community/datetimepicker'
// import CustomTextInputAdmitCard from '@ifas/views/atoms/CustomTextInputAdmitCard';
// import { submitAdmitCard } from '@ifas/actions/ExamAction';
// import { format } from 'date-fns'
// import { SafeAreaView } from 'react-native-safe-area-context';
// import  AsyncStorage from '@react-native-community/async-storage';
import { useLocation, useNavigate } from 'react-router-dom';

function AdmitCardScreen(props) {
  // const { colors } = useTheme();
  //const admitCardData = props.examData[0][props.selectedLanguage.id]
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const examId = location.state.examId;
  const examData = location.state.examData;
  const admitCardData = examData['en'];
  // const admitCardData = examData[props.selectedLanguage.id]
  const [textInput, setTextInput] = useState([{ id: 0, value: '', error: '', regex: '' }]);
  const [loader, setLoading] = useState(false);
  const [admitCardDetails, setAdmitCardDetails] = useState(admitCardData.adminCardDetails);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateInputIndex, setDateInputIndex] = useState(0);
  const [dateT, setDateT] = useState(new Date());

  const [dateText, setDateText] = useState('');
  // var width = Dimensions.get('window').width; //full width
  // var height = Dimensions.get('window').height; //full height
  let dateFormat = 'yyyy-MM-dd';

  for (let i = 1; i < admitCardDetails.length; i++) {
    textInput.push({
      id: i,
      value: '',
      error: admitCardDetails.validationMessage,
      regex: admitCardDetails.validationExpression
    });
  }

  const _storeData = async () => {
    try {
      examData[0].timeTaken = 0;
      examData[0].isSubmitted = false;
      examData[0].questionsData.forEach((question) => {
        question.ansType = 0; // 0 - not visited, 1 - answered, 2 -not answered, 3 -marked, 4- marked & answered
        question.answer = [];
        question.ansStatus = 0;
        question.timeSpent = 0;
      });
      // await AsyncStorage.setItem(
      //   examData[0]._id,
      //   JSON.stringify(examData[0])
      // );
    } catch (error) {}
  };

  const onPressStart = () => {};

  const changeText = (index, value) => {
    let tempArr = [...textInput];

    if (value === '') {
      tempArr[index] = {
        id: index,
        value: value,
        error: admitCardDetails[index]['validationMessage'],
        regex: admitCardDetails[index]['validationExpression']
      };
      setTextInput(tempArr);
      admitCardDetails[index]['value'] = value;
    } else {
      if (
        value.match(admitCardDetails[index]['validationExpression']) ||
        admitCardDetails[index]['validationExpression'].includes('YYYY') ||
        admitCardDetails[index]['validationExpression'].includes('yyyy')
      ) {
        tempArr[index] = {
          id: index,
          value: value,
          error: '',
          regex: admitCardDetails[index]['validationExpression']
        };
      } else {
        tempArr[index] = {
          id: index,
          value: value,
          error: admitCardDetails[index]['validationMessage'],
          regex: admitCardDetails[index]['validationExpression']
        };
      }
      setTextInput(tempArr);
      admitCardDetails[index]['value'] = value;
    }
  };

  const _renderForm = () => {
    return admitCardData.adminCardDetails.map((item, index) => {
      let keyType = 'number-pad';
      if (item.fieldType === 'number') {
        keyType = 'number-pad';
      } else if (item.fieldType === 'date') {
        keyType = 'numbers-and-punctuation';
        dateFormat = item.validationExpression;
      } else if (item.fieldType === 'text') {
        keyType = 'default';
      }
      return (
        <div>
          {item.fieldType === 'date' ? (
            <div>
              {dateText.length > 0 ? <p>{item.label}</p> : null}
              <button
                onPress={() => {
                  setDateInputIndex(index);
                  setShowDatePicker(true);
                }}></button>
              <div></div>
            </div>
          ) : (
            ''
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div>
        <div keyboardShouldPersistTaps="handled" showsVerticalScrollIndicator={false}>
          <div>
            <p>{admitCardData.name}</p>

            {_renderForm()}

            <Button onPress={onPressStart} />

            <div>admitCardNote</div>
          </div>

          {React.useMemo(() => {
            return (
              showDatePicker && (
                <div>
                  {
                    <div style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Button
                        title="done"
                        onPress={() => {
                          setShowDatePicker(false);
                        }}
                      />
                    </div>
                  }
                </div>
              )
            );
          }, [showDatePicker])}
        </div>
      </div>
    </>
  );
}

export default AdmitCardScreen;
