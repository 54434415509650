import services from '../../Constant/service';
import endpoint from '../../Constant/endpoint';
import { SET, LOADER } from '../../Types/homeTypes';
import { showSuccessSnackbar } from './snackbarAction';
import { showErrorSnackbar } from './snackbarAction';
import { setParentCourse, updateMoreValues } from './AuthAction';
import moment from 'moment';

export const updateHomeValues = (value) => (dispatch) => {
  dispatch({
    type: SET,
    payload: value
  });
};
export const updateHomeMoreValues = (value) => (dispatch) => {
  dispatch({
    type: SET,
    payload: value
  });
};

export const getFreeLecture = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getFreeLecture,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) callback(res?.data?.data);
      else dispatch(showSuccessSnackbar(res?.response?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getQuote = (params, isHome) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getQuote,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        if (isHome)
          dispatch({
            type: SET,
            payload: { homeQuote: res?.data?.data?.title ? res?.data?.data?.title : '' }
          });
      } else dispatch(showSuccessSnackbar(res?.response?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getBanner = (params) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getBanner,
    params,
    (res) => {
      dispatch(getNotificationUnread());
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        dispatch({
          type: SET,
          payload: { banner: res.data.bannerData }
        });
      } else dispatch(showSuccessSnackbar(res?.response?.data?.msg));
    },
    () => {
      dispatch(getNotificationUnread());
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong11111'));
    }
  );
};

export const getCourseHome = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getCourseHome,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        if (res?.data?.data) callback(res?.data?.data);
        else callback([]);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback([]);
    }
  );
};

export const getCourseList = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getCourseList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      callback([]);
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // dispatch(showErrorSnackbar(error?.data?.msg));
    }
  );
};

export const getCourseDetail = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getCourseDetail,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else dispatch(showSuccessSnackbar(res?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getfreeCourseDetail = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getfreeCourseDetail,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else dispatch(showSuccessSnackbar(res?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const addToCart = (data, callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.addToCart,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        dispatch(updateHomeValues({ cartListDetails: res?.data?.courses }));
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else dispatch(showSuccessSnackbar(res?.msg));
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar(error?.data?.msg));
      errorCallback(error?.data?.data);
    }
  );
};

export const updateSession = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.updateSession,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        dispatch(
          updateHomeValues({
            cartAddedData: res?.data,
            cartListDetails: res?.data?.courses
          })
        );
        dispatch(showSuccessSnackbar(res?.msg));
        callback();
      } else dispatch(showSuccessSnackbar(res?.msg));
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error?.response?.data);
    }
  );
};

export const removeToCart = (data) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.removeToCart,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) {
        dispatch(showSuccessSnackbar(res?.msg));
        dispatch(getCartList(() => {}, true));
        dispatch(getRecentlyCartList());
        dispatch(getAddOnList());
      } else dispatch(showSuccessSnackbar(res?.response?.data?.msg));
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const getCartList = (callback, isLoader) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: isLoader ? false : true }));
  services.getApiCall(
    endpoint.home.cartList,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));

      if (res.status === services.status_code.success) {
        dispatch(
          updateHomeValues({
            cartAddedData: res?.data?.data ? res?.data?.data : {},
            cartListDetails: res?.data?.data?.courses ? res?.data?.data?.courses : []
          })
        );
        dispatch(setParentCourse('cartItemCount', res?.data?.data?.courses?.length));
        callback(res?.data?.data?.courses);
      } else {
      }
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};
export const getRecentlyCartList = () => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.recentlyList,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        dispatch(updateHomeValues({ recentView: res?.data?.data }));
      }
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const getAddressList = (callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.setting.addressList,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        dispatch(updateHomeValues({ addressList: res?.data?.data }));
        callback(res?.data?.data);
        if (res?.data?.data.length > 0) {
          if (res?.data?.data.length === 1)
            dispatch(updateHomeValues({ address: res?.data?.data[0] }));
          else {
            const index = res?.data?.data.findIndex((item) => item.isDefault);
            if (index > -1) dispatch(updateHomeValues({ address: res?.data?.data[index] }));
            else dispatch(updateHomeValues({ address: res?.data?.data[0] }));
          }
        }
      } else {
        dispatch(showErrorSnackbar('wentWrong'));
        errorCallback();
      }
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
      errorCallback();
    }
  );
};

export const setDefaultRemoveAddress =
  (isRemove, data, callback, ErrorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      isRemove ? endpoint.setting.deleteAddress : endpoint.setting.defaultAddress,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          dispatch(
            getAddressList(
              (data) => {
                callback(data);
              },
              () => {
                ErrorCallback();
              }
            )
          );
          callback();
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          ErrorCallback();
        }
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        dispatch(showErrorSnackbar(error?.data?.msg));
        ErrorCallback(error?.response?.data);
      }
    );
  };

export const getAddOnList = () => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.addon,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        dispatch(updateHomeValues({ addon: res?.data?.data }));
      }
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const addEditAddress =
  (isAdd, data, callback, ErrorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      isAdd ? endpoint.setting.addAddress : endpoint.setting.updateAddress,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          dispatch(
            getAddressList(
              (data) => {
                callback();
              },
              () => {
                ErrorCallback();
              }
            )
          );
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          ErrorCallback();
        }
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        ErrorCallback(error?.response?.data);
      }
    );
  };

export const getSingleAddress = (id, callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.setting.addressList,
    id,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar('wentWrong'));
        errorCallback();
      }
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
      errorCallback();
    }
  );
};

export const editProfile = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.setting.editProfile,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback();
    }
  );
};

export const applyCouponfree = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.applyCouponfree,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        dispatch(updateHomeValues({ CouponData: res.data.package }));
        callback(res);
      } else callback([]);
    },
    (error) => {
      callback([]);
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // dispatch(showErrorSnackbar(error?.data?.msg));
    }
  );
};

export const applyCoupon = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.applyCoupon,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        dispatch(updateHomeValues({ CouponData: res.data.package }));
        callback(res);
      } else callback([]);
    },
    (error) => {
      callback([]);
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // dispatch(showErrorSnackbar(error?.data?.msg));
    }
  );
};

export const applyCouponCart = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.applyCouponCart,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      callback([]);
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // dispatch(showErrorSnackbar(error?.data?.msg));
    }
  );
};

export const removeCoupon = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.removeCoupon,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const placeOrder = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.placeOrder,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const placeOrderInstallment = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.placeOrderInstallment + data,
    {},
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const updateOrder = (data, callback) => async (dispatch, getState) => {
  services.postApiCall(
    endpoint.home.updateOrder,
    data,
    (res) => {
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
        callback([]);
      }
    },
    (error) => {
      callback([]);
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getPaidCourseList = () => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getPaidCourseList,
    {
      date: moment(new Date()).format('YYYY-MM-DD')
    },
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        if (res?.data?.courses.length > 0)
          dispatch({
            type: SET,
            payload: {
              paidTiles: res?.data?.courses,
              installments: res?.data?.installments,
              selectedTiles: res?.data?.courses[0]
            }
          });
        else
          dispatch({
            type: SET,
            payload: {
              paidTiles: [],
              installments: []
            }
          });
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getUnActiveCourse = () => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getUnActiveCourse,
    {},
    (res) => {
      let paidTilesInactive = [];
      let installmentsInactive = [];
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        if (res?.data?.courses.length > 0) {
          res?.data?.courses.map((item) => {
            paidTilesInactive.push({
              ...item,
              isInactive: true,
              isSubscribed: false,
              lectureData: {}
            });
          });
        }
        if (res?.data?.installments.length > 0) {
          installmentsInactive = res?.data?.installments;
        }
      }

      services.getApiCall(
        endpoint.home.getUnActiveSubscribedCourseNew,
        '',
        (resInner) => {
          dispatch(updateHomeMoreValues({ isLoader: false }));
          if (resInner.status === services.status_code.success) {
            if (resInner?.data?.data?.length > 0) {
              resInner?.data?.data.map((item) => {
                paidTilesInactive.push({
                  ...item,
                  isInactive: true,
                  isSubscribed: false,
                  hasExtendOption: true,
                  lectureData: {}
                });
              });
            }
          }
        },
        (error) => {
          dispatch(updateHomeMoreValues({ isLoader: false }));
          dispatch(showErrorSnackbar('wentWrong'));
        }
      );

      dispatch({
        type: SET,
        payload: {
          paidTilesInactive: paidTilesInactive,
          installmentsInactive: installmentsInactive
        }
      });
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
      services.getApiCall(
        endpoint.home.getUnActiveSubscribedCourseNew,
        '',
        (resInner) => {
          let paidTilesInactive = [];
          dispatch(updateHomeMoreValues({ isLoader: false }));
          if (resInner.status === services.status_code.success) {
            if (resInner?.data?.data?.length > 0) {
              resInner?.data?.data.map((item) => {
                paidTilesInactive.push({
                  ...item,
                  isInactive: true,
                  isSubscribed: false,
                  hasExtendOption: true,
                  lectureData: {}
                });
              });
            }
          }
          dispatch({
            type: SET,
            payload: {
              paidTilesInactive: paidTilesInactive
            }
          });
        },
        (error) => {
          dispatch(updateHomeMoreValues({ isLoader: false }));
          dispatch(showErrorSnackbar('wentWrong'));
        }
      );
    }
  );
};

export const getUnActiveSubscribedCourse = () => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getUnActiveSubscribedCourse,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        if (res?.data?.data?.length > 0) {
          const newCourseData = res?.data?.data.map((item) => {
            return { ...item, isInactive: true, isSubscribed: true, lectureData: {} };
          });
          dispatch({
            type: SET,
            payload: {
              paidTilesSubscribed: newCourseData
            }
          });
        } else
          dispatch({
            type: SET,
            payload: {
              paidTilesSubscribed: []
            }
          });
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const GetMyLearningData =
  (endPoint, callback, errorCallback) => async (dispatch, getState) => {
    const baseCourse = endPoint.replace(/\s/g, '_').toLowerCase();
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.getRequestLearning(
      baseCourse,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.status === services.status_code.success) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar('wentWrong'));
          errorCallback();
        }
      },
      () => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        dispatch(showErrorSnackbar('wentWrong'));
        errorCallback();
      }
    );
  };

export const getLiveVideo = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getLiveVideo,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else dispatch(showErrorSnackbar(res?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getPremierVideo = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getPremierVideo,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else dispatch(showErrorSnackbar(res?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getSubscribedRecordedPaid = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  dispatch({ type: LOADER, payload: true });
  services.getApiCall(
    endpoint.home.getSubscribedRecordedLectures,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else dispatch(showErrorSnackbar(res?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getRecordedPaid = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  dispatch({ type: LOADER, payload: true });
  services.getApiCall(
    endpoint.home.getRecordedLectures,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else dispatch(showErrorSnackbar(res?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getRecordedPaidDetails = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getRecordedLectureDetails,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else dispatch(showErrorSnackbar(res?.data?.msg));
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getCoursePaidList = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getPaidCourseList,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else callback([]);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getSubjectList = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getSubjectList,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else callback([]);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getTopicList = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getTopicList,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        //dispatch(showErrorSnackbar('noInternet'));
        callback(res?.data?.data);
      } else callback([]);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getAddList = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.addChat,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data[0]);
      } else dispatch(showErrorSnackbar(res?.msg));
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      //dispatch(showErrorSnackbar('Please provide valid data'));
      ErrorCallback(error?.response?.data);
    }
  );
};

export const getMessageList = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getMessageList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else callback([]);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback([]);
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const addSupportMessage = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.addSupportMessage,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('Please enter message'));
    }
  );
};

export const deleteSupportMsg = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.deleteApiCall(
    endpoint.home.deleteSupportMsg,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback();
      } else callback();
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const getListSupport = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getListSupport,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) callback(res?.data?.data);
      else dispatch(showErrorSnackbar(res?.response?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getOrderList = (callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getOrderList,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) callback(res.data.data);
      else errorCallback();
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback();
    }
  );
};

export const getOrderDetails = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getOrderDetails,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) callback(res?.data?.data);
      else callback([]);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getFreeLectureList = (data, callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getFreeLectureList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) callback(res?.data);
      else errorCallback();
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback();
    }
  );
};

export const getFreeLectureByTeacher =
  (data, callback, errorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.home.getFreeLectureByTeacher,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.statusCode === services.status_code.success) callback(res.data);
        else errorCallback();
      },
      () => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        errorCallback();
      }
    );
  };

export const getLiveLecturesForFree =
  (data, callback, errorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.home.getLiveLecturesForFree,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.statusCode === services.status_code.success) callback(res.data);
        else errorCallback();
      },
      () => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        errorCallback();
      }
    );
  };

export const getLectureSchedule = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.lectureSchedule,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else callback([]);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback([]);
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getLectureScheduleFree = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.lectureScheduleFree,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
      callback([]);
    }
  );
};

export const getExtendedCourseDetail = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getExtendedCourseDetail,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else dispatch(showErrorSnackbar(res?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getNotificationList =
  (params, callback, errorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.home.notificationList,
      params,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.status === services.status_code.success) {
          callback(res?.data?.data);
        }
      },
      () => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        dispatch(showErrorSnackbar('wentWrong'));
      }
    );
  };

export const getNotificationUnread = () => async (dispatch, getState) => {
  services.getApiCall(
    endpoint.home.notificationUnread,
    '',
    (res) => {
      if (res.status === services.status_code.success) {
        dispatch(setParentCourse('unReadCount', res?.data?.data));
      } else {
        c;
      }
    },
    () => {
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getSupport = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getSupport,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else dispatch(showSuccessSnackbar(res?.data?.msg));
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error?.response?.data);
    }
  );
};

export const uploadProfilePic = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postUploadApiCall(
    endpoint.setting.uploadProfilePic,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res.data);
        dispatch(updateMoreValues(res.data));
        dispatch(showSuccessSnackbar(res?.msg));
      } else {
        dispatch(showSuccessSnackbar(res?.response?.data?.msg));
        ErrorCallback();
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error?.response?.data);
    }
  );
};
export const getstart = (params, callback) => async (dispatch, getState) => {
  //callback();
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.getstart,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res);
      } else dispatch(showErrorSnackbar('wentWrong'));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const addVideoRatings = (data, callback) => async (dispatch) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.addVideoRating,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
        dispatch(showSuccessSnackbar(res?.msg));
      } else {
        dispatch(showSuccessSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (error?.data?.data?.feedback) dispatch(showErrorSnackbar(error?.data?.data?.feedback));
      else dispatch(showErrorSnackbar(error?.data?.msg));
    }
  );
};

export const getVideoRating = (data, callback) => async (dispatch) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getVideoRating,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));

      if (res.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback();
      }
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const updateVideoRating = (_id, data, callback) => async (dispatch) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.patchApiCall(
    endpoint.home.updateVideoRating + _id,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
        dispatch(showSuccessSnackbar(res?.data?.msg));
      } else {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback();
      }
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getExamtype = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.exam.getExamType,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else dispatch(showErrorSnackbar(res?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getExamSectionList = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getExamSectionList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) callback(res.data);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const startEndLiveStatus = (data) => async (dispatch) => {
  services.postApiCall(
    endpoint.home.startEndLiveStatus,
    data,
    (res) => {},
    () => {}
  );
};

export const getExamDateList = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getExamDateList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) callback(res.data);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const getExamSyllabus = (data, callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getExamSyllabus,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) callback(res.data);
      else errorCallback();
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback();
    }
  );
};

export const getAboutExamFAQ = (data, callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getAboutExamFAQ,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) callback(res.data);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const getPyqPdf = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getPyqPdf,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) callback(res.data);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const getExamPaperAnalysis = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getExamPaperAnalysis,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) callback(res.data);
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const getCourseSubscribed = (callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getCourseSubscribed,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) callback(res?.data?.data);
      else dispatch(showSuccessSnackbar(res?.response?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getBaseCourse = (id, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.course.getBaseCourse,
    id,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));

      if (res.status === services.status_code.success) callback(res.data.data);
      else {
        ErrorCallback(res);
        dispatch(showErrorSnackbar('wentWrong'));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getCourseFreeLecture = (params, isSubExam, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    isSubExam ? endpoint.home.getCourseFreeLectureSubExam : endpoint.home.getCourseFreeLecture,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) callback(res?.data?.data);
      else dispatch(showSuccessSnackbar(res?.response?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const getCourseFreeLectureLive = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getCourseFreeLectureLive,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) callback(res?.data?.data);
      else dispatch(showSuccessSnackbar(res?.response?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const classRoomPlaceorder = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.classRoomPlaceorder,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) callback(res?.data?.data);
      else dispatch(showSuccessSnackbar(res?.response?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getUserData = (params, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getUserData,
    params,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else dispatch(showErrorSnackbar(res?.data?.msg));
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};
/**********************************Career Page********************************************/
export const getJobPosition = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.Job.getJobPosition,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      //dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};
export const getCareerJobsList = (data, callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.Job.getCareerJobsList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res);
      } else errorCallback(res);
    },
    (error) => {
      errorCallback(error);
      dispatch(updateHomeMoreValues({ isLoader: false }));
      //dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};
export const getCareerDesignationsList =
  (data, callback, errorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.Job.getCareerDesignationsList,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.statusCode === services.status_code.success) {
          callback(res);
        } else errorCallback(res);
      },
      (error) => {
        errorCallback(error);
        dispatch(updateHomeMoreValues({ isLoader: false }));
        //dispatch(showErrorSnackbar('wentWrong'));
      }
    );
  };
export const getCareerjobloction =
  (data, callback, errorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.Job.getCareerjobloction,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.statusCode === services.status_code.success) {
          callback(res);
        } else errorCallback(res);
      },
      (error) => {
        errorCallback(error);
        dispatch(updateHomeMoreValues({ isLoader: false }));
        //dispatch(showErrorSnackbar('wentWrong'));
      }
    );
  };

export const getCutoffList = (data, callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.Job.getCutoffList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res);
      } else errorCallback(res);
    },
    (error) => {
      errorCallback(error);
      dispatch(updateHomeMoreValues({ isLoader: false }));
      //dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

// export const getMonthYearList = (params) => async (dispatch, getState) => {
//   dispatch(updateHomeMoreValues({ isLoader: true }));
//   services.getApiCall(
//     endpoint.home.getMonthYearList,
//     params,
//     (res) => {
//       dispatch(getNotificationUnread());
//       dispatch(updateHomeMoreValues({ isLoader: false }));
//       if (res.status === services.status_code.success) {
//         dispatch({
//           type: SET,
//           payload: { banner: res.data.data.bannerData }
//         });
//       } else dispatch(showSuccessSnackbar(res?.response?.data?.msg));
//     },
//     () => {
//       dispatch(getNotificationUnread());
//       dispatch(updateHomeMoreValues({ isLoader: false }));
//       dispatch(showErrorSnackbar('wentWrong'));
//     }
//   );
// };

export const getMonthYearList = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getMonthYearList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      callback([]);
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // dispatch(showErrorSnackbar(error?.data?.msg));
    }
  );
};

export const getResultList = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getResultList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      callback([]);
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // dispatch(showErrorSnackbar(error?.data?.msg));
    }
  );
};

export const getReferLink = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getReferLink,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.status === services.status_code.success) {
        callback(res?.data?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback([]);
    }
  );
};

export const sendReferInvite = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.sendReferLink,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res?.msg);
      } else errorCallback(res?.msg);
    },
    (error) => {
      callback(error?.data);
      dispatch(updateHomeMoreValues({ isLoader: false }));
      //dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getMyAllRefer = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getMyRerfer,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.status === services.status_code.success) {
        callback(res?.data?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback([]);
    }
  );
};

export const getAllRefer = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getAllRefer,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback([]);
    }
  );
};

export const addingNewBank = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.addUserAccount,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback([]);
    }
  );
};

export const getReferCash = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getReferCashback,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.status === services.status_code.success) {
        callback(res?.data?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback([]);
    }
  );
};
export const getRefferer = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getRefferDetails,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback([]);
    }
  );
};
export const getLearningProgress = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getLearningProgress,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // callback([]);
    }
  );
};
export const videoWatchStatus = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.videoWatchStatus,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // callback([]);
    }
  );
};
export const getTestAttempted = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getTestAttempted,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback([]);
    }
  );
};
export const getUserRank = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getUserRank,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // callback([]);
    }
  );
};

export const getTestSeriesRank = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getTestSeriesRank,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // callback([]);
    }
  );
};

export const getPyqRank = (data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getPyqRank,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else callback([]);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // callback([]);
    }
  );
};
