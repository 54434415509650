import React from 'react';
import { useState, useEffect } from 'react';
import '../Assets/CSS/signIn.css';
import Submitbtn from '../Component/submitBtn';
import { LocalImages } from '../Constant';
import InputField from '../Component/inputField';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import service from '../Constant/service';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { loginUser } from '../Redux/Actions/AuthAction';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PhonePopup from '../Module/phonePopup';

function UserVerification() {
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoading] = useState(false);
  const [call, setCall] = useState('');
  const [visibleCallText, setVisibelCallText] = useState(false);
  const [blank, setblank] = useState(false);
  const [open, setopen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState({
    username: '',
    password: ''
  });
  const [value, setValue] = useState({
    username: '',
    password: ''
  });
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const homepage = (e) => {
    window.location.href = '/';
  };

  const handleUsername = (e) => {
    setusername(e);
  };

  const handlePassword = (e) => {
    setpassword(e);
  };
  const checkPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClose = () => {
    setopen(false);
  };
  const handleClick = (event) => {
    setopen(true);
  };

  const checkDisable = () => {
    if (username === '') {
      setError((prevState) => ({
        ...prevState,
        username: 'pleaseEnterUsername'
      }));

      return true;
    }
    if (password === '') {
      setError((prevState) => ({
        ...prevState,
        password: 'pleaseEnterPassword'
      }));

      return true;
    }
    return false;
  };

  const verifyDetailPress = () => {
    if (!checkDisable()) {
      setLoading(true);
      const payload = {
        username: username,
        password: password
      };
      dispatch(
        loginUser(
          payload,
          (response) => {
            setLoading(false);
            if (response?.statusCode === service.status_code.success) {
              dispatch(showSuccessSnackbar(response.msg));

              navigate('/MobileVerification', { state: { response: response?.data } });
            } else {
              if (response?.msgCode === '509') {
                setLoading(false);
                setNumber(response.data.maskedNumber);
                navigate('/OtpError', { state: { maskedNumber: response.data.maskedNumber } });
                setVisible(true);
              } else {
                setCall(response.data.support_number);
                setVisibelCallText(true);
                dispatch(showSuccessSnackbar(response.msg));
              }
            }
          },
          (responseAlready) => {
            setLoading(false);
            setNumber(responseAlready.maskedNumber);
            navigate('/OtpError', { state: { maskedNumber: responseAlready.maskedNumber } });
            setVisible(true);
          },
          (error) => {
            setLoading(false);
            if (error?.msgCode === '509') {
              setLoading(false);
              setNumber(error?.data?.maskedNumber);
              navigate('/OtpError', { state: { maskedNumber: error?.data?.maskedNumber } });
              setVisible(true);
            }
            if (error?.msgCode === '508') {
              setVisibelCallText(true);
              setCall(error.data.support_number);
            }
          }
        )
      );
    } else {
      dispatch(showErrorSnackbar('Please Fill username and password field'));
    }
  };
  useEffect(() => {
    if (username.length != 0 && password.length != 0) {
      setblank(false);
    } else {
      setblank(true);
    }
  }, [username, password]);
  return (
    <div className="existuserbg bgmob">
      <div className="d-block d-lg-none loginHeader text-start">
        <img
          src={LocalImages.logo}
          alt="IFAS Logo"
          className="logo cursor"
          onClick={homepage}
          style={{ width: '120px' }}
        />
      </div>
      <div className="row authDiv">
        <div className="d-none d-lg-block col-lg-7 leftSidediv">
          <div className="loginHeader ">
            <img
              src={LocalImages.logo}
              alt="IFAS Logo"
              className="logo cursor"
              onClick={homepage}
              style={{ width: '120px' }}
            />
          </div>
          <div className="imgDiv">
            {/* <img src={LocalImages.userVerfyimg} alt="signIn" className='leftImg' /> */}
          </div>
        </div>
        <div className="col-lg-5 rightDivPad position-relative">
          <h3 className="text-center colorblue fw-bold boldfont">Welcome Back to IFAS</h3>
          <p className="text-center mb-1 mt-3 colorblue">Happy to have you back</p>
          <p className="text-center">Please Verify your login details</p>

          <div className="mobileField mt-5">
            <form onSubmit={handleSubmit}>
              <div>
                <div className="me-2">
                  <label className="mb-2">Username</label>
                  <InputField
                    type="text"
                    id="username"
                    placeholder="Enter your username"
                    value={username}
                    setInputText={handleUsername}
                  />
                </div>
                <div>
                  <label className="mb-2">Password</label>
                  <div className="position-relative d-flex">
                    <InputField
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      className="w-100"
                      placeholder="Enter Password"
                      value={password}
                      maxLength={50}
                      setInputText={handlePassword}
                    />
                    <div>
                      {showPassword ? (
                        <span onClick={checkPassword} className=" password cursor">
                          <RemoveRedEyeOutlinedIcon />
                        </span>
                      ) : (
                        <span onClick={checkPassword} className=" password cursor">
                          <VisibilityOffOutlinedIcon />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {visibleCallText && (
                <div className="d-flex align-items-center">
                  <p className="text-center mb-1 mt-3" style={{ color: 'red' }}>
                    Invalid credentials,Please try again
                  </p>
                  <p className="text-center mb-1 mt-3 px-1">or</p>
                  <p
                    className="text-center mb-1 mt-3 px-1 colorblue fw-bold"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={handleClick}>
                    Call to support
                  </p>
                </div>
              )}
              <PhonePopup open={open} number={call} handleClose={handleClose} />
              <div className="mt-5 text-center">
                <Submitbtn name="Verify Details" disabled={blank} onPress={verifyDetailPress} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserVerification;
