import { ExamSelectModal, StartExamModal, SetOptionValueFalseModal } from '../../Modals';
import {
  SET_EXAM_ID_DATA,
  SET_NOT_SYNCED_DATA,
  SET_INSTRUCTIONS,
  SELECTED_LANGUAGE,
  SET_OPTION_VALUE_FALSE,
  SET_EXAM_STATUS_CHANGE_VALUE,
  SET_EXAM_STATUS_SECTION_CHANGED,
  SET_QUESTIONS_SELECTED_VALUE
} from '../../Types/examTypes';

export const ExamSelectReducer = (state = new ExamSelectModal(), action) => {
  switch (action.type) {
    case SET_NOT_SYNCED_DATA:
      return { ...state, notSyncedData: action.payload };
    case SET_EXAM_ID_DATA:
      {let keys = Object.keys({...action.payload})
      let key = keys[0]
      let value = action.payload[key];
      state.examIdData[key] = value
      return { ...state};}
    default:
      return state;
  }
};

export const StartExamReducer = (state = new StartExamModal(), action) => {
  switch (action.type) {
    case SET_INSTRUCTIONS:
      return { ...state, ...action.payload };
    case SELECTED_LANGUAGE:
      return { ...state, selectedLanguage: action.payload };
    default:
      return state;
  }
};

export const SetOptionValueFalseReducer = (state = new SetOptionValueFalseModal(), action) => {
  switch (action.type) {
    case SET_OPTION_VALUE_FALSE:
      return { ...state, isTrue: action.payload };
    case SET_EXAM_STATUS_CHANGE_VALUE:
      return {
        ...state,
        isExamStatusValueChanged: action.payload
      };
    case SET_EXAM_STATUS_SECTION_CHANGED:
      return {
        ...state,
        isExamStatusSectionChanged: action.payload
      };
    case SET_QUESTIONS_SELECTED_VALUE:
      return {
        ...state,
        isQuestionSelected: action.payload
      };
    default:
      return state;
  }
};
