import React, { useState, useEffect } from 'react';
import '../App.css';
import earn from '../Assets/Images/refer&earn/earn.png';
import referral from '../Assets/Images/refer&earn/referral.svg';
import signedup from '../Assets/Images/refer&earn/signedup.svg';
import enrolled from '../Assets/Images/refer&earn/enrolled.svg';
import inviteIcon from '../Assets/Images/refer&earn/invite.png';
import discount from '../Assets/Images/refer&earn/discount.png';
import purse from '../Assets/Images/refer&earn/purse.png';
import refer from '../Assets/Images/refer&earn/refer.png';
import leaderBorad from '../Assets/Images/refer&earn/leader-borad.jpg';
import WhatsApp from '../Assets/Images/refer&earn/whatsapp.png';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import MobileInvite from '../Component/mobileInvite';
import InviteLink from '../Component/inviteLink';
import { useDispatch } from 'react-redux';
import { addingNewBank, addingNewUpi, getAllRefer, getMyAllRefer, getReferLink, sendReferInvite } from '../Redux/Actions/homeAction';
import { showErrorSnackbar, showSuccessSnackbar } from '../Redux/Actions/snackbarAction';
import AddUpi from '../Module/addUPI';
import { Link } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Lottie from 'react-lottie';
import referralCheck from '../Assets/Images/JSON/referralCheck.json';


function ReferEarn(props) {
    const dispatch = useDispatch()
    const [referData, setReferData] = useState({})
    const [myAllRefer, setMyAllRefer] = useState({})
    const [allReferData, setAllReferData] = useState([])
    const [mobile, setMobile] = useState('')
    const [open, setopen] = useState(false);
    const [upi, setUpi] = useState('')
    const [showError, setShowError] = useState(false)
    const [isAddUpi, setIsAddUpi] = useState(true)
    const [btnShw, setBtnShw] = useState(false);
    const [isAddAcc, setIsAddAcc] = useState(false)
    const [isAddedAcc, setIsAddedAcc] = useState(false)
    const [accName, setAccName] = useState('')
    const [accNum, setAccNum] = useState('')
    const [accIfsc, setAccIfsc] = useState('')
    const accountDetails = {accName: "", accNum: "", accIfsc: ""};
    const [bankDetails, setBankDetails] = useState(accountDetails);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: referralCheck,
        renderer: 'svg'
    };
    const isSubmitDisabled = !(bankDetails.accName && bankDetails.accNum && bankDetails.accIfsc);
    const handleClose = () => {
        setopen(false);
    };
    const handleClick = (event) => {
        setopen(true);
    };
    const handleChange = (e) => {
        setMobile(e.target.value)
        e.target.value.length > 9 ? setBtnShw(true) : setBtnShw(false)
    }
    const handleChangeBankDetails = (e) => {
        const { name, value } = e.target;
        setBankDetails({...bankDetails,[name]: value});
        
    }
    const get_refer_link = async () => {
        dispatch(getReferLink('', async (response) => {
            await setReferData(response)
            if (response?.upiId) {
                setIsAddUpi(false)
            } else {
                setIsAddUpi(true)
            }
        }))
    }
    const sendReferLink = async () => {
        if (mobile?.length >= 10) {
            let requestBody = {
                userReferCode: referData?.referralCode,
                referredContactNumber: mobile
            }
            dispatch(sendReferInvite(requestBody, async (response) => {
                if (response?.statusCode === 200) {
                    dispatch(showSuccessSnackbar(response))
                    // handleClick()
                    get_my_refer()
                    setIsAddAcc(true)
                } else {
                    setIsAddedAcc(true)
                    console.log('working')
                }
            }))
        } else {
            dispatch(showErrorSnackbar('Mobile number is incorrect'))
        }
    }
    const get_my_refer = async () => {
        dispatch(getMyAllRefer('', async (response) => {
            await setMyAllRefer(response)
        }))
    }
    const get_all_refer = async () => {
        let requestBody = {
            count: 10
        }
        dispatch(getAllRefer(requestBody, async (response) => {
            await setAllReferData(response)
        }))
    }

    const addUpi = () => {
        let reqBody = {
            accountName: bankDetails.accName,
            bankAccountNo: bankDetails.accNum,
            ifscCode: bankDetails.accIfsc
        }
        dispatch(
            addingNewBank(reqBody, async (data) => {
                setopen(false)
                setUpi('')
                setIsAddedAcc(true)
            })
        );
    }

    useEffect(() => {
        get_refer_link(),
            get_my_refer(),
            get_all_refer()
    }, []);

    return (
        <section className='ref-earn my-3'>
            <div className="rightPanel">
                <div className="row mb-5">
                    <div className='col-lg-6 mb-5'>
                        <div className='earn-cashback mb-5'>
                            <div className='col-lg-6 col-md-8'>
                                <div className="card">
                                    <div className='card-body'>
                                        <div className='d-flex'>
                                            <img src={earn} alt="earning" width="35" />
                                            <p className='text-center mx-auto'>Earned Cashback <span className='d-block colorblue'>&#8377; {myAllRefer?.referralAmount ? myAllRefer?.referralAmount : 0}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-10 mb-5'>
                            <h3 className='colorblue mb-3'>Learn Together. Earn Together</h3>
                            <div className='text-holder'>
                                <p className='text-single-line'>Unlock incredible rewards as you help future scholars embark on their Ph.D./MSc entrance exam journey. Invite friends and juniors to join IFAS Edutech and enjoy cashback on every referral purchase. The more you refer, the more you win! Enroll them on our official app or website using your unique referral code and be a part of their success story. Join our community of knowledge sharers today!</p>
                            </div>
                        </div>
                        <div className="col-lg-10 mb-5">
                            <MobileInvite value={mobile} onChange={handleChange} onBtnClick={sendReferLink} isShow={btnShw} />
                        </div>
                        <div className='col-lg-10 mb-3'>
                            <div className='link-holder'>
                                <div className='row m-0'>
                                    <div className='col-md-7 ps-0 pe-lg-3 pe-0 mb-3'>
                                        <h5>Your Invite Link</h5>
                                        <InviteLink
                                            onClick={() => navigator.clipboard.writeText(referData?.link)}
                                            placeholder={referData?.link}
                                            buttonCaption="Copy Link"
                                        />
                                    </div>
                                    <div className='col-md-5 pe-lg-0 pe-0 ps-0'>
                                        <h5>Your Invite code</h5>
                                        <InviteLink
                                            placeholder={referData?.referralCode}
                                            buttonCaption="Copy Code" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='social-media-holder'>
                                <h5>Share via social</h5>
                                <div className="d-flex justify-content-start ">
                                    <div className='py-2'>
                                        <a
                                            href="https://twitter.com/ifasEdutech"
                                            className="link-secondary"
                                            target="_blank"
                                            rel="noreferrer">
                                            <span className="footericon cursor ">
                                                <TwitterIcon />
                                            </span>
                                        </a>
                                    </div>
                                    <div className='py-2'>
                                        <a
                                            href="https://www.instagram.com/ifas_online/?igshid=YmMyMTA2M2Y%3D"
                                            className="link-secondary"
                                            target="_blank"
                                            rel="noreferrer">
                                            <span className="footericon cursor">
                                                <InstagramIcon />
                                            </span>
                                        </a>
                                    </div>
                                    <div className='py-2'>
                                        <a
                                            href="https://www.facebook.com/ifasedutech"
                                            className="link-secondary"
                                            target="_blank"
                                            rel="noreferrer">
                                            <span className="footericon cursor ">
                                                <FacebookRoundedIcon />
                                            </span>
                                        </a>
                                    </div>
                                    <div className='py-2'>
                                        <a
                                            href="https://t.me/ifas_edutech"
                                            className="link-secondary"
                                            target="_blank"
                                            rel="noreferrer">
                                            <span className="footericon cursor ">
                                                <TelegramIcon />
                                            </span>
                                        </a>
                                    </div>
                                    <div className='whats-app-btn text-center ms-lg-5 webBtn'>
                                        <a href={`https://wa.me/?text=Dear Aspirant, Let’s crack the PhD,MSc State PSC Entrance examination with IFAS Edutech. IFAS- India’s No.1 AI App for IIT-JAM, CSIR, GATE, UGC NET and more. Use my referral Code ${referData?.referralCode} to get 30% OFF When you enrol for any Course. Link- https://ifasonline.com Offer valid only for the 48 hours!`} className='btn' type='button' target="_blank" rel="noreferrer"><img src={WhatsApp} alt="" className='me-2' width="30" />Invite on WhatsApp</a>
                                    </div>
                                    <div className='whats-app-btn text-center ms-lg-5 mobBtn'>
                                        <a href={`https://wa.me/?text=Dear Aspirant, Let’s crack the PhD,MSc State PSC Entrance examination with IFAS Edutech. IFAS- India’s No.1 AI App for IIT-JAM, CSIR, GATE, UGC NET and more. Use my referral Code ${referData?.referralCode} to get 30% OFF When you enrol for any Course. Link- https://ifasonline.com Offer valid only for the 48 hours!`} className='btn' type='button' target="_blank" rel="noreferrer"><img src={WhatsApp} alt="" className='mx-auto' width="25" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='col-lg-9  mx-lg-auto referal-info-box'>
                            <ul className=''>
                                <li className='mb-4 d-flex'>
                                    <div className='icon-box me-4'>
                                        <img src={inviteIcon} alt="" />
                                    </div>
                                    <div className='text-holder'>
                                        <h3>Invite Friends</h3>
                                        <p>Verify Your Phone Number & Share Your Referral Code Or Link With Your Friends.</p>
                                    </div>
                                </li>
                                {/* <li className='mb-4 d-flex'>
                                    <div className='icon-box me-4'>
                                        <img src={discount} alt="" />
                                    </div>
                                    <div className='text-holder'>
                                        <h3>Your Friends Get a 30% Discount</h3>
                                        <p>Your friends enjoy a fantastic 30% discount when they use your referral code to purchase any product on IFAS Online.</p>
                                    </div>
                                </li> */}
                                <li className='mb-4 d-flex'>
                                    <div className='icon-box me-4'>
                                        <img src={purse} alt="" />
                                    </div>
                                    <div className='text-holder'>
                                        <h3>You Earn Cashback</h3>
                                        <p>Every time your referral code is used, you earn instant cashback of 5% in your bank account.</p>
                                    </div>
                                </li>
                                <li className='mb-4 d-flex'>
                                    <div className='icon-box me-4'>
                                        <img src={refer} alt="" />
                                    </div>
                                    <div className='text-holder'>
                                        <h3>Refer More, Earn More</h3>
                                        <p>There's no limit to your earnings. Keep referring as many friends as you can, and watch your cash rewards grow!</p>
                                    </div>
                                </li>
                                <span className='vertical-line'></span>
                            </ul>
                        </div>
                    </div>
                </div>
                <section className='referral-status'>
                    <div className='container-fluid p-0'>
                        <div className='row mb-5'>
                            <div className='col-md-11'>
                                <div className='heading-holder mb-4'>
                                    <h3>Your Referral Status</h3>
                                </div>
                                <div className='row p-0'>
                                    <div className='col-lg-4 mb-3 ps-lg-0'>
                                        <Link to="MyReferral">
                                            <div className="card">
                                                <div className='card-body'>
                                                    <div className='d-flex justify-content-between'>
                                                        <p>{myAllRefer?.referEntries !== undefined ? myAllRefer?.referEntries : 0}<span className='d-block'>Referral Entries</span></p>
                                                        <img src={referral} alt="referral" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <Link to="MyReferral">
                                            <div className="card">
                                                <div className='card-body'>
                                                    <div className='d-flex justify-content-between'>
                                                        <p>{myAllRefer?.signupUsers !== undefined ? myAllRefer?.signupUsers : 0}<span className='d-block'>Successful Sign-ups</span></p>
                                                        <img src={signedup} alt="referral" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <Link to="MyReferral">
                                            <div className="card enrolled-card">
                                                <div className='card-body'>
                                                    <div className='d-flex justify-content-between'>
                                                        <p>{myAllRefer?.enrolledUser !== undefined ? myAllRefer?.enrolledUser : 0}<span className='d-block'>Enrolled Users</span></p>
                                                        <img src={enrolled} alt="referral" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='leader-board mb-5'>
                    <div className='row'>
                        <div className='heading-holder mb-4'>
                            <h3>Referral Leaderboard</h3>
                        </div>
                        <div className='col-lg-11'>
                            <div className='row leader-board-box p-2'>
                                <div className='sub-heading-holder mb-2'>
                                    <div className='col-lg-6 mb-3'>
                                        <h5 className="colorblue mb-2 mt-3 text-center">Our Top Referrals</h5>
                                    </div>
                                </div>
                                <div className='col-lg-6 mb-3 ps-0'>
                                    <div className='leader-board-img-box'>
                                        <img className="img-fluid" src={leaderBorad} alt={"Top leadersd"} />
                                    </div>
                                </div>
                                <div className='col-lg-6 mb-3 px-1'>
                                    <div className='table-responsive'>
                                        <table className='table w-100'>
                                            <thead>
                                                <tr className='border-bottom'>
                                                    <th>Rank</th>
                                                    <th>Name</th>
                                                    <th>Student Enrolled</th>
                                                    <th>Cashback</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {allReferData.map((item, indexMain) => {
                                                    return (
                                                        <tr> */}
                                                {/* <td>{indexMain + 1}</td> */}
                                                {/* image pending  */}
                                                {/* <td>{item?.userId?.firstName} {item?.userId?.lastName}</td>
                                                            <td>{item?.enrolledUser}</td>
                                                            <td>&#8377;{item?.referralAmount}</td>
                                                        </tr> */}
                                                {/* )
                                                })} */}
                                                <tr>
                                                    <td>1</td>
                                                    {/* image pending  */}
                                                    <td>Mahesh Maltwadkar</td>
                                                    <td>500</td>
                                                    <td>&#8377; 212250</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    {/* image pending  */}
                                                    <td>Mayur Jagtap</td>
                                                    <td>300</td>
                                                    <td>&#8377; 130000</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    {/* image pending  */}
                                                    <td>Tanvir Mulani</td>
                                                    <td>270</td>
                                                    <td>&#8377; 75000</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    {/* image pending  */}
                                                    <td>Shekhar Gupta</td>
                                                    <td>220</td>
                                                    <td>&#8377; 40300</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    {/* image pending  */}
                                                    <td>Harshada Sharma</td>
                                                    <td>180</td>
                                                    <td>&#8377; 25500</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    {/* image pending  */}
                                                    <td>Arun Patil</td>
                                                    <td>130</td>
                                                    <td>&#8377; 22200</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    {/* image pending  */}
                                                    <td>Anjali Patil</td>
                                                    <td>70</td>
                                                    <td>&#8377; 18100</td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    {/* image pending  */}
                                                    <td>Rutujaa Gaundge</td>
                                                    <td>65</td>
                                                    <td>&#8377; 16000</td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    {/* image pending  */}
                                                    <td>Pranali Malang</td>
                                                    <td>60</td>
                                                    <td>&#8377; 15500</td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    {/* image pending  */}
                                                    <td>Nikhil Jadhav</td>
                                                    <td>58</td>
                                                    <td>&#8377; 15300</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='col-md-11'>
                                    <div className='proven-method-holder m-3'>
                                        <h5 className="colorblue mb-2 mt-3">Maximize Your Rewards with Proven Referral Methods!</h5>
                                        <ol className='p-0'>
                                            <li><strong>Share on Socials:</strong> Post your referral link on all your social media accounts, in both regular posts and stories. Let your network know about this incredible opportunity!</li>
                                            <li><b>WhatsApp Groups:</b> Share your link in all the WhatsApp groups you're part of, especially in your college and coaching groups. Your peers might be the next champions!</li>
                                            <li><b>Broadcast to Friends:</b> Create a broadcast list on WhatsApp with friends who would love this contest. Personalize your invitation and reach out directly.</li>
                                            <li><b>Facebook Groups:</b> Share your referral link in all the relevant Facebook groups you're a member of. Expand your reach and connect with like-minded enthusiasts.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='terms-condtions'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-11'>
                                <div className='heading-holder'>
                                    <h3 className="colorblue mb-2 mt-3 text-center">Refer and Earn Policy - Terms and Conditions</h3>
                                </div>
                                <div className='text-holder'>
                                    <ol className='p-0'>
                                        <li><strong>Referral Code Usage:</strong> To qualify for the referral program, aspirants must use a valid referral code when making a purchase.</li>
                                        <li><strong>Cashback Credit:</strong> Cashback earned through referrals will be credited to the student's account within 24 hours after the referred aspirant completes their product purchase.</li>
                                        <li><strong>Privacy and Data Protection:</strong> This document outlines policies created to safeguard the identity of our alumni and personal information data from misuse. All members who register with this website are bound by these conditions.</li>
                                    </ol>
                                </div>
                                <div className='text-holder'>
                                    <p><strong>Personal Information:</strong> The portal does not collect personal information unless provided voluntarily through email, online forms, or applicable online applications.</p>
                                </div>
                                <div className='text-holder'>
                                    <p> <strong>Registration Data:</strong>The portal's profile form requires contact information (e.g., name and email address), unique identifiers (unique name and password), and demographic information (e.g., pin/zip code or age). You have the option to mark most profile information as private through appropriate settings.</p>
                                </div>
                                <div className='text-holder'>
                                    <p>Your personal information will not be disclosed to any person or organization outside this forum. </p>
                                </div>
                                <div className='text-holder'>
                                    <p>By using this platform, you agree to these terms and conditions. We prioritize your data privacy and security.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <AddUpi isAdd={isAddUpi} handleBtnClick={(val) => addUpi(val)} open={open} handleClose={handleClose} /> */}
            {isAddAcc && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="model"
                    open={isAddAcc}
                    onClose={() => {
                        setIsAddAcc(false);
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}>
                    <Fade in={isAddAcc}>
                        <div className="addUpi-modal col-md-4">
                            <div>
                                <div className="col mb-2 text-end">
                                    <ClearOutlinedIcon
                                        className="colorblue cursor"
                                        onClick={() => {
                                            setIsAddAcc(false);
                                        }}
                                    />
                                </div>
                                <b>Note:</b> <small>Below mentioned Bank details are required for referral cashback payout, when your friend purchase a course using your referral code</small>

                                <div className='modal-info-holder p-3'>

                                    <div className='label-holder my-3'>
                                        <label className="invite-label">Bank Account</label>
                                    </div>
                                    <form class="mb-3">
                                        <div className="row ">
                                            <div class="mobile-invite mb-3">
                                                <input value={bankDetails.accName} name={"accName"} onChange={handleChangeBankDetails} type="text" className="form-control" placeholder="Enter your Bank Name" required/>
                                            </div>
                                            <div class="mobile-invite mb-3">
                                                <input value={bankDetails.accNum} name={"accNum"} onChange={handleChangeBankDetails} type="text" className="form-control" placeholder="Enter your Bank Account Number" required />
                                            </div>
                                            <div class="mobile-invite mb-3">
                                                <input value={bankDetails.accIfsc} name={"accIfsc"} onChange={handleChangeBankDetails} type="text" className="form-control" placeholder="Enter IFSC Code" required/>
                                            </div>
                                            <div className='addupi-btn-holder text-center mb-3'>
                                                <button onClick={() => addUpi()} className='btn' type='button' disabled={isSubmitDisabled}>Add Bank Account</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            )}
            {isAddedAcc && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="model"
                    open={isAddedAcc}
                    onClose={() => {
                        setIsAddedAcc(false);
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}>
                    <Fade in={isAddedAcc}>
                        <div className="addUpi-modal col-md-4">
                            <div>
                                <div className="col mb-2 text-end">
                                    <ClearOutlinedIcon
                                        className="colorblue cursor"
                                        onClick={() => {
                                            setIsAddedAcc(false);
                                        }}
                                    />
                                </div>
                                <div className='modal-info-holder'>
                                    <div className='animation-holder mb-4'>
                                        <Lottie options={defaultOptions} height={130} width={200}></Lottie>
                                    </div>
                                    <div className='text-holder text-center my-5'>
                                        <h5>Your Referral Details Are Submitted Successfully.</h5>
                                        <p>To see your referral status <Link to="">click here</Link></p>
                                    </div>
                                    <div className='addupi-btn-holder text-center mb-3'>
                                        <button className='btn' type='button' onClick={() => {setIsAddedAcc(false), setIsAddAcc(true)}}>Bank Account change request</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            )}
        </section >
    );
}

export default ReferEarn;