import React, { useState } from 'react';
import ButtonPrimary from '../Component/buttonPrimary';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import videoicon from '../Assets/Images/Learn/videoicon.png';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import ShakaPlayer from 'shaka-player-react';
import 'shaka-player/dist/controls.css';

function SubjectTestDetails(data1) {
  const [disable, setDisable] = useState(false);
  const [open, setOpen] = useState(false);

  const clickButton = () => {
    if (data1?.data1?.totalQuestions > data1?.data1?.totalAddedQuestion) {
      dispatch(showErrorSnackbar('This Exam will be available soon, Please try after sometime'));
    }
  };
  return (
    <div className="boxshadow d-flex p-3 rounded mb-3 bgwhite">
      <div className="px-2 d-flex justify-content-between flex-fill">
        <div className="d-flex justify-content-between flex-column">
          {data1.hasActivePaidPlan ? (
            <h6 className="text-uppercase">{data1?.data1?.en.name}</h6>
          ) : (
            <h6 className="text-uppercase">{data1?.data1?.name}</h6>
          )}
          <div className="d-flex">
            <div>
              <p className="mb-1 colorgrey mt-3">Questions</p>
              <p className="colorblue text-center mb-0">{data1?.data1?.totalQuestions} Q</p>
            </div>
            <div className="mx-2">
              <p className="mb-1 colorgrey mt-3">Duration</p>
              <p className="colorblue text-center mb-0 ">{data1.data1.duration} Min</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end flex-column">
          {data1?.data1?.discussionVideo?.awsDesktopUrl ? (
            <div className="d-flex justify-content-center flex-row my-2">
              <div className="imgcircle cursor">
                <img
                  onClick={() => setOpen(true)}
                  src={videoicon}
                  alt="video Icon"
                  style={{ width: '20px' }}
                />
              </div>
              {data1.data1.status === 2 ? (
                <div className="successcolor checkcircle ">
                  <CheckCircleIcon />
                </div>
              ) : null}
            </div>
          ) : data1?.data1?.discussionVideo?.youtubeUrl ? (
            <div className="d-flex justify-content-center flex-row my-2">
              <div className="imgcircle cursor">
                <a
                  href={data1?.data1?.discussionVideo?.youtubeUrl}
                  target="_blank"
                  rel="noreferrer">
                  <img src={videoicon} alt="video Icon" style={{ width: '20px' }} />
                </a>
              </div>
              {data1.data1.status === 2 ? (
                <div className="successcolor checkcircle ">
                  <CheckCircleIcon />
                </div>
              ) : null}
              {data1?.data1?.pyqpdf ? (
                <div className="d-flex align-items-center">
                  <a
                    className=" font12 d-flex align-items-center"
                    href={data1?.data1?.pyqpdf}
                    target="_blank"
                    rel="noreferrer">
                    <img src={LocalImages.pdff} alt="pdf" style={{ width: '23px' }} />
                  </a>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center my-2">
              {data1.data1.status === 2 ? (
                <div className="successcolor checkcircle ">
                  <CheckCircleIcon />
                </div>
              ) : null}
            </div>
          )}
          <div className="d-flex justify-content-center">
            {data1.data1.status === 2 ? (
              <div>
                <button
                  type="button"
                  className="btn bg-green px-2 px-sm-4 py-1 font11"
                  onClick={() => data1.onStartExam(data1.examId)}>
                  Test Analysis
                </button>
              </div>
            ) : (
              <ButtonPrimary
                name={
                  data1?.data1?.totalAddedQuestion == 0 || data1?.data1?.totalQuestions == 0
                    ? 'Coming soon'
                    : 'Start Now'
                }
                gotoLink={() => data1.onStartExam(data1.examId, setDisable(true))}
                onClick={clickButton}
                disabled={data1?.data1?.totalAddedQuestion == 0 || data1.startdata === '1'}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="modeldiv text-center">
            <div className="d-flex justify-content-evenly mt-5">
              <ShakaPlayer autoPlay src={data1?.data1?.discussionVideo?.awsDesktopUrl} />
            </div>
            <button
              type="button"
              className="btn btn-primary px-5 my-4"
              onClick={() => setOpen(false)}>
              Close
            </button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default SubjectTestDetails;
