import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function MoreBtn(props) {
  return (
    <button
      className="btn btn-secondary py-1 px-2 me-sm-5 d-flex align-items-center justify-content-evenly"
      style={{ width: '90px', height: '35px' }}
      onClick={props.gotoLink}>
      More <ArrowForwardIosIcon className="arrowfont" />
    </button>
  );
}

export default MoreBtn;
