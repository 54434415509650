import React,{useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import NatBtn from './natbtn';
import { maxAttemptReachedMsg, maxDecimalLimiReachedMsg } from '../Utils/errormsg';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ReactHtmlParser from 'react-html-parser';

const no = ['7', '8', '9', 'c', '4', '5', '6', '-', '1', '2', '3', 'ok', '0', '.', 'clear'];
const initailAns = 'please click here to enter your answer';

function Nat(props) {
  const [open, setOpen] = useState(false);
  const [openimage, setimage] = useState(false);
  const [popimg, setpopimg] = useState('');
  const selectedQuestionIndex = props.selectedQuestionIndex;
  const questionData = props.questionData;
  const questionSelected = questionData[selectedQuestionIndex];
  const qNo = questionSelected?.questionNumber;
  const marks = questionSelected.marks;
  const negativeMarks = questionSelected.negativeMarks;
  let questionTypeDescription = 'NAT';
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setimage(false);
  };
  const handleimage = (val) => {
    setpopimg(val);
    setimage(true);
  };

  const [ans, setAns] = useState();
  const [str, setStr] = useState(props.natAnswer ?? '');
  const [showMaxDecimalLimitReached, setShowMaxDecimalLimitReached] = useState(false);
  const {
    isMaxAttemptReached,
    isLastSection,
    isNormalExamFlow,
    findNextQuestionNumberLegend,
    findPreviousQuestionNumberLegend,
    filterQuestionData
  } = props;

  useEffect(() => {
    if (props.natAnswer !== '') setAns(props.natAnswer);
    else setAns('');

    setStr(props.natAnswer ?? '');
  }, [props.natAnswer]);

  useEffect(() => {
    setShowMaxDecimalLimitReached(false);
  }, [selectedQuestionIndex]);

  const calculateDot = (val) => {
    let count = 0;
    for (let i = 0; i < val.length; i++) {
      if (val[i] === '.') count = count + 1;
    }
    return count;
  };

  const getAns = (val) => {
    setShowMaxDecimalLimitReached(false);
    let temp = ans;
    if (val === 'clear') {
      setAns('');
      setStr('');
      temp = '';
      if (questionSelected?.ansType !== 3) {
        props.setIsSaveAnswered(false);
      }
    } else if (val === 'ok') {
      setOpen(false);
      if (ans === initailAns || ans === '') props.setIsSaveAnswered(false);
      else {
        props.setIsSaveAnswered(true);
        props.updateNatAnswer(questionSelected, temp);
      }
    } else if (val === 'c') {
      let strC = ans.toString();
      strC = strC.substring(0, strC.length - 1);
      setAns(strC);
      setStr('');
      props.setIsSaveAnswered(true);
      temp = strC;
    } else {
      let currentVal = str + val;
      if (currentVal.includes('.')) {
        if (
          calculateDot(currentVal) <= 1 &&
          currentVal.length <= currentVal.indexOf('.') + 1 + questionSelected.decimalPlaces
        ) {
          setAns(currentVal);
          setStr(currentVal);
          temp = currentVal;
          props.setIsSaveAnswered(true);
        } else {
          setShowMaxDecimalLimitReached(true);
          temp = str;
          props.setIsSaveAnswered(true);
        }
      } else {
        setAns(currentVal);
        setStr(currentVal);
        temp = currentVal;
        props.setIsSaveAnswered(true);
      }
    }
    if (isNaN(parseFloat(temp))) props.setNatAnswer('');
    else props.setNatAnswer(parseFloat(temp));
    if (questionSelected?.ansType === 3) {
      // props.setIsSaveAnswered(false);
      props.setIsSaveAnswered(true);
      props.updateNatAnswer(questionSelected, temp);
    }
  };
  const getQuestionDescription = (question) => {
    return question.en?.title?.map((item) => {
      return item?.descType === 'img' ? (
        <div className="">
          <img
            className="qusimg cursor"
            alt={item?.description}
            key={item?._id}
            src={item?.description}
            onClick={() => handleimage(item?.description)}
          />
        </div>
      ) : (
        ReactHtmlParser(item?.description)
      );
    });
  };

  return (
    <div className="p-lg-1 h-100">
      <span style={{ color: 'red' }}>{isMaxAttemptReached ? maxAttemptReachedMsg : ''}</span>
      <div className="questionBox boxshadow p-2 p-lg-3 rounded">
        <div className="d-flex justify-content-between">
          <div>
            {isNormalExamFlow ? (
              <>
                {qNo != '1' ? (
                  <span className="leftarrow">
                    <span
                      className="position-absolute"
                      onClick={(e) => props.handleQuestionNoClick(selectedQuestionIndex - 1)}>
                      {qNo - 1}
                    </span>
                  </span>
                ) : null}
              </>
            ) : filterQuestionData &&
              filterQuestionData[0].questionNumber != questionSelected?.questionNumber ? (
              <span className="leftarrow">
                <span
                  className="position-absolute"
                  onClick={(e) =>
                    props.handleNextQuestionLegend(questionSelected?.questionNumber, false)
                  }>
                  {findPreviousQuestionNumberLegend(questionSelected?.questionNumber)}
                </span>
              </span>
            ) : null}
            <span>
              Q. {qNo}/{props.totalNoOfQuestion}
            </span>{' '}
            <span className="greentxt p-1 rounded mx-1">{marks}</span>{' '}
            <span className="redtxt p-1 rounded mx-1">
              {negativeMarks === 0 ? `${negativeMarks}` : `-${negativeMarks}`}
            </span>
            <span className="greentxt p-1 rounded mx-1">{questionTypeDescription}</span>
          </div>
          {isNormalExamFlow ? (
            <>
              {!(questionData.length - 1 === selectedQuestionIndex && isLastSection) ? (
                <span className="rightarrow">
                  <span
                    className="position-absolute"
                    onClick={(e) => props.handleQuestionNoClick(selectedQuestionIndex + 1)}>
                    {qNo + 1}
                  </span>
                </span>
              ) : null}
            </>
          ) : filterQuestionData &&
            filterQuestionData[filterQuestionData.length - 1].questionNumber !=
              questionSelected?.questionNumber ? (
            <span className="rightarrow">
              <span
                className="position-absolute"
                onClick={(e) => props.handleNextQuestionLegend(questionSelected?.questionNumber)}>
                {findNextQuestionNumberLegend(questionSelected?.questionNumber)}
              </span>
            </span>
          ) : null}
        </div>
        <div className="question fw-bold mt-2 p-2">{getQuestionDescription(questionSelected)}</div>
      </div>
      <div className="p-3 shadow-sm cursor rounded" onClick={handleOpen}>
        {ans === '' ? initailAns : ans}
      </div>

      {/* <div className="optionBox mt-4">
                <div className="d-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm" onClick={() => {handleOnPressAnswerOption(0)}}>
                    <label className="radiocontainer">
                        <input type="radio" name="radio" checked={firstOptionSelected}/>

                        <span className="checkmark">1</span>
                    </label>
                    <span className="ms-3 otiontxt">{firstOption.replace(/(<([^>]+)>)/gi, "")}</span>
                </div>
                <div className="d-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm" onClick={() => {handleOnPressAnswerOption(1)}}>
                    <label className="radiocontainer">
                        <input type="radio" name="radio" checked={secondOptionSelected}/>

                        <span className="checkmark">2</span>
                    </label>
                    <span className="ms-3 otiontxt">{secondOption.replace(/(<([^>]+)>)/gi, "")}</span>
                </div>
                <div className="d-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm" onClick={() => {handleOnPressAnswerOption(2)}}>
                    <label className="radiocontainer">
                        <input type="radio" name="radio" checked={thirdOptionSelected}/>
                        <span className="checkmark">3</span>
                    </label>
                    <span className="ms-3 otiontxt">{thirdOption.replace(/(<([^>]+)>)/gi, "")}</span>
                </div>
                <div className="d-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm" onClick={() => handleOnPressAnswerOption(3)}>
                    <label className="radiocontainer">
                        <input type="radio" name="radio" checked={fourthOptionSelected}/>

                        <span className="checkmark">4</span>
                    </label>
                    <span className="ms-3 otiontxt">{fourthOption.replace(/(<([^>]+)>)/gi, "")}</span>
                </div>
            </div> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model cursor"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="bgwhite natModel p-3 rounded position-absolute bottom-0 end-0 ">
            <div className="col-sm-3 border-top border-2 justify-content-top ps-5 pb-5 topborder"></div>
            <div className="modal-body pt-5">
              <span className="d-block" style={{ color: 'red' }}>
                {showMaxDecimalLimitReached ? maxDecimalLimiReachedMsg : null}
              </span>
              <div className="bgwhite p-3 ansbody rounded  boxshadow ">
                <p className="fw-bold">Your Answer</p>
                <input
                  type="ans"
                  value={ans === initailAns || ans === '' ? 'Please enter your answer' : ans}
                  placeholder="Please enter your answer"
                  className="w-100 rounded p-2 mb-3  border border-secondary"
                />
                {no.map((c, index) => {
                  return <NatBtn key={index} no={c} getno={(value) => getAns(value)} />;
                })}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={openimage}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={openimage}>
          <div className="modeldiv editprofEdit">
            <div className="col d-flex mt-0 justify-content-end ">
              <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
            </div>
            <div className="">
              <img className="img-fluid " src={popimg} alt="images" />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Nat;
