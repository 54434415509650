import React, { useEffect, useState } from 'react';

import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CopyButton from '../Component/copybtn';
import { useSelector } from 'react-redux';

function RemindMe(props) {
  const { examTypeName } = useSelector((state) => ({
    examTypeName: state?.AuthReducer?.examTypeName
  }));
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="modeldiv">
          <div className="col mb-4 d-flex justify-content-between position-relative">
            <div className="text-center w-100">
              <h3 className="colorblue fw-bold">Alert Is On! Will Inform</h3>
              <h3 className="colorblue fw-bold">{`You About ${examTypeName} June Contest`}</h3>
            </div>

            <ClearOutlinedIcon
              className="colorblue cursor position-absolute end-0"
              onClick={props.handleClose}
            />
          </div>
          <div className="ps-0 form-check">
            <div className="ps-0 m-auto text-center mb-2 d-flex justify-content-between width60">
              Invite your friends and compete with them In Contest And Win Free Course Subscription
            </div>
            <div className="d-flex justify-content-evenly mt-5">
              {/* <button type='button' className='btn btn-primary px-5 cursor'>Copy Link</button> */}
              <div className="cursor copylinkDiv rounded">
                {/* Copy Link <img className="CopyLink" src={LocalImages.CopyLink} alt="CopyLink"/> */}
                {props.text}{' '}
                <CopyButton textToCopy={`${props.text}`} resetInterval={props?.resetInterval} />
              </div>
            </div>
            <h5 className="colorblue text-center mt-3">Share with your friends</h5>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default RemindMe;
