import React, { useEffect, useState } from 'react';
import '../Assets/CSS/home.css';
import Footer from '../Component/footer';
import ClassroomInPocket from '../Component/classroomInPocket';
import { LocalImages } from '../Constant';
import data from '../Assets/DummyData/classroomCoaching.json';
import HeaderHome from '../Module/headerHome';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { classRoomPlaceorder } from '../Redux/Actions/homeAction';
import axios from 'axios';
import ClassroomRegisterPopup from '../Module/classroomRegisterPopup';
import SEODetails from '../Module/metaTitle';
import { Helmet } from 'react-helmet';

const ClassroomCoachingDetails = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  // const baseCourseName = location.state.baseCourseName;
  const desc = localStorage.getItem('desc');
  const { baseCourseName } = useParams();
  const [classroomData, setclassroomData] = useState();
  const [baseCourse, setbaseCourse] = useState();
  const { isClassRoom, classroomId, isClassRoomPayment } = useSelector((state) => ({
    classroomId: state?.AuthReducer?.classroomId,
    isClassRoom: state?.AuthReducer?.isClassRoom,
    isClassRoomPayment: state?.AuthReducer?.isClassRoomPayment
  }));
  const { ACCESS_TOKEN_NEW } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));
  useEffect(() => {
    document.title = props.title;
    if (classroomId) {
      if (classroomId.toUpperCase() === '1') {
        setbaseCourse('life_science');
      } else if (classroomId.toUpperCase() === '2') {
        setbaseCourse('chemical_science');
      } else if (classroomId.toUpperCase() === '3') {
        setbaseCourse('physical_science');
      } else if (classroomId.toUpperCase() === '4') {
        setbaseCourse('mathematical_science');
      } else {
        setbaseCourse('life_science');
      }
    }
  }, [classroomId]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (baseCourseName) {
      if (baseCourseName.toUpperCase() === 'LIFE-SCIENCE-COACHING') {
        setbaseCourse('life_science');
      } else if (baseCourseName.toUpperCase() === 'CHEMICAL-SCIENCE-COACHING') {
        setbaseCourse('chemical_science');
      } else if (baseCourseName.toUpperCase() === 'PHYSICAL-SCIENCE-COACHING') {
        setbaseCourse('physical_science');
      } else if (baseCourseName.toUpperCase() === 'MATHEMATICAL-SCIENCE-COACHING') {
        setbaseCourse('mathematical_science');
      } else {
        setbaseCourse('life_science');
      }
    }
  }, [baseCourseName]);

  // let classroomData;

  let content, TABLE, tableContent, tableNote, FAQ, Name, bannerimg, features, registration;
  useEffect(() => {
    setclassroomData(data[baseCourse]);
    content,
      TABLE,
      tableContent,
      tableNote,
      FAQ,
      Name,
      bannerimg,
      features,
      (registration = classroomData);
  }, [baseCourse]);
  console.log("dfsdfsfgsdgdfgdfgdfg:-",classroomData);

  // const classroomData = data[baseCourse]
  async function handleIssue(data = {}) {
    const config = {
      headers: {
        'Content-Type': 'text/plain'
      },
      responseType: 'text'
    };
    const response = await axios.post(
      process.env.REACT_APP_BASEURL + '/v1/users/ccavRequestHandler',
      data,
      config
    );
    // setDescription({__html:response.data})

    const temp = JSON.parse(response.data);
    // navigate(temp?.data)
    return temp;
  }
  const gotoclassroomregistration = () => {
    if (ACCESS_TOKEN_NEW) {
      if (isClassRoom) {
        dispatch(
          classRoomPlaceorder('', async (res) => {
            const orderNo = res?.orderData?.orderNo;
            const orderId = res?.orderData?.id;
            const data = `merchant_id=${process.env.REACT_APP_CC_MERCHANT_ID}&order_id=${orderNo}&currency=INR&amount=1000.0&redirect_url=${process.env.REACT_APP_BASEURL}/v1/users/ccavResponseHandler&cancel_url=${process.env.REACT_APP_BASEURL}/v1/users/ccavResponseHandler`;
            const paymentResponse = await handleIssue(data);
            window.location.href = paymentResponse?.data;
          })
        );
      } else {
        setopen(true);
      }
    } else {
      window.location.href = '/ClassroomSignIn';
    }
    // window.location.href = 'ClassroomSignIn';
  };
  // const { FAQ, Name,bannerimg, features, registration } = classroomData

  const topperList = [
    {
      name: 'Ramika Singla',
      path: LocalImages.ramikaSingla
    },
    {
      name: 'Ajay Dangi',
      path: LocalImages.AjayDangi
    },
    {
      name: 'Subhamoy Bhowmik',
      path: LocalImages.shubhamoy
    },
    {
      name: 'Arti Rathore',
      path: LocalImages.artiRathore
    },
    {
      name: 'Royal Pradhan',
      path: LocalImages.royalPradhan
    },
    {
      name: 'Saireet Misra',
      path: LocalImages.saireetMishra
    },
    {
      name: 'Khushbu Pilania',
      path: LocalImages.KhushbuPilania
    },
    {
      name: 'Rohan Chowdhury',
      path: LocalImages.rohanChowdhary
    },
    {
      name: 'Royal Pradhan',
      path: LocalImages.royalPradhan
    },
    {
      name: 'Saireet Misra',
      path: LocalImages.saireetMishra
    },
    {
      name: 'Khushbu Pilania',
      path: LocalImages.KhushbuPilania
    },
    {
      name: 'Rohan Chowdhury',
      path: LocalImages.rohanChowdhary
    }
  ];
  const [open, setopen] = useState(false);
  const handleClose = () => {
    setopen(false);
  };

  return (
    <div className={ACCESS_TOKEN_NEW ? 'freeuserrightsidepanel' : ''}>
      {ACCESS_TOKEN_NEW ? null : <HeaderHome />}
      <SEODetails title={classroomData?.metaTitle} description={classroomData?.metaDescription} canonical={classroomData?.canonical} />
      <div className="">
        <img
          className="img-fluid w-100"
          src={LocalImages[classroomData?.bannerimg]}
          alt={classroomData?.alt}></img>
      </div>
      <div className="row mx-4 pt-4 mb-4">
        <h1 className="text-center mb-2 colorblue coursenametitle fw-bold">
          {classroomData?.Name}  
        </h1>
        <div className="col-md-10  mx-auto mb-4">
          {classroomData?.content?.map((item) => (
            <p className="" dangerouslySetInnerHTML={{ __html: item.text }}></p>
          ))}
        </div>
        <p className="text-center mb-2 ">Classroom Coaching Feature</p>
        {classroomData?.features?.map((item) => (
          <div className="col-md-4 col-6  text-center">
            <img className="featureimg" src={LocalImages[item.img]} alt="classroom"></img>
            <p className="fw-bold">{item.name}</p>
          </div>
        ))}
      </div>
      <div className="mx-5 p-4 mb-4  rounded detailbg  text-center">
        <div className="row mx-xl-5 mb-3">
          {classroomData?.registration?.map((item) => (
            <div className="col-md-3 col-6 text-center ">
              <div className="mx-xl-4 px-xl-4 text-center ">
                <p className=" fw-bold colorblue mb-0">
                  <span className="  me-1">
                    {' '}
                    <img className="detailimg " src={LocalImages[item.img]} alt="classroom"></img>
                  </span>

                  {item.name}
                </p>
              </div>
              {item.subtext?.map((item) => {
                return <p className=" fw-bold mb-1">{item}</p>;
              })}
            </div>
          ))}
        </div>
        <div className="text-center ">
          {isClassRoomPayment ? (
            <div className="text-success fw-bold">
              You Have already registered for this classroom
            </div>
          ) : (
            <>
              <button className="btn btn-primary" onClick={gotoclassroomregistration}>
                {' '}
                Book Your Classroom Seat
              </button>
              <p className="text-danger">Registration fee 1000/-</p>
            </>
          )}
        </div>
      </div>
      <div className="app-achiev common-pad pt-2 pb-2 text-center bg">
        <p className="text-center nameheading mb-3">CSIR NET TOPPERS</p>

        <div className="row content-topper mt-3 col-lg-10 mx-auto">
          {topperList?.map((c, indexMain) => {
            return (
              <div key={indexMain.toString()} className=" col-6 col-sm-2 rounded mb-3">
                <img className="img-fluid" src={c.path} alt={c.name} />
                <span className="d-block mt-2">{c.name}</span>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="row mb-4">
        <div className="col-md-8 mx-auto my-5">
          <h2 className="text-center mb-4 colorblue coursenametitle">
            {' '}
            {classroomData?.tableHeading}
          </h2>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Course</th>
                <th>Fees</th>
                <th>Duration</th>
                <th>Registration</th>
              </tr>
            </thead>
            <tbody>
              {classroomData?.TABLE?.tableContent?.map((c, index) => {
                return (
                  <tr key={index}>
                    <td>{c.course}</td>
                    <td>{c.fees}</td>
                    <td>{c.duration}</td>
                    <td>{c.registration}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p className="fw-bold">{classroomData?.tableNote}</p>
        </div>
      </div> */}
      <div className="row px-4 pt-4">
        <p className="text-center mb-2 colorblue coursenametitle fw-bold">
          Some Frequently Asked Questions
        </p>

        <div
          className="accordion accordion-flush row d-flex justify-content-center"
          id="accordionFlushExample">
          <div className="accordion-item noborder col-md-8 ">
            {classroomData?.FAQ?.map((c, index) => {
              return (
                <div className="boxshadow" style={{ margin: '6px' }}>
                  <h2 className="accordion-header" id={'heading' + index}>
                    <button
                      className={`accordion-button  ${index === 0 ? '' : 'collapsed'}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={'#collapse' + index}
                      aria-expanded={index === 0 ? 'true' : 'false'}
                      aria-controls={'collapse' + index}>
                      {c?.title}
                    </button>
                  </h2>
                  <div
                    id={'collapse' + index}
                    className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                    aria-labelledby={'heading' + index}
                    data-bs-parent={'#accordionExample'}>
                    <div className="accordion-body">
                      {c?.text}
                      {c?.answer?.map((a) => {
                        return <p>{a.module}</p>;
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {ACCESS_TOKEN_NEW ? null : <ClassroomInPocket />}
      {ACCESS_TOKEN_NEW ? null : <Footer />}
      <ClassroomRegisterPopup open={open} handleClose={handleClose} />
    </div>
  );
};

export default ClassroomCoachingDetails;
