import React, { useState, useEffect } from 'react';
import '../App.css';
import Lottie from 'react-lottie';
import Header from '../Module/header';
import studymaterial from '../Assets/Images/Dashboard/studyMaterial.json';
import Expert from '../Assets/Images/JSON/expert.json';
import TalktoExpert from '../Module/talktoExpert';
import lecture from '../Assets/Images/Dashboard/lecture.json';
import LectureCard from '../Component/lectureCard';
import CourseCard from '../Module/courseCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LocalImages } from '../Constant';
import MoreBtn from '../Component/moreBtn';
import Carousel from 'react-grid-carousel';
import HeaderHome from '../Module/headerHome';
import {
  getCourseHome,
  getCourseFreeLecture,
  updateHomeValues,
  getCourseFreeLectureLive
} from '../Redux/Actions/homeAction';
import { updateCourseDetailValues } from '../Redux/Actions/courseDetailAction';
import ClassroomInPocket from '../Component/classroomInPocket';
import Footer from '../Component/footer';

import topperListData from '../Assets/DummyData/topperData.json';
import { getId, getName } from '../Redux/Actions/AuthAction';
import SEODetails from '../Module/metaTitle';

function CourseDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { baseCoursename, examName, subExamName } = useParams();
  const [baseCourseID, setBaseCourseID] = useState('');
  const [examId, setExamId] = useState('');
  const [open, setopen] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Expert,
    renderer: 'svg'
  };
  const [seo, setSeoData] = useState();

  const { ACCESS_TOKEN_NEW } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));

  const topperList = topperListData.toppers.filter((item) => item?.id === examId);

  const { upcoming, freeVideo, courseHome, moreFree, examTypeName } = useSelector((state) => ({
    moreFree: state?.HomeReducer?.moreFree,
    examTypeName: state?.AuthReducer?.examTypeName,
    upcoming: state?.HomeReducer?.upcoming,
    freeVideo: state?.HomeReducer?.freeVideo,
    courseHome: state?.HomeReducer?.courseHome
  }));
  useEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(
      updateHomeValues({
        courseHome: [],
        upcoming: [],
        freeVideo: [],
        courseList: [],
        courseSelect: ''
      })
    );
    if (examName) {
      const data = {
        seoName: examName,
        seoBaseCourseName: baseCoursename
      };
      dispatch(updateHomeValues({ isLoader: false }));
      dispatch(
        getId(
          data,
          (res) => {
            const { examTypeId, subExamTypeId, examTypeSeo, baseCourseId } = res;
            setSeoData(examTypeSeo);
            let url1 = `/${examTypeId}/${baseCourseId}`;
            let url2 = `/${baseCourseId}/${examTypeId}`;
            setBaseCourseID(baseCourseId);
            setExamId(examTypeId);
           
            if (subExamTypeId) {
              url2 = `/${baseCourseId}/${examTypeId}/${subExamTypeId}`;
              url1 = `/${examTypeId}/${baseCourseId}/${subExamTypeId}`;
            }

            if (ACCESS_TOKEN_NEW) {
              dispatch(
                getCourseFreeLectureLive(url1, async (data) => {
                  dispatch(
                    getCourseHome(url2, (dataCourse) => {
                      dispatch(updateHomeValues({ courseHome: dataCourse }));
                    })
                  );
                  dispatch(updateHomeValues({ upcoming: data?.liveLecture }));
                  dispatch(updateHomeValues({ freeVideo: data?.freeLectures }));
                  const moreData = data?.freeLectures;
                  dispatch(
                    updateHomeValues({
                      moreFree: moreData,
                      isLoader: false
                    })
                  );
                })
              );
            } else {
              dispatch(
                getCourseHome(url2, (dataCourse) => {
                  dispatch(updateHomeValues({ courseHome: dataCourse }));
                })
              );
              dispatch(
                getCourseFreeLecture(url1, subExamTypeId ? true : false, async (data) => {
                  dispatch(
                    updateHomeValues({
                      moreFree: data?.freeLectures,
                      isLoader: false,
                      freeVideo: data?.freeLectures
                    })
                  );
                })
              );
            }
          },
          () => {}
        )
      );
    } else {
      dispatch(
        updateHomeValues({
          courseHome: [],
          upcoming: [],
          freeVideo: [],
          courseList: [],
          courseSelect: ''
        })
      );
    }
  }, [baseCoursename, examName, subExamName]);
  console.log("exmaId",examId);
  const upcomingArray = [];
  for (let i = 0; i < 3; i++) {
    upcomingArray.push(upcoming[i]);
  }

  const courseroute = () => {
    navigate('/MoreCourse', { state: { examId: examId, baseCourse: baseCourseID } });
  };

  const allFreeLectures = () => {
    navigate('/FreeLectures', { state: { free: moreFree } });
  };
  const smRoute = (dataStudyMaterial) => {
    const { _id, baseCourseId, examTypeId, subExamTypeId } = dataStudyMaterial;
    dispatch(
      updateCourseDetailValues({
        packageSection: [],
        sessions: [],
        courseDetail: {}
      })
    );
    const data = {
      baseCourseId,
      examTypeId
    };
    if (subExamTypeId) data.subExamTypeId = subExamTypeId;
    dispatch(
      getName(
        data,
        (res) => {
          const { seoBaseCourseName, seoSubExamName, seoExamTypeName } = res;
          navigate(
            '/' +
              `${seoSubExamName ? seoSubExamName : seoExamTypeName}` +
              '/' +
              seoBaseCourseName +
              '-study-material' +
              '/' +
              _id
          );
        },
        () => {}
      )
    );
  };
  const lsRoute = (courseID, courseName) => {
    navigate('/LectureSeries/' + courseName + '/' + courseID, {
      state: {
        courseID: courseID,
        isextended: false,
        baseCoursename: baseCoursename,
        examName: examName
      }
    });
  };
  const courseDetails = (courseID, courseName) => {
    navigate('/CourseDetails/' + courseName + '/' + courseID, {
      state: { courseID: courseID, isextended: false }
    });
  };
  const handleClose = () => {
    setopen(false);
  };
  const handleClick = () => {
    setopen(true);
  };
  const study = [
    {
      name: 'Study Material',
      path: studymaterial,
      subtextsm: 'Best IIT JAM Life Science Study Material Package in Printed Book Version',
      defaultOptions: {
        loop: true,
        autoplay: true,
        animationData: studymaterial,
        renderer: 'svg'
      },
      link: smRoute
    },
    {
      name: 'Lecture Series',
      subtextls: 'Best IIT JAM Life Science Lecture Series',
      defaultOptions: {
        loop: true,
        autoplay: true,
        animationData: lecture,
        renderer: 'svg'
      },
      link: lsRoute
    }
  ];
  const index = courseHome.findIndex(
    (obj) => (obj?.type === 2 && obj?.packages) || (obj?.type === 5 && obj?.packages)
  );

  return (
    <div className=" bgwhite">
      {ACCESS_TOKEN_NEW === undefined || ACCESS_TOKEN_NEW === '' || ACCESS_TOKEN_NEW === null ? (
        <HeaderHome />
      ) : (
        <Header />
      )}
      <SEODetails title={seo?.metaTitle} description={seo?.metaDescrition} />
      <div
        className={
          examId || courseHome.length !== 0
            ? ' row d-flex bg common-pad backgroundimage'
            : 'bg examcommonpad backgroundimage '
        }>
        <div
          className={
            examId || courseHome.length !== 0 ? 'col-lg-6 col-sm-12 p-4' : 'col-lg-12 col-sm-12 '
          }>
          {examId || courseHome.length !== 0 ? (
            <h1 className="letterSpacing">
              {/* Boost your <span className="colorblue"> {examTypeName}</span> Exam Preparation with */}
              Boost your Exam Preparation with IFAS
            </h1>
          ) : (
            <>
              <h1 className="letterSpacing mb-0">Please, Visit Again!!</h1>
              <span className="colorblue fw-bold" style={{ fontSize: '61px' }}>
                {' '}
                Program Coming Soon
              </span>
            </>
          )}
          <div className="mt-5 ">
            <p className=" w-75 letterSpacing mb-0">
              India's <span className="colorblue fw-bold"> No. 1 AI Based App</span>
            </p>
            <p className=" w-75 letterSpacing mb-0">For IIT-JAM,CSIR - UGC NET,</p>
            <p className=" w-75 letterSpacing">GATE & Other Competitive Examination</p>
          </div>
          <button className="btn btn-primary rounded fs-5 px-3 mt-5" onClick={courseroute}>
            {examId || courseHome.length !== 0 ? 'Get Subscription' : 'Checkout Other Courses'}
          </button>
        </div>

        {examId || courseHome.length !== 0 ? (
          <div className="col-lg-6 col-sm-12 pt-5">
            {topperList?.map((item, indexMain) => {
              return (
                <div key={indexMain} className="row d-flex">
                  {item?.topperList?.map((c, indexInner) => (
                    <div key={indexInner} className="col-3 hometopperimg align-items-center">
                      <img className="img-fluid" src={LocalImages[c.path]} alt={c.name}></img>
                      <p className="d-block font10 text-center fw-bold">{c.name}</p>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </div>

      {upcoming != '' ? (
        <div className="boxshadow liveclassdiv bgwhite">
          <div className="customcontainer py-4">
            <div className="d-flex justify-content-between">
              <h3 className="fw-bold  ms-4 boldfont">Live Classes</h3>
              {/* <MoreBtn gotoLink={allClassroute} /> */}
            </div>
            <div className="mt-4">
              <div className="row">
                {upcomingArray &&
                  upcomingArray?.map((c, index) =>
                    c !== undefined ? <LectureCard key={index.toString()} data={c} /> : null
                  )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="boxshadow freeuserdiv customcontainer mx-auto">
        {courseHome?.map((obj, indexCourseHome) => (
          <div key={indexCourseHome}>
            {obj.type === 1 ? (
              <div className="customcontainer py-4 mt-4 ">
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="fw-bold  ms-4 boldfont">{obj.name}</h3>
                  <MoreBtn gotoLink={courseroute} />
                </div>
                <Carousel cols={3} rows={1} gap={10}>
                  {obj &&
                    obj?.packages?.map((c, indexCarousel) => {
                      return (
                        <Carousel.Item key={indexCarousel}>
                          <div className="mx-4 mb-3">
                            <CourseCard
                              path={c.bannerImage}
                              course={c.name}
                              sectionType={c?.sectionType}
                              courseType={obj.type}
                              batch={c.startDate}
                              courseID={c._id}
                              language={c.language.icon}
                              timeType={c.timeType}
                              classType={c.classType}
                              baseCoursename={baseCoursename}
                              examName={examName}
                              baseCourseId={c?.baseCourseId}
                              examTypeId={c?.examTypeId}
                              subExamTypeId={c?.subExamTypeId}
                            />
                          </div>
                        </Carousel.Item>
                      );
                    })}
                </Carousel>
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      <div className="boxshadow freeuserdiv customcontainer mx-auto">
        {courseHome?.map((obj, indexCourseHome) => (
          <div key={indexCourseHome}>
            {obj.type === 6 ? (
              <div className="customcontainer py-4 mt-4 ">
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="fw-bold  ms-4 boldfont">{obj.name}</h3>
                  <MoreBtn gotoLink={courseroute} />
                </div>
                <Carousel cols={3} rows={1} gap={10}>
                  {obj &&
                    obj?.packages?.map((c, indexCarousel) => {
                      return (
                        <Carousel.Item key={indexCarousel}>
                          <div className="mx-4 mb-3">
                            <CourseCard
                              path={c.bannerImage}
                              course={c.name}
                              sectionType={c?.sectionType}
                              courseType={obj.type}
                              batch={c.startDate}
                              courseID={c._id}
                              language={c.language.icon}
                              timeType={c.timeType}
                              classType={c.classType}
                              baseCoursename={baseCoursename}
                              examName={examName}
                              baseCourseId={c?.baseCourseId}
                              examTypeId={c?.examTypeId}
                              subExamTypeId={c?.subExamTypeId}
                            />
                          </div>
                        </Carousel.Item>
                      );
                    })}
                </Carousel>
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      <div className="boxshadow freeuserdiv customcontainer mx-auto">
        {courseHome?.map((obj, indexMain) => (
          <div key={indexMain}>
            {obj.type === 3 ? (
              <div className="customcontainer py-4 boxshadow freeuserdiv  mx-auto">
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="fw-bold ms-4 boldfont">{obj?.name}</h3>
                  <MoreBtn gotoLink={courseroute} />
                </div>
                <div className="mt-4">
                  <div className="row mx-4">
                    {obj &&
                      obj?.packages?.map((c, indexInner) => {
                        if (indexInner <= 2) {
                          return (
                            <div
                              key={indexInner}
                              className="col-sm-4 col-md-4 col-xl-4 ps-0 pe-0 mb-3">
                              <div className="card border-0 p-4 shadow-lg me-sm-4">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h6 className="ellipsis fw-bold">{c.name}</h6>
                                  <span className="tslang colorblue border p-1">
                                    {c?.language?.abbreviation}
                                  </span>
                                </div>

                                <p className="mb-4 font12">
                                  Unit Test: {c.testCount.unit}, Mock Test: {c.testCount.mock}
                                </p>
                                <button
                                  className="btn btn-primary w-75 py-md-1 py-lg-0 font-12 mx-auto"
                                  onClick={() => {
                                    courseDetails(c._id, c.name);
                                  }}>
                                  View Details
                                </button>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      {index > -1 ? (
        <div className=" freeuserdiv mt-3 customcontainer mx-auto">
          {courseHome?.map((obj, indexPackages) =>
            obj?.type === 5 && obj?.packages ? (
              <div
                key={indexPackages}
                className="col-sm-12 "
                onClick={() => {
                  lsRoute(obj?.packages[0]?._id, obj?.packages[0]?.name);
                }}>
                <div className="lectureseriesbgclr studymaterialLectureseriesdiv text-white card border-0 p-3 pt-1 pb-0 d-flex flex-row flex-wrap align-items-center my-md-3 boxshadow">
                  <div className="col-12 col-md-8 cursor">
                    <h6 className="font12 fw-bold">{obj?.name}</h6>
                    <p className="font12 mt-1">
                      Best {examTypeName} {baseCoursename} Lecture Series
                    </p>
                  </div>
                  <div className="col-12 col-md-4 animateImges">
                    <Lottie
                      className="me-0"
                      options={study[1].defaultOptions}
                      height={110}
                      width={165}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          )}
        </div>
      ) : (
        ''
      )}
      <div className="boxshadow freeuserdiv customcontainer mx-auto">
        {courseHome?.map((obj, indexMain) => (
          <div key={indexMain}>
            {obj.type === 7 ? (
              <div className="customcontainer py-4 boxshadow freeuserdiv  mx-auto">
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="fw-bold ms-4 boldfont">{obj?.name}</h3>
                  <MoreBtn gotoLink={courseroute} />
                </div>
                <div className="mt-4">
                  <div className="row mx-4">
                    {obj &&
                      obj?.packages?.map((c, indexInner) => {
                        if (indexInner <= 2) {
                          return (
                            <div
                              key={indexInner}
                              className="col-sm-4 col-md-4 col-xl-4 ps-0 pe-0 mb-3">
                              <div className="card border-0 p-4 shadow-lg me-sm-4">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h6 className="ellipsis fw-bold">{c.name}</h6>
                                  <span className="tslang colorblue border p-1">
                                    {c?.language?.abbreviation}
                                  </span>
                                </div>

                                <p className="mb-4 font12">
                                  Unit Test: {c.testCount.unit}, Mock Test: {c.testCount.mock}
                                </p>
                                <button
                                  className="btn btn-primary w-75 py-md-1 py-lg-0 font-12 mx-auto"
                                  onClick={() => {
                                    courseDetails(c._id, c.name);
                                  }}>
                                  View Details
                                </button>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      <div className="boxshadow freeuserdiv customcontainer mx-auto">
        {courseHome?.map((obj, indexMain) => (
          <div key={indexMain}>
            {obj.type === 8 ? (
              <div className="customcontainer py-4 boxshadow freeuserdiv  mx-auto">
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="fw-bold ms-4 boldfont">{obj?.name}</h3>
                  <MoreBtn gotoLink={courseroute} />
                </div>
                <div className="mt-4">
                  <div className="row mx-4">
                    {obj &&
                      obj?.packages?.map((c, indexInner) => {
                        if (indexInner <= 2) {
                          return (
                            <div
                              key={indexInner}
                              className="col-sm-4 col-md-4 col-xl-4 ps-0 pe-0 mb-3">
                              <div className="card border-0 p-4 shadow-lg me-sm-4">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h6 className="ellipsis fw-bold">{c.name}</h6>
                                  <span className="tslang colorblue border p-1">
                                    {c?.language?.abbreviation}
                                  </span>
                                </div>

                                <p className="mb-4 font12">
                                  Unit Test: {c.testCount.unit}, Mock Test: {c.testCount.mock}
                                </p>
                                <button
                                  className="btn btn-primary w-75 py-md-1 py-lg-0 font-12 mx-auto"
                                  onClick={() => {
                                    courseDetails(c._id, c.name);
                                  }}>
                                  View Details
                                </button>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      {index > -1 ? (
        <div className="ps-md-0 mb-3 freeuserdiv mt-3 customcontainer  mx-auto">
          {courseHome?.map((obj, indexObj) =>
            obj?.type === 2 && obj?.packages ? (
              <div
                key={indexObj}
                className="col-sm-12 "
                onClick={() => {
                  smRoute(obj?.packages[0]);
                }}>
                <div className="studymaterialbgclr studymaterialLectureseriesdiv text-white card border-0 p-3 pt-1 pb-0 d-flex flex-row flex-wrap align-items-center my-md-3 me-0 boxshadow ">
                  <div className="col-12 col-md-8 cursor">
                    <h6 className="font12 fw-bold mt-2">{obj?.name}</h6>
                    <p className="font12 mt-1">
                      Best {examName} {baseCoursename} Study Material Package in Printed Book
                      Version
                    </p>
                  </div>
                  <div className="col-12 col-md-4 animateImges">
                    <Lottie options={study[0].defaultOptions} height={110} width={165} />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          )}
        </div>
      ) : (
        ''
      )}

      {freeVideo.length != 0 ? (
        <div className="customcontainer py-4 boxshadow freeuserdiv  mx-auto">
          <div className="d-flex justify-content-between">
            <h3 className="fw-bold ms-4 boldfont">Free Lectures</h3>

            <MoreBtn gotoLink={allFreeLectures} />
          </div>
          <div className="mt-4 d-flex">
            {freeVideo &&
              freeVideo?.map((c, indexLecture) => {
                if (c !== undefined && indexLecture <= 2) {
                  return <LectureCard data={c} key={indexLecture} />;
                }
              })}
          </div>
        </div>
      ) : null}

      <div
        className="position-fixed backcolor rounded-circle"
        style={{ right: '20px', bottom: '30px' }}
        onClick={handleClick}>
        <Lottie options={defaultOptions} height={70} width={70}></Lottie>
      </div>
      {ACCESS_TOKEN_NEW ? null : <ClassroomInPocket />}
      <Footer />
      <TalktoExpert open={open} handleClose={handleClose} />
    </div>
  );
}

export default CourseDashboard;
