import '../App.css';
import React, { Suspense } from 'react';
import Sidebar from '../Module/sidebar';
import { Route, Routes } from 'react-router-dom';
import { SidebarData } from '../Module/sidebarData';
import Header from '../Module/header';

import ClassroomCoachingDashbord from './classroomCoachingDashbord';



function ClassroomRegistrationMenu(Props) {
  
  return (
    <div>
      <Header />
      <div className="dashboard d-flex backimg">
        <Sidebar data={SidebarData} />
        <Routes>

          <Route path="/" element={<ClassroomCoachingDashbord/>} />
          <Route path="ClassroomCoachingDashbord" element={<ClassroomCoachingDashbord/>} />

        </Routes>
      </div>
    </div>
  );
}

export default ClassroomRegistrationMenu;
