import React, { useState, useEffect } from 'react';
import '../App.css';
import DatePicker from 'react-horizontal-datepicker';
import { getLectureSchedule, getLectureScheduleFree } from '../Redux/Actions/homeAction';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import videoicon from '../Assets/Images/Learn/videoicon.png';
import NoRecord from '../Component/noRecord';

function LectureSchedule() {
  const [scheduleData, setScheduleData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const location = useLocation();
  const dispatch = useDispatch();

  const courseId = location.state.courseId;
  const { selectedTiles } = useSelector((state) => ({
    selectedTiles: state?.HomeReducer?.selectedTiles
  }));
  const { examTypeId, baseCourseId, childrenId } = useSelector((state) => ({
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    childrenId: state?.AuthReducer?.childrenId
  }));
  useEffect(() => {
    const requestData = {
      baseCourseId,
      examTypeId,
      subExamTypeId: childrenId,
      date: moment(selectedDate).format('YYYY-MM-DD')
    };
    if (childrenId === '') delete requestData['subExamTypeId'];
    if (courseId)
      dispatch(
        getLectureSchedule(
          `${courseId}/${moment(selectedDate).format('YYYY-MM-DD')}`,
          (data) => {
            setScheduleData(data);
          },
          () => {}
        )
      );
    else
      dispatch(
        getLectureScheduleFree(requestData, (data) => {
          setScheduleData(data);
        })
      );
  }, []);

  const selectedDay = (val) => {
    const requestData = {
      baseCourseId,
      examTypeId,
      subExamTypeId: childrenId,
      date: moment(val).format('YYYY-MM-DD')
    };
    if (childrenId === '') delete requestData['subExamTypeId'];
    setSelectedDate(moment(val).format('YYYY-MM-DD'));
    if (courseId)
      dispatch(
        getLectureSchedule(`${courseId}/${moment(val).format('YYYY-MM-DD')}`, (data) => {
          setScheduleData(data);
        })
      );
    else
      dispatch(
        getLectureScheduleFree(requestData, (data) => {
          setScheduleData(data);
        })
      );
  };
  return (
    <div className="rightPanel">
      <div className=" bgwhite">
        <h4>{selectedTiles.name}</h4>
      </div>
      <div className="w-50 mt-4 ">
        <div className="p-2 sticky-top bgwhite dateBox">
          <DatePicker
            className="DatePicker_dayLabel__2trhR  DatePicker_dateLabel__xMZ2T"
            getSelectedDay={selectedDay}
            labelFormat={'MMMM'}
            color={'#016fe9'}
          />
        </div>
        <div className="lecturediv">
          {scheduleData?.length > 0 ? (
            scheduleData.map((c, index) => {
              return (
                <div className="boxshadow rounded mb-4 customcontainer bgwhite w-78 mt-4">
                  <p className="mb-0 m-3 fw-bold colorblue">
                    {c.status === 1 ? 'Upcoming' : c.status === 2 ? 'Live' : 'Completed'}
                  </p>
                  <div className="d-flex justify-content-between p-3 pt-1 pb-0">
                    {c?.lectureTitle ? (
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center border px-2 py-1 ms-2 border-dark rounded">
                          <span className="font12">{index + 1}</span>
                        </div>
                        <span className="ms-3 font12">{c?.lectureTitle}</span>
                      </div>
                    ) : null}

                    <div className="d-flex align-items-center">
                      <img src={videoicon} alt="video Icon" style={{ width: '23px' }} />
                    </div>
                  </div>

                  <div className="d-flex justify-content-between p-3 pt-0">
                    <span className=" font12">{c?.teacher?.name}</span>

                    <div className="d-flex align-items-center mt-2 ">
                      <span className="ms-3 font12">
                        {moment.utc(c?.sessionTime, 'hh:mm:ss').format('LT')}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <NoRecord />
          )}
        </div>
      </div>
    </div>
  );
}

export default LectureSchedule;
