import React from 'react';
import { useEffect } from "react";
import { LocalImages } from '../Constant';
import { useSelector } from 'react-redux';
import { useState } from "react";
import HeaderHome from '../Module/headerHome';
import Header from '../Module/header';
import {  getCareerDesignationsList,
  getCareerJobsList,
  getCareerjobloction, } from "../Redux/Actions/homeAction";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from "moment";
function JobInIfas() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const jobPosition = location.state.jobPosition;
  const [JobData, setJobData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [locatioinData, setlocatioinData] = useState([]);
  const [selectedDesignation, setselectedDesignation] = useState([]);
  const [selectedLocation, setselectedLocation] = useState([]);
  const { ACCESS_TOKEN_NEW, } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW,
  }));
 
  useEffect(() => {
    dispatch(
      getCareerJobsList({ jobPositionId: jobPosition?.jobPositionId }, (data) => {
        setJobData(data.data);
      })
    );

    dispatch(
      getCareerDesignationsList({ jobPositionId: jobPosition?.jobPositionId }, (data) => {
        setDesignationData(data.data);
      })
    );

    dispatch(
      getCareerjobloction({ jobPositionId: jobPosition?.jobPositionId }, (data) => {
        setlocatioinData(data.data);
      })
    );
  }, [jobPosition]);
 
  
  const handleDesignation = (e) => {
   
    let newArrr = [];
    newArrr = selectedDesignation;
    if (e.target.checked) {
      newArrr.push(e.target.value);
    } else {
      newArrr.splice(newArrr.indexOf(e.target.value), 1);
    }
   setselectedDesignation(newArrr);

    const Postdata={
      designation:newArrr,
      jobPositionId:jobPosition?.jobPositionId,
      location:selectedLocation
    }
    if (newArrr.length===0) {
      delete Postdata["designation"];
    }
    if (selectedLocation.length===0) {
      delete Postdata["location"];
    }
    dispatch(
      getCareerJobsList(Postdata, (data) => {
        setJobData(data.data);
      })
    );
  };
  const handleLocation = (e) => {

    let newArrr = [];
    newArrr = selectedLocation;
    if (e.target.checked) {
      newArrr.push(e.target.value);
    } else {
      newArrr.splice(newArrr.indexOf(e.target.value), 1);
    }
    setselectedLocation(newArrr);

    const Postdata={
      designation:selectedDesignation,
      jobPositionId:jobPosition?.jobPositionId,
      location:newArrr
    }
    if (newArrr.length===0) {
      delete Postdata["location"];
    }
    if (selectedDesignation.length===0) {
      delete Postdata["designation"];
    }
    dispatch(
      getCareerJobsList(Postdata, (data) => {
        setJobData(data.data);
      })
    );
  };
  const jobdescription = (data) => {
    // window.location.href = "./JobDescription";
     navigate('/JobDescription',{ state: { jobData:data } });
  };
     //
  return (
  <div className=''>
   {ACCESS_TOKEN_NEW == '' || ACCESS_TOKEN_NEW == undefined ? <HeaderHome /> : <Header />}
   <div className='backimg privacy'>
   <div className="common-pad py-5">
        <div className="d-flex align-items-center">
          {" "}
          <h1 className="boldfont textshadow text-primary">Job Openings </h1>{" "}
          <span className="jobCountBox rounded-pill">
            Open Position ({JobData?.length} Job Positions)
          </span>
        </div>
        <p className="fw-bold">For  {jobPosition?.jobPositionName} Department</p>

        <div className="d-flex row mt-4">
          <div className="col-md-3 filterBox ">
            <div className="mb-3">
              <p className="mb-2 text-primary fw-bold">By Job Role</p>
              {designationData?.map((item, index) => {
                return (
                  <div key={index}>
                    <input
                      type="checkbox"
                      value={item}
                      onChange={handleDesignation}
                    />
                    <span className="ms-2">{item}</span>{" "}
                  </div>
                 );
              })}  
           
            </div>
         
            <div className="mb-3">
              <p className="mb-2 text-primary fw-bold">By Location</p>
                {locatioinData?.map((item, index) => {
                return (  
                  <div  key={index}>
                    {/* */}
                    <input
                      type="checkbox"
                      value={item}
                       onChange={handleLocation}
                    />
                    <span className="ms-2">{item}</span>{" "}
                  </div>
                  );
              })}  
            </div>
          </div>
          <div className="col-md-8 border-start border-secondary border-2">
          <div>
              {JobData?.map((item, index) => {
                return (
                  <div className="boxshadow rounded mb-3 jobcard" key={index}>
                    <div className="d-flex justify-content-between">
                      <div className="greencolor fw-bold">
                        {item?.isNew ? "New" : null}
                      </div>
                      <div className="fw-bold">{moment(item?.postedOn, "YYYYMMDD").fromNow()}</div>
                    </div>
                    <div className="fontsize20 fw-bold my-1">
                      {item?.designation}
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <img src={LocalImages.suitcase} alt="suitcase" />
                      <span className="ms-2">{item?.experience}</span>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <img src={LocalImages.wallet} alt="wallet" />
                      <span className="ms-2">{item?.salary}</span>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <img src={LocalImages.graduate} alt="graduate" />
                      <span className="ms-2">{item?.qualification}</span>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <img src={LocalImages.jobskill} alt="skills" />
                      <span className="ms-2">{item?.skills.toString()}</span>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <img src={LocalImages.location} alt="location" />
                      <span className="ms-2">{item?.location.toString()}</span>
                    </div>
                    <div className="text-end mt-4">
                      <button
                        className="btn btn-primary fontsize20 p-2 fw-bold px-5"
                        onClick={()=>jobdescription(item)}
                      >
                        View details
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

   </div>
    
  </div>
  );
}
export default JobInIfas;