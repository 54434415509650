import React from 'react';
import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

function ChatImagePopup(props) {
 
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="modeldiv p-md-4 py-md-4 ">
        <div className="m-2  bgwhite">
            <div className="col mb-2 d-flex justify-content-end ">
              <ClearOutlinedIcon
                className="colorblue cursor"
                onClick={() => {
                  props.handleClose();
                }}
              />
            </div>
            <div>
              <div className="justify-content-center d-flex ">
                <img className="img-fluid" src={props?.imageFull?.file} alt ={props?.imageFull?.originalName}/>
              </div>
              
            </div>
           
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default ChatImagePopup;
