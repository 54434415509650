import React, { useState, useEffect } from 'react';
import '../Assets/CSS/home.css';
import Footer from '../Component/footer';
import ClassroomInPocket from '../Component/classroomInPocket';
import { LocalImages } from '../Constant';
import Lottie from 'react-lottie';
import Expert from '../Assets/Images/JSON/expert.json';
import TalktoExpert from '../Module/talktoExpert';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import data from '../Assets/DummyData/course.json';
import Header from '../Module/header';
import HeaderHome from '../Module/headerHome';
import { useDispatch } from 'react-redux';
import { getExamList } from '../Redux/Actions/AuthAction';

const AboutSubExam = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { examId, examName, seoExamName } = useParams();
  const [open, setopen] = useState(false);
  const [childrenId, setChildrenId] = useState([]);
  const [courseexamData, setcourseexamData] = useState('');

  const { ACCESS_TOKEN_NEW } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Expert,
    renderer: 'svg'
  };

  useEffect(() => {
    dispatch(
      getExamList((data) => {
        const index = data.findIndex((item) => item._id === examId);
        setChildrenId(data[index]?.childrenId);
      })
    );

    window.scrollTo({ top: 0 });
    if (examName?.toUpperCase() === 'CSIR NET') setcourseexamData(data['csir_net']);
    else if (examName?.toUpperCase() === 'GATE') setcourseexamData(data['gate']);
    else if (examName?.toUpperCase() === 'UGC NET') setcourseexamData(data['ugc_net']);
    else if (examName?.toUpperCase() === 'IIT-JAM') setcourseexamData(data['iit_jam']);
    else if (examName?.toUpperCase() === 'CUET') setcourseexamData(data['cuet']);
    else if (examName?.toUpperCase() === 'MSC ENTRANCE') setcourseexamData(data['MSC']);
    else if (examName?.toUpperCase() === 'PSC TEACHING') setcourseexamData(data['psc_teaching']);
    else if (examName?.toUpperCase() === 'MPPSC') setcourseexamData(data['mppsc_teaching']);
    else if (examName?.toUpperCase() === 'RPSC') setcourseexamData(data['rppsc_teaching']);
    else if (examName?.toUpperCase() === 'BARC') setcourseexamData(data['barc']);
    else if (examName?.toUpperCase() === 'TIFR') setcourseexamData(data['tifr']);
    else if (examName?.toUpperCase() === 'APTITUDE NET,GATE,SET')
      setcourseexamData(data['general_aptitude']);
    else if (examName?.toUpperCase() === 'GAT B') setcourseexamData(data['gatb']);
    else if (examName?.toUpperCase() === 'JEST') setcourseexamData(data['jest']);
    else if (examName?.toUpperCase() === 'UPHESC') setcourseexamData(data['uphesc']);
    else if (examName?.toUpperCase() === 'CSIR NET APTITUDE')
      setcourseexamData(data['csir_net_Apti']);
    else if (examName?.toUpperCase() === 'GATE APTITUDE') setcourseexamData(data['gate_apti']);
    else if (examName?.toUpperCase() === 'UGC NET APTITUDE')
      setcourseexamData(data['ugc_net_apti']);
    else if (examName?.toUpperCase() === 'SET') setcourseexamData(data['set_apti']);
    else if (examName?.toUpperCase() === 'ICMR') setcourseexamData(data['icmr']);
    else if (examName?.toUpperCase() === 'DBT') setcourseexamData(data['dbt']);
    else if (examName?.toUpperCase() === 'GOA PSC') setcourseexamData(data['goa-psc']);
    else if (examName?.toUpperCase() === 'HPSC') setcourseexamData(data['hpsc']);
    else if (examName?.toUpperCase() === 'JKPSC') setcourseexamData(data['jkpsc']);
  }, [examName]);

  const coursePage = (_id, name, seoName) => navigate('/' + seoName);

  const handleClose = () => setopen(false);

  const handleClick = () => setopen(true);

  return (
    <div className="">
      {ACCESS_TOKEN_NEW === undefined || ACCESS_TOKEN_NEW === '' || ACCESS_TOKEN_NEW === null ? (
        <HeaderHome />
      ) : (
        <Header />
      )}
      {courseexamData?.ismultiple === false ? (
        <div
          className="app-achiev common-pad pt-2 pb-2 wow fadeIn text-center bg"
          data-wow-duration="2s"
          data-wow-delay="0s">
          <p className="text-center nameheading mb-3">{courseexamData?.name} TOPPERS</p>

          <div className="row justify-content-center content-topper mt-3 col-lg-10 mx-auto">
            {courseexamData?.toppers?.map((c, indexMain) => {
              return (
                <div
                  key={indexMain}
                  className="  col-sm-2 rounded wow fadeIn mb-3"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s">
                  <img className="img-fluid" src={LocalImages[c?.path]} alt={c.name} />
                  <span className="d-block mt-2">{c.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className="app-achiev common-pad pt-2 pb-2 wow fadeIn text-center bg"
          data-wow-duration="2s"
          data-wow-delay="0s">
          {courseexamData?.examlist?.map((exam, index) => {
            return (
              <>
                <p className="text-center nameheading mb-3">{exam?.examName}</p>

                <div className="row justify-content-center content-topper mt-3 col-lg-10 mx-auto">
                  {exam?.toppers?.map((c, indexMain) => {
                    return (
                      <div
                        key={indexMain}
                        className="  col-sm-2 rounded wow fadeIn mb-3"
                        data-wow-duration="2s"
                        data-wow-delay="0.3s">
                        <img className="img-fluid" src={LocalImages[c?.path]} alt={c.name} />
                        <span className="d-block mt-2">{c.name}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      )}
      <div
        className="app-achiev common-pad pt-4 pb-2 wow fadeIn text-center"
        data-wow-duration="2s"
        data-wow-delay="0s">
        <p className="text-center mb-2 colorblue coursenametitle fw-bold">{examName} SubExam</p>
        <p className="subjectsubtext mb-3">
          The Most Comprehensive Packages Created By The Best Teachers Of India
        </p>
        <div className="content-topper mt-5 col-lg-10 mx-auto d-flex justify-content-center flex-wrap">
          {childrenId?.map((c) => {
            return (
              <div
                key={c._id}
                className="col-md-3 mb-2 px-3"
                data-wow-duration="2s"
                data-wow-delay="0.3s">
                <div
                  className="w-100 d-flex basecourseBox rounded wow fadeIn boxshadow cursor px-3 py-2"
                  onClick={() => coursePage(c?._id, c?.name, c?.seoName)}>
                  {c?.file && (
                    <div className="text-center p-2">
                      <img className="img-fluid examimg ms-0" src={c?.file} alt={c.name} />
                    </div>
                  )}
                  <div className="text-start pt-3">
                    <p className="d-block">{c.name}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row common-pad pt-4">
        {courseexamData?.faq !== undefined ? (
          <p className="text-center mb-2 colorblue coursenametitle fw-bold">
            Some Frequently Asked Questions
          </p>
        ) : (
          ''
        )}
        <div
          className="accordion accordion-flush row d-flex justify-content-center"
          id="accordionFlushExample">
          {courseexamData?.faq?.map((c, index) => {
            return (
              <div key={index.toString()} className="accordion-item noborder col-md-6 ">
                <div className="boxshadow" style={{ margin: '6px' }}>
                  <h2 className="accordion-header" id={'heading' + index}>
                    <button
                      className={`accordion-button  ${index === 0 ? '' : 'collapsed'}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={'#collapse' + index}
                      aria-expanded={index === 0 ? 'true' : 'false'}
                      aria-controls={'collapse' + index}>
                      {c?.title}
                    </button>
                  </h2>
                  <div
                    id={'collapse' + index}
                    className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                    aria-labelledby={'heading' + index}
                    data-bs-parent={'#accordionExample'}>
                    <div className="accordion-body">{c?.text}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ClassroomInPocket />
      <Footer />
      <div
        className="position-fixed backcolor rounded-circle cursor"
        style={{ right: '20px', bottom: '30px' }}>
        <a className="d-block d-md-none talkexpertbtn" href="tel:+919172266888">
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </a>
        <span className="d-none d-md-block" onClick={handleClick}>
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </span>
      </div>
      <TalktoExpert open={open} handleClose={handleClose} />
    </div>
  );
};

export default AboutSubExam;
