import React from 'react';
function NatBtn(props) {
  return (
    <button
      type="button"
      className="btn btn-outline-secondary px-4 py-3 me-1 mb-2"
      onClick={() => props.getno(props.no)}>
      {props.no}
    </button>
  );
}

export default NatBtn;
