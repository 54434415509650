import { subscriptionPlan } from '../../Modals';
import * as Actions from '../../Types/subscriptionPlanTypes';

const subscriptionPlanReducer = (state = new subscriptionPlan(), action) => {
  switch (action.type) {
    case Actions.SET:
      return { ...state, ...action.payload };
    case Actions.LOADER:
      return { ...state, loader: action.payload };
    default:
      return state;
  }
};

export default subscriptionPlanReducer;
