import React from 'react';
import { LocalImages } from '../Constant';
import '../Assets/CSS/onlineTestExam.css';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function RefundPolicy(props) {
  const { ACCESS_TOKEN_NEW } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));
  // useEffect(() => {
  //   document.title = props.title;
  // }, []);
  const dashbord = () => {
    if (ACCESS_TOKEN_NEW) window.location.href = '../Home';
    else window.location.href = '../';
  };

  return (
    <div>
      <header className="d-flex align-items-center">
        <Helmet>
          <title>Refund Policy - IFAS</title>
          <meta
            name="description"
            content="IFAS Refund Policy: Learn about our refund process and guidelines. Your satisfaction is our priority. Read our refund policy now."
          />
        </Helmet>
        <div>
          <img
            src={LocalImages.logo}
            alt="IFAS Logo"
            className="logo cursor"
            onClick={dashbord}
            style={{ width: '120px' }}
          />
        </div>
        <h3 className="mb-0 ms-3">Payment Terms & Refund Policy</h3>
      </header>
      <div className="instcontainer backimg privacy">
        <div className="m-5 insttxtDiv bgwhite">
          <h6 className="colorblue fw-bold my-3">Contact Information</h6>
          <p className="insttxt mb-2">
            By clicking the link you will be redirected to the website of the third party for the
            purpose of online course fee payment. The third-party website is not owned or controlled
            by IFAS Edutech Pvt. Ltd. and contents thereof are not sponsored, endorsed, or approved
            by the institute. IFAS Edutech Pvt. Ltd. does not vouch or guarantee or take any
            responsibility for any of the contents of the said website including transactions,
            products, services, or other items offered through the website. You will access this
            site solely for the purpose of online payment of the course fee and you acknowledge that
            any reliance on any opinion, advice, statement, memorandum, or information available on
            the site shall be at your sole risk and consequences.
          </p>
          <p className="insttxt mb-2">
            IFAS Edutech Pvt. Ltd. and its branches, employees, officers, directors, and other
            associates shall not be liable for any loss, claim or damage whatsoever including
          </p>
          <ul className="ps-3">
            <li className="insttxt mb-2">
              The event of deficiency in the service of such third-party websites
            </li>
            <li className="insttxt mb-2">
              Any consequences of error or failure of internet connection equipment hardware or
              software used to access the third-party website through this link
            </li>
            <li className="insttxt mb-2">
              The event of the interception, "hacking" or other unauthorized access to information
              provided by a user of this service.
            </li>
            <li className="insttxt mb-2">
              The institute cannot accept liability for a payment not reaching the correct institute
              account due to you quoting an incorrect account number or incorrect personal details
            </li>
            <li className="insttxt mb-2">
              The institute cannot accept liability if payment is refused or declined by the
              credit/debit card supplier for any reason
            </li>
            <li className="insttxt mb-2">
              If the card supplier declines payment, the institute is under no obligation to bring
              this fact to your attention. You should check with your bank/credit/debit card
              supplier that payment has been deducted from your account
            </li>
          </ul>
          <p className="insttxt mb-2">
            IFAS Edutech Pvt. Ltd. is not directly involved in the online payment but through a
            service provider. Thus, we shall be not responsible in any case until the course fee
            paid by the student or parent is credited into the Bank Account of the institute.
          </p>
          <p className="insttxt mb-2">
            By proceeding further to access the said website it is presumed that you have agreed to
            the above and also the other terms and conditions applicable. These terms and conditions
            apply to the use of the online service for payment of Course Fee to IFAS Edutech Pvt.
            Ltd. . Please read the terms and conditions carefully. IFAS Edutech Pvt. Ltd. reserves
            the right to amend these terms and conditions at any time without notice. You should
            therefore re-read the terms and conditions each time that this service is used.
          </p>
          <p className="insttxt mb-2">
            Upon completing an Online Service, you will be presented with a confirmation screen
            verifying the transaction details you wish to process. It is your responsibility to
            verify that all transaction information and other details are correct. The transaction
            shall be deemed binding at the time the confirmation screen is displayed. You should
            print the transaction confirmation for future reference and your files. IFAS Edutech
            Pvt. Ltd. shall have no accountability for transactions that are incorrect as a result
            of inaccurate data entry in the course of providing Online Services or for loss of data
            or information caused by factors outside of the institute's control.
          </p>
          <h6 className="colorblue fw-bold my-3">Refund & Cancellation</h6>
          <p className="insttxt mb-2">
            Please read the subscription terms and conditions carefully before subscribing to{' '}
            <b>
              any of the subscription plans, as once you have subscribed you cannot change, cannot
              transfer your subscription, or cancel your subscription plan.
            </b>{' '}
            Once you subscribe and make the required payment,
            <b>
              it shall be final and there cannot be any changes or modifications to the same, and
              neither will there be any refund.
            </b>
          </p>
          <p className="insttxt mb-2">
            In case of any technical issue found in online fee payment, such as :
          </p>
          <ol className="ps-3">
            <li className="insttxt mb-2">
              During the online payment through credit/debit card if the payment gets debited and
              the internet goes down due to some external server malfunction or any other similar
              happening.
            </li>
            <li className="insttxt mb-2">
              Discount Offers are only valid on the mentioned date time period, and no extra refund
              will be provided if you found any other exciting discount offer on website after
              course purchase, IFAS Edutech PVT Ltd. can launch new offers any time whenever they
              want and there is no link with previous offers.
            </li>
            <li className="insttxt mb-2">
              There is no full or partial refund in below mention conditions after purchasing any
              course:
            </li>
            <ol className="ps-3" type="a">
              <li className="insttxt mb-2">Health issue</li>
              <li className="insttxt mb-2">Any Family emergency</li>
              <li className="insttxt mb-2">Dropping education</li>
              <li className="insttxt mb-2">
                Going abroad for Higher study/ Job or any other reason.
              </li>
            </ol>
            <li className="insttxt mb-2">
              IFAS Edutech PVT Ltd. does not consider any verbal communication with a counsellor,
              employee, or 3rd party person for a refund, information mentioned on the website, or
              on the app is final and IFAS Edutech PVT Ltd. considers that information only.
            </li>
            <li className="insttxt mb-2">
              There is no course extension in any case if you have health issues, are not able to
              study at subscription time, or went abroad for study/Job or any other reason.
            </li>
            <li className="insttxt mb-2">
              The system fails to generate the required acknowledgment due to an internet
              malfunction.
            </li>
            <li className="insttxt mb-2">
              We shall not be responsible in any case until the course fee paid by the student or
              parent is credited into the Bank Account of the institute. If credited into our
              account, the refund policy will be applicable as per the institute norms.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default RefundPolicy;
