import React from 'react';

// function InputField({ id, type, placeholder, value, setInputText }) {
function InputField(props) {
  return (
    <div className="d-flex mb-2 align-items-center w-100">
      <input
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        className="form-control m-0"
        onChange={(e) => props.setInputText(e.target.value)}
        maxLength={props.maxLength}
        minLength={props.minLength}
        onKeyDown={props.onKeyDown}
        disabled={props.disabled}
      />
    </div>
  );
}

export default InputField;
