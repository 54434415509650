import React, { useState } from 'react';
import '../App.css';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Lottie from 'react-lottie';
import referralCheck from '../Assets/Images/JSON/referralCheck.json';
import { Link } from 'react-router-dom';


function AddUpi(props) {
  const {error, onChange, isAdd} = props;
  const [showMobileInput, setShowMobileInput] = useState(false)
  const [accName, setAccName] = useState('')
  const [accNum, setAccNum] = useState('')
  const [accIfsc, setAccIfsc] = useState('')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: referralCheck,
    renderer: 'svg'
  };
  const show = () => {
    isAdd ? setShowMobileInput(true) : setShowMobileInput(false);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="addUpi-modal col-md-4">
          <div>
            <div className="col mb-2 text-end">
              <ClearOutlinedIcon
                className="colorblue cursor"
                onClick={() => {
                  props.handleClose();
                }}
              />
            </div>
            {
              !showMobileInput ? (
                <div className='modal-info-holder'>
                  <div className='animation-holder mb-4'>
                    <Lottie options={defaultOptions} height={130} width={200}></Lottie>
                  </div>
                  <div className='text-holder text-center my-5'>
                    <h5>Your Referral Details Are Submitted Successfully.</h5>
                    <p>To see your referral status <Link to="">click here</Link></p>
                  </div>
                  <div className='addupi-btn-holder text-center mb-3'>
                    <button className='btn' type='button' onClick={show}>{isAdd ? 'Add Bank Account' : 'Bank Account change request'} </button>
                  </div>
                </div>
              ) : (
                <div className='modal-info-holder p-3'>
                  <b>Note: Below mentioned Bank details are required for referral cashback payout, when your friend purchase a course using your referral code</b>
                  <div className='label-holder my-3'>
                    <label className="invite-label">Bank Account</label>
                  </div>
                  <form class="mb-3">
                    <div className="row ">
                      <div class="mobile-invite mb-5">
                        <input value={accName} onChange={(txt) => setAccName(txt.target.value)} type="text" className="form-control" placeholder="Enter your Bank Namefdsgfsf" />
                      </div>
                      <div class="mobile-invite mb-5">
                        <input value={accNum} onChange={(txt) => setAccNum(txt.target.value)} type="text" className="form-control" placeholder="Enter your Bank Account Number" />
                      </div>
                      <div class="mobile-invite mb-5">
                        <input value={accIfsc} onChange={(txt) => setAccIfsc(txt.target.value)} type="text" className="form-control" placeholder="Enter IFSC Code" />
                      </div>
                      <div className='addupi-btn-holder text-center mb-3'>
                        <button onClick={()=>{
                          let reqBody = {
                            accountName: accName,
                            bankAccountNo: accNum,
                            ifscCode: accIfsc
                          }
                          props?.handleBtnClick(reqBody)
                        }} className='btn' type='button'>Add Bank Account</button>
                      </div>
                      {error ? (  <p style={{color:'red', textAlign:'center'}}>Enter valid Bank Details</p>): null}
                    </div>
                  </form>
                </div>
              )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default AddUpi;
