import React from 'react';
import { getState, getExamList, getSubExamList } from '../Redux/Actions/AuthAction';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateMoreValues, getParentCourse } from '../Redux/Actions/AuthAction';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

function CourseDropdown(props) {
  const dispatch = useDispatch();
  const [parentCourse, setparentCourse] = useState([]);
  const navigate = useNavigate();
  const [exam, setexam] = useState('');
  const [examData, setexamData] = useState([]);
  const [subExamName, setsubExamName] = useState('');
  const [SubExamData, setSubExamData] = useState([]);
  const [showSubCourse, setshowSubCourse] = useState(false);
  const [showState, setshowState] = useState(false);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    APIFetch();
  }, []);

  const APIFetch = async () => {
    dispatch(
      getExamList((dataCourse) => {
        setexamData(dataCourse);
        setexam(dataCourse[0]);
        if (dataCourse[0]?.isStatewise === true) {
          setStateList(dataCourse[0]?.stateIds);
        } else {
          if (dataCourse[0]?.baseCourseId.length > 0) {
            setparentCourse(dataCourse[0]?.baseCourseId);
          }
          if (dataCourse[0]?.childrenId?.length > 0) {
            setshowSubCourse(true);
            setSubExamData(dataCourse[0]?.childrenId);
          } else {
            setshowSubCourse(false);
          }
        }
      })
    );
    await dispatch(
      getState(
        (dataState) => {
          const newArray = dataState;
          newArray.splice(0, 0, { _id: '', name: 'All India' });
          dispatch(updateMoreValues({ stateData: newArray }));
        },
        () => {}
      )
    );
  };

  const handleClick = (val) => {
    if (!props?.isClassroomProgram) {
      props.handleClose();
      navigate(
        '/' +
          `${subExamName?.name ? subExamName?.seoName : exam?.seoName}` +
          '/' +
          val?.seoName +
          '-online-coaching'
      );
    } else {
      window.location.href = '/ClassroomCoachingDetails';
    }
  };

  const getExamData = (val) => {
    setexam(val);
    setSubExamData([]);
    setsubExamName('');
    if (val?.isStatewise === true) {
      setStateList(val?.stateIds);
      setshowState(true);
      setSubExamData([]);
      setparentCourse([]);
    } else {
      setshowState(false);
      if (val?.baseCourseId.length > 0) {
        setparentCourse(val?.baseCourseId);
      }
      if (val?.childrenId?.length > 0) {
        setSubExamData(val?.childrenId);
        setshowSubCourse(true);
      } else {
        setshowSubCourse(false);
      }
    }
  };
  const onClickState = (val) => {
    setshowState(false);
    setsubExamName('');
    if (exam?.childrenId?.length > 0) {
      dispatch(
        getSubExamList(
          { examTypeId: exam?._id, stateId: val?._id },
          (res) => {
            if (res?.data?.length > 0) {
              setSubExamData(res?.data);
              setshowSubCourse(true);
            } else setshowSubCourse(false);
          },
          () => {}
        )
      );
    } else {
      setshowSubCourse(false);
    }
  };
  const onClickSubExam = (val) => {
    setsubExamName(val);
    dispatch(
      getSubExamList(
        { examTypeId: exam?._id },
        (res) => {
          if (res?.data?.length > 0) {
            const found = res.data.find((item) => item._id === val._id);
            setparentCourse(found.baseCourseId);
            setshowSubCourse(false);
          }
        },
        () => {}
      )
    );
  };

  const baseCourse = props.isClassroomProgram ? classroomParentCourse : parentCourse;

  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model courseModel"
        open={props.open ? props.open : props.isClassroomProgram}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={props.open ? props.open : props.isClassroomProgram}>
          <div className="coursemodeldiv">
            <ClearOutlinedIcon
              className="colorblue fs-1 p-1 mb-2 shadow rounded-circle border-primary cursor float-end"
              onClick={props.handleClose}
            />
            <div className="courseDropdown">
              <div className="backimg ">
                <div className="d-flex align-items-start p-md-3 px-md-3 row homecourse">
                  <div
                    className="nav flex-md-column nav-pills col-12 col-md-2 flex-nowrap boxshadow  px-0 mb-3 mb-md-0"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical">
                    {examData?.map((c, index) => {
                      return (
                        <button
                          key={index.toString()}
                          className={`nav-link d-flex ${index === 0 ? 'active' : ''} text-start`}
                          id={'tab' + c._id}
                          data-bs-toggle="pill"
                          data-bs-target={'#v-pills' + c._id}
                          type="button"
                          role="tab"
                          aria-controls={'#v-pills' + c._id}
                          aria-selected="true"
                          onClick={() => getExamData(c)}>
                          <img src={c.file} alt={c.name} className="examLogoimg" />
                          {c.name}
                        </button>
                      );
                    })}
                  </div>
                  <div className="tab-content col-12 col-md-9" id="v-pills-tabContent">
                    {showState ? (
                      <div className="d-flex flex-wrap mt-2 courselist ">
                        {stateList?.map((item, index) => (
                          <div
                            key={index}
                            className="courseDiv text-center border rounded"
                            onClick={() => {
                              onClickState(item);
                            }}>
                            <span className="d-block mt-2 font12">{item?.name}</span>
                          </div>
                        ))}
                      </div>
                    ) : showSubCourse ? (
                      <div className="d-flex flex-wrap mt-2 courselist ">
                        {SubExamData?.map((item, index) => (
                          <div
                            key={index}
                            className="courseDiv text-center border rounded"
                            onClick={() => onClickSubExam(item)}>
                            {item?.file ? (
                              <img src={item.file} alt={item.name} className="examLogoimg" />
                            ) : null}
                            <span className="d-block font12">{item?.name}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="d-flex flex-wrap mt-2 courselist ">
                        {baseCourse?.map((item, index) => (
                          <div
                            key={index}
                            className="courseDiv text-center border rounded"
                            onClick={() => handleClick(item)}>
                            {item?.file ? (
                              <img src={item.file} alt={item.name} className="examLogoimg" />
                            ) : null}
                            <span className="d-block mt-2 font12">{item?.name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default CourseDropdown;
