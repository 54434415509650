import React from 'react';
function Books(data) {
  return (
    <div className="bookDiv d-inline me-2 mb-3">
      <img src={data.data.coverImage} alt="books" style={{ width: '80px' }} />
      <a
        href={data.data.pdf}
        target="_blank"
        className="d-block text-center fw-bold border mx-auto font10 cursor"
        style={{ width: '80px' }}
        rel="noreferrer">
        Sample PDF
      </a>
    </div>
  );
}

export default Books;
