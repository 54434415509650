import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MenuIcon from '@mui/icons-material/Menu';
import SignInbtn from '../Component/signInbtn';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CourseDropdown from '../Component/courseDropdown';
import { LocalImages } from '../Constant';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useDispatch } from 'react-redux';
import MenuButton from '../Component/menuButton';
import StudyMaterialDropdown from '../Component/studyMaterialDropdown';
import MetaTitle from './metaTitle';
import { MetaTags } from 'react-meta-tags';
function HeaderHome(props) {
  const [openCourse, setopenCourse] = useState(false);
  const [openStudy, setOpenStudy] = useState(false);
  const [menu, showMenu] = useState(false);
  const [isClassroomProgram, setIsClassroomProgram] = useState(false);
  const [isExam, setIsExam] = useState(false);
  const [selectedmenu, setselectedmenu] = useState('');
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [pathLink, setPathLink] = useState('');
  const navigate = useNavigate();
  const currentURLarr = useLocation().pathname.split('/');
  let link = currentURLarr[currentURLarr.length - 1];
  const classroomListroute = () => {
    setIsClassroomProgram(!isClassroomProgram);
    setIsExam(false);
  };
  const showexam = (val) => {
    setIsExam(!isExam);
    setIsClassroomProgram(false);
  };

  const classRoomExam = (
    basecourseId,
    examId,
    baseCourseName,
    examName,
    titleName,
    descriptionName
  ) => {
    setIsExam(false);
    setIsClassroomProgram(false);
    setIsExam(false);
    const xamName = examName.toLowerCase();
    const baseCourseVal = baseCourseName.split(' ').join('-').toLowerCase();
    const metaT = basecourseId ? titleName : '';
    setDesc(descriptionName);
    document.title = metaT;
    setTitle(metaT);
    localStorage.setItem('metaTitle', metaT);
    localStorage.setItem('desc', descriptionName);
    setPathLink('/' + xamName + '/' + baseCourseVal + '-' + 'coaching');
    navigate('/' + xamName + '/' + baseCourseVal + '-' + 'coaching', {
      state: { baseCourseName: baseCourseVal }
    });
    // if (document.querySelector("[name='description']")) {
    //   document.querySelector("[name='description']").remove()
    //   document.querySelector('head').append('<meta name="description" content="this is new">');
    // }
    // let metaTag = document.querySelector('meta[name="description"]');
    // if (metaTag) {
    //   metaTag.setAttribute("content", descriptionName);
    // }
  };

  const courseListroute = () => {
    setIsClassroomProgram(false);
    setopenCourse(true);
    setOpenStudy(false);
  };

  const studyListRoute = () => {
    setIsClassroomProgram(false);
    setopenCourse(false);
    setOpenStudy(true);
  };

  const closePopup = () => {
    setopenCourse(false);
    setIsClassroomProgram(false);
    setOpenStudy(false);
  };

  const closePopupStudy = () => {
    setopenCourse(false);
    setIsClassroomProgram(false);
    setOpenStudy(false);
  };

  const dashbord = () => {
    navigate('/');
  };
  const Course = [
    {
      exam: 'CSIR-NET',
      examID: '6433b5b36f88433b504b4258',
      basecourse: [
        {
          basecoursename: 'Life Science',
          basecourseid: '6433ae116f88433b504b4151',
          title: 'CSIR NET Life Science Coaching: IFAS India #1 Edutech',
          description:
            'Get comprehensive CSIR NET Life Science coaching with expert guidance and study materials to excel in your exam. Join us for top-tier preparation.'
        },
        {
          basecoursename: 'Chemical Science',
          basecourseid: '6433acdf6f88433b504b4144',
          title: 'Best Coaching For CSIR NET Chemical Science | IFAS Edutech',
          description:
            'Prepare for CSIR NET Chemical Science with expert coaching. Gain in-depth knowledge, practice, and guidance for success in your exam.'
        },
        {
          basecoursename: 'Physical Science',
          basecourseid: '6433ae866f88433b504b4163',
          title: 'Best CSIR NET Physical Science Coaching : IFAS Join Us Today',
          description:
            'Discover top CSIR NET Physical Science coaching programs. Get expert guidance, study materials, and practice tests for success in your exam preparation.'
        },
        {
          basecoursename: 'Mathematical Science',
          basecourseid: '6433ae556f88433b504b415a',
          title: 'CSIR NET Mathematical Science Coaching | IFAS',
          description:
            'Get expert guidance and comprehensive study material for CSIR NET Mathematical Science coaching. Achieve your goal of clearing the exam with our experienced faculty and proven methods. Join us today!'
        }
      ]
    }
  ];

  const handleClick = (val) => {
    console.log('handleClick', val);
    navigate('/ClassroomCoachingDetails/' + val, { state: { baseCourseName: val } });
  };

  return (
    <>
      {/* <MetaTags>
        <title>{title}</title>
        <meta name="description" content={desc} />
      </MetaTags> */}
      {/* <MetaTitle title={title} description={desc} lin={pathLink} name={''} type={''} /> */}
      <header className={'headerWeb d-flex justify-content-md-between'}>
        <div className="d-flex align-items-center logodiv">
          <div className="d-xs-flex d-sm-flex d-md-none me-2">
            <span
              onClick={() => {
                showMenu(!menu);
              }}>
              <MenuIcon />
            </span>
            {menu ? (
              <div className="d-xs-flex d-sm-flex d-md-none homemenu flex-fill py-1">
                <button
                  className="btn btn-light py-1 fs-6 text-muted d-flex justify-content-between"
                  onClick={courseListroute}
                  style={{
                    backgroundColor: '#F8F5FC',
                    display: isClassroomProgram ? 'none' : 'block'
                  }}>
                  Course
                  {openCourse ? (
                    <ArrowDropUpIcon className="text-dark droparrow" fontSize="medium" />
                  ) : (
                    <ArrowDropDownIcon className="text-dark droparrow" fontSize="medium" />
                  )}
                </button>
                <span className="position-relative">
                  <button
                    className="btn btn-light py-1 my-2 text-muted d-flex justify-content-between fs-md-6 border-0"
                    style={{ backgroundColor: '#F8F5FC' }}
                    onClick={classroomListroute}>
                    Classroom Programs{' '}
                    {isClassroomProgram ? (
                      <ArrowDropUpIcon className="text-dark" fontSize="medium" />
                    ) : (
                      <ArrowDropDownIcon className="text-dark" fontSize="medium" />
                    )}{' '}
                  </button>
                  {isClassroomProgram ? (
                    <div className="position-absolute classroompopup bgwhite boxshadow border">
                      {Course.map((c) => {
                        return (
                          <div
                            className="d-flex justify-content-between border-bottom cursor p-2 "
                            onClick={() => {
                              handleClick(c);
                            }}>
                            <p className="mb-0 font13">{c.title}</p>
                            <div className="text-end">
                              <ArrowRightIcon />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </span>

                <span className="position-relative">
                  <button
                    className="btn btn-light py-2 my-2 text-muted d-flex justify-content-between fs-md-6 border-0"
                    style={{ backgroundColor: '#F8F5FC' }}
                    onClick={studyListRoute}>
                    Study Material
                    {isExam === true ? (
                      <ArrowDropUpIcon className="text-dark" fontSize="medium" />
                    ) : (
                      <ArrowDropDownIcon className="text-dark" fontSize="medium" />
                    )}
                  </button>
                </span>
              </div>
            ) : null}
          </div>
          <img
            src={LocalImages.logo}
            alt="IFAS Logo"
            className="cursor"
            onClick={dashbord}
            style={{ width: '120px' }}
          />
        </div>
        <div className="d-none d-sm-none d-md-flex flex-fill py-1 ms-lg-5 ">
          <button
            className="btn btn-light py-1 px-xs-2 fs-md-6 text-muted border-0"
            onClick={courseListroute}
            style={{ backgroundColor: '#F8F5FC' }}>
            Online Programs
            {openCourse ? (
              <ArrowDropUpIcon className="text-dark" fontSize="medium" />
            ) : (
              <ArrowDropDownIcon className="text-dark" fontSize="medium" />
            )}
          </button>
          <span className="position-relative">
            <button
              className="btn btn-light py-3 text-muted fs-md-6 border-0 position-relative"
              style={{ backgroundColor: '#F8F5FC' }}
              onClick={classroomListroute}>
              Classroom Programs{' '}
              {isClassroomProgram === true ? (
                <ArrowDropUpIcon className="text-dark" fontSize="medium" />
              ) : (
                <ArrowDropDownIcon className="text-dark" fontSize="medium" />
              )}
            </button>
            <span>
              {isClassroomProgram === true ? (
                <div
                  className="position-absolute classroompopup bgwhite boxshadow border"
                  style={{ marginTop: '25px' }}>
                  {Course.map((c, index) => {
                    console.log(c);
                    return (
                      <span>
                        <MenuButton
                          data={c}
                          isExam={isClassroomProgram}
                          index={index}
                          setselectedmenu={setselectedmenu}
                          selectedmenu={selectedmenu}
                          handleroutestudymaterial={(bid, eid, bname, ename, tname, dName) =>
                            classRoomExam(bid, eid, bname, ename, tname, dName)
                          }
                        />
                      </span>
                      // <div
                      //   className="d-flex justify-content-between border-bottom cursor p-2 "
                      //   onClick={() => {
                      //     handleClick(c.title);
                      //   }}
                      //   >
                    );
                  })}
                  {/* <p className="mb-0 font13">{c.title}</p> */}
                  {/* <div className="text-end">
                        <ArrowRightIcon />
                      </div>
                    </div> */}
                  {/* );
                })} */}
                </div>
              ) : (
                ''
              )}
            </span>
          </span>
          <span className="position-relative">
            <button
              className="btn btn-light py-2 my-2 text-muted fs-md-6 border-0"
              style={{ backgroundColor: '#F8F5FC' }}
              onClick={studyListRoute}>
              Study Material
              {isExam === true ? (
                <ArrowDropUpIcon className="text-dark" fontSize="medium" />
              ) : (
                <ArrowDropDownIcon className="text-dark" fontSize="medium" />
              )}
            </button>
          </span>
          {/* <div className='ms-auto me-4'>
            <p className="fw-bold mb-0 pt-2" style={{ fontSize: "16px" }}>
              For any query or Career guidance <br /> kindly contact at{' '}
              <span className="landingPhone boldfont cursor me-1"> +91-91 72266 888 </span>
            </p>
          </div> */}
        </div>
        {link !== 'ClassroomCoachingDetails' && <SignInbtn />}
      </header>
      <CourseDropdown open={openCourse} handleClose={closePopup} />
      <StudyMaterialDropdown open={openStudy} handleClose={closePopupStudy} />
    </>
  );
}

export default HeaderHome;
