import React, { useState } from 'react';
import { useSelector } from 'react-redux';

function PlaceOrderInstallment() {
  const { cartAddedData } = useSelector((state) => ({
    cartAddedData: state?.HomeReducer?.cartAddedData
  }));
  const { installmentData } = cartAddedData;

  function padNumber(number) {
    var string = '' + number;
    string = string.length < 2 ? '0' + string : string;
    return string;
  }

  const date = new Date();
  const next_date = new Date(date.setDate(date.getDate() + 30));
  const formatted =
    next_date.getUTCFullYear() +
    '-' +
    padNumber(next_date.getUTCMonth() + 1) +
    '-' +
    padNumber(next_date.getUTCDate());
  return (
    <div>
      <div className="py-4 backimg">
        <div className="customcontainer">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3 checkoutHeight p-3">
                <div className="boxshadow font12 p-3 py-2 rounded mt-3 bgwhite">
                  <div className="mt-2 ms-3">
                    <p className="mb-1 fw-bold mt-1">
                      Total Payable Amount:{' '}
                      <span className="successcolor fw-bold ms-2">
                        &#8377; {installmentData.totalAmountPayable}
                      </span>
                    </p>
                    <div className="px-2 py-1 border w-50">
                      <p className="mb-1 fw-bold">
                        1st Installment{' '}
                        <span className="successcolor fw-bold ms-2">
                          &#8377; {installmentData.installment1}
                        </span>
                      </p>
                      <p className="mb-0 font10">Pay by Today</p>
                    </div>
                    <div className="px-2 py-1 border mt-2 w-50">
                      <p className="mb-1 fw-bold">
                        2nd Installment{' '}
                        <span className="successcolor fw-bold ms-2">
                          &#8377; {installmentData.installment2}
                        </span>
                      </p>
                      <p className="mb-0 font10">Pay installment by {formatted}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlaceOrderInstallment;
