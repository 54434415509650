import React, { useState, useRef, useEffect, Fragment } from 'react';
import '../App.css';
import SubjectTestCard from '../Module/subjectTestCard';
import SubjectTestDetails from '../Module/subjectTestDetails';
import OnlineTestExamCard from '../Module/onlineTestExamCard';
import ScholarshipTestsCard from '../Module/scholarshipTestsCard';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getExamById,
  notSyncedDataAction,
  setInstructionsAction,
  getMockTestList,
  getUnitTests,
  getScholarshipList,
  getLiveScholarshipList,
  getExamListByType,
  getOnlineTest,
  getExamList,
  getExamTopic,
  getExamSubject,
  scholarshipExamRemindMe
} from '../Redux/Actions/examActions';
import { _retrieveData } from '../Utils/utils';
import ScreenNames from '../Constant/ScreenNames';
import moment from 'moment';
import NoRecord from '../Component/noRecord';
import RemindMe from '../Module/remindMe';
import { showSuccessSnackbar } from '../Redux/Actions/snackbarAction';

const SectionWiseTest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialScholarShipState = {
    live: [],
    completed: [],
    upcoming: []
  };
  const [subjectTest, setSubjectTest] = useState([]);
  const [mockTest, setMockTest] = useState([]);
  const [previousQPList, setPreviousQPList] = useState([]);
  const [previousYearsList, setPreviousYearsList] = useState([]);
  const [selectedPrevYear, setSelectedPrevYear] = useState('');
  const [scholarShip, setScholarShip] = useState(initialScholarShipState);
  const [loader, setLoading] = useState(false);
  const [test, settest] = useState([]);
  const [reminderText, setReminderText] = useState('');
  const [isPYQ, setIsPYQ] = useState(false);
  const [testData, setTestData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const startdata = localStorage.getItem('startTest');
  let selectedQuestionIndex = 0;
  const currentURLarr = useLocation().pathname.split('/');
  let link = currentURLarr[currentURLarr.length - 1];
  const {
    examTypeId,
    baseCourseId,
    notSyncedDataFromStore,
    examIdDataFromStoreObject,
    hasActivePaidPlan,
    selectedTiles,
    childrenId
  } = useSelector((state) => ({
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    hasActivePaidPlan:
      link === 'OnlineTestFree' ? false : state?.AuthReducer?.hasActivePaidPlan || false,
    notSyncedDataFromStore: state?.ExamSelectReducer?.notSyncedData,
    examIdDataFromStoreObject: state?.ExamSelectReducer?.examIdData,
    childrenId: state?.AuthReducer?.childrenId,
    selectedTiles: state?.HomeReducer?.selectedTiles
  }));

  useEffect(() => {
    if (isPYQ === true) {
      getExamAPICall(1);
    } else {
      getExamAPICall(1);
    }
  }, [isPYQ]);

  const getExamAPICall = (examTypeLocal) => {
    const postdata = {
      baseCourseId,
      examTypeId,
      isFreeUser: true,
      isPYQ: isPYQ,
      examType: examTypeLocal
    };
    if (postdata.examType === 4) {
      postdata.year = '2023';
      delete postdata.isPYQ;
      delete postdata.isFreeUser;
    }
    console.log('deleteKey', postdata);

    dispatch(
      getExamList(postdata, (res) => {
        console.log('ResponseData:', res);
        setTestData(res);
      })
    );
  };
  const getExam = (e) => {
    const id = e.currentTarget.id;
    if (id === 'pills-Unit-Test-tab') {
      getExamAPICall(1);
    } else if (id === 'pills-Topic-Test-tab') {
      getExamAPICall(3);
    } else if (id === 'pills-Mock-Test-tab') {
      getExamAPICall(2);
    } else if (id === 'pills-PYQ-Unit-Test-tab') {
      getExamAPICall(1);
    } else if (id === 'pills-PYQ-Topic-Test-tab') {
      getExamAPICall(3);
    } else if (id === 'pills-PYQ-Mock-Test-tab') {
      getExamAPICall(4);
    }
  };
  const getExamSubjectApiCall = (examTypeLocal) => {
    const postdata = {
      baseCourseId,
      examTypeId,
      isFreeUser: true,
      isPYQ: isPYQ,
      examType: examTypeLocal
    };
    dispatch(
      getExamSubject(postdata, (res) => {
        console.log('ResponseTopicData:', res);
        setTopicData(res);
      })
    );
  };
  const getSubject = (e) => {
    const id = e.currentTarget.id;
    if (id === 'pills-Topic-Test-tab') {
      getExamSubjectApiCall(3);
    } else if (id === 'pills-PYQ-Topic-Test-tab') {
      getExamSubjectApiCall(3);
    }
  };

  useEffect(() => {
    if (hasActivePaidPlan === true) {
      const postdata = {
        baseCourseId: selectedTiles?.baseCourseId._id,
        examTypeId: selectedTiles?.examTypeId?._id,
        subExamTypeId: selectedTiles?.subExamTypeId?._id,
        courseId: selectedTiles?.courseId
      };
      if (selectedTiles?.subExamTypeId === '') delete postdata.subExamTypeId;

      dispatch(
        getOnlineTest(postdata, (dataState) => {
          settest(dataState);
          setMockTest(dataState?.MockTest);
          setPreviousQPList(dataState?.PYQ);
          setSubjectTest(dataState?.UnitTest);
          const uniquePreviousYearsSet = new Set();
          for (const item of dataState?.PYQ) {
            uniquePreviousYearsSet.add(item._id);
          }
          setPreviousYearsList(['All', ...uniquePreviousYearsSet]);
        })
      );
    } else {
      const postData = {
        baseCourseId: baseCourseId,
        examTypeId: examTypeId,
        subExamTypeId: childrenId
      };

      if (childrenId === '') delete postData.subExamTypeId;
      dispatch(
        getUnitTests(
          postData,
          (res) => {
            const subjectsData = [];
            const subjectSet = new Set(); // store unique subjects
            for (const x of res.data) {
              subjectSet.add(x.subjectName);
            }
            for (const subject of subjectSet) {
              const x = res.data.filter((item) => item.subjectName === subject);
              subjectsData.push({
                title: subject,
                count: x.length,
                subjectId: x[0].subjectId
              });
            }
            setSubjectTest(subjectsData);
          },
          () => {}
        )
      );

      dispatch(
        getMockTestList(
          postData,
          (res) => {
            setMockTest(res.data);
          },
          () => {}
        )
      );

      const postDataPrevYearPapersData = {
        ...postData,
        examType: 4
      };
      dispatch(
        getExamListByType(
          postDataPrevYearPapersData,
          (res) => {
            const uniquePreviousYearsSet = new Set();
            for (const item of res.data) {
              uniquePreviousYearsSet.add(item.examYear);
            }
            const prevQPYearWise = [];
            for (const year of uniquePreviousYearsSet) {
              prevQPYearWise.push({
                examYear: year,
                data: res?.data?.filter((item) => item.examYear == year)
              });
            }
            setPreviousQPList(prevQPYearWise);
            setPreviousYearsList(['All', ...uniquePreviousYearsSet]);
          },
          (err) => {}
        )
      );
      dispatch(
        getScholarshipList(
          postData,
          (res) => {
            const today = new Date();
            const todayDateTime = moment(today, 'YYYY-MM-DD LT');
            const todayDate = moment(today, 'YYYY-MM-DD');

            let completed = [],
              upcoming = [],
              live = [];

            res?.data?.map((item) => {
              const startDate = moment(item.contestStartDate, 'YYYY-MM-DD').utc();
              const endDate = moment(item.contestEndDate, 'YYYY-MM-DD').utc();

              const startDateTime = moment(item.contestStartDate, 'YYYY-MM-DD LT').utc();
              const endDateTime = moment(item.contestEndDate, 'YYYY-MM-DD LT').utc();
              if (todayDate < startDate && todayDate < endDate) {
                upcoming.push(item);
              } else if (todayDateTime > startDateTime && todayDateTime > endDateTime) {
                completed.push(item);
              } else {
                live.push(item);
              }
            });
            setScholarShip((prevState) => ({ ...prevState, completed, upcoming, live }));
          },
          () => {}
        )
      );
      dispatch(
        getLiveScholarshipList(
          postData,
          (res) => {
            setScholarShip((prevState) => ({ ...prevState, live: res.data }));
          },
          () => {}
        )
      );
    }
  }, []);

  const onStartExam = (examId) => {
    const examIdDataFromStore = examIdDataFromStoreObject[examId];
    const args = {
      examId,
      navigate,
      setLoading,
      dispatch,
      getExamById,
      notSyncedDataFromStore,
      selectedQuestionIndex,
      notSyncedDataAction,
      examIdDataFromStore,
      setInstructionsAction
    };
    _retrieveData(args);
  };

  const onpressTest = (subject) => {
    if (hasActivePaidPlan == true)
      navigate(`/${ScreenNames.PaidUser}/${ScreenNames.SubjectTest}`, {
        state: { subject: subject }
      });
    else {
      if (link === 'FreeOnlineTest') {
        navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.FreeSubjectTest}`, {
          state: { subject: subject }
        });
      } else {
        navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.SubjectTest}`, {
          state: { subject: subject }
        });
      }
    }
  };

  const onpressTopicList = (_id) => {
    const postData = {
      baseCourseId,
      examTypeId,
      isFreeUser: true,
      isPYQ: isPYQ,
      examType: 3,
      subjectId: _id
    };
    if (link === 'FreeTopicList') {
      navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.FreeTopicList}`, {
        state: postData
      });
    } else {
      navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.FreeTopicList}`, {
        state: postData
      });
    }
  };

  const [open, setopen] = useState(false);
  const handleClose = () => {
    setopen(false);
  };

  const scholarshipRemindMe = (examId, flag = true) => {
    const examUrl = 'https://ifasonline.com/'; // TODO: it will be provided from backend
    setReminderText(examUrl);

    if (flag === false) {
      dispatch(
        scholarshipExamRemindMe(
          { examId },
          (res) => {
            const postData = {
              baseCourseId: baseCourseId,
              examTypeId: examTypeId,
              subExamTypeId: childrenId
            };
            dispatch(
              getScholarshipList(
                postData,
                (res) => {
                  const today = new Date();
                  const todayDateTime = moment(today, 'YYYY-MM-DD LT');
                  const todayDate = moment(today, 'YYYY-MM-DD');

                  let completed = [],
                    upcoming = [],
                    live = [];

                  res?.data?.map((item) => {
                    const startDate = moment(item.contestStartDate, 'YYYY-MM-DD').utc();
                    const endDate = moment(item.contestEndDate, 'YYYY-MM-DD').utc();

                    const startDateTime = moment(item.contestStartDate, 'YYYY-MM-DD LT').utc();
                    const endDateTime = moment(item.contestEndDate, 'YYYY-MM-DD LT').utc();
                    if (todayDate < startDate && todayDate < endDate) {
                      upcoming.push(item);
                    } else if (todayDateTime > startDateTime && todayDateTime > endDateTime) {
                      completed.push(item);
                    } else {
                      live.push(item);
                    }
                  });

                  setScholarShip((prevState) => ({ ...prevState, completed, upcoming, live }));
                  setopen(true);
                },
                () => {}
              )
            );
          },
          (err) => {}
        )
      );
    } else if (flag === true) {
      dispatch(showSuccessSnackbar('Reminder is already on!'));
      setopen(true);
    }
  };
  let prevYearQP = [];
  if (hasActivePaidPlan == true) {
    prevYearQP =
      selectedPrevYear === 'All' || selectedPrevYear === ''
        ? previousQPList
        : previousQPList.filter((item) => item._id == selectedPrevYear);
  } else {
    prevYearQP =
      selectedPrevYear === 'All' || selectedPrevYear === ''
        ? previousQPList
        : previousQPList.filter((item) => item.examYear == selectedPrevYear);
  }
  return (
    <div className="rightPanel">
      <h3>Practice Test</h3>
      <div className="customtab mt-4 ">
        <ul
          className="nav nav-pills mb-3 boxshadow p-2 rounded bgwhite"
          id="pills-tab"
          role="tablist">
          <li className="nav-item me-3 mt-1 " role="presentation">
            <button
              className="nav-link active"
              id="pills-Unit-Test-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-Unit-Test"
              type="button"
              role="tab"
              aria-controls="pills-Unit-Test"
              aria-selected="true"
              onClick={getExam}>
              Unit Test
            </button>
          </li>
          <li className="nav-item me-3 mt-1" role="presentation">
            <button
              className="nav-link"
              id="pills-Topic-Test-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-Topic-Test"
              type="button"
              role="tab"
              aria-controls="pills-Topic-Test"
              aria-selected="true"
              onClick={getSubject}>
              Topic Test
            </button>
          </li>
          <li className="nav-item me-3 mt-1" role="presentation">
            <button
              className="nav-link"
              id="pills-Mock-Test-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-Mock-Test"
              type="button"
              role="tab"
              aria-controls="pills-Mock-Test"
              aria-selected="true"
              onClick={getExam}>
              Mock Test
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade active show"
            id="pills-Unit-Test"
            role="tabpanel"
            aria-labelledby="pills-Unit-Test-tab"
            tabIndex="0">
            <div className="mt-1 subjectTestheight p-1 p-md-3">
              {testData.length > 0 ? (
                testData.map((c) => {
                  return (
                    <SubjectTestCard
                      key={c?.subjectId}
                      title={c?.subjectName}
                      lectcount={c?.totalQuestions}
                      onpress={() => onpressTest(c)}
                    />
                  );
                })
              ) : (
                <NoRecord />
              )}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-Topic-Test"
            role="tabpanel"
            aria-labelledby="pills-Topic-Test-tab"
            tabIndex="0">
            <div className="mt-1 subjectTestheight p-1 p-md-3">
              {topicData.length > 0 ? (
                topicData.map((c) => {
                  return (
                    <SubjectTestCard
                      key={c?._id}
                      title={c?.name}
                      lectcount={c?.count}
                      // time={c?.duration}
                      onpress={() => onpressTopicList(c._id)}
                    />
                  );
                })
              ) : (
                <NoRecord />
              )}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-Mock-Test"
            role="tabpanel"
            aria-labelledby="pills-Mock-Test-tab"
            tabIndex="0">
            <div
              className={
                mockTest.length != 0
                  ? 'mt-1 subjectTestheight p-1 p-md-3 width75 '
                  : 'mt-1 subjectTestheight p-1 p-md-3  col-12'
              }>
              {testData.length > 0 ? (
                testData.map((c) => {
                  return (
                    <SubjectTestDetails
                      key={c.examId}
                      data1={c}
                      existInStore={examIdDataFromStoreObject[c?.examId] ? true : false}
                      onStartExam={() => onStartExam(c.examId)}
                      startdata={startdata}
                    />
                  );
                })
              ) : (
                <NoRecord />
              )}
            </div>
          </div>
        </div>
      </div>
      <RemindMe open={open} text={reminderText} resetInterval={3000} handleClose={handleClose} />
    </div>
  );
};

export default SectionWiseTest;
