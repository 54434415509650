import home from '../Assets/Images/Dashboard/home.png';
import course from '../Assets/Images/Dashboard/course.png';
import learn from '../Assets/Images/Dashboard/learn.png';
import readingBook from '../Assets/Images/Dashboard/readingBook.png';
import profile from '../Assets/Images/Dashboard/profile.png';
import address from '../Assets/Images/Dashboard/address.png';
import orderhistory from '../Assets/Images/Dashboard/orderhistory.png';
import refer from '../Assets/Images/Dashboard/refer.png';
import setting from '../Assets/Images/Dashboard/setting.png';
import help from '../Assets/Images/Menu/help.png';
import RecordedLectures from '../Screen/recordedLectures';

export const PaidSidebarData = [
  {
    title: 'Dashboard',
    link: 'PaidDashboard',
    extra: ['PaidDashboard'],
    imgpath: home
  },
  {
    title: 'My Courses',
    link: 'MyLearning',
    extra: [
      'MyLearning',
      'MyLectures',
      'OnlineTest',
      'LectureSchedule',
      'MediaPlayer',
      'AllAbout',
      'SubjectTest',
      'LectureSubject',
      'Topics',
      'FreeLectures',
      'AllLectureVideo',
      'CourseAnalysis'
    ],
    imgpath: readingBook
  },
  {
    title: 'All Courses',
    link: 'OnlineCourse',
    extra: ['OnlineCourse'],
    imgpath: course
  },

  {
    title: 'Learn Free',
    link: 'Learn',
    extra: [
      'Lectures',
      'Learn',
      'FreeOnlineTest',
      'FreeAllAbout',
      'AllLectureVideo',
      'TeacherWiselectures',
      'FreeSubjectTest',
      'LectureScheduleFree',
      'FreeLectures',
      'LiveClass',
      'results',
      'RecordedLectures'
    ],
    imgpath: learn
  },
  {
    title: 'My Profile',
    link: 'myProfile',
    extra: ['myProfile', 'MyProfile'],
    imgpath: profile
  },
  {
    title: 'Help',
    link: 'Help',
    extra: [
      'Help',
      'Chat',
      'CourseSupport',
      'TechnicalSupport',
      'SupportChat',
      'AddTechnicalSupport',
      'AddcourseSupport'
    ],
    imgpath: help
  },
  {
    title: 'Addresses',
    link: 'MyAddress',
    extra: ['MyAddress'],
    imgpath: address
  },
  {
    title: 'Order History',
    link: 'OrderHistory',
    extra: ['OrderHistory', 'OrderDetails'],
    imgpath: orderhistory
  },
  {
    title: 'Refer & Earn',
    link: 'ReferEarn',
    extra: ['ReferEarn'],
    imgpath:refer
  }
  // {
  //   title: 'Settings',
  //   link: 'addbooks',
  //   extra: ['addbooks'],
  //   imgpath: setting
  // }
];
