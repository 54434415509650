export default {
  auth: {
    country: '/v1/countries',
    sendOtp: '/v1/auth/website/get-otp',
    verifyOtp: '/v1/auth/web/verify-otp',
    verifyUser: '/v1/auth/web/verifyExistingUserOtp',
    getState: '/v1/states',
    getCity: '/v1/cities/',
    getCourse: '/v1/base-courses',
    register: '/v1/auth/web/register',
    classroomRegister: '/v1/auth/classroom/web/register',
    loginUser: '/v1/auth/login',
    logout: '/v1/auth/logout',
    getstart: '/v1/auth/getStarted/callExpert',
    getBranch: '/v1/branch/list',
    getBatchDate: 'v1/batch/list',
    classroomBaseCourseList: '/v1/classroom/listBaseCourseId',
    classroomBranchList: '/v1/classroom/listBranchId',
    classroomList: '/v1/classroom/list',
    patchclassroom: '/v1/classroom/classroomPurchase',
    registerUser: '/v2/auth/web/register',
    getId: '/v2/get-id',
    getName: '/v2/get-name'
  },
  course: {
    getState: '/v1/states',
    getParentCourse: '/v1/base-courses',
    getBaseCourse: '/v1/get-baseCourse/'
  },
  exam: {
    getExam: '/v1/exams/getExam',
    examType: '/v1/exam-types',
    submitAdmitCard: '/v1/exams/submitAdmitCard',
    submitExam: '/v1/exams/submitExam',
    startExam: '/v1/exams/startExam',
    startExamV2: '/v2/exam/startExam',
    getUnitTests: '/v1/exams/getUnitTests',
    getExamById: '/v1/exams/getExam/',
    getExamListByType: '/v1/exams/getExams',
    getMockTestList: '/v1/exams/getMockTests',
    getScholarshipList: '/v1/exams/scholarshipExams',
    getLiveScholarshipList: '/v1/exams/liveScholarshipExam',
    scholarshipRemindMe: '/v1/users/scholarship/exam-reminder/add',
    examTypeClassroom: '/v1/exam-type/classroom',
    getExamType: '/v1/examType/getExamTypeList',
    getClassroomBaseCourseExam: '/v1/classroom/listBaseCourseAndExamType',
    getBatchListFilter: '/v1/batch/list-filter',
    getExamList: '/v2/exam-type/list',
    getSubExamList: '/v2/subexam-type/list',
    getSubExamStudyMaterialList: '/v2/subexam-type/study-material/list',
    getExamListStudyMaterial: '/v2/exam-type/study-material/list'
  },
  home: {
    getCourseHome: '/v1/courses/get-course-for-home-page',
    getCourseList: '/v1/courses/get-course-list',
    getCourseDetail: '/v1/courses/get-course-details',
    getfreeCourseDetail: '/v1/get-course-details',
    getFreeLecture: '/v1/courses/get-free-lectures',
    getBanner: '/v1/banners/list',
    getQuote: '/v1/get-quote',
    addToCart: '/v1/cart/add-to-cart',
    removeToCart: '/v1/cart/remove-from-cart',
    cartList: '/v1/cart/cart-list',
    recentlyList: 'v1/cart/recently-viewed-list',
    updateSession: '/v1/cart/update',
    addon: '/v1/cart/add-ons',
    applyCoupon: '/v1/cart/apply-coupon',
    applyCouponfree: '/v1/apply-coupon',
    applyCouponCart: '/v1/cart/apply-coupon-from-cart',
    removeCoupon: '/v1/cart/remove-coupon',
    placeOrder: '/v1/orders/place-order',
    updateOrder: '/v1/orders/update-order',
    getPaidCourseList: '/v1/courses/get-paid-course-list',
    getUnActiveCourse: '/v1/courses/get-unactive-course-list',
    getUnActiveSubscribedCourse: '/v1/courses/subscribed-inactive-courses-list',
    getUnActiveSubscribedCourseNew: '/v1/courses/subscribed-inactive-courses-list_new',
    getLiveVideo: '/v1/courses/get-live-video',
    getRecordedLectures: '/v1/courses/get-recorded-lectures/',
    getSubscribedRecordedLectures: '/v1/courses/get-subscribed-recorded-lectures/',
    getRecordedLectureDetails: '/v1/courses/get-recorded-lecture-details/',
    getOnlineTest: '/v1/courses/get-tests',
    getExamList: '/v1/exams/getExamsList',
    getExamTopic: '/v1/exams/get-exams-topics',
    getExamSubject: '/v1/exams/get-exams-subjects',
    getSubjectList: '/v1/supports/list-subjects/',
    getTopicList: '/v1/supports/list-topics/',
    addChat: '/v1/supports/add',
    getMessageList: '/v1/supports/list-messages/',
    getExams: '/v1/exams/getExams/',
    addSupportMessage: '/v1/supports/add-support-message',
    getListSupport: 'v1/supports/list-support/',
    getOrderList: '/v1/orders/order-list',
    getOrderDetails: '/v1/orders/',
    getFreeLectureList: '/v1/courses/freeLectures',
    getFreeLectureByTeacher: '/v1/courses/freeLecturesByTeacher',
    getPremierVideo: '/v1/courses/get-premier-video/',
    lectureSchedule: '/v1/courses/get-lecture-schedule/',
    lectureScheduleFree: '/v1/courses/get-lecture-schedule-free',
    getLiveLecturesForFree: '/v1/courses/get-free-user-live-video',
    getExtendedCourseDetail: '/v1/courses/get-extended-course-details',

    scholarshipExams: '/v1/exams/scholarshipExams',
    liveScholarshipExam: '/v1/exams/liveScholarshipExam',
    unitTestsFree: '/v1/exams/getUnitTests',
    mockTests: '/v1/exams/getMockTests',
    notificationList: '/v1/users/get-notification-list/',
    notificationUnread: '/v1/users/get-unread-notifications',
    placeOrderInstallment: '/v1/orders/pay-installment/',
    uploadSupportMedia: '/v1/supports/upload-file/',
    addVideoRating: '/v1/users/add-video-ratings',
    getVideoRating: '/v1/users/get-video-ratings',
    updateVideoRating: '/v1/users/update/',
    startEndLiveStatus: '/v1/users/start-live-status',
    deleteSupportMsg: '/v1/supports/support-message-delete/',

    getExamSectionList: '/v1/exam-section/list',
    getExamDateList: '/v1/exam-dates/list',
    getExamSyllabus: '/v1/exam-syllabus/list',
    getAboutExamFAQ: '/v1/about-exam/list',
    getPyqPdf: '/v1/pyqPDF/list',
    getExamPaperAnalysis: '/v1/exam-paper-analysis/list',
    getCourseSubscribed: '/v1/courses/subscribed-courses-list-with-support-available',
    getCourseFreeLectureSubExam: '/v1/get-subscriptions-freeLectures-subexam',
    getCourseFreeLecture: '/v1/get-subscriptions-freeLectures',
    getCourseFreeLectureLive: '/v1/courses/get-subscriptions-freeLectures',
    classRoomPlaceorder: '/v1/orders/place-classroom-order',
    getUserData: '/v1/classroom/getUserDetails',
    getSubjectPredictor: '/v1/rankPredictor/get-subjects',
    getCategoriesPredictor: '/v1/rankPredictor/get-categories',
    getPredictRank: '/v1/rankPredictor/get-rank',
    getResultList: '/v1/exam-result/list',
    getMonthYearList: '/v1/exam-result/months-years-list',

    getReferLink: '/v1/users/get-link',
    sendReferLink: '/v1/users/send-referral-link',
    getMyRerfer: '/v1/users/get-my-referrals',
    getAllRefer: '/v1/users/get-all-referrals',
    addUserAccount: '/v1/users/add-bankAccount',
    getRefferDetails: '/v1/users/get-referrer-details',
    getReferCashback: '/v1/users/get-cashback-details',

    getTestAttempted: '/v1/users/course/test-attemped',
    getLearningProgress: '/v1/users/course/learning-progress',
    videoWatchStatus: '/v1/users/course/video-watch-status/add',

    getUserRank: '/v1/users/course/user-rank',
    getTestSeriesRank: '/v1/users/course/user-testseriesrank',
    getPyqRank: '/v1/users/course/user-pyqrank',
  },
  setting: {
    getSetting: '/v1/users/settings',
    updateSetting: '/v1/users/settings/',
    addressList: '/v1/users/get-address',
    deleteAddress: '/v1/users/delete-address',
    defaultAddress: '/v1/users/set-default-address',
    addAddress: '/v1/users/add-address',
    updateAddress: '/v1/users/update-address',
    editProfile: '/v1/users/edit-profile',
    uploadProfilePic: '/v1/users/upload',
    deleteUser: '/v1/users/delete-user'
  },
  chat: {
    reopensupport: '/v1/supports/reopen-support/'
  },
  mainType: {},
  Job: {
    getJobPosition: '/v1/get-jobposition',
    getCareerJobsList: '/v1/get-career-listfilter',
    getCareerDesignationsList: '/v1/get-career-designations',
    getCareerjobloction: '/v1/get-career-location',
    getCutoffList: '/v1/exam-paper-analysis/cutoff/list'
  }
};
