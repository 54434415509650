import { sessions } from '../../Modals';
import * as Actions from '../../Types/sessionTypes';

export const SessionReducer = (state = new sessions(), action) => {
  switch (action.type) {
    case Actions.SET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
