import React, { useState } from 'react';
import '../App.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import AppleIcon from '../Assets/Images/Dashboard/AppleIcon.png';
import Playstore from '../Assets/Images/Dashboard/playstore.png';
function Sidebar(props) {
  const currentURLarr = useLocation().pathname.split('/');
  let link = currentURLarr[currentURLarr.length - 1];
  if (link === 'Course-dashboard') link = 'createParentCourse';

  const hidemenu = () => {
    document.getElementById('sidebarMenu').classList.remove('active');
  };

  return (
    <div className="sidebar" id="sidebarMenu">
      <ul className="sidebarList shadow">
        {props.data.map((val, key) => {
          const index = val.extra.findIndex((item) => item === link);
          return (
            <li
              key={key}
              id={key}
              className={index > -1 ? 'sideListRow active' : 'sideListRow'}
              onClick={hidemenu}>
              <Link to={val.link}>
                <img className="me-2" src={val.imgpath} alt={val.title} />
                {val.title}
              </Link>
            </li>
          );
        })}

        <div className="mt-3 downloadsection">
          <p className="fw-bold boldfont" style={{ color: 'var(--blue)' }}>
            Download App Now
          </p>
          <div className="mb-3 ">
            <a
              href="https://play.google.com/store/apps/details?id=com.ifasapp"
              target="_blank"
              className="d-flex store align-items-center rounded-3"
              rel="noreferrer">
              <div className="imgdiv">
                <img src={Playstore} style={{ width: '25px' }} alt="Playstore" />
              </div>
              <div className="ms-3 me-2">
                <span className="d-block font11 lh-sm">Get it On</span>
                <span className="d-block storename font12 lh-1">Play Store</span>
              </div>
            </a>
          </div>
          <div>
            <a
              href="https://itunes.apple.com/us/app/ifas-online/id1448199555?ls=1&mt=8"
              target="_blank"
              className="d-flex store align-items-center rounded-3"
              rel="noreferrer">
              <div className="imgdiv">
                <img src={AppleIcon} style={{ width: '25px' }} alt="Playstore" />
              </div>
              <div className="ms-3 me-2">
                <span className="d-block font11 lh-sm">Get it On</span>
                <span className="d-block storename font12 lh-1">App Store</span>
              </div>
            </a>
          </div>
        </div>
      </ul>
    </div>
  );
}

export default Sidebar;
