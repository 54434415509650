import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { useState, useRef } from 'react';

function ClassroomDropdown({ lableName, setInputText, options, defaultValue, reset, type }) {
  const [names, setName] = useState(lableName);

  React.useEffect(() => {
    var newArray;
    newArray = options.filter(function (el) {
      return el._id === defaultValue;
    });
    newArray = options?.map((item) => {
      if (type === 'date') {
        setName(dateFormatter(item.date));
      } else {
        setName(item.name);
      }
      setInputText(item);
    });
  }, [defaultValue]);

  React.useEffect(() => {
    setName(lableName);
  }, [reset]);

  function padNumber(number) {
    var string = '' + number;
    string = string.length < 2 ? '0' + string : string;
    return string;
  }
  function dateFormatter(value) {
    const date = new Date(value);
    const next_date = new Date(date.setDate(date.getDate()));
    const formatted =
      next_date.getUTCFullYear() +
      '-' +
      padNumber(next_date.getUTCMonth() + 1) +
      '-' +
      padNumber(next_date.getUTCDate());
    return formatted;
  }

  const elementRef = useRef();
  const handleChange = (e) => {
    if (type === 'date') setName(dateFormatter(e.target.value.date));
    else setName(e.target.value.name);
    setInputText(e.target.value);
  };

  return (
    <div className="d-flex">
      <Select
        className="custom-Dropdown"
        defaultValue={names}
        value={names}
        label="name"
        onChange={handleChange}>
        <MenuItem disabled value={names}>
          {names}
        </MenuItem>
        {options !== undefined
          ? options?.map((item) => (
              <MenuItem key={item?._id} value={item}>
                {item?.name ? item?.name : item?.date ? dateFormatter(item?.date) : item.name}
              </MenuItem>
            ))
          : null}
      </Select>
    </div>
  );
}

export default ClassroomDropdown;
