import React from 'react';
import { useState } from 'react';
import { MenuItem, Select } from '@mui/material';
function CustomDropdown({ lableName, setInputText, options, defaultValue, reset, Isrequired }) {
  const [names, setName] = useState(lableName);

  React.useEffect(() => {
    var newArray;
    if (defaultValue != undefined) {
      newArray = options.filter(function (el) {
        return el._id === defaultValue;
      });
    }
    newArray?.map((item) => {
      setName(item.name);
      setInputText(item);
    });
  }, [defaultValue]);

  React.useEffect(() => {
    if (reset != null) {
      setName(lableName);
    }
  }, [reset]);

  const handleChange = (e) => {
    setName(e.target.value.name);
    setInputText(e.target.value);
  };

  return (
    <div className="d-flex">
      <Select
        className="custom-Dropdown"
        defaultValue={names}
        value={names}
        label="name"
        onChange={handleChange}
        required={Isrequired}>
        <MenuItem disabled value={names}>
          {names}
        </MenuItem>
        {options !== undefined
          ? options?.map((item) => (
              <MenuItem key={item?._id} value={item}>
                {item?.name}
              </MenuItem>
            ))
          : null}
      </Select>
      {Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}

export default CustomDropdown;
