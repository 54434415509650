import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getExamList, getSubExamList, updateMoreValues } from '../Redux/Actions/AuthAction';
import { useNavigate } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { updateCourseDetailValues } from '../Redux/Actions/courseDetailAction';
import { editProfile } from '../Redux/Actions/homeAction';

function CourseList(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [parentCourse, setParentCourse] = useState([]);
  const [exam, setExam] = useState('');
  const [examData, setExamData] = useState([]);
  const [subExamName, setSubExamName] = useState('');
  const [SubExamData, setSubExamData] = useState([]);
  const [showSubCourse, setShowSubCourse] = useState(false);
  const [showState, setShowState] = useState(false);
  const [stateList, setStateList] = useState([]);
  const { hasActivePaidPlan, examTypeName, examTypeId, baseCourseId } = useSelector((state) => ({
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
    examTypeName: state?.AuthReducer?.examTypeName,
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId
  }));

  const handleClick = (val) => {
    dispatch(
      updateCourseDetailValues({
        packageSection: [],
        sessions: [],
        courseDetail: {}
      })
    );
    if (window.location.pathname == '/Home') {
      navigate('/DashboardMenu/Dashboard');
    } else if (window.location.pathname === '/CourseDetails') {
      navigate('/DashboardMenu/Dashboard');
    } else if (window.location.pathname === '/StudyMaterialDetails') {
      navigate('/DashboardMenu/Dashboard');
    } else if (window.location.pathname === '/LectureSeries') {
      navigate('/DashboardMenu/Dashboard');
    }

    const data = {
      baseCourseId: val?._id,
      baseCourseName: val?.name,
      examTypeId: exam?._id,
      examTypeName: exam?.name
    };

    if (examTypeName === undefined) {
      dispatch(
        editProfile(
          data,
          () => {},
          () => {}
        )
      );
    }

    dispatch(
      updateMoreValues({
        baseCourseId: val?._id,
        baseCourseName: val?.name,
        examTypeId: exam?._id,
        examTypeName: exam?.name,
        childrenId: subExamName?._id,
        childrenName: subExamName?.name
      })
    );

    props.handleClose();
    if (hasActivePaidPlan) {
      navigate('/PaidUser/PaidDashboard');
    } else {
      navigate('/DashboardMenu/Dashboard');
    }
  };

  useEffect(() => {
    dispatch(
      getExamList((dataCourse) => {
        setExamData(dataCourse);
        const index = dataCourse.findIndex((item) => item._id === examTypeId);
        const finalIndex = index > -1 ? index : 0;
        setExam(dataCourse[finalIndex]);
        if (dataCourse[finalIndex]?.isStatewise === true) {
          setStateList(dataCourse[finalIndex]?.stateIds);
        } else if (dataCourse[finalIndex]?.childrenId?.length > 0) {
          setShowSubCourse(true);
          setSubExamData(dataCourse[finalIndex]?.childrenId);
        } else if (dataCourse[finalIndex]?.baseCourseId.length > 0) {
          const newData = dataCourse[finalIndex]?.baseCourseId.map((item) => {
            if (item._id === baseCourseId) return { ...item, isSelected: true };
            else return { ...item, isSelected: false };
          });
          setParentCourse(newData);
        } else {
          setShowSubCourse(false);
        }
      })
    );
  }, []);

  const getExamData = (val) => {
    setExam(val);
    setSubExamData([]);
    if (val?.isStatewise === true) {
      setStateList(val?.stateIds);
      setShowState(true);
      setSubExamData([]);
    } else {
      setShowState(false);
      if (val?.baseCourseId.length > 0) {
        setParentCourse(val?.baseCourseId);
      }
      if (val?.childrenId?.length > 0) {
        setSubExamData(val?.childrenId);
        setShowSubCourse(true);
      } else {
        setShowSubCourse(false);
      }
    }
  };

  const onClickState = (val) => {
    setShowState(false);
    if (exam?.childrenId?.length > 0) {
      dispatch(
        getSubExamList(
          { examTypeId: exam?._id, stateId: val?._id },
          (res) => {
            if (res?.data?.length > 0) {
              setSubExamData(res?.data);
              setShowSubCourse(true);
            } else {
              setShowSubCourse(false);
            }
          },
          () => {}
        )
      );
    } else {
      setShowSubCourse(false);
    }
  };
  const onClickSubExam = (val) => {
    setSubExamName(val);
    if (exam?.isStatewise === true) {
      setParentCourse(val?.baseCourseId);
      setShowSubCourse(false);
    } else {
      setShowSubCourse(false);
    }
  };
  const baseCourse = props.isClassroomProgram ? classroomParentCourse : parentCourse;

  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model courseModel"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={props.open}>
          <div className="coursemodeldiv mb-2">
            <ClearOutlinedIcon
              className="colorblue fs-1 p-1 shadow rounded-circle border-primary cursor float-end"
              onClick={props.handleClose}
            />
            <div className="courseDropdown">
              <div className="backimg ">
                <div className="d-flex align-items-start p-md-3 px-md-3 row homecourse">
                  <div
                    className="nav flex-md-column nav-pills col-12 col-md-2 flex-nowrap boxshadow  px-0 mb-3 mb-md-0"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical">
                    {examData?.map((c, index) => {
                      return (
                        <button
                          key={index.toString()}
                          className={`nav-link d-flex ${
                            exam?._id === c?._id ? 'active' : ''
                          } text-start`}
                          id={'tab' + c._id}
                          data-bs-toggle="pill"
                          data-bs-target={'#v-pills' + c._id}
                          type="button"
                          role="tab"
                          aria-controls={'#v-pills' + c._id}
                          aria-selected="true"
                          onClick={() => getExamData(c)}>
                          <img src={c.file} alt={c.name} className="examLogoimg" />
                          {c.name}
                        </button>
                      );
                    })}
                  </div>
                  <div className="tab-content col-12 col-md-9" id="v-pills-tabContent">
                    {showState ? (
                      <div className="d-flex flex-wrap mt-2 courselist ">
                        {stateList?.map((item, index) => (
                          <div
                            key={index}
                            className="courseDiv text-center border rounded"
                            onClick={() => onClickState(item)}>
                            {item?.file ? (
                              <img src={item.file} alt={item.name} className="examLogoimg" />
                            ) : null}
                            <span className="d-block mt-2 font12">{item?.name}</span>
                          </div>
                        ))}
                      </div>
                    ) : showSubCourse ? (
                      <div className="d-flex flex-wrap mt-2 courselist ">
                        {SubExamData?.map((item, index) => (
                          <div
                            key={index}
                            className="courseDiv text-center border rounded"
                            onClick={() => onClickSubExam(item)}>
                            {item?.file ? (
                              <img src={item.file} alt={item.name} className="examLogoimg" />
                            ) : null}
                            <span className="d-block mt-2 font12">{item?.name}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="d-flex flex-wrap mt-2 courselist ">
                        {baseCourse?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="courseDiv text-center border rounded"
                              onClick={() => handleClick(item)}>
                              {item?.file ? (
                                <img src={item.file} alt={item.name} className="examLogoimg" />
                              ) : null}
                              <span className="d-block mt-2 font12">{item?.name}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default CourseList;
