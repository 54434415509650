import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRank, getTestSeriesRank, getPyqRank } from '../Redux/Actions/homeAction';
import { getExamById, notSyncedDataAction, setInstructionsAction } from '../Redux/Actions/examActions';
import { getTimeTakenToStringFormat } from '../Utils/utils';
import moment from 'moment';
import { LocalImages } from '../Constant';
import { useNavigate } from 'react-router-dom';
import NoRecord from '../Component/noRecord';
import OverAllResult from '../Component/overallResult';
function TestAnalysis() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userRank, setUserRank] = useState([]);
  const [currentUserData, setCurrentUserData] = useState([]);
  const [isPYQ, setIsPYQ] = useState(false);
  const [loader, setLoading] = useState(false);
  const [limit, setlimit] = useState(10);
  const { examTypeId, baseCourseId, userId } = useSelector((state) => ({
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    userId: state?.AuthReducer?._id,
  }));

  useEffect(() => {
    getRank();
  }, []);

  useEffect(() => {
    if (isPYQ === true) {
      getPYQ(1)
    } else if (isPYQ === false) {
      getPYQ(1)
    } else {
      getPYQ(1)
    }
  }, [isPYQ]);

  const changePYQ = (e) => {
    const id = e.currentTarget.id;

    if (id === "pills-pyq-test-tab") {
      setIsPYQ(true);
      getPYQ(1)
    }
    else if (id === "pills-practice-test-tab") {
      setIsPYQ(false);
      getPYQ(1)
    }
    if (isPYQ) { return; }
  }

  const getRank = (eType) => {
    const postData = {
      examTypeId: examTypeId,
      baseCourseId: baseCourseId,
      examType: eType
    }
    dispatch(getUserRank(postData, async (data) => {
      await setUserRank(data.rankResult);
      const filteredUser = data.rankResult.filter(currentUser => (currentUser.userId.includes(userId)))
      setCurrentUserData(filteredUser);
    }))
  }

  const getTsRank = (eType) => {
    const postData = {
      examTypeId: examTypeId,
      baseCourseId: baseCourseId,
      examType: eType
    }
    dispatch(getTestSeriesRank(postData, async (data) => {
      await setUserRank(data.rankResult);
      const filteredUser = data.rankResult.filter(currentUser => (currentUser.userId.includes(userId)))
      setCurrentUserData(filteredUser);
    }))
  }
  const getPYQ = (eType) => {
    const postData = {
      examTypeId: examTypeId,
      baseCourseId: baseCourseId,
      examType: eType,
      isPYQ: isPYQ,
    }
    if (eType === 4) delete postData.isPYQ;

    dispatch(getPyqRank(postData, async (data) => {
      await setUserRank(data.rankResult);
      const filteredUser = data.rankResult.filter(currentUser => (currentUser.userId.includes(userId)))
      setCurrentUserData(filteredUser);
    }))
  }
  const getExamType = (e) => {
    const id = e.currentTarget.id;
    if (id === "pills-all-tab") {
      getRank('')
    }
    else if (id === "pills-dpp-tab") {
      getRank(3)
    }
    else if (id === "pills-test-series-tab") {
      getTsRank(1)
    }
    else if (id === "pyq-unit") {
      getPYQ(1)
    }
    else if (id === "pyq-topic") {
      getPYQ(3)
    }
    else if (id === "pyq-mock") {
      getPYQ(4)
    }
    else if (id === "practice-unit") {
      getPYQ(1)
    }
    else if (id === "practice-topic") {
      getPYQ(3)
    }
    else if (id === "practice-mock") {
      getPYQ(4)
    }
  }

  const _retrieveData = async (examId) => {
    try {
      let strNotSyncedData = notSyncedDataFromStore || '{}';

      let notSyncedData = JSON.parse(strNotSyncedData);
      selectedQuestionIndex = +(notSyncedData[examId] || 0);
      dispatch(notSyncedDataAction(JSON.stringify(notSyncedData)));
    } catch (error) { }

    try {
      const value = examIdDataFromStore;

      if (value !== null) {
        let examData = JSON.parse(value);
        let examDataT = JSON.parse(`{"examData": [${value}]}`);
        dispatch(setInstructionsAction(examDataT));
        if (examData.isSubmitted === true) {
          navigate('/DetailResult', { state: { examId: examId, examData: examData } });
        } else {
          let tmp = [examData];
          navigate('/ExamInstruction', { state: { examId: examId, examData: tmp } });
        }
      } else {
        getExamAPI(examId);
      }
    } catch (error) {
      getExamAPI(examId);
    }
  };

  const getExamAPI = (examId) => {
    setLoading(true);
    dispatch(
      getExamById(
        examId,
        (data) => {
          setLoading(false);
          let examData = data.data.examData;
          let examStatus = examData[0].status;
          let isContestTest = examData[0].isContestTest;
          if (examStatus === 2) {
            if (isContestTest === true) {
              let currentDate = +new Date();
              let contestEndDate = +new Date(examData.contestEndDate);
              if (currentDate > contestEndDate) {
                sessionStorage.setItem('examId', examId);
                sessionStorage.setItem('examData', JSON.stringify(examData[0]));
                window.open(
                  '/DetailResult',
                  '_blank',
                  'width=' + screen.availWidth + ',height=' + screen.availHeight
                );
              } else {
              }
            } else {
              sessionStorage.setItem('examId', examId);
              sessionStorage.setItem('examData', JSON.stringify(examData[0]));
              window.open(
                '/DetailResult',
                '_blank',
                'width=' + screen.availWidth + ',height=' + screen.availHeight
              );
            }
          } else {
            if (examData[0]?.totalQuestions !== examData[0]?.totalAddedQuestion) {
              dispatch(
                showErrorSnackbar('This Exam will be available soon, Please try after sometime')
              );
            } else {
              sessionStorage.setItem('examId', examId);
              sessionStorage.setItem('examData', JSON.stringify(examData));
              localStorage.setItem('startTest', '1');
              var windowName = 'userConsole';
              var popUp = window.open(
                '/ExamInstruction',
                windowName,
                '_blank',
                'width=' + screen.availWidth + ',height=' + screen.availHeight
              );
              if (popUp == null || typeof popUp == 'undefined') {
                localStorage.setItem('startTest', '2');
                alert('Please disable your pop-up blocker and click the "Open" link again.');
              } else {
                popUp.focus();
              }
            }
          }
        },
        () => {
          setLoading(false);
        }
      )
    );
  };
  const testAnalysisDetails = (examId) => {
    _retrieveData(examId);
  }

  return (
    <section className='test-analylis'>
      <div className="rightPanel">
        <h3>
          Test Analysis
        </h3>
        <div className="col-sm-12 bgwhite boxshadow p-lg-5 p-1 mb-4">
          <div className='d-flex'>
            <h4 className='mb-3'>Test Analysis</h4>
          </div>
          <div className="row">
            <div className='col-md-12'>
              <ul class="nav nav-pills mb-1" id="pills-tab" role="tablist">
                <li class="nav-item me-1" role="presentation">
                  <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true" onClick={getExamType}>All</button>
                </li>
                <li class="nav-item mx-1" role="presentation">
                  <button class="nav-link" id="pills-dpp-tab" data-bs-toggle="pill" data-bs-target="#pills-dpp" type="button" role="tab" aria-controls="pills-dpp" aria-selected="false" onClick={getExamType}>DPP</button>
                </li>
                <li class="nav-item mx-1" role="presentation">
                  <button class="nav-link" id="pills-test-series-tab" data-bs-toggle="pill" data-bs-target="#pills-test-series" type="button" role="tab" aria-controls="pills-test-series" aria-selected="false" onClick={getExamType}>Test Series</button>
                </li>
                <li class="nav-item mx-1" role="presentation">
                  <button class="nav-link" id="pills-pyq-test-tab" data-bs-toggle="pill" data-bs-target="#pills-pyq-test" type="button" role="tab" aria-controls="pills-pyq-test" aria-selected="false" onClick={changePYQ}>PYQ Test</button>
                </li>
                <li class="nav-item mx-1" role="presentation">
                  <button class="nav-link" id="pills-practice-test-tab" data-bs-toggle="pill" data-bs-target="#pills-practice-test" type="button" role="tab" aria-controls="pills-practice-test" aria-selected="false" onClick={changePYQ} >Practice Test</button>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
              {/* All Tab Start */}
              <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0">
                <div className='row'>
                  <div className='col-md-6'>
                    <div className="bgwhite boxshadow rounded mb-5 py-2 px-3">
                      <h5 className='mb-2'>Overall Result</h5>
                      {currentUserData.length > 0 ? (
                        currentUserData.map((item, indexMain) => {
                          return (
                            <OverAllResult key={indexMain}
                              noOfTest={item?.no_Of_Test}
                              AvgPercentile={item?.no_Of_Test}
                              overAllRank={item?.Rank}
                            />
                          )
                        })) : (
                        <OverAllResult
                          noOfTest="00"
                          AvgPercentile="00"
                          overAllRank="00"
                        />
                      )}
                    </div>
                    <div className='analysis-table-holder'>
                      {userRank.length > 0 ?
                        <div style={{ overflowX: "auto" }}>
                          <table class="table table-borderless">
                            <thead>
                              <tr className='border-bottom'>
                                <th scope="col">Rank</th>
                                <th scope="col">Name</th>
                                <th scope="col">No of Tests</th>
                                <th scope="col">Avg Percentile</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userRank.slice(0, limit ? limit : userRank.length).map((item, indexMain) => {
                                return (
                                  <tr key={indexMain}>
                                    <td>{item.Rank}</td>
                                    <td>{item?.profilePicture ?
                                      <img src={item?.profilePicture} alt="" className='me-2' />
                                      :
                                      <img src={LocalImages.user} alt="" className='me-2' />
                                    }
                                      {item?.firstName} {item?.lastName}
                                    </td>
                                    <td>{item?.no_Of_Test}</td>
                                    <td>{item?.percentileAvg.toFixed(2)}%</td>
                                  </tr>
                                )
                              })}
                              {currentUserData[0]?.Rank > 10 ? (currentUserData.map((item, indexMain) => {
                                return (
                                  <tr className='border border-3'>
                                    <td>{item.Rank}</td>
                                    <td>{item?.profilePicture ?
                                      <img src={item?.profilePicture} alt="" className='me-2' />
                                      :
                                      <img src={LocalImages.user} alt="" className='me-2' />
                                    }
                                      {item?.firstName} {item?.lastName}
                                    </td>
                                    <td>{item?.no_Of_Test}</td>
                                    <td>{item?.percentileAvg.toFixed(2)}%</td>
                                  </tr>
                                )
                              })) : ""}
                            </tbody>
                          </table>
                        </div>
                        :
                        <NoRecord />
                      }
                    </div>
                  </div>
                  <div className='col-md-6 px-lg-5 px-2'>
                    <div className="bgwhite boxshadow rounded mb-5 p-4">
                      <div className='d-flex'>
                        <h4 className='mb-3'>Test Progress</h4>
                      </div>
                      {currentUserData.length > 0 ? (currentUserData.map((item, indexMain) => {
                        return (
                          <div key={indexMain}>
                            {
                              item?.examStats.map((tp, index) => {
                                return (
                                  <div className="progress-card bgwhite mb-3 cursor" key={index} onClick={() => { testAnalysisDetails(tp.examId) }}>
                                    <h5 className='my-2'>Test I: {tp?.submittedOn ? moment(tp.submittedOn).format('DD MMM YYYY') : ''}</h5>
                                    <div className='d-flex justify-content-between text-center'>
                                      <p>{tp?.marksObtained} <span className='d-block'>Mark</span></p>
                                      <p>{getTimeTakenToStringFormat(tp?.totalTimeSpent)} <span className='d-block'>Time Taken</span></p>
                                      <p>{tp?.percentile.toFixed(2)}% <span className='d-block'>Percentile</span></p>
                                      <p>{tp?.rank}<sup>th</sup><span className='d-block'>Rank</span></p>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      })) : (
                        <NoRecord />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* All Tab End */}
              {/* DPP Tab Start */}
              <div class="tab-pane fade" id="pills-dpp" role="tabpanel" aria-labelledby="pills-dpp-tab" tabindex="0">
                <div className='row'>
                  <div className='col-md-6'>
                    <div className="bgwhite boxshadow rounded mb-5 py-2 px-3">
                      <h5 className='mb-2'>Overall Result</h5>
                      {currentUserData.length > 0 ? (
                        currentUserData.map((item, indexMain) => {
                          return (
                            <OverAllResult key={indexMain}
                              noOfTest={item?.no_Of_Test}
                              AvgPercentile={item?.no_Of_Test}
                              overAllRank={item?.Rank}
                            />
                          )
                        })) : (
                        <OverAllResult
                          noOfTest="00"
                          AvgPercentile="00"
                          overAllRank="00"
                        />
                      )}
                    </div>
                    <div className='analysis-table-holder'>
                      {userRank.length > 0 ?
                        <div style={{ overflowX: "auto" }}>
                          <table class="table table-borderless">
                            <thead>
                              <tr className='border-bottom'>
                                <th scope="col">Rank</th>
                                <th scope="col">Name</th>
                                <th scope="col">No of Tests</th>
                                <th scope="col">Avg Percentile</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userRank.slice(0, limit ? limit : userRank.length).map((item, indexMain) => {
                                return (
                                  <tr key={indexMain}>
                                    <td>{item.Rank}</td>
                                    <td>{item?.profilePicture ?
                                      <img src={item?.profilePicture} alt="" className='me-2' />
                                      :
                                      <img src={LocalImages.user} alt="" className='me-2' />
                                    }
                                      {item?.firstName} {item?.lastName}
                                    </td>
                                    <td>{item?.no_Of_Test}</td>
                                    <td>{item?.percentileAvg.toFixed(2)}%</td>
                                  </tr>
                                )
                              })}
                              {currentUserData[0]?.Rank > 10 ? (currentUserData.map((item, indexMain) => {
                                return (
                                  <tr className='border border-3'>
                                    <td>{item.Rank}</td>
                                    <td>{item?.profilePicture ?
                                      <img src={item?.profilePicture} alt="" className='me-2' />
                                      :
                                      <img src={LocalImages.user} alt="" className='me-2' />
                                    }
                                      {item?.firstName} {item?.lastName}
                                    </td>
                                    <td>{item?.no_Of_Test}</td>
                                    <td>{item?.percentileAvg.toFixed(2)}%</td>
                                  </tr>
                                )
                              })) : ""}
                            </tbody>
                          </table>
                        </div>
                        :
                        <NoRecord />
                      }
                    </div>
                  </div>
                  <div className='col-md-6 px-lg-5 px-2'>
                    <div className="bgwhite boxshadow rounded mb-5 p-4">
                      <div className='d-flex'>
                        <h4 className='mb-3'>Test Progress</h4>
                      </div>
                      {currentUserData.length > 0 ? (currentUserData.map((item, indexMain) => {
                        return (
                          <div key={indexMain}>
                            {
                              item?.examStats.map((tp, index) => {
                                return (
                                  <div className="progress-card bgwhite mb-3 cursor" key={index} onClick={() => { testAnalysisDetails(tp.examId) }}>
                                    <h5 className='my-2'>Test I: {tp?.submittedOn ? moment(tp.submittedOn).format('DD MMM YYYY') : ''}</h5>
                                    <div className='d-flex justify-content-between text-center'>
                                      <p>{tp?.marksObtained} <span className='d-block'>Mark</span></p>
                                      <p>{getTimeTakenToStringFormat(tp?.totalTimeSpent)} <span className='d-block'>Time Taken</span></p>
                                      <p>{tp?.percentile.toFixed(2)}% <span className='d-block'>Percentile</span></p>
                                      <p>{tp?.rank}<sup>th</sup><span className='d-block'>Rank</span></p>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      })) : (
                        <NoRecord />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* DPP Tab End */}

              {/* Test Series Tab Start */}
              <div class="tab-pane fade" id="pills-test-series" role="tabpanel" aria-labelledby="pills-test-series-tab" tabindex="0">
                <div className='row'>
                  <div className='col-md-6'>
                    <div className="bgwhite boxshadow rounded mb-5 py-2 px-3">
                      <h5 className='mb-2'>Overall Result</h5>
                      {currentUserData.length > 0 ? (
                        currentUserData.map((item, indexMain) => {
                          return (
                            <OverAllResult key={indexMain}
                              noOfTest={item?.no_Of_Test}
                              AvgPercentile={item?.no_Of_Test}
                              overAllRank={item?.Rank}
                            />
                          )
                        })) : (
                        <OverAllResult
                          noOfTest="00"
                          AvgPercentile="00"
                          overAllRank="00"
                        />
                      )}
                    </div>
                    <div className='analysis-table-holder'>
                      {userRank.length > 0 ?
                        <div style={{ overflowX: "auto" }}>
                          <table class="table table-borderless">
                            <thead>
                              <tr className='border-bottom'>
                                <th scope="col">Rank</th>
                                <th scope="col">Name</th>
                                <th scope="col">No of Tests</th>
                                <th scope="col">Avg Percentile</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userRank.slice(0, limit ? limit : userRank.length).map((item, indexMain) => {
                                return (
                                  <tr key={indexMain}>
                                    <td>{item.Rank}</td>
                                    <td>{item?.profilePicture ?
                                      <img src={item?.profilePicture} alt="" className='me-2' />
                                      :
                                      <img src={LocalImages.user} alt="" className='me-2' />
                                    }
                                      {item?.firstName} {item?.lastName}
                                    </td>
                                    <td>{item?.no_Of_Test}</td>
                                    <td>{item?.percentileAvg.toFixed(2)}%</td>
                                  </tr>
                                )
                              })}
                              {currentUserData[0]?.Rank > 10 ? (currentUserData.map((item, indexMain) => {
                                return (
                                  <tr className='border border-3'>
                                    <td>{item.Rank}</td>
                                    <td>{item?.profilePicture ?
                                      <img src={item?.profilePicture} alt="" className='me-2' />
                                      :
                                      <img src={LocalImages.user} alt="" className='me-2' />
                                    }
                                      {item?.firstName} {item?.lastName}
                                    </td>
                                    <td>{item?.no_Of_Test}</td>
                                    <td>{item?.percentileAvg.toFixed(2)}%</td>
                                  </tr>
                                )
                              })) : ""}
                            </tbody>
                          </table>
                        </div>
                        :
                        <NoRecord />
                      }
                    </div>
                  </div>
                  <div className='col-md-6 px-lg-5 px-2'>
                    <div className="bgwhite boxshadow rounded mb-5 p-4">
                      <div className='d-flex'>
                        <h4 className='mb-3'>Test Progress</h4>
                      </div>
                      {currentUserData.length > 0 ? (currentUserData.map((item, indexMain) => {
                        return (
                          <div key={indexMain}>
                            {
                              item?.examStats.map((tp, index) => {
                                return (
                                  <div className="progress-card bgwhite mb-3 cursor" key={index} onClick={() => { testAnalysisDetails(tp.examId) }}>
                                    <h5 className='my-2'>Test I: {tp?.submittedOn ? moment(tp.submittedOn).format('DD MMM YYYY') : ''}</h5>
                                    <div className='d-flex justify-content-between text-center'>
                                      <p>{tp?.marksObtained} <span className='d-block'>Mark</span></p>
                                      <p>{getTimeTakenToStringFormat(tp?.totalTimeSpent)} <span className='d-block'>Time Taken</span></p>
                                      <p>{tp?.percentile.toFixed(2)}% <span className='d-block'>Percentile</span></p>
                                      <p>{tp?.rank}<sup>th</sup><span className='d-block'>Rank</span></p>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      })) : (
                        <NoRecord />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Test Series Tab End */}
              {/* PYQ Test Start */}
              <div class="tab-pane fade" id="pills-pyq-test" role="tabpanel" aria-labelledby="pills-pyq-test-tab" tabindex="0">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item me-1" role="presentation">
                    <button class="nav-link active" id="pyq-unit" data-bs-toggle="pill" data-bs-target="#pills-unit" type="button" role="tab" aria-controls="pills-unit" aria-selected="true" onClick={getExamType}>Unit Test</button>
                  </li>
                  <li class="nav-item mx-1" role="presentation">
                    <button class="nav-link" id="pyq-topic" data-bs-toggle="pill" data-bs-target="#pills-topic" type="button" role="tab" aria-controls="pills-topic" aria-selected="false" onClick={getExamType}>Topic Test</button>
                  </li>
                  <li class="nav-item mx-1" role="presentation">
                    <button class="nav-link" id="pyq-mock" data-bs-toggle="pill" data-bs-target="#pills-mock" type="button" role="tab" aria-controls="pills-mock" aria-selected="false" onClick={getExamType}>Mock Test</button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-unit" role="tabpanel" aria-labelledby="pills-unit" tabindex="0">
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="bgwhite boxshadow rounded mb-5 py-2 px-3">
                          <h5 className='mb-2'>Overall Result</h5>
                          {currentUserData.length > 0 ? (
                            currentUserData.map((item, indexMain) => {
                              return (
                                <OverAllResult key={indexMain}
                                  noOfTest={item?.no_Of_Test}
                                  AvgPercentile={item?.no_Of_Test}
                                  overAllRank={item?.Rank}
                                />
                              )
                            })) : (
                            <OverAllResult
                              noOfTest="00"
                              AvgPercentile="00"
                              overAllRank="00"
                            />
                          )}
                        </div>
                        <div className='analysis-table-holder'>
                          {userRank.length > 0 ?
                            <div className='table-responsive'>
                              <table class="table table-borderless">
                                <thead>
                                  <tr className='border-bottom'>
                                    <th scope="col">Rank</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">No of Tests</th>
                                    <th scope="col">Avg Percentile</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userRank.slice(0, limit ? limit : userRank.length).map((item, indexMain) => {
                                    return (
                                      <tr key={indexMain}>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })}
                                  {currentUserData[0]?.Rank > 10 ? (currentUserData.map((item, indexMain) => {
                                    return (
                                      <tr className='border border-3'>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })) : ''}
                                </tbody>
                              </table>
                            </div>
                            :
                            <NoRecord />
                          }
                        </div>
                      </div>
                      <div className='col-md-6 px-lg-5 px-2'>
                        <div className="bgwhite boxshadow rounded mb-5 p-4">
                          <div className='d-flex'>
                            <h4 className='mb-3'>Test Progress</h4>
                          </div>
                          {currentUserData.length > 0 ? (currentUserData.map((item, indexMain) => {
                            return (
                              <div key={indexMain}>
                                {
                                  item?.examStats.map((tp, index) => {
                                    return (
                                      <div className="progress-card bgwhite mb-3 cursor" key={index} onClick={() => { testAnalysisDetails(tp.examId) }}>
                                        <h5 className='my-2'>Test I: {tp?.submittedOn ? moment(tp.submittedOn).format('DD MMM YYYY') : ''}</h5>
                                        <div className='d-flex justify-content-between text-center'>
                                          <p>{tp?.marksObtained} <span className='d-block'>Mark</span></p>
                                          <p>{getTimeTakenToStringFormat(tp?.totalTimeSpent)} <span className='d-block'>Time Taken</span></p>
                                          <p>{tp?.percentile.toFixed(2)}% <span className='d-block'>Percentile</span></p>
                                          <p>{tp?.rank}<sup>th</sup><span className='d-block'>Rank</span></p>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          })) : (
                            <NoRecord />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="pills-topic" role="tabpanel" aria-labelledby="pills-topic" tabindex="0">
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="bgwhite boxshadow rounded mb-5 py-2 px-3">
                          <h5 className='mb-2'>Overall Result</h5>
                          {currentUserData.length > 0 ? (
                            currentUserData.map((item, indexMain) => {
                              return (
                                <OverAllResult key={indexMain}
                                  noOfTest={item?.no_Of_Test}
                                  AvgPercentile={item?.no_Of_Test}
                                  overAllRank={item?.Rank}
                                />
                              )
                            })) : (
                            <OverAllResult
                              noOfTest="00"
                              AvgPercentile="00"
                              overAllRank="00"
                            />
                          )}
                        </div>
                        <div className='analysis-table-holder'>
                          {userRank.length > 0 ?
                            <div style={{ overflowX: "auto" }}>
                              <table class="table table-borderless">
                                <thead>
                                  <tr className='border-bottom'>
                                    <th scope="col">Rank</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">No of Tests</th>
                                    <th scope="col">Avg Percentile</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userRank.slice(0, limit ? limit : userRank.length).map((item, indexMain) => {
                                    return (
                                      <tr key={indexMain}>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })}
                                  {currentUserData[0]?.Rank > 10 ? (currentUserData.map((item, indexMain) => {
                                    return (
                                      <tr className='border border-3'>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })) : ""}
                                </tbody>
                              </table>
                            </div>
                            :
                            <NoRecord />
                          }
                        </div>
                      </div>
                      <div className='col-md-6 px-lg-5 px-2'>
                        <div className="bgwhite boxshadow rounded mb-5 p-4">
                          <div className='d-flex'>
                            <h4 className='mb-3'>Test Progress</h4>
                          </div>
                          {currentUserData.length > 0 ? (currentUserData.map((item, indexMain) => {
                            return (
                              <div key={indexMain}>
                                {
                                  item?.examStats.map((tp, index) => {
                                    return (
                                      <div className="progress-card bgwhite mb-3 cursor" key={index} onClick={() => { testAnalysisDetails(tp.examId) }}>
                                        <h5 className='my-2'>Test I: {tp?.submittedOn ? moment(tp.submittedOn).format('DD MMM YYYY') : ''}</h5>
                                        <div className='d-flex justify-content-between text-center'>
                                          <p>{tp?.marksObtained} <span className='d-block'>Mark</span></p>
                                          <p>{getTimeTakenToStringFormat(tp?.totalTimeSpent)} <span className='d-block'>Time Taken</span></p>
                                          <p>{tp?.percentile.toFixed(2)}% <span className='d-block'>Percentile</span></p>
                                          <p>{tp?.rank}<sup>th</sup><span className='d-block'>Rank</span></p>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          })) : (
                            <NoRecord />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="pills-mock" role="tabpanel" aria-labelledby="pills-mock" tabindex="0">
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="bgwhite boxshadow rounded mb-5 py-2 px-3">
                          <h5 className='mb-2'>Overall Result</h5>
                          {currentUserData.length > 0 ? (
                            currentUserData.map((item, indexMain) => {
                              return (
                                <OverAllResult key={indexMain}
                                  noOfTest={item?.no_Of_Test}
                                  AvgPercentile={item?.no_Of_Test}
                                  overAllRank={item?.Rank}
                                />
                              )
                            })) : (
                            <OverAllResult
                              noOfTest="00"
                              AvgPercentile="00"
                              overAllRank="00"
                            />
                          )}
                        </div>
                        <div className='analysis-table-holder'>
                          {userRank.length > 0 ?
                            <div style={{ overflowX: "auto" }}>
                              <table class="table table-borderless">
                                <thead>
                                  <tr className='border-bottom'>
                                    <th scope="col">Rank</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">No of Tests</th>
                                    <th scope="col">Avg Percentile</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userRank.slice(0, limit ? limit : userRank.length).map((item, indexMain) => {
                                    return (
                                      <tr key={indexMain}>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })}
                                  {currentUserData[0]?.Rank > 10 ? (currentUserData.map((item, indexMain) => {
                                    return (
                                      <tr className='border border-3'>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })) : ""}
                                </tbody>
                              </table>
                            </div>
                            :
                            <NoRecord />
                          }
                        </div>
                      </div>
                      <div className='col-md-6 px-lg-5 px-2'>
                        <div className="bgwhite boxshadow rounded mb-5 p-4">
                          <div className='d-flex'>
                            <h4 className='mb-3'>Test Progress</h4>
                          </div>
                          {currentUserData.length > 0 ? (currentUserData.map((item, indexMain) => {
                            return (
                              <div key={indexMain}>
                                {
                                  item?.examStats.map((tp, index) => {
                                    return (
                                      <div className="progress-card bgwhite mb-3 cursor" key={index} onClick={() => { testAnalysisDetails(tp.examId) }}>
                                        <h5 className='my-2'>Test I: {tp?.submittedOn ? moment(tp.submittedOn).format('DD MMM YYYY') : ''}</h5>
                                        <div className='d-flex justify-content-between text-center'>
                                          <p>{tp?.marksObtained} <span className='d-block'>Mark</span></p>
                                          <p>{getTimeTakenToStringFormat(tp?.totalTimeSpent)} <span className='d-block'>Time Taken</span></p>
                                          <p>{tp?.percentile.toFixed(2)}% <span className='d-block'>Percentile</span></p>
                                          <p>{tp?.rank}<sup>th</sup><span className='d-block'>Rank</span></p>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          })) : (
                            <NoRecord />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PYQ Test End */}
              {/* Practice Test Start */}
              <div class="tab-pane fade" id="pills-practice-test" role="tabpanel" aria-labelledby="pills-practice-test-tab" tabindex="0">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item me-1" role="presentation">
                    <button class="nav-link active" id="practice-unit" data-bs-toggle="pill" data-bs-target="#pills-practice-unit" type="button" role="tab" aria-controls="pills-practice-unit" aria-selected="true" onClick={getExamType}>Unit Test</button>
                  </li>
                  <li class="nav-item mx-1" role="presentation">
                    <button class="nav-link" id="practice-topic" data-bs-toggle="pill" data-bs-target="#pills-practice-topic" type="button" role="tab" aria-controls="pills-practice-topic" aria-selected="false" onClick={getExamType}>Topic Test</button>
                  </li>
                  <li class="nav-item mx-1" role="presentation">
                    <button class="nav-link" id="practice-mock" data-bs-toggle="pill" data-bs-target="#pills-practice-mock" type="button" role="tab" aria-controls="pills-practice-mock" aria-selected="false" onClick={getExamType}>Mock Test</button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-practice-unit" role="tabpanel" aria-labelledby="pills-practice-unit" tabindex="0">
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="bgwhite boxshadow rounded mb-5 py-2 px-3">
                          <h5 className='mb-2'>Overall Result</h5>
                          {currentUserData.length > 0 ? (
                            currentUserData.map((item, indexMain) => {
                              return (
                                <OverAllResult key={indexMain}
                                  noOfTest={item?.no_Of_Test}
                                  AvgPercentile={item?.no_Of_Test}
                                  overAllRank={item?.Rank}
                                />
                              )
                            })) : (
                            <OverAllResult
                              noOfTest="00"
                              AvgPercentile="00"
                              overAllRank="00"
                            />
                          )}
                        </div>
                        <div className='analysis-table-holder'>
                          {userRank.length > 0 ?
                            <div style={{ overflowX: "auto" }}>
                              <table class="table table-borderless">
                                <thead>
                                  <tr className='border-bottom'>
                                    <th scope="col">Rank</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">No of Tests</th>
                                    <th scope="col">Avg Percentile</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userRank.slice(0, limit ? limit : userRank.length).map((item, indexMain) => {
                                    return (
                                      <tr key={indexMain}>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })}
                                  {currentUserData[0]?.Rank > 10 ? (currentUserData.map((item, indexMain) => {
                                    return (
                                      <tr className='border border-3'>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })) : ""}
                                </tbody>
                              </table>
                            </div>
                            :
                            <NoRecord />
                          }
                        </div>
                      </div>
                      <div className='col-md-6 px-lg-5 px-2'>
                        <div className="bgwhite boxshadow rounded mb-5 p-4">
                          <div className='d-flex'>
                            <h4 className='mb-3'>Test Progress</h4>
                          </div>
                          {currentUserData.length > 0 ? (currentUserData.map((item, indexMain) => {
                            return (
                              <div key={indexMain}>
                                {
                                  item?.examStats.map((tp, index) => {
                                    return (
                                      <div className="progress-card bgwhite mb-3 cursor" key={index} onClick={() => { testAnalysisDetails(tp.examId) }}>
                                        <h5 className='my-2'>Test I: {tp?.submittedOn ? moment(tp.submittedOn).format('DD MMM YYYY') : ''}</h5>
                                        <div className='d-flex justify-content-between text-center'>
                                          <p>{tp?.marksObtained} <span className='d-block'>Mark</span></p>
                                          <p>{getTimeTakenToStringFormat(tp?.totalTimeSpent)} <span className='d-block'>Time Taken</span></p>
                                          <p>{tp?.percentile.toFixed(2)}% <span className='d-block'>Percentile</span></p>
                                          <p>{tp?.rank}<sup>th</sup><span className='d-block'>Rank</span></p>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          })) : (
                            <NoRecord />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="pills-practice-topic" role="tabpanel" aria-labelledby="pills-practice-topic" tabindex="0">
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="bgwhite boxshadow rounded mb-5 py-2 px-3">
                          <h5 className='mb-2'>Overall Result</h5>
                          {currentUserData.length > 0 ? (
                            currentUserData.map((item, indexMain) => {
                              return (
                                <OverAllResult key={indexMain}
                                  noOfTest={item?.no_Of_Test}
                                  AvgPercentile={item?.no_Of_Test}
                                  overAllRank={item?.Rank}
                                />
                              )
                            })) : (
                            <OverAllResult
                              noOfTest="00"
                              AvgPercentile="00"
                              overAllRank="00"
                            />
                          )}
                        </div>
                        <div className='analysis-table-holder'>
                          {userRank.length > 0 ?
                            <div style={{ overflowX: "auto" }}>
                              <table class="table table-borderless">
                                <thead>
                                  <tr className='border-bottom'>
                                    <th scope="col">Rank</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">No of Tests</th>
                                    <th scope="col">Avg Percentile</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userRank.slice(0, limit ? limit : userRank.length).map((item, indexMain) => {
                                    return (
                                      <tr key={indexMain}>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })}
                                  {currentUserData[0]?.Rank > 10 ? (currentUserData.map((item, indexMain) => {
                                    return (
                                      <tr className='border border-3'>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })) : ""}
                                </tbody>
                              </table>
                            </div>
                            :
                            <NoRecord />
                          }
                        </div>
                      </div>
                      <div className='col-md-6 px-lg-5 px-2'>
                        <div className="bgwhite boxshadow rounded mb-5 p-4">
                          <div className='d-flex'>
                            <h4 className='mb-3'>Test Progress</h4>
                          </div>
                          {currentUserData.length > 0 ? (currentUserData.map((item, indexMain) => {
                            return (
                              <div key={indexMain}>
                                {
                                  item?.examStats.map((tp, index) => {
                                    return (
                                      <div className="progress-card bgwhite mb-3 cursor" key={index} onClick={() => { testAnalysisDetails(tp.examId) }}>
                                        <h5 className='my-2'>Test I: {tp?.submittedOn ? moment(tp.submittedOn).format('DD MMM YYYY') : ''}</h5>
                                        <div className='d-flex justify-content-between text-center'>
                                          <p>{tp?.marksObtained} <span className='d-block'>Mark</span></p>
                                          <p>{getTimeTakenToStringFormat(tp?.totalTimeSpent)} <span className='d-block'>Time Taken</span></p>
                                          <p>{tp?.percentile.toFixed(2)}% <span className='d-block'>Percentile</span></p>
                                          <p>{tp?.rank}<sup>th</sup><span className='d-block'>Rank</span></p>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          })) : (
                            <NoRecord />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="pills-practice-mock" role="tabpanel" aria-labelledby="pills-practice-mock" tabindex="0">
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="bgwhite boxshadow rounded mb-5 py-2 px-3">
                          <h5 className='mb-2'>Overall Result</h5>
                          {currentUserData.length > 0 ? (
                            currentUserData.map((item, indexMain) => {
                              return (
                                <OverAllResult key={indexMain}
                                  noOfTest={item?.no_Of_Test}
                                  AvgPercentile={item?.no_Of_Test}
                                  overAllRank={item?.Rank}
                                />
                              )
                            })) : (
                            <OverAllResult
                              noOfTest="00"
                              AvgPercentile="00"
                              overAllRank="00"
                            />
                          )}
                        </div>
                        <div className='analysis-table-holder'>
                          {userRank.length > 0 ?
                            <div style={{ overflowX: "auto" }}>
                              <table class="table table-borderless">
                                <thead>
                                  <tr className='border-bottom'>
                                    <th scope="col">Rank</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">No of Tests</th>
                                    <th scope="col">Avg Percentile</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userRank.slice(0, limit ? limit : userRank.length).map((item, indexMain) => {
                                    return (
                                      <tr key={indexMain}>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })}
                                  {currentUserData[0]?.Rank > 10 ? (currentUserData.map((item, indexMain) => {
                                    return (
                                      <tr className='border border-3'>
                                        <td>{item.Rank}</td>
                                        <td>{item?.profilePicture ?
                                          <img src={item?.profilePicture} alt="" className='me-2' />
                                          :
                                          <img src={LocalImages.user} alt="" className='me-2' />
                                        }
                                          {item?.firstName} {item?.lastName}
                                        </td>
                                        <td>{item?.no_Of_Test}</td>
                                        <td>{item?.percentileAvg.toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })) : ''}
                                </tbody>
                              </table>
                            </div>
                            :
                            <NoRecord />
                          }
                        </div>
                      </div>
                      <div className='col-md-6 px-lg-5 px-2'>
                        <div className="bgwhite boxshadow rounded mb-5 p-4">
                          <div className='d-flex'>
                            <h4 className='mb-3'>Test Progress</h4>
                          </div>
                          {currentUserData.length > 0 ? (currentUserData.map((item, indexMain) => {
                            return (
                              <div key={indexMain}>
                                {
                                  item?.examStats.map((tp, index) => {
                                    return (
                                      <div className="progress-card bgwhite mb-3 cursor" key={index} onClick={() => { testAnalysisDetails(tp.examId) }}>
                                        <h5 className='my-2'>Test I: {tp?.submittedOn ? moment(tp.submittedOn).format('DD MMM YYYY') : ''}</h5>
                                        <div className='d-flex justify-content-between text-center'>
                                          <p>{tp?.marksObtained} <span className='d-block'>Mark</span></p>
                                          <p>{getTimeTakenToStringFormat(tp?.totalTimeSpent)} <span className='d-block'>Time Taken</span></p>
                                          <p>{tp?.percentile.toFixed(2)}% <span className='d-block'>Percentile</span></p>
                                          <p>{tp?.rank}<sup>th</sup><span className='d-block'>Rank</span></p>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          })) : (
                            <NoRecord />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Practice Test End */}
            </div>
          </div>
        </div>
      </div>
    </section >
  );
}

export default TestAnalysis;
