import React from 'react';
import '../App.css';
import { LocalImages } from '../Constant';
function PyqPdfCard(props) {
  const { _id, pyq, batchName } = props?.data;
  return (
    <div>
      <div className="accordion" id={'accordionExample'}>
        <div className="accordion-item border mb-2" key={_id}>
          <h2 className="accordion-header" id={'headingOne' + _id}>
            <button
              className="accordion-button py-2 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={'#collapseOne' + _id}
              aria-expanded="true"
              aria-controls={'collapseOne' + _id}>
              <div>
                <div className="">{batchName}</div>
              </div>
            </button>
          </h2>
          <div
            id={'collapseOne' + _id}
            className="accordion-collapse collapse"
            aria-labelledby={'headingOne' + _id}
            data-bs-parent={'#accordionExample'}>
            <div className="accordion-body">
              {pyq?.map((e) => {
                return (
                  <a
                    className="boxshadow rounded p-3 d-flex justify-content-between px-4 mb-3 cursor"
                    href={e.pdf.file}
                    target="_blank"
                    rel="noreferrer">
                    <div className="colorblack d-flex align-items-center">{e.name}</div>
                    <img src={LocalImages.pdff} alt="pdf" style={{ width: '23px' }} />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PyqPdfCard;
