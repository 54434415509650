import React, { useEffect, useState } from 'react';
import '../App.css';
import SubjectTestDetails from '../Module/subjectTestDetails';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getExamTopic,
} from '../Redux/Actions/examActions';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import SubjectTestCard from '../Module/subjectTestCard';
import ScreenNames from '../Constant/ScreenNames';

function TopicListFree() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [topicList, setTopicList] = useState([]);
  const currentURLarr = useLocation().pathname.split('/');
  let link = currentURLarr[currentURLarr.length - 1];

  let selectedQuestionIndex = 0;
  const [loader, setLoading] = useState(false);
  console.log("location?.state", location?.state)
  const navigate = useNavigate();

  const {
    examTypeId,
    baseCourseId,
    notSyncedDataFromStore,
    examIdDataFromStoreObject,
    hasActivePaidPlan
  } = useSelector((state) => ({
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    notSyncedDataFromStore: state?.ExamSelectReducer?.notSyncedData,
    examIdDataFromStoreObject: state?.ExamSelectReducer?.examIdData,
    hasActivePaidPlan:
      link === 'OnlineTestFree' ? false : state?.AuthReducer?.hasActivePaidPlan || false
  }));
  const payLoad = location?.state ? location.state : '';
  console.log("payload",payLoad);
  useEffect(() => {
    dispatch(
      getExamTopic(
        payLoad,
        (res) => {
          setTopicList(res);
        },
        () => { }
      )
    );
  }, []);
  const onpressTopicExam = (_id) => {
    const postData = {
      baseCourseId,
      examTypeId,
      isFreeUser: payLoad.isFreeUser,
      isPYQ: payLoad.isPYQ,
      examType: payLoad.examType,
      topicId:_id,
      subjectId:payLoad.subjectId,
    };
    if (hasActivePaidPlan == true){
      navigate(`/${ScreenNames.PaidUser}/${ScreenNames.SubjectTest}`, {
        state: postData
      });
     } else {
      if (link === 'FreeTopicList') {
        navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.SubjectTest}`, {
          state: postData
        });
      } else {
        navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.SubjectTest}`, {
          state: postData
        });
      }
    }
};
return (
  <div className="rightPanel">
    <div>
      <h3>Topic List</h3>
    </div>
    <div className="mt-4 width75 p-1 p-sm-3 lectureheight">
      {topicList.map((c) => {
        return (
          <div className='col-md-6'>
            <SubjectTestCard
              key={c?._id}
              title={c?.name}
              lectcount={c?.count}
              onpress={() => onpressTopicExam(c._id)}
            />
          </div>
        );
      })}
    </div>
  </div>
);
}

export default TopicListFree;
