import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { setInitialState, updateMoreValues } from '../Redux/Actions/AuthAction';
import { store } from '../store';

const $http = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json' //,
  }
});

$http.interceptors.response.use(
  (config) => config,
  (error) => {
    if (
      error?.message?.includes('403') // Block
    ) {
      handleApiError();
    }
    return Promise.reject(error);
  }
);

const handleApiError = async () => {
  const { dispatch } = store;
  await dispatch(updateMoreValues({ ACCESS_TOKEN_NEW: '' }));
  setTimeout(() => {
    window.location.href = '/';
    return Promise.reject();
  }, 1000);
};

$http.interceptors.request.use(
  async (req) => {
    if (req?.headers) {
      const getState = await store?.getState();
      if (getState) {
        const authToken = store.getState()?.AuthReducer?.ACCESS_TOKEN_NEW;
        if (authToken && authToken.length > 0) {
          // $http.defaults.headers.common.Authorization = `Bearer ${authToken}`;
          req.headers.Authorization = `Bearer ${authToken}`;
        }
      }
    }
    return req;
  },
  (err) => {
    return err;
  }
);

const setAuthorizationToken = (token) => {
  if (token) {
    $http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

let status_code = {
  success: 200,
  successAction: 201,
  notFound: 204,
  badRequest: 400,
  Unauthorized: 401,
  invalid: 400,
  timeout: 408,
  userDelete: 410,
  serverError: 500
};

const postApiCall = (endPoint, params, successCallback, errorCallback) => {
  $http
    .post(endPoint, JSON.stringify(params))
    .then((response) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const postApiCallWithToken = (endPoint, params, successCallback, errorCallback) => {
  const items = allStorage()[2]
    ? JSON.parse(JSON.parse(allStorage()[2]).AuthReducer).ACCESS_TOKEN_NEW
    : '';
  let headers = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  if (items != '') headers.headers['Authorization'] = `Bearer ${items}`;

  $http
    .post(endPoint, JSON.stringify(params), headers)
    .then((response) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};
const postUploadApiCall = (endPoint, params, successCallback, errorCallback) => {
  const authToken = store.getState()?.AuthReducer?.ACCESS_TOKEN_NEW;
  $http
    .post(endPoint, params, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    })
    .then((response) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const putApiCall = (endPoint, params, successCallback, errorCallback) => {
  $http
    .put(endPoint, params)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const postUploadBooks = (endPoint, params, successCallback, errorCallback) => {
  $http
    .post(endPoint, JSON.stringify(params), {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then((response) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

function allStorage() {
  var values = [],
    keys = Object.keys(localStorage),
    i = keys.length;

  while (i--) {
    values.push(localStorage.getItem(keys[i]));
  }

  return values;
}

const getApiCall = (endPoint, paramsData, successCallback, errorCallback) => {
  $http
    .get(endPoint + paramsData, {})
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const getApiCallCountry = (endPoint, paramsData, successCallback, errorCallback) => {
  $http
    .get(endPoint + paramsData, { withCredentials: true })
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const getApiCallWithoutToken = (endPoint, paramsData, successCallback, errorCallback) => {
  $http
    .get(endPoint + paramsData)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const patchApiCall = (endPoint, params, successCallback, errorCallback) => {
  $http
    .patch(endPoint, params)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const deleteApiCall = (endPoint, paramsData, successCallback, errorCallback) => {
  $http
    .delete(endPoint + paramsData, {})
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

export default {
  postApiCall,
  postApiCallWithToken,
  postUploadApiCall,
  getApiCall,
  getApiCallWithoutToken,
  patchApiCall,
  putApiCall,
  deleteApiCall,
  handleApiError,
  setAuthorizationToken,
  status_code,
  $http,
  postUploadBooks,
  getApiCallCountry
};
