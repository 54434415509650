import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Lottie from 'react-lottie';
import emptyorder from '../Assets/Images/JSON/order history.json';
import { getOrderList, updateHomeValues } from '../Redux/Actions/homeAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import OrderHistoryBlank from '../Component/orderHistoryBlank';
function OrderHistory(props) {
  const { hasActivePaidPlan } = useSelector((state) => ({
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan
  }));
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyorder,
    renderer: 'svg'
  };
  const routeOrderHistory = (orderId) => {
    if (hasActivePaidPlan) navigate('/PaidUser/OrderDetails', { state: { orderId: orderId } });
    else navigate('/DashboardMenu/OrderDetails', { state: { orderId: orderId } });
  };
  const courseroute = () => {
    if (hasActivePaidPlan) {
      navigate('/PaidUser/OnlineCourse');
    } else {
      navigate('/DashboardMenu/OnlineCourse');
    }
  };
  const CourseDetails = () => {
    navigate('CourseDetails');
  };

  const [order, setOrder] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(updateHomeValues('isLoader', true));
    dispatch(
      getOrderList(
        (data) => {
          dispatch(updateHomeValues('isLoader', false));
          setOrder(data);
          setLoader(false);
        },
        () => {
          setLoader(false);
        }
      )
    );
  }, []);
  return (
    <div className="rightPanel">
      <div className="row">
         <div className="d-grid d-md-flex justify-content-end">
        <div className="col">
          <h3>Order History</h3>
        </div>
        {order?.length !== 0 ? 
          <button
            type="button"
            className="btn btn-primary py-2 px-4 font12 me-md-1"
            onClick={courseroute}>
            Browse Courses
          </button>:""}
        </div>
      </div>
      <div className="row w-80">
        {order?.length !== 0 ? (
          order?.map((c,index) => (
            <div key={index.toString()} className="col-md-6 ">
              {
                <div
                  className="card boxshadow p-3 mt-2 me-md-3 cursor border-0 mb-2"
                  onClick={() => {
                    routeOrderHistory(c?._id);
                  }}>
                  <div className="d-flex">
                    {c?.status === 2 ? (
                      <span className="successcolor">
                        <TaskAltIcon />
                      </span>
                    ) : (
                      <span>
                        <CancelOutlinedIcon style={{ color: 'red' }} />
                      </span>
                    )}
                    <div className="ms-2 ellipsis" style={{ lineHeight: 1.2}}>
                      {c?.courses?.map((item,index) => (
                        <span key={index.toString()} className="">
                          {/* <b className="font12">{item.courseId.name}</b> */}
                         <span className=''>{index>0?'\u002C ':''}{item.courseId.name}</span> 
                        </span>
                      ))}
                    </div>
                  </div>
                  <p className="font12 mb-0 mt-3 text-secondary">
                    {moment(c?.date).format('ddd  DD MMM YYYY')}
                  </p>
                  <p className="font12 mb-0 text-secondary">Order No: {c?.orderNo}</p>
                  <p className="font12 mb-0 text-secondary">Amount Paid: {c?.netPayableAmount}</p>
                  <p className="font12 mb-0 text-secondary">{c?.courses?.length} items</p>
                  <div className="d-flex flex-column align-items-end">
                    {/* {c?.status === 2 ?<button type="button" class="btn btn-primary py-1 font12 " onClick={CourseDetails}>Extend Subscription</button>:""} */}
                    {c?.status === 2 ? (
                      <button
                        type="button"
                        onClick={() => {
                          routeOrderHistory(c?._id);
                        }}
                        className="border-0 greentxt align-self-end">
                        Successful
                      </button>
                    ) : (
                      <button
                        type="button"
                        className=" border-0 redtxt align-self-end"
                        onClick={() => {
                          routeOrderHistory(c?._id);
                        }}>
                        Payment Failed
                      </button>
                    )}
                  </div>
                </div>
              }
            </div>
          ))
        ) : <OrderHistoryBlank courseroute={courseroute}/>
          
          //   <div className="col justify-content center shadow-sm pt-2 pb-5">
          //     <Lottie options={defaultOptions} height={130} width={200}></Lottie>
          //     <p className="text-center">
          //       <b>No orders Yet</b>
          //     </p>
          //     <div className='d-flex justify-content-center'>
          //     <button
          //   type="button"
          //   className="btn btn-primary py-2 px-4 font12 me-md-1"
          //   onClick={courseroute}>
          //   Browse Courses
          // </button>
          // </div>
          //   </div>
       
        }
      </div>
    </div>
  );
}

export default OrderHistory;
