import React, {useEffect, useState} from 'react';
function Timer(props) {
  const [timerClock, setTimerClock] = useState(props.countdown);
  const timer = timerClock < 10 ? `0${timerClock}` : timerClock;
  useEffect(() => {});

  const returnTimerValue = (time) => {
    const sec = Math.floor(time % 60);
    const min = Math.floor((time / 60) % 60);
    const hours = Math.floor(time / 60 / 60);
    const strSec = sec < 10 ? `0${sec}` : `${sec}`;
    const strMin = min < 10 ? `0${min}` : `${min}`;
    const strHour = hours < 10 ? `0${hours}` : `${hours}`;
    return `${strHour}:${strMin}:${strSec}`;
  };

  useEffect(() => {
    let clockIntervalId;
    if (timerClock > 0) {
      clockIntervalId = setInterval(() => {
        setTimerClock((prevValue) => prevValue - 1);
      }, 1000);
    }
    return () => clearInterval(clockIntervalId);
  }, [timerClock]);

  return <span>{returnTimerValue(timer)}</span>;
}

export default Timer;
