import React, { useEffect, useState } from 'react';
import '../App.css';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'; 
import orderCompleted from '../Assets/Images/JSON/ordercompleted.json'; 
import { useDispatch } from 'react-redux'; 
import { useSelector } from 'react-redux'; 
import { getFullScreen } from '../Utils/utils';
import { startEndLiveStatus } from '../Redux/Actions/homeAction';

function BbbVideo(props) {
  const [successpopup, setsuccesspopup] = useState(false);
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false)  

  const { liveVideoData,userId } = useSelector((state) => ({
    userId: state?.AuthReducer?._id,
    liveVideoData: state?.AuthReducer?.liveVideoData
  }));
 

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orderCompleted,
    renderer: 'svg'
  };

//   window.addEventListener('beforeunload', function (e) { 
//     e.returnValue = '';
//     dispatch(startEndLiveStatus({
//       "userId": userId,
//       "liveId": liveVideoData.liveId,
//       "liveType": liveVideoData.liveType,
//       "isLive": false,
//   })) 
//         opener.location.reload(); 
//         window.close();
// });

  const joinLive = () =>{ 
    //var strWindowFeatures = "menubar=no,location=no,resizable=no,scrollbars=no,status=yes";
    //setDisable(true)
   // getFullScreen();
   dispatch(startEndLiveStatus({
    "userId": userId,
    "liveId": liveVideoData.liveId,
    "liveType": liveVideoData.liveType,
    "isLive": true,
    "attendeeUrl": liveVideoData.attendeeUrl,
    "URL":liveVideoData?.URL
}))
    window.open(liveVideoData?.attendeeUrl, '_blank',"width="+screen.availWidth+",height="+screen.availHeight, "dialog=yes,resizable=no");
    
    
  }

  const endLive = () =>{ 
    
    dispatch(startEndLiveStatus({
      "userId": userId,
      "liveId": liveVideoData.liveId,
      "liveType": liveVideoData.liveType,
      "isLive": false,
      "attendeeUrl": liveVideoData.attendeeUrl,
  }))
  props.handleClose()
  
  }

  return (
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className="model"
    open={props.open}
   // onClose={props.handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}>
      <Fade in={props.open}>
        <div className="modeldiv p-4 py-5 talktoexpertModel">
          <div>
            {/* <div className="col mb-2 d-flex justify-content-between ">
              <h5 className="colorblue"></h5>
              <ClearOutlinedIcon className="colorblue cursor" onClick={props.handleClose} />
            </div> */}
            <div>
              <h4 className="ms-3 colorblue fw-bold text-center">BBB Live Video</h4>
              <p className="ms-3 mt-3 text-center">Click below button to join live</p>
            </div>
            <div className="d-flex justify-content-evenly mt-5">
                <button type="button" className="btn btn-primary px-5"  onClick={joinLive}>
                    Join
                </button>
                <button type="button" className="btn btn-primary px-5"  onClick={endLive}>
                    End Live
                </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default BbbVideo;
