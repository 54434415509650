import React, { useState, useEffect } from 'react';
import '../App.css';
import VideoLecture from '../Component/videoLecture';
import { useDispatch, useSelector } from 'react-redux';
import { getFreeLectureList } from '../Redux/Actions/homeAction';
import { useNavigate } from 'react-router-dom';
import NoRecord from '../Component/noRecord';
function Lectures() {
  const [lecture, setLecture] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { examTypeId, baseCourseId, examTypeName ,hasActivePaidPlan,childrenId } = useSelector((state) => ({
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    childrenId: state?.AuthReducer?.childrenId,
    examTypeName: state?.AuthReducer?.examTypeName,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan
  }));

  useEffect(() => {
    let data = {
      baseCourseId,
      examTypeId,
    }
    if(childrenId){
      data["subExamTypeId"]=childrenId;
    }
    dispatch(
      getFreeLectureList(
        data,
        (data) => {
          if (data[0]) setLecture(data[0]?.subjects);
          setLoader(false);
        },
        () => {}
      )
    );
  }, []);

  const onpressvideo = (item) => {
    // navigate(hasActivePaidPlan ? '/PaidUser/TeacherWiselectures' : '/DashboardMenu/TeacherWiselectures', { state: { item } });
    navigate(hasActivePaidPlan ? '/PaidUser/RecordedLectures' : '/DashboardMenu/RecordedLectures', { state: { topics:item?.topic,subjectId:item?.subjectId } });
  };
  return (
    <div className="rightPanel">
      <div>
        <h3 className="px-2">Lectures</h3>
      </div>
      <div className="mt-4 lectureheight p-2 width75">
        {lecture?.length !== 0 ? (
          lecture?.map((c, index) => {
            return (
              <VideoLecture
                key={index}
                title={c?.subjectId?.name}
                lectcount={c?.subjectVideosCount}
                onpress={() => {
                  onpressvideo(c);
                }}
              />
            );
          })
        ) : (
          <NoRecord />
        )}
      </div>
    </div>
  );
}

export default Lectures;
