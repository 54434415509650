import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { getFullScreen } from '../Utils/utils';

const ExamWarningDialog = ({ handleRedirect }) => {
  const [openWarning, setOpenWarning] = React.useState(false); // handles exam exit warning

  const handleReturnToExam = () => {
    setOpenWarning(false);
    getFullScreen();
  };

  const handleExitExam = (e) => {
    setOpenWarning(false);
    if (handleRedirect != null) handleRedirect(e);
  };

  React.useEffect(() => {
    // getFullScreen();
    
    const handleWindowResize = (e) => {
      if (window.innerHeight == window.screen.height) {
        // pass
        console.log('if working')
      } else {
        console.log('else working')
        setOpenWarning(true)
      }
    };
    setTimeout(()=>{
      window.addEventListener('resize', handleWindowResize);
      return function cleanup() {
        window.removeEventListener('resize', handleWindowResize);
      };
    },1000)
    
  }, []);

  return (
    <Dialog open={openWarning} aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Do you want to EXIT your test?
      </DialogTitle>

      <DialogActions>
        <Button autoFocus onClick={handleReturnToExam}>
          No
        </Button>
        <Button onClick={handleExitExam}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExamWarningDialog;
