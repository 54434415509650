export function disableCtrlKeyCombination(e) {
  //list all CTRL + key combinations you want to disable
  let forbiddenKeys = new Array('a', 'n', 'c', 'x', 'v', 'j', 'w', 's', 'j');
  let key;
  let isCtrl;

  if (window.event) {
    key = window.event.keyCode; //IE
    if (window.event.ctrlKey) isCtrl = true;
    else isCtrl = false;
  } else {
    key = e.which; //firefox
    if (e.ctrlKey) isCtrl = true;
    else isCtrl = false;
  }

  //if ctrl is pressed check if other key is in forbidenKeys array
  if (isCtrl) {
    for (let i = 0; i < forbiddenKeys.length; i++) {
      //case-insensitive comparation
      if (forbiddenKeys[i].toLowerCase() == String.fromCharCode(key).toLowerCase()) {
        return false;
      }
    }
  }
  return true;
}

let message = '';

export function clickIE() {
  if (document.all) {
    message = 'Right click not allowed';
    return false;
  }
}

export function clickNS(e) {
  if (document.layers || (document.getElementById && !document.all)) {
    if (e.which == 2 || e.which == 3) {
      message = 'Right click not allowed';
      return false;
    }
  }
}
