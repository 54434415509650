import React, { useState } from 'react';
import ProgressCircle from '../Component/progressCircle';
import ButtonPrimary from '../Component/buttonPrimary';
import lock from '../Assets/Images/Dashboard/lock.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getDateAndShortMonthStringFromDate } from '../Utils/utils';
import moment from 'moment';
import Timer from '../Component/timer';
import { getExamById } from '../Redux/Actions/examActions';
import { useDispatch } from 'react-redux';
import ScholershipTestAnalysis from './scholershipTestAnalysis';

function ScholarshipTestsCard(data1) {
  const [testanalysis, setTestanalysis] = useState('');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  let endDate = data1.data1?.contestEndDate
    ? moment.parseZone(data1.data1.contestEndDate).local(true).format('lll')
    : '';
  let startDate = data1.data1?.contestStartDate
    ? moment.parseZone(data1.data1.contestStartDate).local(true).format('lll')
    : '';
  startDate = getDateAndShortMonthStringFromDate(startDate);
  endDate = getDateAndShortMonthStringFromDate(endDate);
  const today = new Date();
  const difference = moment(data1.data1.contestStartDate, 'YYYY-MM-DD LT')
    .utc()
    .diff(moment(today, 'YYYY-MM-DD LT'), 'second');

  var contestStartDate = data1?.data1?.contestStartDate
    ? new Date(data1?.data1?.contestStartDate)?.toISOString()
    : '';
  const onClickTestAnalysis = (data) => {
    dispatch(
      getExamById(data?.data1?.examId, (res) => {
        setTestanalysis(res?.data);
        if (res?.data?.examData[0].resultDate) {
          if (
            moment(res?.data?.examData[0].resultDate, 'YYYY-MM-DD LT').utc() >
            moment(new Date(), 'YYYY-MM-DD LT')
          ) {
            setOpen(true);
          } else {
            sessionStorage.setItem('examId', data?.data1?.examId);
            sessionStorage.setItem('examData', JSON.stringify(res?.data?.examData[0]));
            window.open('/DetailResult', '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
          }
        } else {
          sessionStorage.setItem('examId', data?.data1?.examId);
          sessionStorage.setItem('examData', JSON.stringify(res?.data?.examData[0]));
          window.open('/DetailResult', '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
        }
      })
    );
  };

  // Printing the converted string.

  return (
    <>
      {data1.headtitle === 'Live' ? (
        <div className="boxshadow d-flex p-3 rounded mb-3 bgwhite">
          <div className="px-2 d-flex justify-content-between flex-fill">
            <div className="d-flex justify-content-between flex-column">
              <h6 className="text-uppercase">{data1.data1.name}</h6>
              {contestStartDate &&
              moment.utc(contestStartDate).format('YYYY-MM-DD LT') >
                moment(today).format('YYYY-MM-DD LT') ? (
                difference && difference > 0 ? (
                  <h6 className="errorcolor">Starting In: {<Timer countdown={difference} />}</h6>
                ) : (
                  <h6 className="errorcolor">
                    Already starts at:{' '}
                    {moment.utc(data1.data1.contestStartDate).format('Do MMM, LT')}
                  </h6>
                )
              ) : (
                <h6 className="errorcolor">
                  {/* Started On: {moment(data1.data1.contestStartDate).format('lll')} */}
                  Started On:{' '}
                  {moment.parseZone(data1.data1.contestStartDate).local(true).format('lll')}
                </h6>
              )}
              <div className="d-flex">
                <div className="me-3">
                  <p className="mb-1 colorgrey text-center">Questions</p>
                  <p className="colorblue text-center mb-0">{data1.data1.totalQuestions} Q</p>
                </div>
                <div className="mx-2">
                  <p className="mb-1 colorgrey text-center">Duration</p>
                  <p className="colorblue text-center mb-0 ">{data1.data1.duration} Min</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column">
              <div className="d-flex justify-content-center">
                {data1.data1.status == '1' && data1?.existInStore ? (
                  <span>
                    <ProgressCircle
                      strokeWidth={4}
                      percentage={data1.data1.percentage ? data1.data1.percentage : 0}
                      width={45}
                      height={45}
                    />
                  </span>
                ) : data1.data1.status == 2 ? (
                  <span className="successcolor checkcircle">
                    <CheckCircleIcon />
                  </span>
                ) : (
                  <img src={lock} alt="Join Now" />
                )}
              </div>
              <div className="mt-3">
                {data1.data1.status == 1 && data1?.existInStore ? (
                  <ButtonPrimary
                    disabled={
                      data1?.data1?.totalQuestions == null || data1?.data1?.totalQuestions == 0
                    }
                    gotoLink={() => data1.onStartExam(data1.examId)}
                    name="Resume"
                  />
                ) : data1.data1.status == 2 ? (
                  <button
                    type="button"
                    className="btn bg-green px-2 px-sm-4 py-1 font12"
                    onClick={() => onClickTestAnalysis(data1)}>
                    Test Analysis
                  </button>
                ) : (
                  <ButtonPrimary
                    name="Join Now"
                    disabled={
                      data1?.data1?.totalQuestions == null ||
                      data1?.data1?.totalQuestions == 0 ||
                      difference > 0
                    }
                    // disabled={
                    //   data1?.data1?.totalQuestions == null ||
                    //   data1?.data1?.totalQuestions == 0 ||
                    //   moment.utc(data1.data1.contestStartDate).format('YYYY-MM-DD') >
                    //     moment(today).format('YYYY-MM-DD') ||
                    //   difference > 0
                    // }
                    gotoLink={() => data1.onStartExam(data1.examId)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : data1.headtitle === 'Completed' ? (
        <div className="boxshadow d-flex p-3 rounded mb-3 bgwhite">
          <div className="px-2 d-flex justify-content-between flex-fill">
            <div className="d-flex justify-content-between flex-column">
              <h6 className="text-uppercase">{data1.data1.name}</h6>
              <h6 className="successcolor">Ended On: {endDate} </h6>
              <div className="d-flex">
                <div className="me-3">
                  <p className="mb-1 colorgrey text-center">Questions</p>
                  <p className="colorblue text-center mb-0">{data1.data1.totalQuestions} Q</p>
                </div>
                <div className="mx-2">
                  <p className="mb-1 colorgrey text-center">Duration</p>
                  <p className="colorblue text-center mb-0 ">{data1.data1.duration} Min</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column">
              <div className="d-flex justify-content-center">
                {data1.data1.status == 1 && data1?.existInStore ? (
                  <span>
                    <ProgressCircle
                      strokeWidth={4}
                      percentage={data1.data1.percentage ? data1.data1.percentage : 0}
                      width={45}
                      height={45}
                    />
                  </span>
                ) : data1.data1.status == 2 ? (
                  <span className="successcolor checkcircle">
                    <CheckCircleIcon />
                  </span>
                ) : (
                  <img src={lock} width={45} height={45} alt="Join Now" />
                )}
              </div>
              <div className="mt-3">
                {data1.data1.status == 1 && data1?.existInStore ? (
                  <ButtonPrimary
                    disabled={
                      data1?.data1?.totalQuestions == null || data1?.data1?.totalQuestions == 0
                    }
                    gotoLink={() => data1.onStartExam(data1.examId)}
                    name="Resume"
                  />
                ) : data1.data1.status == 2 ? (
                  <button
                    type="button"
                    className="btn bg-green px-2 px-sm-4 py-1 font12"
                    onClick={() => onClickTestAnalysis(data1)}>
                    Test Analysis
                  </button>
                ) : (
                  <ButtonPrimary
                    disabled={
                      data1?.data1?.totalQuestions == null || data1?.data1?.totalQuestions == 0
                    }
                    gotoLink={() => data1.onStartExam(data1.examId)}
                    name="Attempt"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="boxshadow d-flex p-3 rounded mb-3 bgwhite">
          <div className="px-2 d-flex justify-content-between flex-fill">
            <div className="d-flex justify-content-between flex-column">
              <h6 className="text-uppercase">{data1.data1.name}</h6>
              <h6 className="successcolor">
                Starts On: {moment.parseZone(contestStartDate).local(true).format('lll')}
              </h6>
              <div className="d-flex">
                <div className="me-3">
                  <p className="mb-1 colorgrey text-center">Questions</p>
                  <p className="colorblue text-center mb-0">{data1.data1.totalQuestions} Q</p>
                </div>
                <div className="mx-2">
                  <p className="mb-1 colorgrey text-center">Duration</p>
                  <p className="colorblue text-center mb-0">{data1.data1.duration} Min</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center flex-column">
              <div className="d-flex justify-content-center">
                <img src={lock} alt="Join Now" />
              </div>
              <div className="mt-3">
                {data1.data1.isReminder === false ? (
                  <ButtonPrimary
                    name="Remind Me"
                    gotoLink={() => data1.onStartExam(data1.examId, data1.data1.isReminder)}
                  />
                ) : (
                  // <ButtonPrimary
                  //   name="isReminder On"
                  //   gotoLink={() => data1.onStartExam(data1.examId, data1.data1.isReminder)}
                  // />
                  <button
                    type="button"
                    className="btn btn-secondary submitbtn rounded w-100 px-2 px-sm-4 py-1 font12 "
                    onClick={() => data1.onStartExam(data1.examId, data1.data1.isReminder)}
                    disabled={true}>
                    Reminder On
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <ScholershipTestAnalysis open={open} handleClose={handleClose} examData={testanalysis} />
    </>
  );
}

export default ScholarshipTestsCard;
