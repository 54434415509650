import React, { useState } from 'react';
import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useSelector, useDispatch } from 'react-redux';
import CouponCard from '../Module/couponCard';
import {
  getPaidCourseList,
  updateOrder,
  placeOrderInstallment,
  updateHomeMoreValues
} from '../Redux/Actions/homeAction';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import OrderPlacedSuccesfull from '../Module/orderPlacedSuccesfull';
import OrderPendingPopup from '../Module/orderPendingPopup';
import moment from 'moment/moment';

function InstallmentPopUp(props) {
  const { loader, firstName, lastName, email, mobileNo } = useSelector((state) => ({
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    email: state?.AuthReducer?.email,
    mobileNo: state?.AuthReducer?.mobileNo,
    loader: state?.HomeReducer?.loader
  }));

  const {
    taxAmount,
    couponDiscount,
    price,
    totalBeforeTax,
    taxableAmount,
    dueAmount,
    _id,
    dueDate,
    deactivateDate
  } = props?.data;
 
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { REACT_APP_RAZORPAY_API_KEY } = process.env;
  const [orderID, setOrderID] = useState('');
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderHolded, setOrderPending] = useState(false);

  const callPaymentGateway = (checkoutData) => {
    const { orderData, paymentOrderDataForCheckout } = checkoutData;
    const { amount, currency, id, status } = paymentOrderDataForCheckout;

    var options = {
      key: REACT_APP_RAZORPAY_API_KEY,
      amount: amount,
      currency: currency,
      name: `${firstName} ${lastName}`,
      description: 'IFAS Edutech Pvt. Ltd.',
      image: 'https://ifas-qa.s3.ap-south-1.amazonaws.com/icons/IFAS-New+Logo-Symbol.png',
      order_id: id,
      handler: function (response) {
        if (response) {
          dispatch(
            updateOrder(
              {
                orderId: orderData?.id,
                status: 'success',
                checkoutData: response
              },
              (res) => {
                if (res?.length == 0) {
                } else {
                  dispatch(getPaidCourseList());
                  setOrderID(orderData?.id);
                  setOrderSuccess(true);
                }
              }
            )
          );
        }
      },
      prefill: {
        name: `${firstName} ${lastName}`,
        email: email,
        contact: mobileNo
      },
      notes: {
        address: 'Razorpay Corporate Office'
      },
      theme: {
        color: '#3399cc'
      }
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handleClose = () => {
    setOrderPending(false);
  };

  const onPlaceorderClick = () => {
    props.handleClose();
    dispatch(
      placeOrderInstallment(_id, (res) => {
        if (res?.length == 0) {
        } else {
          callPaymentGateway(res);
        }
      })
    );
  };

  function padNumber(number) {
    var string = '' + number;
    string = string?.length < 2 ? '0' + string : string;
    return string;
  }

  const date = new Date();
  const next_date = new Date(date?.setDate(date?.getDate() + 30));
  const formatted =
    next_date?.getUTCFullYear() +
    '-' +
    padNumber(next_date?.getUTCMonth() + 1) +
    '-' +
    padNumber(next_date?.getUTCDate());
  const days = moment(dueDate).diff(moment(new Date()), 'days');

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="modeldiv p-5">
          <div className="col d-flex justify-content-between ">
            <p className="mb-1 fw-bold mt-1">
              Total Payable Amount:{' '}
              <span className="successcolor fw-bold ms-2">&#8377; {dueAmount}</span>
            </p>
            <ClearOutlinedIcon className="colorblue cursor" onClick={props.handleClose} />
          </div>
          <div className="mt-2 ms-3">
            <div className="px-2 py-1 border mt-2 w-100">
              <p className="mb-1 fw-bold">
                2nd Installment <span className="successcolor fw-bold ms-2">&#8377; {price}</span>
              </p>

              {/* subtText={days===0?`${i18n.t('payByToday')}`:days>0?`${i18n.t('payBy')}${moment(dueDate).fromNow(false)} `:`${i18n.t('dueDateWas')} ${moment.utc(dueDate).format(" Do MMM YYYY")}`} */}

              <p className="mb-0 font10">
                {days >= 0
                  ? `Pay Installment by ${moment.utc(dueDate).format(' Do MMM YYYY')}`
                  : `Due date was ${moment.utc(dueDate).format(' Do MMM YYYY')}`}
                ,
              </p>
              {deactivateDate ? null : (
                <p className="mb-0 font10 ">Otherwise your package will be deactivate.</p>
              )}
            </div>
          </div>
          <div className="mt-2 ms-3">
            <div className="border font12 p-3 rounded bgwhite">
              <p className="colorblue mb-2 fw-bold">Bill Details</p>
              {price ? (
                <p className="mb-0">
                  2nd Installment Price :{' '}
                  <span className="successcolor fw-bold ms-2">&#8377; {price}</span>
                </p>
              ) : null}
              {couponDiscount ? (
                <p className="mb-0">
                  Coupon Discount :{' '}
                  <span className="successcolor fw-bold ms-2">&#8377; {couponDiscount}</span>
                </p>
              ) : null}
              {totalBeforeTax ? (
                <p className="mb-0">
                  Total Before Tax :{' '}
                  <span className="successcolor fw-bold ms-2">&#8377; {totalBeforeTax}</span>
                </p>
              ) : null}
              {taxableAmount ? (
                <p className="mb-0">
                  Total Taxable Amount:{' '}
                  <span className="successcolor fw-bold ms-2">&#8377; {taxableAmount}</span>
                </p>
              ) : null}
              {taxAmount ? (
                <p className="mb-0">
                  Tax Amount(18%) :{' '}
                  <span className="successcolor fw-bold ms-2">&#8377; {taxAmount}</span>
                </p>
              ) : null}
              {dueAmount ? (
                <p className="mb-0">
                  Total Payable Amount(Including all Taxes) :{' '}
                  <span className="successcolor fw-bold ms-2">&#8377; {dueAmount}</span>
                </p>
              ) : null}
            </div>
          </div>
          {deactivateDate && (
            <div>
              Your plan has been temporary deactivated till{' '}
              {moment.utc(deactivateDate).format('YYYY-MM-DD')} after your course will be permanent
              deleted
            </div>
          )}
          <div className=" justify-content-evenly mt-2">
            <CouponCard btnname="Place Order" setOnClick={onPlaceorderClick} Price={dueAmount} />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default InstallmentPopUp;
