import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { clearSnackbar } from '../Redux/Actions/snackbarAction';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ErrorSnackbar() {
  const dispatch = useDispatch();
  const { errorSnackbarOpen, message } = useSelector((state) => ({
    errorSnackbarOpen: state.uiReducer.errorSnackbarOpen,
    message: state.uiReducer.message
  }));
  function handleClose() {
    dispatch(clearSnackbar());
  }
  return (
    <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default ErrorSnackbar;
