import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setOptionValueFalseAction,
  setExamQuestionSelectedAction
} from '../Redux/Actions/examActions';
import { maxAttemptReachedMsg } from '../Utils/errormsg';
import { convertFromHtmlToPlainText, getQuestionDescription } from '../Utils/utils';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ReactHtmlParser from 'react-html-parser';

function Msq(props) {
  const dispatch = useDispatch();
  const selectedQuestionIndex = props.selectedQuestionIndex;
  const questionNo = selectedQuestionIndex + 1;
  // const qNo = props.qNo + selectedQuestionIndex;
  const questionData = props.questionData;
  const questionSelected = questionData[selectedQuestionIndex];
  const qNo = questionSelected?.questionNumber;
  const firstOption = questionSelected.en.options[0]?.description;
  const secondOption = questionSelected.en.options[1]?.description;
  const thirdOption = questionSelected.en.options[2]?.description;
  const fourthOption = questionSelected.en.options[3]?.description;
  const marks = questionSelected.marks;
  const negativeMarks = questionSelected.negativeMarks;
  const {
    isMaxAttemptReached,
    isLastSection,
    isNormalExamFlow,
    findNextQuestionNumberLegend,
    findPreviousQuestionNumberLegend,
    filterQuestionData
  } = props;
  let questionTypeDescription = 'MSQ';
  const clearOptions = useSelector((state) => state.SetOptionValueFalseReducer.isTrue);
  const isQuestionSelected = useSelector(
    (state) => state.SetOptionValueFalseReducer.isQuestionSelected
  );
  const [firstOptionSelected, setFirstOptionSelected] = useState(props.clearOptionSelection);
  const [secondOptionSelected, setSecondOptionSelected] = useState(props.clearOptionSelection);
  const [thirdOptionSelected, setThirdOptionSelected] = useState(props.clearOptionSelection);
  const [fourthOptionSelected, setFourthOptionSelected] = useState(props.clearOptionSelection);
  const optionsData = questionSelected.en.options;
  const [answerIndex1, setAnswerIndex1] = useState(0);
  const [answerIndex2, setAnswerIndex2] = useState(0);
  const [open, setOpen] = useState(false);
  const [popimg, setpopimg] = useState('');
  const handleimage = (val) => {
    setpopimg(val);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    // if (isQuestionSelected) {
    setFirstOptionSelected((prevState) => false);
    setSecondOptionSelected((prevState) => false);
    setThirdOptionSelected((prevState) => false);
    setFourthOptionSelected((prevState) => false);
    let count = 0;
    if (optionsData[0]?.isAnswer === true) {
      setFirstOptionSelected((prevState) => true);
      count++;
    }
    if (optionsData[1]?.isAnswer === true) {
      setSecondOptionSelected((prevState) => true);
      count++;
    }
    if (optionsData[2]?.isAnswer === true) {
      setThirdOptionSelected((prevState) => true);
      count++;
    }
    if (optionsData[3]?.isAnswer === true) {
      setFourthOptionSelected((prevState) => true);
      count++;
    }
    if (count === 0) {
      setFirstOptionSelected((prevState) => false);
      setSecondOptionSelected((prevState) => false);
      setThirdOptionSelected((prevState) => false);
      setFourthOptionSelected((prevState) => false);
    }
    dispatch(setExamQuestionSelectedAction(false));
    // }

    if (clearOptions) {
      setFirstOptionSelected((prevState) => false);
      setSecondOptionSelected((prevState) => false);
      setThirdOptionSelected((prevState) => false);
      setFourthOptionSelected((prevState) => false);
      dispatch(setOptionValueFalseAction(false));
    }
    setFirstOptionSelected((prevState) => false);
    setSecondOptionSelected((prevState) => false);
    setThirdOptionSelected((prevState) => false);
    setFourthOptionSelected((prevState) => false);
    if (props.dataArray?.length > 0) {
      props.dataArray?.map((data, index) => {
        if (data === 0) {
          setFirstOptionSelected((prevState) => true);
        } else if (data === 1) {
          setSecondOptionSelected((prevState) => true);
        } else if (data === 2) {
          setThirdOptionSelected((prevState) => true);
        } else if (data === 3) {
          setFourthOptionSelected((prevState) => true);
        }
      });
    }
  }, [clearOptions, isQuestionSelected, props.dataArray]);

  const handleOnPressAnswerOption = (answerIndex) => {
    if (answerIndex === 0) {
      setFirstOptionSelected((prevState) => !prevState);
    } else if (answerIndex === 1) {
      setSecondOptionSelected((prevState) => !prevState);
    } else if (answerIndex === 2) {
      setThirdOptionSelected((prevState) => !prevState);
    } else {
      setFourthOptionSelected((prevState) => !prevState);
    }
    props?.handleOnPressAnswer(answerIndex);
  };
  const getQuestionDescription = (question) => {
    return question.en?.title?.map((item) => {
      return item?.descType === 'img' ? (
        <div className="">
          <img
            className="qusimg cursor"
            key={item?._id}
            src={item?.description}
            alt={item?.description}
            onClick={() => handleimage(item?.description)}
          />
        </div>
      ) : (
        ReactHtmlParser(item?.description)
      );
    });
  };
  return (
    <div className="p-lg-1 h-100">
      <span style={{ color: 'red' }}>{isMaxAttemptReached ? maxAttemptReachedMsg : ''}</span>
      <div className="questionBox boxshadow p-2 p-lg-3 rounded">
        <div className="d-flex justify-content-between">
          <div>
            {isNormalExamFlow ? (
              <>
                {qNo != '1' ? (
                  <span className="leftarrow">
                    <span
                      className="position-absolute"
                      onClick={(e) => props.handleQuestionNoClick(selectedQuestionIndex - 1)}>
                      {qNo - 1}
                    </span>
                  </span>
                ) : null}
              </>
            ) : filterQuestionData &&
              filterQuestionData[0].questionNumber != questionSelected?.questionNumber ? (
              <span className="leftarrow">
                <span
                  className="position-absolute"
                  onClick={(e) =>
                    props.handleNextQuestionLegend(questionSelected?.questionNumber, false)
                  }>
                  {findPreviousQuestionNumberLegend(questionSelected?.questionNumber)}
                </span>
              </span>
            ) : null}
            <span>
              Q. {qNo}/{props.totalNoOfQuestion}
            </span>{' '}
            <span className="greentxt p-1 rounded mx-1">{marks}</span>{' '}
            <span className="redtxt p-1 rounded mx-1">
              {negativeMarks === 0 ? `${negativeMarks}` : `-${negativeMarks}`}
            </span>
            <span className="greentxt p-1 rounded mx-1">{questionTypeDescription}</span>
          </div>
          {isNormalExamFlow ? (
            <>
              {!(questionData.length - 1 === selectedQuestionIndex && isLastSection) ? (
                <span className="rightarrow">
                  <span
                    className="position-absolute"
                    onClick={(e) => props.handleQuestionNoClick(selectedQuestionIndex + 1)}>
                    {qNo + 1}
                  </span>
                </span>
              ) : null}
            </>
          ) : filterQuestionData &&
            filterQuestionData[filterQuestionData.length - 1].questionNumber !=
              questionSelected?.questionNumber ? (
            <span className="rightarrow">
              <span
                className="position-absolute"
                onClick={(e) => props.handleNextQuestionLegend(questionSelected?.questionNumber)}>
                {findNextQuestionNumberLegend(questionSelected?.questionNumber)}
              </span>
            </span>
          ) : null}
        </div>
        <div className="question fw-bold mt-2 p-2">{getQuestionDescription(questionSelected)}</div>
      </div>
      <div className="optionBox mt-4">
        {/* ***************************option1********************* */}
        {optionsData[0]?.descType === 'img' ? (
          <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
            <div
              className={
                firstOptionSelected
                  ? 'd-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm'
                  : 'd-flex align-items-center py-2 px-4 mb-2 '
              }
              onClick={() => handleOnPressAnswerOption(0)}>
              {/* don't use label because by default clicking a label will also trigger the onClick event of the input element it is associated with input. */}
              <div className="radiocontainer">
                <input type="checkbox" name="checkbox" checked={firstOptionSelected} />
                <span className="checkmark">1</span>
              </div>
            </div>
            <span className="ms-3 otiontxt ">
              {optionsData[0]?.descType === 'img' ? (
                <img
                  src={firstOption}
                  className="optionimg"
                  alt="option"
                  onClick={() => handleimage(firstOption)}
                />
              ) : (
                ReactHtmlParser(firstOption)
              )}
            </span>
          </div>
        ) : (
          <div
            className={
              firstOptionSelected
                ? 'checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm'
                : 'd-flex align-items-center border rounded px-4  mb-2 '
            }
            onClick={() => handleOnPressAnswerOption(0)}>
            {/* don't use label because by default clicking a label will also trigger the onClick event of the input element it is associated with input. */}
            <div className="checkboxcontainer">
              <input type="checkbox" name="checkbox" checked={firstOptionSelected} />
              <span className="checkmark">1</span>
            </div>
            <span className="ms-3 otiontxt mt-3">
              {optionsData[0]?.descType === 'img' ? (
                <img
                  src={firstOption}
                  className="optionimg"
                  alt="option"
                  onClick={() => handleimage(firstOption)}
                />
              ) : (
                ReactHtmlParser(firstOption)
              )}
            </span>
          </div>
        )}

        {/* ***************************option2********************* */}
        {optionsData[1]?.descType === 'img' ? (
          <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
            <div
              className={
                secondOptionSelected
                  ? 'd-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm'
                  : 'd-flex align-items-center py-2 px-4 mb-2 '
              }
              onClick={() => handleOnPressAnswerOption(1)}>
              <div className="checkboxcontainer">
                <input type="checkbox" name="checkbox" checked={secondOptionSelected} />
                <span className="checkmark">2</span>
              </div>
            </div>
            <span className="ms-3 otiontxt">
              {optionsData[1]?.descType === 'img' ? (
                <img
                  src={secondOption}
                  className="optionimg"
                  alt="option"
                  onClick={() => handleimage(secondOption)}
                />
              ) : (
                ReactHtmlParser(secondOption)
              )}
            </span>
          </div>
        ) : (
          <div
            className={
              secondOptionSelected
                ? 'checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm'
                : 'd-flex align-items-center border rounded px-4  mb-2 '
            }
            onClick={() => handleOnPressAnswerOption(1)}>
            <div className="checkboxcontainer">
              <input type="checkbox" name="checkbox" checked={secondOptionSelected} />
              <span className="checkmark">2</span>
            </div>
            <span className="ms-3 otiontxt mt-3">
              {optionsData[1]?.descType === 'img' ? (
                <img
                  src={secondOption}
                  className="optionimg"
                  alt="option"
                  onClick={() => handleimage(secondOption)}
                />
              ) : (
                ReactHtmlParser(secondOption)
              )}
            </span>
          </div>
        )}

        {/* ***************************option3********************* */}

        {optionsData[2] != undefined ? (
          <>
            {optionsData[0]?.descType === 'img' ? (
              <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
                <div
                  className={
                    thirdOptionSelected
                      ? 'd-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm'
                      : 'd-flex align-items-center py-2 px-4 mb-2 '
                  }
                  onClick={() => handleOnPressAnswerOption(2)}>
                  <div className="checkboxcontainer">
                    <input type="checkbox" name="checkbox" checked={thirdOptionSelected} />
                    <span className="checkmark">3</span>
                  </div>
                </div>
                <span className="ms-3 otiontxt">
                  {optionsData[2]?.descType === 'img' ? (
                    <img
                      src={thirdOption}
                      className="optionimg"
                      alt="option"
                      onClick={() => handleimage(thirdOption)}
                    />
                  ) : (
                    ReactHtmlParser(thirdOption)
                  )}
                </span>
              </div>
            ) : (
              <div
                className={
                  thirdOptionSelected
                    ? 'checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm'
                    : 'd-flex align-items-center border rounded px-4  mb-2 '
                }
                onClick={() => handleOnPressAnswerOption(2)}>
                <div className="checkboxcontainer">
                  <input type="checkbox" name="checkbox" checked={thirdOptionSelected} />
                  <span className="checkmark">3</span>
                </div>
                <span className="ms-3 otiontxt mt-3">
                  {optionsData[2]?.descType === 'img' ? (
                    <img
                      src={thirdOption}
                      className="optionimg"
                      alt="option"
                      onClick={() => handleimage(thirdOption)}
                    />
                  ) : (
                    ReactHtmlParser(thirdOption)
                  )}
                </span>
              </div>
            )}
          </>
        ) : null}

        {/* ***************************option4********************* */}

        {optionsData[3] != undefined ? (
          <>
            {optionsData[0]?.descType === 'img' ? (
              <div className="d-flex position-relative py-2 px-4 border rounded mb-2 shadow-sm">
                <div
                  className={
                    fourthOptionSelected
                      ? 'd-flex align-items-center py-2 px-4 border rounded mb-2 shadow-sm'
                      : 'd-flex align-items-center py-2 px-4 mb-2 '
                  }
                  onClick={() => handleOnPressAnswerOption(3)}>
                  <div className="checkboxcontainer">
                    <input type="checkbox" name="checkbox" checked={fourthOptionSelected} />
                    <span className="checkmark">4</span>
                  </div>
                </div>
                <span className="ms-3 otiontxt">
                  {optionsData[3]?.descType === 'img' ? (
                    <img
                      src={fourthOption}
                      className="optionimg"
                      alt="option"
                      onClick={() => handleimage(fourthOption)}
                    />
                  ) : (
                    ReactHtmlParser(fourthOption)
                  )}
                </span>
              </div>
            ) : (
              <div
                className={
                  fourthOptionSelected
                    ? 'checkboxbg d-flex align-items-center  px-4 border rounded mb-2 shadow-sm'
                    : 'd-flex align-items-center border rounded px-4  mb-2 '
                }
                onClick={() => handleOnPressAnswerOption(3)}>
                <div className="checkboxcontainer">
                  <input type="checkbox" name="checkbox" checked={fourthOptionSelected} />
                  <span className="checkmark">4</span>
                </div>
                <span className="ms-3 otiontxt mt-3">
                  {optionsData[3]?.descType === 'img' ? (
                    <img
                      src={fourthOption}
                      className="optionimg"
                      alt="option"
                      onClick={() => handleimage(fourthOption)}
                    />
                  ) : (
                    ReactHtmlParser(fourthOption)
                  )}
                </span>
              </div>
            )}
          </>
        ) : null}

        {/* ******************************************************************** */}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="modeldiv editprofEdit">
            <div className="col d-flex mt-0 justify-content-end ">
              <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
            </div>
            <div className="">
              <img className="img-fluid " src={popimg} alt="images" />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Msq;
