import React, { useState } from 'react';
import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Lottie from 'react-lottie';
import orderCompleted from '../Assets/Images/JSON/ordercompleted.json';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getCartList } from '../Redux/Actions/homeAction';

function OrderPlacedSuccesfull(props) {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orderCompleted,
    renderer: 'svg'
  };

  const { hasActivePaidPlan } = useSelector((state) => ({
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan
  }));
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const handleClose = async () => {
    setOpen(false);
    if (hasActivePaidPlan) {
      await navigate('/PaidUser/PaidDashboard');
      await dispatch(getCartList(() => {}, true));
    } else {
      await navigate('/DashboardMenu/Dashboard');
      await dispatch(getCartList(() => {}, true));
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}>
      <Fade in={open}>
        <div className="modeldiv p-5 text-center">
          <Lottie options={defaultOptions} height={130} width={200}></Lottie>
          <p className="text-center colorblue font12">
            <b>Order Placed Successfully!</b>
          </p>
          <p className="text-center font11 ">Order ID: {props.orderID}</p>
          <button type="button" className="btn rounded-pill btn-primary w-50" onClick={handleClose}>
            Proceed to home
          </button>
        </div>
      </Fade>
    </Modal>
  );
}

export default OrderPlacedSuccesfull;
