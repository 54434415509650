import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { clearSnackbar } from '../Redux/Actions/snackbarAction';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function SuccessSnackbar() {
  const dispatch = useDispatch();
  const { successSnackbarOpen, message } = useSelector((state) => ({
    successSnackbarOpen: state.uiReducer.successSnackbarOpen,
    message: state.uiReducer.message
  }));

  function handleClose() {
    dispatch(clearSnackbar());
  }
  return (
    <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SuccessSnackbar;
