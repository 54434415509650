import React, { useState } from 'react';
import '../App.css';
import ButtonPrimary from '../Component/buttonPrimary';
import videoicon from '../Assets/Images/Learn/videoicon.png';
import { LocalImages } from '../Constant';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import ShakaPlayer from 'shaka-player-react';
import 'shaka-player/dist/controls.css';
function OnlineTestExamCard(data) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="boxshadow d-flex p-2 p-md-3 rounded mb-3 bgwhite">
        <div className="px-2 d-flex justify-content-between flex-fill">
          <div className="d-flex justify-content-between flex-column">
            <h6 className="text-uppercase">{data.data.name}</h6>
            <div className="d-flex">
              <div className="me-3">
                <p className="mb-1 colorgrey text-center">Questions</p>
                <p className="colorblue text-center mb-0">{data.data.totalQuestions} Q</p>
              </div>
              <div className="mx-2">
                <p className="mb-1 colorgrey text-center">Duration</p>
                <p className="colorblue text-center mb-0 ">{data.data.duration} Min</p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-column">
            <div className="d-flex justify-content-between mx-2">
              {data?.data?.discussionVideo?.awsDesktopUrl ? (
                <div className="imgcircle mx-2">
                  <img
                    onClick={() => setOpen(true)}
                    src={videoicon}
                    alt="video Icon"
                    style={{ width: '20px' }}
                  />
                </div>
              ) : data?.data?.discussionVideo?.youtubeUrl ? (
                <div className="imgcircle mx-2">
                  <a
                    href={data?.data?.discussionVideo?.youtubeUrl}
                    target="_blank"
                    rel="noreferrer">
                    <img src={videoicon} alt="video Icon" style={{ width: '20px' }} />
                  </a>
                </div>
              ) : null}
              {data?.data?.pyqpdf ? (
                <div className="d-flex align-items-center">
                  <a
                    className=" font12 d-flex align-items-center"
                    href={data?.data?.pyqpdf}
                    target="_blank"
                    rel="noreferrer">
                    <img src={LocalImages.pdff} alt="pdf" style={{ width: '23px' }} />
                  </a>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="mt-3">
              {data.data.status == 1 && data?.existInStore ? (
                <ButtonPrimary
                  disabled={
                    data?.data?.totalAddedQuestion == null || data?.data?.totalAddedQuestion == 0
                  }
                  gotoLink={() => data.onStartExam(data.examId)}
                  name="Resume"
                />
              ) : data.data.status == 2 ? (
                <button
                  type="button"
                  className="btn bg-green"
                  onClick={() => data.onStartExam(data.examId)}>
                  Test Analysis
                </button>
              ) : (
                <ButtonPrimary gotoLink={() => data.onStartExam(data.examId)} name="Start Now" />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="modeldiv text-center">
            <div className="d-flex justify-content-evenly mt-5">
              <ShakaPlayer autoPlay src={data?.data?.discussionVideo?.awsDesktopUrl} />
            </div>
            <button
              type="button"
              className="btn btn-primary px-5 my-4"
              onClick={() => setOpen(false)}>
              Close
            </button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default OnlineTestExamCard;
