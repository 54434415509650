import { navigationRef } from '../Services/navigationService';
import axios from 'axios';
import { store } from '../store';
import ScreenNames from '../Constant/ScreenNames';
import { updateMoreValues } from '../Redux/Actions/AuthAction';
const PlatformNumber = 2;

// const deviceId = DeviceInfo.getUniqueId();

const $http = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});
const $https = axios.create({
  baseURL: 'https://ifas-qa.s3.ap-south-1.amazonaws.com/ForWebsiteTesting',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
    //mode: 'no-cors',
    //'Access-Control-Allow-Origin': '*',
    //'Access-Control-Allow-Credentials': false
    //Accept: '*/*'
  }
});

$http.interceptors.response.use(
  // (config) => config,
  // (error) => {
  //   if (
  //     error?.message?.includes('403') // Block
  //   ) {
  //     handleApiError();
  //   }
  //   return error;
  // }
  function (response) {
    throw new axios.Cancel('Operation canceled by the user.');
  },
  function (error) {
    if (
      error?.message?.includes('403') // Block
    ) {
      handleApiError();
    }
    return Promise.reject(error);
  }
);

const handleApiError = async () => {
  const { dispatch } = store;
  await dispatch(updateMoreValues({ ACCESS_TOKEN_NEW: '' }));
  await navigationRef.current.reset({
    index: 1,
    routes: [{ name: ScreenNames.SignIn }]
  });
};

$http.interceptors.request.use(
  async (req) => {
    if (req?.headers) {
      const getState = await store?.getState();
      if (getState) {
        //@ts-ignore
        const authToken = store.getState()?.Auth?.authToken;
        if (authToken && authToken.length > 0) {
          $http.defaults.headers.common.Authorization = `Bearer ${authToken}`;
        }
      }
    }
    return req;
  },
  (err) => {
    return err;
  }
);

const setAuthorizationToken = (token) => {
  if (token) {
    $http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

let status_code = {
  success: 200,
  successAction: 201,
  notFound: 204,
  badRequest: 400,
  Unauthorized: 401,
  invalid: 400,
  timeout: 408,
  userDelete: 410,
  internalError: 500,
  serverError: 500
};

/**
 * Description: Executes a Get Request
 * @param {string} endUrl request Url
 * @param {Object} headers headers specific to current request
 * @param {Object} queryParams request query parameters
 */

const getRequest = (endPoint, queryParams = '', successCallback, errorCallback) => {
  $http
    .get(endPoint + queryParams, {})
    .then((response) => {
      try {
        if (response?.data?.statusCode === status_code.success) {
          successCallback(response);
        } else {
          successCallback(response?.response);
        }
      } catch {
        successCallback(response?.response);
      }
    })
    .catch((error) => {
      errorCallback(error);
    });
};

/**
 * Description: Executes a Post Request
 * @param {string} endPoint request Url
 * @param {Object} headers headers specific to current request
 * @param {Object} body request body
 */

/*
const postRequest = (
    endPoint: string,
    body: object,
    successCallback: Function,
    errorCallback: Function,
) => {
    $http
        .post(endPoint, JSON.stringify(body))
        .then((response: any) => {
            try{
                if(response?.data?.statusCode === status_code.success){
                     successCallback(response);
                }else{
                     successCallback(response?.response);
                }
            }catch{
             successCallback(response?.response);
            }
        })
        .catch((error: any) => {
            errorCallback(error);
        });
};*/
const postRequest = (endPoint, body, successCallback, errorCallback) => {
  $http
    .post(endPoint, JSON.stringify(body))
    .then((response) => {
      try {
        if (response?.data?.statusCode === status_code.success) {
          successCallback(response);
        } else {
          successCallback(response?.response);
        }
      } catch {
        successCallback(response?.response);
      }
    })
    .catch((error) => {
      errorCallback(error);
    });
};

/**
 * Description: Executes a patch Request
 * @param {string} endPoint request Url
 * @param {Object} headers headers specific to current request
 * @param {Object} queryParams request query parameters
 * @returns {Promise} returns a promise of response
 */

const patchRequest = (endPoint, queryParams, successCallback, errorCallback) => {
  $http
    .patch(endPoint, queryParams)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

/**
 * Description: Executes a delete Request
 * @param {string} endPoint request Url
 * @param {Object} headers headers specific to current request
 * @param {Object} queryParams request query parameters
 * @returns {Promise} returns a promise of response
 */
const deleteRequest = (endPoint, queryParams = '', successCallback, errorCallback) => {
  $http
    .delete(endPoint + queryParams, {})
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

/**
 * Description: Executes a Put Request
 * @param {string} endPoint request Url
 * @param {Object} headers headers specific to current request
 * @param {Object} queryParams request query parameters
 * @returns {Promise} returns a promise of response
 */

const putRequest = (endPoint, queryParams, successCallback, errorCallback) => {
  $http
    .put(endPoint, queryParams)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

/**
 * Description: Executes a Post Request
 * @param {string} endPoint request Url
 * @param {Object} headers headers specific to current request
 * @param {Object} body request body
 */

const postFormRequest = (endPoint, body, successCallback, errorCallback) => {
  $http
    .post(endPoint, JSON.stringify(body), { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

const getApiMyLearning = (endPoint, successCallback, errorCallback) => {
  $https
    .get(endPoint + '.json', {})
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

export default {
  postRequest,
  putRequest,
  patchRequest,
  getRequest,
  deleteRequest,
  handleApiError,
  setAuthorizationToken,
  PlatformNumber,
  status_code,
  $http,
  postFormRequest,
  getApiMyLearning
};
