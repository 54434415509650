import React from 'react';
import '../App.css';
import doubts from '../Assets/Images/Help/doubts.json';
import Lottie from 'react-lottie';
import { LocalImages } from '../Constant';
import { useNavigate } from 'react-router-dom';

function Help() {
  const navigate = useNavigate();
  const coursesupportroute = () => {
    navigate('/PaidUser/CourseSupport');
    // window.location.href = 'CourseSupport';
  };
  const technicalsupportroute = () => {
    navigate('/PaidUser/TechnicalSupport');
    // window.location.href = 'TechnicalSupport';
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: doubts,
    renderer: 'svg'
  };
  return (
    <div className="rightPanel">
      <div>
        <h4>Course Support</h4>
      </div>
      <div className="mt-3 lectureheight p-md-3 width75 ps-md-0">
        <div className="bgwhite">
          <div className="bg rounded p-3 d-flex justify-content-between px-5">
            <div className="d-flex flex-column justify-content-center">
              <h5 className="colorblue fw-bold">Have Doubts?</h5>
              <p className="">Ask To Our Experts</p>
            </div>
            <div>
              <Lottie options={defaultOptions} height={110} width={110} />
            </div>
          </div>
        </div>

        <div className="row mt-4 p-2 d-flex justify-content-between">
          <div
            className="d-flex align-items-center justify-content-between boxshadow p-3 px-lg-5 rounded col-12 col-sm-5 bgwhite cursor mb-3 mb-sm-0"
            onClick={coursesupportroute}>
            <div className="fw-bold">Course Support</div>
            <img src={LocalImages.courseSupport} alt="Course Support" />
          </div>
          <div
            className="d-flex align-items-center justify-content-between boxshadow p-3 px-lg-5 rounded col-12 col-sm-5 bgwhite cursor"
            onClick={technicalsupportroute}>
            <div className="fw-bold">Technical Support</div>
            <img src={LocalImages.techSupport} alt="Technical Support" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
