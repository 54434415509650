import React from 'react';
import '../App.css';
import Lottie from 'react-lottie';
import live from '../Assets/Images/Dashboard/liveNew.json';
import PlayCircleIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { LocalImages } from '../Constant';
import { getExamById } from '../Redux/Actions/examActions';
import { useDispatch } from 'react-redux';

function LectureCard({ data }) {
  const dispatch = useDispatch();
  const { status, link, _id, studentName, videoUrl, title, handOutPdf, examId } = data;
  var youtubeThumbnail = require('youtube-thumbnail');

  if (link) {
    var thumbnail = youtubeThumbnail(link);
  } else if (videoUrl) {
    var thumbnail = youtubeThumbnail(videoUrl);
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: live,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  var lecttype;
  if (status == '2')
    lecttype = (
      <span className="livelable">
        <Lottie options={defaultOptions} height={30} width={100} />
      </span>
    );
  else if (status == '3') lecttype = '';
  else if (videoUrl) lecttype = '';
  else lecttype = <span className="livelable bg-green p-1 rounded">Upcoming</span>;

  const livelecture = (val) => {
    window.open(val, '_blank');
  };

  const viewPDF = (val) => {
    sessionStorage.setItem('pdfLink', val);
    window.open('/PdfViewer', '_blank', 'toolbar=no,scrollbars=yes,resizable=yes', 'noreferrer');
  };

  const handleClickDpp = (dppId) => {
    dispatch(
      getExamById(
        dppId?._id,
        (res) => {
          console.log('res', res);
          if (res?.data?.examData[0]?.totalQuestions === 0) {
            handleDppClick();
          } else if (res?.data?.examData[0]?.status === 2) {
            sessionStorage.setItem('examId', dppId._id);
            sessionStorage.setItem('examData', JSON.stringify(res?.data?.examData[0]));
            window.open(
              '/DetailResult',
              '_blank',
              'width=' + screen.availWidth + ',height=' + screen.availHeight
            );
          } else {
            sessionStorage.setItem('examId', dppId._id);
            sessionStorage.setItem('examData', JSON.stringify(res?.data?.examData));
            window.open(
              '/ExamInstruction',
              '_blank',
              'width=' + screen.availWidth + ',height=' + screen.availHeight
            );
          }
        },
        (err) => {}
      )
    );
  };

  return link !== 'link' && link ? (
    <div className="col-sm-2 py-2 px-4 position-relative ">
      <div key={_id} onClick={(value) => livelecture(link)}>
        {lecttype}
        <img className="img-fluid rounded cursor" src={thumbnail.high.url} alt="Image" />
        {link && (
          <>
            <span className="playbtn ">
              {status === 2 || status === 3 ? (
                <a href={link} target="_blank" style={{ color: '#fff' }} rel="noreferrer">
                  <PlayCircleIcon />
                </a>
              ) : (
                ''
              )}
            </span>
          </>
        )}
      </div>
      <div className="text-center mt-2">{title ? title : ''}</div>
      <div className="border-bottom p-2 font12 fw-bold d-flex justify-content-between align-items-center pe-4 cursor">
        {examId ? (
          <div onClick={() => handleClickDpp(examId)}>
            <img src={LocalImages.dppNew} alt="DPP " className="me-2" style={{ height: '35px' }} />
            DPP
          </div>
        ) : null}
        {handOutPdf ? (
          <div onClick={() => viewPDF(handOutPdf)}>
            <img src={LocalImages.pdff} alt="PDF " className="me-2" style={{ height: '25px' }} />
            PDF
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div
      key={_id}
      className="col-sm-2 py-2 px-2 position-relative "
      onClick={(value) => livelecture(videoUrl)}>
      {lecttype}
      <img className="img-fluid rounded cursor" src={thumbnail?.high?.url} alt="Image" />
      {videoUrl && (
        <>
          <span className="playbtn">
            <a href={videoUrl} target="_blank" style={{ color: '#fff' }} rel="noreferrer">
              <PlayCircleIcon />
            </a>
          </span>
        </>
      )}
      <div className="text-center mt-2">{studentName ? studentName : ''}</div>
    </div>
  );
}

export default LectureCard;
