import React, { useState, useEffect } from 'react';
import { createRef } from 'react';
import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { LocalImages } from '../Constant';
import { useDispatch, useSelector } from 'react-redux';
import {
  addVideoRatings,
  getRecordedPaidDetails,
  getVideoRating,
  updateVideoRating,
  videoWatchStatus
} from '../Redux/Actions/homeAction';
import { useLocation } from 'react-router-dom';
import 'shaka-player/dist/controls.css';
import { getExamById } from '../Redux/Actions/examActions';
import RatingVideo from '../Module/ratingVideo';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SettingsIcon from '@mui/icons-material/Settings';
import { updateHomeMoreValues } from '../Redux/Actions/homeAction';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import DppPopup from './DppPopup';

function LiveLectureVideo() {
  const dispatch = useDispatch();
  const vidRef = createRef();
  const location = useLocation();
  const [ratingId, setRatingID] = useState('');
  const [feedback, setFeedback] = useState('');
  const [star, setStar] = useState(0);

  const [openDpp, setopenDpp] = useState(false);
  const [openrate, setOpenRate] = useState(false);
  const [openHandout, setOpenHandout] = useState(false);
  const { selectedTiles } = useSelector((state) => ({
    selectedTiles: state?.HomeReducer?.selectedTiles
  }));
  const [settings, setSetting] = useState(false);
  const [playbacksetting, setPlaybacksetting] = useState(false);
  const [mute, setmute] = useState(false);
  const [temp, setTemp] = useState(1);
  const { recordedData, lectureId, subjectTopicInfo } = location.state;
  const { m3u8LinkHigh, m3u8LinkLow } = recordedData;
  const [data, setData] = useState(recordedData);
  const [submitFeedback, setSubmitFeedback] = useState(false);
  const [recordStatus, setRecordStatus] = useState('');
  const [play, setPlay] = useState(false);
  const [playTemp, setPlayTemp] = useState(false);
  const [volume, setVolume] = useState(1);
  const [vId, setVId] = useState(data?._id);
  const [subjectId, setSubjectId] = useState(subjectTopicInfo[0]?._id);
  const finalVolume = mute ? 0 : volume ** 2;
  const [link, setLink] = useState('High');
  let manifestUri = m3u8LinkHigh;
  useEffect(() => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    dispatch(
      getVideoRating(
        {
          courseId: selectedTiles?.courseId,
          recordedLectureId: lectureId
        },
        async (res) => {
          setRatingID(res._id);
          setStar(res.rating);
          setFeedback(res.feedback);
        }
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      getRecordedPaidDetails(lectureId, async (data) => {
        setRecordStatus(data);
      })
    );
  }, []);

  const watchVideoStatus = () => {
    let cTime = vidRef?.current?.currentTime;
    const postData = {
      courseId: selectedTiles?.courseId,
      subjectId: lectureId,
      type: 1,
      videoId: vId,
      watchTime: cTime,
      status: cTime === '0' ? '1' : '2'
    };
    dispatch(videoWatchStatus(postData, data));
  };

  async function init() {
    const video = document.getElementById('video');
    let percentageCompleted = 0;
    let totalLength;
    let { videoStarted, videoComplete } = false;
    let status;
    video.ontimeupdate = function () {
      getPercentage()
    };

    function getPercentage() {
      totalLength = video.duration;
      percentageCompleted = (video.currentTime / totalLength) * 100;
      console.log('length',totalLength);
      console.log('percentage', (percentageCompleted+'%'));

      //if video start
      if ((!videoStarted) && (percentageCompleted > 0.5)) {
        videoStarted = true;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'playStart'
        });
        if (videoStarted = true) {
          watchVideoStatus(video.duration, status = 1);
        }
      }
      // if video complete
      if ((!videoComplete) && (percentageCompleted > 95)) {
        videoComplete = true;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'playComplete'
        });
        if (videoComplete = true) {
          watchVideoStatus(video.duration, status = 2);
        }
      }
    }
    const ui = video['ui'];
    const config = {
      // addSeekBar: false,
      controlPanelElements: ['time_and_duration']
    };
    ui.configure(config);
    const controls = ui.getControls();
    const player = controls.getPlayer();

    window.player = player;
    window.ui = ui;
    try {
      await player.load(manifestUri);
      dispatch(updateHomeMoreValues({ isLoader: false }));
    } catch (error) {}
  }

  document.addEventListener('shaka-ui-loaded', init);

  function initFailed(errorEvent) {}

  document.addEventListener('shaka-ui-load-failed', initFailed);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setPlay(true);
    vidRef.current.pause();
    setOpen(true);
  };

  const handleCLickRating = () => {
    setSubmitFeedback(true);
    const data = {
      courseId: selectedTiles?.courseId,
      recordedLectureId: lectureId,
      rating: star,
      feedback
    };
    if (feedback === '') delete data['feedback'];
    if (star === 0) delete data['rating'];
    if (feedback !== '' || star !== 0) {
      if (ratingId) {
        delete data['courseId'];
        delete data['recordedLectureId'];
        dispatch(
          updateVideoRating(ratingId, data, () => {
            setSubmitFeedback(false);
          })
        );
      } else
        dispatch(
          addVideoRatings(data, (res) => {
            setSubmitFeedback(false);
            setRatingID(res._id);
          })
        );
      setOpenRate(false);
    } else dispatch(showErrorSnackbar(`Please give any feedback`));
  };

  const handleClose = () => {
    handleClick2();
    setOpen(false);
  };
  const handleClickDpp = (examId) => {
    setPlay(true);
    vidRef.current.pause();
    dispatch(
      getExamById(
        examId._id,
        (res) => {
          if (res?.data?.examData[0]?.totalQuestions === 0) {
            handleDppClick();
          } else if (res?.data?.examData[0]?.status === 2) {
            sessionStorage.setItem('examId', examId._id);
            sessionStorage.setItem('examData', JSON.stringify(res?.data?.examData[0]));
            window.open(
              '/DetailResult',
              '_blank',
              'width=' + screen.availWidth + ',height=' + screen.availHeight
            );
          } else {
            sessionStorage.setItem('examId', examId._id);
            sessionStorage.setItem('examData', JSON.stringify(res?.data?.examData));
            window.open(
              '/ExamInstruction',
              '_blank',
              'width=' + screen.availWidth + ',height=' + screen.availHeight
            );
          }
        },
        (err) => {}
      )
    );
  };

  const handleCloseRate = () => {
    handleClick2();
    setOpenRate(false);
  };

  const handleClickRate = (event) => {
    setPlay(true);
    vidRef.current.pause();
    setOpenRate(true);
  };

  const handleClick2 = () => {
    if (!playTemp) {
      setPlay(false);
      vidRef.current?.play();
    }
  };

  const handleHandouts = () => {
    setPlay(true);
    vidRef.current.pause();
    setOpenHandout(true);
  };
  const handleCloseHandout = () => {
    handleClick2();
    setOpenHandout(false);
  };
  function sumTime(times) {
    let sumSeconds = 0;
    times.forEach((timer) => {
      let a = timer.split(':');
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });

    return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
  }

  const handleFullScreen = () => {
    setSetting(false);
    setshowSidebar(!showSidebar);
    LiveLectureVideo;
  };
  const handleMute = () => {
    setSetting(false);
    setmute((prev) => !prev);
    if (volume) setVolume(0);
    else setVolume(1);
  };

  const handleSettings = () => {
    setPlaybacksetting(false);
    setSetting((prev) => !prev);
  };
  const handleplaybackSettings = () => {
    setSetting(false);
    setPlaybacksetting((prev) => !prev);
  };

  async function handleResolution(premiereLink, type) {
    let cTime = vidRef?.current?.currentTime;

    setSetting((prev) => !prev);
    await player.load(premiereLink);
    setLink(type);

    if (document.getElementById('video')) {
      document.getElementById('video').currentTime = cTime;
    }
  }

  const handlePlayPause = () => {
    let cTime = vidRef?.current?.currentTime;
    if (document.getElementById('video')) {
      document.getElementById('video').currentTime = cTime;
    }
    if (vidRef.current.paused) {
      setPlayTemp(false);
      setPlay(false);
      vidRef.current.play();
    } else {
      setPlayTemp(true);
      setPlay(true);
      vidRef.current.pause();
    }
    setSetting(false);
  };

  const [playbackRate, setPlaybackRate] = useState(1);

  useEffect(() => {
    vidRef.current.playbackRate = playbackRate;
  }, [playbackRate]);

  useEffect(() => {
    vidRef.current.volume = volume;
  }, [volume]);

  const handleVolume = (e) => {
    setVolume(e.target.valueAsNumber);
    if (e.target.valueAsNumber === 1) {
      setmute(false);
    } else if (e.target.valueAsNumber === 0) {
      setmute(true);
    }
    setSetting(false);
  };
  const setPlayBack = (e, key) => {
    setPlaybacksetting(!playbacksetting);
    setTemp(key);
    setPlaybackRate(key);
  };
  const handleLoad = () => {
    setmute(true);
  };
  const playbackspeeds = [0.5, 1, 1.25, 1.5, 1.75, 2];

  const handleDppClose = () => {
    handleClick2();
    setopenDpp(false);
  };

  const handleDppClick = () => {
    setopenDpp(true);
  };
  const viewPDF = (val) => {
    sessionStorage.setItem('pdfLink', val);
    window.open('/PdfViewer', '_blank', 'toolbar=no,scrollbars=yes,resizable=yes', 'noreferrer');
  };
  const [showSidebar, setshowSidebar] = useState(true);
  return (
    <div className="d-flex">
      <div className={` align-item-center ${showSidebar === true ? 'col-md-9' : 'col-md-12'}`}>
        <div
          data-shaka-player-container
          onClick={handleClick2}
          data-shaka-player-cast-receiver-id="8D8C71A7"
          className="w-100 col-12"
          style={{ zIndex: 0, overflow: 'hidden', height: '92vh', pointerEvents: 'none' }}>
          <video
            autoPlay
            muted={mute}
            data-shaka-player
            id="video"
            height="100%"
            width="100%"
            ref={vidRef}
            className=""
            onLoad={() => {
              handleLoad;
            }}></video>
        </div>
        <div
          className={` videobottom p-2 d-flex justify-content-between align-items-center position-fixed bottom-0 ${
            showSidebar === true ? 'col-md-9' : 'col-md-12 '
          }`}>
          <div>
            <span className="ms-3" onClick={handlePlayPause}>
              {play ? <PlayArrowIcon /> : <PauseIcon />}
            </span>
          </div>
          <div>
            <span className="me-2" onClick={handleMute}>
              {mute === false ? <VolumeUpRoundedIcon /> : <VolumeOffRoundedIcon />}
            </span>
            <span>
              <input
                style={{ height: '5px' }}
                type="range"
                min={0}
                max={1}
                step={0.02}
                value={volume}
                onChange={(event) => {
                  handleVolume(event);
                }}
              />
            </span>
            <span className="position-relative me-2">
              <SlowMotionVideoIcon
                className="cursor "
                alt="setting"
                onClick={handleplaybackSettings}
              />
              {playbacksetting ? (
                <div className="playbacksetting bgwhite boxshadow position-absolute rounded">
                  <div className="text-center border-bottom">
                    {/* <ClearOutlinedIcon style={{width:"17px"}} className="colorblue cursor" onClick={handleclose}/> */}
                  </div>
                  {playbackspeeds.map(function (key) {
                    return (
                      <div
                        id={key}
                        className="qualityno py-2 border-bottom"
                        style={{ cursor: 'pointer', background: temp === key ? '#c2c2a3' : '' }}
                        onClick={(e) => {
                          setPlayBack(e, key);
                        }}>
                        {key}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
            </span>
            <span className="position-relative me-2 ">
              <SettingsIcon className="cursor " alt="setting" onClick={handleSettings} />
              {settings ? (
                <div
                  className="videoquality bgwhite rounded position-absolute border"
                  style={{ right: '-15px', zIndex: '1' }}>
                  <div
                    className={
                      link === 'Low'
                        ? 'qualityno py-2  cursor checkboxbg text-primary selected '
                        : 'qualityno py-2  cursor'
                    }
                    onClick={() => handleResolution(m3u8LinkLow, 'Low')}>
                    Low Quality
                  </div>
                  <div
                    className={
                      link === 'High'
                        ? 'qualityno py-2  cursor checkboxbg text-primary selected'
                        : 'qualityno py-2  cursor '
                    }
                    onClick={() => handleResolution(m3u8LinkHigh, 'High')}>
                    High Quality
                  </div>
                </div>
              ) : (
                ''
              )}
            </span>
            <span className="me-2" onClick={handleFullScreen}>
              <FullscreenIcon />
            </span>
          </div>
        </div>
      </div>
      {showSidebar && (
        <div className="col-md-3 videorightpanel py-3 ">
          {data?.description && (
            <div
              className="border-top border-bottom p-2 font12 fw-bold cursor"
              onClick={handleOpen}>
              <img
                src={LocalImages.about}
                alt="About Lectures "
                className="me-2 "
                style={{ height: '35px' }}
              />
              About Lectures
            </div>
          )}
          {(data?.handout1Url || data?.handout2Url) && (
            <div className="border-bottom p-2 font12 fw-bold  cursor" onClick={handleHandouts}>
              <img
                src={LocalImages.teacherHandout}
                alt="Teachers Handout"
                className="me-2"
                style={{ height: '35px' }}
              />
              {/* <a href={data?.handout1Url} className="text-dark" target="_blank" rel="noreferrer"> */}
              Teachers Handout
              {/* </a> */}
            </div>
          )}
          {data?.examId && (
            <div
              className="border-bottom p-2 font12 fw-bold d-flex justify-content-between align-items-center pe-4 cursor"
              onClick={() => handleClickDpp(data?.examId)}>
              <div>
                <img
                  src={LocalImages.dppNew}
                  alt="DPP "
                  className="me-2"
                  style={{ height: '35px' }}
                />
                DPP
              </div>
              <div className="colorgrey">
                {recordStatus?.examStatus === 0
                  ? 'Start Test'
                  : recordStatus?.examStatus === 1
                  ? 'Start Test'
                  : recordStatus?.examStatus === 2
                  ? 'Test Analysis'
                  : ''}
              </div>
            </div>
          )}
          <div className="border-bottom p-2 font12 fw-bold d-flex justify-content-between align-items-center pe-4 cursor">
            <div onClick={handleClickRate}>
              <img src={LocalImages.rate} alt="rate " className="me-2" style={{ height: '35px' }} />
              Rate Video Lecture
            </div>
          </div>
          <RatingVideo
            star={star}
            setStar={(number) => setStar(number)}
            feedback={feedback}
            setFeedback={(value) => setFeedback(value)}
            open={openrate}
            title={data?.lectureTitle}
            handleCLick={handleCLickRating}
            handleCloseRate={handleCloseRate}
            submitFeedback={submitFeedback}
          />
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="bgwhite aboutlectureModel p-5 rounded position-absolute bottom-0 end-0 ">
            <div className="mb-2 d-flex justify-content-between border-bottom border-3">
              <h6 className="colorblue fw-bold">About Lecture</h6>
              <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
            </div>
            <div className="col">
              <div className="modal-body">
                <h6>
                  {' '}
                  <b>{data?.lectureTitle}</b>
                </h6>
                <p className="text-break" align="justify">
                  {data?.description}
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      {openHandout ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={openHandout}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}>
          <Fade in={openHandout}>
            <div className="bgwhite aboutlectureModel p-5 rounded position-absolute bottom-0 end-0 ">
              <div className="mb-3 d-flex justify-content-between border-bottom border-3">
                <h6 className="colorblue fw-bold">Teachers Handout</h6>
                <ClearOutlinedIcon className="colorblue cursor" onClick={handleCloseHandout} />
              </div>

              <div className="col">
                {data?.handout1Url && (
                  <p className="text-dark fw-bold">
                    {/* <a
                      href={data?.handout1Url}
                      className="text-dark"
                      target="_blank"
                      rel="noreferrer">
                      Without Annotations
                    </a> */}
                    <div
                      className="cursor"
                      // href={"http://localhost:3000/PdfViewer/"+pdf}
                      // target="_blank"
                      // rel="noreferrer"
                      onClick={() => viewPDF(data?.handout1Url)}>
                      Without Annotations
                    </div>
                  </p>
                )}
                {data?.handout2Url && (
                  <p className="text-dark fw-bold">
                    {/* <a
                      href={data?.handout2Url}
                      className="text-dark"
                      target="_blank"
                      rel="noreferrer">
                      With Annotations
                    </a> */}
                    <div className="cursor" onClick={() => viewPDF(data?.handout2Url)}>
                      With Annotations
                    </div>
                  </p>
                )}
              </div>
            </div>
          </Fade>
        </Modal>
      ) : (
        ''
      )}
      {/* <button id='clickButton' style={{display:'none'}}></button> */}
      <DppPopup open={openDpp} handleClose={handleDppClose} />
    </div>
  );
}

export default LiveLectureVideo;
