// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerImg {
    /* max-width: 500px; */
    aspect-ratio: 16/4 !important;
    width: 100%;
  }
  .bannerInnerDiv{
    text-align: center;
    margin: auto;

  }

  .bannercontainer {
    max-width: 1120px;
    margin: auto;
  }
 
  .bannercontainer .carousel-control-prev{
    left: -2%;
    width:2%;
  }

  .bannercontainer .carousel-control-next{
    right: -2%;
    width:2%;
  }
`, "",{"version":3,"sources":["webpack://./src/Assets/CSS/bannerCard.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,6BAA6B;IAC7B,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,YAAY;;EAEd;;EAEA;IACE,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,SAAS;IACT,QAAQ;EACV;;EAEA;IACE,UAAU;IACV,QAAQ;EACV","sourcesContent":[".bannerImg {\n    /* max-width: 500px; */\n    aspect-ratio: 16/4 !important;\n    width: 100%;\n  }\n  .bannerInnerDiv{\n    text-align: center;\n    margin: auto;\n\n  }\n\n  .bannercontainer {\n    max-width: 1120px;\n    margin: auto;\n  }\n \n  .bannercontainer .carousel-control-prev{\n    left: -2%;\n    width:2%;\n  }\n\n  .bannercontainer .carousel-control-next{\n    right: -2%;\n    width:2%;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
