import React, { useEffect, useState } from 'react';
import videoicon from '../Assets/Images/Learn/videoicon.png';
import Books from './books';
import { HomeReducerModal } from '../Modals';
import { useDispatch, useSelector } from 'react-redux';
import { updateHomeValues, removeToCart, applyCoupon } from '../Redux/Actions/homeAction';
import { updateSubscriptionValues } from '../Redux/Actions/subscriptionPlanAction';
import { updateCourseDetailValues } from '../Redux/Actions/courseDetailAction';
function IndividualBooksCard(bookdata, languageIcon) {
 
  const dispatch = useDispatch();
  const { bookSectionId, sectionType, packageSection } = useSelector((state) => ({
    bookSectionId: state?.CourseDetailreducer?.bookSectionId,
    sectionType: state?.CourseDetailreducer?.sectionType,
    packageSection: state?.CourseDetailreducer?.packageSection
  }));

  const {
    subscriptionID,
    selectedPackages,
    cartListDetails,
    CouponData,
    couponCode,
    OriginalPrice
  } = useSelector((state) => ({
    subscriptionID: state?.HomeReducer?.subscriptionID,
    selectedPackages: state?.HomeReducer?.selectedPackages,
    cartListDetails: state?.HomeReducer?.cartListDetails,
    CouponData: state?.HomeReducer?.CouponData,
    couponCode: state?.HomeReducer?.couponCode,
    OriginalPrice: state?.HomeReducer?.OriginalPrice
  }));

  const handleChange = (e) => {
    if (!e.target.checked) {
      let removeObj = cartListDetails?.filter(
        (obj) => obj?.bookSectionId === bookdata?.bookdata?._id
      );
      if (removeObj[0]) dispatch(removeToCart({ cartId: removeObj[0]?._id }));
    }
    dispatch(
      updateHomeValues({
        discountAmount: 0,
        CouponData: [],
        showRemoveCoupon: false,
        couponCode: ''
      })
    );
    dispatch(updateCourseDetailValues({ bookSectionId: e.target.value }));
    let index = packageSection.findIndex((obj) => obj._id == bookdata.packageID);
    let bookIndex = packageSection[index].books.findIndex(
      (boo) => boo._id == bookdata.bookdata._id
    );
    let data = [...packageSection];
    data[index].books[bookIndex].isSelected = e.target.checked;
    dispatch(updateCourseDetailValues({ packageSection: data }));
    if (e.target.checked) {
      const packages = {
        sectionType,
        courseId: bookdata.cId,
        packageSectionId: packageSection[bookdata.Key]?._id,
        bookSectionId: e.target.value
      };
      selectedPackages.push(packages);
    }
    if (bookdata.bookdata.isSelected === false) {
      // selectedPackages.pop();
      const removed = selectedPackages?.filter((item) => item?.bookSectionId !== e?.target?.value);
      const lengthh = selectedPackages?.length;
      for (let i = 0; i < lengthh; i++) {
        selectedPackages.pop();
      }
      for (let i = 0; i < removed?.length; i++) {
        selectedPackages.push(removed[i]);
      }
      dispatch(
        updateHomeValues({
          selectedPackages
        })
      );
    }
    
    let NewPrice = 0;
    for (let i = 0; i < packageSection.length; i++) {
      for (let j = 0; j < packageSection[i].books.length; j++) {
        if (
          packageSection[i].packagebooksSellType === 1 ||
          packageSection[i].packagebooksSellType === 2
        ) {
          if (packageSection[i].books[j].isSelected)
            NewPrice = NewPrice + packageSection[i].books[j].price;
        }
        if (packageSection[i].packagebooksSellType === 3)
          packageSection[i].books[j]['isSelected'] = false;
      }
    }
    dispatch(updateHomeValues({ OriginalPrice: NewPrice }));
    dispatch(
      updateSubscriptionValues({
        price: NewPrice
      })
    );
  };
  return (
    <div className="text-center boxshadow p-2 rounded me-2 me-md-3 mb-3 indbookcard bgwhite">
      <Books data={bookdata.bookdata} />
      <div className="d-flex justify-content-between">
        <span className="ellipsis fw-bold font10 text-start me-1 ">{bookdata.bookdata.name}</span>
        <img style={{ height: '20px' }} src={bookdata?.languageIcon} alt="Book" />
      </div>
      <div className="d-flex justify-content-between mt-2 align-bottom">
        <div className="d-flex">
          {' '}
          <input
            type="checkbox"
            className="cursor"
            value={bookdata.bookdata._id}
            checked={bookdata.bookdata.isSelected}
            onChange={(e) => {
              handleChange(e);
            }}
          />{' '}
          <span className="colorblue ms-2">&#8377; {bookdata.bookdata.price}</span>
        </div>
        {bookdata.bookdata.videoUrl ? (
          <div>
            <a
              href={bookdata.bookdata.videoUrl}
              target="_blank"
              style={{ color: '#fff' }}
              rel="noreferrer">
              <img src={videoicon} alt=" Video Icon" className="ms-1" style={{ width: '15px' }} />
            </a>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default IndividualBooksCard;
