import React, { useEffect } from 'react';
import '../App.css';
import { useSelector } from 'react-redux';
import CourseCard from '../Module/courseCard';
import Lottie from 'react-lottie';
import norecord from '../Assets/Images/JSON/no_record_found.json';
import { useDispatch } from 'react-redux';
import { getCourseList, updateHomeValues } from '../Redux/Actions/homeAction';
import NoRecord from '../Component/noRecord';
function OnlineCourse() {
  const dispatch = useDispatch();
  const { examTypeId, baseCourseId, childrenId, courseList } = useSelector((state) => ({
    courseList: state?.HomeReducer?.courseList,
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    childrenId: state?.AuthReducer?.childrenId
  }));

  useEffect(() => {
    const requestData = {
      baseCourseId,
      examTypeId,
      subExamTypeId: childrenId
    };
    if (childrenId === '' || childrenId === undefined) delete requestData['subExamTypeId'];
    dispatch(
      getCourseList(requestData, (dataCourse) => {
        dispatch(updateHomeValues({ courseList: dataCourse }));
        if (dataCourse.length > 0)
          dispatch(updateHomeValues({ courseSelect: dataCourse[0].sectionId }));
      })
    );
  }, [examTypeId, childrenId]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: norecord,
    renderer: 'svg'
  };

  return (
    <div className="rightPanel">
      <h3>Courses</h3>

      {courseList.length !== 0 ? (
        <div className="customtab mt-4">
          <ul
            className="nav nav-pills mb-3 boxshadow p-2  rounded bgwhite"
            id="pills-tab"
            role="tablist">
            {courseList?.map((c, index) => (
              <li key={index.toString()} className="nav-item m-1" role="presentation">
                <button
                  className={`nav-link text-lowercase text-capitalize px-3 me-3 ${
                    index === 0 ? 'active' : ''
                  }`}
                  id={'pills-' + index + '-tab'}
                  data-bs-toggle="pill"
                  data-bs-target={'#pills-' + index}
                  type="button"
                  role="tab"
                  aria-controls={'pills-' + index}
                  aria-selected="true">
                  {c.name}
                </button>
              </li>
            ))}
          </ul>
          <div className="tab-content" id="pills-tabContent">
            {courseList?.map((c, index) => (
              <div
                key={index.toString()}
                className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                id={'pills-' + index}
                role="tabpanel"
                aria-labelledby={'pills-' + index + '-tab'}
                tabIndex="0">
                <div className="row">
                  {c?.packages?.length !== 0 ? (
                    c?.packages?.map((e, indexInternal) => (
                      <div
                        key={indexInternal.toString()}
                        className="col-sm-5 col-lg-4 col-xl-3 mx-2 mx-lg-3 mb-3">
                        <CourseCard
                          path={e?.bannerImage}
                          course={e?.name}
                          batch={e?.startDate}
                          courseType={e?.type}
                          courseID={e?._id}
                          baseCourseId={e?.baseCourseId}
                          examTypeId={e?.examTypeId}
                          subExamTypeId={e?.subExamTypeId}
                          sectionType={e?.sectionType}
                          courseDuration={e?.duration}
                          testCount={e?.testCount}
                          timeType={e?.timeType}
                          bookCount={e?.bookCount}
                          language={e?.language.icon}
                          classType={e?.classType}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="row mt-5 bgwhite">
                      <div className="col justify-content center">
                        <Lottie options={defaultOptions} height={130} width={200}></Lottie>
                        <p className="text-center">
                          <b>No Result Found</b>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoRecord />
      )}
    </div>
  );
}

export default OnlineCourse;
