import React, { useState } from 'react';

import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  updateMoreValues } from '../Redux/Actions/AuthAction';
import Lottie from 'react-lottie';
import doubts from '../Assets/Images/Help/doubts.json';

function LoginErrorPopup(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { isLoader, ACCESS_TOKEN_NEW ,baseCourseName,examTypeName,firstName} = useSelector((state) => ({
    isLoader: state?.HomeReducer?.isLoader,
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW,
    baseCourseName:state?.AuthReducer?.baseCourseName,
    examTypeName:state?.AuthReducer?.examTypeName,
    firstName:state?.AuthReducer?.firstName,
  }));
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: doubts,
    renderer: 'svg'
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.wrongParent(false);
  };
const gotoLogin=()=>{ 
    dispatch(updateMoreValues({ ACCESS_TOKEN_NEW: '' }));
  sessionStorage.setItem('ACCESS_TOKEN_NEW', '');
  navigate('/SignIn');
}
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <div className="modeldiv p-5 loginErrorPopup">
          <div className="col mb-4 d-flex justify-content-end ">
            {/* <h6 className="colorblue fw-bold">Select Batch Timing</h6> */}
            <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
          </div>
          <div className="row ps-0  x form-check">            
              <div className="col ps-0 mb-2 width60 text-center">
                <div><Lottie options={defaultOptions} height={130} width={200}></Lottie></div>
                <h6 className="form-check-label"><b>"{firstName}"</b> you already registered for "{baseCourseName}-{examTypeName}" to get a subscription in "{props?.baseCoursesname} - {props?.examName}",</h6>
                <h6 className="form-check-label">"Please Register with another account</h6>                 
              </div>
          
            <div className="d-flex justify-content-evenly mt-5">
              
              <button type="button" className="btn btn-primary px-5" onClick={handleClose}>
                Cancel
              </button>
              <button type="button" className="btn btn-primary px-5" onClick={gotoLogin}>
                Login
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default LoginErrorPopup;
