import React, { useEffect, useState } from 'react';
import { LocalImages } from '../Constant';
import '../Assets/CSS/onlineTestExam.css';
import Submitbtn from '../Component/submitBtn';
import ExamStatus from '../Component/examStatus';
import HorizontalTab from '../Component/horizontalTab';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getStatsFromQuestionData,
  totalAnsweredCountForReview,
  totalNotAnsweredCountForReview
} from '../Utils/utils';
import { useDispatch } from 'react-redux';
import { getExamById, setInstructionsAction, submitExam } from '../Redux/Actions/examActions';
import ScreenNames from '../Constant/ScreenNames';
import ExamWarningDialog from '../Component/examWarningDialog';
import ExamSuccesfull from '../Module/examSuccesfull';
import moment from 'moment';
import TimerValue from '../Component/timerValue';
import TestAlertPopUp from '../Module/testAlertPopUp';

function ReviewExam() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const examData = location.state.examData;
  const sections = examData.sections;
  const questionData = examData.questionsData;
  const currentExamTimeSpent = location.state.currentExamTimeSpent;
  const currentQuestion = location.state.currentQuestion;
  const onlegendCounter = location.state.onlegendCounter;
  
  const [sectionSelcted, setSectionSelected] = useState(examData.sections[0]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [examResultinfo, setExamresultinfo] = useState(false);
  const [isAutoSubmit, setIsAutoSubmit] = useState(false);
  const typeCount = (questionSet = [], type) => {
    let count = 0;

    questionSet.forEach((question, index) => {
      if (question.ansType === type) {
        count++;
      }
    });

    return count;
  };

  useEffect(() => {
    setInterval(() => {
      examData.timeTaken = examData.timeTaken + 1;
    }, 1000);
  }, []);

  const examDuration = [
    {
      name: 'Online Class',
      path: LocalImages.duration,
      subText: 'Duration'
    },
    {
      name: 'English,Hindi',
      path: LocalImages.language,
      subText: 'Language'
    },
    {
      name: '20',
      path: LocalImages.totalQuestions,
      subText: 'Total Question'
    },
    {
      name: '20',
      path: LocalImages.maxMarks,
      subText: 'Maximum Marks'
    }
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    // setExamresultinfo(true);
    updateCurrentQuestionTimeSpent(currentQuestion);
    setIsDisabled(true);
    setOpen(false);
    const {
      correctAnswers,
      wrongAnswers,
      skippedAnswers,
      markEarned,
      takenTime,
      negativeMark,
      totalCorrentMark
    } = getStatsFromQuestionData(questionData);
    let examStats = {
      correct: correctAnswers.length,
      incorrect: wrongAnswers.length,
      skipped: skippedAnswers.length,
      markEarned: markEarned,
      marksObtained: totalCorrentMark,
      totalTimeSpent: takenTime,
      totalMarks: examData.totalMarks,
      negativeMark: negativeMark
    };
    let todaysDate = new Date();
    todaysDate = todaysDate.toISOString().split('T')[0];

    let questionsData = [...questionData];
    questionsData.map((question) => {
      if (question?.answerForNAT) {
        delete question.answerForNAT;
      }
    });
    examData['examStats'] = examStats;
    const postData = {
      examId: examData._id,
      questionsData: questionsData,
      examStats: examStats,
      submittedOn: todaysDate,
      status: 2 // exam submit status
    };

    dispatch(
      submitExam(
        postData,
        (res) => {
          let obj = {
            examData,
            correctAnswers,
            wrongAnswers,
            skippedAnswers,
            markEarned,
            takenTime
          };
          // navigate(`/${ScreenNames.DetailResult}`, { state: { ...obj } });
          sessionStorage.setItem('examData', JSON.stringify(obj.examData));
          if (examData.examType == '5') {
            if(examData.resultDate){
              if ( moment(examData.resultDate, "YYYY-MM-DD LT").utc() > moment(new Date(), "YYYY-MM-DD LT")) 
              {
                setIsDisabled(false);
                setExamresultinfo(true);
              } else {
                // sessionStorage.setItem('examId', data?.data1?.examId);
                // sessionStorage.setItem('examData', JSON.stringify(res?.data?.examData[0]));
                navigate('/DetailResult');
              }
            }
            else{
              // sessionStorage.setItem('examId', data?.data1?.examId);
              //   sessionStorage.setItem('examData', JSON.stringify(res?.data?.examData[0]));
                navigate('/DetailResult');
            }

            // var today = new Date();
            // if (
            //   moment(examData.resultDate).format('MMMM Do YYYY, h:mm:ss a') >
            //   moment(today).format('MMMM Do YYYY, h:mm:ss a')
            // ) {
            //   setIsDisabled(false);
            //   setExamresultinfo(true);
            // } else {
            //   // window.open('/DetailResult',"_blank","width="+screen.availWidth+",height="+screen.availHeight);
            //   setIsDisabled(false);
            //   navigate('/DetailResult');
            // }
          } else {
            // window.open('/DetailResult',"_blank","width="+screen.availWidth+",height="+screen.availHeight);
            setIsDisabled(false);
            navigate('/DetailResult');
          }
        },
        (err) => {
          setIsDisabled(false);
        }
      )
    );
  };

  const handleRedirect = (e) => {
    examData.isSubmitted = false;
    dispatch(setInstructionsAction(examData));
    dispatch(
      getExamById(
        examData?._id,
        (res) => {
          // navigate to Dashboard OnlineTest Screen
          return navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.OnlineTest}`);
        },
        () => {}
      )
    );
  };
  const onQuesClick = (val, id,qindex) => {
    navigate(`/${ScreenNames.Exam}`, {
      state: {
        quesIndex: qindex,
        quesno:val,
        examId: examData?._id,
        examData: examData,
        secID: id,
        currentExamTimeSpent: currentExamTimeSpent,
        currentQuestion:currentQuestion
      }
    });
    // navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.OnlineTest}`);
  };

  /**
   * Redirects to exams with a type of all questions.
   * @param {Number} type type represents the ansType of question, i.e. markForReview, answered, notAnswered, etc.
   */
  const onLegendClick = (type) => {
    const filterQuestionData = questionData?.filter((question) => question?.ansType === type);
    if (filterQuestionData.length > 0) {
      navigate(`/${ScreenNames.Exam}`, {
        state: {
          filterQuestionData,
          examId: examData?._id,
          examData: examData,
          currentExamTimeSpent: currentExamTimeSpent,
          onlegendCounter:onlegendCounter + 1,
          currentQuestion:currentQuestion
        }
      });
    }
  };

  window.addEventListener('beforeunload', function (e) {
    e.preventDefault();
    e.returnValue = '';
    localStorage.setItem('startTest', '2');
    sessionStorage.removeItem('examId');
    sessionStorage.removeItem('examData');
    opener.location.reload();
    window.close();
    // opener.location.sessionStorage.removeItem("examId")
    // opener.location.sessionStorage.removeItem("examData")
  });
  const timecnt=(val)=>{
    examData.timeTaken=val;        
    // setTimerCount(val)
  }
  const onTimerFinished = () => {
    if (isAutoSubmit === false) {      
      setIsAutoSubmit(true);
      updateCurrentQuestionTimeSpent(currentQuestion);
      // handleAutoSubmitExam()
    }
  }; 
   // update time spent on the current question
   const updateCurrentQuestionTimeSpent = async (currentQuestion) => {
   
    let time = ((examData?.timeTaken ? examData?.timeTaken : 0) - currentExamTimeSpent) || 0;
 
    // setCurrentExamTimeSpent(examData?.timeTaken);   
    if (currentQuestion?.timeSpent != null ) {
      currentQuestion.timeSpent = (currentQuestion?.timeSpent || 0) + time;
    } else {
      currentQuestion.timeSpent = 0;
    }
  };
 
  return (
    <div>
      <header className="d-flex align-items-center">
        <div>
          <img src={LocalImages.logo} alt="IFAS Logo" className="logo" style={{ width: '120px' }} />
        </div>
        <h5 className="mb-0 ms-3">{examData?.en?.name}</h5>
        <div className="d-flex align-items-center justify-content-end flex-grow-1">
            <span className="timer rounded">
              <AccessTimeIcon className="me-2" />
              <TimerValue
                countdown={examData.duration * 60 - (examData.timeTaken ?? 0)}
                onTimerFinished={onTimerFinished} timecnt={(val)=>timecnt(val)}
                counttime={examData.timeTaken || 0}
              />
            </span>
          </div>
      </header>
      <div className="instcontainer mt-4 p-2">
        <div className="instDuration review py-2">
          <div className="d-flex justify-content-between py-3">
            <div className="d-flex">
              <h6 className="fw-bold colorblue me-3">Exam Status</h6>
              <span className="insttxt colorgrey">
                (Total Questions: {examData?.totalQuestions})
              </span>
            </div>
          </div>
          <ExamStatus
            answered={
              typeCount(questionData, 1) < 10
                ? `0${typeCount(questionData, 1)}`
                : `${typeCount(questionData, 1)}`
            }
            notAnswered={
              typeCount(questionData, 2) < 10
                ? `0${typeCount(questionData, 2)}`
                : `${typeCount(questionData, 2)}`
            }
            marked={
              typeCount(questionData, 3) < 10
                ? `0${typeCount(questionData, 3)}`
                : `${typeCount(questionData, 3)}`
            }
            markAndSave={
              typeCount(questionData, 4) < 10
                ? `0${typeCount(questionData, 4)}`
                : `${typeCount(questionData, 4)}`
            }
            notVisited={
              typeCount(questionData, 0) < 10
                ? `0${typeCount(questionData, 0)}`
                : `${typeCount(questionData, 0)}`
            }
            questionData={questionData}
            isSection={false}
          />
        </div>
        <div className="mt-4 insttxtDiv py-2 tabcontent">
          <HorizontalTab
            examData={examData}
            sections={sections}
            section={sectionSelcted}
            questionData={questionData}
            handleClick={(value, id,quesno) => onQuesClick(value, id,quesno)}
          />
        </div>
      </div>
      <div className="boxshadow pt-2 mt-2 rightbottom rounded instcontainer px-lg-4">
        <p className="fw-bold mb-0 d-none d-md-block">Legend</p>
        <div className="row w-100 align-items-center">
          <div className="col-md-9 d-none d-md-flex justify-content-evenly align-items-center">
            <div className="text-center cursor" onClick={() => onLegendClick(1)}>
              <div className="legend answered">
                {typeCount(questionData, 1) < 10
                  ? `0${typeCount(questionData, 1)}`
                  : `${typeCount(questionData, 1)}`}
              </div>
              <p className="insttxt mt-2 mb-0">Answered</p>
            </div>
            <div className="text-center cursor" onClick={() => onLegendClick(2)}>
              <div className="legend notAnswered">
                {typeCount(questionData, 2) < 10
                  ? `0${typeCount(questionData, 2)}`
                  : `${typeCount(questionData, 2)}`}
              </div>
              <p className="insttxt mt-2 mb-0">Not Answered</p>
            </div>
            <div className="text-center cursor" onClick={() => onLegendClick(3)}>
              <div className="legend marked">
                {typeCount(questionData, 3) < 10
                  ? `0${typeCount(questionData, 3)}`
                  : `${typeCount(questionData, 3)}`}
              </div>
              <p className="insttxt mt-2 mb-0">Marked</p>
            </div>
            <div className="text-center cursor" onClick={() => onLegendClick(4)}>
              <div className="legend marknsave">
                {typeCount(questionData, 4) < 10
                  ? `0${typeCount(questionData, 4)}`
                  : `${typeCount(questionData, 4)}`}
              </div>
              <p className="insttxt mt-2 mb-0">Marked & Answered</p>
            </div>
            <div className="text-center cursor" onClick={() => onLegendClick(0)}>
              <div className="legend notVisited">
                {typeCount(questionData, 0) < 10
                  ? `0${typeCount(questionData, 0)}`
                  : `${typeCount(questionData, 0)}`}
              </div>
              <p className="insttxt mt-2 mb-0">Not Visited</p>
            </div>
          </div>
          <div className="col-12 col-md-3 m-auto mt-3 mb-3 text-center d-flex justify-content-evenly">
            {/* <button type="button" className="btn btn-primary px-4" onClick={()=>onQuesClick("0")}>
              Review
            </button> */}
            <button type="button" className="btn btn-primary px-4" onClick={handleOpen}>
              Submit
            </button>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="model"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}>
            <Fade in={open}>
              <div className="modeldiv">
                <h5 className="colorblue fw-bold text-center">{examData.en.name}</h5>
                <h6 className="text-center w-75 m-auto my-4">
                  You are about to finish your exam, Click "Submit" to finish and "Cancel" to
                  continue
                </h6>
                <div className="row justify-content-center">
                  {sections.map((section, index) => {
                    let totalAnsweredCountForReviewPerSection = totalAnsweredCountForReview(
                      questionData,
                      section?._id
                    );
                    let totalNotAnsweredCountForReviewPerSection = totalNotAnsweredCountForReview(
                      questionData,
                      section?._id
                    );
                    let totalQuestionsCountPerSection =
                      totalAnsweredCountForReviewPerSection +
                      totalNotAnsweredCountForReviewPerSection;
                    totalQuestionsCountPerSection =
                      totalQuestionsCountPerSection < 10
                        ? `0${totalQuestionsCountPerSection}`
                        : `${totalQuestionsCountPerSection}`;
                    return (
                      <div key={section?._id} className="col-6 col-lg-4 confirmReview ">
                        <span className="fw-bold mb-2">
                          {/* Total Question: {totalQuestionsCountPerSection} */}
                        </span>
                        <div className="Reviewdetails rounded">
                          <span className="fw-bold d-block mb-2 text-center colorgreen">
                            {section.name}
                          </span>
                          <span>Total Question: {totalQuestionsCountPerSection}</span>
                          <span>
                            Total Answered:{' '}
                            {totalAnsweredCountForReviewPerSection < 10
                              ? `0${totalAnsweredCountForReviewPerSection}`
                              : `${totalAnsweredCountForReviewPerSection}`}
                          </span>
                          <span>
                            Not Answered:{' '}
                            {totalNotAnsweredCountForReviewPerSection < 10
                              ? `0${totalNotAnsweredCountForReviewPerSection}`
                              : `${totalNotAnsweredCountForReviewPerSection}`}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex justify-content-evenly mt-5">
                  <button
                    type="button"
                    className="btn btn-outline-secondary px-5"
                    onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    disabled={isDisabled}
                    className="btn btn-primary px-5"
                    onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </Fade>
          </Modal>
          <ExamWarningDialog handleRedirect={handleRedirect} />
          {examResultinfo ? <ExamSuccesfull examData={examData} /> : null}
          {isAutoSubmit ? <TestAlertPopUp handleAutoSubmitExam={handleSubmit} examDetails= {examData}/> : ''}
        </div>
      </div>
    </div>
  );
}

export default ReviewExam;
