import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useCopyToClipboard from '../Hooks/useCopyToClipboard';
import { LocalImages } from '../Constant';

function CopyButton({ textToCopy, resetInterval = 3000 }) {
  // isCopied is reset after 3 second timeout
  const [isCopied, handleCopy] = useCopyToClipboard(resetInterval);

  return (
    <button
      style={{ outline: 'none' }}
      className="cursor successcolor copylinkDiv rounded"
      onClick={() => handleCopy(textToCopy)}>
      {isCopied ? (
        <span className="checkcircle">
          <CheckCircleIcon />
        </span>
      ) : (
        <img className="CopyLink" src={LocalImages.CopyLink} alt="CopyLink" />
      )}
    </button>
  );
}

export default CopyButton;
