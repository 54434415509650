import React from 'react';
import { useState, useEffect } from 'react';
import '../Assets/CSS/signIn.css';
import Submitbtn from '../Component/submitBtn';
import { LocalImages } from '../Constant';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useLocation, useNavigate } from 'react-router-dom';
function OtpSuccess() {
  const location = useLocation();
  const number = location.state.maskedNumber;
  const hasActivePaidPlan = location.state.hasActivePaidPlan;
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (hasActivePaidPlan === true) {
      navigate('/PaidUser/PaidDashboard');
    } else {
      navigate('/DashboardMenu/Dashboard');
    }
  };
  return (
    <div className="row">
      <div className="col-7 leftSidediv">
        <div className="loginHeader ">
          <img src={LocalImages.logo} alt="IFAS Logo" onClick={handleSubmit} className="logo cursor" style={{ width: "120px" }}  />
        </div>
        <div className="imgDiv">
          <img src={LocalImages.otpsuccessimg} alt="signIn" className="leftImg img-fluid w-75" />
        </div>
      </div>
      <div className="col-5 rightDivPad">
        <div className="text-center successicon successcolor">
          <TaskAltIcon />
        </div>
        <h4 className="text-center colorblue fw-bold mb-0 mt-5">Thank You!</h4>
        <h4 className="text-center colorblue fw-bold">For Verifying Your Phone Number</h4>
        <p className="text-center txt">Now onward use mobile number for login to web</p>
        <p className="text-center successcolor">+91-{number}</p>

        <div className="mobileField mt-5">
          <form onSubmit={handleSubmit}>
            <div className="mt-5 text-center">
              <Submitbtn name="Proceed to Home" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default OtpSuccess;
