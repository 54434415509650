import React,{useState, useEffect} from 'react';
import '../Assets/CSS/home.css';
import downloadbanner from '../Assets/Images/Home/classroominpocket.png';
import wave from '../Assets/Images/Home/wave.png';
import AppleIcon from '../Assets/Images/Dashboard/AppleIcon.png';
import PhoneInput from '../Component/phoneInput';
import Playstore from '../Assets/Images/Dashboard/playstore.png';
import { useDispatch } from 'react-redux';
import { getCountry } from '../Redux/Actions/AuthAction';

function ClassroomInPocket() {
  const dispatch = useDispatch();
  const [number, setNumber] = useState('');
  const [countryCode, setCountryCode] = useState({ _id: '', name: '', callingCode: '', flag: '' });
  const [countryData, setCountryData] = useState([]);

  const handleOnChangePhoneText = (prevPhoneInputText) => {
    setNumber(prevPhoneInputText.target.value);
  };
  useEffect(() => {
    dispatch(
      getCountry(
        (data) => {
          setCountryData(data);
          const filteredCountries = data.filter((item) => {
            const countryCodeNew = item.callingCode.toLowerCase();
            return countryCodeNew.indexOf('+91') > -1;
          });

          setCountryCode(filteredCountries[0]);

          setNumber('');
        },
        () => {}
      )
    );
  }, []);
  return (
    <div className="downloadsec position-relative">
      <img src={wave} alt="Wave" className="waveimg" />
      <div className="row justify-content-center">
        <div className="col-12 col-md-5 m-0">
          <div className="downtxtdiv ">
            <h4 className="fw600 ">Classroom In Your Pocket</h4>
            <h5 className="mb-1 mt-3 grey70">Choose Your Courses & Grow Your</h5>
            <h5 className="grey70">Career With All New IFAS App</h5>
          </div>
          {window.location.pathname === '/Home' ||
          window.location.pathname === '/Dashboard' ||
          window.location.pathname === '/HomePage' ? (
            ''
          ) : (
            <PhoneInput
              setCountryCode={(value) => {
                setCountryCode(value);
              }}
              countryCode={countryCode}
              countryData={countryData}
              placeholder="Enter Your Phone Number"
              value={number}
              handleOnChangePhoneText={handleOnChangePhoneText}
            />
          )}

          <div className="mt-5">
            <p className="fw600 downtxt" style={{ color: 'var(--blue)' }}>
              Download App Now
            </p>
            <div className="d-flex">
              <a
                href="https://play.google.com/store/apps/details?id=com.ifasapp"
                target="_blank"
                className="d-flex store align-items-center rounded-3"
                rel="noreferrer">
                <div className="imgdiv">
                  <img src={Playstore} style={{ width: '25px' }} alt="Playstore" />
                </div>
                <div className="ms-3 me-2">
                  <span className="d-block font11 lh-sm">Get it On</span>
                  <span className="d-block storename font12 lh-1">Play Store</span>
                </div>
              </a>
              <a
                href="https://itunes.apple.com/us/app/ifas-online/id1448199555?ls=1&mt=8"
                target="_blank"
                className="d-flex store align-items-center rounded-3"
                rel="noreferrer">
                <div className="imgdiv">
                  <img src={AppleIcon} style={{ width: '25px' }} alt="Playstore" />
                </div>
                <div className="ms-3 me-2">
                  <span className="d-block font11 lh-sm">Get it On</span>
                  <span className="d-block storename font12 lh-1">App Store</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-7">
          <img className="downloadbanner img-fluid" src={downloadbanner} alt="downloadbanner" />
        </div>
      </div>
    </div>
  );
}

export default ClassroomInPocket;
