import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setExamStatusSectionChangeAction,
  setExamStatusValueChangeAction
} from '../Redux/Actions/examActions';
const ExamStatus = (props) => {
  const dispatch = useDispatch();
  const isChangeSectionValue = useSelector(
    (state) => state.SetOptionValueFalseReducer.isExamStatusSectionChanged
  );
  const isChangeValue = useSelector(
    (state) => state.SetOptionValueFalseReducer.isExamStatusValueChanged
  );

  if (isChangeValue & !props.isSection) {
    dispatch(setExamStatusSectionChangeAction(true));
    dispatch(setExamStatusValueChangeAction(false));
  }
 
  if (isChangeSectionValue & props.isSection) {
    dispatch(setExamStatusSectionChangeAction(false));
  }

  return (
    <div className="d-flex border-bottom pb-2 examstatus">
      <div className="text-center border-end px-2">
        <span className="colorgreen d-block fw-bold">{props.answered}</span>
        <span className="insttxt colorgrey d-block fw-bold">Answered</span>
      </div>
      <div className="text-center border-end px-2">
        <span className="errorcolor d-block fw-bold">{props.notAnswered}</span>
        <span className="insttxt colorgrey d-block fw-bold">Not Answered</span>
      </div>
      <div className="text-center px-2 border-end">
        <span className="colorpurple d-block fw-bold">{props.marked}</span>
        <span className="insttxt colorgrey d-block fw-bold">Marked</span>
      </div>
      <div className="text-center px-2 border-end">
        <span className="colorpurple d-block fw-bold marknsaveval">{props.markAndSave}</span>
        <span className="insttxt colorgrey d-block fw-bold">Mark & save</span>
      </div>
      <div className="text-center px-2">
        <span className="colorgrey d-block fw-bold">{props.notVisited}</span>
        <span className="insttxt colorgrey d-block fw-bold">Not Visited</span>
      </div>
    </div>
  );
};

export default ExamStatus;
