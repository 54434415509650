import React from 'react';
import msg from '../Assets/Images/Help/msg.png';
import { LocalImages } from '../Constant';
import MessageIcon from '@mui/icons-material/Message';
import moment from 'moment';
function SupportCard(props) {
  const { data, clickhandle } = props;
  return (
    <div
      className="boxshadow rounded font12  p-2 bgwhite mb-3 cursor"
      onClick={clickhandle}
      key={data?.issueId}>

      <div className="d-flex justify-content-between mx-5  mt-2">

        <p className="colorblue mb-0 ms-3">{data?.name}</p>


        {data?.unreadMsgCountUser > 0 ? <div className="position-relative statusbtn ">
          <div className="colorred ms-3">
            <MessageIcon />
          </div>
          <div className="unreadcount bg-success">
            {data?.unreadMsgCountUser}
          </div>
        </div> : null}
      </div>
      <div className="d-flex">
        {data?.logo ?
          <div className="px-2">
            <img src={data?.logo} alt="Course img" style={{ width: '35px' }} />
          </div> :
          <div className="px-2 mt-3">
            <img
              src={LocalImages.favicon}
              alt="IFAS Logo"
              className=" cursor"
              style={{ width: '40px' }}
            />
          </div>}
        <div className="flex-fill ps-2">




          <p className="mb-0">{data?.description}</p>
          <p className="mb-0">
            Issue ID: #{data?.issueId} |  {moment(data?.createdAt).format('D MMMM YYYY, h:mm a')}
          </p>
          {data?.status === 1 ? (
            <div className="d-flex colorgrey">
              <MessageIcon fontSize='small' />
              <p className="successcolor mb-0 ms-1">{moment(data?.lastMessageReceivedDate).format('D MMMM YYYY, h:mm a')}</p>
            </div>

          ) : (
            <div className="d-flex colorgrey">
              <MessageIcon fontSize='small' />
              <p className="mb-0 ms-1">{moment(data?.lastMessageReceivedDate).format('D MMMM YYYY, h:mm a')}</p>
            </div>
          )}
        </div>
        {/* {data?.status === 1 ? (
        <div className="d-flex flex-column justify-content-between align-items-end">
          <img src={msg} alt="Msg Count" style={{ width: '18px' }} />
          <button type="button" className="btn inprogress btn-outline-success font12 py-1">
            In Progress
          </button>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-end align-items-end">
          <button type="button" className="btn btn-outline-secondary font12 py-1">
            Closed
          </button>
        </div>
      )} */}

        {/* {data?.status === 1 ? (
        <div className="d-flex flex-column justify-content-end align-items-end">
          <button type="button" className="btn btn-outline-success font12 py-1">
            New
          </button>
        </div>
      ) : null}
      {data?.status === 2 ? (
        <div className="d-flex flex-column justify-content-end align-items-end">
          <button type="button" className="btn btn-outline-primary font12 py-1">
            In Progress
          </button>
        </div>
      ) : null} */}

        {/* {data?.status === 3 ? (
        <div className="d-flex flex-column justify-content-end align-items-end">
          <button type="button" className="btn btn-outline-secondary font12 py-1">
            Closed
          </button>
        </div>
      ) : null} */}
      </div>
    </div>
  );
}

export default SupportCard;
