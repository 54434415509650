import { LocalImages } from '../Constant';
import React, { useState, useEffect } from 'react';
import logo from '../Assets/Images/Home/logo.png';
import '../Assets/CSS/signIn.css';
import Phoneno from '../Component/phoneno';
import Submitbtn from '../Component/submitBtn';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import service from '../Constant/service';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import TimerClock from '../Component/timerClock';
import { getCountry, sendOTP } from '../Redux/Actions/AuthAction';

function MobileVerification(props) {
  const location = useLocation();
  const { isVerified, userId, mobileNo } = location.state.response;
  const [number, setNumber] = useState('');
  const [style, setStyle] = useState(1);
  const [numberError, setNumberError] = useState('');
  const [loader, setLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const dispatch = useDispatch();
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [countryCode, setCountryCode] = useState({ _id: '', name: '', callingCode: '', flag: '' });
  const [isVisible, setVisible] = useState(false);
  const [blank, setblank] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (number?.length == 10) {
      setblank(false);
    } else {
      setblank(true);
    }
  }, [number]);

  function allnumeric(inputtxt) {
    var numbers = /^[0-9]+$/;
    if (inputtxt.match(numbers)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input numeric characters only'));
      return false;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleOnChangePhoneText = (prevPhoneInputText) => {
    const numbersNew = /^[0-9]+$/;
    if (!prevPhoneInputText.target.value) setNumber(prevPhoneInputText.target.value);
    else if (prevPhoneInputText.target.value.match(numbersNew))
      setNumber(prevPhoneInputText.target.value);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
  };

  const checkDisable = () =>
    numberError !== '' ||
    number === '' ||
    countryCode._id === '' ||
    !allnumeric(number) ||
    number.length !== 10;

  const onPressSendOTP = () => {
    if (!checkDisable()) {
      setStyle(0.7);
      const payload = {
        mobileNo: number,
        countryId: countryCode._id
      };

      dispatch(
        sendOTP(
          payload,
          (response) => {
            if (response?.statusCode === service.status_code.success) {
              dispatch(showSuccessSnackbar(response?.msg));

              navigate('/OTPVerification', {
                state: { mobileNo: number, countryCode: countryCode, isVerified: false, userId }
              });
            } else {
              dispatch(showErrorSnackbar('wentWrong'));
            }
          },
          (error) => {
            if (error?.response?.data?.msg) dispatch(showErrorSnackbar(error.response.data.msg));
            else dispatch(showErrorSnackbar('wentWrong'));
          }
        )
      );
    } else {
      dispatch(showErrorSnackbar('please fill mobile number'));
    }
  };

  useEffect(() => {
    // ReadSms.requestReadSMSPermission();

    dispatch(
      getCountry(
        (data) => {
          setCountryData(data);
          setLoading(false);
          const filteredCountries = data.filter((item) => {
            const countryCodeNew = item.callingCode.toLowerCase();
            return countryCodeNew.indexOf('+91') > -1;
          });
          setCountryCode(filteredCountries[0]);
          setNumber('');
        },
        () => {
          setLoading(false);
        }
      )
    );
  }, []);

  return (
    <div className="row">
      <div className="col-7 leftSidediv">
        <div className="loginHeader ">
          <img src={LocalImages.logo} alt="IFAS Logo" className="logo" style={{ width: '120px' }} />
        </div>
        <div className="mobimgDiv">
          <img src={LocalImages.mobVerfiimg} alt="signIn" className="leftImg w-75" />
        </div>
      </div>
      <div className="col-5 rightDivPad">
        <h3 className="text-center colorblue boldfont mb-3">Welcome back to IFAS</h3>
        <p className="text-center colorblue mb-2" style={{ fontSize: '18px' }}>
          Connect your phone number
        </p>
        <p className="text-center txt mb-0">for smoothly use,</p>
        <p className="text-center txt">please verify the mobile number</p>

        <div className="mobileField mt-5 px-2">
          <form onSubmit={handleSubmit}>
            <label className="mb-2 ">Mobile number</label>

            <Phoneno
              setCountryCode={(value) => setCountryCode(value)}
              countryCode={countryCode}
              countryData={countryData}
              placeholder="Enter Your Phone Number"
              maxLength="10"
              minLength={10}
              value={number}
              handleOnChangePhoneText={handleOnChangePhoneText}
            />

            <div className="mt-4 text-center">
              <Submitbtn name="Send OTP" disabled={blank} onPress={onPressSendOTP} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MobileVerification;
