import React from 'react';
import { useState, useEffect } from 'react';
import '../Assets/CSS/signIn.css';
import Phoneno from '../Component/phoneno';
import Submitbtn from '../Component/submitBtn';
import { LocalImages } from '../Constant';
import EditIcon from '@mui/icons-material/Edit';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import service from '../Constant/service';
import {
  verifyOTP,
  verifyOTPUser,
  updateMoreValues,
  examType,
  getState,
  sendOTP
} from '../Redux/Actions/AuthAction';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { NavLink } from 'react-bootstrap';
import RefreshIcon from '@mui/icons-material/Refresh';
import TimerClock from '../Component/timerClock';

function OtpVerification(props) {
  const location = useLocation();
  const [otp, setotp] = useState();
  const { mobileNo, countryCode, isVerified, userId } = location.state;
  const [loader, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [blank, setblank] = useState(true);
  const [openVideo, setopenVideo] = useState(false);

  const PrivacyPolicy = () => {
    window.location.href = 'privacy-policy';
  };

  const Terms = () => {
    window.location.href = 'terms-conditions';
  };
  const homepage = (e) => {
    window.location.href = '/';
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleChange = (otp) => {
    setotp(otp.toString());
  };
  const navigatelink = (e) => {
    // if(isOldUser===true){
    // navigate('/MobileVerification');
    // }
    // else
    // navigate('/SignIn', { state: { IsclassRoom: false } });
    history.back();
  };
  useEffect(() => {
    if (otp?.length == 4) {
      setblank(false);
    } else {
      setblank(true);
    }
  }, [otp]);

  const onPress = () => {
    setLoading(true);
    if (isVerified) {
      const payload = {
        mobileNo,
        countryId: countryCode._id,
        otp: otp
      };
      dispatch(
        verifyOTP(
          payload,
          async (response) => {
            const {
              isNewUser,
              userData,
              cartItemCount,
              unReadCount,
              hasActivePaidPlan,
              IsclassRoom
            } = response;

            if (isNewUser) {
              setLoading(false);
              navigate('/CreateAccount', {
                state: { countryCode: countryCode, mobileNo: mobileNo }
              });
            } else if (IsclassRoom) {
              setLoading(false);
              navigate('/ClassroomRegistration', {
                state: { countryCode: countryCode, mobileNo: mobileNo }
              });
            } else {
              const accessToken = response.jwtToken.access.token;
              const {
                baseCourseId,
                stateId,
                examTypeId,
                examTypeName,
                subExamTypeId,
                subExamTypeName
              } = userData;
              dispatch(
                updateMoreValues({
                  examTypeId: examTypeId,
                  examTypeName: examTypeName
                })
              );
              if (subExamTypeId) {
                dispatch(
                  updateMoreValues({
                    childrenId: subExamTypeId,
                    childrenName: subExamTypeName
                  })
                );
              }
              await dispatch(
                getState(
                  (dataState) => {
                    const newArray = dataState;
                    newArray.splice(0, 0, { _id: '', name: 'All India' });
                    dispatch(updateMoreValues({ stateData: newArray }));
                    const index = newArray.findIndex((item) => item._id === stateId);
                    dispatch(updateMoreValues({ stateName: newArray[index].name }));
                  },
                  () => {}
                )
              );
              await dispatch(
                examType(
                  { baseCourseId, stateId },
                  (examTypeResponse) => {
                    dispatch(
                      updateMoreValues({
                        examType: examTypeResponse.data.examTypes,
                        talkToExpert: examTypeResponse.data.expertDetails
                      })
                    );
                    if (examTypeId === undefined || examTypeId === null || examTypeId === '') {
                      if (examTypeResponse.data.examTypes.length > 0) {
                        dispatch(
                          updateMoreValues({
                            examTypeId: examTypeResponse.data.examTypes[0]._id,
                            examTypeName: examTypeResponse.data.examTypes[0].name
                          })
                        );

                        if (examTypeResponse.data.examTypes[0].childrenId) {
                          dispatch(
                            updateMoreValues({
                              childrenName: examTypeResponse.data.examTypes[0].childrenId[0].name,
                              childrenId: examTypeResponse.data.examTypes[0].childrenId[0]._id
                            })
                          );
                        }
                      }
                    }
                  },
                  () => {}
                )
              );
              setLoading(false);
              await dispatch(updateMoreValues(userData));
              service.setAuthorizationToken(accessToken);
              await dispatch(
                updateMoreValues({
                  ACCESS_TOKEN_NEW: accessToken,
                  cartItemCount,
                  unReadCount
                })
              );
              sessionStorage.setItem('ACCESS_TOKEN_NEW', accessToken);
              if (hasActivePaidPlan === true) {
                navigate('/PaidUser/PaidDashboard');
              } else {
                navigate('/DashboardMenu/Dashboard');
              }
            }
          },
          (error) => {
            setLoading(false);
            if (error?.data?.msg) {
              dispatch(showErrorSnackbar(error?.data?.msg));
            } else {
              dispatch(showErrorSnackbar('wentWrong'));
            }
          }
        )
      );
    } else {
      const payload = {
        mobileNo,
        countryId: countryCode._id,
        otp: otp,
        userId
      };
      dispatch(
        verifyOTPUser(
          payload,
          (response) => {
            setLoading(false);
            const { accessToken, userData, cartItemCount, unReadCount, hasActivePaidPlan } =
              response?.data;
            if (response?.statusCode === service.status_code.success) {
              dispatch(showSuccessSnackbar(response?.msg));
              const { baseCourseId, stateId } = userData;
              dispatch(
                getState(
                  (dataState) => {
                    const newArray = dataState;
                    newArray.splice(0, 0, { _id: '', name: 'All India' });
                    dispatch(updateMoreValues({ stateData: newArray }));
                    const index = newArray.findIndex((item) => item._id === stateId);
                    dispatch(updateMoreValues({ stateName: newArray[index].name }));
                  },
                  () => {}
                )
              );
              dispatch(
                examType(
                  { baseCourseId, stateId },
                  (examTypeResponse) => {
                    dispatch(
                      updateMoreValues({
                        examType: examTypeResponse?.data?.examTypes,
                        talkToExpert: examTypeResponse?.data?.expertDetails
                      })
                    );

                    if (examTypeResponse?.data?.examTypes?.length > 0) {
                      dispatch(
                        updateMoreValues({
                          examTypeId: examTypeResponse?.data?.examTypes[0]?._id,
                          examTypeName: examTypeResponse?.data?.examTypes[0]?.name
                        })
                      );

                      if (examTypeResponse?.data?.examTypes[0]?.childrenId) {
                        dispatch(
                          updateMoreValues({
                            childrenName: examTypeResponse?.data?.examTypes[0]?.childrenId[0]?.name,
                            childrenId: examTypeResponse?.data?.examTypes[0]?.childrenId[0]?._id
                          })
                        );
                      }
                    }
                  },
                  () => {}
                )
              );
              setLoading(false);
              dispatch(updateMoreValues(userData));
              service.setAuthorizationToken(accessToken);
              dispatch(
                updateMoreValues({
                  ACCESS_TOKEN_NEW: accessToken,
                  cartItemCount,
                  unReadCount
                })
              );

              navigate('/OtpSuccess', {
                state: { maskedNumber: mobileNo, hasActivePaidPlan: hasActivePaidPlan }
              });
            } else {
              dispatch(showErrorSnackbar('wentWrong'));
            }
          },
          (error) => {
            setLoading(false);
            if (error?.data?.msg) dispatch(showErrorSnackbar(error.data.msg));
            else {
              dispatch(showErrorSnackbar('wentWrong'));
            }
          }
        )
      );
    }
  };

  const onResendPress = () => {
    //Keyboard.dismiss();
    const payload = {
      mobileNo,
      countryId: countryCode._id
    };
    dispatch(
      sendOTP(
        payload,
        (response) => {
          if (response?.statusCode === service.status_code.success) {
            setotp(false);
            dispatch(showSuccessSnackbar(response?.msg));
          } else dispatch(showErrorSnackbar('wentWrong'));
        },
        (error) => {
          setLoading(false);
          if (error?.response?.data?.msg) dispatch(showErrorSnackbar(error.response.data.msg));
          else dispatch(showErrorSnackbar('wentWrong'));
        }
      )
    );
  };

  return (
    <div className="bgmob">
      <div className="d-block d-lg-none loginHeader text-start">
        <img
          src={LocalImages.logo}
          alt="IFAS Logo"
          className="logo cursor"
          onClick={homepage}
          style={{ width: '120px' }}
        />
      </div>
      <div className="row authDiv">
        <div className="d-none d-lg-block col-lg-7 leftSidediv">
          <div className="loginHeader ">
            <img
              src={LocalImages.logo}
              alt="IFAS Logo"
              className="logo cursor"
              onClick={homepage}
              style={{ width: '120px' }}
            />
          </div>
          <div className="imgDiv">
            <img src={LocalImages.otpimg} alt="signIn" className="leftImg img-fluid w-100" />
          </div>
        </div>
        <div className="col-lg-5 rightDivPad position-relative">
          <h3 className="text-center colorblue fw-bold boldfont mb-2">OTP Verification</h3>
          <p className="text-center">
            Enter a 4 digit OTP sent to{' '}
            <span className="colorblue">
              {countryCode.callingCode}-{mobileNo}
            </span>
          </p>
          <p className="justify-content-center d-flex colorblue" onClick={navigatelink}>
            <EditIcon className="cursor me-1 colorblue" fontSize="small" />
            <NavLink>Edit Number</NavLink>
          </p>
          
          <div className="mobileField mt-5">
            <form onSubmit={handleSubmit}>
              <div className="mt-3 text-center">
                <label className="mb-2 ">OTP</label>
                <div className="otpBox  cursor d-flex justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    renderSeparator={<span className="ms-3 rounded-5"></span>}
                    isInputNum={true}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
              </div>
              <div className="mt-5 text-center px-5 ms-2 bgwhite">
                <Submitbtn name="Verify OTP" disabled={blank} onPress={onPress} />
                {/* <button className="mt-5 text-center px-5 ms-2 color-grey" type="submit" id="button-addon2" onPress={onPress} >Verify OTP</button> */}
              </div>
              <div className="fw-bolder text-center mx-2 px-3">
                OTP Sent to your Mobile Number and Email
              </div>
              <TimerClock onResendPress={onResendPress} />

              {/* <p className="text-center " style={{ marginTop: "128px" }}><span className="colorblue cursor" onClick={Terms}>T&C</span> and<span className="colorblue cursor" onClick={PrivacyPolicy}> Privacy Policy</span></p> */}
            </form>
          </div>
          <div className="mb-o tncdiv bottom-0 start-0 end-0 m-auto">
            <p className="text-center ">
              <span className="colorblue cursor" onClick={Terms}>
                T&C
              </span>{' '}
              and
              <span className="colorblue cursor" onClick={PrivacyPolicy}>
                {' '}
                Privacy Policy
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpVerification;
