import {
  SET_EXAM_ID_DATA,
  SET_NOT_SYNCED_DATA,
  SET_INSTRUCTIONS,
  SELECTED_LANGUAGE,
  SET_OPTION_VALUE_FALSE,
  SET_EXAM_STATUS_CHANGE_VALUE,
  SET_EXAM_STATUS_SECTION_CHANGED,
  SET_QUESTIONS_SELECTED_VALUE
} from '../../Types/examTypes';
import Services from '../../Api-Client/index';
import services from '../../Constant/service';
import endpoint from '../../Constant/endpoint';
import { showErrorSnackbar, showSuccessSnackbar } from './snackbarAction';
import { setParentCourse } from '../../Redux/Actions/AuthAction';
import { updateHomeMoreValues } from './homeAction';

export const notSyncedDataAction = (data) => ({
  type: SET_NOT_SYNCED_DATA,
  payload: data
});

export const examIdDataAction = (key, value) => ({
  type: SET_EXAM_ID_DATA,
  payload: { [key]: value }
});

export const setInstructionsAction = (data) => ({
  type: SET_INSTRUCTIONS,
  payload: data
});

export const selectedLanguageAction = (data) => ({
  type: SELECTED_LANGUAGE,
  payload: data
});

export const setOptionValueFalseAction = (data) => ({
  type: SET_OPTION_VALUE_FALSE,
  payload: data
});

export const setExamStatusValueChangeAction = (data) => ({
  type: SET_EXAM_STATUS_CHANGE_VALUE,
  payload: data
});

export const setExamStatusSectionChangeAction = (data) => ({
  type: SET_EXAM_STATUS_SECTION_CHANGED,
  payload: data
});

export const setExamQuestionSelectedAction = (data) => ({
  type: SET_QUESTIONS_SELECTED_VALUE,
  payload: data
});

export const getExam =
  (access_token, examId, callback, errorCallback) => async (dispatch, getState) => {
    const internet = getState().AuthReducer.internet;
    Services.setAuthorizationToken(access_token);
    if (!internet) {
      errorCallback();
    } else dispatch(updateHomeMoreValues({ isLoader: true }));
    Services.getRequest(
      endpoint.exam.getExam + '/' + examId,
      '',
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.status === Services.status_code.success) {
          const data = res?.data?.data;

          if (data) {
            dispatch({
              type: SET_INSTRUCTIONS,
              payload: data
            });
            callback(data);
          }
        } else {
          errorCallback();
        }
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        errorCallback();
      }
    );
  };

export const startExam = (examId, callback, ErrorCallback) => async (dispatch, getState) => {
  const internet = getState().AuthReducer.internet;
  if (!internet) {
    ErrorCallback();
  } else dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.exam.startExam,
    { examId, startedFrom: 1, startedOn: new Date().toString() },
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === Services.status_code.success) {
        callback(res?.data);
      } else {
        ErrorCallback(res?.data);
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const startExamV2 = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  const internet = getState().AuthReducer.internet;
  if (!internet) {
    ErrorCallback();
  } else dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.exam.startExamV2,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === Services.status_code.success) {
        callback(res?.data);
      } else {
        ErrorCallback(res?.data);
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const GetMyLearningData =
  (endPoint, callback, errorCallback) => async (dispatch, getState) => {
    const baseCourse = endPoint.replace(/\s/g, '_').toLowerCase();

    const internet = getState().AuthReducer.internet;

    if (!internet) {
      errorCallback();
    } else {
      dispatch(updateHomeMoreValues({ isLoader: true }));
      Services.getApiMyLearning(
        baseCourse,
        (res) => {
          dispatch(updateHomeMoreValues({ isLoader: false }));
          if (res.status === Services.status_code.success) {
            callback(res?.data);
          }
        },
        () => {
          dispatch(updateHomeMoreValues({ isLoader: false }));
        }
      );
    }
  };

export const getUnitTests = (data, callback, errorCallback) => async (dispatch, getExam) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.exam.getUnitTests,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res.msg));
        callback(res);
      } else if (res.statusCode != services.status_code.success) {
        errorCallback(res);
        dispatch(showErrorSnackbar(res.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback(error);
    }
  );
};

export const getExamById = (data, callback, errorCallback) => async (dispatch, getExam) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.exam.getExamById,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.data.statusCode === services.status_code.success) {
        dispatch(setParentCourse('exam', res.data.data));
        // dispatch(showSuccessSnackbar(res.data.msg));
        callback(res.data);
      } else if (res.data.statusCode != services.status_code.success) {
        errorCallback(res);
        dispatch(showErrorSnackbar(res.data.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback(error);
    }
  );
};

export const getExamListByType = (data, callback, errorCallback) => async (dispatch, getExam) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.exam.getExamListByType,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res.msg));
        callback(res);
      } else if (res.data.statusCode != services.status_code.success) {
        errorCallback(res);
        dispatch(showErrorSnackbar(res.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback(error);
    }
  );
};

export const getMockTestList = (data, callback, errorCallback) => async (dispatch, getExam) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.exam.getMockTestList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res.msg));
        callback(res);
      } else if (res.data.statusCode != services.status_code.success) {
        errorCallback(res);
        dispatch(showErrorSnackbar(res.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback(error);
    }
  );
};

export const getScholarshipList = (data, callback, errorCallback) => async (dispatch, getExam) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.exam.getScholarshipList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res.msg));
        callback(res);
      } else if (res.data.statusCode != services.status_code.success) {
        errorCallback(res);
        dispatch(showErrorSnackbar(res.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback(error);
    }
  );
};

export const getLiveScholarshipList =
  (data, callback, errorCallback) => async (dispatch, getExam) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.exam.getLiveScholarshipList,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res.msg));
          callback(res);
        } else if (res.data.statusCode != services.status_code.success) {
          errorCallback(res);
          dispatch(showErrorSnackbar(res.msg));
        }
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getOnlineTest = (data, callback, errorCallback) => async (dispatch, getExam) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getOnlineTest,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res.msg));
        callback(res.data);
      } else if (res.data.statusCode != services.status_code.success) {
        errorCallback(res);
        dispatch(showErrorSnackbar(res.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback(error);
    }
  );
};
export const getExamList = (data, callback, errorCallback) => async (dispatch, getExam) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getExamList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res.msg));
        callback(res.data);
      } else if (res.data.statusCode != services.status_code.success) {
        // errorCallback(res);
        dispatch(showErrorSnackbar(res.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // errorCallback(error);
    }
  );
};
export const getExamTopic = (data, callback, errorCallback) => async (dispatch, getExam) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getExamTopic,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res.msg));
        callback(res.data);
      } else if (res.data.statusCode != services.status_code.success) {
        // errorCallback(res);
        dispatch(showErrorSnackbar(res.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // errorCallback(error);
    }
  );
};
export const getExamSubject = (data, callback, errorCallback) => async (dispatch, getExam) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getExamSubject,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res.msg));
        callback(res.data);
      } else if (res.data.statusCode != services.status_code.success) {
        // errorCallback(res);
        dispatch(showErrorSnackbar(res.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // errorCallback(error);
    }
  );
};
export const submitExam = (data, callback, errorCallback) => async (dispatch, getExam) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.exam.submitExam,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res.msg));
        callback(res.data);
      } else if (res.data.statusCode != services.status_code.success) {
        errorCallback(res);
        dispatch(showErrorSnackbar(res.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback(error);
    }
  );
};

export const scholarshipExamRemindMe =
  (data, callback, errorCallback) => async (dispatch, getExam) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.exam.scholarshipRemindMe,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res.msg));
          callback(res.data);
        } else if (res.data.statusCode != services.status_code.success) {
          errorCallback(res);
          dispatch(showErrorSnackbar(res.msg));
        }
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };
