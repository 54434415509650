import * as React from 'react';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Screen/home';
import SignIn from './Screen/signIn';
import OtpVerification from './Screen/otpVerification';
import CreateAccount from './Screen/createAccount';
import UserVerification from './Screen/userVerification';
import MobileVerification from './Screen/mobileVerification';
import OtpSuccess from './Screen/otpSuccess';
import OtpError from './Screen/otpError';
import ExamInstruction from './Screen/examInstruction';
import Test from './Screen/test';
import SuccessSnackbar from './Component/successSnackbar';
import ErrorSnackbar from './Component/errorSnackbar';
import ReviewExam from './Screen/reviewExam';
import DetailResult from './Screen/detailResult';
import SelectTest from './Screen/selectTest';
import Dashboard from './Screen/dashboard';
import CourseDropdown from './Component/courseDropdown';
import DashboardMenu from './Screen/dashboardMenu';
import OnlineCourse from './Screen/onlineCourse';
import LiveClass from './Screen/liveClass';
import FreeLectures from './Screen/freeLectures';
import CourseDetails from './Screen/courseDetails';
import MyCart from './Screen/myCart';
import StudyMaterialDetails from './Screen/studyMaterialDetails';
import CourseList from './Module/courseList';
import LiveLectureVideo from './Screen/liveLectureVideo';
import LectureSeries from './Screen/lectureSeries';
import PlaceOrder from './Screen/placeOrder';
import Address from './Screen/address';
import PaidUser from './Screen/paidUser';
import PrivacyPolicy from './Screen/privacyPolicy';
import RefundPolicy from './Screen/refundPolicy';
import AdmitCardScreen from './Screen/admitCard';
import HomePage from './Screen/homePage';
import Terms from './Screen/terms';
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';
import Notification from './Screen/notification';
import ClassroomSignIn from './Screen/classroomsignin ';
import ClassroomOtpVerification from './Screen/classroomotpVerification';
import ClassroomRegistration from './Screen/classroomRegistration';
import MediaPlayer from './Screen/mediaPlayer';
import VideoPlayer from './Screen/videoPlayer';
import Career from './Screen/career';
import AboutUs from './Screen/aboutUs';
import AboutCourse from './Screen/aboutCourse';
import CourseDashboard from './Screen/courseDashboard';
import './App.css';
import service from './Constant/service';
import WOW from 'wowjs';
import { setInitialState, Logout, updateMoreValues } from './Redux/Actions/AuthAction';
import services from './Constant/service';
import { useDispatch } from 'react-redux';
import TestVideoPlayer from './Screen/testVideoPlayer';
import ClassroomCoachingDetails from './Screen/classroomCoachingDetails';
import ClassroomCoachingDashbord from './Screen/classroomCoachingDashbord';
import ClassroomRegistrationMenu from './Screen/classroomRegistrationMenu';
import JobInIfas from './Screen/jobinIFAS';
import JobDescription from './Screen/jobDescription';
import MoreCourse from './Screen/moreCourse';
import AptitudeCourses from './Screen/aptitudeCourses ';
import PdfViewer from './Component/pdfViewer';
import LocalImages from './Constant/LocalImages';
import KailashChoudhary from './Screen/kailashChoudhary';
import DigambarSir from './Screen/digambarSir';
import AboutSubExam from './Screen/aboutSubExam';
import AboutExamState from './Screen/aboutExamState';
import PageNotFound from './Screen/pageNotFound';
// import ServerErrorPage from './Screen/serverErrorPage';
import FixFooter from './Component/fixFooter';
function App() {
  const classRoomTitle = localStorage.getItem('metaTitle');
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, []);
  const { isLoader, ACCESS_TOKEN_NEW } = useSelector((state) => ({
    isLoader: state?.HomeReducer?.isLoader,
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));
  useEffect(() => {
    if (ACCESS_TOKEN_NEW === undefined || ACCESS_TOKEN_NEW === '' || ACCESS_TOKEN_NEW === null) {
      dispatch(updateMoreValues({ ACCESS_TOKEN_NEW: '' }));
    }
  }, [ACCESS_TOKEN_NEW]);

  return (
    <div className="App">
      {isLoader ? (
        <Backdrop sx={{ color: '#fff', zIndex: '10001' }} open>
          <span className="loader"></span>
        </Backdrop>
      ) : (
        ''
      )}

      <SuccessSnackbar />
      <ErrorSnackbar />
      <Routes>
        {ACCESS_TOKEN_NEW === undefined || ACCESS_TOKEN_NEW === '' || ACCESS_TOKEN_NEW === null ? (
          <Route
            path="/"
            element={<Home title="IFAS - India's No.1 AI App for IIT-JAM, CSIR, GATE & More" />}
          />
        ) : (
          <Route
            path="/"
            element={<HomePage title="IFAS - India's No.1 AI App for IIT-JAM, CSIR, GATE & More" />}
          />
        )}
        <Route
          path="Home"
          element={<HomePage title="IFAS - India's No.1 AI App for IIT-JAM, CSIR, GATE & More" />}
        />
        <Route path="SignIn/*" element={<SignIn />} />
        <Route path="OTPVerification/*" element={<OtpVerification />} />
        <Route path="CreateAccount/*" element={<CreateAccount />} />
        <Route path="UserVerification/*" element={<UserVerification />} />
        <Route path="MobileVerification/*" element={<MobileVerification />} />
        <Route path="OtpSuccess/*" element={<OtpSuccess />} />
        <Route path="OtpError/*" element={<OtpError />} />
        <Route path="ExamInstruction/*" element={<ExamInstruction />} />
        <Route path="Test/*" element={<Test />} />
        <Route path="SelectTest/*" element={<SelectTest />} />
        <Route path="ReviewExam/*" element={<ReviewExam />} />
        <Route path="DetailResult/*" element={<DetailResult />} />
        <Route path="Dashboard/*" element={<Dashboard />} />
        <Route path="CourseDropdown/*" element={<CourseDropdown />} />
        <Route path="DashboardMenu/*" element={<DashboardMenu />} />
        <Route path="OnlineCourse/*" element={<OnlineCourse />} />
        <Route path="LiveClass/*" element={<LiveClass />} />
        <Route path="FreeLectures/*" element={<FreeLectures />} />
        <Route path="CourseDetails/:courseName/:courseID" element={<CourseDetails />} />
        <Route path="MyCart/*" element={<MyCart />} />
        <Route
          path="StudyMaterialDetails/:courseName/:courseID"
          element={<StudyMaterialDetails title="StudyMaterialDetails" />}
        />

        <Route path="CourseList" element={<CourseList />} />
        <Route path="LiveLectureVideo" element={<LiveLectureVideo />} />
        <Route path="LectureSeries/:courseName/:courseID" element={<LectureSeries />} />
        <Route path="PlaceOrder" element={<PlaceOrder />} />
        <Route path="Address" element={<Address />} />
        <Route path="PaidUser/*" element={<PaidUser />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="AdmitCard" element={<AdmitCardScreen />} />
        <Route path="terms-conditions" element={<Terms />} />
        <Route path="Notification" element={<Notification />} />
        <Route path="ClassroomSignIn" element={<ClassroomSignIn />} />
        <Route path="ClassroomOtpVerification" element={<ClassroomOtpVerification />} />
        <Route path="ClassroomRegistration" element={<ClassroomRegistration />} />
        <Route path="Career" element={<Career />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path=":seoExamName" element={<AboutCourse />} />
        <Route path="AboutExamState/:seoExamName/:examId/:examName" element={<AboutExamState />} />
        <Route
          path="AboutCourse/:seoExamName/:examId/:examName/:seoSubExamName/:subExamID/:subExamName"
          element={<AboutCourse />}
        />
        <Route path="VideoPlayer" element={<VideoPlayer />} />
        <Route exact path="MediaPlayer" element={<MediaPlayer />} />
        <Route path="TestVideoPlayer" element={<TestVideoPlayer />} />
        <Route path="/:examName/:baseCourseName" element={<ClassroomCoachingDetails/>} />
        <Route
          path="/:examName/:baseCourseName"
          element={<ClassroomCoachingDetails title={classRoomTitle} />}
        />
        <Route
          path="/:examName/:baseCourseName-study-material/:courseID"
          element={<StudyMaterialDetails title="StudyMaterialDetails" />}
        />
        <Route
          path="/:examName/:baseCourseName/:subExamName-study-material/:courseID"
          element={<StudyMaterialDetails title="StudyMaterialDetails" />}
        />
        <Route path="/:examName/:baseCoursename-online-coaching" element={<CourseDashboard />} />
        <Route path="AboutSubExam/:seoExamName/:examId/:examName" element={<AboutSubExam />} />
        <Route path="ClassroomRegistrationMenu/*" element={<ClassroomRegistrationMenu />} />
        <Route path="Job-In-Ifas" element={<JobInIfas />} />
        <Route path="JobDescription" element={<JobDescription />} />
        <Route path="MoreCourse" element={<MoreCourse />} />
        <Route path="Aptitude-Courses" element={<AptitudeCourses />} />
        <Route path="PdfViewer" element={<PdfViewer />} />
        <Route path="DrKailashChoudhary" element={<KailashChoudhary />} />
        <Route path="DigambarJagtap" element={<DigambarSir />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {location.pathname === '/ExamInstruction' && '/Test' ? '' : <FixFooter />}
    </div>
  );
}

export default App;
