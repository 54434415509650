import React, { useEffect, useState, useRef } from 'react';
import '../App.css';
import Header from '../Module/header';
import CartCard from '../Module/cartCard';
import CouponCard from '../Module/couponCard';
import { LocalImages } from '../Constant';
import { removeToCart, getAddressList, uploadProfilePic } from '../Redux/Actions/homeAction';
import { useDispatch, useSelector } from 'react-redux';
import AddNewAddress from '../Module/addNewAddress';
import EditAddress from '../Module/editAddress';
import ChangeAddress from '../Module/changeAddress';
import { updateMoreValues } from '../Redux/Actions/AuthAction';
import { editProfile } from '../Redux/Actions/homeAction';
import { useNavigate } from 'react-router-dom';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import moment from 'moment';
import TermsCondition from '../Module/termsCondition';
function Address() {
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  var price = 0;
  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    email,
    mobileNo,
    profilePicture,
    dob,
    address,
    cartAddedData,
    cartListDetails,
    countryCode
  } = useSelector((state) => ({
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    email: state?.AuthReducer?.email,
    mobileNo: state?.AuthReducer?.mobileNo,
    profilePicture: state?.AuthReducer?.profilePicture,
    countryCode: state?.AuthReducer?.countryCode,
    dob: state?.AuthReducer?.dob,
    address: state?.HomeReducer?.address,
    cartAddedData: state?.HomeReducer?.cartAddedData,
    cartListDetails: state?.HomeReducer?.cartListDetails
  }));
  const [emailAddress, setEmailAddress] = useState(email);
  const [dateOfBirth, setDateOfBirth] = useState(moment(dob).format('YYYY-MM-DD'));
  const { hasStudyMaterial } = cartAddedData;
  const [open, setOpen] = useState(false);
  const [changeAddress, setchangeAddress] = useState(false);
  const [openterms, setOpenTerms] = useState(false);
  const [edit, edited] = useState(false);
  const [editId, setEditId] = useState('');
  const twelveYearFromNow = new Date();
  twelveYearFromNow.setFullYear(twelveYearFromNow.getFullYear() - 12);
  useEffect(() => {
    if (hasStudyMaterial) {
      dispatch(
        getAddressList(
          () => {},
          () => {}
        )
      );
    }
  }, []);

  const handleClose = () => {
    setOpenTerms(false);
  };

  const handleClick = (event) => {
    setOpenTerms(true);
  };

  const handleImageUpload = (e) => {
    const [file] = e?.target?.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
    dispatch(
      uploadProfilePic(
        e.target.files,
        () => {},
        () => {}
      )
    );
  };
  const placeorderroute = () => {
    if (!checkDisable()) {
      if (isChecked) {
        if (emailAddress && dateOfBirth) {
          const data = {
            email: emailAddress,
            dob: dateOfBirth
          };
          dispatch(
            editProfile(
              data,
              async (res) => {
                await dispatch(
                  updateMoreValues({
                    email: res?.data?.email,
                    dob: res?.data?.dob
                  })
                );
                // await dispatch(showSuccessSnackbar(res?.msg));
                await navigate('/PlaceOrder');
              },
              () => {}
            )
          );
        } else if (emailAddress) {
          const data = {
            email: emailAddress
          };
          dispatch(
            editProfile(
              data,
              async (res) => {
                await dispatch(updateMoreValues({ email: res?.data?.email }));
                // await dispatch(showSuccessSnackbar(res?.msg));
                await navigate('/PlaceOrder');
              },
              () => {}
            )
          );
        } else if (dateOfBirth) {
          const data = {
            dob: dateOfBirth
          };
          dispatch(
            editProfile(
              data,
              async (res) => {
                await dispatch(updateMoreValues({ dob: res?.data?.dob }));
                // await dispatch(showSuccessSnackbar(res?.msg));
                await navigate('/PlaceOrder');
              },
              () => {}
            )
          );
        } else {
          navigate('/PlaceOrder');
        }
      } else {
        dispatch(showErrorSnackbar('Please select T&C'));
      }
    } else dispatch(showErrorSnackbar('Please input valid email'));
  };

  const myAddress = (e) => {
    e.preventDefault();
    setchangeAddress(true);
  };
  const myAddressClose = () => {
    setchangeAddress(false);
  };
  const Terms = () => {
    navigate('./terms');
    // window.location.href = 'terms-conditions';
  };

  const setOnRemove = (id) => {
    dispatch(removeToCart({ cartId: id }));
  };

  useEffect(() => {
    if (cartListDetails.length === 0) {
      navigate('/MyCart');
    }
  }, [cartListDetails]);
  for (let i = 0; i < cartListDetails?.length; i++) {
    if (cartListDetails[i]?.discPer) price = price + cartListDetails[i]?.discountedPrice;
    else price = price + cartListDetails[i]?.price;
  }

  const handleChange = (event) => {
    if (event?.target?.checked) {
      if (!hasStudyMaterial) {
        if (!checkDisable() && dateOfBirth && profilePicture) {
          setIsChecked(true);
        }
      } else {
        if (
          !checkDisable() &&
          dateOfBirth &&
          profilePicture &&
          !(Object.keys(address).length === 0)
        ) {
          setIsChecked(true);
        }
      }
      if (!checkDisable()) {
      } else {
        dispatch(showErrorSnackbar('Please input valid email'));
        event.target.checked = false;
      }
      if (dateOfBirth) {
      } else {
        dispatch(showErrorSnackbar('Please provide date of birth'));
        event.target.checked = false;
      }

      if (profilePicture) {
      } else {
        dispatch(showErrorSnackbar('Please upload profile picture'));
        event.target.checked = false;
      }
      if (hasStudyMaterial && !(Object.keys(address).length === 0)) {
      } else if (hasStudyMaterial) {
        dispatch(showErrorSnackbar('Please provide shipping address'));
        event.target.checked = false;
      }
    } else {
      event.target.checked = false;
      setIsChecked(false);
    }
    setIsSubscribed((current) => !current);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const editAddress = (id) => {
    edited(true);
    setEditId(id);
  };

  var emails =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  function allemail(inputtxt) {
    if (inputtxt.match(emails)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input valid email'));
      return false;
    }
  }

  const checkDisable = () => {
    if (emailAddress === '' || !allemail(emailAddress)) return true;
  };

  const handleOnChangeEmailText = (prevEmailInputText) => {
    setEmailAddress(prevEmailInputText.target.value);

    // var emails =
    //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // // if (prevEmailInputText.target.value.match(emails)) {
    // //   //setEmailAddress(prevEmailInputText.target.value)
    // //   //dispatch(showSuccessSnackbar('valid email'));
    // // } else {
    // //   // dispatch(showErrorSnackbar('Please input valid email'));
    // // }
    // if (prevEmailInputText.target.value.match(emails)) {
    //   return true;
    // } else {
    //   dispatch(showErrorSnackbar('Please input valid email'));
    //   return false;
    // }
  };
  return (
    <div>
      <Header />
      <div className="py-4 backimg">
        <div className="customcontainer">
          <h3 className="fw-normal px-2">Mailing Address</h3>
          <div className="row">
            <div className="col-md-6 Addressleftside pe-lg-5 mt-3 pt-1">
              <div className="boxshadow p-3 rounded bgwhite">
                <p className="fw-bold mb-2">Personal Details</p>
                <div className="row">
                  <div className="col-8 ps-0">
                    <div className="d-flex row font12">
                      <div className="col-sm-6 ps-0">
                        <label className="mb-2 d-block">First Name</label>
                        <input
                          type="text"
                          placeholder={firstName}
                          className="border-0 border-bottom w-100"
                          disabled
                        />
                      </div>
                      <div className="col-sm-6 ps-0">
                        <label className="mb-2 d-block">Last Name</label>
                        <input
                          type="text"
                          placeholder={lastName}
                          className="border-0 border-bottom w-100"
                          disabled
                        />
                      </div>
                      <div className="col-sm-12 mt-2 ps-0">
                        <label className="mb-2 d-block">Mobile Number</label>
                        <input
                          type="text"
                          placeholder={`${countryCode}-${mobileNo}`}
                          className="border-0 border-bottom w-100"
                          disabled
                        />
                      </div>
                      <div className="col-sm-12 mt-2 ps-0 position-relative">
                        <label className="mb-2 d-block">Email Address</label>

                        <input
                          type="email"
                          placeholder="Enter Mail ID"
                          ref={(ref) => (inputRefs.current[0] = ref)}
                          className={
                            emails === false
                              ? 'border-0 border-bottom border-warning w-100'
                              : 'border-0 border-bottom w-100'
                          }
                          value={emailAddress}
                          maxLength="40"
                          onChange={(value) => handleOnChangeEmailText(value)}
                        />
                        <img
                          src={LocalImages.edit}
                          onClick={() => inputRefs?.current[0].focus()}
                          alt="Edit"
                          style={{
                            width: '10px',
                            position: 'absolute',
                            right: '17px',
                            bottom: '4px'
                          }}
                        />
                      </div>
                      {!emailAddress ? (
                        <div className="ps-0 font10 text-danger">Please Enter Email ID </div>
                      ) : (
                        ''
                      )}
                      <div className="col-sm-12 mt-2 ps-0">
                        <label className="mb-2 d-block">Date Of Birth</label>
                        <input
                          type="date"
                          max={moment(twelveYearFromNow).format('YYYY-MM-DD')}
                          className="border-0 border-bottom w-100"
                          value={dateOfBirth}
                          onChange={(e) => setDateOfBirth(e.target.value)}
                        />
                      </div>
                      {!dateOfBirth ? (
                        <div className="ps-0 font10 text-danger">Please Enter Date Of Birth </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={handleImageUpload}
                        ref={imageUploader}
                        style={{
                          display: 'none'
                        }}
                      />
                      <div
                        className="rounded"
                        style={{
                          minHeight: '80px',
                          width: '80px',
                          border: '1px solid var(--lightgray)',
                          position: 'relative',
                          cursor: 'pointer'
                        }}
                        onClick={() => imageUploader.current.click()}>
                        {profilePicture ? (
                          <img
                            ref={uploadedImage}
                            src={profilePicture}
                            style={{
                              width: '100%'
                            }}
                            alt="Profile Pics"
                          />
                        ) : (
                          <img
                            ref={uploadedImage}
                            src={LocalImages.ulpoad}
                            style={{
                              width: '100%'
                            }}
                            alt="Profile Pics"
                          />
                        )}
                      </div>
                      {!profilePicture ? (
                        <div className="ps-0 font10 text-danger">Please upload Your Photo </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {hasStudyMaterial ? (
                <div className="boxshadow font12 p-3 py-2 rounded mt-3 bgwhite">
                  {!(JSON.stringify(address) === '{}') ? (
                    <>
                      <p className="mb-2 fw-bold">Shipping Address</p>
                      <p className="colorblue mb-2 fw-bold d-flex align-items-center text-break ">
                        <LocationOnIcon
                          style={{ width: '15px' }}
                          defaultChecked={address?.isDefault}
                        />
                        <span className="ms-1">Home</span>
                      </p>
                      <div className="mb-2 text-break ">
                        <p className="mb-0 fw-bold">{address?.label}</p>
                        <p className="mb-0">{address?.line1}</p>
                        <p className="mb-0">{address?.line2}</p>
                        <p className="mb-0">{address?.landmark} </p>
                        <p className="mb-0">
                          {address?.city?.name}, {address?.pincode}
                        </p>
                        {address?.alternatePhoneNumber ? (
                          <p className="mb-0">Phone Number: +91-{address?.alternatePhoneNumber}</p>
                        ) : (
                          ''
                        )}
                      </div>
                      <div
                        className="border d-inline font10 px-2 py-1 mt-2 rounded cursor"
                        onClick={() => editAddress(address?._id)}>
                        <img src={LocalImages.edit} alt="Edit" style={{ width: '10px' }} />
                        <span className="ms-1 rounded">Edit</span>
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <button
                          type="button"
                          className="btn btn-outline-secondary py-1 font12"
                          onClick={myAddress}>
                          Change Address
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary py-1 font12"
                          onClick={handleOpen}>
                          Add New Address
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="mb-2 fw-bold">Shipping Address</p>
                      <button
                        type="button"
                        className="btn btn-outline-primary py-1 font12"
                        onClick={handleOpen}>
                        Add New Address
                      </button>
                    </>
                  )}
                </div>
              ) : (
                ''
              )}
              <div className="d-flex mt-3">
                <input
                  type="checkbox"
                  className="me-2 cursor"
                  value={isSubscribed}
                  onChange={handleChange}
                  id="subscribe"
                  name="subscribe"
                />
                by selecting you agree with our{' '}
                <span className="colorblue ms-1 cursor" onClick={handleClick}>
                  <u>T& C</u>
                </span>
              </div>
              <TermsCondition open={openterms} handleClose={handleClose} />
            </div>
            <div className="col-md-6 padbottom-mob40">
              <div className="mb-3 checkoutHeight p-2">
                {cartListDetails &&
                  cartListDetails?.map((c, index) => {
                    return <CartCard key={index} data={c} setOnRemove={setOnRemove} />;
                  })}
              </div>
              <CouponCard
                btnname="Proceed To Checkout"
                disabled={
                  hasStudyMaterial
                    ? !isChecked ||
                      !emailAddress ||
                      !emailAddress.match(emails) ||
                      !dateOfBirth ||
                      !profilePicture ||
                      Object.keys(address).length === 0
                    : !isChecked ||
                      !emailAddress ||
                      !emailAddress.match(emails) ||
                      !profilePicture ||
                      !dateOfBirth
                }
                setOnClick={placeorderroute}
                Price={price}
                itemCount={cartListDetails?.length}
              />
            </div>
          </div>
        </div>
      </div>
      {open ? <AddNewAddress open={setOpen} /> : null}
      {edit ? <EditAddress edit={edited} open={setOpen} editId={editId} /> : null}
      <ChangeAddress open={changeAddress} handleClose={myAddressClose} addressId={address?._id} />
    </div>
  );
}
export default Address;
