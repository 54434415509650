import React, { useEffect, useState } from 'react';
import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Lottie from 'react-lottie';
import orderCompleted from '../Assets/Images/JSON/ordercompleted.json';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { useDispatch } from 'react-redux';
import { validateFullName, validateIsNumberOnly, checkValidPincode } from '../Utils/validators';
import { validateFullNameErrMsg, validateIsNumberOnlyErrMsg } from '../Utils/errormsg';
import { sendOTP, getCountry } from '../Redux/Actions/AuthAction';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import InputField from '../Component/inputField';
import { getstart } from '../Redux/Actions/homeAction';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
function TalktoExpert(props) {
  const [blank, setblank] = useState(true);
  const [fname, setfullname] = useState('');
  const [mobno, setmobno] = useState('');
  const [successpopup, setsuccesspopup] = useState(false);
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState({ _id: '', name: '', callingCode: '', flag: '' });
  const [countryData, setCountryData] = useState([]);
  const [number, setNumber] = useState('');
  const [countrycodeData, setCountries] = useState([]);
  const [inputText, setInputText] = useState('');
  const [show, setShow] = useState(false);
  const country = () => {
    setShow(!show);
    setInputText('');
    setCountries(countryData);
  };
  const handleClose = () => {
    setShow(false);
    setsuccesspopup(false);
    setInputText('');
    setCountries(countryData);
  };

  const { firstName, lastName, email, mobileNo } = useSelector((state) => ({
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    mobileNo: state?.AuthReducer?.mobileNo
  }));

  const onSearchTextChange = (value) => {
    const filteredCountries = countrycodeData.filter((item) => {
      const countryName = item.name.toLowerCase();
      const countryCodeNew = item.callingCode.toLowerCase();
      const queryString = value.toLowerCase();
      return countryName.indexOf(queryString) > -1 || countryCodeNew.indexOf(queryString) > -1;
    });

    setInputText(value);
    setCountries(filteredCountries);
  };
  const currentURLarr = useLocation().pathname.split('/');
  let link = currentURLarr[currentURLarr.length - 1];

  useEffect(() => {
    if (link !== '') {
      setfullname(`${firstName} ${lastName}`);
      setmobno(`${mobileNo}`);
    }
    dispatch(
      getCountry(
        (data) => {
          setCountryData(data);
          setCountries(data);
          const filteredCountries = data.filter((item) => {
            const countryCodeNew = item.callingCode.toLowerCase();
            return countryCodeNew.indexOf('+91') > -1;
          });
          setCountryCode(filteredCountries[0]);
          setNumber('');
        },
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    if (fname.length != 0 && mobno.length == 10) {
      setblank(false);
    } else {
      setblank(true);
    }
  }, [fname, mobno]);

  function allnumeric(inputtxt) {
    var numbers = /^[0-9]+$/;
    if (inputtxt.match(numbers)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input numeric characters only'));
      return false;
    }
  }

  const checkDisable = () => mobno === '' || !allnumeric(mobno) || mobno.length !== 10;
  const handleOnChangePhoneText = (prevPhoneInputText) => {
    var numbers = /^[0-9]+$/;
    if (!prevPhoneInputText.target.value) setmobno(prevPhoneInputText.target.value);
    else if (prevPhoneInputText.target.value.match(numbers))
      setmobno(prevPhoneInputText.target.value);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
  };

  const setfname = (e) => {
    if (validateFullName(e.target.value)) setfullname(e.target.value);
    else dispatch(showErrorSnackbar(validateFullNameErrMsg));
  };

  const submitData = () => {
    if (!checkDisable()) {
      const data = {
        isGetStarted: true,
        mobileNumber: mobno,
        fullName: fname,
        countryCode: countryCode.callingCode
      };
      dispatch(
        getstart(data, () => {
          setsuccesspopup(true);
        })
      );
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orderCompleted,
    renderer: 'svg'
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={() => {
        if (link === '') {
          setfullname('');
          setmobno('');
        }
        setsuccesspopup();
        props.handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="modeldiv p-4 py-5 talktoexpertModel">
          {successpopup ? (
            <div>
              <div className="col mb-2 d-flex justify-content-end">
                <ClearOutlinedIcon
                  className="colorblue cursor"
                  onClick={() => {
                    if (link === '') {
                      setfullname('');
                      setmobno('');
                    }
                    setsuccesspopup();
                    props.handleClose();
                  }}
                />
              </div>
              <div className="text-center">
                <Lottie options={defaultOptions} height={130} width={200}></Lottie>
                <h5 className="colorblue fw-bold mb-2 mt-4">Thank You! We Received Your Details</h5>
                <p className="">Our expert will contact you on below given details.</p>
                <p className="successcolor mb-2">{fname}</p>
                <p className="successcolor">+91-{mobno}</p>
              </div>
            </div>
          ) : (
            <div>
              <div className="col mb-2 d-flex justify-content-between ms-2 ">
                <h4 className="colorblue fw-bold">Call To Our Expert</h4>
                <ClearOutlinedIcon
                  className="colorblue cursor"
                  onClick={() => {
                    if (link === '') {
                      setfullname('');
                      setmobno('');
                    }
                    setsuccesspopup();
                    props.handleClose();
                  }}
                />
              </div>
              <p className=" ms-2 ">
                Experts answer all your questions about the goal and course subscription
              </p>
              {link === '' ? (
                <form>
                  <div className="row w-100 m-auto">
                    <div className="col-auto mt-3">
                      <h6 className="colorblue fw-bold pb-2 mb-2">Your Contact Details</h6>
                      <input
                        type="text"
                        className="form-control mb-2 mt-2"
                        placeholder="Enter Your Full Name"
                        maxLength="50"
                        value={fname}
                        onChange={(e) => setfname(e)}
                      />

                      <div className="position-relative mt-3">
                        {/* <span className="indcode cursor" onClick={country}>
                          {countryCode.callingCode}
                          {show ? (
                            <ArrowDropUpIcon fontSize="large" />
                          ) : (
                            <ArrowDropDownIcon fontSize="large" />
                          )}
                        </span>
                        {show ? (
                          <div className="boxshadow position-absolute p-3 rounded font12 bgwhite countrypopup">
                            <div className="d-flex justify-content-between">
                              <p className="colorblue fw-bold">Select a Country</p>
                              <ClearOutlinedIcon
                                className="colorblue cursor"
                                onClick={handleClose}
                              />
                            </div>
                            <div className="position-relative d-flex W-100 inputpadding">
                              <SearchIcon className="position-absolute text-secondary m-2" />
                              <InputField
                                value={inputText}
                                setInputText={(value) => onSearchTextChange(value)}
                                className="w-100"
                              />
                            </div>
                            <div className="row font11">
                              <div className="col-sm-8 countryflow">
                                {countrycodeData.map((c) => (
                                  <div
                                    onClick={() => {
                                      setCountryCode(c);
                                      setShow(false);
                                      setInputText('');
                                      setCountries(countryData);
                                    }}
                                    className="row d-flex cursor">
                                    <div className="col-3">
                                      <img src={c.flag} className="m-1 img-fluid" />{' '}
                                    </div>
                                    <div
                                      className={
                                        countryCode.callingCode == c.callingCode
                                          ? 'colorblue col-3'
                                          : 'col-3'
                                      }>
                                      {' '}
                                      {c.callingCode}
                                    </div>
                                    <div
                                      className={
                                        countryCode.callingCode == c.callingCode
                                          ? 'colorblue col-6'
                                          : 'col-6'
                                      }>
                                      {' '}
                                      {c.name}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ) : null} */}
                        <span className="indcode cursor">
                          + 91
                          {show ? (
                            <ArrowDropUpIcon fontSize="large" />
                          ) : (
                            <ArrowDropDownIcon fontSize="large" />
                          )}
                        </span>
                        <input
                          setCountryCode={(value) => setCountryCode(value)}
                          countryCode={countryCode}
                          countryData={countryData}
                          type="text"
                          className="form-control expertphoneinput"
                          placeholder="Enter Your Phone Number"
                          value={mobno}
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          id="phone"
                          maxLength="10"
                          minLength={10}
                          onChange={(value) => handleOnChangePhoneText(value)}
                        />
                      </div>
                    </div>
                    {fname && mobno ? (
                      <div className="d-flex justify-content-evenly mt-5">
                        <button
                          type="button"
                          className="btn btn-primary px-5"
                          disabled={blank}
                          onClick={submitData}>
                          Continue
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-evenly mt-5">
                        <button
                          type="button"
                          className="btn btn-secondary px-5"
                          disabled={blank}
                          onClick={submitData}>
                          Continue
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              ) : (
                <form>
                  <div className="row w-100">
                    <div className="col-12 mt-3">
                      <h6 className="colorblue fw-bold pb-2 mb-2">Your Contact Details</h6>
                      <div className="d-flex">
                        <p className="fw-bold lablename">Full name :</p>
                        <p className="fw-bold mb-2 ">{fname}</p>
                      </div>
                      <div className="d-flex">
                        <p className="fw-bold lablename">Phone Number :</p>
                        <p className="fw-bold mb-2 ">+91-{mobno}</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly mt-5">
                      <button type="button" className="btn btn-primary px-5" onClick={submitData}>
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

export default TalktoExpert;
