import React from 'react';
import '../App.css';
import VideoLecture from '../Component/videoLecture';
import { LocalImages } from '../Constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { timeConvert } from '../Utils/utils';
import { useDispatch } from 'react-redux';
import { getRecordedPaidDetails } from '../Redux/Actions/homeAction';
import { updateMoreValues } from '../Redux/Actions/AuthAction';

function Topics() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const topics = location.state.topics;
  const title = location.state.title;
  const subjectTopicInfo = location.state.subjectTopicInfo;
  const onPressVideo = () => {
    window.location.href = 'Topics';
  };
  const newTopic = topics?.map((item) => {
    const newItem = item?.subTopics?.map((innerItem) => {
      const newLecture = innerItem?.lectureTitle?.sort((a, b) => {
        return a?.order - b?.order;
      });

      return { ...innerItem, lectureTitle: newLecture };
    });
    return { ...item, subTopics: newItem };
  });

  const goVideo = (lectureId) => {
    dispatch(
      getRecordedPaidDetails(lectureId, async (data) => {
        if (data) {
          await dispatch(updateMoreValues({ recordedVideoData: data }));
          await navigate('/LiveLectureVideo', {
            state: { lectureId: lectureId, recordedData: data, subjectTopicInfo: subjectTopicInfo }
          });
          await navigate(0);
        }
      })
    );
  };
  return (
    <div className="rightPanel scrollbar me-1">
      <div>
        <h5>{title}</h5>
      </div>
      {newTopic.map((c) => (
        <div className="row" key={c?._id}>
          <div className="col-md-5">
            <div className="mt-3 lectureheight p-2 cursor ">
              <VideoLecture
                title={c.name}
                lectcount={c.videoCount}
                duration={c.duration}
                onpress={onPressVideo}
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="boxshadow lectureheight mt-4 p-3">
              <div className="accordion" id={'accordionExample' + c?._id}>
                {c.subTopics?.map((d) => (
                  <div className="accordion-item border mb-2" key={d?._id}>
                    <h2 className="accordion-header" id={'headingOne' + d?._id}>
                      <button
                        className="accordion-button py-2 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={'#collapseOne' + d?._id}
                        aria-expanded="true"
                        aria-controls={'collapseOne' + d?._id}>
                        <div>
                          <div className="">{d.name}</div>
                          <div className="font12 mt-2">
                            {' '}
                            <img
                              src={LocalImages.videoicon}
                              style={{ width: '15px' }}
                              alt="Video"
                            />
                            <span className="ms-2">
                              {d.videoCount} Video | {timeConvert(parseInt(d.duration))}
                            </span>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={'collapseOne' + d?._id}
                      className="accordion-collapse collapse"
                      aria-labelledby={'headingOne' + d?._id}
                      data-bs-parent={'#accordionExample' + c?._id}>
                      <div className="accordion-body">
                        {d.lectureTitle?.map((e) => (
                          <div
                            key={e?.lectureId}
                            className="boxshadow rounded p-2 px-4 mb-3 cursor"
                            onClick={() => {
                              goVideo(e.lectureId);
                            }}>
                            <div className="colorblue">{e.lectureTitle}</div>
                            <div className="font12">{timeConvert(parseInt(e.duration))}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Topics;
