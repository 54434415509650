import React, { useEffect, useState } from 'react';
import '../App.css';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import '../Assets/CSS/signIn.css';
import Submitbtn from '../Component/submitBtn';
import InputField from '../Component/inputField';
import CustomDropdown from '../Component/customDropdown';
import { useDispatch, useSelector } from 'react-redux';
import service from '../Constant/service';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
  updateMoreValues,
  classroomRegister,
  examTypeClassroom,
  getBatchDate,
  getState,
  getCourse,
  getCountry,
  getBranch,
  getBatchListFilter,
  examType,
  getClassroomBaseCourseExam,
  patchclassroom
} from '../Redux/Actions/AuthAction';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LocalImages } from '../Constant';
import ClassRegistrationPopup from '../Module/classroomRegistrationPopup';
import { validateNameNumberNotAllowedErrMsg } from '../Utils/errormsg';
import { NavLink } from 'react-bootstrap';
import { validateFullName } from '../Utils/validators';
import ClassroomDropdown from '../Component/classroomDropdown';
import ClassroomDropdownNew from '../Component/classroomDropdownNew';
import { classRoomPlaceorder } from '../Redux/Actions/homeAction';
import axios from 'axios';
function ClassroomRegisterPopup(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [fname, setfname] = useState('');
  const [lname, setlname] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [classroomCourse, setClassroomCourse] = useState('');
  const [statename, setstatename] = useState('');
  const [branchName, setBranchName] = useState('');
  const [batchDate, setBatchDate] = useState('');

  const [stateData, setStateList] = useState([]);
  const [classroomCoursesList, setClassroomCoursesList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [batchList, setBatchList] = useState([]);

  const [registrationSuccess, setregistrationSuccess] = useState(false);
  const [resetExamName, setResetExamName] = useState('');
  const [resetStateName, setResetStateName] = useState('');
  const [resetBranchName, setResetBranchName] = useState('');
  const [resetBatchDate, setResetBatchDate] = useState('');


  const {
    classroomBranchId,
    baseCourseName,
    classroomBranchName,
    examTypeId,
    examTypeName,
    baseCourseId,
    countryCode,
    mobileNo,
    firstName,
    lastName,
    email,
    stateId
  } = useSelector((state) => ({
    classroomBranchId: state?.AuthReducer?.classroomBranchId,
    baseCourseName: state?.AuthReducer?.baseCourseName,
    classroomBranchName: state?.AuthReducer?.classroomBranchName,
    examTypeName: state?.AuthReducer?.examTypeName,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    examTypeId: state?.AuthReducer?.examTypeId,
    countryCode: state?.AuthReducer?.countryCode,
    mobileNo: state?.AuthReducer?.mobileNo,
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    email: state?.AuthReducer?.email,
    stateId: state?.AuthReducer?.stateId,
  }));

  function findId(data, classroomBranchId) {
    var categoryArray = data;
    for (var i = 0; i < categoryArray.length; i++) {
      if (categoryArray[i]._id === classroomBranchId) {
        return categoryArray[i];
      }
    }
  }

  useEffect(() => {
    if(email){
      setEmailAddress(email)
    }
  }, [email]);
  useEffect(() => {
    dispatch(
      getState((dataState) => {
        setStateList(dataState);
      })
    );
    dispatch(
      getBranch(
        (data) => {
          if (classroomBranchId) {
            var item = findId(data, classroomBranchId);
            setBranchName(item);
          }
          setBranchList(data);
        },
        () => {}
      )
    );
    if (classroomBranchId) {
      dispatch(
        getClassroomBaseCourseExam(
          { branchId: classroomBranchId },
          (response) => {
            let selectedData = response.map((item) => {
              if (item?.baseCourseId === baseCourseId && item?.examTypeId === examTypeId) {
                return item;
              }
            });
            setClassroomCoursesList(response);
            setClassroomCourse(selectedData[0]);
          },
          () => {}
        )
      );
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  function allCharacter(inputtxt) {
    var regex = /^[a-zA-Z ]{1,30}$/;
    if (inputtxt.match(regex)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input characters only'));
      return false;
    }
  }

  const onChangeFirstName = (value) => {
    if (validateFullName(value)) {
      setfname(value);
    } else {
      dispatch(showErrorSnackbar(validateNameNumberNotAllowedErrMsg));
    }
  };

  const onChangeLastName = (value) => {
    if (validateFullName(value)) {
      setlname(value);
    } else {
      dispatch(showErrorSnackbar(validateNameNumberNotAllowedErrMsg));
    }
  };

  var emails =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  function allemail(inputtxt) {
    if (inputtxt.match(emails)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input valid email'));
      return false;
    }
  }

  const OnChangeEmailAddress = (value) => {
    setEmailAddress(value);
  };

  const onChangeExamName = (value) => {
    setClassroomCourse(value);
    setResetStateName(!resetStateName);
    setResetBatchDate(!resetBatchDate);
    dispatch(
      getBatchListFilter(
        {
          baseCourseId: value?.baseCourseId,
          examTypeId: value?.examTypeId,
          branchId: branchName?._id
        },
        (res) => {
          setBatchList(res.data);
        },
        () => {}
      )
    );
  };

  const onChangeBranch = (value) => {
    setBranchName(value);
    setResetExamName(!resetExamName);
    setResetStateName(!resetStateName);
    setResetBatchDate(!resetBatchDate);

    dispatch(
      getClassroomBaseCourseExam(
        { branchId: value?._id },
        (response) => {
          setClassroomCoursesList(response);
        },
        () => {}
      )
    );
  };

  const onChangeBatchDate = (value) => {
    setBatchDate(value);
  };

  const checkDisable = () => {
    fname === '' || !allCharacter(fname) || !allCharacter(lname) || lname === '';
    if (emailAddress === '' || !allemail(emailAddress)) {
      return true;
    }
  };
  async function handleIssue(data={}){
    const config = {
        headers: {
            'Content-Type': 'text/plain'
        },
       responseType: 'text'
    };
    const response = await axios.post(process.env.REACT_APP_BASEURL+'/v1/users/ccavRequestHandler', data, config);
    const temp = JSON.parse(response.data);
    return temp
    
  }

  async function handleIssue(data={}){
    const config = {
        headers: {
            'Content-Type': 'text/plain'
        },
       responseType: 'text'
    };
    const response = await axios.post(process.env.REACT_APP_BASEURL+'/v1/users/ccavRequestHandler', data, config);
 
    let temp = response.data
    return temp
    
  }
  const onRegister = () => {
      const payload = {
       branchId: branchName?._id,
        batchId: batchDate._id,
        email: emailAddress,
      };
      dispatch(
        patchclassroom(
          payload,
          (response) => {
           
           const userData = response?.data;
          //  dispatch(showSuccessSnackbar(response?.msg));
         
           dispatch(updateMoreValues(response?.data));
          
           var orderNo, orderId;
              dispatch(
                classRoomPlaceorder('', async (res) => {
                 
                  props.handleClose();
                  orderNo = res?.orderData?.orderNo;
                  orderId = res?.orderData?.id;
                  var data = `merchant_id=${process.env.REACT_APP_CC_MERCHANT_ID}&order_id=${orderNo}&currency=INR&amount=1.0&redirect_url=${process.env.REACT_APP_BASEURL}/v1/users/ccavResponseHandler&cancel_url=${process.env.REACT_APP_BASEURL}/v1/users/ccavResponseHandler`;
                  const paymentResponse = await handleIssue(data);
                  window.location.href = paymentResponse?.data;
                })
              );
          },
          () => {}
        )
      );
  };


  return (
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className="model"
    open={props.open}
    onClose={props.handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500
    }}>
    <Fade in={props.open}>
        <div className="modeldiv p-4 py-3 talktoexpertModel">
        <div className="bgmob">
        <div className="col mb-2 d-flex justify-content-between ">
        <h5 className="text-center colorblue fw-bold boldfont">
            Book Your Regular Classroom Seat Now !!
          </h5>
            <ClearOutlinedIcon className="colorblue cursor" onClick={props.handleClose} />
          </div>
      <div className="row authDiv">
       
        <div className="col-lg-12  position-relative">
         
          <p className="text-center">
            Registration for{' '}
            <span className="colorblue">
              {countryCode.callingCode}-{mobileNo}{' '}
            </span>
          </p>

          <div className="mobileField mt-3">
            <form onSubmit={handleSubmit}>
              <div className="d-flex">
                <div className="me-2">
                  <label className="mb-2">First Name</label>
                  <InputField
                    type="text"
                    id="fname"
                    placeholder="Enter First Name"
                    value={firstName}
                    maxLength="30"
                   disabled={true}
                  />
                </div>
                <div>
                  <label className="mb-2">Last Name</label>
                  <InputField
                    type="text"
                    id="fname"
                    placeholder="Enter Last Name"
                    value={lastName}
                    maxLength="30"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-sm-12 mt-2 ps-0 ">
                <label className="mb-2 d-block">Email Address</label>
                <InputField
                  type="email"
                  placeholder="example@gmail.com"
                  className="border-0 border-bottom w-100"
                  maxLength="40"
                  value={emailAddress}
                  setInputText={OnChangeEmailAddress}
                />
              </div>
              <div className="mt-3">
                <label className="mb-2">IFAS Branch</label>
                <ClassroomDropdownNew
                  lableName="Select Branch Name"
                  setInputText={(value) => onChangeBranch(value)}
                  value={branchName}
                  options={branchList}
                  reset={resetBranchName}
                />
              </div>
              <div className="mt-3">
                <label className="mb-2">Exam Name</label>
                <ClassroomDropdownNew
                  key="E1"
                  lableName="Select Exam Name"
                  setInputText={(value) => {
                    onChangeExamName(value);
                  }}
                  value={classroomCourse}
                  options={classroomCoursesList}
                  reset={resetExamName}
                  defaultValue={classroomCourse?._id}
                />
              </div>
              <div className="mt-3">
                <label className="mb-2">Batch Date</label>
                <ClassroomDropdown
                  key="B1"
                  lableName="Select Batch Date"
                  setInputText={(value) => onChangeBatchDate(value)}
                  value={batchDate}
                  options={batchList}
                  type={'date'}
                  reset={resetBatchDate}
                  defaultValue={'Select Batch'}
                />
              </div>
              <div className="mt-4 text-center">
                <Submitbtn
                  name="Book My Seat Now"
                  disabled={
                    !emailAddress ||
                    !classroomCourse ||
                    !branchName ||
                    !batchDate
                  }
                  onPress={onRegister}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default ClassroomRegisterPopup;
