import React from 'react';
import { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';
import logo from '../Assets/Images/Home/logo.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function PdfViewer(props) {
  const link = sessionStorage.getItem('pdfLink');

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, []);

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <header className={'d-flex justify-content-between position-sticky'}>
        <div className="d-flex align-items-center">
          <img src={logo} alt="IFAS Logo" className="headerlogo" style={{ width: '120px' }} />
        </div>
      </header>
      <div className="pdfDiv">

        <Document
          file={link}
          onLoadSuccess={onDocumentLoadSuccess}
          onContextMenu={(e) => e.preventDefault()}
          className="pdf-container">
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((page) => (
              <Page pageNumber={page} />
            ))}
        </Document>
      </div>
    </div>
  );
}

export default PdfViewer;
