import React, { useEffect, useState } from 'react';
import '../App.css';
import PriceCard from '../Module/priceCard';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { updateHomeValues } from '../Redux/Actions/homeAction';
import { updateSubscriptionValues } from '../Redux/Actions/subscriptionPlanAction';
import { useDispatch } from 'react-redux';

function LecSeriesPopUp(props) {
  const { open } = props;
  const subObject = props.subscription?.filter((obj) => obj?.isRecommended === true);
  const dispatch = useDispatch();

  const [uncheckPrice, setUncheckPrice] = useState(0);
  const [lectureData, setLectureData] = useState({});
  useEffect(() => {
    setUncheckPrice(0);
    // setUncheckPrice(subObject[0]?.price)
    // if(open){
    //   setLectureData({subscriptionID: subObject[0]?._id,
    //     discountAmount: 0,
    //     price: subObject[0]?.price})
    // }
  }, [open]);

  const close = () => {
    setUncheckPrice(0);
    dispatch(updateHomeValues({ subscriptionID: '' }));
    props.handleClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="modeldiv p-4">
          <div className="col mb-2 d-flex justify-content-between ">
            <h6 className="colorblue fw-bold">{props.name}</h6>
            <ClearOutlinedIcon className="colorblue cursor" onClick={close} />
          </div>
          <div className="p-4 px-5">
            {props.subscription?.map((c, index) => (
              <PriceCard
                data={c}
                isLecture={true}
                setUncheckPrice={setUncheckPrice}
                setLectureData={setLectureData}
              />
            ))}
          </div>
          <div className="d-flex justify-content-evenly mt-5">
            <button
              type="button"
              disabled={uncheckPrice === 0}
              className={uncheckPrice === 0 ? 'btn btn-secondary px-5' : 'btn btn-primary px-5'}
              onClick={async () => {
                if (uncheckPrice > 0) {
                  await dispatch(
                    updateHomeValues({
                      subscriptionID: lectureData?.subscriptionID,
                      discountAmount: 0,
                      OriginalPrice: lectureData?.price
                    })
                  );
                  await dispatch(updateSubscriptionValues({ price: lectureData?.price }));
                  await props.handleClick(uncheckPrice, lectureData?.subscriptionID);
                } else await props.handleClose();
              }}>
              Save and Proceed
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default LecSeriesPopUp;
