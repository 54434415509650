// Validation functions start
export const containsSpecialChars = (str) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
};

export function containsNumber(str) {
  return /\d/.test(str);
}
export function hasWhiteSpace(str) {
  return /\s/g.test(str);
}

export function startsWithSpace(str) {
  return /^\s/.test(str);
}

export function startsWithNumber(str) {
  return /^\d/.test(str);
}

// export function contains

export function containsSpecialCharsOrNumberOrWhiteSpace(str) {
  if (containsSpecialChars(str) || containsNumber(str) || startsWithSpace(str)) {
    return true;
  }
  return false;
}

export function containsSpecialCharsOrStartsWithNumberOrWhiteSpace(str) {
  if (containsSpecialChars(str) || startsWithNumber(str) || startsWithSpace(str)) {
    return true;
  }
  return false;
}
export function containsAllowedSpecialCharsOrNumberOrWhiteSpace(str) {
  const AllowedSpecialChar = ['&', ':', ','];
  str = str.charAt(str.length - 1);
  if (containsSpecialChars(str)) {
    if (!AllowedSpecialChar.includes(str)) {
      return true;
    }
  }
}

export function validateFullName(str) {
  if (
    typeof str !== 'string' ||
    /[0-9]+/g.test(str) ||
    containsSpecialChars(str) ||
    startsWithSpace(str)
  ) {
    return false;
  }
  return true;
}

export function validateCouponCodeName(str) {
  if (
    startsWithNumber(str) ||
    startsWithSpace(str) ||
    hasWhiteSpace(str) ||
    containsSpecialChars(str)
  ) {
    return false;
  }
  return true;
}

export function validateIsFileImageType(e) {
  if (e.target.files[0].type.substring(0, 5) === 'image') {
    return true;
  }
  return false;
}

export function validateIsFilePDFType(e) {
  if (e.target.files[0].type === 'application/pdf') {
    return true;
  }
  return false;
}

export function validateisValidPrice(value) {
  return /^\d{0,5}(\.\d{1,2})?$/.test(value);
}

export const validateIsUrlPattern = (urlString) => {
  return urlString.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
};

export const isValidDiscount = (value) => {
  return /^[1-9][0-9]?$/.test(value);
};

export const validateLoginId = (str) => {
  if (hasWhiteSpace(str) || startsWithNumber(str)) {
    return false;
  }
  return true;
};

export const validatePassword = (str) => {
  if (startsWithSpace(str)) {
    return false;
  }
  return true;
};

export const validateEmailAddress = (str) => {
  return str.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validateIsNumberOnly = (value) => {
  return /\D/.test(value);
};
export const checkValidName = (name) => {
  let regName = /^[a-zA-Z]+$/;
  let regFullName = /^[a-zA-Z]+ [a-zA-Z]+$/;
  if (regName.test(name) || regFullName.test(name)) {
    return true;
  } else {
    return false;
  }
};

export const checkValidPincode = (name) => {
  let regName = /^[1-9][0-9]{5}$/;
  if (regName.test(name)) {
    return true;
  } else {
    return false;
  }
};

// Validation functions ends
