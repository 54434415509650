import React, { useEffect, useState } from 'react';
import '../App.css';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import orderCompleted from '../Assets/Images/JSON/ordercompleted.json';
import { useDispatch } from 'react-redux';

function RatingVideo({
  open,
  handleCloseRate,
  star,
  setStar,
  feedback,
  setFeedback,
  handleCLick,
  title,
  submitFeedback
}) {



  const validations = () => { 
    return (
      feedback?.length === 0||
      star === 0
      
    
    );
    
  };
 
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={handleCloseRate}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <div className="modeldiv p-4 py-5 talktoexpertModel">
          <div>
            <div className="col mb-2 d-flex justify-content-between ">
              <h5 className="colorblue boldfont">{title}</h5>
              <ClearOutlinedIcon className="colorblue cursor" onClick={handleCloseRate} />
            </div>
            <div className="col m-3 p-2 mt-0 pt-2">
              <div className="modal-body">
                <div className="p-3 d-flex justify-content-center">
                  <Box
                    sx={{
                      '& > legend': { mt: 0 }
                    }}>
                    <Rating
                      name="simple-controlled size-small"
                      value={star}
                      onChange={(event, newValue) => {
                        setStar(newValue?newValue:0);
                      }}
                    />
                  </Box>
                </div>
                <div> 
                  <textarea
                    className="form-control "
                    id="exampleFormControlTextarea1"
                    placeholder="Tell us what you loved about lecture"
                    onChange={(value) => setFeedback(value.target.value)}
                    value={feedback}
                    maxLength="500"
                    rows="6"></textarea>
                </div>
                <p className="pt-3">Your word makes IFAS a better place. You are the influence!</p>

                <div className="mx-auto d-flex justify-content-center pt-5">
                  <button onClick={() => handleCLick()} 
                  disabled={validations() || submitFeedback} 
                  className={`btn mb-3 submitbtn ${
                                  validations() || submitFeedback ? "btn-secondary" : "btn-primary"
                                }`}
                   
                   type="button">
                    Submit your feedback
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default RatingVideo;
