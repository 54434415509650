import React from 'react';
import { useState, useEffect } from 'react';
import '../Assets/CSS/signIn.css';
import Submitbtn from '../Component/submitBtn';
import InputField from '../Component/inputField';
import CustomDropdown from '../Component/customDropdown';
import { useDispatch, useSelector } from 'react-redux';
import service from '../Constant/service';
import {
  updateMoreValues,
  classroomRegister,
  examTypeClassroom,
  getBatchDate,
  getState,
  getCourse,
  getCountry,
  getBranch,
  getBatchListFilter,
  examType,
  getClassroomBaseCourseExam
} from '../Redux/Actions/AuthAction';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LocalImages } from '../Constant';
import ClassRegistrationPopup from '../Module/classroomRegistrationPopup';
import { validateNameNumberNotAllowedErrMsg } from '../Utils/errormsg';
import { NavLink } from 'react-bootstrap';
import { validateFullName } from '../Utils/validators';
import ClassroomDropdown from '../Component/classroomDropdown';
import ClassroomDropdownNew from '../Component/classroomDropdownNew';
import { classRoomPlaceorder } from '../Redux/Actions/homeAction';
import axios from 'axios';

function ClassroomRegistration(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { countryCode, mobileNo } = location?.state;
  const [fname, setfname] = useState('');
  const [lname, setlname] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [classroomCourse, setClassroomCourse] = useState('');
  const [statename, setstatename] = useState('');
  const [branchName, setBranchName] = useState('');
  const [batchDate, setBatchDate] = useState('');

  const [stateData, setStateList] = useState([]);
  const [classroomCoursesList, setClassroomCoursesList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [batchList, setBatchList] = useState([]);

  const [registrationSuccess, setregistrationSuccess] = useState(false);
  const [resetExamName, setResetExamName] = useState('');
  const [resetStateName, setResetStateName] = useState('');
  const [resetBranchName, setResetBranchName] = useState('');
  const [resetBatchDate, setResetBatchDate] = useState('');

  const {
    classroomBranchId,
    baseCourseName,
    classroomBranchName,
    examTypeId,
    examTypeName,
    baseCourseId
  } = useSelector((state) => ({
    classroomBranchId: state?.AuthReducer?.classroomBranchId,
    baseCourseName: state?.AuthReducer?.baseCourseName,
    classroomBranchName: state?.AuthReducer?.classroomBranchName,
    examTypeName: state?.AuthReducer?.examTypeName,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    examTypeId: state?.AuthReducer?.examTypeId
  }));

  function findId(data, classroomBranchId) {
    var categoryArray = data;
    for (var i = 0; i < categoryArray.length; i++) {
      if (categoryArray[i]._id === classroomBranchId) {
        return categoryArray[i];
      }
    }
  }

  useEffect(() => {
    dispatch(
      getState((dataState) => {
        setStateList(dataState);
      })
    );
    dispatch(
      getBranch(
        (data) => {
          if (classroomBranchId) {
            var item = findId(data, classroomBranchId);
            setBranchName(item);
          }
          setBranchList(data);
        },
        () => {}
      )
    );
    if (classroomBranchId) {
      dispatch(
        getClassroomBaseCourseExam(
          { branchId: classroomBranchId },
          (response) => {
            let selectedData = response.map((item) => {
              if (item?.baseCourseId === baseCourseId && item?.examTypeId === examTypeId) {
                return item;
              }
            });
            setClassroomCoursesList(response);
            setClassroomCourse(selectedData[0]);
          },
          () => {}
        )
      );
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const homepage = (e) => {
    window.location.href = '/';
  };
  const navigatelink = (e) => {
    navigate('/ClassroomSignIn', { state: { IsclassRoom: true } });
  };

  function allCharacter(inputtxt) {
    var regex = /^[a-zA-Z ]{1,30}$/;
    if (inputtxt.match(regex)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input characters only'));
      return false;
    }
  }

  const onChangeFirstName = (value) => {
    if (validateFullName(value)) {
      setfname(value);
    } else {
      dispatch(showErrorSnackbar(validateNameNumberNotAllowedErrMsg));
    }
  };

  const onChangeLastName = (value) => {
    if (validateFullName(value)) {
      setlname(value);
    } else {
      dispatch(showErrorSnackbar(validateNameNumberNotAllowedErrMsg));
    }
  };

  var emails =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  function allemail(inputtxt) {
    if (inputtxt.match(emails)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input valid email'));
      return false;
    }
  }

  const OnChangeEmailAddress = (value) => {
    setEmailAddress(value);
  };

  const onChangeExamName = (value) => {
    setClassroomCourse(value);
    setResetStateName(!resetStateName);
    setResetBatchDate(!resetBatchDate);
    dispatch(
      getBatchListFilter(
        {
          baseCourseId: value?.baseCourseId,
          examTypeId: value?.examTypeId,
          branchId: branchName?._id
        },
        (res) => {
          setBatchList(res.data);
        },
        () => {}
      )
    );
  };

  const onChangeBranch = (value) => {
    setBranchName(value);
    setResetExamName(!resetExamName);
    setResetStateName(!resetStateName);
    setResetBatchDate(!resetBatchDate);

    dispatch(
      getClassroomBaseCourseExam(
        { branchId: value?._id },
        (response) => {
          setClassroomCoursesList(response);
        },
        () => {}
      )
    );
  };

  const onChangeBatchDate = (value) => {
    setBatchDate(value);
  };

  const checkDisable = () => {
    fname === '' || !allCharacter(fname) || !allCharacter(lname) || lname === '';
    if (emailAddress === '' || !allemail(emailAddress)) {
      return true;
    }
  };
  async function handleIssue(data = {}) {
    const config = {
      headers: {
        'Content-Type': 'text/plain'
      },
      responseType: 'text'
    };
    const response = await axios.post(
      process.env.REACT_APP_BASEURL + '/v1/users/ccavRequestHandler',
      data,
      config
    );

    let temp = response.data;
    return temp;
  }
  const onRegister = () => {
    if (fname?.length < 3) {
      dispatch(showErrorSnackbar('First name should have minimum 3 charecter'));
    } else if (!checkDisable()) {
      const payload = {
        baseCourseId: classroomCourse?.baseCourseId,
        branchId: branchName?._id,
        batchId: batchDate._id,
        examTypeId: classroomCourse?.examTypeId,
        stateId: statename._id,
        mobileNo: mobileNo,
        email: emailAddress,
        firstName: fname,
        lastName: lname,
        countryId: countryCode._id
      };
      dispatch(
        classroomRegister(
          payload,
          (response) => {
            if (response?.statusCode === service.status_code.success) {
              const { userData } = response?.data;
              const accessToken = response?.data.jwtToken.access.token;
              const { baseCourseId, stateId } = userData;
              // dispatch(showSuccessSnackbar(response?.msg));
              const newArray = stateData;
              newArray.splice(0, 0, { _id: '', name: 'All India' });
              userData['countryCode'] = countryCode?.callingCode;
              dispatch(updateMoreValues(userData));
              dispatch(
                updateMoreValues({
                  stateData: newArray,
                  ACCESS_TOKEN_NEW: accessToken
                })
              );
              service.setAuthorizationToken(accessToken);
              dispatch(
                getState(
                  (dataState) => {
                    const newArray = dataState;
                    newArray.splice(0, 0, { _id: '', name: 'All India' });
                    dispatch(updateMoreValues({ stateData: newArray }));
                    const index = newArray.findIndex((item) => item._id === stateId);
                    dispatch(updateMoreValues({ stateName: newArray[index].name }));
                  },
                  () => {}
                )
              );
              dispatch(
                examType(
                  { baseCourseId, stateId },
                  (examTypeResponse) => {
                    dispatch(
                      updateMoreValues({
                        examType: examTypeResponse.data.examTypes,
                        talkToExpert: examTypeResponse.data.expertDetails
                      })
                    );
                    if (examTypeResponse.data.examTypes.length > 0) {
                      dispatch(
                        updateMoreValues({
                          examTypeId: examTypeResponse.data.examTypes[0]._id,
                          examTypeName: examTypeResponse.data.examTypes[0].name
                        })
                      );
                      if (examTypeResponse.data.examTypes[0].childrenId) {
                        dispatch(
                          updateMoreValues({
                            childrenName: examTypeResponse.data.examTypes[0].childrenId[0].name,
                            childrenId: examTypeResponse.data.examTypes[0].childrenId[0]._id
                          })
                        );
                      }
                    }
                  },
                  () => {}
                )
              );
              sessionStorage.setItem('ACCESS_TOKEN_NEW', accessToken);
              var orderNo, orderId;
              dispatch(
                classRoomPlaceorder('', async (res) => {
                  orderNo = res?.orderData?.orderNo;
                  orderId = res?.orderData?.id;
                  var data = `merchant_id=${process.env.REACT_APP_CC_MERCHANT_ID}&order_id=${orderNo}&currency=INR&amount=1000.0&redirect_url=${process.env.REACT_APP_BASEURL}/v1/users/ccavResponseHandler&cancel_url=${process.env.REACT_APP_BASEURL}/v1/users/ccavResponseHandler`;
                  const paymentResponse = await handleIssue(data);
                  window.location.href = paymentResponse?.data;
                })
              );
              // setregistrationSuccess(true);
              // navigate("/ClassroomCoachingDashbord" , { state: {  baseCourseName: val } });
            } else {
              dispatch(showSuccessSnackbar('wentWrong'));
            }
          },
          () => {}
        )
      );
    }
  };

  return (
    <div className="bgmob">
      <div className="d-block d-lg-none loginHeader text-start">
        <img
          src={LocalImages.logo}
          alt="IFAS Logo"
          className="logo cursor"
          onClick={homepage}
          style={{ width: '120px' }}
        />
      </div>
      <div className="row authDiv">
        <div className="d-none d-lg-block col-lg-7 leftSidediv">
          <div className="loginHeader ">
            <img
              src={LocalImages.logo}
              alt="IFAS Logo"
              className="logo cursor"
              onClick={homepage}
              style={{ width: '120px' }}
            />
          </div>
          <div className="imgDiv">
            <img
              src={LocalImages.createAccountimg}
              alt="signIn"
              className="leftImg img-fluid w-75"
            />
          </div>
        </div>
        <div className="col-lg-5 pt-5 rightDivPad position-relative">
          <h3 className="text-center colorblue fw-bold boldfont">
            Book Your Regular Classroom Seat Now !!
          </h3>
          <p className="text-center">
            Registration for{' '}
            <span className="colorblue">
              {countryCode.callingCode}-{mobileNo}{' '}
            </span>
          </p>
          <div className="justify-content-center d-flex">
            <p className="text-center me-2">Want to Change</p>
            <span
              className="colorblue text-decoration-underline"
              //onClick={navigatelink}
              onClick={(value) => navigatelink(value)}
              style={{ cursor: 'pointer' }}>
              <NavLink>Mobile Number</NavLink>
            </span>
          </div>

          <div className="mobileField mt-5">
            <form onSubmit={handleSubmit}>
              <div className="d-flex">
                <div className="me-2">
                  <label className="mb-2">First Name</label>
                  <InputField
                    type="text"
                    id="fname"
                    placeholder="Enter First Name"
                    value={fname}
                    maxLength="30"
                    // minLength="3"
                    setInputText={(value) => onChangeFirstName(value)}
                  />
                </div>
                <div>
                  <label className="mb-2">Last Name</label>
                  <InputField
                    type="text"
                    id="fname"
                    placeholder="Enter Last Name"
                    value={lname}
                    maxLength="30"
                    setInputText={(value) => onChangeLastName(value)}
                  />
                </div>
              </div>

              <div className="col-sm-12 mt-2 ps-0 ">
                <label className="mb-2 d-block">Email Address</label>
                <InputField
                  type="email"
                  placeholder="example@gmail.com"
                  className="border-0 border-bottom w-100"
                  maxLength="40"
                  value={emailAddress}
                  setInputText={OnChangeEmailAddress}
                />
              </div>
              <div className="mt-3">
                <label className="mb-2">IFAS Branch</label>
                <ClassroomDropdownNew
                  lableName="Select Branch Name"
                  setInputText={(value) => onChangeBranch(value)}
                  value={branchName}
                  options={branchList}
                  reset={resetBranchName}
                />
              </div>
              <div className="mt-3">
                <label className="mb-2">Exam Name</label>
                <ClassroomDropdownNew
                  key="E1"
                  lableName="Select Exam Name"
                  setInputText={(value) => {
                    onChangeExamName(value);
                  }}
                  value={classroomCourse}
                  options={classroomCoursesList}
                  reset={resetExamName}
                  defaultValue={classroomCourse?._id}
                />
              </div>
              {countryCode.callingCode === countryCode?.callingCode ? (
                <div className="mt-3">
                  <label className="mb-2">State Name</label>
                  <CustomDropdown
                    setInputText={(value) => setstatename(value)}
                    value={statename}
                    lableName="Select State Name"
                    options={stateData}
                    reset={resetStateName}
                  />
                </div>
              ) : (
                ''
              )}
              <div className="mt-3">
                <label className="mb-2">Batch Date</label>
                <ClassroomDropdown
                  key="B1"
                  lableName="Select Batch Date"
                  setInputText={(value) => onChangeBatchDate(value)}
                  value={batchDate}
                  options={batchList}
                  type={'date'}
                  reset={resetBatchDate}
                  defaultValue={'Select Batch'}
                />
              </div>
              <div className="mt-5 text-center">
                <Submitbtn
                  name="Book My Seat Now"
                  disabled={
                    !fname ||
                    !lname ||
                    !emailAddress ||
                    !classroomCourse ||
                    !statename ||
                    !branchName ||
                    !batchDate
                  }
                  onPress={onRegister}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      {registrationSuccess ? <ClassRegistrationPopup /> : ''}
    </div>
  );
}

export default ClassroomRegistration;
