import React, { useEffect, useState } from 'react';
import '../Assets/CSS/bannerCard.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function BannerCard() {
  const navigate = useNavigate();
  const [bannerList, setBannerList] = useState([]);
  const { banner } = useSelector((state) => ({
    banner: state?.HomeReducer?.banner
  }));

  useEffect(() => {
    if (banner?.length > 0) {
      let temp = banner?.filter((item) => item?.bannerTypeId?.type.toUpperCase() !== 'RESULTS');
      setBannerList(temp);
    }
  }, []);

  const onBannerClick = (item, index) => {
    if (item?.bannerTypeId?.type.toUpperCase() === 'RESULTS') {
      navigate('/PaidUser/Results');
    }
  };

  return (
    <>
      {bannerList ? (
        <div
          id="carouselExampleDark"
          className="carousel carousel-dark slide bannercontainer px-0"
          data-bs-ride="carousel">
          <div className="carousel-indicators">
            {bannerList?.map((item, index) => {
              return (
                <button
                  type="button"
                  key={index}
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to={index}
                  className={index === 0 ? 'active' : null}
                  aria-current="true"
                  aria-label={'Slide ' + index}></button>
              );
            })}
          </div>

          <div className="carousel-inner">
            {bannerList?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    index === 0
                      ? 'carousel-item active position-relative'
                      : 'carousel-item  position-relative'
                  }
                  data-bs-interval="2000">
                  <div className="boxshadow rounded mb-4 bannerTilesBox bgwhite cursor">
                    <div className="d-flex justify-content-between">
                      <div className="bannerInnerDiv">
                        <img
                          className="bannerImg"
                          src={item?.file}
                          //src={LocalImages?.dummyBaner}
                          alt="Course Logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* <button
        className="carousel-control-prev"
        type="button"
        key={'Previous'}
        data-bs-target="#carouselExampleDark"
        data-bs-slide="prev">
        <span
          className="carousel-control-prev-icon"
          style={{ background: 'none', color: '#328aed' }}
          aria-hidden="true">
          <ArrowBackIosNewIcon />
        </span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        key={'Next'}
        className="carousel-control-next "
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide="next">
        <span
          className="carousel-control-next-icon"
          style={{ background: 'none', color: '#328aed' }}
          aria-hidden="true">
          <ArrowForwardIosIcon />
        </span>
        <span className="visually-hidden">Next</span>
      </button> */}
        </div>
      ) : null}
    </>
  );
}

export default BannerCard;
