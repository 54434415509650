import React, { useState } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateHomeValues } from '../Redux/Actions/homeAction';
import { updateSubscriptionValues } from '../Redux/Actions/subscriptionPlanAction';
import { useEffect } from 'react';
function PriceCard(data) {
  const dispatch = useDispatch();
  const { subscriptionID } = useSelector((state) => ({
    subscriptionID: state?.HomeReducer?.subscriptionID
  }));

  useEffect(() => {
    if (subscriptionID) {
      setActive(data?.data?._id === subscriptionID ? true : false);
    }
  }, [subscriptionID]);

  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(
      data.isLecture
        ? data?.data?.hasSelected
          ? true
          : false
        : data?.data?.hasSelected
        ? true
        : data?.data?.isRecommended
        ? data?.isHasSelected
          ? false
          : true
        : false
    );
  }, [data.isLecture && data?.data?.isRecommended]);

  const handleChange = (e, data1) => {
    dispatch(updateHomeValues({ showRemoveCoupon: false }));
    if (data.isLecture) {
      if (data.setUncheckPrice) data.setUncheckPrice(data1?.price);
      data?.setLectureData({
        subscriptionID: data1?._id,
        discountAmount: 0,
        price: data1?.price
      });
      dispatch(updateHomeValues({ subscriptionID: data1?._id }));
    } else {
      if (data.setUncheckPrice) data.setUncheckPrice(data1?.price);
      dispatch(
        updateHomeValues({
          subscriptionID: data1?._id,
          discountAmount: 0,
          CouponData: '',
          couponCode: '',
          OriginalPrice: data1?.price
        })
      );
      // dispatch(updateSubscriptionValues({ 'price': data1?.price }));
      // dispatch(updateHomeValues({ subscriptionID: data1?._id }));
      // let currentSelectedSubscription = CouponData.length > 0 ? CouponData.filter(
      //   (obj) => obj.subscriptionPlanId === data1?._id
      // ) : {};
      // if (currentSelectedSubscription?.discountedPrice)
      //   dispatch(updateSubscriptionValues({ price: currentSelectedSubscription?.discountedPrice }));
      // else
      dispatch(updateSubscriptionValues({ price: data1?.price }));
    }
  };
  return (
    <div
      key={data?.data?._id}
      className={
        active
          ? 'd-flex border border-2 border-primary py-2 px-3 rounded mb-3 bgwhite'
          : 'd-flex border border-2 border-secondary py-2 px-3 rounded mb-3 bgwhite'
      }>
      <div className="flex-fill">
        <input
          type="radio"
          id={data?.data?._id}
          className="form-check-input cursor"
          value={data?.data?._id}
          onClick={(e) => handleChange(e, data?.data)}
          name="month"
          checked={active ? 'checked' : undefined}
          defaultChecked={active ? 'checked' : undefined}
        />
        {active ? (
          <span className="ms-2 fw-bold colorblue">{data?.data?.months} Months</span>
        ) : (
          <span className="ms-2 fw-bold">{data?.data?.months} Months</span>
        )}
      </div>
      {data?.data?.isRecommended == true ? (
        <div className="position-relative">
          <span className="recommended">Recommended</span>
        </div>
      ) : null}

      <div className="flex-fill">
        {active ? (
          <span className="d-block text-end fw-bold mb-2 colorblue">
            &#8377; {Math.floor(data?.data?.price / data?.data?.months)}
            /mo
          </span>
        ) : (
          <span className="d-block text-end fw-bold mb-2">
            &#8377; {Math.floor(data?.data?.price / data?.data?.months)}
            /mo
          </span>
        )}
        {/* <span className="d-block text-end font12">Total &#8377; {CouponData[0]?.isCouponApply && data?.packageSectionID===CouponData[0]?.packageSectionId && data?.data?._id===subscriptionID ? CouponData[0]?.discountedPrice :data?.data?.price}</span> */}
        <span className="d-block text-end font12">Total &#8377; {data?.data?.price}</span>
      </div>
    </div>
  );
}

export default PriceCard;
