import React from 'react';
import '../App.css';
import videoicon from '../Assets/Images/Learn/videoicon.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { timeConvert } from '../Utils/utils';

function VideoLecture({ title, lectcount, onpress, duration }) {
  return (
    <div
      className="d-flex boxshadow p-3 rounded justify-content-between align-items-center mb-3 bgwhite"
      style={{ cursor: 'pointer' }}
      onClick={onpress}>
      <div>
        <p className="text-uppercase mb-2 fw-bold">{title}</p>
        <span>
          <img src={videoicon} className="icon" alt="video Icon" />
          <span className="ms-2 fw-bold">{`${lectcount} Video`}</span>
          {duration && (
            <span className="ms-2 border-start ps-2 border-2 border-dark">
              {timeConvert(parseInt(duration))}
            </span>
          )}
        </span>
      </div>
      <div className="colorblue customicon">
        <ArrowForwardIosIcon style={{ width: '38px' }} />
      </div>
    </div>
  );
}

export default VideoLecture;
