import React, { useEffect, useState } from 'react';
import { LocalImages } from '../Constant';
import '../Assets/CSS/signIn.css';
import Phoneno from '../Component/phoneno';
import Submitbtn from '../Component/submitBtn';
import { sendOTP, getCountry } from '../Redux/Actions/AuthAction';
import service from '../Constant/service';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { ConstructionOutlined } from '@mui/icons-material';
import signInimg from '../Assets/Images/SignIn/signInbg.png';
import PhoneInput from '../Component/phoneInput';

function ClassroomSignIn(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [number, setNumber] = useState('');
  const [countryData, setCountryData] = useState([]);
  const [countryCode, setCountryCode] = useState({ _id: '', name: '', callingCode: '', flag: '' });
  const [blank, setblank] = useState(true);

  useEffect(() => {
    if (location.state) {
      const { mobileNo1, countryCode1 } = location.state;

      setNumber(mobileNo1);
      setCountryCode(countryCode1);
    }
  }, []);

  useEffect(() => {
    if (number?.length === 10) {
      setblank(false);
    } else {
      setblank(true);
    }
  }, [number]);

  useEffect(() => {
    dispatch(
      getCountry(
        (data) => {
          setCountryData(data);
          const filteredCountries = data.filter((item) => {
            const countryCodeNew = item.callingCode.toLowerCase();
            return countryCodeNew.indexOf('+91') > -1;
          });
          setCountryCode(filteredCountries[0]);
          // setNumber('');
        },
        () => {}
      )
    );
  }, []);

  const navigatelink = (e) => {
    window.location.href = 'UserVerification';
  };
  const PrivacyPolicy = () => {
    window.location.href = 'privacy-policy';
  };
  const Terms = () => {
    window.location.href = 'terms-conditions';
  };
  const homepage = (e) => {
    window.location.href = '/';
  };

  function allnumeric(inputtxt) {
    var numbers = /^[0-9]+$/;
    if (inputtxt.match(numbers)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input numeric characters only'));
      return false;
    }
  }

  const checkDisable = () => {
    number === '' || countryCode._id === '' || !allnumeric(number) || number.length !== 10;
  };

  const handleOnChangePhoneText = (phoneInputText) => {
    const numbersNew = /^[0-9]+$/;
    if (!phoneInputText.target.value) setNumber(phoneInputText.target.value);
    else if (phoneInputText.target.value.match(numbersNew)) setNumber(phoneInputText.target.value);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const onPressSendOTP = () => {
    if (!checkDisable()) {
      const payload = {
        mobileNo: number,
        countryId: countryCode._id
      };

      dispatch(
        sendOTP(
          payload,
          (response) => {
            if (response?.statusCode === service.status_code.success) {
              dispatch(showSuccessSnackbar(response?.msg));

              navigate('/ClassroomOtpVerification', {
                state: { mobileNo: number, countryCode: countryCode, isVerified: true, userId: '' }
              });
            } else {
              dispatch(showErrorSnackbar('wentWrong'));
            }
          },
          (error) => {
            if (error?.response?.data?.msg) dispatch(showErrorSnackbar(error.response.data.msg));
            else dispatch(showErrorSnackbar('wentWrong'));
          }
        )
      );
    } else {
      dispatch(showErrorSnackbar('please fill mobile number'));
    }
  };

  return (
    <div className="bgmob">
      <div className="d-block d-lg-none loginHeader text-start">
        <img src={LocalImages.logo} alt="IFAS Logo" className="logo cursor" onClick={homepage} />
      </div>
      <div className="row authDiv">
        <div className="d-none d-lg-block col-lg-7 leftSidediv">
          <div className="loginHeader ">
            <img
              src={LocalImages.logo}
              alt="IFAS Logo"
              className="logo cursor"
              onClick={homepage}
              style={{ width: '120px' }}
            />
          </div>
          <div className="imgDiv">
            <img src={LocalImages.signInimg} alt="signIn" className="leftImg" />
          </div>
        </div>
        <div className="col-lg-5 rightDivPad position-relative">
          <h3 className="text-center colorblue fw-bold boldfont">Classroom Registration</h3>
          <p className="text-center colorblue">Let's Get Started</p>
          <p className="text-center txt">To get started, please verify mobile number</p>

          <div className="mobileField mt-5 px-2">
            <form onSubmit={handleSubmit}>
              <label className="mb-2 ">Mobile number</label>
              <Phoneno
                setCountryCode={(value) => setCountryCode(value)}
                countryCode={countryCode}
                countryData={countryData}
                placeholder="Enter Your Phone Number"
                maxLength={10}
                minLength={10}
                value={number}
                defaultValue={number}
                handleOnChangePhoneText={handleOnChangePhoneText}
              />

              <div className="mt-4 text-center">
                <Submitbtn name="Send OTP" disabled={blank} onPress={onPressSendOTP} />
              </div>
            </form>
          </div>
          <p className="text-center mb-2">
            Have an account with a <span className="fw-bold">Username?</span>
          </p>

          <p className="text-center fw-bold colorblue">
            <NavLink className="d-inline-block" onClick={navigatelink}>
              Click Here
            </NavLink>
          </p>
          <div className="mb-o tncdiv bottom-0 start-0 end-0 m-auto">
            <p className="text-center ">
              <span className="colorblue cursor" onClick={Terms}>
                T&C
              </span>{' '}
              and
              <span className="colorblue cursor" onClick={PrivacyPolicy}>
                {' '}
                Privacy Policy
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassroomSignIn;
