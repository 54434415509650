import React, { useEffect, useState } from 'react';
import '../Assets/CSS/home.css';
import { LocalImages } from '../Constant';
import data from '../Assets/DummyData/classroomCoaching.json';
import { useSelector } from 'react-redux';

const ClassroomCoachingDashbord = () => {
  //const baseCourseName = location.state.baseCourseName;
  const [classroomData, setclassroomData] = useState();
  const [baseCourse, setbaseCourse] = useState();

  const { baseCourseName } = useSelector((state) => ({
    baseCourseName: state?.AuthReducer?.baseCourseName
  }));

  // let baseCourse;

  useEffect(() => {
    if (baseCourseName.toUpperCase() === 'LIFE SCIENCE') {
      setbaseCourse('life_science');
    } else if (baseCourseName.toUpperCase() === 'CHEMICAL SCIENCE') {
      setbaseCourse('chemical_science');
    } else if (baseCourseName.toUpperCase() === 'PHYSICAL SCIENCE') {
      setbaseCourse('physical_science');
    } else if (baseCourseName.toUpperCase() === 'MATHEMATICAL SCIENCE') {
      setbaseCourse('mathematical_science');
    } else {
      setbaseCourse('life_science');
    }
  }, [baseCourseName]);

  // let classroomData;

  let FAQ, Name, bannerimg, features, registration;
  useEffect(() => {
    setclassroomData(data[baseCourse]);
    FAQ, Name, bannerimg, features, (registration = classroomData);
  }, [baseCourse]);

  // const classroomData = data[baseCourse]
  const gotoclassroomregistration = () => {
    window.location.href = 'ClassroomSignIn';
  };
  // const { FAQ, Name,bannerimg, features, registration } = classroomData

  const topperList = [
    {
      name: 'Ramika Singla',
      path: LocalImages.ramikaSingla
    },
    {
      name: 'Ajay Dangi',
      path: LocalImages.AjayDangi
    },
    {
      name: 'Subhamoy Bhowmik',
      path: LocalImages.shubhamoy
    },
    {
      name: 'Arti Rathore',
      path: LocalImages.artiRathore
    },
    {
      name: 'Royal Pradhan',
      path: LocalImages.royalPradhan
    },
    {
      name: 'Saireet Misra',
      path: LocalImages.saireetMishra
    },
    {
      name: 'Khushbu Pilania',
      path: LocalImages.KhushbuPilania
    },
    {
      name: 'Rohan Chowdhury',
      path: LocalImages.rohanChowdhary
    },
    {
      name: 'Royal Pradhan',
      path: LocalImages.royalPradhan
    },
    {
      name: 'Saireet Misra',
      path: LocalImages.saireetMishra
    },
    {
      name: 'Khushbu Pilania',
      path: LocalImages.KhushbuPilania
    },
    {
      name: 'Rohan Chowdhury',
      path: LocalImages.rohanChowdhary
    }
  ];

  return (
    <div className="freeuserrightsidepanel">
      <div className="">
        <img
          className="img-fluid"
          src={LocalImages[classroomData?.bannerimg]}
          alt="classroom"></img>
      </div>

      <div className="row mx-4 pt-4 mb-4">
        <p className="text-center mb-2 colorblue coursenametitle fw-bold">
          {/* {classroomData?.Name} : CLASSROOM COACHING */}
        </p>
        <p className="text-center mb-2 ">Classroom Coaching Feature</p>
        {classroomData?.features?.map((item) => (
          <div className="col-md-4 col-6  text-center">
            <img className="featureimg" src={LocalImages[item.img]} alt="classroom"></img>
            <p className="fw-bold">{item.name}</p>
          </div>
        ))}
      </div>
      <div className="mx-5 p-4 mb-4  rounded detailbg  text-center">
        <div className="row mx-xl-5 mb-3">
          {classroomData?.registration?.map((item) => (
            <div className="col-md-3 col-6 text-center ">
              <div className="mx-xl-4 px-xl-4 text-center ">
                <p className=" fw-bold colorblue mb-0">
                  <span className="  me-1">
                    {' '}
                    <img className="detailimg " src={LocalImages[item.img]} alt="classroom"></img>
                  </span>

                  {item.name}
                </p>
              </div>

              <p className=" fw-bold">{item.subtext}</p>
            </div>
          ))}
        </div>
        <div className="text-center ">
          <button className="btn btn-primary" onClick={gotoclassroomregistration}>
            {' '}
            FREE CLASSROOM REGISTRATION
          </button>
        </div>
      </div>
      <div
        className="app-achiev common-pad pt-2 pb-2 text-center bg"
        data-wow-duration="2s"
        data-wow-delay="0s">
        <p className="text-center nameheading mb-3">CSIR NET TOPPERS</p>

        <div className="row content-topper mt-3 col-lg-10 mx-auto">
          {topperList?.map((c, indexMain) => {
            return (
              <div key={indexMain.toString()} className=" col-6 col-sm-2 rounded mb-3">
                <img className="img-fluid" src={c.path} alt={c.name} />
                <span className="d-block mt-2">{c.name}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="row px-4 pt-4">
        <p className="text-center mb-2 colorblue coursenametitle fw-bold">
          Some Frequently Asked Questions
        </p>

        <div
          className="accordion accordion-flush row d-flex justify-content-center"
          id="accordionFlushExample">
          <div className="accordion-item noborder col-md-8 ">
            {classroomData?.FAQ?.map((c, index) => {
              return (
                <div className="boxshadow" style={{ margin: '6px' }}>
                  <h2 className="accordion-header" id={'heading' + index}>
                    <button
                      className={`accordion-button  ${index === 0 ? '' : 'collapsed'}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={'#collapse' + index}
                      aria-expanded={index === 0 ? 'true' : 'false'}
                      aria-controls={'collapse' + index}>
                      {c?.title}
                    </button>
                  </h2>
                  <div
                    id={'collapse' + index}
                    className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                    aria-labelledby={'heading' + index}
                    data-bs-parent={'#accordionExample'}>
                    <div className="accordion-body">{c?.text}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassroomCoachingDashbord;
