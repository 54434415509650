import React from 'react';
import videoicon from '../Assets/Images/Learn/videoicon.png';
import timeCircle from '../Assets/Images/Learn/timeCircle.png';
function RecommendedCard(props) {
  const livelecture = (val) => {
    window.open(val, '_blank');
  };

  return (
    <div key={props.data.name} className="boxshadow font12 p-2 px-4 rounded mb-3 bgwhite">
      <div className="d-flex justify-content-between mb-2">
        <span className="fw-bold">{props.data.name}</span>
        {/* <span className="fw-bold">{props.data.packageSectionName}</span>  */}
        <span className="colorblue d-flex">
          {/* <img className="ms-2" style={{ width: '15px' }} src={videoicon} alt="Video Icon" /> */}
          {props.data.examTypeName}{' '}
          <div
            className="justify-content-between "
            onClick={(value) => livelecture(props.data.video)}>
            <a href={props.data.video} target="_blank" style={{ color: '#fff' }} rel="noreferrer">
              <img className="ms-2" style={{ width: '15px' }} src={videoicon} alt="Video Icon" />
            </a>
          </div>
        </span>
      </div>

      <div className="mb-2">
        <span className="colorblue fw-bold">&#8377; {props.data.price}</span>
        <span className="ms-2">
          <del></del>
        </span>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-1">
        {props.data.sectionType === 2 ? (
          <div></div>
        ) : (
          <div>
            <img className="me-2" style={{ width: '15px' }} src={timeCircle} alt="timeCircle" />
            {props.data.months} Months
          </div>
        )}
        <button
          className="btn btn-secondary px-2"
          style={{ fontSize: '10px' }}
          onClick={() => {
            props.handleAddOns(props.data);
          }}>
          Add +
        </button>
      </div>
    </div>
  );
}

export default RecommendedCard;
