import React, { useEffect, useState } from 'react';

import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { AddCardTwoTone } from '@mui/icons-material';
import { updateSession } from '../Redux/Actions/homeAction';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function BatchTimingCart(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [batchIndex, setBatchIndex] = useState(-1);
  const [localSessionId, setLocalSessionId] = useState();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.show(false);
  };

  useEffect(() => {
    if (props?.sessions) {
      let batchIndexNew = props?.sessions.findIndex((item) => item._id === props?.sessionId);
      setBatchIndex(batchIndexNew);
    }
    setLocalSessionId(props?.sessionId);
  }, [props.sessionId]);

  const handleChange = (e) => {
    setLocalSessionId(e.target.value);
  };

  const addCart = () => {
    dispatch(
      updateSession(
        {
          cartId: props.id,
          sessionId: localSessionId
        },
        () => {},
        () => {}
      )
    );

    setOpen(false);
    props.show(false);
  };
 
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <div className="modeldiv p-5">
          <div className="col mb-4 d-flex justify-content-between ">
            <h6 className="colorblue fw-bold">Select Batch Timing</h6>
            <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
          </div>
          <div className="row ps-0  x form-check">
            {props.sessions?.map((c, index) => (
              <div className="col ps-0 mb-2 d-flex justify-content-between ">
                <div className="d-flex">
                  <h6 className="form-check-label">{c.name} Batch</h6>
                  {c?.time ? (
                    <h6 className="form-check-label ms-2">
                      ({moment(c?.time, 'HH:mm').format('hh:mm A')} )
                    </h6>
                  ) : (
                    ''
                  )}
                </div>
                <input
                  type="radio"
                  className="form-check-input cursor"
                  id="radio2"
                  name="optradio"
                  value={c._id}
                  checked={c._id === localSessionId}
                  defaultChecked={c._id === props?.sessionId}
                  onChange={(e) => {
                    handleChange(e, c);
                  }}
                />
              </div>
            ))}

            <div className="d-flex justify-content-evenly mt-5">
              <button
                type="button"
                className="btn btn-primary px-5 cursor"
                onClick={() => addCart()}>
                Save and Proceed
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default BatchTimingCart;
