import React, { useEffect, useState } from 'react';
import '../App.css';
import SubjectTestDetails from '../Module/subjectTestDetails';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getExamById,
  getUnitTests,
  notSyncedDataAction,
  setInstructionsAction,
  getExamList
} from '../Redux/Actions/examActions';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import NoRecord from '../Component/noRecord';

function SubjectTest() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [unitTest, setUnitTest] = useState([]);
  const [topicTest, setTopicTest] = useState([]);
  const currentURLarr = useLocation().pathname.split('/');
  let link = currentURLarr[currentURLarr.length - 1];

  const [loader, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    examTypeId,
    baseCourseId,
    notSyncedDataFromStore,
    examIdDataFromStoreObject,
    hasActivePaidPlan
  } = useSelector((state) => ({
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    notSyncedDataFromStore: state?.ExamSelectReducer?.notSyncedData,
    examIdDataFromStoreObject: state?.ExamSelectReducer?.examIdData,
    hasActivePaidPlan:
      link === 'FreeSubjectTest' ? false : state?.AuthReducer?.hasActivePaidPlan || false
  }));
  console.log("paid", hasActivePaidPlan);
  const startdata = localStorage.getItem('startTest');
  const examIdDataFromStore = examIdDataFromStoreObject[0];

  const _retrieveData = async (examId) => {
    try {
      let strNotSyncedData = notSyncedDataFromStore || '{}';

      let notSyncedData = JSON.parse(strNotSyncedData);
      selectedQuestionIndex = +(notSyncedData[examId] || 0);
      dispatch(notSyncedDataAction(JSON.stringify(notSyncedData)));
    } catch (error) {}

    try {
      const value = examIdDataFromStore;
      if (value !== null) {
        let examData = JSON.parse(value);
        let examDataT = JSON.parse(`{"examData": [${value}]}`);
        dispatch(setInstructionsAction(examDataT));
        if (examData.isSubmitted === true) {
          navigate('/DetailResult', { state: { examId: examId, examData: examData } });
        } else {
          let tmp = [examData];
          navigate('/ExamInstruction', { state: { examId: examId, examData: tmp } });
        }
      } else {
        getExamAPI(examId);
      }
    } catch (error) {
      getExamAPI(examId);
    }
  };

  const getExamAPI = (examId) => {
    setLoading(true);
    dispatch(
      getExamById(
        examId,
        (data) => {
          setLoading(false);
          let examData = data.data.examData;
          let examStatus = examData[0].status;
          let isContestTest = examData[0].isContestTest;
          if (examStatus === 2) {
            if (isContestTest === true) {
              let currentDate = +new Date();
              let contestEndDate = +new Date(examData.contestEndDate);
              if (currentDate > contestEndDate) {
                sessionStorage.setItem('examId', examId);
                sessionStorage.setItem('examData', JSON.stringify(examData[0]));
                window.open(
                  '/DetailResult',
                  '_blank',
                  'width=' + screen.availWidth + ',height=' + screen.availHeight
                );
              } else {
              }
            } else {
              sessionStorage.setItem('examId', examId);
              sessionStorage.setItem('examData', JSON.stringify(examData[0]));
              window.open(
                '/DetailResult',
                '_blank',
                'width=' + screen.availWidth + ',height=' + screen.availHeight
              );
            }
          } else {
            if (examData[0]?.totalQuestions !== examData[0]?.totalAddedQuestion) {
              dispatch(
                showErrorSnackbar('This Exam will be available soon, Please try after sometime')
              );
            } else {
              sessionStorage.setItem('examId', examId);
              sessionStorage.setItem('examData', JSON.stringify(examData));
              localStorage.setItem('startTest', '1');
              var windowName = 'userConsole';
              var popUp = window.open(
                '/ExamInstruction',
                windowName,
                '_blank',
                'width=' + screen.availWidth + ',height=' + screen.availHeight
              );
              if (popUp == null || typeof popUp == 'undefined') {
                localStorage.setItem('startTest', '2');
                alert('Please disable your pop-up blocker and click the "Open" link again.');
              } else {
                popUp.focus();
              }
            }
          }
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    const subjectId = location?.state?.subject?.subjectId ? location.state.subject.subjectId : '';
    const subjectdata = location?.state?.subject ? location.state.subject : '';
    console.log("dkdkd",subjectdata);
    const postData = {
      baseCourseId: baseCourseId,
      examTypeId: examTypeId
    };
    if (hasActivePaidPlan === true) {
      // setUnitTest([...unitTest, subjectdata]);
      setUnitTest([subjectdata]);
      const postdata = {
        baseCourseId: baseCourseId,
        examTypeId: examTypeId,
        examType: location?.state?.examType,
        isFreeUser: false,
        isPYQ: location?.state?.isPYQ,
        subjectId:location?.state?.subjectId,
      };
      dispatch(
        getExamList(postdata,(res) => {
            setTopicTest(res);
          },
          () => {}
        )
      );
      
    } else {
      dispatch(
        getUnitTests(
          postData,
          (res) => {
            if(res.data.subjectId){
              const unitTestData = res.data.filter((item) => item.subjectId === subjectId);
              setUnitTest(unitTestData);
            }else if(!res.data.subjectId){
              setUnitTest(res.data);
            }
          },
          () => {}
        )
      );
    }

    if (hasActivePaidPlan === false) {
      const postdata = {
        baseCourseId: baseCourseId,
        examTypeId: examTypeId,
        examType: location?.state?.examType,
        isFreeUser: location?.state?.isFreeUser,
        isPYQ: location?.state?.isPYQ
      };
      dispatch(
        getExamList(postdata,(res) => {
            setTopicTest(res);
          },
          () => {}
        )
      );
    }
  }, []);
  const onStartExam = (examId) => {
    _retrieveData(examId);
  };
console.log("value", unitTest);
  return (
    <div className="rightPanel">
      <div>
        <h3>{topicTest.length > 0 ? 'Topic Test' : 'Unit Test'}</h3>
      </div>
      {unitTest.length > 0 ? (
        <div className="mt-4 width75 p-1 p-sm-3 lectureheight">
          {unitTest.map((c) => {
            return (
              <SubjectTestDetails
                existInStore={examIdDataFromStoreObject[c?.examId] ? true : false}
                key={c.examId}
                data1={c}
                onStartExam={() => onStartExam(c.examId)}
                startdata={startdata}
              />
            );
          })}
        </div>
      ) : (
      ''
      )}
      {topicTest.length > 0 ? (
        <div className="mt-4 width75 p-1 p-sm-3 lectureheight">
          {topicTest.map((c) => {
            return (
              <SubjectTestDetails
                existInStore={examIdDataFromStoreObject[c?.examId] ? true : false}
                key={c.examId}
                data1={c}
                onStartExam={() => onStartExam(c.examId)}
                startdata={startdata}
              />
            );
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default SubjectTest;
