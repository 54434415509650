import React, {useEffect, useState} from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';

function TimerClock({ onResendPress, countdown = 30 }) {
  const [timerClock, setTimerClock] = useState(countdown);
  const [isResendReady, setIsResendReady] = useState(false);
  const timer = timerClock < 10 ? `0${timerClock}` : timerClock;

  useEffect(() => {
    let clockIntervalId;
    if (!isResendReady && timerClock > 0) {
      clockIntervalId = setInterval(() => {
        setTimerClock((prevValue) => prevValue - 1);
      }, 1000);
    } else {
      setIsResendReady(true);
    }
    return () => clearInterval(clockIntervalId);
  }, [timerClock, isResendReady]);

  const resendCodeClick = () => {
    onResendPress();
    setIsResendReady(false);
    setTimerClock(countdown);
  };

  const returnTimerValue = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${minutes}${':'}${seconds <= 9 && seconds >= 0 ? `0${seconds}` : seconds}`;
  };

  return (
    <div>
      {isResendReady ? (
        <div className="d-flex justify-content-between m-2 ms-4 me-2 font12">
          <p>Didn't Receive OTP Code</p>
          <div>
            <span className="colorblue cursor">
              Resend OTP <RefreshIcon onClick={resendCodeClick} fontSize="small" />
            </span>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <p className="font12">
            Didn't Receive OTP ? Retry after{' '}
            <span className="colorblue"> {returnTimerValue(timer)} Sec</span>
          </p>
        </div>
      )}
    </div>
  );
}

export default TimerClock;
