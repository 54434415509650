import React, { useEffect, useState } from 'react';
import '../App.css';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'; 
import orderCompleted from '../Assets/Images/JSON/ordercompleted.json'; 
import { useDispatch } from 'react-redux'; 

function PhonePopup(props) {
  const [successpopup, setsuccesspopup] = useState(false);
  const dispatch = useDispatch();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orderCompleted,
    renderer: 'svg'
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="modeldiv p-4 py-5 talktoexpertModel">
          <div>
            <div className="col mb-2 d-flex justify-content-between ">
              <h5 className="colorblue"></h5>
              <ClearOutlinedIcon className="colorblue cursor" onClick={props.handleClose} />
            </div>
            <div>
              <h4 className="ms-3 colorblue fw-bold text-center">{props.number}</h4>
              <p className="ms-3 mt-3 text-center">Call on this number to get support</p>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default PhonePopup;
