import React, { useState } from 'react';

import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, getCartList } from '../Redux/Actions/homeAction';
import { updateSessionValues } from '../Redux/Actions/sessionAction';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function BatchTiming(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const { _id, sectionType, packageSection, sessions } = useSelector((state) => ({
    _id: state?.CourseDetailreducer?._id,
    sectionType: state?.CourseDetailreducer?.sectionType,
    packageSection: state?.CourseDetailreducer?.packageSection,
    sessions: state?.CourseDetailreducer?.sessions
  }));

  const { subscriptionID, selectedPackages, CouponData } = useSelector((state) => ({
    subscriptionID: state?.HomeReducer?.subscriptionID,
    selectedPackages: state?.HomeReducer?.selectedPackages,
    CouponData: state?.HomeReducer?.CouponData
  }));

  const [packages, setPackage] = useState({
    _id: packageSection?.length > 1 && packageSection[props.setKey]?._id,
    name: packageSection?.length > 1 && packageSection[props.setKey]?.name
  });
  const courseId = _id;
  const packageSectionId =
    packages && packages._id ? packages._id : packageSection[props.setKey]?._id;
  const subscriptionPlanId = subscriptionID;
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.show(false);
  };

  const { name, type, sessionId } = useSelector((state) => ({
    name: state?.SessionReducer?.name,
    type: state?.SessionReducer?.type,
    sessionId: state?.SessionReducer?.sessionId
  }));

  const addCart = () => {
    if (sectionType === 1 || sectionType === 3) {
      const postData = {
        sectionType,
        courseId,
        packageSectionId,
        subscriptionPlanId,
        sessionId,
        isExetended: props.extendedOption
      };
      if (CouponData[0]?.isCouponApply) {
        postData['couponId'] = CouponData[0]?.couponId;
      }
      dispatch(
        addToCart(
          {
            package: [postData],
            extendedOption: props.extendedOption
          },
          (data) => {
            dispatch(getCartList(() => {}, true));
            navigate('/MyCart');
          },
          (error) => {
            console.log("error");
            // props.setErrormsg(error);
          }
        )
      );
    } else if (sectionType === 5) {
      for (let i = 0; i < CouponData.length; i++) {
        for (let j = 0; j < selectedPackages.length; j++) {
          if (CouponData[i].packageSectionId === selectedPackages[j].packageSectionId) {
            selectedPackages[j]['couponId'] = CouponData[i].couponId;
          }
        }
      }

      dispatch(
        addToCart(
          {
            package: selectedPackages,
            extendedOption: props.extendedOption
          },
          (data) => {
            navigate('/MyCart');
          },
          () => {}
        )
      );
    }

    setOpen(false);
    props.show(false);
  };

  const handleChange = (e) => {
    dispatch(updateSessionValues({ sessionId: e.target.value }));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <div className="modeldiv p-5">
          <div className="col mb-4 d-flex justify-content-between ">
            <h6 className="colorblue fw-bold">Select Batch Timing</h6>
            <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
          </div>
          <div className="row ps-0  x form-check">
            {sessions?.map((c, index) => (
              <div className="col ps-0 mb-2 d-flex justify-content-between ">
                <div className="d-flex">
                  <h6 className="form-check-label">{c.name} Batch</h6>
                  {c?.time ? (
                    <h6 className="form-check-label ms-2">
                      ({moment(c?.time, 'HH:mm').format('hh:mm A')} )
                    </h6>
                  ) : (
                    ''
                  )}
                </div>

                <input
                  type="radio"
                  className="form-check-input cursor"
                  id={'radio' + index}
                  name="optradio"
                  value={c._id}
                  defaultChecked={props?.defaultVal ? props.defaultVal : index == 0}
                  onChange={(e) => {
                    handleChange(e, c);
                  }}
                />
              </div>
            ))}
            <div className="d-flex justify-content-evenly mt-5">
              <button type="button" className="btn btn-primary px-5" onClick={addCart}>
                Save and Proceed
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default BatchTiming;
