import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import '../App.css';
import CompleteSyllabusCard from '../Module/completeSyllabusCard';
import { LocalImages } from '../Constant';
import {
  getExamById,
  notSyncedDataAction,
  setInstructionsAction,
  getExamListByType,
  getOnlineTest
} from '../Redux/Actions/examActions';
import NoRecord from '../Component/noRecord';
import { _retrieveData } from '../Utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getAboutExamFAQ,
  getCutoffList,
  getExamDateList,
  getExamPaperAnalysis,
  getExamSectionList,
  getExamSyllabus,
  getPyqPdf
} from '../Redux/Actions/homeAction';
import YouTubeIframe from 'react-youtube-iframe';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import PyqPdfCard from '../Module/pyqPdfCard';

function AllAbout() {
  const [key, setKey] = useState('one');
  const [detail, setDetail] = useState([]);
  const [Unitno, setUnitno] = useState('');
  const [previousQPList, setPreviousQPList] = useState([]);
  const [previousYearsList, setPreviousYearsList] = useState([]);
  const [selectedPrevYear, setSelectedPrevYear] = useState('');
  const [loader, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedval, setselectedval] = useState('');
  const [examSyllabus, setExamSyllabus] = useState([]);
  const [FAQData, setFAQData] = useState();
  const [examDate, setExamDate] = useState([]);
  const [paperAnalysis, setPaperAnalysis] = useState(null);
  const [examSection, setExamSection] = useState(null);
  const [CutoffData, setCutoffData] = useState();
  const [pyqPdfData, setPyqPdfData] = useState();
  const currentURLarr = useLocation().pathname.split('/');
  let link = currentURLarr[currentURLarr.length - 1];
  const youtubeParse = (url) => {
    var regExp =
      /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
    var match = url.match(regExp);
    return match && match[1].length == 11 ? match[1] : '';
  };

  let selectedQuestionIndex = 0;
  const {
    baseCourseId,
    examTypeId,
    hasActivePaidPlan,
    PaidCourseID,
    notSyncedDataFromStore,
    examIdDataFromStoreObject,
    childrenId,
    examTypeName,
    baseCourseName,
    selectedTiles
  } = useSelector((state) => ({
    baseCourseName: state?.AuthReducer?.baseCourseName,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    examTypeId: state?.AuthReducer?.examTypeId,
    hasActivePaidPlan:
      link === 'FreeAllAbout' ? false : state?.AuthReducer?.hasActivePaidPlan || false,
    PaidCourseID: state?.HomeReducer?.PaidCourseID,
    notSyncedDataFromStore: state?.ExamSelectReducer?.notSyncedData,
    examTypeName: state?.AuthReducer?.examTypeName,
    examIdDataFromStoreObject: state?.ExamSelectReducer?.examIdData,
    childrenId: state?.AuthReducer?.childrenId,
    selectedTiles: state?.HomeReducer?.selectedTiles
  }));
  useEffect(() => {
    if (hasActivePaidPlan === true) {
      const postdata = {
        baseCourseId: selectedTiles?.baseCourseId._id,
        examTypeId: selectedTiles?.examTypeId._id,
        courseId: selectedTiles?.courseId
      };
      dispatch(
        getOnlineTest(
          postdata,
          (dataState) => {
            setPreviousQPList(dataState?.PYQ);
            const uniquePreviousYearsSet = new Set();
            for (const item of dataState?.PYQ) {
              uniquePreviousYearsSet.add(item._id);
            }
            setPreviousYearsList(['All', ...uniquePreviousYearsSet]);
          },
          () => {}
        )
      );
    } else {
      const postData = {
        baseCourseId: baseCourseId,
        examTypeId: examTypeId
      };
      const postDataPrevYearPapersData = {
        ...postData,
        examType: 4
      };
      dispatch(
        getExamListByType(
          postDataPrevYearPapersData,
          (res) => {
            const uniquePreviousYearsSet = new Set();
            for (const item of res.data) {
              uniquePreviousYearsSet.add(item.examYear);
            }
            const prevQPYearWise = [];
            for (const year of uniquePreviousYearsSet) {
              prevQPYearWise.push({
                examYear: year,
                data: res?.data?.filter((item) => item.examYear == year)
              });
            }
            setPreviousQPList(prevQPYearWise);
            // setPreviousYearsList(['All', ...uniquePreviousYearsSet]);
          },
          (err) => {}
        )
      );
    }
  }, []);

  useEffect(() => {
    let requestData = {};
    if (hasActivePaidPlan === true) {
      requestData = {
        baseCourseId: selectedTiles?.baseCourseId._id,
        examTypeId: selectedTiles?.examTypeId._id,
        subExamTypeId: selectedTiles?.subExamTypeId
      };
      if (selectedTiles?.subExamTypeId === '') delete requestData['subExamTypeId'];
    } else {
      requestData = {
        baseCourseId,
        examTypeId,
        subExamTypeId: childrenId
      };
      if (childrenId === '') delete requestData['subExamTypeId'];
    }

    dispatch(
      getExamSyllabus(
        requestData,
        (res) => {
          setExamSyllabus(res);
          if (res) {
            setselectedval(res[0]._id);
            setUnitno(res[0]?.subjectName);
            setDetail(res[0]);
          }
        },
        () => {}
      )
    );
    dispatch(
      getAboutExamFAQ(requestData, (res) => {
        if (res) setFAQData(res?.faq);
      })
    );
    dispatch(
      getPyqPdf(requestData, (res) => {
        setPyqPdfData(res);
      })
    );
    dispatch(
      getExamSectionList(requestData, (res) => {
        setExamSection(res);
      })
    );

    dispatch(
      getExamDateList(requestData, (res) => {
        setExamDate(res);
      })
    );

    dispatch(
      getExamPaperAnalysis(requestData, (res) => {
        setPaperAnalysis(res);
      })
    );

    dispatch(
      getCutoffList(
        requestData,
        (res) => {
          setCutoffData(res.data);
        },
        () => {}
      )
    );
  }, []);

  const examIdDataFromStore = examIdDataFromStoreObject[0];
  const onStartExam = (examId) => {
    const args = {
      examId,
      navigate,
      setLoading,
      dispatch,
      getExamById,
      notSyncedDataFromStore,
      selectedQuestionIndex,
      notSyncedDataAction,
      examIdDataFromStore,
      setInstructionsAction
    };
    _retrieveData(args);
  };

  const showdetails = (value, index) => {
    setselectedval(value._id);
    setUnitno(value?.subjectName);
    setDetail(value);
  };

  const onChangePrevYear = (e) => {
    setSelectedPrevYear(e.target.value);
  };
  let prevYearQP = [];
  if (hasActivePaidPlan == true) {
    prevYearQP =
      selectedPrevYear === 'All' || selectedPrevYear === ''
        ? previousQPList
        : previousQPList.filter((item) => item._id == selectedPrevYear);
  } else {
    prevYearQP =
      selectedPrevYear === 'All' || selectedPrevYear === ''
        ? previousQPList
        : previousQPList.filter((item) => item.examYear == selectedPrevYear);
  }

  return (
    <div className="rightPanel">
      <h3>
        All About {hasActivePaidPlan === true ? selectedTiles?.examTypeId?.name : examTypeName}
      </h3>
      <div className="customtab mt-4">
        <ul
          className="nav nav-pills mb-3 boxshadow p-2 rounded bgwhite"
          id="pills-tab"
          role="tablist">
          <li className="nav-item me-3 mb-2" role="presentation">
            <button
              className="nav-link active"
              id="pills-AllAbout-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-AllAbout"
              type="button"
              role="tab"
              aria-controls="pills-AllAbout"
              aria-selected="true">
              All About Exam
            </button>
          </li>
          <li className="nav-item me-3 mb-2" role="presentation">
            <button
              className="nav-link"
              id="pills-complateSyllabus-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-complateSyllabus"
              type="button"
              role="tab"
              aria-controls="pills-complateSyllabus"
              aria-selected="true">
              Complete Syllabus
            </button>
          </li>
          <li className="nav-item me-3 mb-2" role="presentation">
            <button
              className="nav-link"
              id="pills-pep-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-pep"
              type="button"
              role="tab"
              aria-controls="pills-pep"
              aria-selected="true">
              Previous Exams Paper
            </button>
          </li>
          <li className="nav-item me-3 mb-2" role="presentation">
            <button
              className="nav-link"
              id="pills-Pattern-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-Pattern"
              type="button"
              role="tab"
              aria-controls="pills-Pattern"
              aria-selected="true">
              Pattern/Dates
            </button>
          </li>
        </ul>

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade active show"
            id="pills-AllAbout"
            role="tabpanel"
            aria-labelledby="pills-AllAbout-tab"
            tabIndex="0">
            <div className="row subjectTestheight">
              <div className="p-5 ps-3 shadow bg-white me-3">
                {FAQData ? ReactHtmlParser(FAQData) : <NoRecord />}
              </div>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="pills-complateSyllabus"
            role="tabpanel"
            aria-labelledby="pills-complateSyllabus-tab"
            tabIndex="0">
            <div>
              {examSyllabus?.length > 0 ? (
                <div className="row d-none d-md-flex">
                  <div className="col-md-6 col-xl-7 subjectTestheight p-2 ps-1">
                    {examSyllabus?.map((item, index) => {
                      return (
                        <CompleteSyllabusCard
                          data={item}
                          index={index}
                          selectedval={selectedval}
                          onclick={(value) => showdetails(value, index)}
                        />
                      );
                    })}
                  </div>
                  <div className="col-md-6 col-xl-5 subjectTestheight px-md-4 py-1">
                    <div className="boxshadow rounded p-3 font12 bgwhite">
                      <p className="colorblue mb-2 fw-bold">Curriculum</p>
                      <div className="mt-2">
                        <p className="mb-1 ms-1 boldfont">{Unitno}</p>
                        <div className="youtubeframe">
                          {detail?.subjectVideo && (
                            <YouTubeIframe videoId={youtubeParse(detail.subjectVideo)} />
                          )}
                        </div>
                        <div className="syllabusDetaildiv">
                          {ReactHtmlParser(detail?.topicInfo)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <NoRecord />
              )}
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="pills-pep"
            role="tabpanel"
            aria-labelledby="pills-pep-tab"
            tabIndex="0">
            <div className="row mt-2">
              {pyqPdfData?.length > 0 ? (
                <>
                  <div className="col-9 subjectTestheight p-1 p-md-3">
                    {pyqPdfData.map((c) => {
                      return (
                        <Fragment>
                          <PyqPdfCard data={c} />
                        </Fragment>
                      );
                    })}
                  </div>
                  {/* {prevYearQP.length != 0 ? (
                    <div className="col-3 bgwhite exampattern">
                      <div className="row d-flex justify-content-between">
                        <div className="col-9 pe-0">
                          <span className="fw-bold">Exam Year</span>
                        </div>
                        <div className="col-3 ps-0">
                          <select
                            style={{ border: 'none' }}
                            value={selectedPrevYear}
                            onChange={(e) => onChangePrevYear(e)}>
                            {previousYearsList?.map((previousYear) => {
                              return (
                                <option key={previousYear} value={previousYear}>
                                  {previousYear}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )} */}
                </>
              ) : (
                <NoRecord className="bgwhite" />
              )}
            </div>
          </div>

          <div
            className="tab-pane fade custominnertab"
            id="pills-Pattern"
            role="tabpanel"
            aria-labelledby="pills-Pattern-tab"
            tabIndex="0">
            <Tabs
              id="tinyTab"
              className="tabcontent w-100"
              activeKey={key}
              onSelect={(e) => setKey(e)}
              fill
              variant="pills">
              <Tab eventKey="one" title="Exam Dates">
                <div className="childtabcontent shadow bgwhite">
                  {examDate?.length != 0 ? (
                    examDate.map((c, index) => (
                      <div className="d-flex align-items-end">
                        <div>
                          {index == 0 ? null : <div className="line"></div>}

                          <div>
                            <img src={c?.file} alt="img" className="examdateimg" />
                          </div>
                        </div>
                        <div className="ms-5 rounded boxshadow p-2 p-md-3 rounded mb-3 bgwhite">
                          <p className="mb-1 fw-bold font12">{c?.labelName}</p>
                          <div className="font12">{c?.date ? ReactHtmlParser(c?.date) : ''}</div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <NoRecord />
                  )}
                </div>
              </Tab>
              <Tab eventKey="two" title="Exam Pattern ">
                {examSection ? (
                  <>
                    <div className="childtabcontent shadow bgwhite">
                      <p className="text-uppercase fw-bold mb-4 mt-3">
                        {baseCourseName.toUpperCase()}
                      </p>
                      <div className="row examTime boxshadow mb-3">
                        {/* <div className="col-6 col-sm-3 d-flex p-2 p-sm-3">
                            <img src={LocalImages.duration} alt="Duration" />
                            <div className="ms-2">
                              <p className="fw-bold mb-0">{c?.exam_pattern?.duration}</p>
                              <p className="mb-0 font12">Duration</p>
                            </div>
                          </div>
                          <div className="col-6 col-sm-3 d-flex p-2 p-sm-3">
                            <img src={LocalImages.language} alt="language" />
                            <div className="ms-2">
                              <p className="fw-bold mb-0">{c?.exam_pattern?.language}</p>
                              <p className="mb-0 font12">language</p>
                            </div>
                          </div> */}
                        <div className="col-6 col-sm-3 d-flex p-2 p-sm-3">
                          <img src={LocalImages.totalQuestions} alt="TotalQuestion" />
                          <div className="ms-2">
                            <p className="fw-bold mb-0">{examSection?.totalQuestions}</p>
                            <p className="mb-0 font12">Total Questions</p>
                          </div>
                        </div>
                        <div className="col-6 col-sm-3 d-flex p-2 p-sm-3">
                          <img src={LocalImages.maxMarks} alt="Duration" />
                          <div className="ms-2">
                            <p className="fw-bold mb-0">{examSection?.totalMarks}</p>
                            <p className="mb-0 font12">Total Marks</p>
                          </div>
                        </div>
                        {/* ))} */}
                      </div>

                      <div className="accordion accordion-flush" id="courseExams">
                        {examSection?.sectionData.map((subject, index) => (
                          <div className="accordion-item mb-2 border">
                            <h2 className="accordion-header" id={'headingOne' + index}>
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={'#collapseOne' + index}
                                aria-expanded="false"
                                aria-controls={'collapseOne' + index}>
                                <p className="text-uppercase fw-bold m-1">{subject?.name}</p>
                              </button>
                            </h2>

                            <div
                              id={'collapseOne' + index}
                              className="accordion-collapse collapse"
                              aria-labelledby={'headingOne' + index}
                              data-bs-parent="courseExams">
                              <div className="accordion-body">
                                <div className="d-flex justify-content-between">
                                  <p>
                                    <b className="me-1">Total Question:</b>
                                    {subject?.totalQuestions}
                                  </p>
                                  <p>
                                    <b className="me-1">Max Attempt:</b>
                                    {subject?.maxAttempt}
                                  </p>
                                </div>
                                <div className="row quesDeatails boxshadow p-2">
                                  <table>
                                    <tr>
                                      <th className="colorblue text-center">Question Range</th>
                                      <th className="colorblue text-center">Type</th>
                                      <th className="colorblue text-center">Correct Marks</th>
                                      <th className="colorblue text-center">Negative Marks</th>
                                    </tr>

                                    {subject?.parts?.map((parts) => (
                                      <tr>
                                        <td className="text-center">{`${parts?.questionFrom} - ${parts?.questionTo}`}</td>
                                        <td className="text-center">{parts?.questionTypeName}</td>
                                        <td className="text-center successcolor">
                                          {parts?.correct}
                                        </td>
                                        <td className="text-center errorcolor">
                                          {parts?.negative}
                                        </td>
                                      </tr>
                                    ))}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <NoRecord />
                )}
              </Tab>
              <Tab eventKey="three" title="Paper Analysis ">
                {paperAnalysis ? (
                  <div className="childtabcontent shadow bgwhite paperAnalysis">
                    {ReactHtmlParser(paperAnalysis?.topicData)}
                  </div>
                ) : (
                  <NoRecord />
                )}
              </Tab>
              <Tab eventKey="four" title="Cut off">
                {CutoffData ? (
                  <div className="childtabcontent shadow bgwhite paperAnalysis">
                    {ReactHtmlParser(CutoffData?.cutOffData)}
                  </div>
                ) : (
                  <NoRecord />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllAbout;
