import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ChatFooter = ({ socket }) => {
  const [message, setMessage] = useState('');
  const handleTyping = () => socket.emit('typing', `${localStorage.getItem('userName')} is typing`);
  const { firstName, _id, lastName } = useSelector((state) => ({
    firstName: state?.AuthReducer?.firstName,
    _id: state?.AuthReducer?._id,
    lastName: state?.AuthReducer?.lastName
  }));
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim()) {
      const id = `${socket.id}${Math.random()}`;
      socket.emit('message', {
        text: [{ text: message, user: { _id, name: firstName + ' ' + lastName }, _id: id }],
        name: firstName + ' ' + lastName,
        userId: _id,
        id,
        socketID: socket.id
      });
    }

    setMessage('');
  };

  return (
    <div className="chat__footer">
      <form className="form d-flex Justify-content-between" onSubmit={handleSendMessage}>
        <input
          type="text"
          placeholder="Write message"
          className="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button className="btn btn-success" id="myBtn">
          SEND
        </button>
      </form>
    </div>
  );
};

export default ChatFooter;
