export const containsSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg = "Special Characters are not allowed and cannot start with a number and White Space"
export const containsSpecialCharsOrNumberOrWhiteSpaceErrMsg = "Number, White Space and Special Characters are not allowed!"
export const validateIsFileImageTypeErrMsg = "File should be type of Image only!"
export const validateIsFilePDFTypeErrMsg = "File should be type of PDF only!"
export const validateIsUrlPatternErrMsg = "Invalid URL pattern!"
export const validateisValidPriceErrMsg = "Invalid Price!"
export const isValidDiscountErrMsg = "Please enter between 1 and 99 only"
export const validateFullNameErrMsg = "Please enter valid name. "
export const validateLastNameErrMsg = "Please enter valid Last name. "
export const validateLoginIdErrMsg = "Please enter valid login ID"
export const validatePasswordErrMsg = "Please enter valid password"
export const validateEmailAddressErrMsg = "Please enter valid email address"
export const validateCouponCodeNameErrMsg = "Please enter valid coupon code"
export const validateIsNumberOnlyErrMsg = "Please enter number only"
export const noFileUploadedErrMsg = "Please upload a file";
export const containsAllowedSpecialCharsOrNumberOrWhiteSpaceMsg="Only Number char and & , : are allowed"
export const markedForReviewMsg = "Now your response is not considered for evaluation";
export const selectOptionMsg = "Please Select An option first or Click on the Next Button";
export const selectOptionNATMsg = "Please provide your input";
export const clearResponseAndClickNextMsg = "Please clear response first and then select An option";
export const maxAttemptReachedMsg = "Max attempt reached";
export const maxDecimalLimiReachedMsg = "Max Decimal Limit reached"
export const validateTenDigitNumberOnlyErrMsg = "Please enter 10 digit number"
export const validateNameNumberNotAllowedErrMsg = "Numbers not allowed "
export const maxAttemptReachedPopupMsg = "You Exceed The Limit Of The Maximum No Questions Were Attempted For This Section.";