import React, { useState } from 'react';
import { createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'shaka-player/dist/controls.css';
import { useEffect } from 'react';
import { showSuccessSnackbar } from '../Redux/Actions/snackbarAction';
import moment from 'moment';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SettingsIcon from '@mui/icons-material/Settings';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import { startEndLiveStatus, updateHomeMoreValues } from '../Redux/Actions/homeAction';
import PremiervedioEndedPopUp from '../Module/premiervideoEndedPopUp';
import { exitFullScreenMode } from '../Utils/utils';

function VideoPlayer() {
  const vidRef = createRef();
  const dispatch = useDispatch();
  const { liveVideoData, userId } = useSelector((state) => ({
    userId: state?.AuthReducer?._id,
    liveVideoData: state?.AuthReducer?.liveVideoData
  }));
  const { classDuration, highLink, lowLink, sessionDate, sessionTime } = liveVideoData;
  const [link, setLink] = useState(highLink);
  const [selectedVideoCurrentTime, setSelectedVideoCurrentTime] = useState(0);
  const [settings, setSetting] = useState(false);
  const [mute, setmute] = useState(false);
  const [time, setTime] = useState({});
  const [openVideo, setopenVideo] = useState(false);
  const [volume, setVolume] = useState(1);
  const finalVolume = mute ? 0 : volume ** 2;
  let manifestUri = link;
  const [linkstatus, setlinkstatus] = useState(1);
  useEffect(() => {
    // setmute(false)
    dispatch(updateHomeMoreValues({ isLoader: true }));
    getVideoSessionTiming();
  }, []);
  async function init() {
    // When using the UI, the player is made automatically by the UI object.
    const video = document.getElementById('video');
    const ui = video['ui'];
    const config = {
      addSeekBar: false,
      controlPanelElements: []
    };
    ui.configure(config);
    const controls = ui.getControls();
    const player = controls.getPlayer();
    player.addEventListener('pause', () => {
      player.play();
    }); 
    window.player = player;
    window.ui = ui;

    controls.addEventListener('pause', () => {
      controls.play();
    });

    // Try to load a manifest.
    // This is an asynchronous process.
    try {
      await player.load(manifestUri);
      dispatch(updateHomeMoreValues({ isLoader: false }));

      // This runs if the asynchronous load is successful.
    } catch (error) {}
  }

  // Listen to the custom shaka-ui-loaded event, to wait until the UI is loaded.
  document.addEventListener('shaka-ui-loaded', init);
  // Listen to the custom shaka-ui-load-failed event, in case Shaka Player fails
  // to load (e.g. due to lack of browser support).
  if (document.getElementById('video')) {
    document.getElementById('video').currentTime = selectedVideoCurrentTime;
  }

  function myFunction(event) {
    // The currentTime property returns the current position of the audio/video playback
    document.getElementById('demo').innerHTML = event.currentTime;
  }

  const handleClick = () => {
    vidRef.current?.play();
  };
  async function handleResolution(premiereLink,val) {
    setSetting((prev) => !prev);
    await player.load(premiereLink);
    setLink(premiereLink);
    setlinkstatus(val)
  }

  function sumTime(times) {
    let sumSeconds = 0;

    times.forEach((timer) => {
      let a = timer.split(':');
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });

    return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
  }

  const getVideoSessionTiming = () => {
    try {
      let now = RemoveSeconds(new Date());
      var arr = moment(sessionDate)
        .format('YYYY-MM-DD')
        .split(/-|\s|:/); // split string and create array.
      var arr1 = sessionTime.split(/-|\s|:/);
      var arr2 = sumTime([sessionTime, classDuration]).split(/-|\s|:/);
      let startDate1 = new Date(arr[0], arr[1] - 1, arr[2], arr1[0], arr1[1], arr1[2]);
      let endDate1 = new Date(arr[0], arr[1] - 1, arr[2], arr2[0], arr2[1], arr2[2]);
      setTime({
        start: RemoveSeconds(startDate1),
        end: RemoveSeconds(endDate1)
      });
      if (RemoveSeconds(startDate1) > now) {
        dispatch(showSuccessSnackbar('Please Wait Lecture will start very soon'));
      } else if (RemoveSeconds(startDate1) <= now && now <= RemoveSeconds(endDate1)) {
        let seconds = (now - RemoveSeconds(startDate1)) / 1000;
        setSelectedVideoCurrentTime(seconds);
      } else {
        handleLiveVideoClick();
      }
    } catch (err) {
      handleLiveVideoClick();
    }
  };

  const handleFullScreen = () => {
    if (vidRef.current.requestFullscreen) {
      vidRef.current.requestFullscreen();
    } else if (vidRef.current.msRequestFullscreen) {
      vidRef.msRequestFullscreen();
    } else if (vidRef.current.mozRequestFullScreen) {
      vidRef.current.mozRequestFullScreen();
    } else if (vidRef.current.webkitRequestFullScreen) {
      vidRef.current.webkitRequestFullScreen();
    }
    setSetting(false)
  };

  const handleExitFullScreen = () =>{
    

      if (document.fullscreenElement === null) {
          // var docElm = document.documentElement;
          // handleFullScreen();
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        }
     
  }
 

  const handleMute = () => {
    setSetting(false)
    setmute((prev) => !prev);
    getVideoSessionTiming();
  };

  window.addEventListener('beforeunload', function (e) {
    e.returnValue = '';
    dispatch(
      startEndLiveStatus({
        userId: userId,
        liveId: liveVideoData.liveId,
        liveType: 2,
        isLive: false
      })
    );
    opener.location.reload();
    window.close();
  });

  const handleSettings = () => {
    setSetting((prev) => !prev);
    getVideoSessionTiming()
  };

  const RemoveSeconds = (dt) => {
    dt.setSeconds(0);
    return dt.getTime();
  };

  const onProgress = () => {
    if (time?.start) {
      let now = RemoveSeconds(new Date());
      const { end } = time;
      if (now && end) {
        if (now > end) {
          handleLiveVideoClick();
        }
      }
    }
  };

  const handleLiveVideoClose = () => {
    setopenVideo(false);
  };

  const handleLoad = () => {
    setmute(true);
  };

  const handleLiveVideoClick = () => {
    //exitFullScreenMode();
    handleExitFullScreen();
    vidRef.current?.pause(); 
    setopenVideo(true);
  };
  const handleVolume = (e) => {
    setVolume(e.target.valueAsNumber);
    setSetting(false)
    if (e.target.valueAsNumber === 0) {
       setmute(true);
     }
     else{
      setmute(false);
     }
    // if (e.target.valueAsNumber === 1) {
    //   setmute(false);
    // } else if (e.target.valueAsNumber === 0) {
    //   setmute(true);
    // }
  };
  return (
    <div className="align-item-center premvideo col-md-12" id="Videoplayer">
      <div
        data-shaka-player-container
        style={{ maxWidth: '99em' }}
        onClick={handleClick}
        data-shaka-player-cast-receiver-id="8D8C71A7"
        shaka-controls="false"
        className="col-md-12">
        <video
          autoPlay
          muted={mute}
          data-shaka-player
          id="video"
          ref={vidRef}
          className="w-100 h-100 "
          // onEnded={signOutMeeting}
          // onError={signOutMeeting}
          // onError={() => {
          //   handleLiveVideoClick();
          // }}
          onEnded={() => {
            //exitFullScreenMode();
            handleExitFullScreen();
            vidRef.current?.pause();
            handleLiveVideoClick();
          }}
          onTimeUpdate={onProgress}
          onLoad={() => {
            handleLoad;
          }}></video>
      </div>
      <div className="col-12 primiervideobottom p-2 d-flex justify-content-end align-items-end position-fixed bottom-0 ">
        <span className="me-1" onClick={handleMute}>
          {mute === false ? <VolumeUpRoundedIcon /> : <VolumeOffRoundedIcon />}
        </span>
        <span className="mb-1">
              <input
              style={{height:"5px"}}
                type="range"
                min={0}
                max={1}
                step={0.02}
                value={volume}
                onChange={(event) => {
                  handleVolume(event);
                }}
              />
            </span>
        <span className="position-relative me-2">
          <SettingsIcon className="cursor ms-3  " alt="setting" onClick={handleSettings} />
          {settings ? (
            <div
              className="videoquality boxshadow bgwhite rounded position-absolute"
              style={{ right: '-15px', zIndex: '1' }}>
              <div
                className={
                  linkstatus === 0
                    ? 'qualityno py-2 border-bottom cursor checkboxbg text-primary '
                    : 'qualityno py-2 border-bottom cursor'
                }
                onClick={() => handleResolution(lowLink,0)}>
                Low Quality
              </div>
              <div
                className={
                  linkstatus === 1
                    ? 'qualityno py-2 border-bottom cursor checkboxbg text-primary '
                    : 'qualityno py-2 border-bottom cursor'
                }
                onClick={() => handleResolution(highLink,1)}>
                High Quality
              </div>
            </div>
          ) : (
            ''
          )}
        </span>
        <span onClick={handleFullScreen}>
          <FullscreenIcon />
        </span>
      </div>
      {openVideo ? (
        <PremiervedioEndedPopUp open={openVideo} handleClose={handleLiveVideoClose} />
      ) : null}
    </div>
  );
}

export default VideoPlayer;
