import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { useState, useEffect } from 'react';
import moment from 'moment';
function ClassroomDropdownNew({
  lableName,
  value,
  options,
  setInputText,
  type,
  reset,
  defaultValue,
  x,
  disabled,
  Isrequired
}) {
  const [names, setName] = useState(lableName);

  useEffect(() => {
    if (x === 'type') {
      var newArray = options?.filter(function (el) {
        return el._id === defaultValue;
      });
      newArray?.map((item) => {
        setName(item.type);
        setInputText(item);
      });
    } else if (x === 'en') {
      var newArray = options?.filter(function (el) {
        return el._id === defaultValue;
      });
      newArray?.map((item) => {
        setName(item.en.name);
        setInputText(item);
      });
    } else if (x === 'order') {
      var newArray = options?.filter(function (el) {
        return el.name === defaultValue;
      });
      newArray?.map((item) => {
        setName(item.name);
        setInputText(item);
      });
    } else if (x === 'ctype') {
      var newArray = options?.filter(function (el) {
        return el.type === defaultValue;
      });
      newArray?.map((item) => {
        setName(item.name);
        setInputText(item);
      });
    } else {
      var newArray = options?.filter(function (el) {
        return el._id == defaultValue;
      });
      newArray?.map((item) => {
        setName(item.name);
        setInputText(item);
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (reset === true) {
      setName(lableName);
    }
  }, [reset]);

  const handleChange = (e) => {
    if (type === 'type') {
      setName(e.target.value.type);
      setInputText(e.target.value);
    } else {
      if (e.target.value.months) setName(e.target.value.months);
      else if (e.target.value.date) setName(moment(e.target.value.date).format('DD - MM - YYYY'));
      else if (e.target.value.en) {
        setName(e.target.value.en.name);
        setInputText(e.target.value);
      } else setName(e.target.value.name);

      setInputText(e.target.value);
    }
  };

  return (
    <div className="d-flex">
      <Select
        className="custom-Dropdown"
        defaultValue={names}
        value={names}
        label="name"
        onChange={handleChange}
        disabled={disabled}
        required={Isrequired}>
        <MenuItem disabled value={names}>
          {names}
        </MenuItem>

        {options !== undefined
          ? options.map((item) => (
              <MenuItem key={item._id} value={item}>
                {item?.name
                  ? item?.name
                  : item?.months
                  ? item?.months
                  : item?.type
                  ? item?.type
                  : item?.en?.name
                  ? item?.en?.name
                  : item?.date
                  ? moment(item.date).format('DD - MM - YYYY')
                  : ''}
              </MenuItem>
            ))
          : null}
      </Select>
      {Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}

export default ClassroomDropdownNew;
