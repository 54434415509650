import React from 'react';
import '../App.css';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function SubjectTestCard({ title, lectcount, time, onpress }) {
  return (
    <div
      className="d-flex boxshadow p-3 rounded justify-content-between align-items-center mb-3 width75 bgwhite "
      style={{ cursor: 'pointer' }}
      onClick={onpress}>
      <div>
        <p className="text-uppercase mb-2 ms-2">{title}</p>
        <span>
          <span className="testicon ms-2">
            <DescriptionIcon />
          </span>{' '}
          <span className="ms-2 fw-bold">{lectcount}{time}</span>
        </span>
      </div>
      <div className="colorblue customicon">
        <ArrowForwardIosIcon />
      </div>
    </div>
  );
}

export default SubjectTestCard;
