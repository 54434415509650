import React from 'react';
import { useEffect, useState } from 'react';
import '../App.css';
import Header from '../Module/header';
import CartCard from '../Module/cartCard';
import CouponCard from '../Module/couponCard';
import { LocalImages } from '../Constant';
import OrderPendingPopup from '../Module/orderPendingPopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeToCart,
  placeOrder,
  updateOrder,
  getNotificationUnread
} from '../Redux/Actions/homeAction';
import useRazorpay from 'react-razorpay';
import { showSuccessSnackbar } from '../Redux/Actions/snackbarAction';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { Navigate, useNavigate } from 'react-router-dom';
import OrderPlacedSuccesfull from '../Module/orderPlacedSuccesfull';
import { updateMoreValues } from '../Redux/Actions/AuthAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';
function PlaceOrder() {
  const { REACT_APP_RAZORPAY_API_KEY, REACT_APP_RAZORPAY_API_KEY_PUBLICATION } = process.env;
  const [orderHolded, setOrderPending] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [paymentOption, setPaymentOption] = useState(1);
  const [orderID, setOrderID] = useState('');
  const [installmentSelected, setInstallmentSelected] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    cartAddedData,
    firstName,
    lastName,
    email,
    mobileNo,
    countryCode,
    address,
    cartListDetails
  } = useSelector((state) => ({
    cartAddedData: state?.HomeReducer?.cartAddedData,
    address: state?.HomeReducer?.address,
    countryCode: state?.AuthReducer?.countryCode,
    loader: state?.HomeReducer?.loader,
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    email: state?.AuthReducer?.email,
    mobileNo: state?.AuthReducer?.mobileNo,
    cartListDetails: state?.HomeReducer?.cartListDetails
  }));

  const Razorpay = useRazorpay();

  const { totalAmount, installmentData, hasStudyMaterial } = cartAddedData;

  const [finalPrice, setFinalPrice] = useState(totalAmount ? totalAmount : 0);
  const handleClose = () => {
    setOrderPending(false);
  };
  const handleInstallment = (e) => {
    setPaymentOption(e.target.value);
    setFinalPrice(installmentData.installment1);
    setInstallmentSelected(true);
  };
  const handleWithoutInstallment = (e) => {
    setPaymentOption(e.target.value);
    setInstallmentSelected(false);
    setFinalPrice(totalAmount ? totalAmount : 0);
  };

  useEffect(() => {
    if (cartListDetails.length === 0) {
      navigate('/MyCart');
    }
  }, [cartListDetails]);

  const callPaymentGateway = (checkoutData) => {
    const { orderData, paymentOrderDataForCheckout } = checkoutData;
    const { amount, currency, id, status } = paymentOrderDataForCheckout;
    const { isPublication } = orderData;
    var options = {
      key: isPublication ? REACT_APP_RAZORPAY_API_KEY_PUBLICATION : REACT_APP_RAZORPAY_API_KEY,
      amount: amount,
      currency: currency,
      name: `${firstName} ${lastName}`,
      description: 'IFAS Edutech Pvt. Ltd.',
      image: 'https://ifas-qa.s3.ap-south-1.amazonaws.com/icons/IFAS-New+Logo-Symbol.png',
      order_id: id,
      handler: function (response) {
        if (response) {
          setOrderPending(true);
          dispatch(
            updateOrder(
              {
                orderId: orderData?.id,
                status: 'success',
                checkoutData: response
              },
              (res) => {
                setOrderPending(false);
                if (res?.length == 0) {
                } else {
                  dispatch(getNotificationUnread());
                  dispatch(updateMoreValues({ hasActivePaidPlan: res?.hasActivePaidPlan }));
                  setOrderID(orderData?.orderNo);
                  setOrderSuccess(true);
                }
              }
            )
          );
        }
      },
      prefill: {
        name: `${firstName} ${lastName}`,
        email: email,
        contact: mobileNo
      },
      notes: {
        address: 'Razorpay Corporate Office'
      },
      theme: {
        color: '#3399cc'
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on('payment.failed', function (response) {});
    rzp1.on('payment.authorized', function (response) {});
    rzp1.open();
  };

  const onPlaceorderClick = () => {
    if (hasStudyMaterial) {
      if (address?._id && paymentOption) {
        dispatch(
          placeOrder(
            {
              payType: paymentOption,
              addressId: address?._id
            },
            (res) => {
              if (Object.keys(res).length === 0) {
                // to do
              } else {
                callPaymentGateway(res);
              }
            }
          )
        );
      } else {
        dispatch(showErrorSnackbar('wentWrong1'));
      }
    } else {
      if (paymentOption) {
        dispatch(
          placeOrder(
            {
              payType: paymentOption
            },
            (res) => {
              // Object.keys(address).length === 0
              if (Object.keys(res).length === 0) {
              } else {
                callPaymentGateway(res);
              }
            }
          )
        );
      } else {
        dispatch(showErrorSnackbar('wentWrong2'));
      }
    }
  };

  const setOnRemove = (id) => {
    dispatch(removeToCart({ cartId: id }));
  };

  function padNumber(number) {
    var string = '' + number;
    string = string?.length < 2 ? '0' + string : string;
    return string;
  }

  const date = new Date();
  const next_date = new Date(date?.setDate(date?.getDate() + 30));
  const formatted =
    next_date?.getUTCFullYear() +
    '-' +
    padNumber(next_date?.getUTCMonth() + 1) +
    '-' +
    padNumber(next_date?.getUTCDate());

  return (
    <div>
      <Header />
      <div className="py-4 backimg">
        <div className="customcontainer">
          <h3 className="fw-normal px-2">Place Order</h3>
          <div className="row">
            <div className="col-md-6 checkoutHeight pe-lg-5 mt-3 pt-2">
              {/* <CartCard />
                            <CartCard /> */}
              {cartListDetails &&
                cartListDetails?.map((c, index) => (
                  <CartCard data={c} setOnRemove={setOnRemove} key={index} />
                ))}
            </div>
            <div className="col-md-6 padbottom-mob40">
              <div className="mb-3 checkoutHeight p-md-3">
                <div className="boxshadow font12 p-3 rounded bgwhite">
                  <p className="colorblue mb-2 fw-bold">Bill Details</p>
                  <p className="mb-0">
                    Total Payable Amount:{' '}
                    <span className="successcolor fw-bold ms-2">
                      &#8377;{' '}
                      {installmentSelected
                        ? cartAddedData.hasInstallmentOption
                          ? installmentData.totalAmountPayable
                          : totalAmount
                          ? totalAmount
                          : 0
                        : totalAmount
                        ? totalAmount
                        : 0}
                    </span>
                  </p>
                  <p className="mb-1">Including all Taxes</p>
                </div>
                {cartAddedData.hasInstallmentOption ? (
                  <>
                    <div className="boxshadow font12 p-3 py-2 rounded mt-3 bgwhite">
                      <p className="colorblue mb-0 fw-bold d-flex">
                        <input
                          type="radio"
                          name="payment"
                          value={1}
                          selected={installmentSelected === false}
                          onChange={handleWithoutInstallment}
                          defaultChecked={installmentSelected === false}
                        />
                        <span
                          className="ms-1 fw-bold"
                          style={{ color: installmentSelected === false ? '' : 'green' }}>
                          Saved Extra &#8377;{cartAddedData?.installmentData?.additionalPrice}
                        </span>
                      </p>
                      <p className="mb-0 ms-3">By paying the fee in one time</p>
                    </div>

                    <div className="boxshadow font12 p-3 py-2 rounded mt-3 bgwhite">
                      <p className="colorblue mb-0 fw-bold d-flex">
                        <input
                          type="radio"
                          name="payment"
                          value={2}
                          selected={installmentSelected === true}
                          defaultChecked={installmentSelected === true}
                          onChange={handleInstallment}
                        />
                        <span
                          className="ms-1 fw-bold"
                          style={{ color: installmentSelected === true ? '' : 'green' }}>
                          Pay fee in installment
                        </span>
                      </p>
                      {installmentSelected ? (
                        <div className="mt-2 ms-3">
                          <p className="mb-1 fw-bold mt-1">
                            Total Payable Amount:{' '}
                            <span className="successcolor fw-bold ms-2">
                              &#8377; {installmentData?.totalAmountPayable}
                            </span>
                          </p>
                          <div className="px-2 py-1 w-50 borderBlue ">
                            <p className="mb-1 fw-bold">
                              1st Installment{' '}
                              <span className="successcolor fw-bold ms-2">
                                &#8377; {installmentData?.installment1}
                              </span>
                            </p>
                            <p className="mb-0 font10">Pay by Today</p>
                          </div>
                          <div className="px-2 py-1 border mt-2 w-50">
                            <p className="mb-1 fw-bold">
                              2nd Installment{' '}
                              <span className="successcolor fw-bold ms-2">
                                &#8377; {installmentData?.installment2}
                              </span>
                            </p>
                            <p className="mb-0 font10">Pay installment by {formatted}</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : (
                  ''
                )}
                {hasStudyMaterial ? (
                  <div className="boxshadow font12 p-3 py-2 rounded mt-3 bgwhite">
                    <p className="mb-2 fw-bold">Shipping Address</p>
                    {/* <p className="colorblue mb-2 fw-bold d-flex">

                                        <input type="radio" name="address" defaultChecked={address.isDefault} /><span className="ms-1">Home</span>
                                    </p> */}
                    {/* <form>
                                        <LocationOnIcon style={{ width: "15px" }} className=" text-secondary cursor"  />
                                    </form> */}
                    <p className="colorblue mb-2 fw-bold d-flex">
                      <LocationOnIcon
                        style={{ width: '15px' }}
                        className=" text-secondary cursor"
                      />
                      <span className="ms-1 mt-1">Home</span>
                    </p>
                    <div className="mb-2 text-break">
                      <p className="mb-0 fw-bold">{address?.label}</p>
                      <p className="mb-0">{address?.line1}</p>
                      <p className="mb-0">{address?.line2}</p>
                      <p className="mb-0">{address?.landmark} </p>
                      <p className="mb-0">
                        {address?.city?.name}, {address?.pincode}
                      </p>
                      {address?.alternatePhoneNumber ? (
                        <p className="mb-0">Phone Number: +91-{address?.alternatePhoneNumber}</p>
                      ) : null}
                    </div>
                    {/* <div className="border d-inline font10 px-2 py-1 mt-2 cursor"><img src={LocalImages.edit} alt="Edit" style={{ width: "10px" }} /><span className="ms-1 rounded">Edit</span></div> */}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <CouponCard
                btnname="Place Order"
                setOnClick={onPlaceorderClick}
                Price={
                  installmentSelected
                    ? cartAddedData.hasInstallmentOption
                      ? installmentData?.installment1
                      : totalAmount
                      ? totalAmount
                      : 0
                    : totalAmount
                    ? totalAmount
                    : 0
                }
                installmentSelected={
                  cartAddedData.hasInstallmentOption ? installmentSelected : false
                }
                installmentData={installmentData}
                itemCount={cartListDetails?.length}
              />
            </div>
          </div>
        </div>
      </div>
      {orderHolded ? <OrderPendingPopup open={orderHolded} handleClose={handleClose} /> : null}
      {orderSuccess ? <OrderPlacedSuccesfull orderID={orderID} /> : ''}
    </div>
  );
}

export default PlaceOrder;
