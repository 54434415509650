import React, { useState } from 'react';
import '../Assets/CSS/home.css';
import appAchievementvideo from '../Assets/Video/IFAS-Achievements.mp4';
import Footer from '../Component/footer';
import { LocalImages } from '../Constant';
import Lottie from 'react-lottie';
import Expert from '../Assets/Images/JSON/expert.json';
import TalktoExpert from '../Module/talktoExpert';

import CourseDropdown from '../Component/courseDropdown';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import kcsir from '../Assets/Images/AboutUs/kc.png';
import rcsir from '../Assets/Images/AboutUs/rc.png';
import djsir from '../Assets/Images/AboutUs/dj.png';
import dssir from '../Assets/Images/AboutUs/ds.png';
import rtsir from '../Assets/Images/AboutUs/rt.png';
import bhushansir from '../Assets/Images/AboutUs/bhushan.png';
import HeaderHome from '../Module/headerHome';
import Header from '../Module/header';
import { useEffect } from 'react';
// import MetaTitle from '../Module/metaTitle';
import { Helmet } from 'react-helmet';
const AboutUs = (props) => {
  const [open, setopen] = useState(false);
  const [openCourse, setopenCourse] = useState(false);
  const [isClassroomProgram, setIsClassroomProgram] = useState(false);
  const { ACCESS_TOKEN_NEW } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));
  // useEffect(() => {
  //   document.title = props.title;
  // }, []);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Expert,
    renderer: 'svg'
  };

  const closePopup = () => {
    setopenCourse(false);
    setIsClassroomProgram(false);
  };
  const signInroute = () => {
    navigate('./SignIn');
    // window.location.href = 'SignIn';
    // return navigate("./SignIn");
  };

  const handleClose = () => {
    setopen(false);
  };
  const handleClick = (event) => {
    setopen(true);
  };
  const vision = [
    {
      name: 'Student Happiness',
      path: LocalImages.StudentHappyness,
      txt: 'Make a positive difference in the lives of our students.'
    },
    {
      name: 'Think Big',
      path: LocalImages.ThinkBig,
      txt: 'With every milestone, aim higher and strive to achieve it.'
    },
    {
      name: 'Reform',
      path: LocalImages.Reform,
      txt: 'Innovate the oldest industry and find better ways to do it.'
    },
    {
      name: 'Nurturing',
      path: LocalImages.Nurturing,
      txt: 'Grow by investing in people.'
    },
    {
      name: 'Respect Everyone',
      path: LocalImages.RespectEveryone,
      txt: 'We value our colleagues, our students and hold the utmost regard for everyone.'
    }
  ];

  return (
    <div className="">
      {/* <MetaTitle
        title="About Us - IFAS"
        description="Know about IFAS application, how and when it started, what are the benefits etc. here."
      /> */}
         <Helmet>
          <title>About Us - IFAS</title>
          <meta
            name="description"
            content="Know about IFAS application, how and when it started, what are the benefits etc. here."
          />
          <link rel="canonical" href='about-us'/>
        </Helmet>
      {ACCESS_TOKEN_NEW == '' || ACCESS_TOKEN_NEW == undefined ? <HeaderHome /> : <Header />}
      <div className="aboutusbanneerdiv w-100">
        <div className="">
          <h1>IFAS: Transformation through Education</h1>
          <img className="aboutusbanner" src={LocalImages.AboutUsBannerold} alt="Banner" />
          <h2>IFAS is India's #1 EduTech Company for IIT JAM, CSIR - UGC NET & GATE.</h2>
        </div>
      </div>
      <div className="row d-flex content-mission ">
        <div className="row d-flex col-md-6 ourmisdiv">
          <div className="col-sm-5 text-center ">
            <img className="missionimg" src={LocalImages.Ourmission} alt="Ourmission" />
          </div>
          <div className="col-sm-7">
            <h1 className="fw-bold">
              Our <span style={{ color: '#1da0f1' }}>Mission</span>{' '}
            </h1>
            <h4 style={{ color: '#000' }}>
              Democratising Online Education through Personalized Learning
            </h4>
          </div>
        </div>
        <div className="col-md-6 row d-flex ">
          <div className="col-sm-5 text-center ">
            <img className="missionimg" src={LocalImages.Ourvision} alt="Ourmission" />
          </div>
          <div className="col-sm-7">
            <h1 className="fw-bold">
              Our <span style={{ color: '#1da0f1' }}>Vision</span>{' '}
            </h1>
            <h4 style={{ color: '#000' }}>
              We are and will remain #1 brand for Competitive Examination Preparation, helping the
              highest number of students to succeed.
            </h4>
          </div>
        </div>
      </div>

      <div
        className="text-center common-pad pt-3 pb-4 wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0s">
        <h1>
          Our <span style={{ color: ' #1da0f1' }}>Values</span>
        </h1>
        <div className="row d-flex">
          {vision.map((item, index) => {
            return (
              <div className="col-md-2 boxshadow rounded-3 m-3 p-2">
                <img style={{ width: '130px' }} src={item.path} alt={item.name}></img>
                <p className="valuetext fw-bold">{item.name}</p>
                <p>{item.txt}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className="common-pad pt-3 pb-4 wow fadeIn team"
        data-wow-duration="2s"
        data-wow-delay="0s">
        <h2 className="boldfont colorblue text-center my-5">Pillars of IFAS</h2>
        <div className="mt-4 width75 m-auto">
          <div className="d-flex justify-content-center flex-wrap">
            <div className="pillerbox">
              <img src={kcsir} alt="Dr. Kailash Choudhary" className="img-fluid" />
              <p className="fw-bold pillername mb-2 mt-2">Dr. Kailash Choudhary</p>
              <span className="colorblue">Co-Founder and Chairman</span>
            </div>
            <div className="pillerbox">
              <img src={rcsir} alt="Dr. Kailash Choudhary" className="img-fluid" />
              <p className="fw-bold pillername mb-2 mt-2">Radheshyam Choudhary</p>
              <span className="colorblue">Co-Founder and CEO</span>
            </div>
          </div>
          <div className="d-flex justify-content-center my-3 flex-wrap">
            <div className="pillerbox">
              <img src={djsir} alt="Digambar Jagtap" className="img-fluid" />
              <p className="fw-bold pillername mb-2 mt-2">Digambar Jagtap</p>
              <span className="colorblue">COO</span>
            </div>
            <div className="pillerbox">
              <img src={dssir} alt="Dadasaheb Sondge" className="img-fluid" />
              <p className="fw-bold pillername mb-2 mt-2">Dadasaheb Sondge</p>
              <span className="colorblue">CLO</span>
            </div>
            <div className="pillerbox">
              <img src={rtsir} alt="Romila Tutoo" className="img-fluid" />
              <p className="fw-bold pillername mb-2 mt-2">Romila Tutoo</p>
              <span className="colorblue">CMO</span>
            </div>
            <div className="pillerbox">
              <img src={bhushansir} alt="Bhushan Mude" className="img-fluid" />
              <p className="fw-bold pillername mb-2 mt-2">Bhushan Mude</p>
              <span className="colorblue">CTO</span>
            </div>
          </div>
          <div className="d-flex justify-content-center flex-wrap"></div>
        </div>
      </div>

      <div
        className="app-achiev common-pad pb-2 text-center wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0s">
        <h2 className="fw-bold boldfont">
          What makes IFAS <span className="colorblue">ahead of others</span>
        </h2>
        <div className="text-center">
          <video muted autoPlay loop className="videotag m-auto" id="videoApp">
            <source src={appAchievementvideo} type="video/mp4" />
          </video>
        </div>
      </div>
      <div
        className="common-pad justify-content-center mt-0  py-0 position-relative wow fadeIn"
        data-wow-duration="2s">
        <img src={LocalImages.aboutifas} alt="about" className="img-fluid " />
        <button className="btn btn-dark knowmorebtn px-5 position-relative" onClick={signInroute}>
          Know more
        </button>
      </div>
      <Footer />
      <div
        className="position-fixed backcolor rounded-circle cursor"
        style={{ right: '20px', bottom: '30px' }}>
        <a className="d-block d-md-none talkexpertbtn" href="tel:+919172266888">
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </a>
        <span className="d-none d-md-block" onClick={handleClick}>
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </span>
      </div>
      <TalktoExpert open={open} handleClose={handleClose} />

      <CourseDropdown
        open={openCourse}
        isClassroomProgram={isClassroomProgram}
        handleClose={closePopup}
      />
    </div>
  );
};

export default AboutUs;
