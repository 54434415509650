import React, { useState } from 'react';

import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Lottie from 'react-lottie';
import orderCompleted from '../Assets/Images/JSON/ordercompleted.json';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function OrderPlacedSuccesfull(props) {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orderCompleted,
    renderer: 'svg'
  };
  const { hasActivePaidPlan } = useSelector((state) => ({
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan
  }));
  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
 navigate('/DashboardMenu/Dashboard');
  };
  const homepage = () => {
    setOpen(false);
  navigate('/DashboardMenu/Dashboard');
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <div className="modeldiv p-5 text-center">
          <Lottie options={defaultOptions} height={130} width={200}></Lottie>
          <p className="text-center colorblue font12">
            <b>Your Booking Is Confirmed !!</b>
          </p>
          <p className="text-center font11 ">Please check you email for Booking details</p>
          <button type="button" className="btn rounded-pill btn-primary w-50" onClick={homepage}>
            Proceed to home
          </button>
        </div>
      </Fade>
    </Modal>
  );
}

export default OrderPlacedSuccesfull;
