import React from 'react';
import '../App.css';
import LectureCard from '../Component/lectureCard';
import { useLocation } from 'react-router-dom';
import Header from '../Module/header';
import HeaderHome from '../Module/headerHome';
import { useSelector } from 'react-redux';

function FreeLectures() {
  const location = useLocation();
  const free = location?.state?.free;
  const { ACCESS_TOKEN_NEW } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));
  return (
    <div>
      {ACCESS_TOKEN_NEW ? <Header /> : <HeaderHome />}
      <div className="common-pad">
        <div className="freeuserrightsidepanel py-4">
          <div>
            <h2 className="px-2">Free Lectures</h2>
            <h5 className="px-2">Watch free classes from top educators</h5>
          </div>
          <div className="mt-2">
            <div className="row">
              {free?.map((c) => {
                return <LectureCard data={c} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreeLectures;
