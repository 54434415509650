import React from 'react';
import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateHomeValues } from '../Redux/Actions/homeAction';

function NotificationPopup(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="modeldiv p-md-4 py-md-4 talktoexpertModel">
          <div className="m-2 insttxtDiv bgwhite">
            <div className="col mb-2 d-flex justify-content-end ">
              <ClearOutlinedIcon
                className="colorblue cursor"
                onClick={() => {
                  props.handleClose();
                }}
              />
            </div>
            <div className="col mb-2 d-flex justify-content-between ">
              <div className="col-sm-1 justify-centent-end">
                <img src={props.icon} style={{ width: '30px' }} alt={props.title}/>
              </div>
              <div className="col">
                <p className="fw-bold boldfont ms-2">
                  <span>{props.title}</span>
                </p>
                <p align="justify" className="px-2 text-break">
                  <span>{props.description}</span>
                </p>
              </div>
            </div>
            {props.link && <div className='text-center'>
              <button
                className="btn btn-primary w-40 py-md-1 py-lg-0 font-12 mx-auto"
                onClick={() => {
                  window.open(props.link, '_blank');
                }}>
                Click Now
              </button>
            </div>}
            {(props.id === 1 || props.id === 2) && <div className='text-center'>
              <button
                className="btn btn-primary w-40 py-md-1 py-lg-0 font-12 mx-auto"
                onClick={() => {
                  dispatch(updateHomeValues({ 'selectedTiles': props.dataCard }));
                  navigate('/PaidUser/MyLearning');
                }
                }>
                Click Now
              </button>
            </div>}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default NotificationPopup;
