import React, { useState, useEffect } from 'react';
import '../App.css';
import logo from '../Assets/Images/Home/logo.png';
import cart from '../Assets/Images/Dashboard/cart.png';
import bell from '../Assets/Images/Dashboard/bell.png';
import profile from '../Assets/Images/Dashboard/profile.png';
import home from '../Assets/Images/Menu/home.png';
import readingBook from '../Assets/Images/Dashboard/readingBook.png';
import courses from '../Assets/Images/Menu/courses.png';
import learn from '../Assets/Images/Menu/learn.png';
import address from '../Assets/Images/Menu/Address.png';
import orderhistroy from '../Assets/Images/Menu/orderhistroy.png';
import logout from '../Assets/Images/Menu/logout.png';
import Nonotification from '../Assets/Images/JSON/no-notification.json';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Logout } from '../Redux/Actions/AuthAction';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CourseList from './courseList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MenuButton from '../Component/menuButton';
import { getCourseHome } from '../Redux/Actions/homeAction';
import StudyMaterialDropdown from '../Component/studyMaterialDropdown';
function Header() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [notice, setnotify] = useState(false);
  const dispatch = useDispatch();
  const [openStudy, setOpenStudy] = useState(false);
  const [openCourse, setopenCourse] = useState(false);
  const [menu, showMenu] = useState(false);
  const [isClassroomProgram, setIsClassroomProgram] = useState(false);
  const [isExam, setIsExam] = useState(false);
  const [selectedmenu, setselectedmenu] = useState('');
  const classroomListroute = () => {
    setIsExam(false);
    setIsClassroomProgram(!isClassroomProgram);
  };

  const {
    unReadCount,
    firstName,
    lastName,
    cartItemCount,
    mobileNo,
    countryCode,
    isClassRoom,
    classroomId
  } = useSelector((state) => ({
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    mobileNo: state?.AuthReducer?.mobileNo,
    cartItemCount: state?.AuthReducer?.cartItemCount,
    unReadCount: state?.AuthReducer?.unReadCount,
    countryCode: state?.AuthReducer?.countryCode,
    classroomId: state?.AuthReducer?.classroomId,
    isClassRoom: state?.AuthReducer?.isClassRoom
  }));

  const { examTypeName } = useSelector((state) => ({
    examTypeName: state?.AuthReducer?.examTypeName
  }));
  const { hasActivePaidPlan } = useSelector((state) => ({
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan
  }));

  useEffect(() => {
    if (examTypeName === undefined) {
      setopenCourse(true);
    }
  }, []);

  const dashbord = () => {
    navigate('/');
  };
  const courseList = () => {
    setopenCourse(true);
  };

  const mycart = () => {
    navigate('../MyCart');
  };

  const studyListRoute = () => {
    setIsClassroomProgram(false);
    setopenCourse(false);
    setOpenStudy(true);
  };

  const notification = () => {
    navigate('../Notification');
  };
  const closePopup = () => {
    if (examTypeName === undefined) setopenCourse(true);
    else setopenCourse(false);
  };

  const handleClick = async (item) => {
    showMenu(false);
    if (item.title === 'Logout') {
      await dispatch(Logout());
    } else {
      await navigate(item?.link);
    }
  };
  const menuData =
    hasActivePaidPlan === true
      ? [
        {
          title: 'Dashboard',
          imgpath: home,
          link: '../PaidUser/PaidDashboard'
        },
        {
          title: 'My Courses',
          imgpath: readingBook,
          link: '../PaidUser/MyLearning'
        },
        {
          title: 'All Courses',
          imgpath: courses,
          link: '../PaidUser/OnlineCourse'
        },
        {
          title: 'Learn free',
          imgpath: learn,
          link: '../PaidUser/Learn'
        },
        {
          title: 'My Profile',
          imgpath: profile,
          link: '../PaidUser/myProfile'
        },
        {
          title: 'Addresses',
          imgpath: address,
          link: '../PaidUser/MyAddress'
        },
        {
          title: 'Order History',
          imgpath: orderhistroy,
          link: '../PaidUser/OrderHistory'
        },
        {
          title: 'Logout',
          imgpath: logout,
          link: '../'
        }
      ]
      : [
        {
          title: 'Dashboard',
          imgpath: home,
          link: '../DashboardMenu/Dashboard'
        },
        {
          title: 'My Profile',
          imgpath: profile,
          link: '../DashboardMenu/MYProfile'
        },
        {
          title: 'Courses',
          imgpath: courses,
          link: '../DashboardMenu/OnlineCourse'
        },
        {
          title: 'Learn',
          imgpath: learn,
          link: '../DashboardMenu/Learn'
        },
        {
          title: 'Addresses',
          imgpath: address,
          link: '../DashboardMenu/MyAddress'
        },
        {
          title: 'Order History',
          imgpath: orderhistroy,
          link: '../DashboardMenu/OrderHistory'
        },
        {
          title: 'Logout',
          imgpath: logout,
          link: '../'
        }
      ];

  const clickNotice = () => {
    setnotify(!notice);
    setShow(false);
  };
  const clickProfile = () => {
    setShow(!show);
    setnotify(false);
  };
  const handleOpen = () => {
    setnotify(true);
  };
  const handleClose = () => {
    setnotify(false);
  };

  const toggleMenu = () => {
    showMenu(!menu);
    if (menu) {
      document.getElementById('sidebarMenu').classList.add('active');
    } else {
      document.getElementById('sidebarMenu').classList.remove('active');
    }
  };

  const closePopupStudy = () => {
    setopenCourse(false);
    setIsClassroomProgram(false);
    setOpenStudy(false);
  };

  const Course = [
    {
      title: 'LIFE SCIENCE'
    },
    {
      title: 'CHEMICAL SCIENCE'
    },
    {
      title: 'PHYSICAL SCIENCE'
    },
    {
      title: 'MATHEMATICAL SCIENCE'
    }
  ];

  const handleClickClassroom = (id) => {
    setIsExam(false);
    if (hasActivePaidPlan === true) {
      navigate('/PaidUser/ClassroomCoachingDetails', { state: { classroomId: id } });
    } else {
      navigate('/DashboardMenu/ClassroomCoachingDetails', { state: { classroomId: id } });
    }
  };
  const handleClickClassroomByName = (val) => {
    setIsExam(false);
    if (hasActivePaidPlan === true) {
      navigate('/PaidUser/ClassroomCoachingDetails', { state: { baseCourseName: val } });
    } else {
      navigate('/DashboardMenu/ClassroomCoachingDetails', { state: { baseCourseName: val } });
    }
  };

  return (
    <>
      <header
        className={
          window.location.pathname === '/Dashboard' || window.location.pathname === '/Home'
            ? 'd-flex justify-content-between position-sticky bgpurple'
            : 'd-flex justify-content-between position-sticky'
        }>
        <div className="d-flex align-items-center">
          <img
            src={logo}
            alt="IFAS Logo"
            className="headerlogo cursor"
            onClick={dashbord}
            style={{ width: '120px' }}
          />

          {window.location.pathname !== '/PaidUser/PaidDashboard' ? (
            <div className="ms-md-4" style={{ cursor: 'pointer' }} onClick={courseList}>
              {examTypeName}
              {examTypeName && window.location.pathname !== '/CourseList' ? (
                openCourse ? (
                  <ArrowDropUpIcon className="text-dark" fontSize="medium" />
                ) : (
                  <ArrowDropDownIcon className="text-dark" fontSize="medium" />
                )
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {isClassRoom && classroomId ? (
            <button
              className="btn ms-2 py-1 text-muted fs-md-6 border-0 position-relative"
              onClick={() => {
                handleClickClassroom(classroomId);
              }}>
              Classroom Program{' '}
            </button>
          ) : (
            <button
              className="btn ms-2 py-1 text-muted fs-md-6 border-0 position-relative d-none d-md-inline-block"
              onClick={classroomListroute}>
              Classroom Program{' '}
              {/* <KeyboardArrowDownIcon className="text-dark" fontSize="large" /> */}
              {isClassroomProgram ? (
                <ArrowDropUpIcon className="text-dark" fontSize="medium" />
              ) : (
                <ArrowDropDownIcon className="text-dark" fontSize="medium" />
              )}
              {isClassroomProgram ? (
                <div className="position-absolute classroompopup bgwhite boxshadow border">
                  {Course.map((c) => {
                    return (
                      <div
                        className="d-flex justify-content-between border-bottom cursor p-2 "
                        onClick={() => {
                          handleClickClassroomByName(c.title);
                        }}>
                        <p className="mb-0 font13">{c.title}</p>
                        <div className="text-end">
                          <ArrowRightIcon />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
            </button>
          )}
          <span className="carticon position-relative d-none d-md-inline-block">
            <button
              className="btn ms-2 py-1 text-muted fs-md-6 border-0 position-relative"
              onClick={studyListRoute}>
              Study Material
              {isExam === true ? (
                <ArrowDropUpIcon className="text-dark" fontSize="medium" />
              ) : (
                <ArrowDropDownIcon className="text-dark" fontSize="medium" />
              )}
            </button>
          </span>
        </div>
        {/* <div className='ms-auto me-4'>
          <p className="fw-bold mb-0 pt-2" style={{fontSize:"16px"}}>
            For any query or Career guidance <br/> kindly contact at{' '}
            <span className="landingPhone boldfont cursor me-1"> +91-91 72266 888 </span>
          </p>
        </div> */}
        <div className="py-1">
          <div className="carticon d-none d-md-inline-block ">
            <div className=" me-4">
              <img
                className="icon py-1 cursor position-relative"
                style={{ width: '27px' }}
                src={cart}
                alt="cart"
                onClick={mycart}
              />
              {cartItemCount ? (
                <span className="bg-primary position-absolute top-5 end-30 translate-middle badge rounded-pill">
                  {cartItemCount}
                </span>
              ) : (
                ''
              )}
            </div>
            {/* <img className='icon py-1 cursor position-relative' style={{ width: "27px" }} src={cart} alt="cart" onClick={() => setcart(!cartitem)} />
            {cartItemCount ?<span className="bg-primary position-absolute top-5 end-30 translate-middle badge rounded-pill">
            {cartItemCount}
                        </span>:""}
            <div className="cartbox boxshadow position-absolute p-3 rounded font12 cartdiv">

              {cartListDetails?.map((item) => (<div className="row d-flex align-items-center border-bottom border-2">
                <div className="col-sm-4">
                  <img className='icon py-1 ' style={{ width: "110px" }} src={LocalImages.liveimg} alt="cart" />
                </div>
                <div className="col-sm-8">
                  <p className="mb-0 text-secondary ">{item?.name}:</p>

                  <p className="mb-2 text-secondary">&#8377; {item?.discountedPrice ? item?.discountedPrice : item?.price}</p>
                </div>
              </div>))}
              <div className="row d-flex">
                <div className='col-sm-6 mt-4 courseLeftside'>
                  <p className="font-14 "><b>Total Amount</b></p>
                </div>
                <div className="col-sm-6 mt-4 ">
                  <p className="font-12 mb-0 colorblue"><b>&#8377; {cartAddedData?.sellingPrice}</b></p>
                  <p className="mb-2 text-secondary">({cartAddedData?.courses?.length} item)</p>
                </div>
              </div>
              <div className="">
                <button className="position-sticky btn btn-primary d-flex justify-content-center w-100 cursor" onClick={mycart} >Go to cart
                  <ArrowForwardIosIcon fontSize="small" className="mt-1" /></button>
              </div>
            </div> */}
          </div>
          <div className="carticon d-none d-md-inline-block ">
            <div className="me-2">
              <img
                className="icon  py-1  cursor position-relative "
                src={bell}
                alt="bell"
                onClick={notification}
              />
              {unReadCount > 0 && (
                <span className="bg-primary position-absolute top-5 start-91 translate-middle badge rounded-pill">
                  {unReadCount}
                </span>
              )}
            </div>
            {/* {notice ?
              <div className="position-absolute notiDiv ">
                <div className="position-absolute transperentbg" onClick={() => setnotify(false)}></div>
                <div className="notification boxshadow position-absolute p-3 rounded font12 notificationbell">
                  <div className="align-items-center w-60 mt-1">
                    <ul className="nav nav-pills">
                      {tabs?.map((c) => (<li className="nav-item">
                        <a className={` ${c.id === selectedIndex.id ? "bluetxt" : 'graytxt'} btn-sm position-relative mx-2 px-2 py-2 text-capitalize rounded-pill mb-5`} href="#" role="button" onClick={() => handleNotification(c)}>  {c.title}
                          <span className={` ${c.id === selectedIndex.id ? "bg-primary" : 'bg-secondary text-dark'} position-absolute top-0 start-98 translate-middle badge rounded-pill`}>
                            {c.name === 'classSchedule' ? notificationForClassSchedule.length : c.name === 'testSeries' ? notificationForTestSeries.length : notificationForOthers.length}
                          </span>
                        </a>
                      </li>))}
                    </ul>
                  </div>
                  {data.length !== 0 ? data.map((item) => (
                    <div className='mt-4 courseLeftside'>
                      {moment().diff(item[0]?.notificationDate, "days") == 0 ? (
                        <p className="font-12 m-2 text-secondary">
                          <b>TODAY</b>
                        </p>
                      ) : moment().diff(item[0]?.notificationDate, "days") == 1 ? (
                        <p className="font-12 m-2 text-secondary">
                          <b>YESTERDAY</b>
                        </p>
                      ) : (
                        <p className="font-12 m-2 text-secondary">
                          <b>{item[0]?.notificationDate}</b>
                        </p>
                      )}

                      {item?.map((c) => (
                        <div className=" row align-items-center border-bottom border-2 p-1">
                          <div className="col-sm-2 justify-centent-end">
                            <img src={videoicon} style={{ width: "30px" }} alt="bell" />
                          </div>
                          <div className="col-sm-7">
                            <p className=" successcolor font15 m-0">
                              <b>Watch Now</b>
                            </p>
                            <p className=" font-12 m-0">
                              {c.title}
                              {c.notificationType}
                            </p>
                            <p className=" font-12 m-0">{c.description}</p>
                          </div>
                          <div className="col-sm-3">
                            <p className="font10 m-2 text-secondary">{`${moment(
                              nfDate
                            ).format("h:mm a")}`}</p>
                          </div>
                        </div>
                      ))}
                    </div>)) :
                    <div className="px-2 mb-5 mt-5 bgwhite">
                      <Lottie options={defaultOptions} height={75} width={75}></Lottie>
                      <p className="text-center font15"><b>No New Notification</b></p>
                    </div>
                  }

                  {data.length !== 0 ?
                    <div className="">
                      <button className="position-sticky btn btn-primary d-flex justify-content-center w-100 cursor" onClick={notification} >View More
                        <ArrowForwardIosIcon fontSize="small" className="mt-1" /></button>
                    </div> : null
                  }
                </div>
              </div>
              : null
            } */}
          </div>
          <span className="profilebtn boxshadow d-none d-md-inline" onClick={clickProfile}>
            <img
              src={profile}
              alt="Profile"
              className="rounded-circle py-1"
              style={{ width: '28px' }}
            />
          </span>
        </div>
        {show ? (
          <div className="position-absolute notiDiv">
            <div className="position-absolute transperentbg" onClick={() => setShow(false)}></div>
            <div
              className="boxshadow position-absolute p-3 rounded font12"
              style={{ background: '#fff', top: '70px', right: '40px' }}>
              <div className="d-flex align-items-center border-bottom border-2 pb-2 mb-2">
                <div className="profilePhoto shadow rounded-circle">
                  <img
                    src={profile}
                    alt="Profile"
                    className="rounded-circle"
                    style={{ width: '20px' }}
                  />
                </div>
                <div className="ms-3">
                  <div className="colorblue">
                    {firstName} {lastName}
                  </div>
                  <div className="color-grey">
                    {countryCode}-{mobileNo}
                  </div>
                </div>
              </div>
              <div className="px-2">
                {menuData.map((c) => (
                  <div
                    className="pb-2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleClick(c);
                    }}>
                    <img src={c.imgpath} alt={c.title} style={{ width: '17px' }} />{' '}
                    <span className="ms-3">{c.title}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        <div className="d-xs-flex d-sm-flex d-md-none me-2 cursor" onClick={toggleMenu}>
          <MenuIcon />
        </div>
      </header>
      <CourseList open={openCourse} handleClose={closePopup} />
      <StudyMaterialDropdown open={openStudy} handleClose={closePopupStudy} />
    </>
  );
}

export default Header;
