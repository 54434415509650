import moment from 'moment';
import life_science from './../Assets/DummyData/life_science.json';
import mathematical_science from './../Assets/DummyData/mathematical_science.json';
import physical_science from './../Assets/DummyData/physical_science.json';
import chemical_science from './../Assets/DummyData/chemical_science -.json';
import ReactHtmlParser from 'react-html-parser';
export const getQuestionType = (key) => {
  const hashmap = {
    0: 'MCQ',
    1: 'MCQ',
    2: 'MSQ',
    3: 'NAT'
  };
  return key in hashmap ? hashmap[key] : '';
};

export const _retrieveData = async ({
  examId,
  navigate,
  setLoading,
  dispatch,
  getExamById,
  notSyncedDataFromStore,
  selectedQuestionIndex,
  notSyncedDataAction,
  examIdDataFromStore,
  setInstructionsAction
}) => {
  try {
    let strNotSyncedData = notSyncedDataFromStore || '{}';
    let notSyncedData = JSON.parse(strNotSyncedData);
    selectedQuestionIndex = +(notSyncedData[examId] || 0);
    dispatch(notSyncedDataAction(JSON.stringify(notSyncedData)));
  } catch (error) {}

  try {
    const value = examIdDataFromStore;
    if (value !== null) {
      let examData = JSON.parse(value);
      let examDataT = JSON.parse(`{"examData": [${value}]}`);

      dispatch(setInstructionsAction(examDataT));
      if (examData.isSubmitted === true) {
        //   navigate('/DetailResult', {state: {examId: examId, examData: examData}})
        sessionStorage.setItem('examId', examId);
        sessionStorage.setItem('examData', JSON.stringify(examData));
        window.open('/DetailResult', '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
      } else {
        sessionStorage.setItem('examId', examId);
        sessionStorage.setItem('examData', JSON.stringify(tmp));
        localStorage.setItem('startTest', '1');
        var windowName = 'userConsole';
        var popUp = window.open(
          '/ExamInstruction',
          windowName,
          '_blank',
          'toolbar=yes,scrollbars=yes,resizable=yes',
          'width=' + screen.availWidth + ',height=' + screen.availHeight
        );
        if (popUp == null || typeof popUp == 'undefined') {
          localStorage.setItem('startTest', '2');
          alert('Please disable your pop-up blocker and click the "Open" link again.');
        } else {
          popUp.focus();
        }
      }
    } else {
      const args = { examId, navigate, setLoading, dispatch, getExamById };
      getExamAPI(args);
    }
  } catch (error) {
    const args = { examId, navigate, setLoading, dispatch, getExamById };
    getExamAPI(args);
  }
};

export const getExamAPI = ({ examId, navigate, setLoading, dispatch, getExamById }) => {
  setLoading(true);
  dispatch(
    getExamById(
      examId,
      (data) => {
        setLoading(false);
        let examData = data.data.examData;
        let examStatus = examData[0].status;
        let isContestTest = examData[0].isContestTest;
        if (examStatus === 2) {
          if (isContestTest === true) {
            let currentDate = moment(new Date(), 'YYYY-MM-DD LT');
            let contestEndDate = moment(examData[0].contestEndDate, 'YYYY-MM-DD LT').utc();
            // let resultDate = new Date(examData[0].resultDate)
            let resultDate = moment(examData[0].resultDate, 'YYYY-MM-DD LT').utc();
            let startDate = moment
              .utc(examData[0].contestStartDate)
              .format('MMMM Do YYYY, h:mm:ss a');
            if (currentDate < startDate) {
              alert(`Exam will start on ${startDate}`);
              return;
            } else if (currentDate > contestEndDate) {
              sessionStorage.setItem('examId', examId);
              sessionStorage.setItem('examData', JSON.stringify(examData[0]));
              localStorage.setItem('startTest', '1');
              window.open('/DetailResult', '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
            } else if (currentDate < resultDate) {
            } else {
              sessionStorage.setItem('examId', examId);
              sessionStorage.setItem('examData', JSON.stringify(examData[0]));
              localStorage.setItem('startTest', '1');
              window.open('/DetailResult', '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
            }
          } else {
            sessionStorage.setItem('examId', examId);
            sessionStorage.setItem('examData', JSON.stringify(examData[0]));
            localStorage.setItem('startTest', '1');
            window.open('/DetailResult', '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
          }
        } else if (examStatus === 0 && isContestTest === true) {
          let currentDate = moment(new Date(), 'YYYY-MM-DD LT');
          let contestEndDate = new Date(examData[0].contestEndDate);
          let resultDate = new Date(examData[0].resultDate);
          let startDate = moment(examData[0].contestStartDate, 'YYYY-MM-DD LT').utc();
          if (currentDate < startDate) {
            // live exam has not started yet
            alert(`Exam will start on ${startDate}`);
          } else {
            sessionStorage.setItem('examId', examId);
            sessionStorage.setItem('examData', JSON.stringify(examData));
            localStorage.setItem('startTest', '1');
            var windowName = 'userConsole';
            var popUp = window.open(
              '/ExamInstruction',
              windowName,
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes',
              'width=' + screen.availWidth + ',height=' + screen.availHeight
            );
            if (popUp == null || typeof popUp == 'undefined') {
              localStorage.setItem('startTest', '2');
              alert('Please disable your pop-up blocker and click the "Open" link again.');
            } else {
              popUp.focus();
            }
          }
        } else {
          //   navigate(`/${ScreenNames.ExamInstruction}`,  {state: {examId: examId, examData: examData}})
          sessionStorage.setItem('examId', examId);
          sessionStorage.setItem('examData', JSON.stringify(examData));
          localStorage.setItem('startTest', '1');
          // window.open('/ExamInstruction', '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
          var windowName = 'userConsole';
          var popUp = window.open(
            '/ExamInstruction',
            windowName,
            '_blank',
            'toolbar=yes,scrollbars=yes,resizable=yes',
            'width=' + screen.availWidth + ',height=' + screen.availHeight
          );
          if (popUp == null || typeof popUp == 'undefined') {
            localStorage.setItem('startTest', '2');
            alert('Please disable your pop-up blocker and click the "Open" link again.');
          } else {
            popUp.focus();
          }
        }
      },
      () => {
        setLoading(false);
      }
    )
  );
};

export const getDateAndShortMonthStringFromDate = (value) => {
  const date = new Date(value);
  if (date == 'Invalid Date') return '';
  let dateString = date.getDate();
  dateString = dateString < 10 ? `0${dateString}` : `${dateString}`;
  const monthString = date.toLocaleString('default', { month: 'short' });
  return `${dateString} ${monthString}`;
};

export const timeConvert = (n) => {
  if (n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + ' hours and ' + rminutes + ' minutes.';
  } else return '';
};

export const convertFromHtmlToPlainText = (html) => {
  // converts htmlString to plan text

  // Create a new div element
  const tempDivElement = document.createElement('div');
  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;
  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || '';
};

export const getCssClassNameFromQuestionType = (questionType) => {
  let cssClassName = '';
  switch (questionType) {
    case 0:
      // not visited yet
      cssClassName = 'notVisited';
      break;
    case 1:
      // save and next
      cssClassName = 'answered';
      break;
    case 2:
      // visited but not answered
      cssClassName = 'notAnswered';
      break;
    case 3:
      // mark for review
      cssClassName = 'marked';
      break;
    case 4:
      // markAndSave
      cssClassName = 'marknsave';
      break;
    default:
      cssClassName = 'notVisited';
      break;
  }
  return cssClassName;
};

export const totalAnsweredCountForReview = (questionSet = [], sectionId) => {
  // count totalAnswered questions for review since there are only two options total answered and total not answered
  // total answered is sum of all answers(Answered + MarkedAndSave) .i.e. questions having ansType=1 or 4
  let count = 0;
  if (sectionId) {
    questionSet = questionSet.filter((element) => element.section?.sectionId === sectionId);
  }
  questionSet.forEach((question, index) => {
    if (question.ansType === 1 || question.ansType === 4) {
      count++;
    }
  });
  return count;
};

export const totalNotAnsweredCountForReview = (questionSet = [], sectionId) => {
  // count totalAnswered questions for review since there are only two options total answered and total not answered
  // total answered is sum of all answers(NotAnswered + Marked + Not Visited) .i.e. questions having ansType=0, 2 or 4
  let count = 0;
  if (sectionId) {
    questionSet = questionSet.filter((element) => element.section?.sectionId === sectionId);
  }
  questionSet.forEach((question, index) => {
    if (question.ansType === 0 || question.ansType === 2 || question.ansType === 3) {
      count++;
    }
  });
  return count;
};

export const getStatsFromQuestionData = (questionData = []) => {
  let correctAnswers = questionData?.filter(
    (item) => item.ansStatus == 1 && (item.ansType == 1 || item.ansType == 4)
  );
  let wrongAnswers = questionData?.filter(
    (item) => item.ansStatus == 2 && (item.ansType == 1 || item.ansType == 4)
  );

  let skippedAnswers = questionData?.filter((item) => item.ansType != 1 && item.ansType != 4);
  let markedForReviewAnswers = questionData?.filter((item) => item.ansType === 3);

  let markEarned = questionData
    ?.filter((item) => item.ansStatus == 1 && (item.ansType == 1 || item.ansType == 4))
    .reduce(function (val, obj) {
      return val + obj.marks;
    }, 0);

  let negativeMark = questionData
    ?.filter((item) => item.ansStatus == 2 && (item.ansType == 1 || item.ansType == 4))
    .reduce(function (val, obj) {
      return val + obj.negativeMarks;
    }, 0);
  let totalCorrentMark = markEarned - negativeMark;

  let takenTime = questionData?.reduce(function (val, obj) {
    return val + obj.timeSpent;
  }, 0);

  return {
    correctAnswers,
    wrongAnswers,
    skippedAnswers,
    markedForReviewAnswers,
    markEarned,
    takenTime,
    totalCorrentMark,
    negativeMark
  };
};

export const getTimeTakenToStringFormat = (seconds) => {
  if (seconds == null) return 'undefined';
  let totalMinutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds - totalMinutes * 60;
  return `${totalMinutes} Min : ${remainingSeconds} Sec`;
};

export const getFullScreen = () => {
  const elem = document?.documentElement;
  if (elem?.requestFullscreen) {
    elem?.requestFullscreen();
  } else if (elem?.webkitRequestFullscreen) {
    /* Safari */
    elem?.webkitRequestFullscreen();
  } else if (elem?.msRequestFullscreen) {
    /* IE11 */
    elem?.msRequestFullscreen();
  }
};

export const exitFullScreenMode = (document) => {
  const elem = document;
  if (document?.exitFullscreen) {
    return document?.exitFullscreen();
  } else if (document?.webkitExitFullscreen) {
    /* Safari */
    document?.webkitExitFullscreen();
  } else if (document?.msExitFullscreen) {
    /* IE11 */
    document?.msExitFullscreen();
  }
  // }
  // else {

  // }
};

export const ctrlShiftKey = (e, keyCode) => {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
};

export const getCoursedata = (baseCourseName) => {
  let data = {};
  if (baseCourseName === 'MATHEMATICAL SCIENCE') data = mathematical_science;
  else if (baseCourseName === 'LIFE SCIENCE') data = life_science;
  else if (baseCourseName === 'PHYSICAL SCIENCE') data = physical_science;
  else if (baseCourseName === 'CHEMICAL SCIENCE') data = chemical_science;
  return data;
};

export const getQuestionDescription = (question) => {
  return question.en?.title?.map((item) => {
    return item?.descType === 'img' ? (
      <div className="text-start">
        <img className="qusimg cursor" key={item?._id} src={item?.description} alt="description" />
      </div>
    ) : (
      ReactHtmlParser(item?.description)
    );
  });
};

export const getAnswerStatusFromQuestion = (question) => {
  let answerStatus;
  switch (question?.ansType) {
    case 0:
      answerStatus = 'notVisited';
      break;
    case 1:
      answerStatus = 'answered';
      break;
    case 2:
      answerStatus = 'notAnswered';
      break;
    case 3:
      answerStatus = 'marked';
      break;
    default:
      answerStatus = 'marknsave';
      break;
  }
  return answerStatus;
};

export const secondsMinute = (seconds) => {
  let minutes = Math.floor(seconds / 60);
  let extraSeconds = seconds % 60;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  extraSeconds = extraSeconds < 10 ? '0' + extraSeconds : extraSeconds;
  if (minutes === '00') return `${extraSeconds} sec`;
  return `${minutes} min and ${extraSeconds} sec`;
};
