import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMonthYearList, getResultList } from '../Redux/Actions/homeAction';
import LectureCard from '../Component/lectureCard';

function Results() {
  const dispatch = useDispatch();
  const [studentType, setStudentType] = useState(1);
  const [MonthYearList, setMonthYearList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const { examTypeId, baseCourseId, childrenId } = useSelector((state) => ({
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    childrenId: state?.AuthReducer?.childrenId
  }));

  useEffect(() => {
    const requestData = {
      studentType,
      baseCourseId,
      examTypeId,
      subExamTypeId: childrenId
    };
    if (childrenId === '') delete requestData['subExamTypeId'];
    dispatch(
      getMonthYearList(
        requestData,
        (data) => {
          setMonthYearList(data);
          const postData = {
            studentType,
            baseCourseId,
            examTypeId,
            examMonth: data[0]?.examMonth,
            examYear: data[0]?.examYear,
            subExamTypeId: childrenId
          };
          if (childrenId === '') delete postData['subExamTypeId'];
          dispatch(
            getResultList(
              postData,
              (data) => {
                setResultList(data);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  }, []);

  const tabList = [
    {
      _id: 1,
      name: 'Our Toppers'
    },
    {
      _id: 2,
      name: 'Toppers Talk'
    }
  ];

  const onChangeResultTab = (value) => {
    setStudentType(value);
    const requestData = {
      baseCourseId,
      examTypeId,
      subExamTypeId: childrenId,
      studentType: value
    };
    if (childrenId === '') delete requestData['subExamTypeId'];
    dispatch(
      getMonthYearList(
        requestData,
        (data) => {
          setMonthYearList(data);
          const postData = {
            studentType: value,
            baseCourseId,
            examTypeId,
            examMonth: data[0]?.examMonth,
            examYear: data[0]?.examYear,
            subExamTypeId: childrenId
          };
          if (childrenId === '') delete postData['subExamTypeId'];
          dispatch(
            getResultList(
              postData,
              (data) => {
                setResultList(data);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const onChangeResultList = (item) => {
    const postData = {
      studentType,
      baseCourseId,
      examTypeId,
      examMonth: item?.examMonth,
      examYear: item?.examYear,
      subExamTypeId: childrenId
    };
    if (childrenId === '') delete postData['subExamTypeId'];
    dispatch(
      getResultList(
        postData,
        (data) => {
          setResultList(data);
        },
        () => {}
      )
    );
  };

  return (
    <div className="rightPanel">
      <h3>Results</h3>
      <div className="customtab">
        <ul className="nav nav-pills boxshadow p-2  rounded bgwhite" id="pills-tab" role="tablist">
          {tabList?.map((c, index) => (
            <li key={index.toString()} className="nav-item m-1" role="presentation">
              <button
                className={`nav-link text-lowercase text-capitalize px-3 me-3 ${
                  index === 0 ? 'active' : ''
                }`}
                id={'pills-' + index + '-tab'}
                data-bs-toggle="pill"
                data-bs-target={'#pills-' + index}
                type="button"
                role="tab"
                aria-controls={'pills-' + index}
                aria-selected="true"
                onClick={() => onChangeResultTab(c?._id)}>
                {c.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="customtab  mt-2">
        <ul
          className="nav nav-pills p-2  rounded bgwhite d-flex flex-nowrap overflow-auto w-100"
          id="pills-tab"
          role="tablist">
          {MonthYearList?.map((c, index) => (
            <li key={index.toString()} className="nav-item m-1" role="presentation">
              <button
                className={`nav-link text-lowercase text-capitalize px-3 me-3 resultmonthbtn ${
                  index === 0 ? 'active' : ''
                }`}
                id={'pills-' + index + '-tab'}
                data-bs-toggle="pill"
                data-bs-target={'#pills-' + index}
                type="button"
                role="tab"
                aria-controls={'pills-' + index}
                aria-selected="true"
                onClick={() => onChangeResultList(c)}>
                {c.monthYear}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="row Addressleftside boxshadow bgwhite rounded">
        <div className="col-sm-12">
          <div className="row">
            {studentType === 1
              ? resultList?.length > 0
                ? resultList?.map((item) => {
                    return (
                      <div className="col-sm-3 col-xl-2 d-flex align-items-center justify-content-center my-2">
                        <div className="rounded-top border boxshadow h-100">
                          <img
                            src={
                              item?.studentImg
                                ? item?.studentImg
                                : 'https://ifaslive-preprod-s3.s3.ap-south-1.amazonaws.com/examResult/profile/examResult_profile_1692273953716.jpg'
                            }
                            loading="lazy"
                            className="resultImg rounded-top border"
                            alt="profile"
                          />
                          <div className="text-center mt-2">{item?.studentName}</div>
                          <div className="text-center text-danger font10">
                            {item?.rank ? `RANK - ${item?.rank}` : ''}
                          </div>
                          <div className="text-center font10">{item?.qualifiedFor}</div>
                          <div className="text-center font10">{item?.rollNo}</div>
                        </div>
                      </div>
                    );
                  })
                : 'No Result Found'
              : resultList?.length > 0
              ? resultList?.map((item) => {
                  return (
                    // <div className="row d-flex align-items-center justify-content-center my-3">
                    <LectureCard data={item} />
                    // </div>
                  );
                })
              : 'No Result Found'}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Results;
