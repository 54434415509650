import React, { useEffect } from 'react';
import { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import '../Assets/CSS/onlineTestExam.css';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { LocalImages } from '../Constant';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  convertFromHtmlToPlainText,
  exitFullScreenMode,
  getQuestionDescription,
  getQuestionType,
  getStatsFromQuestionData,
  getTimeTakenToStringFormat
} from '../Utils/utils';
import ScreenNames from '../Constant/ScreenNames';
import { maxWidth } from '@mui/system';
import moment from 'moment';
import { secondsMinute } from '../Utils/utils';
import { setParentCourse, updateMoreValues } from '../Redux/Actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

function DetailResult() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState('one');
  const dispatch = useDispatch();

  // let { examData } = location.state
  const examId = sessionStorage.getItem('examId');
  const examData = JSON.parse(sessionStorage.examData);

  const questionData = examData?.questionsData;
  questionData.map((item, index) => (item.questionNumber = index + 1)); // added current question number

  useEffect(() => {
    //handleExitFullScreen();
    // history.pushState(null, null, location.href);
    window.onpopstate = function (event) {
      return navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.OnlineTest}`);
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  function handleRedirect() {
    localStorage.setItem('startTest', '2');
    sessionStorage.removeItem('examId');
    sessionStorage.removeItem('examData');
    opener.location.reload();
    window.close();
  }

  const getSectionMark = (sectionId) => {
    let questionsData = examData.questionsData;
    let markArr = questionsData
      .map((item) =>
        item.section?.sectionId == sectionId &&
        item.ansStatus == 2 &&
        (item.ansType == 1 || item.ansType == 4)
          ? {
              ansStatus: 2,
              marks: item.marks,
              neg: item.negativeMarks,
              sectionId: item.section?.sectionId
            }
          : item.section?.sectionId == sectionId &&
            item.ansStatus == 1 &&
            (item.ansType == 1 || item.ansType == 4)
          ? {
              ansStatus: 1,
              marks: item.marks,
              neg: item.negativeMarks,
              sectionId: item.section?.sectionId
            }
          : null
      )
      .filter((im) => im != null);

    const tempArrMark = {};
    for (const itm of markArr) {
      tempArrMark[itm.marks] = markArr.filter((obj) => obj?.marks == itm?.marks);
    }

    return tempArrMark;
  };

  let {
    correctAnswers,
    wrongAnswers,
    skippedAnswers,
    markedForReviewAnswers,
    markEarned,
    takenTime,
    negativeMark
  } = getStatsFromQuestionData(questionData);

  const examStats = examData.examStats;
  const sectionsResult = examData?.sections?.map((section) => {
    // returns total correct and incorrect questions grouped by different marks type
    let marksData = Object.entries(getSectionMark(section._id));
    marksData.unshift(section?.name);
    // data returned is of the format: ["sectionName", ["marks(2/1/4 mark question)", "marksArr"]]
    return marksData;
  });

  const countCorrectQuestionsSectionWiseMarks = (itemArr) => {
    return itemArr != undefined
      ? itemArr?.reduce(function (val, obj) {
          return val + (obj.ansStatus == 1 ? 1 : 0);
        }, 0)
      : 0;
  };

  const countIncorrectQuestionsSectionWiseMarks = (itemArr) => {
    return itemArr != undefined
      ? itemArr?.reduce(function (val, obj) {
          return val + (obj.ansStatus == 2 ? 1 : 0);
        }, 0)
      : 0;
  };

  const getAnswerDescription = (question) => {
    const ansDescription = question.en?.ansDescription;
    return (
      <div className="text-start">
        {ansDescription?.image === '' &&
        ansDescription?.text === '' &&
        ansDescription?.video === '' ? null : (
          <>
            {ansDescription?.image ? (
              <div>
                <img
                  className="img-fluid qusimgdesc"
                  src={ansDescription?.image}
                  alt={'Anwer image'}
                />
              </div>
            ) : null}
            {ansDescription?.text ? ReactHtmlParser(ansDescription?.text) : null}
            {ansDescription?.video ? (
              <>
                <video className="videotag m-auto" width="320" height="240" controls>
                  <source src={ansDescription?.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </>
            ) : null}
          </>
        )}
      </div>
    );
  };

  window.addEventListener('beforeunload', function (e) {
    e.preventDefault();
    e.returnValue = '';
    opener.location.reload();
    window.close();
  });

  return (
    <div className="detailsResult">
      <header className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="cursor" onClick={() => navigate(`/${ScreenNames.Home}`)}>
            <img
              src={LocalImages.logo}
              alt="IFAS Logo"
              className="logo"
              style={{ width: '120px' }}
            />
          </div>
          <h5 className="mb-0 ms-3">{examData?.en?.name}</h5>
        </div>

        <div>
          <button
            type="button"
            className="btn btn-primary py-2 px-5 font12 me-md-1"
            onClick={handleRedirect}>
            Close
          </button>
        </div>
      </header>
      <div className="instcontainer mt-4 px-2">
        <div className="instDuration">
          <div className="row width60 m-auto d-flex justify-content-center">
            {sectionsResult?.map((sectionItem, index) => {
              return (
                <div className="col-6 col-lg-4 confirmReview" key={index}>
                  <div className="Reviewdetails rounded ">
                    {sectionItem.map((item, ind) => {
                      return (
                        <>
                          {ind == 0 ? (
                            <span className="fw-bold d-block mb-2 text-center colorgreen">
                              {item}
                            </span>
                          ) : null}
                          {ind != 0 ? (
                            <>
                              <br />
                              {item?.length ? (
                                <>
                                  <span>
                                    {item[0] ? item[0] : 0}M Correct:{' '}
                                    {item[1] ? countCorrectQuestionsSectionWiseMarks(item[1]) : 0}
                                  </span>
                                  <span>
                                    {item[0] ? item[0] : 0}M Wrong:{' '}
                                    {item[1] ? countIncorrectQuestionsSectionWiseMarks(item[1]) : 0}
                                  </span>
                                </>
                              ) : (
                                <span>Not Answered. Skipped</span>
                              )}
                            </>
                          ) : null}
                          {sectionItem.length === 1 ? (
                            <>
                              <br />
                              Skipped section
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-center mt-4">
            <div className="px-4 border-end text-center">
              <h5 className="colorblue fw-bold mb-0">
                {examStats?.marksObtained}/{examStats?.totalMarks}
              </h5>
              <p className="insttxt colorgrey">Marks</p>
            </div>
            <div className="px-4 text-center">
              <h5 className="colorpurple fw-bold mb-0">
                {getTimeTakenToStringFormat(examStats?.totalTimeSpent)}
              </h5>
              <p className="insttxt  colorgrey">Time Taken</p>
            </div>
          </div>
        </div>
        <div className="mt-4 py-2 tabcontent">
          <Tabs
            id="tinyTab"
            className="tabcontent"
            activeKey={key}
            onSelect={(e) => setKey(e)}
            fill
            variant="pills">
            <Tab
              eventKey="one"
              title={`${
                correctAnswers?.length < 10
                  ? `0${correctAnswers?.length}`
                  : `${correctAnswers?.length}`
              } Correct Answer`}>
              {correctAnswers.map((question, index) => {
                return (
                  <div key={question?._id} className="border rounded mb-2 p-2 mt-3">
                    <div className="row">
                      <div className="col-2 col-sm-1 px-1">
                        <span className="queno">{question.questionNumber}</span>
                        <span className="questype d-block rounded text-center mt-2">
                          {getQuestionType(question?.questionType)}
                        </span>
                      </div>
                      <div className="col-10 col-sm-11">{getQuestionDescription(question)}</div>
                    </div>
                    {question?.questionType !== 3 ? (
                      <div className="row mt-2">
                        <div className="col-2 col-sm-1 colorblue font12 px-1">Options:</div>
                        <div className="col-10 col-sm-11">
                          <table>
                            {question?.en?.options?.map((option, index) => {
                              return (
                                <tr key={option?._id}>
                                  <td
                                    className={
                                      option.isCorrect ? 'colorgreen text-break' : ' text-break'
                                    }>
                                    {option.isCorrect ? <DoneIcon /> : null}
                                  </td>
                                  <td
                                    className={
                                      option.isCorrect
                                        ? 'colorgreen text-break d-flex align-item-center'
                                        : ' text-break d-flex align-item-center'
                                    }>
                                    <span className="mx-2">{index + 1}.</span>{' '}
                                    {option?.descType === 'img' ? (
                                      <img
                                        className="qusimg"
                                        src={option?.description}
                                        alt="Description"
                                      />
                                    ) : (
                                      ReactHtmlParser(option.description)
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="row mt-2">
                        <div className="col-1 colorblue font12">Answer:</div>
                        <div className="col-11">
                          <table>
                            <tr>
                              <td className="colorgreen">
                                <DoneIcon />
                              </td>
                              <td className="colorgreen">
                                {question?.answer?.length ? question?.answer[0] : 0}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    )}
                    <div className="row mt-2">
                      <div className="col-1 errorcolor font12 pe-0">Time Taken:</div>
                      <div className="col-11">
                        {/* <span>{question?.timeSpent ? question.timeSpent : 0} Sec</span> */}
                        <span>{question?.timeSpent ? secondsMinute(question.timeSpent) : 0}</span>
                      </div>
                    </div>
                    <div className="mt-2">
                      {question?.en.ansDescription?.text === '' &&
                      question?.en.ansDescription?.image === '' &&
                      question?.en.ansDescription?.video === '' ? null : (
                        <div className="accordion" id="accordionCorrect1">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                              <button
                                className="accordion-button collapsed colorgreen"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapseOne-${question?._id}`}
                                aria-expanded="false"
                                aria-controls="flush-collapseOne">
                                <span className="colorgreen">Answer Description</span>
                              </button>
                            </h2>
                            <div
                              id={`flush-collapseOne-${question?._id}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionCorrect1">
                              <div className="accordion-body">{getAnswerDescription(question)}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </Tab>
            <Tab
              eventKey="two"
              title={`${
                wrongAnswers?.length < 10 ? `0${wrongAnswers?.length}` : `${wrongAnswers?.length}`
              } Wrong Answer`}>
              {wrongAnswers.map((question, index) => {
                var mom = moment('question.timeSpent', 'HHmmss');
                return (
                  <div key={question?._id} className="border rounded mb-2 p-2 mt-3">
                    <div className="row">
                      <div className="col-2 col-sm-1 px-1">
                        <span className="queno">{question.questionNumber}</span>
                        <span className="questype d-block rounded text-center mt-2">
                          {getQuestionType(question?.questionType)}
                        </span>
                      </div>
                      <div className="col-10 col-sm-11">{getQuestionDescription(question)}</div>
                    </div>
                    {question?.questionType !== 3 ? (
                      <div className="row mt-2">
                        <div className="col-2 col-sm-1 colorblue font12 px-1">Options:</div>
                        <div className="col-10 col-sm-11">
                          <table>
                            {question?.en?.options?.map((option, index) => {
                              return (
                                <tr key={option?._id}>
                                  <td
                                    className={
                                      option.isCorrect
                                        ? 'colorgreen text-break align-item-center'
                                        : option.isAnswer
                                        ? 'errorcolor text-break align-item-center'
                                        : 'text-break align-item-center'
                                    }>
                                    {' '}
                                    {option.isCorrect ? (
                                      <DoneIcon />
                                    ) : option.isAnswer ? (
                                      <ClearIcon />
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td
                                    className={
                                      option.isCorrect
                                        ? 'colorgreen text-break d-flex align-item-center'
                                        : option.isAnswer
                                        ? 'errorcolor text-break d-flex align-item-center'
                                        : 'text-break d-flex align-item-center'
                                    }>
                                    <span className="mx-2">{index + 1}.</span>{' '}
                                    {option?.descType === 'img' ? (
                                      <img
                                        className="qusimg"
                                        src={option?.description}
                                        alt="Description"
                                      />
                                    ) : (
                                      ReactHtmlParser(option.description)
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="row mt-2">
                          <div className="col-1 colorblue font12">Answer:</div>
                          <div className="col-11">
                            <table>
                              <tr>
                                <td className="colorgreen">
                                  <DoneIcon />
                                </td>
                                <td className="colorgreen">
                                  {question.range.r1}
                                  {question.range.r2 !== null ? `-${question.range.r2}` : null}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        {question?.answer?.length ? (
                          <div className="row mt-2">
                            <div className="col-1 colorblue font12">Your Answer:</div>
                            <div className="col-11">
                              <table>
                                <tr>
                                  <td className="errorcolor">
                                    <ClearIcon />
                                  </td>
                                  <td className="errorcolor">
                                    {question?.answer?.length ? question?.answer[0] : ''}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        ) : null}
                      </>
                    )}
                    <div className="row mt-2">
                      <div className="col-1 errorcolor font12 pe-0">Time Taken:</div>
                      <div className="col-11">
                        <span>{question?.timeSpent ? secondsMinute(question.timeSpent) : 0}</span>
                      </div>
                    </div>
                    <div className="mt-2">
                      {question?.en.ansDescription?.image === '' &&
                      question?.en.ansDescription?.text === '' &&
                      question?.en.ansDescription?.video === '' ? null : (
                        <div className="accordion" id="accordionwrong">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                              <button
                                className="accordion-button collapsed colorgreen"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapseOne-${question?._id}`}
                                aria-expanded="false"
                                aria-controls="flush-collapseOne">
                                <span className="colorgreen">Answer Description</span>
                              </button>
                            </h2>
                            <div
                              id={`flush-collapseOne-${question?._id}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionwrong">
                              <div className="accordion-body">{getAnswerDescription(question)}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </Tab>
            <Tab
              eventKey="three"
              title={`${
                skippedAnswers?.length < 10
                  ? `0${skippedAnswers?.length}`
                  : `${skippedAnswers?.length}`
              } Skipped`}>
              {skippedAnswers.map((question, index) => {
                return (
                  <div className="border rounded mb-2 p-2 mt-3" key={question?._id}>
                    <div className="row">
                      <div className="col-2 col-sm-1 px-1">
                        <span className="queno">{question.questionNumber}</span>
                        <span className="questype d-block rounded text-center mt-2">
                          {getQuestionType(question?.questionType)}
                        </span>
                      </div>
                      <div className="col-10 col-sm-11">{getQuestionDescription(question)}</div>
                    </div>
                    {question?.questionType !== 3 ? (
                      <div className="row mt-2">
                        <div className="col-2 col-sm-1 colorblue font12 px-1">Options:</div>
                        <div className="col-10 col-sm-11">
                          <table>
                            {question?.en?.options?.map((option, index) => {
                              return (
                                <tr key={option?._id}>
                                  <td
                                    className={
                                      option?.isCorrect
                                        ? 'colorgreen text-break'
                                        : 'colorpurple  text-break'
                                    }>
                                    {option?.isCorrect ? <DoneIcon /> : null}
                                  </td>
                                  <td
                                    className={
                                      option?.isAnswer
                                        ? 'colorpurple text-break d-flex align-item-center'
                                        : option?.isCorrect
                                        ? 'colorgreen text-break d-flex align-item-center'
                                        : 'text-break d-flex align-item-center'
                                    }>
                                    <span className="mx-2">{index + 1}.</span>{' '}
                                    {option?.descType === 'img' ? (
                                      <img
                                        src={option?.description}
                                        className="qusimg"
                                        alt="description"
                                      />
                                    ) : (
                                      ReactHtmlParser(option?.description)
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="row mt-2">
                          <div className="col-1 colorblue font12">Answer:</div>
                          <div className="col-11">
                            <table>
                              <tr>
                                <td className="colorgreen">
                                  <DoneIcon />
                                </td>
                                <td className="colorgreen">
                                  {question.range.r1}
                                  {question.range.r2 !== null ? `-${question.range.r2}` : null}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        {question?.ansType === 3 && question?.answer?.length ? (
                          <div className="row mt-2">
                            <div className="col-1 colorblue font12">Your Answer:</div>
                            <div className="col-11">
                              <table>
                                <tr>
                                  <td className="colorpurple"></td>
                                  <td className="colorpurple">
                                    {question?.answer?.length ? question?.answer[0] : ''}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        ) : null}
                        {/* { <td className="errorcolor">{question?.answer?.length ? question?.answer[0] : ""}</td> : null} */}
                      </>
                    )}
                    <div className="row mt-2">
                      <div className="col-1 errorcolor font12 pe-0">Time Taken:</div>
                      <div className="col-11">
                        {/* <span>{question.timeSpent ? `${question.timeSpent} Sec` : "Not Visited"}</span> */}
                        <span>
                          {question?.timeSpent ? secondsMinute(question.timeSpent) : 'Not Visited'}
                        </span>
                      </div>
                    </div>
                    <div className="mt-2">
                      {question?.en.ansDescription?.image === '' &&
                      question?.en.ansDescription?.text === '' &&
                      question?.en.ansDescription?.video === '' ? null : (
                        <div className="accordion" id="accordionSkipped">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                              <button
                                className="accordion-button collapsed colorgreen"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapseOne-${question?._id}`}
                                aria-expanded="false"
                                aria-controls="flush-collapseOne">
                                <span className="colorgreen">Answer Description</span>
                              </button>
                            </h2>
                            <div
                              id={`flush-collapseOne-${question?._id}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionSkipped">
                              <div className="accordion-body">{getAnswerDescription(question)}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default DetailResult;
