import React, { useState, useEffect } from 'react';

import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { addEditAddress } from '../Redux/Actions/homeAction';
import { useDispatch } from 'react-redux';
import { getCity, getState } from '../Redux/Actions/AuthAction';
import { checkValidAlphaNumeric, validateFullName } from '../Utils/validators';
import { validateFullNameErrMsg, validateTenDigitNumberOnlyErrMsg } from '../Utils/errormsg';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import CustomDropdown from '../Component/customDropdown';

function AddNewAddress(props) {
  const [open, setOpen] = useState(true);
  const [fullName, setFullName] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [pincode, setPincode] = useState('');
  const [label, setLabel] = useState('');
  const [landmark, setLandmark] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [number, setNumber] = useState('');
  const [resetSelectCity, setResetSelectCity] = useState('');
  const [resetSelectState, setResetSelectState] = useState('');
  const [statename, setstatename] = useState(0);
  const [cityname, setCityname] = useState(0);
  function allnumeric(inputtxt) {
    var numbers = /^[0-9]+$/;
    if (inputtxt.match(numbers)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input numeric characters only'));
      return false;
    }
  }

  function allnumber(inputtxt) {
    var num = /^[0-9]+$/;
    if (inputtxt.match(num)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input numeric characters only'));
      return false;
    }
  }

  const disable = () => {
    if (
      pincode.length !== 6 ||
      pincode === '' ||
      fullName === '' ||
      line1 === '' ||
      line2 === '' ||
      stateId === '' ||
      cityId === '' ||
      landmark === '' ||
      label === ''
    )
      return false;
    return true;
  };

  const checkDisable = () =>
    // number === '' ||
    // !allnumeric(number) ||
    //number.length !== 10 ||
    !allnumber(pincode) || pincode.length !== 6 || pincode === '';

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getState((dataState) => {
        setStateData(dataState);
      })
    );
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.open(false);
  };

  const handleClick = () => {
    setIsDefault(!isDefault);
  };
  const setfullName = (e) => {
    if (validateFullName(e.target.value)) setFullName(e.target.value);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const setline1 = (e) => {
    setLine1(e.target.value);
  };
  const setline2 = (e) => {
    setLine2(e.target.value);
  };

  const setlabel = (e) => {
    setLabel(e.target.value);
  };
  const setlandmark = (e) => {
    var num = /^[0-9a-zA-Z\d\-_\s]+$/;
    if (!e) {
      setLandmark(e);
    } else if (e.match(num)) setLandmark(e);
    else dispatch(showErrorSnackbar('Please input Alpha Numeric only'));
  };

  const handleOnChangePhoneText = (prevPhoneInputText) => {
    var numbers = /^[0-9]+$/;
    if (!prevPhoneInputText.target.value) setNumber(prevPhoneInputText.target.value);
    else if (prevPhoneInputText.target.value.match(numbers))
      setNumber(prevPhoneInputText.target.value);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
  };

  const handleOnChangePincodeText = (prevPincodeInputText) => {
    var num = /^[0-9]+$/;
    if (!prevPincodeInputText.target.value) {
      setPincode(prevPincodeInputText.target.value);
    } else if (prevPincodeInputText.target.value.match(num))
      setPincode(prevPincodeInputText.target.value);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
  };

  const addAddress = () => {
    if (
      pincode.length !== 6 ||
      pincode === '' ||
      fullName === '' ||
      line1 === '' ||
      line2 === '' ||
      stateId === '' ||
      cityId === '' ||
      landmark === '' ||
      label === ''
    )
      dispatch(showErrorSnackbar('Please fill all details'));
    else if (number.length > 0 && number.length !== 10)
      dispatch(showErrorSnackbar(validateTenDigitNumberOnlyErrMsg));
    else if (!checkDisable()) {
      const data = {
        fullName: fullName,
        line1,
        line2,
        state: stateId,
        city: cityId,
        pincode: pincode,
        landmark,
        label,
        isDefault
      };
      if (number) {
        data['alternatePhoneNumber'] = number;
      }
      dispatch(
        addEditAddress(
          true,
          data,
          () => {},
          () => {}
        )
      );
      props.open(false);
      // props.renderAgain(true);
    } else {
      dispatch(showErrorSnackbar('Please provide valid data.'));
    }
  };

  const onStateChange = (value) => {
    setstatename(value);
    setStateId(value._id);
    setResetSelectState(!resetSelectState);
    setResetSelectCity(!resetSelectCity);
    dispatch(
      getCity(value._id, (dataCity) => {
        setCityData(dataCity);
      })
    );
  };
  const onCityChange = (value) => {
    setCityname(value);
    setCityId(value._id);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <div className="modeldiv address">
          <div className="col  d-flex justify-content-between ">
            <h6 className="colorblue fw-bold">Add new address</h6>
            <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
          </div>
          <div className="col m-3 p-3 boxshadow">
            <div className="font12">
              <div className="col-sm-12 ps-0 mt-2">
                <label className="mb-2 d-block">Full Name</label>
                <input
                  type="text"
                  placeholder="Enter Full Name"
                  className="border-0 border-bottom w-100"
                  value={fullName}
                  onChange={(e) => setfullName(e)}
                  maxLength="50"
                />
              </div>
              <div className="col-sm-12 ps-0 mt-2">
                <label className="mb-2 d-block">House/ Flat/ Block No.</label>
                <input
                  type="text"
                  placeholder="Enter House/ Flat/ Block No."
                  className="border-0 border-bottom w-100"
                  onChange={(e) => setline1(e)}
                  maxLength="150"
                />
              </div>
              <div className="col-sm-12 mt-2 ps-0">
                <label className="mb-2 d-block">Apartment/ Road /Area</label>
                <input
                  type="text"
                  placeholder="Enter Apartment/ Road /Area "
                  className="border-0 border-bottom w-100"
                  onChange={(e) => setline2(e)}
                  maxLength="150"
                />
              </div>
              <div className="d-flex row">
                <div className="mt-2 col-6 ps-0 removeborder">
                  <label>State Name</label>
                  <CustomDropdown
                    setInputText={(value) => onStateChange(value)}
                    lableName="Select State"
                    options={stateData}
                    defaultValue={stateId}
                  />
                </div>
                <div className="mt-2 col-6 ps-0 removeborder">
                  <label>City Name</label>
                  <CustomDropdown
                    setInputText={(value) => onCityChange(value)}
                    lableName="Select City"
                    options={cityData}
                    defaultValue={cityId}
                    reset={resetSelectCity}
                  />
                </div>
              </div>

              <div className="d-flex row font12">
                <div className="col-sm-6 ps-0">
                  <label className="mb-2 d-block">Pincode</label>
                  <input
                    type="text"
                    placeholder="Enter Pincode"
                    className="border-0 border-bottom w-100"
                    minLength="6"
                    maxLength="6"
                    value={pincode}
                    onChange={(value) => handleOnChangePincodeText(value)}
                  />
                </div>
                <div className="col-sm-6 ps-0">
                  <label className="mb-2 d-block">Landmark</label>
                  <input
                    type="text"
                    placeholder="Enter Landmark "
                    value={landmark}
                    className="border-0 border-bottom w-100"
                    onChange={(e) => setlandmark(e.target.value)}
                    maxLength="50"
                  />
                </div>
              </div>
              <div className="col-sm-12 mt-2 ps-0 position-relative">
                <label className="mb-2 d-block">Save This Address As</label>
                <input
                  type="text"
                  placeholder="Save As Address As"
                  className="border-0 border-bottom w-100"
                  onChange={(e) => setlabel(e)}
                  maxLength="50"
                />
              </div>
              <div className="col-sm-12 mt-2 ps-0">
                <label className="mb-2 d-block">Alternative Phone Number(Optional)</label>
                <input
                  type="text"
                  placeholder="Enter Phone Number "
                  className="border-0 border-bottom w-100"
                  maxLength="10"
                  minLength="10"
                  value={number}
                  onChange={(value) => handleOnChangePhoneText(value)}
                />
              </div>
              <div className="col-sm-12 mt-2 ps-0">
                <label className="mb-2 d-block">Address As</label>
                <button
                  type="button"
                  className={
                    isDefault
                      ? 'btn btn-primary font12 p-1 px-2'
                      : 'btn btn-outline-primary font12 p-1 px-2'
                  }
                  onClick={handleClick}>
                  Default
                </button>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className={!disable() ? 'btn btn-secondary w-50' : 'btn btn-primary w-50'}
              onClick={addAddress}>
              Save and Proceed
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default AddNewAddress;
