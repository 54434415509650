import React from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
function MyLectureCard(data) {
  const navigate = useNavigate();
  const onPressVideo = (item) =>
    navigate('/PaidUser/LectureSubject', { state: { item: item, title: data?.data?.name, subjectTopicInfo: data?.data?.subjectTopicInfoRecorded?.data} });
  return (
    <div
      className="col-sm-4 px-4 bgwhite mb-4 cursor addressouterbox"
      onClick={() => onPressVideo(data.data.subjectTopicInfoRecorded.data)}>
      <div className=" rounded boxshadow ">
        <img src={data.data.bannerImage} className="img-fluid rounded-top" alt={data.data.name} />
        <p className="mb-0 fw-bold p-3">{data.data.name}</p>
      </div>
    </div>
  );
}

export default MyLectureCard;
