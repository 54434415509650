import React from 'react';
import Lottie from 'react-lottie';
import emptyorder from '../Assets/Images/JSON/order history.json';

function OrderHistoryBlank(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyorder,
    renderer: 'svg'
  };

  return (
    <div className="col justify-content-center bgwhite boxshadow py-5 ">
      <Lottie options={defaultOptions} height={130} width={200}></Lottie>
      <p className="text-center">
        <b>No orders Yet</b>
      </p>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-primary py-2 px-4 font12 me-md-1"
          onClick={props.courseroute}>
          Browse Courses
        </button>
      </div>
    </div>
  );
}

export default OrderHistoryBlank;
