import React, { useState, useEffect } from 'react';
import '../App.css';
import LectureCard from '../Component/lectureCard';
import { useDispatch, useSelector } from 'react-redux';
import { getFreeLectureByTeacher, updateHomeValues } from '../Redux/Actions/homeAction';
import { useLocation, useNavigate } from 'react-router-dom';
import NoRecord from '../Component/noRecord';
function AllLectureVideo() {
  const [video, setVideo] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { topicId, subjectId,teacherId } = location.state;
  const { examTypeId, baseCourseId, childrenId } = useSelector((state) => ({
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    childrenId: state?.AuthReducer?.childrenId
  }));

  useEffect(() => {
    console.log("fjkdafjksdj",subjectId);
    const dataRequest = {
      baseCourseId,
      examTypeId,
      topicId:topicId?._id,
      subjectId: subjectId?._id,
      teacherId:teacherId?._id,
    };
    if (childrenId) {
      dataRequest['subExamTypeId'] = childrenId;
    }
    dispatch(
      getFreeLectureByTeacher(
        dataRequest,
        (data) => {
          setVideo(data);
        },
        () => {}
      )
    );
  }, []);

  return (
    <div className="rightPanel">
      <div>
        <h3>Lectures</h3>
      </div>
      <div className="mt-4 lectureheight py-3">
        <div className="row">
          {video.length>0? video?.map((c) => {
            return <LectureCard data={c} />;
          }) : <NoRecord />}
        </div>
      </div>
    </div>
  );
}

export default AllLectureVideo;
