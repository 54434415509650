import React from 'react';
import { useNavigate } from 'react-router-dom';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import { updateCourseDetailValues } from '../Redux/Actions/courseDetailAction';
import { useDispatch } from 'react-redux';
import { getName } from '../Redux/Actions/AuthAction';
import { timeConvert } from '../Utils/utils';
function CourseCard({
  path,
  course,
  batch,
  courseID,
  sectionType,
  courseDuration,
  testCount,
  timeType,
  bookCount,
  language,
  classType,
  baseCoursename,
  baseCourseId,
  examTypeId,
  subExamTypeId,
  examName
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courseDetails = () => {
    dispatch(
      updateCourseDetailValues({
        packageSection: [],
        sessions: [],
        courseDetail: {}
      })
    );
    if (sectionType === 1 || sectionType === 6)
      navigate('/CourseDetails/' + course + '/' + courseID, {
        state: {
          courseID: courseID,
          isextended: false,
          baseCoursename: baseCoursename,
          examName: examName
        }
      });
    else if (sectionType === 2) {
      const data = {
        baseCourseId,
        examTypeId
      };
      if (subExamTypeId) data.subExamTypeId = subExamTypeId;
      dispatch(
        getName(
          data,
          (res) => {
            const { seoBaseCourseName, seoSubExamName, seoExamTypeName } = res;
            navigate(
              '/' +
                `${seoSubExamName ? seoSubExamName : seoExamTypeName}` +
                '/' +
                seoBaseCourseName +
                '-study-material' +
                '/' +
                courseID
            );
          },
          () => {}
        )
      );
    } else if (sectionType === 3 || sectionType === 7 || sectionType === 8)
      navigate('/CourseDetails/' + course + '/' + courseID, {
        state: {
          courseID: courseID,
          isextended: false,
          baseCoursename: baseCoursename,
          examName: examName
        }
      });
    else if (sectionType === 5) {
      navigate('/LectureSeries/' + course + '/' + courseID, {
        state: {
          courseID: courseID,
          isextended: false,
          baseCoursename: baseCoursename,
          examName: examName
        }
      });
    }
  };

  function padNumber(number) {
    var string = '' + number;
    string = string.length < 2 ? '0' + string : string;
    return string;
  }
  const date = new Date(batch);
  const next_date = new Date(date.setDate(date.getDate()));
  const formatted =
    next_date.getUTCFullYear() +
    '-' +
    padNumber(next_date.getUTCMonth() + 1) +
    '-' +
    padNumber(next_date.getUTCDate());
  return (
    <div className="card boxshadow border-0 h-100">
      <img className="rounded courseimg w-100" src={path} alt={course} />
      <div className="coursetype">
        {timeType === 2 ? (
          <p className="coursetypelable bg-blue rounded">Recorded</p>
        ) : timeType === 1 ? (
          classType === 1 ? (
            <p className="coursetypelable bg-red rounded">Live</p>
          ) : (
            <p className="coursetypelable  bg-green rounded">Premier</p>
          )
        ) : null}
      </div>
      <div className="courselangimg">
        <img className="courseimg w-50 position-absolute bgwhite" src={language} alt={course} />
      </div>
      <div className="p-3">
        <p className="ellipsis font13 mb-0">{course}</p>
        {sectionType === 1 || sectionType === 4 ? (
          timeType === 1 ? (
            <p className="font11">
              Live Batch Starts: <span className="colorblue">{formatted}</span>
            </p>
          ) : timeType === 2 ? (
            courseDuration === 0 || courseDuration === undefined ? null : (
              <p className="font11">
                Course Duration:{' '}
                <span className="colorblue">{timeConvert(parseInt(courseDuration))}</span>
              </p>
            )
          ) : (
            ''
          )
        ) : sectionType === 3 ? (
          <p className="font11">
            <span>
              Unit Test: {testCount?.unit}, Mock Test: {testCount?.mock}
            </span>
          </p>
        ) : sectionType === 2 ? (
          <p className="font11">
            <DescriptionRoundedIcon fontSize="small" color="disabled" className="me-1" />
            <span>{bookCount} Books</span>
          </p>
        ) : sectionType === 5 ? (
          courseDuration === 0 || courseDuration === undefined ? null : (
            <p className="font11">
              Course Duration:{' '}
              <span className="colorblue">{timeConvert(parseInt(courseDuration))}</span>
            </p>
          )
        ) : (
          ''
        )}

        <div className="text-center mt-4">
          <div className="text-center mt-4">
            <button
              className="btn btn-primary w-75 py-md-1 py-lg-1 font12 mx-auto"
              onClick={courseDetails}>
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
