import React,{useState} from 'react';
import { LocalImages } from '../Constant';
const FixFooter = () => {
  const [isHidden, setisHidden] = useState(true);
  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    if (window.innerWidth >= 768) {
      var y = window.scrollY;
      if (y >= 300) {
        setisHidden(false);
      } else {
        setisHidden(true);
      }
    } else {
      setisHidden(false);
    }
  };
  window.addEventListener('scroll', myScrollFunc);
    return ( <>
      {!isHidden ? (
        <div className="fixedFooter font24">
          <div className="FooterInner">
            <div className="col-md-12 mx-0">
              <div className="d-flex justify-content-between mb-2 mb-md-0">
                <div className="d-flex">
                  <img
                    src={LocalImages.logoCircle}
                    alt="IFAS Logo"
                    className="landinglogo me-1 me-md-3"
                  />
                  <p className="fw-bold mb-0 pt-3">
                    For any query or Career guidance kindly contact at{' '}
                    <span className="landingPhone boldfont cursor me-1"> +91-9172266888 </span>
                  </p>
                </div>
                <a href="tel:+91-9172266888" className="mt-2">
                  <button className="btn btn-primary btn-sm boxshadow px-5">Contact Us</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </> );
}
 
export default FixFooter;