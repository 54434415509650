import React, { useState } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SubMenuButton from '../Component/subMenuButton';

function MenuButton(props) {
  const [isSubmenu, setIsSubmenu] = useState(false);
  const [isSubexam, setIsSubexam] = useState(false);
  const [selectedmenu, setselectedmenu] = useState('');

  const showsubmenu = (val, indexval) => {
    props?.setselectedmenu(indexval);
    setIsSubmenu(true);
  };

  const showexam = (indexexam) => {
    props?.setselectedmenu(indexexam);
    setIsSubexam(true);
  };
  return (
    <div
      className="d-flex justify-content-between border-bottom cursor p-2 position-relative"
      onClick={() =>
        props?.data?.subexam
          ? showexam(props?.data.subexam?.examID, props?.index)
          : props?.data?.basecourse
          ? showsubmenu(props?.data.examID, props?.index)
          : props?.handleroutestudymaterial(props?.data?.basecourseid, props?.data?.examID)
      }>
      <p className="mb-0 font13">{props?.data.exam}</p>
      {props?.data?.basecourse || props?.data?.subexam ? (
        <div className="text-end">
          <ArrowRightIcon />
        </div>
      ) : null}

      {isSubmenu === true && props?.selectedmenu === props.index ? (
        <div className="position-absolute submenu bgwhite boxshadow border">
          {props?.data?.basecourse?.map((item, index) => {
            return (
              <div
                className="d-flex justify-content-between border-bottom cursor p-2 "
                onClick={() => {
                  props?.handleroutestudymaterial(
                    item?.basecourseid,
                    props?.data?.examID,
                    item?.basecoursename,
                    props?.data?.exam,
                    item?.title,
                    item?.description,
                  );
                }}>
                <p className="mb-0 font13">{item.basecoursename}</p>
              </div>
            );
          })}
        </div>
      ) : null}

      {isSubexam === true && props?.selectedmenu === props.examindex ? (
        <div className="position-absolute submenu bgwhite boxshadow border">
          {props?.data?.subexam?.map((item, index) => (
            <SubMenuButton
              data={item}
              index={index}
              setselectedmenu={setselectedmenu}
              selectedmenu={selectedmenu}
              handleroutestudymaterial={props?.handleroutestudymaterial}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default MenuButton;
