import React, { useState, useEffect } from 'react';
import '../App.css';
import Header from '../Module/header';
import CouponCard from '../Module/couponCard';
import LsPriceCard from '../Module/lsPriceCard';
import LecSeriesPopUp from '../Module/lecSeriesPopUp';
import { LocalImages } from '../Constant';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateHomeValues,
  getCourseDetail,
  addToCart,
  removeToCart,
  getAddOnList,
  applyCoupon,
  getfreeCourseDetail,
  getExtendedCourseDetail
} from '../Redux/Actions/homeAction';
import BatchTiming from '../Module/batchTiming';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { updateSubscriptionValues } from '../Redux/Actions/subscriptionPlanAction';
import { updateCourseDetailValues } from '../Redux/Actions/courseDetailAction';
import { updateSessionValues } from '../Redux/Actions/sessionAction';
import YouTubeIframe from 'react-youtube-iframe';
import HeaderHome from '../Module/headerHome';
import LoginErrorPopup from '../Module/loginErrorPopup';
import moment from 'moment';
import SEODetails from '../Module/metaTitle';
function LectureSeries() {
  const [open, setopen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const courseID = location.state?.courseID;
  const {courseID,courseName} = useParams();
  const baseCoursename = location?.state?.baseCoursename;
  const baseCourseId = location?.state?.baseCourseId;
  const examName = location?.state?.examName;
  const [show, setShow] = useState(false);
  const [key, setKey] = useState(0);
  const [subscription, setSubscription] = useState([]);
  const [subscriptionName, setSubscriptionName] = useState('');
  const [psId, setPsId] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [checkPrice, setCheckPrice] = useState(0);
  const isextended = location.state?.isextended;
  const [LSPrice, setLSPrice] = useState(0);
  const [couponCodeState, setCouponCode] = useState('');
  const [addToCartDetails, setAddToCartDeatils] = useState({});
  const [vidID, setvidID] = useState(0);
  const [seoData, setSeoData] = useState({});
  var index = 0;
  const [ind, setInd] = useState(0);
  const {
    subCategories,
    name,
    sectionType,
    pdf,
    packageSection,
    sessions,
    primarySubCategoryName,
    OriginalPrice,
    video,
    courseDetail,
    ACCESS_TOKEN_NEW
  } = useSelector((state) => ({
    subCategories: state?.CourseDetailreducer?.subCategories,
    name: state?.CourseDetailreducer?.name,
    sectionType: state?.CourseDetailreducer?.sectionType,
    pdf: state?.CourseDetailreducer?.pdf,
    packageSection: state?.CourseDetailreducer?.packageSection,
    sessions: state?.CourseDetailreducer?.sessions,
    primarySubCategoryName: state?.CourseDetailreducer?.primarySubCategoryName,
    OriginalPrice: state?.HomeReducer?.OriginalPrice,
    video: state?.CourseDetailreducer?.video,
    courseDetail: state?.CourseDetailreducer?.courseDetail,
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));

  useEffect(() => {
    const youtubeParse = (url) => {
      var regExp =
        /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
      var match = url.match(regExp);
      return match && match[1].length == 11 ? match[1] : '';
    };
    if (courseDetail?.video !== undefined) {
      const id = youtubeParse(courseDetail.video);

      setvidID(id);
    }
  }, [courseDetail?.video]);

  const { price, totalPrice } = useSelector((state) => ({
    price: state?.subscriptionPlanReducer?.price,
    totalPrice: state?.subscriptionPlanReducer?.totalPrice
  }));
  const { sessionId } = useSelector((state) => ({
    sessionId: state?.SessionReducer?.sessionId
  }));

  const { subscriptionID, selectedPackages, CouponData, cartListDetails, couponCode } = useSelector(
    (state) => ({
      subscriptionID: state?.HomeReducer?.subscriptionID,
      selectedPackages: state?.HomeReducer?.selectedPackages,
      CouponData: state?.HomeReducer?.CouponData,
      cartListDetails: state?.HomeReducer?.cartListDetails,
      couponCode: state?.HomeReducer?.couponCode
    })
  );
  useEffect(() => {
    const obj = cartListDetails?.filter((object) => object?.courseId === courseID);
    var tempPrice = 0;
    for (let i = 0; i < obj?.length; i++) {
      if (obj[i]?.discountedPrice) tempPrice = tempPrice + obj[i]?.discountedPrice;
      else tempPrice = tempPrice + obj[i]?.price;
    }
    setLSPrice(tempPrice);
    lsPrice = tempPrice;
    dispatch(
      updateHomeValues({
        subscriptionID: '',
        selectedPackages: [],
        CouponData: [],
        couponCode: '',
        showRemoveCoupon: false
      })
    );
    if (ACCESS_TOKEN_NEW) {
      if (isextended === true) {
        dispatch(
          getExtendedCourseDetail(`/${courseID}`, (dataCourse) => {
            if (dataCourse?.couponName) setCouponCode(dataCourse.couponName);
            // dispatch(updateHomeValues({couponCode: dataCourse.couponName}))
            dispatch(
              updateCourseDetailValues({
                primarySubCategoryName: dataCourse?.primarySubCategoryName,
                subCategories: dataCourse?.subCategories,
                language: dataCourse?.language,
                _id: dataCourse?._id,
                name: dataCourse?.name,
                sectionId: dataCourse?.sectionId,
                mainCategoryId: dataCourse?.sectionId,
                sectionType: dataCourse?.sectionType,
                startDate: dataCourse?.startDate,
                bannerImage: dataCourse?.bannerImage,
                video: dataCourse?.video,
                pdf: dataCourse?.pdf,
                duration: dataCourse?.duration
              })
            );

            for (let i = 0; i < dataCourse?.packageSection?.length; i++) {
              dataCourse.packageSection[i]['isSelected'] = false;
            }

            dispatch(
              updateCourseDetailValues({
                packageSection: dataCourse?.packageSection,
                sessions: dataCourse?.sessions,
                courseDetail: dataCourse
              })
            );

            dispatch(updateSessionValues({ sessionId: dataCourse?.sessions[0]?._id }));
            dispatch(updateHomeValues({ selectedCourse: [dataCourse?.packageSection[0]] }));
            const hasSelectIndex = dataCourse?.packageSection?.findIndex(
              (item) => item?.hasSelected
            );
            var count = 0;
            let newPrice = 0;
            let newSelectedPackage = [];
            for (let i = 0; i < dataCourse?.packageSection?.length; i++) {
              if (dataCourse?.packageSection[i]?.hasSelected) {
                dataCourse.packageSection[i]['isSelected'] = true;
                for (let j = 0; j < dataCourse?.packageSection[i]?.subscriptionPlan?.length; j++) {
                  if (dataCourse?.packageSection[i]?.subscriptionPlan[j]?.hasSelected) {
                    dispatch(
                      updateHomeValues({
                        subscriptionID: dataCourse?.packageSection[i]?.subscriptionPlan[j]?._id
                      })
                    );
                    newPrice = newPrice + dataCourse?.packageSection[i]?.subscriptionPlan[j]?.price;

                    const packages = {
                      sectionType: dataCourse?.sectionType,
                      courseId: dataCourse?._id,
                      packageSectionId: dataCourse?.packageSection[i]?._id,
                      subscriptionPlanId: dataCourse?.packageSection[i]?.subscriptionPlan[j]?._id
                    };
                    newSelectedPackage.push(packages);
                  }
                }
                count = 1;
              }
            }
            dispatch(
              updateHomeValues({
                selectedPackages: newSelectedPackage,
                OriginalPrice: newPrice
              })
            );
            dispatch(
              updateSubscriptionValues({
                price: newPrice
              })
            );
            if (count === 0) {
              dispatch(updateSubscriptionValues({ totalPrice: {} }));
              dispatch(
                updateHomeValues({
                  selectedPackages: [],
                  discountAmount: 0,
                  CouponData: [],
                  couponCode: '',
                  showRemoveCoupon: false
                })
              );
            }
          })
        );
      } else
        dispatch(
          getCourseDetail(`/${courseID}`, (dataCourse) => {
            if (dataCourse?.couponName) setCouponCode(dataCourse.couponName);
            // dispatch(updateHomeValues({couponCode: dataCourse.couponName}))
            dispatch(
              updateCourseDetailValues({
                primarySubCategoryName: dataCourse?.primarySubCategoryName,
                subCategories: dataCourse?.subCategories,
                language: dataCourse?.language,
                _id: dataCourse?._id,
                name: dataCourse?.name,
                sectionId: dataCourse?.sectionId,
                mainCategoryId: dataCourse?.sectionId,
                sectionType: dataCourse?.sectionType,
                startDate: dataCourse?.startDate,
                bannerImage: dataCourse?.bannerImage,
                video: dataCourse?.video,
                pdf: dataCourse?.pdf,
                duration: dataCourse?.duration
              })
            );

            for (let i = 0; i < dataCourse?.packageSection?.length; i++) {
              dataCourse.packageSection[i]['isSelected'] = false;
            }

            dispatch(
              updateCourseDetailValues({
                packageSection: dataCourse?.packageSection,
                sessions: dataCourse?.sessions,
                courseDetail: dataCourse
              })
            );

            dispatch(updateSessionValues({ sessionId: dataCourse?.sessions[0]?._id }));
            dispatch(updateHomeValues({ selectedCourse: [dataCourse?.packageSection[0]] }));
            const hasSelectIndex = dataCourse?.packageSection?.findIndex(
              (item) => item?.hasSelected
            );
            var count = 0;
            let newPrice = 0;
            let newSelectedPackage = [];
            for (let i = 0; i < dataCourse?.packageSection?.length; i++) {
              if (dataCourse?.packageSection[i]?.hasSelected) {
                dataCourse.packageSection[i]['isSelected'] = true;
                for (let j = 0; j < dataCourse?.packageSection[i]?.subscriptionPlan?.length; j++) {
                  if (dataCourse?.packageSection[i]?.subscriptionPlan[j]?.hasSelected) {
                    dispatch(
                      updateHomeValues({
                        subscriptionID: dataCourse?.packageSection[i]?.subscriptionPlan[j]?._id
                      })
                    );
                    newPrice = newPrice + dataCourse?.packageSection[i]?.subscriptionPlan[j]?.price;

                    const packages = {
                      sectionType: dataCourse?.sectionType,
                      courseId: dataCourse?._id,
                      packageSectionId: dataCourse?.packageSection[i]?._id,
                      subscriptionPlanId: dataCourse?.packageSection[i]?.subscriptionPlan[j]?._id
                    };
                    newSelectedPackage.push(packages);
                  }
                }
                count = 1;
              }
            }
            dispatch(
              updateHomeValues({
                selectedPackages: newSelectedPackage,
                OriginalPrice: newPrice
              })
            );
            dispatch(
              updateSubscriptionValues({
                price: newPrice
              })
            );
            if (count === 0) {
              dispatch(updateSubscriptionValues({ totalPrice: {} }));
              dispatch(
                updateHomeValues({
                  selectedPackages: [],
                  discountAmount: 0,
                  CouponData: [],
                  couponCode: '',
                  showRemoveCoupon: false
                })
              );
            }
          })
        );
    } else {
      dispatch(
        getfreeCourseDetail(`/${courseID}`, async (dataCourse) => {
          setSeoData(dataCourse?.courseTypeSeo ? dataCourse?.courseTypeSeo : {});
          if (dataCourse?.couponName) setCouponCode(dataCourse.couponName);
          // dispatch(updateHomeValues({couponCode: dataCourse.couponName}))
          dispatch(
            updateCourseDetailValues({
              primarySubCategoryName: dataCourse?.primarySubCategoryName,
              subCategories: dataCourse?.subCategories,
              language: dataCourse?.language,
              _id: dataCourse?._id,
              name: dataCourse?.name,
              sectionId: dataCourse?.sectionId,
              mainCategoryId: dataCourse?.sectionId,
              sectionType: dataCourse?.sectionType,
              startDate: dataCourse?.startDate,
              bannerImage: dataCourse?.bannerImage,
              video: dataCourse?.video,
              pdf: dataCourse?.pdf,
              duration: dataCourse?.duration
            })
          );

          for (let i = 0; i < dataCourse?.packageSection?.length; i++) {
            dataCourse.packageSection[i]['isSelected'] = false;
          }

          dispatch(
            updateCourseDetailValues({
              packageSection: dataCourse?.packageSection,
              sessions: dataCourse?.sessions,
              courseDetail: dataCourse
            })
          );

          dispatch(updateSessionValues({ sessionId: dataCourse?.sessions[0]?._id }));
          dispatch(updateHomeValues({ selectedCourse: [dataCourse?.packageSection[0]] }));
          const hasSelectIndex = dataCourse?.packageSection?.findIndex((item) => item?.hasSelected);
          var count = 0;
          let newPrice = 0;
          let newSelectedPackage = [];
          for (let i = 0; i < dataCourse?.packageSection?.length; i++) {
            if (dataCourse?.packageSection[i]?.hasSelected) {
              dataCourse.packageSection[i]['isSelected'] = true;
              for (let j = 0; j < dataCourse?.packageSection[i]?.subscriptionPlan?.length; j++) {
                if (dataCourse?.packageSection[i]?.subscriptionPlan[j]?.hasSelected) {
                  dispatch(
                    updateHomeValues({
                      subscriptionID: dataCourse?.packageSection[i]?.subscriptionPlan[j]?._id
                    })
                  );
                  newPrice = newPrice + dataCourse?.packageSection[i]?.subscriptionPlan[j]?.price;

                  const packages = {
                    sectionType: dataCourse?.sectionType,
                    courseId: dataCourse?._id,
                    packageSectionId: dataCourse?.packageSection[i]?._id,
                    subscriptionPlanId: dataCourse?.packageSection[i]?.subscriptionPlan[j]?._id
                  };
                  newSelectedPackage.push(packages);
                }
              }
              count = 1;
            }
          }
          dispatch(
            updateHomeValues({
              selectedPackages: newSelectedPackage,
              OriginalPrice: newPrice
            })
          );
          dispatch(
            updateSubscriptionValues({
              price: newPrice
            })
          );
          if (count === 0) {
            dispatch(updateSubscriptionValues({ totalPrice: {} }));
            dispatch(
              updateHomeValues({
                selectedPackages: [],
                discountAmount: 0,
                CouponData: [],
                couponCode: '',
                showRemoveCoupon: false
              })
            );
          }
        })
      );
    }
  }, [courseID]);

  Array.prototype.remove = function (v) {
    if (this.indexOf(v) != -1) {
      this.splice(this.indexOf(v), 1);
    }
  };
  const settrue = async (event, sub, subscriptionObject) => {
    let subObject = subscriptionObject?.subscriptionPlan?.filter(
      (obj) => obj?.isRecommended === true
    );
    const obj = cartListDetails?.filter((object) => object?.courseId === courseID);
    if (!obj) {
      dispatch(updateHomeValues({ subscriptionID: subObject[0]?._id }));
    }
    let removeObj = cartListDetails?.filter(
      (obj) => obj?.packageSectionId === subscriptionObject?._id
    );
    if (removeObj && removeObj[0]) {
      dispatch(removeToCart({ cartId: removeObj[0]?._id }));
    }

    // dispatch(getAddOnList());
    index = packageSection?.findIndex((obj) => obj?._id == subscriptionObject?._id);
    let data = [...packageSection];
    setInd(index);
    data[index].isSelected = event?.target?.checked;
    dispatch(updateCourseDetailValues({ packageSection: data }));
    setPsId(subscriptionObject._id);
    setSubscription(sub);
    setSubscriptionName(subscriptionObject.name);

    if (event.target.checked) {
      setIsChecked(true);
      setopen(true);
    } else {
      dispatch(
        updateHomeValues({
          subscriptionID: '',
          discountAmount: 0,
          CouponData: [],
          couponCode: '',
          showRemoveCoupon: false
        })
      );
      setopen(false);
      setIsChecked(false);
    }
    if (event.target.checked === false) {
      await delete totalPrice[subscriptionObject?._id];
      //const removedObj = filterItem(psId)
      const removed = await selectedPackages?.filter(
        (item) => item?.packageSectionId !== event?.target?.value
      );
      const lengthh = await selectedPackages?.length;
      for (let i = 0; i < lengthh; i++) {
        await selectedPackages.pop();
      }
      for (let i = 0; i < removed?.length; i++) {
        await selectedPackages.push(removed[i]);
      }
      await dispatch(
        updateHomeValues({
          selectedPackages
        })
      );
      //   if(CouponData?.length!==0){
      //     dispatch(
      //      applyCoupon(
      //        {
      //          couponName: couponCode,
      //          // package: selectedPackages?.length !== 0 ? selectedPackages : [postData]
      //          package: selectedPackages
      //        },
      //        (res) => {
      //          if (res?.length == 0) {

      //          } else {
      //           dispatch(
      //             updateHomeValues({
      //               discountAmount: res?.data?.discountedPrice,
      //               showRemoveCoupon: true,
      //               CouponData:  res?.data?.package,
      //             })
      //           );
      //           dispatch(updateSubscriptionValues({ price: res?.data?.discountedPrice }));
      //           dispatch(updateHomeValues({OriginalPrice: res?.data?.price}))

      //          }
      //        }
      //      )
      //    );
      //  }
    }
  };

  const values = Object.values(totalPrice);

  let lsPrice = values.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
  useEffect(() => {
    dispatch(updateSubscriptionValues({ price: lsPrice }));
    dispatch(
      updateHomeValues({
        OriginalPrice: lsPrice
      })
    );
  }, [lsPrice]);

  const handleClose = (event) => {
    setopen(false);
    let data = [...packageSection];
    data[ind].isSelected = false;
    dispatch(updateCourseDetailValues({ packageSection: data }));
  };

  const handleClick = (uncheckPrice, backPage) => {
    setopen(false);
    dispatch(
      updateHomeValues({
        discountAmount: 0,
        // couponCode: '',
        showRemoveCoupon: false
      })
    );
    setCheckPrice(uncheckPrice);
    totalPrice[psId] = uncheckPrice;
    const packages = {
      sectionType,
      courseId: courseID,
      packageSectionId: psId,
      subscriptionPlanId: backPage
    };

    if (isChecked) {
      selectedPackages.push(packages);
    } else {
      selectedPackages.pop();
    }

    if (CouponData?.length !== 0) {
      dispatch(
        applyCoupon(
          {
            couponName: couponCode,
            // package: selectedPackages?.length !== 0 ? selectedPackages : [postData]
            package: selectedPackages
          },
          (res) => {
            if (res?.length == 0) {
            } else {
              dispatch(
                updateHomeValues({
                  discountAmount: res?.data?.discountedPrice,
                  showRemoveCoupon: true,
                  CouponData: res?.data?.package
                })
              );
              dispatch(updateSubscriptionValues({ price: res?.data?.discountedPrice }));
              dispatch(updateHomeValues({ OriginalPrice: res?.data?.price }));
            }
          }
        )
      );
    }
    let index = packageSection?.findIndex((obj) => obj?._id == psId);
    let data = [...packageSection];
    setInd(index);
    data[index].isSelected = true;
    let indexNew = data[index].subscriptionPlan?.findIndex((obj) => obj?._id == backPage);
    data[index].subscriptionPlan[indexNew].isSelected = true;
    dispatch(updateCourseDetailValues({ packageSection: data }));
    if (
      sessions?.length !== 0 &&
      (primarySubCategoryName === 'LIVE' || primarySubCategoryName === 'PREMIERE')
    )
      packages['sessionId'] = sessionId;
  };

  const setOnClick = () => {
    if (ACCESS_TOKEN_NEW) {
      if (isChecked || lsPrice !== 0) {
        if (
          !show &&
          sessions?.length !== 0 &&
          (primarySubCategoryName === 'LIVE' || primarySubCategoryName === 'PREMIERE')
        )
          setShow(true);
        else {
          setShow(false);
          for (let i = 0; i < CouponData.length; i++) {
            for (let j = 0; j < selectedPackages.length; j++) {
              if (CouponData[i].packageSectionId === selectedPackages[j].packageSectionId) {
                selectedPackages[j]['couponId'] = CouponData[i].couponId;
              }
            }
          }

          dispatch(
            addToCart(
              {
                package: selectedPackages,
                extendedOption: isextended === true ? true : false
              },
              (data) => {
                setAddToCartDeatils(data);
                navigate('/MyCart');
              },
              (error) => {
                setErrormsg(error);
              }
            )
          );
        }
      } else {
        dispatch(showErrorSnackbar('Please select Lecture Series'));
      }
    } else {
      navigate('/SignIn', { state: { IsclassRoom: false } });
    }
  };

  const dataForApplyCoupon = {
    sectionType,
    courseId: courseID,
    packageSectionId: packageSection[key]?._id,
    subscriptionPlanId: subscriptionID
  };
  const [wrongParent, setwrongParent] = useState(false);
  const setErrormsg = (val) => {
    setwrongParent(val.popup);
  };
  return (
    <div className="">
      {ACCESS_TOKEN_NEW ? <Header /> : <HeaderHome />}
      <SEODetails title={seoData?.metaTitle} description={seoData?.metaDescrition} />
      <div className="backimg">
        <div className="customcontainer">
          <h3 className="text-uppercase mt-3 mb-3 text-center fw-bold">{name}</h3>
          <div className="row">
            <div className="col-md-6 courseLeftside">
              {/* <div>
                <video className="w-100">
                  <source src={video} type="video/mp4" />
                </video>
              </div> */}
              <div className="mb-3 mt-3 text-center position-relative youtubeframe">
                <YouTubeIframe videoId={vidID} />
              </div>
              <div className="boxshadow rounded py-3 px-4">
                {/* {courseDetail?.startDate ? (
                  <div className="d-flex">
                    <p className="fw-bold">Batch Start :</p>
                    <p className="colorblue ms-1">
                      {moment(courseDetail?.startDate).format('Do MMM YYYY')}
                    </p>
                  </div>
                ) : null} */}

                <div className="d-flex justify-content-between">
                  <h4 className="fw-bold">Course Includes</h4>
                  <a
                    className="btn btn-outline-primary rounded font12 d-flex align-items-center"
                    href={pdf}
                    target="_blank"
                    rel="noreferrer">
                    Course Details{' '}
                    <img
                      src={LocalImages.pdff}
                      alt="pdf"
                      style={{ width: '13px' }}
                      className="ms-2"
                    />
                  </a>
                </div>
                <div className="d-flex justify-content-evenly flex-fill">
                  <div className="col-sm-6 font12 flex-fill">
                    <p className="successcolor fw-bold mb-2">
                      <span className="me-1">
                        <img src={LocalImages.include} alt="include" style={{ width: '15px' }} />
                      </span>{' '}
                      Included
                    </p>

                    {subCategories?.included?.map((c) => (
                      <p className="ms-3 mb-1">
                        <span className="me-2">
                          <img src={c?.icon} alt="Live" style={{ width: '12px' }} />
                        </span>
                        {c?.name}
                      </p>
                    ))}
                  </div>
                  {/* <div className="col-sm-6 font12 flex-fill">
                    <p className="errorcolor fw-bold mb-2">
                      <span className="me-1">
                        <img src={LocalImages.exclude} alt="Exclude" style={{ width: '15px' }} />
                      </span>{' '}
                      Excluded
                    </p>

                    {subCategories?.excluded?.map((c) => (
                      <p className="ms-3 mb-1">
                        <span className="me-2">
                          <img src={c?.icon} alt="Live" style={{ width: '12px' }} />
                        </span>
                        {c?.name}
                      </p>
                    ))}
                  </div> */}
                </div>
                {/* <div className="d-flex justify-content-end">
                  <a
                    className="btn btn-outline-primary rounded font10 p-1 px-3"
                    href={pdf}
                    target="_blank"
                    rel="noreferrer">
                    Course Details{' '}
                    <img
                      src={LocalImages.pdff}
                      alt="pdf"
                      style={{ width: '13px' }}
                      className="ms-2"
                    />
                  </a>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              {packageSection[0]?.isOffer ? (
                <div className="px-md-2 offerBox">{packageSection[0]?.offer}</div>
              ) : (
                <div className="pt-4 pb-3"></div>
              )}

              <div className="row d-flex flex-wrap p-4 px-2 lsRightBox font11">
                {packageSection?.map((c, index) => (
                  <LsPriceCard
                    data={c}
                    onchange={(e) => {
                      settrue(e, c?.subscriptionPlan, c);
                    }}
                    isSelected={c?.isSelected}
                    index={index}
                  />
                ))}
              </div>
              <CouponCard
                disabled={!(selectedPackages.length > 0)}
                btnname={ACCESS_TOKEN_NEW ? 'Buy now' : 'Login'}
                Price={price}
                couponCode={couponCodeState}
                setOnClick={setOnClick}
                dataForApplyCoupon={dataForApplyCoupon}
                itemCount={selectedPackages?.length}
                hideInput={true}
              />
            </div>
          </div>
        </div>
      </div>
      <LecSeriesPopUp
        open={open}
        handleClose={handleClose}
        subscription={subscription}
        name={subscriptionName}
        handleClick={handleClick}
      />
      {show ? <BatchTiming show={setShow} setKey={key} /> : ''}
      {wrongParent ? (
        <LoginErrorPopup
          wrongParent={setwrongParent}
          setKey={key}
          baseCoursesname={baseCoursename}
          examName={examName}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default LectureSeries;
