import React, { useEffect, useState } from 'react';
import { getTestAttempted, getLearningProgress, } from '../Redux/Actions/homeAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LocalImages } from '../Constant';
import NoRecord from '../Component/noRecord';
function CourseAnalysis() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [learnignProgess, setlearnignProgess] = useState([]);
  const [testAttempted, setTestAttempted] = useState([]);
  const { selectedTiles, examTypeId, baseCourseId, } = useSelector((state) => ({
    selectedTiles: state?.HomeReducer?.selectedTiles,
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
  }));
const icons = [LocalImages.videos, LocalImages.dpp, LocalImages.tests, LocalImages.modules];
  useEffect(() => {
    getProgress();
    getAttempted();
  }, []);

  const getProgress = () => {
    const postData = {
      courseId: selectedTiles?.courseId,
    };
    dispatch(getLearningProgress(postData, async (data) => {
      await setlearnignProgess(data);
    }))
  };
  const getAttempted = () => {
    const postData = {
      examTypeId: examTypeId,
      baseCourseId: baseCourseId
    };
    dispatch(getTestAttempted(postData, (data) => {
      console.log("test-attmeped", data);
      setTestAttempted(data)
    }))
  };

  const testAnalysisRoute = () => {
    navigate('/PaidUser/TestAnalysis');
  };
  return (
    <section className='course-analylis'>
      <div className="rightPanel">
        <h3>
          Course Analysis
        </h3>
        <div className="col-sm-11 bgwhite boxshadow p-5 mb-4">
          <div className='d-flex'>
            <h5 className='colorblue mb-3'>Learning Progress</h5>
            <p className='ms-auto'>Today's</p>
          </div>
          <div className="row">
            {learnignProgess.length > 0 ? (
              learnignProgess.map((lp ,index) => {
                return (
                  <div className="col-md-5 me-5 p-md-4 mb-4 text-center cursor bgwhite greyBorder">
                    <p><img src={icons[index]} alt="Video" className='me-3' width="30" />{lp?.result?.Count ? lp?.result?.Count : '0'}</p>
                    <span className='d-block'>{lp?.title}</span>
                  </div>
                )
              })) : (
              <NoRecord />
            )}
          </div>
        </div>
        <div className="col-sm-11 bgwhite boxshadow p-5">
          <h5 className='colorblue mb-3'>Test Attempted</h5>
          <div className="row justify-content-center">
            {testAttempted.map((ta) => {
              return (
                <div className="col-md-4 col-md-offset-4 mb-lg-0 mb-4 text-center cursor bgwhite">
                  <div className='card' onClick={testAnalysisRoute}>
                    <div className='card-body'>
                      <p>{ta?.result?.Count ? ta?.result?.Count : '0'}</p>
                      <span className='d-block text-dark'><b>{ta?.title}</b></span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CourseAnalysis;
