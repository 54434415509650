import React from 'react'

function TestVideoPlayer() {
    const manifestUri =
    // 'https://storage.googleapis.com/shaka-demo-assets/angel-one/dash.mpd';
    "https://ifas-m3u8.s3.ap-south-1.amazonaws.com/M3U8_VIDEO/UGC+NET+COMPUTER+SCIENCE/Artificial+Intelligence/Video/L01++Artificial+Intelligence/hls-master.m3u8";

async function init() {
  // When using the UI, the player is made automatically by the UI object.
  const video = document.getElementById('video');
  const ui = video['ui'];
  const controls = ui.getControls();
  const player = controls.getPlayer();

 
  window.player = player;
  window.ui = ui;

  // Listen for error events.
//   player.addEventListener('error', onPlayerErrorEvent);
//   controls.addEventListener('error', onUIErrorEvent);

  // Try to load a manifest.
  // This is an asynchronous process.
  try {
    await player.load(manifestUri);
    // This runs if the asynchronous load is successful.
  } catch (error) {
    onPlayerError(error);
  }
}

// function onPlayerErrorEvent(errorEvent) {
//   // Extract the shaka.util.Error object from the event.
//   onPlayerError(event.detail);
// }

function onPlayerError(error) {
  // Handle player error
 
}

// function onUIErrorEvent(errorEvent) {
//   // Extract the shaka.util.Error object from the event.
//   onPlayerError(event.detail);
// }

function initFailed(errorEvent) {
  // Handle the failure to load; errorEvent.detail.reasonCode has a
  // shaka.ui.FailReasonCode describing why.
 
}

// Listen to the custom shaka-ui-loaded event, to wait until the UI is loaded.
document.addEventListener('shaka-ui-loaded', init);
// Listen to the custom shaka-ui-load-failed event, in case Shaka Player fails
// to load (e.g. due to lack of browser support).
document.addEventListener('shaka-ui-load-failed', initFailed);
  return (
    <>
    <div data-shaka-player-container style={{maxWidth:'40em'}}
         data-shaka-player-cast-receiver-id="8D8C71A7">
      <video autoplay data-shaka-player id="video" style={{width:'100%',height:'100%'}}></video>
    </div>
    </>
  )
}

export default TestVideoPlayer;