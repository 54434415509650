import React, {useState} from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
function subMenuButton(props) {
  const [isSubExamMenu, setIsSubExamMenu] = useState(false);
  const showsubexammenu = (val, indexval) => {
    props?.setselectedmenu(indexval);
    setIsSubExamMenu(true);
  };

  return (
    <div
      className="d-flex justify-content-between border-bottom cursor p-2 "
      onClick={() => {
        props?.data?.basecourse
          ? showsubexammenu(props?.data.examID, props?.index)
          : props?.handleroutestudymaterial(props?.data?.basecourseid, props?.data?.examID);
      }}>
      <p className="mb-0 font13">{props?.data?.subexamName}</p>
      {props?.data?.basecourse ? (
        <div className="text-end">
          <ArrowRightIcon />
        </div>
      ) : null}
      {isSubExamMenu === true ? (
        <div className="position-absolute submenu bgwhite boxshadow border">
          {props?.data?.basecourse?.map((item, index) => {
            return (
              <div
                className="d-flex justify-content-between border-bottom cursor p-2 "
                onClick={() => {
                  props?.handleroutestudymaterial(item?.basecourseid, props?.data?.examID);
                }}>
                <p className="mb-0 font13">{item?.basecoursename}</p>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default subMenuButton;
