import { CompressOutlined } from '@mui/icons-material';
import React from 'react';
import '../App.css';

function CompleteSyllabusCard(data) {
  return (
    <div
      className={
        data.selectedval == data.data._id
          ? 'boxshadow borderBlue d-flex p-3 rounded mb-3 bgwhite row cursor'
          : 'boxshadow   d-flex p-3 rounded mb-3 bgwhite row cursor'
      }
      onClick={() => data.onclick(data.data)}>
      <div className="d-flex justify-content-start">
        <div className=" colorgrey fw-bold">{data?.data?.subjectName}</div>
      </div>
    </div>
  );
}

export default CompleteSyllabusCard;
