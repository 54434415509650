import home from '../Assets/Images/Dashboard/home.png';
import course from '../Assets/Images/Dashboard/course.png';
import learn from '../Assets/Images/Dashboard/learn.png';
import profile from '../Assets/Images/Dashboard/profile.png';
import address from '../Assets/Images/Dashboard/address.png';
import refer from '../Assets/Images/Dashboard/refer.png';
import orderhistory from '../Assets/Images/Dashboard/orderhistory.png';
import logout from '../Assets/Images/Menu/logout.png';
import setting from '../Assets/Images/Dashboard/setting.png';

export const SidebarData = [
  {
    title: 'Dashboard',
    link: 'Dashboard',
    extra: ['Dashboard', 'PlaceOrderInstallment'],
    imgpath: home
  },
  {
    title: 'Course',
    link: 'OnlineCourse',
    extra: ['OnlineCourse'],
    imgpath: course
  },
  {
    title: 'Learn',
    link: 'Learn',
    extra: [
      'Lectures',
      'Learn',
      'OnlineTest',
      'AllAbout',
      'AllLectureVideo',
      'TeacherWiselectures',
      'SubjectTest',
      'LectureScheduleFree',
      'FreeLectures',
      'LiveClass'
    ],
    imgpath: learn
  },
  {
    title: 'My Profile',
    link: 'MYProfile',
    extra: ['MYProfile'],
    imgpath: profile
  },
  {
    title: 'Addresses',
    link: 'MyAddress',
    extra: ['MyAddress'],
    imgpath: address
  },
  {
    title: 'Order History',
    link: 'OrderHistory',
    extra: ['OrderHistory', 'OrderDetails'],
    imgpath: orderhistory
  },
  // {
  //   title: 'Refer & Earn',
  //   link: 'ReferEarn',
  //   extra: ['ReferEarn'],
  //   imgpath:refer
  // }
];
