import React, { useState, useEffect } from 'react';
import '../App.css';
import MyLectureCard from '../Module/myLectureCard';
import { useDispatch, useSelector } from 'react-redux';
import { getRecordedPaid, getSubscribedRecordedPaid } from '../Redux/Actions/homeAction';
import NoRecord from '../Component/noRecord';
function MyLectures() {
  const dispatch = useDispatch();
  const { selectedTiles } = useSelector((state) => ({
    selectedTiles: state?.HomeReducer?.selectedTiles
  }));
  let [lectureData, setLectureData] = useState([]);

  useEffect(() => {
    dispatch(
      getSubscribedRecordedPaid(
        `${selectedTiles?.courseId}/${selectedTiles?.packageSectionId}`,
        (data) => {
          if (data.length > 0) {
            setLectureData(data);
          }
        }
      )
    );
  }, [selectedTiles]);

  return (
    <div className="rightPanel">
      <div>
        <h3>{selectedTiles?.name}</h3>
      </div>
      {lectureData.length > 0 ? (
        <div className="mt-4">
          <div className="row d-flex ">
            {lectureData.map((c) => (
              <MyLectureCard key={c?._id} data={c} />
            ))}
          </div>
        </div>
      ) : (
        <NoRecord />
      )}
    </div>
  );
}

export default MyLectures;
