import React from 'react';
import { useEffect } from "react";
import { LocalImages } from '../Constant';
import { useSelector } from 'react-redux';
import { useState } from "react";
import HeaderHome from '../Module/headerHome';
import Header from '../Module/header';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { examType, getCountry, getState, sendOTP, updateMoreValues, verifyOTP } from "../Redux/Actions/AuthAction";
import ReactHtmlParser from 'react-html-parser';
import InputField from '../Component/inputField';
import Phoneno from '../Component/phoneno';
function JobDescription() {
  const dispatch = useDispatch();
  const location = useLocation();
  const jobData = location.state.jobData;
  const [number, setNumber] = useState('');
  const [countryData, setCountryData] = useState([]);
  const [countryCode, setCountryCode] = useState({ _id: '', name: '', callingCode: '', flag: '' });
  const [openresumesubmit, setresumesubmit] = useState(false);
  const [Fullname, setFullname] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [CompanyEmail, setCompanyEmail] = useState("");
  const [noticePeriodDays, setNoticePeriodDays] = useState("");
  const [ctc, setCtc] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const { ACCESS_TOKEN_NEW, } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW,
  }));
  useEffect(() => {
    dispatch(
      getCountry(
        (data) => {
          setCountryData(data);
          const filteredCountries = data.filter((item) => {
            const countryCodeNew = item.callingCode.toLowerCase();
            return countryCodeNew.indexOf('+91') > -1;
          });
          setCountryCode(filteredCountries[0]);
          setNumber('');
        },
        () => {}
      )
    );
  }, []);
  const handleOnChangePhoneText = (prevPhoneInputText) => {
    const numbersNew = /^[0-9]+$/;
    if (!prevPhoneInputText.target.value) setNumber(prevPhoneInputText.target.value);
    else if (prevPhoneInputText.target.value.match(numbersNew))
      setNumber(prevPhoneInputText.target.value);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
  };
  
  const handleFullNameChange = (value) =>{
    if (validateFullName(value)) setFullname(value);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
     validateForm();
    //  setFullname(value);
    //  validateForm();
  }
  function handleCompanyEmailChange(value) {
    setCompanyEmail(value);
    validateForm();
  }
  function handleJobRoleChange(value) {
    setJobRole(value);
    validateForm();
  }
  function handleCompanyNameChange(value) {
    setCompanyName(value);
    validateForm();
  }
  function handleNoticePeriodDaysChange(value) {
    const numbersNew = /^[0-9]+$/;
    if (!value)  setNoticePeriodDays(value);
    else if ( value.match(numbersNew))
    setNoticePeriodDays(value);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
    // setNoticePeriodDays(value);
    validateForm();
  }
  function handleCtcChange(value) {
    const ctc = /^[0-9]*[.,]?[0-9]*$/;
    if (!value) setNumber(value);
    else if (value.match(ctc))
    setCtc(value);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
    // setCtc(value);
     validateForm();
  }
  const handleResumeChange = (e) =>{
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    const fileSizeKiloBytes = e.target.files[0].size / 1024;
    if (fileSizeKiloBytes > 1024) {
      dispatch(showErrorSnackbar("File size should be less than 1 MB!!!"));
    }else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setResume(e.target.files[0]);
      };
      reader.readAsDataURL(file);
    }
    // temp = true
    // setResumeSelect(true)
    // setResume(e.target.files[0]);
    validateForm();
  }

  function validateForm() {
    if (Fullname && CompanyEmail && jobRole && CompanyName && ctc && noticePeriodDays && temp) {
      setIsFormValid(true);
      setIsButtonEnabled(true);
    } else {
      setIsFormValid(false);
      setIsButtonEnabled(false);
    }
}
  
  
  return (
  <div className=''>
   {ACCESS_TOKEN_NEW == '' || ACCESS_TOKEN_NEW == undefined ? <HeaderHome /> : <Header />}
   <div className='backimg privacy'>
   <div className="common-pad py-5">
   <div className="d-flex align-items-center">
                <h1 className="boldfont text-primary">{jobData?.designation}:</h1>
        </div>
        <div>
         { ReactHtmlParser(jobData?.description)}
        </div>
        <div className="col-sm-12 lifeatdhister common-pad py-5 ">
        <h1 className="boldfont mb-4 colorBlue text-center">
        Submit Your Resume
        </h1>
      <p className="text-center text-secondary mb-0">
        For Position {jobData?.designation}
        </p>
        <div className="d-flex justify-content-center">
        <div className="col-md-10 boxshadow p-3 p-md-5 mt-4 bgwhite  ">
          <form className="row setmargin"  >
            <div className="col-md-6 nopadding">
              
                <label className="mb-2">
                  Full Name <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Enter full name"
                  className="w-100 mb-3"
                  setInputText={(value) => handleFullNameChange(value)}
                  value={Fullname}
                  //disabled={props.disabled}
                  maxLength={50}
              
                />
              
            </div>
            <div className="col-md-6 nopadding mobile-placeholder">
            <label className="mb-2">Mobile number</label>
              <Phoneno className="placeholder"
                setCountryCode={(value) => setCountryCode(value)}
                countryCode={countryCode}
                countryData={countryData}
                placeholder="Enter Your Phone Number"
                maxLength="10"
                minLength={10}
                value={number}
                handleOnChangePhoneText={handleOnChangePhoneText}
                
              />
            </div> 
            <div className="col-md-6 nopadding">
             
            <label className="mb-2">
                Email <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Enter email address"
                  className="w-100"
                  setInputText={(value) => handleCompanyEmailChange(value)}
                  value={CompanyEmail}
                  //disabled={props.disabled}
                />
              
            </div>
            <div className="col-md-6 nopadding">
              
            <label className="mb-2">
                Job Role  <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Enter current job role"
                  className="w-100"
                  setInputText={(value) => handleJobRoleChange(value)}
                  value={jobRole}
                 // disabled={props.disabled}
                  minLength={30}
                />
              
            </div>
            <div className="col-md-6 nopadding">
             
            <label className="mb-2">
                Current Company Name   <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Enter Current Company name"
                  className="w-100"
                  setInputText={(value) => handleCompanyNameChange(value)}
                  value={CompanyName}
                 // disabled={props.disabled}
                  minLength={30}
                />
             
            </div>
            <div className="col-md-6 nopadding">
              
            <label className="mb-2">
                Current CTC (in LPA) <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Enter Current CTC (in LPA)"
                  className="w-100"
                  setInputText={(value) => handleCtcChange(value)}
                  value={ctc}
                 // disabled={props.disabled}
                  minLength={4}
              
                />
              
            </div>
            <div className="col-md-6 nopadding">
              
            <label className="mb-2">
                Notice Period Days  <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Notice period days"
                  className="w-100"
                  setInputText={(value) => handleNoticePeriodDaysChange(value)}
                  value={noticePeriodDays}
                  //disabled={props.disabled}
                />
              
            </div>
            <div className="col-md-6 nopadding">
              
            <label className="mb-2">
                Attach Resume <span className="text-danger">*</span>
                </label>
                <div className="d-flex">
                  <div className="position-relative col-7 me-1">
                  <input className=" form-control ps-4" placeholder="Select Resume" type='file' accept="application/pdf , .doc ,.docx" onChange={handleResumeChange}/>
                  <span className="uploadicon"><UploadFileRoundedIcon/></span>
                  </div>
                  <p className="resumetxt">Upload in either DOC, DOCX or PDF file format (file size not more than 1MB)</p>
              </div>
            </div>
            <div className="text-center mt-3">
          <button type="button" className={isButtonEnabled ? "btn btn-primary px-5 ": "btn btn-secondary px-5 " }   disabled={!isFormValid}  >Submit</button>
           </div>
          </form>
        </div>
        </div>
      </div>
         
            
       
        </div>
      </div>

   </div>
 
  );
}
export default JobDescription;