import React, { useState, useEffect } from 'react';
import '../App.css';
import { useSelector } from 'react-redux';
import 'shaka-player/dist/controls.css';
import chatimg from '../Assets/Images/LiveLectureVideo/chat.png';
import chatimgActive from '../Assets/Images/LiveLectureVideo/chat-active.png';
import { createRef } from 'react';
import videojs from 'video.js';
import { io } from 'socket.io-client';
import ChatPage from './ChatPage';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SettingsIcon from '@mui/icons-material/Settings';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import { useDispatch } from 'react-redux';
import LivevedioEndedPopUp from '../Module/livevideoEndedPopUp';
import { startEndLiveStatus } from '../Redux/Actions/homeAction';

function MediaPlayer() {
  const dispatch = useDispatch();
  const vidRef = createRef();
  const [myVideoPLayer, setMyVidePlayer] = useState();
  const [mute, setmute] = useState(true);
  const [open, setOpen] = useState(false);
  const [play, setPlay] = useState(true);
  const [settings, setSetting] = useState(false);
  const [initialstyle, afterstyle] = useState();
  const [chatshow, chathide] = useState('chat_hide');
  const [openVideo, setopenVideo] = useState(false);

  const { liveVideoData, userId } = useSelector((state) => ({
    userId: state?.AuthReducer?._id,
    liveVideoData: state?.AuthReducer?.liveVideoData
  }));
  const [temp, setTemp] = useState(liveVideoData?.defaultResolution);
  const socket = io.connect(process.env.REACT_APP_BASEURL, {
    secure: true,
    query: {
      room: liveVideoData?.streamKey
    },
    withCredentials: true
  });

  useEffect(() => {
    // getFullScreen();
    setMyVidePlayer(
      videojs('video', {
        sources: [
          {
            type: 'application/x-mpegURL',
            src: liveVideoData?.videoPlayerObj[liveVideoData?.defaultResolution]
          }
        ]
      })
    );
    // setmute(false)
  }, []);

  useEffect(() => {
    let player = vidRef.current;
    return () => {
      if (player && !player.isDisposed) {
        let videoo = videojs('video');
        videoo.dispose();
        vidRef.current = null;
      }
    };
  }, []);

  const onResolutionChange = (link, key) => {
    setSetting(!settings);
    setTemp(key);
    myVideoPLayer.src({
      type: 'application/x-mpegURL',
      src: link
    });

    myVideoPLayer.play();
  };
  const handleclose = () => {
    setSetting(!settings);
  };

  function handleclick() {
    setOpen(!open);
    if (initialstyle == 'videoplayerstyle') {
      afterstyle('afterchatclick');
      chathide('chat_hide');
    } else {
      afterstyle('videoplayerstyle');
      chathide('chat');
    }
  }
  const handlePlay = () => {
    setPlay((prev) => !prev);
    if (play) vidRef.current?.play();
    else vidRef.current?.pause();
  };
  const handleFullScreen = () => {
    if (vidRef?.current?.requestFullscreen) {
      vidRef?.current?.requestFullscreen();
    } else if (vidRef?.current?.msRequestFullscreen) {
      vidRef?.msRequestFullscreen();
    } else if (vidRef?.current?.mozRequestFullScreen) {
      vidRef?.current?.mozRequestFullScreen();
    } else if (vidRef?.current?.webkitRequestFullScreen) {
      vidRef?.current?.webkitRequestFullScreen();
    }
  };

  const handleExitFullScreen = () => {
    if (document.fullscreenElement === null) {
      // var docElm = document.documentElement;
      // handleFullScreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const handleLiveVideoClose = () => {
    setopenVideo(false);
  };

  const handleLiveVideoClick = () => {
    handleExitFullScreen();
    vidRef.current?.pause();
    setopenVideo(true);
  };

  const handleMute = () => {
    setmute((prev) => !prev);
  };

  function sleep(delay) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
  }

  window.addEventListener('beforeunload', function (e) {
    e.returnValue = '';
    sleep(3000);
    dispatch(
      startEndLiveStatus({
        userId: userId,
        liveId: liveVideoData.liveId,
        liveType: 1,
        isLive: false
      })
    );
    opener.location.reload();
    window.close();
  });

  return (
    <div className="d-flex mediaplayer mediaplayerdiv ">
      <div className={open ? 'videoplayerBox1 ' : 'videoplayerBox  '}>
        <div id="video-container">
          <video
            ref={vidRef}
            id="video"
            className="video-js"
            preload="auto"
            width="640"
            height="264"
            // onError={() => {
            //   // history.back();
            //    dispatch(showErrorSnackbar('Your Live has been ended'));
            //  // handleLiveVideoClick()
            // }}
            onEnded={() => {
              // history.back();
              // dispatch(showErrorSnackbar('Your Live has been ended'));
              handleExitFullScreen();
              vidRef.current?.pause();
              handleLiveVideoClick();
            }}
            autoPlay
            muted={mute}
            data-setup="{}">
            <source type="application/x-mpegURL" />
          </video>
        </div>

        <div className="videobottom p-2 pe-4 d-flex justify-content-end align-items-center">
          <span className="me-2" onClick={handleMute}>
            {!mute ? <VolumeUpRoundedIcon /> : <VolumeOffRoundedIcon />}
          </span>
          <span className="me-2" onClick={handleFullScreen}>
            <FullscreenIcon />
          </span>
          <span className="me-2">
            <ForumRoundedIcon
              src={open ? chatimgActive : chatimg}
              className="cursor livechatbtn"
              alt="chat"
              onClick={handleclick}
            />
          </span>
          <span className="position-relative">
            <SettingsIcon onClick={() => setSetting(!settings)} />
            {settings ? (
              <div className="videoquality bgwhite boxshadow position-absolute rounded">
                <div className="text-center border-bottom">
                  {/* <ClearOutlinedIcon style={{width:"17px"}} className="colorblue cursor" onClick={handleclose}/> */}
                  <p className="cursor  mb-0" onClick={handleclose}>
                    Close
                  </p>
                </div>
                {Object.keys(liveVideoData?.videoPlayerObj).map(function (key, index) {
                  return (
                    <div
                      id={key}
                      className="qualityno py-2 border-bottom"
                      style={{ cursor: 'pointer', background: temp === key ? '#c2c2a3' : '' }}
                      onClick={() => {
                        onResolutionChange(liveVideoData?.videoPlayerObj[key], key);
                      }}>
                      {key}
                    </div>
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </span>
        </div>
      </div>
      <ChatPage socket={socket} hidechatbox={chatshow} />
      <LivevedioEndedPopUp open={openVideo} handleClose={handleLiveVideoClose} />
    </div>
  );
}

export default MediaPlayer;
