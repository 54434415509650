import React, { useEffect, useState } from 'react';
import { addResponseMessage, addUserMessage, deleteMessages } from 'react-chat-widget';
import '../chatStyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getMessageList, addSupportMessage, deleteSupportMsg } from '../Redux/Actions/homeAction';
import '../App.css';
import InputField from '../Component/inputField';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import moment from 'moment/moment';
import endpoint from '../Constant/endpoint';
import axios from 'axios';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { LocalImages } from '../Constant';
import DeleteIcon from '@mui/icons-material/Delete';
import ChatImagePopup from '../Module/chatImagePopup';
import { startsWithSpace } from '../utills/validators';
import { reopensupport } from '../Redux/Actions/AuthAction'; 
import RefreshIcon from '@mui/icons-material/Refresh';

function TechnicalSupportChat() {
  const [messages, setMessages] = useState([]);
  const [imageFull, setImageFull] = useState(); 
  const [coursename, setCoursename] = useState([]);
  const [logo, setLogo] = useState([]);
  const [userId, setUserID] = useState('');
  const [msg, setmsg] = useState('');
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [uploadimg, setuploadimg] = useState({});
  const [open, setopen] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteInfo, setdeleteInfo] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const { authToken } = useSelector((state) => ({
    authToken: state.AuthReducer.ACCESS_TOKEN_NEW
  }));
 
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const status = location.state?.status;
  let _id = '';
  const appid = location.state?.appId;
  if (appid !== 1) {
    _id = location.state?.id.toString();
  }
 
  useEffect(() => {
    if (appid !== 1) {
      start();
    }
  }, [_id]);

  const start = () => {
    dispatch(
      getMessageList(
        _id,
        (res) => {
          // settechsupport(res);
 
          setUserID(res?.data[0]?.from?._id);
          setMessages(res?.data);
          setCoursename(res?.coursename)
          setLogo(res?.logo)

          // res?.data?.map((item) => {
          //   const { message } = item;
          //   if (message !== undefined) {
          //     if (item?.from?._id === res?.data[0]?.from?._id) addResponseMessage(item?.message);
          //     else addUserMessage(item?.message);
          //   }
          // });
        },
        () => {}
      )
    );
  };

  const handleClose = () => {
    setOpenImage(false);
  };

  const handleOnClick = () => {
    setOpenImage(true);
  };

  const setmsgval = (value) => {
    setmsg(value);
  };

  const handleNewUserMessage = () => {
    const data = {
      message: msg,
      messageType: 1,
      supportId: _id,
      to: userId
    };
    dispatch(
      addSupportMessage(data, () => {
        setmsg('');
        start();
      })
    );
  };

  const handleChange = (e, val) => {
    setopen(!open);  
    const fileSizeKiloBytes = e.target.files[0].size / 1024; 
    const fileSizeKiloBytesPdf = e.target.files[0].size / 1024;  
    if (parseInt(fileSizeKiloBytes) > 15360 && val === 1){
      dispatch(showErrorSnackbar('Image size should be less than 15 MB!!!'));
    }
    
    else if(parseInt(fileSizeKiloBytesPdf) > 10240 && val === 2){
      dispatch(showErrorSnackbar('File size should be less than 10 MB!!!'));
    }
    else {
          if (e.target.files.length) {
        if (e.target.files.length) {
          setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
          });
        }
        functionTwo(e.target.files[0], val);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleNewUserMessage();
    }
  };

  const functionTwo = async (val, no) => {
    const res1 = await fileUpload(val, no);

    if (res1.data.data) {
      const data = {
        messageType: 2,
        supportId: _id,
        to: userId,
        mediaFile: {
          file: res1.data.data.file,
          originalName: res1.data.data.originalName,
          type: no
        }
      };
      dispatch(
        addSupportMessage(data, () => {
          setmsg('');
          start();
        })
      );
      dispatch(showSuccessSnackbar(res1.data.msg));
      setuploadimg(res1.data.data);
    } else {
      dispatch(showErrorSnackbar(res1.data.msg));
    }
  };

  const fileUpload = (file, no) => {
    dispatch(showSuccessSnackbar('File Uploading ...'));

    const url = process.env.REACT_APP_BASEURL + endpoint.home.uploadSupportMedia + no + '/';

    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'content-type': 'multipart/form-data'
      }
    };
    return axios.post(url, formData, config);
  };

  const openpdf = (link) => {
    window.open(link, "_blank"); 
   // window.open("https://www.w3schools.com", "_blank"); 
    
  };

  const clickBackButton = () => {
    history.back()
  };

  const deleteMsg = (_id) => {
    dispatch(
      deleteSupportMsg(_id, () => {
        start();
      })
    );
  };

  const clickDeleteIcon = () => {
    setShow(!show);
    setnotify(false);
  };

  const deleteData = () => {
    deleteMsg(_id);
  };
  const handleReopen = () => {
    dispatch(
      reopensupport({status:2},_id, (res) => {
 
        clickBackButton()
      })
    );
  };
  return (
    <div className="rightPanel">
      <div className="row">
        <div className="col-lg-9 rounded bgwhite px-0">
          <div className="">
            <div className="p-lg-3">
              {location?.state?.screeName === 'TechnicalSupport'  ||  location?.state?.screeName === 'AddTechnicalSupport' ?(
                <div className="msgheader bg-blue border p-2 rounded-top d-flex">
                  <ArrowBackIosIcon onClick={clickBackButton} />
                  <img
                    src={LocalImages.favicon}
                    alt="IFAS Logo"
                    className=" cursor"
                    style={{ width: '40px' }}
                  />
                  <p className="font16 mb-0 fw-bold ms-2 ">Technical Support</p>
                   
                  <div className='requestMsg'>
                  <RefreshIcon
                      className="colorwhite fs-1 p-1  border-primary cursor "
                      onClick={() => start()}
                    />
                  </div>
                </div>
              ) : (
                <div className="msgheader bg-blue border p-2 rounded-top d-flex">
                  <ArrowBackIosIcon onClick={clickBackButton} />
                  <img
                    src={logo}
                    alt="Profile"
                    className="rounded-circle logoheaderChat"
                  />
                  <p className="font16 mb-0 fw-bold ms-2 ">{coursename}</p>
                  <div className='requestMsg'>
                  <RefreshIcon
                      className="colorwhite fs-1 p-1  border-primary cursor "
                      onClick={() => start()}
                    />
                  </div>
                     
                </div>
              )}

                    
             

              <div className="messegeBox p-2 border">
 
                {messages != undefined
                  ? messages.map((item, index) => {
                      const { message, createdAt, from, mediaFile, _id, messageType, hasMessages } = item;
                      if (from?._id === userId) {
                        if (messageType === 1) {
  
                      return (
                            <div className="mt-2">
                              <div key={index.toString()} className="d-flex">
                                <div className="requestMsg">
                                  <div>{item?.message}</div>
                                </div>
                                <div
                                  className="font12 text-end cursor "
                                  onClick={() => {
                                    deleteMsg(_id);
                                  }}>
                                  <DeleteIcon />
                                  
                                </div>
                              </div>
                              <div className="font12 text-end">
                                {moment(createdAt).format('D MMMM YYYY, h:mm a')}
                              </div>
                            </div>
                          );
                        } else {
   
                      const filename = (mediaFile?.originalName)
                      return (
                            <>
                              <div className="d-flex">
                                <div className="requestMsg">
                                  <div>
                                    {filename&&filename.includes('pdf') ? (
                                      <div
                                        className="cursor"
                                        onClick={() => openpdf(mediaFile?.file)}>
                                        <PictureAsPdfIcon />
                                        <span className="d-block">{mediaFile?.originalName}</span>
                                      </div>
                                    ) : filename&&filename.includes('mp3')?
                                    (
                                      <>
                                        <div
                                          onClick={() => {
                                            deleteMsg(_id);
                                          }}>
                                         <audio controls controlsList="nodownload noplaybackrate">
                                         <source src={mediaFile?.file} type="audio/ogg"/>
                                         </audio>
                                        </div>
                                      </>
                                    )

                                    :(
                                      <>
                                        <div
                                          onClick={() => {
                                            deleteMsg(_id);
                                          }}></div>
                                          <div>
                                            <div onClick ={()=>{handleOnClick(); setImageFull(mediaFile)}}>
                                            <img
                                            className="chatimg"
                                            src={mediaFile?.file}
                                            alt={mediaFile?.originalName}
                                            />
                                            </div>
                                          
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="font12 text-end cursor "
                                  onClick={() => {
                                    deleteMsg(_id);
                                  }}>
                                  <DeleteIcon />
                                </div>
                              </div>
                              <div className="font12 text-end">
                                {moment(createdAt).format('D MMMM YYYY, h:mm a')}
                              </div>
                            </>
                          );
                        }
                      } else {
                        if (messageType === 1) {
                          return (
                            <>
                               <div className="responseMsg">
                              <div>{message}</div>
                              
                            </div>
                            <div className="font12">{moment(createdAt).format('D MMMM YYYY, h:mm a')}</div>
                            </>
                           
                          );
                        } else {
                          return (
                            <div className="responseMsg">
                              <div>
                                {mediaFile?.type === '2' ? (
                                  <div className="cursor" onClick={() => openpdf(mediaFile?.file)}>
                                    <PictureAsPdfIcon />
                                    <span className="d-block">{mediaFile?.originalName}</span>
                                  </div>
                                ) : (
                                  <div onClick ={()=>{handleOnClick(); setImageFull(mediaFile)}}>
                                  <img
                                    className="chatimg"
                                    src={mediaFile?.file}
                                    alt={mediaFile?.originalName}
                                  />
                                  </div>
                                )}
                              </div>
                              <div className="font12">{moment(createdAt).format('llll')}</div>
                              
                            </div>
                          );
                        }
                      }
                       
                    })
                  : null}
              </div>
             {status !== 3 ? (<div className="d-flex align-items-center removelabel chatBottom border px-2">
                <div>
                  <InputField
                    type="text"
                    className="flex-wrap"
                    placeholder="Enter Messege Here"
                    //setInputText={(value) => setmsgval(value)}
                    setInputText={(value) => {
                      if (startsWithSpace(value)) {
                        dispatch(showErrorSnackbar("space not allowed"));
                        return;
                      } else {
                        setmsgval(value);
                      }
                    }}
                    value={msg}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <span className="ms-2 d-flex align-items-center">
                  <span className="mx-2 position-relative">
                    <AttachFileIcon className="cursor" onClick={() => setopen(!open)} />
                    {open ? (
                      <span className="attachmentBox p-3 position-absolute rounded d-flex justify-content-between">
                        <span className="me-3 text-center">
                          <p className="mediaicon">
                            <span className="mx-2 cursor">
                              <label htmlFor="upload-button" className="cursor">
                                <ImageIcon />
                              </label>
                              <input
                                type="file"
                                id="upload-button"
                                style={{ display: 'none' }}
                                onChange={(e) => handleChange(e, 1)}
                                accept="image/png, image/gif, image/jpeg"
                              />
                            </span>
                          </p>
                          Image
                        </span>
                        <span className="text-center">
                          <p className="mediaicon">
                            <span className="mx-2 cursor">
                              <label htmlFor="upload-button1" className="cursor">
                                <DescriptionIcon />
                              </label>
                              <input
                                type="file"
                                id="upload-button1"
                                style={{ display: 'none' }}
                                onChange={(e) => handleChange(e, 2)}
                                accept=".pdf"
                              />
                            </span>
                          </p>
                          PDF
                        </span>
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                  <div>
                  <button
                    type="submit"
                    //disabled={!msg}
                    className="btn btn-primary submitbtn rounded-pill"
                    style={{maxHeight:"40px" , minWidth:"87px"}}
                    onClick={handleNewUserMessage}>
                    Send
                  </button></div>
                </span>
              </div>): 
              <div className="d-flex align-items-center justify-content-end removelabel chatBottom border px-2">
                <button
                    type="button"
                    //disabled={!msg}
                    className="btn btn-primary submitbtn chatsendbutton rounded-pill"
                     onClick={handleReopen}
                    >
                    Reopen
                  </button>
                </div>}
            </div>
          </div>
        </div>
        <div className="col-sm-5"></div>
      </div>
      <ChatImagePopup open={openImage} handleClose={handleClose} imageFull={imageFull}/>
    </div>
  );
}

export default TechnicalSupportChat;
