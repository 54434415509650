import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const SEODetails = ({ title, description, canonical, name, type }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <link rel='canonical' href={canonical} />
            {/*<meta name='description' content={description} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} /> */}
        </Helmet>
    );
}
export default SEODetails;
