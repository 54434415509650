import React, { useState } from 'react';

import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Lottie from 'react-lottie';
import orderPending from '../Assets/Images/JSON/orderPending.json';

function OrderPendingPopup(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orderPending,
    renderer: 'svg'
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="modeldiv p-5 text-center">
          <Lottie options={defaultOptions} height={130} width={200}></Lottie>
          <p className="text-center colorblue font12">
            <b>Hold On!</b>
          </p>
          <p className="text-center font11 ">We are verifying your payment status.</p>
        </div>
      </Fade>
    </Modal>
  );
}

export default OrderPendingPopup;
