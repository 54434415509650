import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import CustomDropdown from '../Component/customDropdown';
import { getBatch } from '../Redux/Actions/AuthAction';
import { getAddList, updateHomeMoreValues } from '../Redux/Actions/homeAction';
import { LocalImages } from '../Constant';
import endpoint from '../Constant/endpoint';
import axios from 'axios';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { useNavigate, useLocation } from 'react-router-dom';

function AddTechnicalSupport() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [batch, setbatch] = useState(0);
  const [msgtype, setmsgtype] = useState(0);
  const [Title, setTitle] = useState('');
  const [Msg, setMsg] = useState('');
  const [uploadimg, setuploadimg] = useState();
  const type = location.state?.techtype;
  const { authToken } = useSelector((state) => ({
    authToken: state.AuthReducer.ACCESS_TOKEN_NEW
  }));
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getBatch());
  }, []);

  const { examTypeId, baseCourseId, childrenId, paidTiles } = useSelector((state) => ({
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    paidTiles: state?.HomeReducer?.paidTiles,
    childrenId: state?.AuthReducer?.childrenId
  }));

  const supportTopic = [
    {
      _id: 1,
      name: 'Video Issue'
    },
    {
      _id: 2,
      name: 'DPP Issue'
    },
    {
      _id: 3,
      name: 'Other Issue'
    }
  ];

  const handleChange = (e) => {
    const fileSizeKiloBytes = e.target.files[0].size / 1024;
    if (fileSizeKiloBytes > 15360) {
      dispatch(showErrorSnackbar('Image size should be less than 15 MB!!!'));
    } else if (e.target.files.length) {
      if (e.target.files.length) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0]
        });
      }
      functionTwo(e.target.files[0]);
    }
  };

  const functionTwo = async (val) => {
    const res1 = await fileUpload(val);
    dispatch(updateHomeMoreValues({ isLoader: false }));

    if (res1.data.data) {
      dispatch(showSuccessSnackbar(res1.data.msg));
      const data = res1.data.data;
      Object.assign(data, { type: 1 });
      setuploadimg(data);
    } else {
      dispatch(showErrorSnackbar(res1.data.msg));
    }
  };

  const fileUpload = (file) => {
    // dispatch(showSuccessSnackbar('File Uploading ...'));
    dispatch(updateHomeMoreValues({ isLoader: true }));

    const url = process.env.REACT_APP_BASEURL + endpoint.home.uploadSupportMedia + '1/';

    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'content-type': 'multipart/form-data'
      }
    };
    return axios.post(url, formData, config);
  };
  const handleSubmit = (e) => {
    const postdata = {
      baseCourseId: baseCourseId,
      examTypeId: examTypeId,
      subExamTypeId: childrenId,
      supportType: 1,
      technicalSupportType: type,
      description: Msg?.target?.value,
      name: Title?.target?.value,
      media: uploadimg
    };
    if (childrenId === '') delete postdata['subExamTypeId'];

    if (type === 2) {
      postdata['courseId'] = batch?.courseId;
      postdata.examTypeId = batch?.examTypeId?._id;
      if (batch?.subExamTypeId) {
        postdata.subExamTypeId = batch?.subExamTypeId?._id;
      }
      postdata['technicalCourseSupportType'] = parseInt(msgtype?._id);
    }
    if (type === 1) {
      postdata['courseId'] = batch?.courseId;
      postdata.examTypeId = batch?.examTypeId?._id;
      if (batch?.subExamTypeId) {
        postdata.subExamTypeId = batch?.subExamTypeId?._id;
      }
    }
    dispatch(
      getAddList(
        postdata,
        (res) => {
          navigate('../SupportChat', { state: { id: res._id, screeName: 'AddTechnicalSupport' } });
        },
        () => {}
      )
    );
  };

  return (
    <div className="rightPanel">
      <h3>Add Technical support</h3>
      <div className="row mt-4">
        <div className="col-sm-7 bgwhite">
          <div className="boxshadow rounded p-4">
            <div className="pb-2">
              <CustomDropdown
                lableName="Select Batch"
                options={paidTiles}
                Isrequired={true}
                setInputText={(value) => setbatch(value)}
              />
            </div>
            {type === 2 ? (
              <div>
                <CustomDropdown
                  setInputText={(value) => setmsgtype(value)}
                  value={batch}
                  options={supportTopic}
                  Isrequired={true}
                  lableName="Select Support message type"
                />
              </div>
            ) : null}

            <div className="mb-2 mt-2 d-flex">
              <input
                type="text"
                className="form-control border-1 border-bottom"
                placeholder="Title"
                maxLength={50}
                onChange={(value) => setTitle(value)}
              />
              <span className="required ms-2 fs-6">*</span>
            </div>
            <div className="d-flex">
              <textarea
                className="form-control"
                aria-label="With textarea"
                placeholder="Enter your message"
                onChange={(value) => setMsg(value)}></textarea>
              <span className="required ms-2 fs-6">*</span>
            </div>
            <div className="mt-3">
              <label htmlFor="upload-button">
                {image.preview ? (
                  <img src={image.preview} alt="dummy" width="100" />
                ) : (
                  <div className="d-flex align-items-center cursor">
                    <img src={LocalImages.ulpoad} alt="dummy" width="50" height="50" />
                    <p className="text-center mb-0 ms-5">Upload photo</p>
                  </div>
                )}
              </label>
              <input
                type="file"
                accept="image/png, image/jpeg"
                id="upload-button"
                style={{ display: 'none' }}
                onChange={handleChange}
              />
              <br />
            </div>
            <div className="mt-4 w-25 m-auto">
              <button
                type="submit"
                // className="btn btn-primary mb-3 submitbtn"
                className={
                  !paidTiles || !batch || !Title || Title?.target?.value.length < 3 || !Msg
                    ? 'btn btn-secondary mb-3 submitbtn'
                    : 'btn btn-primary mb-3 submitbtn'
                }
                disabled={!paidTiles || !batch || !Title || Title?.target?.value.length < 3 || !Msg}
                onClick={handleSubmit}>
                Send{' '}
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-5"></div>
      </div>
    </div>
  );
}

export default AddTechnicalSupport;
