import React from 'react';
import CopyLink from '../Assets/Images/refer&earn/copyLink.svg';
const InviteLink = (props) => {
    return (<>
        <div class="mobile-invite mb-3">
            <form>
                <input type="text" className='form-control' value={props.placeholder} />
                <button onClick={props?.onClick} type="button" className="btn linkBtn"><img src={CopyLink} style={{height:20, width:20}} className='ms-1' alt="copy link" /></button>
            </form>
        </div>
    </>);
}

export default InviteLink;
