import React from 'react';
import Lottie from 'react-lottie';
import norecord from '../Assets/Images/JSON/no_record_found.json';

function NoIssueFound(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: norecord,
    renderer: 'svg'
  };
  return (
    <div className="col justify-content-center bgwhite boxshadow py-5 ">
      <Lottie options={defaultOptions} height={130} width={200}></Lottie>
      <p className="text-center">
        <b>No Issue Found</b>
      </p>
    </div>
  );
}

export default NoIssueFound;
