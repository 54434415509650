import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import '../App.css';
import Header from '../Module/header';
import CouponCard from '../Module/couponCard';
import { LocalImages } from '../Constant';
import Books from '../Module/books';
import IndividualBooksCard from '../Module/individualBooksCard';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  updateHomeValues,
  getCourseDetail,
  removeToCart,
  applyCoupon,
  getfreeCourseDetail
} from '../Redux/Actions/homeAction';
import YouTubeIframe from 'react-youtube-iframe';
import { updateSubscriptionValues } from '../Redux/Actions/subscriptionPlanAction';
import { updateCourseDetailValues } from '../Redux/Actions/courseDetailAction';
import HeaderHome from '../Module/headerHome';
import LoginErrorPopup from '../Module/loginErrorPopup';
import SEODetails from '../Module/metaTitle';
function StudyMaterialDetails(props) {
  const [key, setKey] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseID } = useParams();
  const baseCoursename = location?.state?.baseCoursename;
  const baseCourseId = location?.state?.baseCourseId;
  const examName = location?.state?.examName;
  const [couponCodeState, setCouponCode] = useState('');
  const [check, setCheck] = useState(false);
  const [smPrice, setSmPrice] = useState(0);
  const [vidID, setvidID] = useState(0);
  const [courseIncludes, setCourseIncludes] = useState([]);
  const [seoData, setSeoData] = useState({});
  const {
    subCategories,
    language,
    _id,
    name,
    sectionId,
    mainCategoryId,
    sectionType,
    startDate,
    bannerImage,
    video,
    pdf,
    duration,
    packageSection,
    sessions,
    bookSectionId,
    fromWhere,
    couponCode,
    OriginalPrice,
    ACCESS_TOKEN_NEW
  } = useSelector((state) => ({
    subCategories: state?.CourseDetailreducer?.subCategories,
    language: state?.CourseDetailreducer?.language,
    _id: state?.CourseDetailreducer?._id,
    name: state?.CourseDetailreducer?.name,
    sectionId: state?.CourseDetailreducer?.sectionId,
    mainCategoryId: state?.CourseDetailreducer?.mainCategoryId,
    sectionType: state?.CourseDetailreducer?.sectionType,
    startDate: state?.CourseDetailreducer?.startDate,
    bannerImage: state?.CourseDetailreducer?.bannerImage,
    video: state?.CourseDetailreducer?.video,
    pdf: state?.CourseDetailreducer?.pdf,
    duration: state?.CourseDetailreducer?.duration,
    packageSection: state?.CourseDetailreducer?.packageSection,
    sessions: state?.CourseDetailreducer?.sessions,
    courseDetail: state?.CourseDetailreducer?.courseDetail,
    bookSectionId: state?.CourseDetailreducer?.bookSectionId,
    fromWhere: state?.CourseDetailreducer?.fromWhere,
    couponCode: state?.HomeReducer?.couponCode,
    OriginalPrice: state?.HomeReducer?.OriginalPrice,
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));
  const { price } = useSelector((state) => ({
    price: state?.subscriptionPlanReducer?.price
  }));

  const { CouponData, cartListDetails } = useSelector((state) => ({
    CouponData: state?.HomeReducer?.CouponData,
    cartListDetails: state?.HomeReducer?.cartListDetails
  }));
  useEffect(() => {
    document.title = props.title;
    const obj = cartListDetails?.filter((object) => object?.courseId === courseID);
    var tempPrice = 0;
    for (let i = 0; i < obj?.length; i++) {
      if (obj[i]?.discountedPrice) tempPrice = tempPrice + obj[i]?.discountedPrice;
      else tempPrice = tempPrice + obj[i]?.price;
    }
    setSmPrice(tempPrice);
    dispatch(updateSubscriptionValues({ price: tempPrice }));

    dispatch(
      updateHomeValues({
        subscriptionID: '',
        selectedPackages: [],
        CouponData: [],
        OriginalPrice: tempPrice,
        // couponCode: '',
        showRemoveCoupon: false
      })
    );

    if (ACCESS_TOKEN_NEW) {
      dispatch(
        getCourseDetail(`/${courseID}`, async (dataCourse) => {
          if (dataCourse?.couponName) setCouponCode(dataCourse.couponName);
          const index = dataCourse.packageSection.findIndex((item) => item?.hasSelected);
          dispatch(
            updateCourseDetailValues({
              subCategories: dataCourse?.subCategories,
              language: dataCourse?.language,
              _id: dataCourse?._id,
              name: dataCourse?.name,
              sectionId: dataCourse?.sectionId,
              mainCategoryId: dataCourse?.mainCategoryId,
              sectionType: dataCourse?.sectionType,
              startDate: dataCourse?.startDate,
              bannerImage: dataCourse?.bannerImage,
              video: dataCourse?.video,
              pdf: dataCourse?.pdf,
              duration: dataCourse?.duration
            })
          );

          for (let i = 0; i < dataCourse?.packageSection?.length; i++) {
            for (let j = 0; j < dataCourse?.packageSection[i]?.books?.length; j++) {
              dataCourse.packageSection[i].books[j]['isSelected'] = false;
            }
          }

          dispatch(
            updateCourseDetailValues({
              packageSection: dataCourse.packageSection,
              sessions: dataCourse.sessions,
              courseDetail: dataCourse
            })
          );

          if (index > -1) {
            onSelectPackageSection(index);
            setCourseIncludes(dataCourse?.packageSection[index]?.subCategoriesIncluded);
          } else {
            setCourseIncludes(dataCourse?.packageSection[0]?.subCategoriesIncluded);
            onSelectPackageSection(0);
          }

          dispatch(updateHomeValues({ selectedCourse: [dataCourse.packageSection[0]] }));

          var count = 0;
          let newPrice = 0;
          let newSelectedPackage = [];
          for (let i = 0; i < dataCourse?.packageSection?.length; i++) {
            for (let j = 0; j < dataCourse?.packageSection[i]?.books?.length; j++) {
              if (dataCourse?.packageSection[i]?.books[j]?.hasSelected) {
                dataCourse.packageSection[i].books[j]['isSelected'] = true;
                newPrice = newPrice + dataCourse?.packageSection[i]?.books[j]?.price;
                const packages = {
                  sectionType: dataCourse?.sectionType,
                  courseId: dataCourse?._id,
                  packageSectionId: dataCourse?.packageSection[i]?._id,
                  bookSectionId: dataCourse?.packageSection[i]?.books[j]?._id
                };
                newSelectedPackage.push(packages);

                count = 1;
              }
            }
          }
          dispatch(
            updateSubscriptionValues({
              price: newPrice
            })
          );
          dispatch(
            updateHomeValues({
              selectedPackages: newSelectedPackage,
              OriginalPrice: newPrice
            })
          );
          if (count === 0) {
            dispatch(updateSubscriptionValues({ price: 0 }));

            dispatch(
              updateHomeValues({
                selectedPackages: [],
                CouponData: [],
                OriginalPrice: 0
              })
            );
          }
        })
      );
    } else {
      dispatch(
        getfreeCourseDetail(`/${courseID}`, async (dataCourse) => {
          setSeoData(dataCourse?.courseTypeSeo ? dataCourse?.courseTypeSeo : {});
          if (dataCourse?.couponName) setCouponCode(dataCourse.couponName);
          const index = dataCourse.packageSection.findIndex((item) => item?.hasSelected);
          dispatch(
            updateCourseDetailValues({
              subCategories: dataCourse?.subCategories,
              language: dataCourse?.language,
              _id: dataCourse?._id,
              name: dataCourse?.name,
              sectionId: dataCourse?.sectionId,
              mainCategoryId: dataCourse?.mainCategoryId,
              sectionType: dataCourse?.sectionType,
              startDate: dataCourse?.startDate,
              bannerImage: dataCourse?.bannerImage,
              video: dataCourse?.video,
              pdf: dataCourse?.pdf,
              duration: dataCourse?.duration
            })
          );

          for (let i = 0; i < dataCourse?.packageSection?.length; i++) {
            for (let j = 0; j < dataCourse?.packageSection[i]?.books?.length; j++) {
              dataCourse.packageSection[i].books[j]['isSelected'] = false;
            }
          }

          dispatch(
            updateCourseDetailValues({
              packageSection: dataCourse.packageSection,
              sessions: dataCourse.sessions,
              courseDetail: dataCourse
            })
          );

          if (index > -1) {
            onSelectPackageSection(index);
            setCourseIncludes(dataCourse?.packageSection[index]?.subCategoriesIncluded);
          } else {
            setCourseIncludes(dataCourse?.packageSection[0]?.subCategoriesIncluded);
            onSelectPackageSection(0);
          }

          dispatch(updateHomeValues({ selectedCourse: [dataCourse.packageSection[0]] }));

          var count = 0;
          let newPrice = 0;
          let newSelectedPackage = [];
          for (let i = 0; i < dataCourse?.packageSection?.length; i++) {
            for (let j = 0; j < dataCourse?.packageSection[i]?.books?.length; j++) {
              if (dataCourse?.packageSection[i]?.books[j]?.hasSelected) {
                dataCourse.packageSection[i].books[j]['isSelected'] = true;
                newPrice = newPrice + dataCourse?.packageSection[i]?.books[j]?.price;
                const packages = {
                  sectionType: dataCourse?.sectionType,
                  courseId: dataCourse?._id,
                  packageSectionId: dataCourse?.packageSection[i]?._id,
                  bookSectionId: dataCourse?.packageSection[i]?.books[j]?._id
                };
                newSelectedPackage.push(packages);

                count = 1;
              }
            }
          }
          dispatch(
            updateSubscriptionValues({
              price: newPrice
            })
          );
          dispatch(
            updateHomeValues({
              selectedPackages: newSelectedPackage,
              OriginalPrice: newPrice
            })
          );
          if (count === 0) {
            dispatch(updateSubscriptionValues({ price: 0 }));

            dispatch(
              updateHomeValues({
                selectedPackages: [],
                CouponData: [],
                OriginalPrice: 0
              })
            );
          }
        })
      );
    }
  }, [courseID]);

  let itemCount = 0;
  for (let i = 0; i < packageSection?.length; i++) {
    for (let j = 0; j < packageSection[i]?.books?.length; j++) {
      if (packageSection[i]?.books[j].isSelected) {
        itemCount++;
      }
    }
  }

  const handleChange = (e, bookSellType, book, packageID) => {
    if (!e.target.checked) {
      let removeObj = cartListDetails?.filter((obj) => obj?.bookSectionId === book?._id);
      if (removeObj[0]) dispatch(removeToCart({ cartId: removeObj[0]?._id }));
    }
    let index = packageSection?.findIndex((obj) => obj?._id == packageID);
    let bookIndex = packageSection[index]?.books?.findIndex((boo) => boo?._id == book?._id);
    dispatch(
      updateHomeValues({
        discountAmount: 0,
        CouponData: [],
        couponCode: '',
        showRemoveCoupon: false
      })
    );

    let data = [...packageSection];

    data[index].books[bookIndex].isSelected = e.target.checked;

    const temp = e?.target?.value;

    dispatch(
      updateCourseDetailValues({
        packageSection: data,
        bookSectionId: temp
      })
    );

    if (e.target.checked) {
      const packages = {
        sectionType,
        courseId: courseID,
        packageSectionId: packageSection[key]?._id,
        bookSectionId: e?.target?.value
      };
      if (bookSellType === 3) {
        dispatch(updateHomeValues({ selectedPackages: [packages] }));
      } else {
        selectedPackages.push(packages);
      }
    }

    if (book?.isSelected === false) {
      const removed = selectedPackages?.filter((item) => item?.bookSectionId !== e?.target?.value);
      const lengthh = selectedPackages?.length;
      for (let i = 0; i < lengthh; i++) {
        selectedPackages.pop();
      }
      for (let i = 0; i < removed?.length; i++) {
        selectedPackages.push(removed[i]);
      }
      dispatch(
        updateHomeValues({
          selectedPackages
        })
      );
    }
    if (bookSellType === 3) {
      let NewPrice = 0;
      for (let i = 0; i < packageSection.length; i++) {
        for (let j = 0; j < packageSection[i].books.length; j++) {
          if (
            packageSection[i].packagebooksSellType === 1 ||
            packageSection[i].packagebooksSellType === 2
          ) {
            packageSection[i].books[j]['isSelected'] = false;
          }
          if (packageSection[i].packagebooksSellType === 3) {
            if (packageSection[i].books[j].isSelected)
              NewPrice = NewPrice + parseInt(packageSection[i].books[j].sellPrice);
          }
        }
      }
      dispatch(
        updateSubscriptionValues({
          price: NewPrice
        })
      );
      dispatch(
        updateHomeValues({
          OriginalPrice: NewPrice
        })
      );
    } else {
      let NewPrice = 0;
      for (let i = 0; i < packageSection.length; i++) {
        for (let j = 0; j < packageSection[i].books.length; j++) {
          if (
            packageSection[i].packagebooksSellType === 1 ||
            packageSection[i].packagebooksSellType === 2
          ) {
            if (packageSection[i].books[j].isSelected)
              NewPrice =
                NewPrice +
                parseInt(
                  packageSection[i].books[j].sellPrice
                    ? packageSection[i].books[j].sellPrice
                    : packageSection[i].books[j].price
                );
          }
          if (packageSection[i].packagebooksSellType === 3)
            packageSection[i].books[j]['isSelected'] = false;
        }
      }
      dispatch(
        updateSubscriptionValues({
          price: NewPrice
        })
      );
      dispatch(
        updateHomeValues({
          OriginalPrice: NewPrice
        })
      );
    }
  };

  const onSelectPackageSection = (e) => {
    setKey(e);
    if (packageSection.length > 0) setCourseIncludes(packageSection[e]?.subCategoriesIncluded);
  };

  const { selectedPackages } = useSelector((state) => ({
    selectedPackages: state?.HomeReducer?.selectedPackages
  }));

  const setOnClick = () => {
    if (ACCESS_TOKEN_NEW) {
      if (selectedPackages.length > 0) {
        if (packageSection[key].packagebooksSellType === 3) {
          const postData = {
            sectionType,
            courseId: courseID,
            packageSectionId: packageSection[key]?._id,
            bookSectionId
          };

          if (CouponData[0]?.isCouponApply) {
            postData['couponId'] = CouponData[0]?.couponId;
          }

          dispatch(
            addToCart(
              {
                package: [postData]
              },
              () => {
                navigate('/MyCart');
              },
              (error) => {
                setErrormsg(error);
              }
            )
          );
        } else if (
          packageSection[key]?.packagebooksSellType === 1 ||
          packageSection[key]?.packagebooksSellType === 2
        ) {
          for (let i = 0; i < CouponData?.length; i++) {
            for (let j = 0; j < selectedPackages?.length; j++) {
              if (CouponData[i]?.bookSectionId === selectedPackages[j]?.bookSectionId) {
                selectedPackages[j]['couponId'] = CouponData[i]?.couponId;
              }
            }
          }
          dispatch(
            addToCart(
              {
                package: selectedPackages
              },
              () => {
                navigate('/MyCart');
              },
              () => {}
            )
          );
        }
      }
    } else {
      navigate('/SignIn', { state: { IsclassRoom: false } });
    }
  };

  const dataForApplyCoupon = {
    sectionType,
    courseId: courseID,
    packageSectionId: packageSection[key]?._id,
    bookSectionId
  };

  useEffect(() => {
    const youtubeParse = (url) => {
      var regExp =
        /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
      var match = url.match(regExp);
      return match && match[1].length == 11 ? match[1] : '';
    };
    if (video !== undefined) {
      const id = youtubeParse(video);

      setvidID(id);
    }
  }, []);
  const [wrongParent, setwrongParent] = useState(false);
  const setErrormsg = (val) => {
    setwrongParent(val.popup);
  };
  return (
    <div className="">
      {ACCESS_TOKEN_NEW ? <Header /> : <HeaderHome />}
      <SEODetails title={seoData?.metaTitle} description={seoData?.metaDescrition} />
      <div className="backimg">
        <div className="customcontainer">
          <h3 className="text-uppercase mt-3 mb-3 text-center fw-bold">{name}</h3>
          <div className="row">
            <div className="col-md-6 courseLeftside">
              <div className="youtubeframe my-3">
                <YouTubeIframe videoId={vidID} />
              </div>
              <div className="boxshadow rounded py-3 px-4 bgwhite mb-2">
                {/* <div className="d-flex">
                  <p className="fw-bold">Batch Start :</p>
                  <p className="colorblue ms-1">{moment(startDate).format('Do MMM YYYY')}</p>
                </div> */}
                <div className="d-flex justify-content-between">
                  <h4 className="fw-bold">Course Includes</h4>
                  <a
                    className="btn btn-outline-primary rounded font12 d-flex align-items-center"
                    href={pdf}
                    target="_blank"
                    rel="noreferrer">
                    Course Details{' '}
                    <img
                      src={LocalImages.pdff}
                      alt="pdf"
                      style={{ width: '13px' }}
                      className="ms-2"
                    />
                  </a>
                </div>
                <div className="d-flex justify-content-evenly flex-fill">
                  <div className="col-sm-6 font12 flex-fill">
                    <p className="successcolor fw-bold mb-2">
                      <span className="me-1">
                        <img src={LocalImages?.include} alt="include" style={{ width: '15px' }} />
                      </span>{' '}
                      Included
                    </p>

                    {courseIncludes?.map((c) => (
                      <p className="ms-3 mb-1">
                        <span className="me-2">
                          <img src={c?.icon} alt="Live" style={{ width: '12px' }} />
                        </span>
                        {c?.name}
                      </p>
                    ))}
                  </div>
                  {/* <div className="col-sm-6 font12 flex-fill">
                    <p className="errorcolor fw-bold mb-2">
                      <span className="me-1">
                        <img src={LocalImages?.exclude} alt="Exclude" style={{ width: '15px' }} />
                      </span>{' '}
                      Excluded
                    </p>

                    {subCategories?.excluded?.map((c) => (
                      <p className="ms-3 mb-1">
                        <span className="me-2">
                          <img src={c?.icon} alt="Live" style={{ width: '12px' }} />
                        </span>
                        {c?.name}
                      </p>
                    ))}
                  </div> */}
                </div>
                {/* <div className="d-flex justify-content-end">
                  <a
                    className="btn btn-outline-primary rounded font12"
                    href={pdf}
                    target="_blank"
                    rel="noreferrer">
                    Course Details{' '}
                    <img
                      src={LocalImages?.pdff}
                      alt="pdf"
                      style={{ width: '13px' }}
                      className="ms-2"
                    />
                  </a>
                </div> */}
              </div>
            </div>

            <div className="col-md-6">
              <Tabs
                id="tinyTab"
                className="coursedetails rounded font12 mb-3"
                activeKey={key}
                onSelect={(e) => {
                  onSelectPackageSection(e);
                }}
                fill
                variant="pills">
                {packageSection?.map((obj, index) =>
                  obj?.packagebooksSellType === 1 ? (
                    <Tab eventKey={index} title={obj?.name}>
                      {obj?.isOffer ? (
                        <div className="px-md-2 offerBox">{obj?.offer}</div>
                      ) : (
                        <div className="pt-4 pb-3"></div>
                      )}
                      <div className="pb-md-4 px-1 pricecardBox bgwhite">
                        <div className="d-flex flex-wrap ">
                          {obj?.books?.map((c) => (
                            <IndividualBooksCard
                              bookdata={c}
                              languageIcon={language?.icon}
                              Key={key}
                              cId={courseID}
                              setCheck={setCheck}
                              packageID={obj?._id}
                            />
                          ))}
                        </div>
                      </div>

                      <CouponCard
                        disabled={!(selectedPackages.length > 0)}
                        btnname={ACCESS_TOKEN_NEW ? 'Buy now' : 'Login'}
                        couponCode={couponCodeState}
                        Price={price}
                        setOnClick={setOnClick}
                        dataForApplyCoupon={dataForApplyCoupon}
                        hideInput={true}
                        itemCount={itemCount}
                      />
                    </Tab>
                  ) : obj.packagebooksSellType !== 1 ? (
                    <Tab eventKey={index} title={obj.name}>
                      {obj?.isOffer ? (
                        <div className="px-md-2 offerBox">{obj?.offer}</div>
                      ) : (
                        <div className="pt-4 pb-3"></div>
                      )}
                      <div className="pb-md-4 px-4 pricecardBox font12">
                        {obj?.books?.map((book) => (
                          <div className="bgwhite border rounded border-2 border-dark p-2 mb-3">
                            <div className="d-flex colorblue justify-content-between fw-bold mb-2">
                              <sapn>{book?.name}</sapn>
                              {/* <sapn>{book?.language.abbreviation}</sapn>
                              <a href={book?.video} target="_blank" style={{ color: '#fff' }} rel="noreferrer">
                              <img className="ms-2" style={{ width: '15px' }} src={videoicon} alt="Video Icon" />
                              </a> */}
                              <img style={{ height: '20px' }} src={language?.icon} alt="Book" />
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                {' '}
                                <input
                                  type="checkbox"
                                  className="cursor"
                                  checked={book?.isSelected}
                                  value={book?._id}
                                  onChange={(e) => {
                                    handleChange(e, obj?.packagebooksSellType, book, obj?._id);
                                  }}
                                />{' '}
                                <span className="colorblue ms-2">&#8377; {book?.sellPrice}</span>
                              </div>
                              <div>
                                <b>No Of Books:</b>{' '}
                                <span className="colorgrey">{book?.booksList?.length} Books</span>
                              </div>
                            </div>
                            <div className="bookContainer d-flex mt-2 flex-wrap">
                              {book?.booksList?.map((c) => (
                                <Books data={c} />
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>

                      <CouponCard
                        disabled={!(selectedPackages.length > 0)}
                        btnname={ACCESS_TOKEN_NEW ? 'Buy now' : 'Login'}
                        couponCode={couponCodeState}
                        Price={price}
                        setOnClick={setOnClick}
                        dataForApplyCoupon={dataForApplyCoupon}
                        hideInput={true}
                        itemCount={itemCount}
                      />
                    </Tab>
                  ) : null
                )}
              </Tabs>
              {wrongParent ? (
                <LoginErrorPopup
                  wrongParent={setwrongParent}
                  setKey={key}
                  baseCoursesname={baseCoursename}
                  examName={examName}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudyMaterialDetails;
