import React, {useState} from 'react';
import '../Assets/CSS/signIn.css';
import { sendOTP, getCountry } from '../Redux/Actions/AuthAction';
import service from '../Constant/service';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { getstart } from '../Redux/Actions/homeAction';
function PhoneInput(props) {
  const navigate = useNavigate();
  const [style, setStyle] = useState(1);
  const [number, setNumber] = useState('');
  const [numberError, setNumberError] = useState('');
  const [loader, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [countrycodeData, setCountries] = useState(props.countryData);
  const [inputText, setInputText] = useState('');
  const [isActive, setIsActive] = useState(false);

  const country = () => {
    setShow(!show);
    setInputText('');
    setCountries(props.countryData);
  };
  const handleClose = () => {
    setShow(false);
    setInputText('');
    setCountries(props.countryData);
  };
  const onSearchTextChange = (value) => {
    const filteredCountries = countrycodeData.filter((item) => {
      const countryName = item.name.toLowerCase();
      const countryCodeNew = item.callingCode.toLowerCase();
      const queryString = value.toLowerCase();
      return countryName.indexOf(queryString) > -1 || countryCodeNew.indexOf(queryString) > -1;
    });

    setInputText(value);
    setCountries(filteredCountries);
  };

  function allnumeric(inputtxt) {
    var numbers = /^[0-9]+$/;
    if (inputtxt.match(numbers)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input numeric characters only'));
      return false;
    }
  }
  const checkDisable = () =>
    numberError !== '' ||
    number === '' ||
    props.countryCode._id === '' ||
    !allnumeric(number) ||
    number.length !== 10;
  const onPressSendOTP = () => {
    if (!checkDisable()) {
      setStyle(0.7);
      setLoading(true);
      const payload = {
        mobileNo: number,
        countryId: props.countryCode._id
      };
      dispatch(
        getstart(
          {
            isGetStarted: true,
            mobileNumber: number
            // countryCode: props.countryCode.callingCode
          },
          (res) => {
            dispatch(
              sendOTP(
                payload,
                (response) => {
                  setLoading(false);
                  if (response?.statusCode === service.status_code.success) {
                    dispatch(showSuccessSnackbar(response?.msg));

                    navigate('/OTPVerification', {
                      state: {
                        mobileNo: number,
                        countryCode: props.countryCode,
                        isVerified: true,
                        userId: ''
                      }
                    });
                  } else {
                    dispatch(showErrorSnackbar('wentWrong'));
                  }
                },
                (error) => {
                  setLoading(false);
                  if (error?.response?.data?.msg)
                    dispatch(showErrorSnackbar(error.response.data.msg));
                  else dispatch(showErrorSnackbar('Please Enter Valid Mobile Number'));
                }
              )
            );
          }
        )
      );
    } else {
      dispatch(showErrorSnackbar('Please fill mobile number'));
    }
  };

  const handleOnChangePhoneText = (prevPhoneInputText) => {
    var numbers = /^[0-9]+$/;
    if (!prevPhoneInputText.target.value) setNumber(prevPhoneInputText.target.value);
    else if (prevPhoneInputText.target.value.match(numbers))
      setNumber(prevPhoneInputText.target.value);
    else dispatch(showErrorSnackbar('Please input numeric characters only'));
  };
  return (
    <div className="position-relative">
      {/* <span className="indcode boldfont cursor me-1" onClick={country}>
        {props.countryCode.callingCode}{' '}
        {show ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />}
      </span> */}
      {/* {show ? (
        <div className="boxshadow position-absolute p-3 rounded font12 bgwhite countrypopup">
          <div className="d-flex justify-content-between">
            <p className="colorblue fw-bold">Select a Country</p>
            <ClearOutlinedIcon className="colorblue cursor" onClick={handleClose} />
          </div>
          <div className="position-relative d-flex W-100 inputpadding">
            <SearchIcon className="position-absolute text-secondary m-2" />
            <InputField value={inputText} setInputText={(value) => onSearchTextChange(value)} />
          </div>
          <div className="row font11">
            <div className="col-sm-8 countryflow ">
              {countrycodeData.map((c) => (
                <div
                  onClick={() => {
                    props.setCountryCode(c);
                    setShow(false);
                    setInputText('');
                    setCountries(props.countryData);
                  }}
                  className="row d-flex cursor">
                  <div className="col-3">
                    <img src={c.flag} className="m-1 img-fluid" />{' '}
                  </div>
                  <div
                    className={
                      props.countryCode.callingCode == c.callingCode ? 'colorblue col-3' : 'col-3'
                    }>
                    {' '}
                    {c.callingCode}
                  </div>
                  <div
                    className={
                      props.countryCode.callingCode == c.callingCode ? 'colorblue col-6' : 'col-6'
                    }>
                    {' '}
                    {c.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null} */}
      <span className="indcode fw600 cursor me-1">
        + 91
        {show ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />}
      </span>
      <input
        type="text"
        className="form-control phoneinput font14 border border-primary rounded-pill"
        placeholder="Enter Your Phone Number"
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
        id="phone"
        maxLength="10"
        minLength="10"
        value={number}
        onChange={(value) => handleOnChangePhoneText(value)}
      />
      <button
        className="btn btn-primary getstartbtn"
        type="submit"
        id="button-addon2"
        onClick={onPressSendOTP}
        style={{ zIndex: '9' }}>
        Get Started
      </button>
    </div>
  );
}

export default PhoneInput;
