import React, { useState, useEffect } from 'react';
import '../App.css';
import live from '../Assets/Images/Learn/livesession.json';
import Lottie from 'react-lottie';
import CustomDropdown from '../Component/customDropdown';
import { updateMoreValues } from '../Redux/Actions/AuthAction';
import { LocalImages } from '../Constant';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getBanner, startEndLiveStatus, updateHomeMoreValues } from '../Redux/Actions/homeAction';
import { getLiveVideo } from '../Redux/Actions/homeAction';
import moment from 'moment/moment';
import BbbVideo from './bbbVideo';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import BannerCard from '../Module/bannerCard';

function MyLearning() {
  const {
    baseCourseId,
    examTypeId,
    childrenId,
    paidTiles,
    selectedTiles,
    liveVideoData,
    userId,
    examTypeName,
    banner
  } = useSelector((state) => ({
    baseCourseId: state?.AuthReducer?.baseCourseId,
    examTypeId: state?.AuthReducer?.examTypeId,
    childrenId: state?.AuthReducer?.childrenId,
    examTypeName: state?.AuthReducer?.examTypeName,
    paidTiles: state?.HomeReducer?.paidTiles,
    selectedTiles: state?.HomeReducer?.selectedTiles,
    liveVideoData: state?.AuthReducer?.liveVideoData,
    isLoader: state?.AuthReducer?.isLoader,
    userId: state?.AuthReducer?._id,
    banner: state?.HomeReducer?.banner
  }));

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [openVideo, setopenVideo] = useState(false);
  const [resultBanner, setResultBanner] = useState([]);

  const courseId = selectedTiles?.courseId;
  const sessionId = selectedTiles?.sessionId;
  useEffect(() => {
    let reqData = {
      courseId,
      dateAndTime: moment(new Date()).format('YYYY-MM-DD')
    };
    if (sessionId)
      reqData = {
        courseId,
        dateAndTime: moment(new Date()).format('YYYY-MM-DD'),
        sessionId
      };
    dispatch(
      getLiveVideo(reqData, (data) => {
        dispatch(updateMoreValues({ liveVideoData: { ...data, liveType: 1 } }));
      })
    );
    // dispatch(getBanner(`/${baseCourseId}/${examTypeId}`));
    if (banner?.length > 0) {
      let temp = banner?.filter((item) => item?.bannerTypeId?.type.toUpperCase() === 'RESULTS');
      setResultBanner(temp);
    }
  }, [selectedTiles]);

  const onBannerClick = (item, index) => {
    if (item?.bannerTypeId?.type.toUpperCase() === 'RESULTS') {
      navigate('/PaidUser/Results');
    }
  };

  const LectureScheduleRoute = () => {
    if (
      !liveVideoData?.isRTMPEnvironment &&
      liveVideoData?.status === 2 
      &&
      liveVideoData?.classType === 1 || liveVideoData?.classType === undefined || !liveVideoData?.classType
    ) {
      handleBbbVideoClick();
    } else if (
      liveVideoData?.isRTMPEnvironment &&
      liveVideoData?.status === 2 &&
      liveVideoData?.classType === 1
    ) {
      dispatch(
        startEndLiveStatus({
          userId: userId,
          liveId: liveVideoData.liveId,
          liveType: 1,
          isLive: true
        })
      );
      window.open(
        '/MediaPlayer',
        '_blank',
        'width=' + screen.availWidth + ',height=' + screen.availHeight
      );
    } else if (liveVideoData?.status === 2 && liveVideoData?.classType === 2) {
      dispatch(
        startEndLiveStatus({
          userId: userId,
          liveId: liveVideoData.liveId,
          liveType: 2,
          isLive: true
        })
      );
      window.open(
        '/VideoPlayer',
        '_blank',
        'width=' + screen.availWidth + ',height=' + screen.availHeight
      );
    } else {
      if (
        moment.utc(liveVideoData?.sessionTime, 'hh:mm:ss').format('LT') <
        moment(new Date()).format('LT')
      ) {
        setOpen(true);
      } else
        navigate('/PaidUser/LectureSchedule', { state: { courseId: selectedTiles?.courseId } });
    }
  };

  const PaidCoursedropdown = (value) => {
    dispatch(
      updateHomeMoreValues({
        selectedTiles: value,
        PaidCourseID: value.courseId
      })
    );
  };
  const onlineTestRoute = () => {
    navigate('/PaidUser/OnlineTest');
  };
  const lectureRoute = () => {
    navigate('/PaidUser/MyLectures');
  };
  const allAboutRoute = () => {
    navigate('/PaidUser/AllAbout');
  };
  const analysisRoute = () => {
    navigate('/PaidUser/CourseAnalysis');
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: live,
    renderer: 'svg'
  };

  const handleBbbVideoClose = () => {
    setopenVideo(false);
  };

  const handleBbbVideoClick = () => {
    setopenVideo(true);
  };
console.log("liveVideoData", liveVideoData);
  return (
    <div className="rightPanel">
      <div className="d-flex">
        <h3 className="me-3 me-md-5">My Learning</h3>
        <div>
          <CustomDropdown
            setInputText={(value) => PaidCoursedropdown(value)}
            value={selectedTiles?.courseId}
            lableName={selectedTiles?.name}
            options={paidTiles}
            defaultValue={selectedTiles?._id}
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="row d-flex justify-content-between width75">
          <div
            className="col-sm-5 row boxshadow rounded p-2 p-md-4 mb-4 align-items-center cursor bgwhite"
            onClick={LectureScheduleRoute}>
            {liveVideoData?.status === 2 ? (
              <div className="col-9">
                <p className="fw-bold mb-2">Live Session</p>
                <p className="mb-0 colorblue font12">Join session now</p>
              </div>
            ) : liveVideoData?.status === 1 ? (
              <div className="col-9">
                <p className="fw-bold mb-2">Live Session</p>
                <p className="mb-0 colorblue font12">
                  {moment.utc(liveVideoData?.sessionTime, 'hh:mm:ss').format('LT') <
                  moment(new Date()).format('LT')
                    ? `Live session starting soon`
                    : `Live Start at ${
                        liveVideoData?.fromDate
                          ? liveVideoData?.fromDate
                          : liveVideoData?.sessionDate
                      } ${moment.utc(liveVideoData?.sessionTime, 'hh:mm:ss').format('LT')}`}
                </p>
              </div>
            ) : (
              <div className="col-9">
                <p className="fw-bold mb-2">Live Session</p>
                <p className="mb-0 colorblue font12">no live session available</p>
              </div>
            )}
            <div className="col-3">
              <Lottie options={defaultOptions} height={60} width={60} />
            </div>
          </div>
          <div
            className="col-sm-5 row boxshadow rounded p-2 p-md-4  mb-4 align-items-center cursor bgwhite"
            onClick={lectureRoute}>
            <div className="col-9">
              <p className="fw-bold mb-2">Lectures</p>
            </div>
            <div className="col-3">
              <img src={LocalImages.lecture} alt="Lecture" />
            </div>
          </div>
          <div
            className="col-sm-5 row boxshadow rounded p-2 p-md-4 mb-4 align-items-center cursor bgwhite"
            onClick={onlineTestRoute}>
            <div className="col-9">
              <p className="fw-bold mb-2">Online Test</p>
            </div>
            <div className="col-3">
              <img src={LocalImages.onlineTest} alt="onlineTest" />
            </div>
          </div>
          <div
            className="col-12 col-sm-5 row boxshadow rounded p-2 p-md-4  mb-4 align-items-center cursor bgwhite"
            onClick={analysisRoute}>
            <div className="col-9">
              <p className="fw-bold mb-2">Analysis</p>
            </div>
            <div className="col-3">
              <img src={LocalImages.allAbout} alt="allAbout" />
            </div>
          </div>
          <div
            className="col-12 col-sm-5 row boxshadow rounded p-2 p-md-4  mb-4 align-items-center cursor bgwhite"
            onClick={allAboutRoute}>
            <div className="col-9">
              <p className="fw-bold mb-2">All About {selectedTiles?.examTypeId?.name}</p>
            </div>
            <div className="col-3">
              <img src={LocalImages.allAbout} alt="allAbout" />
            </div>
          </div>
          {resultBanner?.map((item, index) => {
            return (
              <div className="boxshadow rounded bannerTilesBox bgwhite cursor px-0">
                <div className="d-flex justify-content-between">
                  <div className="bannerInnerDiv" onClick={() => onBannerClick(item, index)}>
                    <img
                      className="bannerImg"
                      src={item?.file}
                      //src={LocalImages?.dummyBaner}
                      alt="Course Logo"
                    />
                  </div>
                </div>
              </div>
            );
          })}
          {/* <BannerCard /> */}
        </div>
      </div>
      {/* {isLoader? <Backdrop sx={{ color: '#fff', zIndex: '10001' }} open>
          <span className="loader"></span>
        </Backdrop>:""} */}

      <BbbVideo open={openVideo} handleClose={handleBbbVideoClose} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="modeldiv p-md-4 py-md-4 talktoexpertModel">
            <div className="m-2 insttxtDiv bgwhite">
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon className="colorblue cursor" onClick={() => setOpen(false)} />
              </div>
              <div className="col justify-content-center  py-5 ">
                <Lottie options={defaultOptions} height={100} width={100}></Lottie>
                <p className="text-center">
                  <b>Please wait for a while , lecture will start soon</b>
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default MyLearning;
