import React, { useState } from 'react';
import '../Assets/CSS/home.css';
import logo from '../Assets/Images/Home/logo.png';
import { LocalImages } from '../Constant';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import pune from '../Assets/Images/Home/pune.png';
import jodhpur from '../Assets/Images/Home/jodhpur.png';
import california from '../Assets/Images/Home/california.png';
import AppleIcon from '../Assets/Images/Dashboard/AppleIcon.png';
import Playstore from '../Assets/Images/Dashboard/playstore.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
function Footer() {
  const { examListNew } = useSelector((state) => ({
    examListNew: state?.AuthReducer?.examListNew
  }));
  const [isHidden, setisHidden] = useState(true);
  const navigate = useNavigate();
  const PrivacyPolicy = () => {
    window.location.href = 'privacy-policy';
  };
  const RefundPolicy = () => {
    window.location.href = 'refund-policy';
  };
  const Terms = () => {
    window.location.href = 'terms-conditions';
  };

  const aboutUs = (e) => {
    window.location.href = 'about-us';
  };

  const ExamClick = (data) => {
    const { isStatewise, childrenId, _id, name, seoName } = data;
    if (isStatewise) navigate('/AboutExamState/' + seoName + '/' + _id + '/' + name);
    else if (childrenId?.length > 0) navigate('/AboutSubExam/' + seoName + '/' + _id + '/' + name);
    else navigate('/' + seoName);
  };
  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    if (window.innerWidth >= 768) {
      var y = window.scrollY;
      if (y >= 300) {
        setisHidden(false);
      } else {
        setisHidden(true);
      }
    } else {
      setisHidden(false);
    }
  };
  window.addEventListener('scroll', myScrollFunc);
  return (
    <>
      <footer className="footer common-pad  pb-2 ms-md-4 me-md-4 wow fadeIn" data-wow-duration="2s">
        <div className="row ">
          <div className="col-md-6 col-lg-4">
            <img src={logo} alt="IFAS Logo" className="footerlogo" />
            <p className="mt-4 downtxt1 ">
              Let's grow up your education level up, We are here to help you keep focus when
              studying.
            </p>
            <div className="d-flex justify-content-start ">
              <div>
                <a
                  href="https://twitter.com/ifasEdutech"
                  className="link-secondary"
                  target="_blank"
                  rel="noreferrer">
                  <span className="footericon cursor ">
                    <TwitterIcon />
                  </span>
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/ifas_online/?igshid=YmMyMTA2M2Y%3D"
                  className="link-secondary"
                  target="_blank"
                  rel="noreferrer">
                  <span className="footericon cursor">
                    <InstagramIcon />
                  </span>
                </a>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/ifasedutech"
                  className="link-secondary"
                  target="_blank"
                  rel="noreferrer">
                  <span className="footericon cursor ">
                    <FacebookRoundedIcon />
                  </span>
                </a>
              </div>
              <div>
                <a
                  href="https://www.youtube.com/channel/UCDCGIVRcNnicF-7k48xLAUw"
                  className="link-secondary"
                  target="_blank"
                  rel="noreferrer">
                  <span className="footericon cursor ">
                    <YouTubeIcon />
                  </span>
                </a>
              </div>
              <div>
                <a
                  href="https://t.me/ifas_edutech"
                  className="link-secondary"
                  target="_blank"
                  rel="noreferrer">
                  <span className="footericon cursor ">
                    <TelegramIcon />
                  </span>
                </a>
              </div>
            </div>
            <div className=" col-sm-3 border-bottom border-2 ms-1 mt-4"></div>
            <div className="mt-4 mb-5">
              <p className="fw600 downtxt">Classroom In Your Pocket</p>
              <p className="mb-0 downtxt1">Choose Your Courses & Grow Your</p>
              <p className="downtxt1">Career With All New IFAS App</p>
            </div>
            <div className="mt-3">
              <p className="fw600 downtxt" style={{ color: 'var(--blue)' }}>
                Download App Now
              </p>
              <div className="d-flex mb-3">
                <a
                  href="https://play.google.com/store/apps/details?id=com.ifasapp"
                  target="_blank"
                  className="d-flex store align-items-center rounded-3"
                  rel="noreferrer">
                  <div className="imgdiv">
                    <img src={Playstore} style={{ width: '25px' }} alt="Playstore" />
                  </div>
                  <div className="ms-3 me-2">
                    <span className="d-block font11 lh-sm">Get it On</span>
                    <span className="d-block storename font12 lh-1">Play Store</span>
                  </div>
                </a>
                <a
                  href="https://itunes.apple.com/us/app/ifas-online/id1448199555?ls=1&mt=8"
                  target="_blank"
                  className="d-flex store align-items-center rounded-3"
                  rel="noreferrer">
                  <div className="imgdiv">
                    <img src={AppleIcon} style={{ width: '25px' }} alt="Playstore" />
                  </div>
                  <div className="ms-3 me-2">
                    <span className="d-block font11 lh-sm">Get it On</span>
                    <span className="d-block storename font12 lh-1">App Store</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <p className="fw-bold boldfont">COURSES</p>
            <div className="d-flex downtxt1 homecourseList">
              <ul className="listnone flex-fill">
                {examListNew.map((c) => (
                  <li key={c._id} onClick={() => ExamClick(c)}>
                    {c.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-2 companydiv">
            <p className="fw-bold boldfont">COMPANY INFO</p>
            <p className="downtxt1 cursor" onClick={aboutUs}>
              About Us
            </p>
            <p className="downtxt1 cursor " onClick={Terms}>
              Terms & Condition
            </p>
            <p className="downtxt1 cursor " onClick={PrivacyPolicy}>
              Privacy Policy
            </p>
            <p className="downtxt1 cursor " onClick={RefundPolicy}>
              Refund Policy
            </p>
            <p className="downtxt1 cursor ">
              <a
                href="https://support.ifasonline.com/theme/App/IFAS_ONLINE_ANDROID.apk"
                target="_blank"
                rel="noreferrer">
                Download Android App
              </a>
            </p>
          </div>
          <div className="col-md-6 col-lg-3">
            <p className="fw-bold boldfont">HEADQUARTER</p>
            <div className="addressboxfooter">
              <div className="addresscontainer d-flex ">
                <div className="imgdiv">
                  <img src={pune} alt="Pune"></img>
                </div>
                <div className="">
                  <p className="fw-bold mb-1">Pune</p>
                  <span className="d-block downtxt1 mb-1 ">
                    ISH Infotech, Phase 1, Hinjewadi, Pune,
                  </span>
                  <span className="downtxt1 ">Maharashtra - 411057</span>
                </div>
              </div>
              <div className="addresscontainer d-flex  mb-2">
                <div className="imgdiv">
                  <img src={jodhpur} alt="jodhpur"></img>
                </div>
                <div>
                  <p className="fw-bold mb-1">Jodhpur</p>
                  <span className="d-block downtxt1 ">B-7,Saraswati Nagar</span>
                  <span className="downtxt1 ">Nr. St. Anne's School,</span>
                  <span className="downtxt1 "> Basni - 1</span>
                </div>
              </div>
              <div className="addresscontainer d-flex mb-1 ">
                <div className="imgdiv">
                  <img src={california} alt="california"></img>
                </div>
                <div>
                  <p className="fw-bold mb-1">California</p>
                  <span className="d-block downtxt1 ">1525 McCarthy Blvd, 1039,Milpitas,</span>
                  <span className="downtxt1 "> CA - 95035</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights ms-2 me-3 mt-4 border-top border-2 mb-4">
          <p className="mb-0">&copy; 2022 All rights reserved IFAS Edutech</p>
        </div>
      </footer>
      {/* {!isHidden ? (
        <div className="fixedFooter font24">
          <div className="FooterInner">
            <div className="col-md-12 mx-0">
              <div className="d-flex justify-content-between mb-2 mb-md-0">
                <div className="d-flex">
                  <img
                    src={LocalImages.logoCircle}
                    alt="IFAS Logo"
                    className="landinglogo me-1 me-md-3"
                  />
                  <p className="fw-bold mb-0 pt-3">
                    For any query or Career guidance kindly contact at{' '}
                    <span className="landingPhone boldfont cursor me-1"> +91-9172266888 </span>
                  </p>
                </div>
                <a href="tel:+91-9172266888" className="mt-2">
                  <button className="btn btn-primary btn-sm boxshadow px-5">Contact Us</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </>
  );
}

export default Footer;
