import React from 'react';
const MobileInvite = (props) => {
    return (<>
        <div className='label-holder my-3'>
            <label className="invite-label">Mobile Invites</label>
        </div>
        <form class="mb-3 mobile-invite">
            <div className="row">
                <div class="col-8">
                    <input maxLength={10} value={props?.value} onChange={props?.onChange} type="text" className="form-control" placeholder="Enter Mobile Number" />
                </div>
                <div className='col-4 text-center py-2'>
                    <button style={{backgroundColor: props?.isShow ? '#016FE9' : '#707070', borderWidth: 0}} disabled={!props?.isShow} className="btn inviteBtn" type="button" onClick={props.onBtnClick}>Invite </button>
                </div>
            </div>
        </form>

    </>);
}

export default MobileInvite;
