import React, { useEffect, useState } from 'react';
import deleteimg from '../Assets/Images/Learn/Delete.png';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { removeCoupon, getCartList, applyCouponCart } from '../Redux/Actions/homeAction';
import { validateCouponCodeName } from '../Utils/validators';
import { validateCouponCodeNameErrMsg } from '../Utils/errormsg';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import downarrow from '../Assets/Images/Dashboard/downarrow.png';
import BatchTimingCart from './batchTimingCart';
import { updateCourseDetailValues } from '../Redux/Actions/courseDetailAction';
import { getName } from '../Redux/Actions/AuthAction';

function CartCard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { couponId, baseCourseID, examTypeId, subExamTypeId, courseId, couponName, session } =
    props?.data;
  const [showRemoveCoupon, setShowRemoveCoupon] = useState(couponId ? true : false);
  const [showApplyCoupon, setShowApplyCoupon] = useState(couponId ? false : true);
  const [errorText, setErrorText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [couponCode, setCouponCode] = useState(couponName);
  const [batchName, setBatchName] = useState('');
  const [showApplyCode, setShowApplyCode] = useState(false);
  const [isCouponIdAvailable, setIsCouponIdAvailable] = useState(couponId ? true : false);
  const [show, setShow] = useState(false);

  let { CouponData, cartListDetails } = useSelector((state) => ({
    CouponData: state?.HomeReducer?.CouponData,
    cartListDetails: state?.HomeReducer?.cartListDetails
  }));

  useEffect(() => {
    setShowRemoveCoupon(couponId ? true : false);
    setIsCouponIdAvailable(couponId ? true : false);
    if (!errorText) {
      setShowApplyCoupon(couponId ? false : true);
    }

    setSuccessText('');
    if (couponName) setCouponCode(couponName);
    else setCouponCode('');
    setShowApplyCode(false);
    setShow(false);
    if (session) {
      let batchIndexNew = session.findIndex((item) => item._id === props?.data?.sessionId);
      setBatchName(session[batchIndexNew]?.name);
    } else {
      setBatchName('');
    }
  }, [cartListDetails]);

  const handleViewDetails = () => {
    dispatch(
      updateCourseDetailValues({
        packageSection: [],
        sessions: [],
        courseDetail: {}
      })
    );

    if (props?.data?.sectionType === 1 || props?.data?.sectionType === 3)
      navigate('/CourseDetails/' + props?.data.name + '/' + courseId, {
        state: { courseID: courseId, isextended: false }
      });
    else if (props?.data?.sectionType === 5)
      navigate('/LectureSeries/' + props?.data.name + '/' + courseId, {
        state: { courseID: courseId, isextended: false }
      });
    else if (props?.data?.sectionType === 2) {
      const data = {
        baseCourseId: baseCourseID ? baseCourseID : baseCourseId,
        examTypeId
      };
      if (subExamTypeId) data.subExamTypeId = subExamTypeId;
      dispatch(
        getName(
          data,
          (res) => {
            const { seoBaseCourseName, seoSubExamName, seoExamTypeName } = res;
            navigate(
              '/' +
                `${seoSubExamName ? seoSubExamName : seoExamTypeName}` +
                '/' +
                seoBaseCourseName +
                '-study-material' +
                '/' +
                courseId
            );
          },
          () => {}
        )
      );
    }
  };
  const handleCoupon = () => {
    setShowApplyCoupon(false);
  };

  const handleBatchTiming = () => {
    if (session) {
      if (!show) setShow(true);
      else setShow(false);
    }
  };

  const handleClick = (cartID) => {
    setShowApplyCode(false);
    setShowApplyCoupon(false);
    dispatch(
      applyCouponCart(
        {
          cartId: cartID,
          couponName: couponCode,
          isExetended: props.data.extendedOption
        },
        (res) => {
          if (res.length == 0) {
            setShowRemoveCoupon(false);
            setErrorText('is invalid ! Please use valid code');
          } else {
            setShowRemoveCoupon(true);
            setErrorText('');
            dispatch(getCartList(() => {}));
          }
        }
      )
    );
  };

  const onChangeCouponCode = (e) => {
    if (validateCouponCodeName(e.target.value)) {
      setCouponCode(e.target.value);
      setShowApplyCode(true);
      setErrorText('');
      setSuccessText('');
      if (e.target.value === '' || e.target.value === null) {
      }
    } else {
      dispatch(showErrorSnackbar(validateCouponCodeNameErrMsg));
    }
  };
  const onChangeRemoveCoupon = () => {
    dispatch(
      removeCoupon(
        {
          cartId: props.data._id
        },
        (res) => {
          dispatch(getCartList(() => {}, false));
          if (res.length == 0) {
            setShowRemoveCoupon(true);
            setErrorText('');
          } else {
            setShowApplyCoupon(true);
            setShowRemoveCoupon(false);
            setIsCouponIdAvailable(false);
            setErrorText('');
            setCouponCode('');
          }
        }
      )
    );
  };

  return (
    <div className="boxshadow p-3 rounded mb-3 bgwhite font12" key={props?.data?._id}>
      <div className="colorblue fw-bold mb-1">
        {props?.data?.bookName
          ? props?.data?.bookName
          : props?.data?.lectureName
          ? props?.data?.lectureName
          : props?.data?.name}
      </div>
      <div className="mb-1">
        <span>Language :</span>
        <span className="ms-2 colorgrey">{props?.data?.language?.name}</span>
      </div>

      <div className="mb-1">
        <span>Course Price :</span>
        <span className="ms-2">
          <span className="colorblue fw-bold">
            &#8377; {props?.data?.discPer ? props?.data?.discountedPrice : props?.data?.price}
          </span>
          {props?.data?.discPer ? (
            <span className="ms-2 colorgrey">
              <del>&#8377; {props?.data?.price}</del>
            </span>
          ) : (
            ''
          )}
        </span>
      </div>
      {batchName ? (
        <div className="mb-1">
          <span>Batch Time :</span>
          <span className="ms-2">
            {batchName}
            <img
              className="ms-2 cursor"
              src={downarrow}
              alt="Dropdown"
              style={{ width: '10px' }}
              onClick={handleBatchTiming}
            />
          </span>
        </div>
      ) : (
        ''
      )}
      <div className="d-flex mt-1 font12 mb-1">
        {showApplyCoupon ? (
          <p onClick={handleCoupon} className="successcolor cursor ">
            <NavLink>Add Coupon Code</NavLink>
          </p>
        ) : (
          <>
            {showRemoveCoupon || isCouponIdAvailable ? (
              <>
                <span className="successcolor">
                  {couponCode !== '' ? couponCode : CouponData[0]?.couponName}
                </span>
                <span className="errorcolor ms-3 " onClick={onChangeRemoveCoupon}>
                  <NavLink>Remove Copuon</NavLink>
                </span>
                <span className="successcolor ms-5 ">Applied Successfully!</span>
              </>
            ) : (
              <div className="p-3 pb-2 ps-0">
                <div className="d-flex">
                  <input
                    className="border-0 border-bottom border-dark"
                    type="text"
                    placeholder="Have a Coupon Code?"
                    value={couponCode}
                    onChange={(e) => onChangeCouponCode(e)}
                  />
                  {couponCode && errorText ? (
                    <>
                      <span className="errorcolor ms-3 " onClick={onChangeRemoveCoupon}>
                        <NavLink>Remove Copuon</NavLink>
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </div>

                {couponCode && showApplyCode ? (
                  <p
                    className="successcolor ms-0 "
                    onClick={() => {
                      handleClick(props?.data?._id);
                    }}>
                    <NavLink className="d-inline block">Apply Code</NavLink>
                  </p>
                ) : null}

                {couponCode && successText ? (
                  <span className="successcolor ms-5">{successText}!</span>
                ) : (
                  ''
                )}
                {couponCode && errorText ? (
                  <>
                    <span className="errorcolor ">
                      {couponCode} {errorText}!
                    </span>
                  </>
                ) : (
                  ''
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-secondary px-4 cursor"
          style={{ fontSize: '12px' }}
          onClick={handleViewDetails}>
          View Details
        </button>
        <div
          className="redtxt px-3 rounded d-flex justify-content-center align-items-center font12 cursor"
          onClick={() => {
            props?.setOnRemove(props?.data?._id);
          }}>
          <img src={deleteimg} alt="Delete" className="me-2 cursor" />
          <NavLink>Remove</NavLink>
        </div>
      </div>
      {show ? (
        <BatchTimingCart
          show={setShow}
          sessions={session}
          sessionId={props?.data?.sessionId}
          id={props?.data?._id}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default CartCard;
