import React, { useState, useEffect } from 'react';
import '../Assets/CSS/home.css';
import Footer from '../Component/footer';
import ClassroomInPocket from '../Component/classroomInPocket';
import { LocalImages } from '../Constant';
import Lottie from 'react-lottie';
import Expert from '../Assets/Images/JSON/expert.json';
import TalktoExpert from '../Module/talktoExpert';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getParentCourse, updateMoreValues } from '../Redux/Actions/AuthAction';
import data from '../Assets/DummyData/course.json';
import Header from '../Module/header';
import HeaderHome from '../Module/headerHome';
const AptitudeCourses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setopen] = useState(false);
  const [courseData, setcourseData] = useState('');
  const [courseexamData, setcourseexamData] = useState('');
  const { ACCESS_TOKEN_NEW } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Expert,
    renderer: 'svg'
  };

  useEffect(() => {
    (async () => {
      await dispatch(
        getParentCourse((data) => {
          dispatch(
            updateMoreValues({
              baseCourseId: data[0]?._id,
              baseCourseName: data[0]?.name
            })
          );
        })
      );
      // }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setcourseData('general_aptitude');
  }, []);

  let faq, toppers, name;
  useEffect(() => {
    setcourseexamData(data[courseData]);
    faq, toppers, (name = courseexamData);
  }, [courseData]);

  const handleClose = () => {
    setopen(false);
  };
  const handleClick = () => {
    setopen(true);
  };

  const course = [
    {
      heading: 'APTITUDE | PAPER 1',
      childCourse: [
        {
          name: 'CSIR NET APTITUDE',
          id: '6433bb7e6f88433b504b4385',
          image: LocalImages.csirImg
        },
        {
          name: 'GATE APTITUDE',
          id: '6433b9166f88433b504b4331',
          image: LocalImages.gateImg
        },
        {
          name: 'SET APTITUDE',
          id: '6433bb2c6f88433b504b437e',
          image: LocalImages.mhsetImg
        },
        {
          name: 'UGC NET APTITUDE',
          id: '6433b9486f88433b504b434e',
          image: LocalImages.ugcnetImg
        }
      ]
    }
  ];

  return (
    <div className="">
      {ACCESS_TOKEN_NEW === undefined || ACCESS_TOKEN_NEW === '' || ACCESS_TOKEN_NEW === null ? (
        <HeaderHome />
      ) : (
        <Header />
      )}
      {courseexamData?.ismultiple === false ? (
        <div
          className="app-achiev common-pad pt-2 pb-2 wow fadeIn text-center bg"
          data-wow-duration="2s"
          data-wow-delay="0s">
          <p className="text-center nameheading mb-3">{courseexamData?.name} TOPPERS</p>

          <div className="row justify-content-center content-topper mt-3 col-lg-10 mx-auto">
            {courseexamData?.toppers?.map((c, indexMain) => {
              return (
                <div
                  key={indexMain}
                  className="  col-sm-2 rounded wow fadeIn mb-3"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s">
                  <img className="img-fluid" src={LocalImages[c?.path]} alt={c.name} />
                  <span className="d-block mt-2">{c.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className="app-achiev common-pad pt-2 pb-2 wow fadeIn text-center bg"
          data-wow-duration="2s"
          data-wow-delay="0s">
          {courseexamData?.examlist?.map((exam, index) => {
            return (
              <>
                <p className="text-center nameheading mb-3">{exam?.examName}</p>

                <div className="row justify-content-between content-topper mt-3 col-lg-10 mx-auto">
                  {exam?.toppers?.map((c, indexMain) => {
                    return (
                      <div
                        key={indexMain}
                        className="  col-sm-2 rounded wow fadeIn mb-3"
                        data-wow-duration="2s"
                        data-wow-delay="0.3s">
                        <img className="img-fluid" src={LocalImages[c?.path]} alt={c.name} />
                        <span className="d-block mt-2">{c.name}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      )}
      <div
        className="app-achiev common-pad pt-4 pb-2 wow fadeIn text-center"
        data-wow-duration="2s"
        data-wow-delay="0s">
        <p className="text-center mb-2 colorblue coursenametitle fw-bold">
          {courseexamData?.name} Courses
        </p>
        <p className="subjectsubtext mb-3">
          The Most Comprehensive Packages Created By The Best Teachers Of India
        </p>
        {course?.map((item) => {
          return (
            <>
              <p className="text-center mb-2 colorblue nameheading  fw-bold">{item?.heading}</p>
              <div className="content-topper mt-5 col-lg-10 mx-auto d-flex justify-content-center flex-wrap">
                {item?.childCourse?.map((c, index) => {
                  return (
                    <div
                      key={c._id}
                      className="col-md-3 coursetab rounded wow fadeIn mb-3 boxshadow cursor p-3"
                      data-wow-duration="2s"
                      data-wow-delay="0.3s"
                      onClick={() => navigate('/' + c.seoName)}>
                      <div className="">
                        <img className="examimg me-1 ms-0" src={c.image} alt={c.name} />
                        <span className="mb-0 ">{c.name}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
      <div className="row common-pad pt-4">
        {courseexamData?.faq !== undefined ? (
          <p className="text-center mb-2 colorblue coursenametitle fw-bold">
            Some Frequently Asked Questions
          </p>
        ) : (
          ''
        )}
        <div
          className="accordion accordion-flush row d-flex justify-content-left"
          id="accordionFlushExample">
          {courseexamData?.faq?.map((c, index) => {
            return (
              <div key={index} className="accordion-item noborder col-md-12 ">
                <div className="boxshadow" style={{ margin: '6px' }}>
                  <h2 className="accordion-header" id={'heading' + index}>
                    <button
                      className={`accordion-button  ${index === 0 ? '' : 'collapsed'}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={'#collapse' + index}
                      aria-expanded={index === 0 ? 'true' : 'false'}
                      aria-controls={'collapse' + index}>
                      {c?.title}
                    </button>
                  </h2>
                  <div
                    id={'collapse' + index}
                    className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                    aria-labelledby={'heading' + index}
                    data-bs-parent={'#accordionExample'}>
                    <div className="accordion-body">{c?.text}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <ClassroomInPocket />

      <Footer />
      <div
        className="position-fixed backcolor rounded-circle cursor"
        style={{ right: '20px', bottom: '30px' }}>
        <a className="d-block d-md-none talkexpertbtn" href="tel:+919172266888">
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </a>
        <span className="d-none d-md-block" onClick={handleClick}>
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </span>
      </div>
      <TalktoExpert open={open} handleClose={handleClose} />
    </div>
  );
};

export default AptitudeCourses;
