import React from 'react';
import { useState, useEffect } from 'react';
import '../Assets/CSS/signIn.css';
import Submitbtn from '../Component/submitBtn';
import { LocalImages } from '../Constant';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useLocation, useNavigate } from 'react-router-dom';
function OtpError() {
  const location = useLocation();
  const navigate = useNavigate();
  const number = location.state.maskedNumber;
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/SignIn', { state: { IsclassRoom: false } });
  };
  return (
    <div className="row">
      <div className="col-7 leftSidediv">
        <div className="loginHeader ">
          <img src={LocalImages.logo} alt="IFAS Logo" className="logo"  style={{ width: "120px" }}/>
        </div>
        <div className="imgDiv">
          <img src={LocalImages.otperrorimg} alt="signIn" className="leftImg img-fluid w-100" />
        </div>
      </div>
      <div className="col-5 rightDivPad">
        <div className="text-center errorcolor">
          <HighlightOffIcon />
        </div>
        <h4 className="text-center colorblue fw-bold mb-0 mt-5">Username & Phone Number</h4>
        <h4 className="text-center colorblue fw-bold">Already Verified</h4>
        <p className="text-center txt mt-4 mb-2">Please Login With Phone Number</p>
        <p className="text-center errorcolor">+91-{number}</p>

        <div className="mobileField mt-5">
          <form onSubmit={handleSubmit}>
            <div className="mt-5 text-center">
              <Submitbtn name="Proceed to Login" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default OtpError;
