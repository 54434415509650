import React, { useState, useEffect } from 'react';
import '../Assets/CSS/home.css';
import Footer from '../Component/footer';
import { LocalImages } from '../Constant';
import { useSelector } from 'react-redux';
import HeaderHome from '../Module/headerHome';
import Header from '../Module/header';
import CareerPositionPopUp from '../Module/careerPositionPopup';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import InputField from '../Component/inputField';
import Phoneno from "../Component/phoneno";
import {  getJobPosition } from "../Redux/Actions/homeAction";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Career = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [JObPosition, setJObPosition] = useState()
  
  const { ACCESS_TOKEN_NEW, } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW,
  }));
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleOnclick = () => {
    handleClick();
  }
  const gotojob=(data)=>{
  
    // window.location.href = './JobInDhister';
     navigate('/Job-In-Ifas',{ state: { jobPosition : data } });
     
  }

  useEffect(() => {
  const Postdata={
  "skip": 0,
  "limit": 5
}
    dispatch(
      getJobPosition(
        Postdata,
        (data) => {
          setJObPosition(data?.data)
        },
        () => {}
      )
    );
  }, []);
  const gotoOpening=()=>{
    const element = document.getElementById("jobOpening");
       if (element) {
         // 👇 Will scroll smoothly to the top of the next section
         element.scrollIntoView({ behavior: 'smooth', block: "center", inline:"nearest"});
       }
   }
 
  return (
    <div className="">
      {ACCESS_TOKEN_NEW == '' || ACCESS_TOKEN_NEW == undefined ? <HeaderHome /> : <Header />}
      <div className="carrerbanner secpad wow fadeIn" data-wow-duration="2s" data-wow-delay="0s">
        <div className="row mb-0 align-items-center w-100">
          <div className="col-12 col-xl-6">
            <h1 className="fw-bold wow fadeIn" data-wow-duration="2s" data-wow-delay="0s">
              <span className="colorblue">Grow</span> Your Career
            </h1>
            <h1 className="fw-bold">
              With <span className="colorblue fw-bold">IFAS</span>
            </h1>
            <h4 className="mt-3 wow fadeInUp animated" data-wow-duration="1.5s">
              Join Us & be part of IFAS Family
            </h4>
            <div className="careerbannerbtnbox wow fadeInUp animated " data-wow-duration="1.5s">
              <h4 className="wow fadeInUp animated" data-wow-duration="1.5s">
                We Are Hiring
              </h4>
              { JObPosition !== undefined ?
              <a
                className="btn btn-primary custombutton mt-2 wow fadeInUp animated"
                href="#exploreJob"
                data-wow-duration="1.5s"
                onClick={gotoOpening}
               // onClick={() => handleOnclick()}
                >
                See Open Position
              </a> :
               <a
               className="btn btn-primary custombutton mt-2 wow fadeInUp animated"
               href="#exploreJob"
               data-wow-duration="1.5s"
              onClick={() => handleOnclick()}
               >
               See Open Position
             </a>}
            </div>
          </div>
          <div className="col-12 col-xl-6 homebannerimg">
            <img className="homeimg" src={LocalImages.kcimg} alt="KC Sir" />
          </div>
        </div>
        
        <CareerPositionPopUp open={open}  handleClose={handleClose} />
      </div>
      <div className="lifeatdhister common-pad py-5 commonMargin">
        <h1 className="boldfont mb-4 textshadow text-center">
          Experience life at IFAS
        </h1>
        <p className=" mb-0 fontsize20 text-center">
          We're building a workspace that promotes growth,
        </p>
        <p className=" fontsize20 text-center"> both personally and professionally.</p>
        <div className="text-center">
          <img
            src={LocalImages.WAWGROUP}
            alt="Banner"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="common-pad  commonMargin mt-0  " id="jobOpening" >
      { JObPosition !== undefined ?<h1 className="boldfont mb-5 textshadow text-center">
          Explore Job Openings
        </h1>:""}
        {JObPosition?.map((c,index) => {
        return (
              <div className="col-6 col-md-3  bgwhite text-center" key={index} onClick={()=>gotojob(c)}  >
                <div className="m-3 cursor opening openingcard rounded border">
                <img className="jobtypeimg mb-3 mt-3" src={c.file} alt={c.jobPositionName}/>
                <p className="fw-bold fontsize20">{c.jobPositionName}</p>
                <p className="designationtext text-primary pb-1">{c.count} Open Position</p>
                </div>
              </div>
           );
          })}
      </div>
      <div className="lifeatdhister common-pad py-5  mt-5">
        <h1 className="boldfont mb-4 textshadow colorBlue text-center">
          Not Found Any Suitable Opening ?
        </h1>
        <p className="text-center text-secondary mb-0">
          Submit your resume and we will reach out to you.
        </p>
        <div className="d-flex justify-content-center">
        <div className="col-md-7 boxshadow p-3 p-md-5 mt-4 bgwhite ">
          <form className="row setmargin"  >
            <div className="col-md-6 nopadding">
              
                <label className="mb-2">
                  Full Name <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Enter full name"
                  className="w-100 mb-3"
                  // setInputText={(value) => handleFullNameChange(value)}
                  // value={Fullname}
                  // disabled={props.disabled}
                  maxLength={50}
              
                />
              
            </div>
            <div className="col-md-6 nopadding mobile-placeholder">
            <label className="mb-2">Mobile number</label>
              <Phoneno className="placeholder"
                // setCountryCode={(value) => setCountryCode(value)}
                // countryCode={countryCode}
                // countryData={countryData}
                placeholder="Enter Your Phone Number"
                maxLength="10"
                minLength={10}
                // value={number}
                // handleOnChangePhoneText={handleOnChangePhoneText}
                
              />
            </div> 
            <div className="col-md-6 nopadding">
             
            <label className="mb-2">
                Email <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Enter email address"
                  className="w-100"
                  // setInputText={(value) => handleCompanyEmailChange(value)}
                  // value={CompanyEmail}
                  // disabled={props.disabled}
                />
              
            </div>
            <div className="col-md-6 nopadding">
              
            <label className="mb-2">
                Job Role  <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Enter current job role"
                  className="w-100"
                  // setInputText={(value) => handleJobRoleChange(value)}
                  // value={jobRole}
                  // disabled={props.disabled}
                  minLength={30}
                />
              
            </div>
            <div className="col-md-6 nopadding">
             
            <label className="mb-2">
                Current Company Name   <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Enter Current Company name"
                  className="w-100"
                  // setInputText={(value) => handleCompanyNameChange(value)}
                  // value={CompanyName}
                  // disabled={props.disabled}
                  minLength={30}
                />
             
            </div>
            <div className="col-md-6 nopadding">
              
            <label className="mb-2">
                Current CTC (in LPA) <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Enter Current CTC (in LPA)"
                  className="w-100"
                  // setInputText={(value) => handleCtcChange(value)}
                  // value={ctc}
                  // disabled={props.disabled}
                  minLength={4}
              
                />
              
            </div>
            <div className="col-md-6 nopadding">
              
            <label className="mb-2">
                Notice Period Days  <span className="text-danger">*</span>
                </label>
                <InputField
                  placeholder="Notice period days"
                  className="w-100"
                  // setInputText={(value) => handleNoticePeriodDaysChange(value)}
                  // value={noticePeriodDays}
                  // disabled={props.disabled}
                />
              
            </div>
            <div className="col-md-6 nopadding">
              
            <label className="mb-2">
                Attach Resume <span className="text-danger">*</span>
                </label>
                <div className="d-flex">
                  <div className="position-relative col-7 me-1">
                  <input className=" form-control ps-4" placeholder="Select Resume" type='file' accept="application/pdf , .doc ,.docx"  />
                  <span className="uploadicon"><UploadFileRoundedIcon/></span>
                  </div>
                  <p className="resumetxt">Upload in either DOC, DOCX or PDF file format (file size not more than 1MB)</p>
              </div>
            </div>
            <div className="text-center mt-3">
           <button className='btn btn-primary'>Submit</button>
           </div>
          </form>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Career;
