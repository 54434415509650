import React, { useState, useEffect } from 'react';

import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useDispatch } from 'react-redux';
import { getCity, getState } from '../Redux/Actions/AuthAction';
import { addEditAddress, getSingleAddress } from '../Redux/Actions/homeAction';
import { validateFullName, validateIsNumberOnly } from '../Utils/validators';
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateTenDigitNumberOnlyErrMsg
} from '../Utils/errormsg';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import CustomDropdown from '../Component/customDropdown';
function EditAddress(props) {
  const [singleAddress, setSingleAddress] = useState({});
  const [open, setOpen] = useState(true);
  const [fullName, setFullName] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [pincode, setPincode] = useState('');
  const [label, setLabel] = useState('');
  const [landmark, setLandmark] = useState('');
  const [alternatePhoneNumber, setAlternatePhoneNumber] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [isDefaultEdit, setIsDefaultEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [stateIndex, setStateIndex] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityIndex, setCityIndex] = useState(0);
  const [cityId, setCityId] = useState(0);
  const editId = props.editId;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getState((dataState) => {
        setStateData(dataState);
      })
    );
    dispatch(
      getSingleAddress(`/${editId}`, (editData) => {
        setSingleAddress(editData);
        setFullName(editData?.fullName);
        setLine1(editData?.line1);
        setLine2(editData?.line2);
        setStateId(editData?.state?._id);
        setPincode(editData?.pincode);
        setLabel(editData?.label);
        setLandmark(editData?.landmark);
        setAlternatePhoneNumber(editData?.alternatePhoneNumber);
        setIsDefault(editData?.isDefault);
        setIsDefaultEdit(editData?.isDefault);
        dispatch(
          getCity(editData?.state?._id, (dataCity) => {
            setCityData(dataCity);
            setCityId(editData?.city?._id);
          })
        );
      })
    );
  }, []);
  const disable = () => {
    if (
      pincode.length !== 6 ||
      pincode === '' ||
      fullName === '' ||
      line1 === '' ||
      line2 === '' ||
      stateId === '' ||
      cityId === '' ||
      landmark === '' ||
      label === ''
    )
      return false;
    return true;
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.edit(false);
  };
  const handleClick = () => {
    if (!isDefaultEdit) setIsDefault(!isDefault);
  };
  const setfullName = (e) => {
    if (validateFullName(e.target.value)) setFullName(e.target.value);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const setline1 = (e) => {
    setLine1(e.target.value);
  };
  const setline2 = (e) => {
    setLine2(e.target.value);
  };
  const setpincode = (e) => {
    if (!validateIsNumberOnly(e.target.value)) setPincode(e.target.value);
    else dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
  };
  const setlabel = (e) => {
    setLabel(e.target.value);
  };
  const setlandmark = (e) => {
    setLandmark(e.target.value);
  };
  const setalternatePhoneNumber = (e) => {
    if (!validateIsNumberOnly(e.target.value)) {
      setAlternatePhoneNumber(e.target.value);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
    // if (alternatePhoneNumber.length !== 10){
    //    dispatch(showErrorSnackbar(validateTenDigitNumberOnlyErrMsg));
    // }
  };
  const editAddress = () => {
    if (
      pincode.length !== 6 ||
      pincode === '' ||
      fullName === '' ||
      line1 === '' ||
      line2 === '' ||
      stateId === '' ||
      cityId === '' ||
      landmark === '' ||
      label === ''
    )
      dispatch(showErrorSnackbar('Please fill all details'));
    else if (alternatePhoneNumber?.length > 0 && alternatePhoneNumber?.length != 10) {
      dispatch(showErrorSnackbar(validateTenDigitNumberOnlyErrMsg));
    } else {
      const data = {
        fullName,
        line1,
        line2,
        state: stateId,
        city: cityId,
        pincode,
        landmark,
        label,
        alternatePhoneNumber,
        isDefault,
        addressId: editId
      };
      dispatch(
        addEditAddress(
          false,
          data,
          () => {},
          () => {}
        )
      );
      props?.edit(false);
    }
  };

  const onStateChange = (value) => {
    setStateId(value._id);
    dispatch(
      getCity(value._id, (dataCity) => {
        setCityData(dataCity);
      })
    );
  };
  const onCityChange = (value) => {
    setCityId(value._id);
  };

  const acceptno = (event) => {
    const result = event.target.value.replace(/\D/g, '');
  };
  return (
    <>
      {singleAddress.line1 && stateData ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}>
          <Fade in={open}>
            <div className="modeldiv address">
              <div className="col  d-flex justify-content-between ">
                <h6 className="colorblue fw-bold">Edit Address</h6>
                <ClearOutlinedIcon className="colorblue" onClick={handleClose} />
              </div>
              <div className="col m-3 p-3 boxshadow">
                <div className="font12">
                  <div className="col-sm-12 ps-0 mt-2">
                    <label className="mb-2 d-block">Full Name</label>
                    <input
                      type="text"
                      placeholder="Enter Full Name"
                      className="border-0 border-bottom w-100"
                      onChange={(e) => setfullName(e)}
                      value={fullName}
                      maxLength="50"
                    />
                  </div>
                  <div className="col-sm-12 ps-0 mt-2">
                    <label className="mb-2 d-block">House/ Flat/ Block No.</label>
                    <input
                      type="text"
                      placeholder="Enter House/ Flat/ Block No."
                      className="border-0 border-bottom w-100"
                      value={line1}
                      onChange={(e) => setline1(e)}
                      maxLength="150"
                    />
                  </div>
                  <div className="col-sm-12 mt-2 ps-0">
                    <label className="mb-2 d-block">Apartment/ Road /Area</label>
                    <input
                      type="text"
                      placeholder="Enter Apartment/ Road /Area "
                      className="border-0 border-bottom w-100"
                      value={line2}
                      onChange={(e) => setline2(e)}
                      maxLength="150"
                    />
                  </div>
                  <div className="d-flex row">
                    <div className="mt-2 col-6 ps-0 removeborder">
                      <label>State Name</label>
                      <CustomDropdown
                        setInputText={(value) => onStateChange(value)}
                        lableName="Select State"
                        options={stateData}
                        defaultValue={stateId}
                      />
                    </div>
                    <div className="mt-2 col-6 ps-0 removeborder">
                      <label>City Name</label>
                      <CustomDropdown
                        setInputText={(value) => onCityChange(value)}
                        lableName="Select City"
                        options={cityData}
                        defaultValue={cityId}
                      />
                    </div>
                  </div>
                  <div className="d-flex row font12 ">
                    <div className="col-sm-6 ps-0">
                      <label className="mb-2 d-block">Pincode</label>
                      <input
                        type="text"
                        placeholder="Enter Pincode"
                        className="border-0 border-bottom w-100"
                        maxLength="6"
                        value={pincode}
                        onChange={(e) => setpincode(e)}
                      />
                    </div>
                    <div className="col-sm-6 ps-0">
                      <label className="mb-2 d-block">Landmark</label>
                      <input
                        type="text"
                        placeholder="Enter Landmark"
                        className="border-0 border-bottom w-100"
                        value={landmark}
                        onChange={(e) => setlandmark(e)}
                        maxLength="50"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2 ps-0 position-relative">
                    <label className="mb-2 d-block">Save This Address As</label>
                    <input
                      type="text"
                      placeholder="Save This Address As"
                      className="border-0 border-bottom w-100"
                      value={label}
                      onChange={(e) => setlabel(e)}
                      maxLength="50"
                    />
                  </div>
                  <div className="col-sm-12 mt-2 ps-0">
                    <label className="mb-2 d-block">Alternative Phone Number(Optional)</label>
                    <input
                      type="text"
                      placeholder="Enter Phone Number "
                      className="border-0 border-bottom w-100"
                      maxLength="10"
                      value={alternatePhoneNumber}
                      onChange={(e) => setalternatePhoneNumber(e)}
                    />
                  </div>
                  <div className="col-sm-12 mt-2 ps-0">
                    <label className="mb-2 d-block">Address As</label>
                    <button
                      type="button"
                      className={
                        isDefault
                          ? 'btn btn-primary py-0 font14'
                          : 'btn btn-outline-primary py-0 font14'
                      }
                      onClick={handleClick}>
                      Default
                    </button>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className={!disable() ? 'btn btn-secondary w-50' : 'btn btn-primary w-50'}
                  onClick={editAddress}>
                  Save and Update
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
}

export default EditAddress;
