import React, { useState, useEffect, Fragment } from 'react';
import '../App.css';
import SubjectTestCard from '../Module/subjectTestCard';
import SubjectTestDetails from '../Module/subjectTestDetails';
import OnlineTestExamCard from '../Module/onlineTestExamCard';
import ScholarshipTestsCard from '../Module/scholarshipTestsCard';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getExamById,
  notSyncedDataAction,
  setInstructionsAction,
  getMockTestList,
  getUnitTests,
  getScholarshipList,
  getLiveScholarshipList,
  getExamListByType,
  getOnlineTest,
  scholarshipExamRemindMe,
  getExamSubject,
} from '../Redux/Actions/examActions';
import { _retrieveData } from '../Utils/utils';
import ScreenNames from '../Constant/ScreenNames';
import moment from 'moment';
import NoRecord from '../Component/noRecord';
import RemindMe from '../Module/remindMe';
import { showSuccessSnackbar } from '../Redux/Actions/snackbarAction';

const OnlineTest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialScholarShipState = {
    live: [],
    completed: [],
    upcoming: []
  };
  const [subjectTest, setSubjectTest] = useState([]);
  const [mockTest, setMockTest] = useState([]);
  const [previousQPList, setPreviousQPList] = useState([]);
  const [previousYearsList, setPreviousYearsList] = useState([]);
  const [selectedPrevYear, setSelectedPrevYear] = useState('');
  const [scholarShip, setScholarShip] = useState(initialScholarShipState);
  const [loader, setLoading] = useState(false);
  const [test, settest] = useState([]);
  const [reminderText, setReminderText] = useState('');
  const [topicData, setTopicData] = useState([]);
  const [Heading, setHeading] = useState('');
  const startdata = localStorage.getItem('startTest');
  let selectedQuestionIndex = 0;
  const currentURLarr = useLocation().pathname.split('/');
  let link = currentURLarr[currentURLarr.length - 1];
  const {
    examTypeId,
    baseCourseId,
    notSyncedDataFromStore,
    examIdDataFromStoreObject,
    hasActivePaidPlan,
    selectedTiles,
    childrenId,
  } = useSelector((state) => ({
    examTypeId: state?.AuthReducer?.examTypeId,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    hasActivePaidPlan:
      link === 'FreeOnlineTest' ? false : state?.AuthReducer?.hasActivePaidPlan || false,
    notSyncedDataFromStore: state?.ExamSelectReducer?.notSyncedData,
    examIdDataFromStoreObject: state?.ExamSelectReducer?.examIdData,
    childrenId: state?.AuthReducer?.childrenId,
    selectedTiles: state?.HomeReducer?.selectedTiles
  }));

  useEffect(() => {
    if (hasActivePaidPlan === true) {
      getHeadTitle();
      const postdata = {
        baseCourseId: selectedTiles?.baseCourseId?._id,
        examTypeId: selectedTiles?.examTypeId?._id,
        subExamTypeId: selectedTiles?.subExamTypeId?._id,
        courseId: selectedTiles?.courseId
      };
      if (selectedTiles?.subExamTypeId === '') delete postdata.subExamTypeId;

      dispatch(
        getOnlineTest(postdata, (dataState) => {
          settest(dataState);
          // setTopicTest(dataState?.topicData);
          setMockTest(dataState?.MockTest);
          setPreviousQPList(dataState?.PYQ);
          setSubjectTest(dataState?.UnitTest);
          const uniquePreviousYearsSet = new Set();
          for (const item of dataState?.PYQ) {
            uniquePreviousYearsSet.add(item._id);
          }
          setPreviousYearsList(['All', ...uniquePreviousYearsSet]);
          /**************Unit Test************** */
        },
          () => { }
        )
      );
    } else {
      const postData = {
        baseCourseId,
        examTypeId,
        subExamTypeId: childrenId
      };

      if (childrenId === '') delete postData.subExamTypeId;
      dispatch(
        getUnitTests(
          postData,
          (res) => {
            const subjectsData = [];
            const subjectSet = new Set(); // store unique subjects
            for (const x of res.data) {
              subjectSet.add(x.subjectName);
            }
            for (const subject of subjectSet) {
              const x = res.data.filter((item) => item.subjectName === subject);
              subjectsData.push({
                title: subject,
                count: x.length,
                subjectId: x[0].subjectId
              });
            }
            setSubjectTest(subjectsData);
          },
          () => { }
        )
      );

      dispatch(
        getMockTestList(
          postData,
          (res) => {
            setMockTest(res.data);
          },
          () => { }
        )
      );

      const postDataPrevYearPapersData = {
        ...postData,
        examType: 4
      };
      dispatch(
        getExamListByType(
          postDataPrevYearPapersData,
          (res) => {
            const uniquePreviousYearsSet = new Set();
            for (const item of res.data) {
              uniquePreviousYearsSet.add(item.examYear);
            }
            const prevQPYearWise = [];
            for (const year of uniquePreviousYearsSet) {
              prevQPYearWise.push({
                examYear: year,
                data: res?.data?.filter((item) => item.examYear == year)
              });
            }
            setPreviousQPList(prevQPYearWise);
            setPreviousYearsList(['All', ...uniquePreviousYearsSet]);
          },
          (err) => { }
        )
      );
      dispatch(
        getScholarshipList(
          postData,
          (res) => {
            const today = new Date();
            const todayDateTime = moment(today, 'YYYY-MM-DD LT');
            const todayDate = moment(today, 'YYYY-MM-DD');

            let completed = [],
              upcoming = [],
              live = [];

            res?.data?.map((item) => {
              const startDate = moment(item.contestStartDate, 'YYYY-MM-DD').utc();
              const endDate = moment(item.contestEndDate, 'YYYY-MM-DD').utc();

              const startDateTime = moment(item.contestStartDate, 'YYYY-MM-DD LT').utc();
              const endDateTime = moment(item.contestEndDate, 'YYYY-MM-DD LT').utc();
              if (todayDate < startDate && todayDate < endDate) {
                upcoming.push(item);
              } else if (todayDateTime > startDateTime && todayDateTime > endDateTime) {
                completed.push(item);
              } else {
                live.push(item);
              }
            });
            setScholarShip((prevState) => ({ ...prevState, completed, upcoming, live }));
          },
          () => { }
        )
      );
      dispatch(
        getLiveScholarshipList(
          postData,
          (res) => {
            setScholarShip((prevState) => ({ ...prevState, live: res.data }));
          },
          () => { }
        )
      );
    }
    getExamSubjectApiCall();
  }, []);
  
  const getExamSubjectApiCall = () => {
    const postdata = {
      baseCourseId,
      examTypeId,
      isFreeUser: false,
      isPYQ: true,
      examType: 3
    };
    dispatch(
      getExamSubject(postdata, (res) => {
        setTopicData(res);
      })
    );
  };
  const onStartExam = (examId) => {
    const examIdDataFromStore = examIdDataFromStoreObject[examId];
    const args = {
      examId,
      navigate,
      setLoading,
      dispatch,
      getExamById,
      notSyncedDataFromStore,
      selectedQuestionIndex,
      notSyncedDataAction,
      examIdDataFromStore,
      setInstructionsAction
    };
    _retrieveData(args);
  };

  const onpressTest = (subject) => {
    if (hasActivePaidPlan == true)
      navigate(`/${ScreenNames.PaidUser}/${ScreenNames.SubjectTest}`, {
        state: { subject: subject }
      });
    else {
      if (link === 'FreeOnlineTest') {
        navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.FreeSubjectTest}`, {
          state: { subject: subject }
        });
      }
      else {
        navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.SubjectTest}`, {
          state: { subject: subject }
        });
      }
    }
  };
  const onpressTopicList = (_id) => {
    const postData = {
      baseCourseId,
      examTypeId,
      isFreeUser: false,
      isPYQ: true,
      examType: 3,
      subjectId: _id
    };
    if (hasActivePaidPlan == true)
      navigate(`/${ScreenNames.PaidUser}/${ScreenNames.FreeTopicList}`, {
        state: postData
      });
    else if (link === 'FreeTopicList') {
      navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.FreeTopicList}`, {
        state: postData
      });
    } else {
      navigate(`/${ScreenNames.DashboardMenu}/${ScreenNames.FreeTopicList}`, {
        state: postData
      });
    }
  };
  const onChangePrevYear = (e) => {
    setSelectedPrevYear(e.target.value);
  };
  const [open, setopen] = useState(false);
  const handleClose = () => {
    setopen(false);
  };

  const scholarshipRemindMe = (examId, flag = true) => {
    const examUrl = 'https://ifasonline.com/'; // TODO: it will be provided from backend
    setReminderText(examUrl);

    if (flag === false) {
      dispatch(
        scholarshipExamRemindMe(
          { examId },
          (res) => {
            const postData = {
              baseCourseId: baseCourseId,
              examTypeId: examTypeId,
              subExamTypeId: childrenId
            };
            dispatch(
              getScholarshipList(
                postData,
                (res) => {
                  const today = new Date();
                  const todayDateTime = moment(today, 'YYYY-MM-DD LT');
                  const todayDate = moment(today, 'YYYY-MM-DD');

                  let completed = [],
                    upcoming = [],
                    live = [];

                  res?.data?.map((item) => {
                    const startDate = moment(item.contestStartDate, 'YYYY-MM-DD').utc();
                    const endDate = moment(item.contestEndDate, 'YYYY-MM-DD').utc();

                    const startDateTime = moment(item.contestStartDate, 'YYYY-MM-DD LT').utc();
                    const endDateTime = moment(item.contestEndDate, 'YYYY-MM-DD LT').utc();
                    if (todayDate < startDate && todayDate < endDate) {
                      upcoming.push(item);
                    } else if (todayDateTime > startDateTime && todayDateTime > endDateTime) {
                      completed.push(item);
                    } else {
                      live.push(item);
                    }
                  });

                  setScholarShip((prevState) => ({ ...prevState, completed, upcoming, live }));
                  setopen(true);
                },
                () => { }
              )
            );
          },
          (err) => { }
        )
      );
    } else if (flag === true) {
      dispatch(showSuccessSnackbar('Reminder is already on!'));
      setopen(true);
    }
  };
  let prevYearQP = [];
  if (hasActivePaidPlan == true) {
    prevYearQP =
      selectedPrevYear === 'All' || selectedPrevYear === ''
        ? previousQPList
        : previousQPList.filter((item) => item._id == selectedPrevYear);
  } else {
    prevYearQP =
      selectedPrevYear === 'All' || selectedPrevYear === ''
        ? previousQPList
        : previousQPList.filter((item) => item.examYear == selectedPrevYear);
  }
  const getHeadTitle = () => {
    if (selectedTiles?.sectionType === 1 && 2 && 3 && 5 && 6) {
      setHeading("Online Test");
    } else if (selectedTiles?.sectionType === 7) {
      setHeading("PYQ Test");
    } else if (selectedTiles?.sectionType === 8) {
      setHeading("Practice Test");
    }
  }
  return (
    <div className="rightPanel">
      <h3>{Heading}</h3>
      <div className="customtab mt-4 ">
        <ul
          className="nav nav-pills mb-3 boxshadow p-2 rounded bgwhite"
          id="pills-tab"
          role="tablist">
          <li className="nav-item me-3 mt-1 " role="presentation">
            <button
              className="nav-link active"
              id="pills-SubjectTest-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-SubjectTest"
              type="button"
              role="tab"
              aria-controls="pills-SubjectTest"
              aria-selected="true">
              Unit Test
            </button>
          </li>
          {selectedTiles?.sectionType === 7 && 8 ?
            <li className="nav-item me-3 mt-1" role="presentation">
              <button
                className="nav-link"
                id="pills-topic-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-topic"
                type="button"
                role="tab"
                aria-controls="pills-topic"
                aria-selected="true">
                Topic Test
              </button>
            </li>
            : (null)}
          <li className="nav-item me-3 mt-1" role="presentation">
            <button
              className="nav-link"
              id="pills-MockTest-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-MockTest"
              type="button"
              role="tab"
              aria-controls="pills-MockTest"
              aria-selected="true">
              Mock Test
            </button>
          </li>
          {selectedTiles?.sectionType === 1 || 2 || 3 || 5 || 6 ?
            <li className="nav-item me-3 mt-1" role="presentation">
              <button
                className="nav-link"
                id="pills-pep-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-pep"
                type="button"
                role="tab"
                aria-controls="pills-pep"
                aria-selected="true">
                Previous Exam Paper
              </button>
            </li>
            : (null)}
          {hasActivePaidPlan ? null : (
            <li className="nav-item me-3 mt-1" role="presentation">
              <button
                className="nav-link"
                id="pills-Scholarship-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Scholarship"
                type="button"
                role="tab"
                aria-controls="pills-Scholarship"
                aria-selected="true">
                Scholarship Tests
              </button>
            </li>
          )}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade active show"
            id="pills-SubjectTest"
            role="tabpanel"
            aria-labelledby="pills-SubjectTest-tab"
            tabIndex="0">
            <div className="mt-1 subjectTestheight p-1 p-md-3">
              {hasActivePaidPlan ? (
                subjectTest.length > 0 ? (
                  subjectTest.map((c) => {
                    return (
                      <SubjectTestCard
                        key={c?.subjectDetails?._id}
                        title={c?.subjectDetails?.name}
                        lectcount={c?.unitTests?.length}
                        onpress={() => onpressTest(c?.unitTests)}
                      />
                    );
                  })
                ) : (
                  <NoRecord />
                )
              ) : hasActivePaidPlan === false && subjectTest.length != 0 ? (
                subjectTest.map((c) => {
                  return (
                    <SubjectTestCard
                      key={c.subjectId}
                      title={c.title}
                      lectcount={c.count}
                      onpress={() => onpressTest(c)}
                    />
                  );
                })
              ) : (
                <NoRecord />
              )}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-topic"
            role="tabpanel"
            aria-labelledby="pills-topic"
            tabIndex="0">
            <div className="mt-1 subjectTestheight p-1 p-md-3">
            {hasActivePaidPlan ? (
              topicData.length > 0 ? (
                topicData.map((c) => {
                  return (
                    <SubjectTestCard
                      key={c?.subjectId}
                      title={c?.name}
                      lectcount={c?.count}
                      // time={c?.duration}
                      onpress={() => onpressTopicList(c._id)}
                    />
                  );
                })
              ) : (
                <NoRecord />
              )
              ) : hasActivePaidPlan === false && topicData.length != 0 ? (
                topicData.map((c) => {
                  return (
                    <SubjectTestCard
                      key={c.subjectId}
                      title={c.title}
                      lectcount={c.count}
                      onpress={() => onpressTopicList(c._id)}
                    />
                  );
                })
              ) : (
                <NoRecord />
              )}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-MockTest"
            role="tabpanel"
            aria-labelledby="pills-MockTest-tab"
            tabIndex="0">
            <div
              className={
                mockTest.length != 0
                  ? 'mt-1 subjectTestheight p-1 p-md-3 width75 '
                  : 'mt-1 subjectTestheight p-1 p-md-3  col-12'
              }>
              {mockTest.length != 0 ? (
                <>
                  {hasActivePaidPlan
                    ? mockTest.map((c) => {
                      return (
                        <SubjectTestDetails
                          hasActivePaidPlan={hasActivePaidPlan}
                          key={c._id}
                          data1={c}
                          existInStore={examIdDataFromStoreObject[c?.examId] ? true : false}
                          onStartExam={() => onStartExam(c._id)}
                          startdata={startdata}
                        />
                      );
                    })
                    : mockTest.map((c) => {
                      return (
                        <SubjectTestDetails
                          key={c.examId}
                          data1={c}
                          existInStore={examIdDataFromStoreObject[c?.examId] ? true : false}
                          onStartExam={() => onStartExam(c.examId)}
                          startdata={startdata}
                        />
                      );
                    })}
                </>
              ) : (
                <NoRecord />
              )}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-pep"
            role="tabpanel"
            aria-labelledby="pills-pep-tab"
            tabIndex="0">
            <div className="row mt-2">
              {prevYearQP.length != 0 ? (
                <>
                  <div className="col-9 subjectTestheight p-1 p-md-3">
                    {hasActivePaidPlan
                      ? prevYearQP.map((c, index) => {
                        const arr1 = c.PYQs;
                        {
                          return (
                            <Fragment key={index}>
                              <div>
                                <strong>{c.examYear}</strong>
                              </div>
                              {arr1?.map((scdata) => {
                                return (
                                  <OnlineTestExamCard
                                    key={scdata.examId}
                                    data={scdata}
                                    existInStore={
                                      examIdDataFromStoreObject[scdata?.examId] ? true : false
                                    }
                                    onStartExam={() => onStartExam(scdata.examId)}
                                  />
                                );
                              })}
                            </Fragment>
                          );
                        }
                      })
                      : prevYearQP.map((c, index) => {
                        const arr1 = c.data;
                        {
                          return (
                            <Fragment key={index}>
                              <div>
                                <strong>{c.examYear}</strong>
                              </div>
                              {arr1?.map((scdata) => {
                                return (
                                  <OnlineTestExamCard
                                    key={scdata.examId}
                                    data={scdata}
                                    existInStore={
                                      examIdDataFromStoreObject[scdata?.examId] ? true : false
                                    }
                                    onStartExam={() => onStartExam(scdata.examId)}
                                  />
                                );
                              })}
                            </Fragment>
                          );
                        }
                      })}
                  </div>
                  {prevYearQP.length != 0 ? (
                    <div className="col-3 bgwhite exampattern">
                      <div className="row d-flex justify-content-between">
                        <div className="col-9 pe-0">
                          <span className="fw-bold">Exam Year</span>
                        </div>
                        <div className="col-3 ps-0">
                          <select
                            style={{ border: 'none' }}
                            value={selectedPrevYear}
                            onChange={(e) => onChangePrevYear(e)}>
                            {previousYearsList.map((previousYear) => {
                              return (
                                <option key={previousYear} value={previousYear}>
                                  {previousYear}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <NoRecord className="bgwhite" />
              )}
            </div>
          </div>
          <div
            className="tab-pane fade custominnertab"
            id="pills-Scholarship"
            role="tabpanel"
            aria-labelledby="pills-Scholarship-tab"
            tabIndex="0">
            Scholarship
            {hasActivePaidPlan === false && scholarShip.length != 0 ? (
              <div className="mt-1 subjectTestheight p-1 p-md-3 width75">
                {scholarShip?.live != undefined && scholarShip?.live != '' ? (
                  <h4 className="my-2">Live</h4>
                ) : null}
                {scholarShip?.live.map((c) => {
                  return (
                    <ScholarshipTestsCard
                      key={c.examId}
                      data1={c}
                      examdetails={c.examId}
                      headtitle="Live"
                      existInStore={examIdDataFromStoreObject[c?.examId] ? true : false}
                      onStartExam={() => onStartExam(c.examId)}
                    />
                  );
                })}

                {scholarShip?.upcoming != undefined && scholarShip?.upcoming != '' ? (
                  <h4 className="my-2">Upcoming</h4>
                ) : null}
                {scholarShip?.upcoming.map((c) => {
                  return (
                    <ScholarshipTestsCard
                      key={c.examId}
                      data1={c}
                      headtitle="Upcoming"
                      existInStore={examIdDataFromStoreObject[c?.examId] ? true : false}
                      onStartExam={() => scholarshipRemindMe(c.examId, c?.isReminder)}
                    />
                  );
                })}

                {scholarShip?.completed != undefined && scholarShip?.completed != '' ? (
                  <h4 className="my-2">Completed</h4>
                ) : null}
                {scholarShip?.completed.map((c) => {
                  return (
                    <ScholarshipTestsCard
                      key={c.examId}
                      data1={c}
                      headtitle="Completed"
                      existInStore={examIdDataFromStoreObject[c?.examId] ? true : false}
                      onStartExam={() => onStartExam(c.examId)}
                    />
                  );
                })}
              </div>
            ) : (
              <NoRecord className="bgwhite" />
            )}
          </div>
        </div>
      </div>
      <RemindMe open={open} text={reminderText} resetInterval={3000} handleClose={handleClose} />
    </div>
  );
};

export default OnlineTest;
