import { combineReducers } from 'redux';
import {
  StartExamReducer,
  ExamSelectReducer,
  SetOptionValueFalseReducer
} from './Reducers/examReducers';
import AuthReducer from './Reducers/authReducer';
import uiReducer from './Reducers/uiReducer';
import HomeReducer from './Reducers/homeReducer';
import { RESET_STORE } from '../Types/authTypes';
import CourseDetailreducer from './Reducers/courseDetailReducer';
import subscriptionPlanReducer from './Reducers/subscriptionPlanReducer';
import { SessionReducer } from './Reducers/sessionReducer';

const appReducer = combineReducers({
  StartExamReducer,
  ExamSelectReducer,
  AuthReducer,
  SetOptionValueFalseReducer,
  uiReducer,
  HomeReducer,
  CourseDetailreducer,
  subscriptionPlanReducer,
  SessionReducer
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
