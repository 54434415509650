import React, { useEffect, useState } from 'react';
import { pureFinalPropsSelectorFactory } from 'react-redux/es/connect/selectorFactory';
import offerimg from '../Assets/Images/CourseDetails/offer.png';
import {
  applyCoupon,
  removeCoupon,
  updateHomeValues,
  applyCouponCart,
  getCartList,
  updateHomeMoreValues,
  applyCouponfree
} from '../Redux/Actions/homeAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  validateFullName,
  validateIsNumberOnly,
  validateCouponCodeName
} from '../Utils/validators';
import { validateCouponCodeNameErrMsg } from '../Utils/errormsg';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { updateSubscriptionValues } from '../Redux/Actions/subscriptionPlanAction';
import { NavLink } from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
function CouponCard(props) {
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState('');
  const [successText, setSuccessText] = useState('');

  const {
    subscriptionID,
    selectedPackages,
    couponCode,
    price,
    showRemoveCoupon,
    packageSection,
    CouponData,
    OriginalPrice,
    ACCESS_TOKEN_NEW
  } = useSelector((state) => ({
    subscriptionID: state?.HomeReducer?.subscriptionID,
    price: state?.subscriptionPlanReducer?.price,
    selectedPackages: state?.HomeReducer?.selectedPackages,
    couponCode: state?.HomeReducer?.couponCode,
    showRemoveCoupon: state?.HomeReducer?.showRemoveCoupon,
    packageSection: state?.CourseDetailreducer?.packageSection,
    CouponData: state?.HomeReducer?.CouponData,
    OriginalPrice: state?.HomeReducer?.OriginalPrice,
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));

  if (props?.dataForApplyCoupon)
    var { sectionType, courseId, packageSectionId, bookSectionId, subscriptionPlanId } =
      props?.dataForApplyCoupon;
  for (let i = 0; i < selectedPackages?.length; i++) {
    for (let key in selectedPackages[i]) {
      if (key == 'sessionId' || key == 'couponId') {
        delete selectedPackages[i][key];
      }
    }
  }

  useEffect(() => {
    const postData = {
      sectionType,
      courseId,
      packageSectionId
    };

    if (bookSectionId) {
      postData['bookSectionId'] = bookSectionId;
    } else if (subscriptionPlanId) {
      postData['subscriptionPlanId'] = subscriptionPlanId;
    }

    if (props.couponCode) {
      if (ACCESS_TOKEN_NEW) {
        dispatch(
          applyCoupon(
            {
              couponName: props.couponCode,
              package: selectedPackages,
              extendedOption: props?.isextended === true ? true : false
            },
            (res) => {
              if (res?.length == 0) {
                dispatch(
                  updateHomeValues({
                    couponCode: res?.data?.couponName,
                    showRemoveCoupon: true,
                    CouponData: []
                  })
                );
                setErrorText('is invalid ! Please use valid code');
                setSuccessText('');
              } else {
                dispatch(
                  updateHomeValues({
                    discountAmount: res?.data?.discountedPrice,
                    CouponData: res?.data?.package
                  })
                );
                dispatch(updateSubscriptionValues({ price: res?.data?.discountedPrice }));
                // dispatch(updateHomeValues({ OriginalPrice: res?.data?.price }));
                setErrorText('');
                setSuccessText(res.msg);
                dispatch(
                  updateHomeValues({ couponCode: res.data.couponName, showRemoveCoupon: true })
                );
              }
            }
          )
        );
      } else {
        dispatch(
          applyCouponfree(
            {
              couponName: props.couponCode,
              package: selectedPackages
            },
            (res) => {
              if (res?.length == 0) {
                dispatch(
                  updateHomeValues({
                    couponCode: res?.data?.couponName,
                    showRemoveCoupon: true,
                    CouponData: []
                  })
                );
                setErrorText('is invalid ! Please use valid code');
                setSuccessText('');
              } else {
                dispatch(
                  updateHomeValues({
                    discountAmount: res?.data?.discountedPrice,
                    CouponData: res?.data?.package
                  })
                );
                dispatch(updateSubscriptionValues({ price: res?.data?.discountedPrice }));
                // dispatch(updateHomeValues({ OriginalPrice: res?.data?.price }));
                setErrorText('');
                setSuccessText(res.msg);
                dispatch(
                  updateHomeValues({ couponCode: res.data.couponName, showRemoveCoupon: true })
                );
              }
            }
          )
        );
      }
    }
  }, [props.couponCode]);

  const handleClick = () => {
    if (price <= 0) {
      dispatch(showErrorSnackbar('Please select first'));
    } else {
      if (props.cartID) {
        dispatch(
          applyCouponCart(
            {
              cartId: props?.cartID,
              couponName: couponCode
            },
            (res) => {
              if (res.length == 0) {
                dispatch(updateHomeValues({ showRemoveCoupon: false, CouponData: [] }));
                setErrorText('is invalid ! Please use valid code');
              } else {
                dispatch(updateHomeValues({ showRemoveCoupon: true }));
                setErrorText('');
                dispatch(getCartList(() => {}, true));
              }
            }
          )
        );
      } else {
        let testArray = [];
        const subscriptionPlanArray = packageSection[props.index]?.subscriptionPlan;
        for (let i = 0; i < subscriptionPlanArray?.length; i++) {
          let testObj = {
            sectionType,
            courseId,
            packageSectionId,
            subscriptionPlanId: subscriptionPlanArray[i]?._id,
            isExetended: props?.isextended
          };
          testArray.push(testObj);
        }
        const postData = {
          sectionType,
          courseId,
          packageSectionId
        };

        if (bookSectionId) {
          postData['bookSectionId'] = bookSectionId;
        } else if (subscriptionPlanId) {
          postData['subscriptionPlanId'] = subscriptionPlanId;
        }
        if (ACCESS_TOKEN_NEW) {
          dispatch(
            applyCoupon(
              {
                couponName: couponCode,
                // package: selectedPackages?.length !== 0 ? selectedPackages : [postData]
                package: selectedPackages?.length !== 0 ? selectedPackages : testArray,
                extendedOption: props?.isextended === true ? true : false
              },
              (res) => {
                if (res?.length == 0) {
                  dispatch(updateHomeValues({ showRemoveCoupon: true, CouponData: [] }));
                  // dispatch(updateHomeValues({ OriginalPrice: price }));
                  setErrorText('is invalid ! Please use valid code');
                  setSuccessText('');
                } else {
                  dispatch(
                    updateHomeValues({
                      discountAmount: res?.data?.discountedPrice,
                      showRemoveCoupon: true
                    })
                  );
                  if (sectionType === 1 || sectionType === 3) {
                    let currentSelectedSubscription = res?.data?.package.filter(
                      (obj) => obj.subscriptionPlanId === subscriptionID
                    )[0];
                    dispatch(
                      updateSubscriptionValues({
                        price: currentSelectedSubscription?.discountedPrice
                      })
                    );
                  } else {
                    dispatch(updateSubscriptionValues({ price: res?.data?.discountedPrice }));
                  }
                  // dispatch(updateHomeValues({ OriginalPrice: res?.data?.price }));
                  setErrorText('');
                  setSuccessText(res?.msg);
                }
              }
            )
          );
        } else {
          dispatch(
            applyCouponfree(
              {
                couponName: couponCode,
                // package: selectedPackages?.length !== 0 ? selectedPackages : [postData]
                package: selectedPackages?.length !== 0 ? selectedPackages : testArray
              },
              (res) => {
                if (res?.length == 0) {
                  dispatch(updateHomeValues({ showRemoveCoupon: true, CouponData: [] }));
                  // dispatch(updateHomeValues({ OriginalPrice: price }));
                  setErrorText('is invalid ! Please use valid code');
                  setSuccessText('');
                } else {
                  dispatch(
                    updateHomeValues({
                      discountAmount: res?.data?.discountedPrice,
                      showRemoveCoupon: true
                    })
                  );
                  if (sectionType === 1 || sectionType === 3) {
                    let currentSelectedSubscription = res?.data?.package.filter(
                      (obj) => obj.subscriptionPlanId === subscriptionID
                    )[0];
                    dispatch(
                      updateSubscriptionValues({
                        price: currentSelectedSubscription?.discountedPrice
                      })
                    );
                  } else {
                    dispatch(updateSubscriptionValues({ price: res?.data?.discountedPrice }));
                  }
                  // dispatch(updateHomeValues({ OriginalPrice: res?.data?.price }));
                  setErrorText('');
                  setSuccessText(res?.msg);
                }
              }
            )
          );
        }
      }
    }
  };

  const onChangeCouponCode = (e) => {
    if (validateCouponCodeName(e.target.value)) {
      dispatch(
        updateHomeValues({
          couponCode: e.target.value,
          discountAmount: 0,
          showRemoveCoupon: false,
          CouponData: []
        })
      );
      dispatch(updateSubscriptionValues({ price: OriginalPrice }));
      setErrorText('');
      setSuccessText('');
    } else {
      dispatch(showErrorSnackbar(validateCouponCodeNameErrMsg));
    }
  };

  const onChangeRemoveCoupon = () => {
    dispatch(
      updateHomeValues({
        discountAmount: 0,
        couponCode: '',
        showRemoveCoupon: false,
        CouponData: []
      })
    );
    dispatch(updateSubscriptionValues({ price: OriginalPrice }));
  };

  return (
    <div className="p-lg-4 px-lg-5 coupondiv ">
      {props.hideInput ? (
        <>
          <div className="position-relative p-3 pb-2 rounded coupon ">
            <div className="d-flex ">
              <div className="position-relative">
                <img src={offerimg} className="offerimg position-absolute ms-1" alt="offerimg" />
                <input
                  className="border-0 border-bottom border-dark couponinput colorblue fw-bold"
                  type="text"
                  placeholder="Have a Coupon Code?"
                  value={couponCode}
                  maxLength="20"
                  // disabled={showRemoveCoupon}
                  style={{ width: '233px' }}
                  onChange={(e) => onChangeCouponCode(e)}
                />
              </div>
              <div className="text-center ps-4">
                {couponCode?.length > 0 && !showRemoveCoupon ? (
                  <span
                    className="successcolor position-absolute font12 mt-1"
                    onClick={handleClick}>
                    <NavLink>Apply Code</NavLink>
                  </span>
                ) : (
                  ''
                )}
                {couponCode?.length > 0 && showRemoveCoupon ? (
                  <>
                    <span
                      className="errorcolor position-absolute mt-1 cursor font12"
                      onClick={onChangeRemoveCoupon}>
                      <DeleteIcon fontSize="font12" />
                      Remove
                    </span>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div>
              {couponCode && successText ? (
                <span className="successcolor  mt-1  font11 ">{successText}</span>
              ) : (
                ''
              )}
              {couponCode && errorText ? (
                <span className="errorcolor mt-1  font12">
                  {' '}
                  {couponCode} {errorText}!
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      ) : (
        ''
      )}

      <div className="shadow-md shadow-lg shadow-xl topshadow d-flex justify-content-between py-3 px-4 rounded bgwhite">
        {props.installmentSelected ? (
          <div>
            <h6 className="fw-bold mb-1">1st Installment :</h6>
            <h6 className="colorblue mb-0">
              &#8377;{props?.Price} <span className="colorgrey">({props?.itemCount} Items)</span>
            </h6>
          </div>
        ) : (
          <div>
            <h6 className="fw-bold mb-1">Total Amount</h6>
            <h6 className="colorblue mb-0">
              &#8377;{props?.Price} <span className="colorgrey">({props?.itemCount} Items)</span>
            </h6>
          </div>
        )}
        {/* <div>
          <h6 className='fw-bold mb-1'>Total Amount</h6>
          <h6 className='colorblue mb-0'>&#8377;{props?.Price} <span className="colorgrey">({props?.itemCount} Items)</span></h6>
        </div> */}
        <div>
          <button
            className={
              props.disabled
                ? 'btn btn-secondary rounded-pill px-4'
                : 'btn btn-primary rounded-pill px-4'
            }
            disabled={props.disabled}
            onClick={props?.setOnClick}>
            {props?.btnname}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CouponCard;
