import React from 'react';
import { useState, useEffect } from 'react';
import '../Assets/CSS/signIn.css';
import Submitbtn from '../Component/submitBtn';
import InputField from '../Component/inputField';
import CustomDropdown from '../Component/customDropdown';
import { useDispatch } from 'react-redux';
import service from '../Constant/service';
import { validateFullName } from '../Utils/validators';
import { validateFullNameErrMsg, validateLastNameErrMsg } from '../Utils/errormsg';
import {
  updateMoreValues,
  examType,
  getState,
  register,
  getCourse,
  getCountry,
  getExamList,
  registerUser
} from '../Redux/Actions/AuthAction';
import { showSuccessSnackbar, showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LocalImages } from '../Constant';

import { NavLink } from 'react-bootstrap';

function CreateAccount(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [parentCourseVal, setparentCourseVal] = useState(0);
  const [statename, setstatename] = useState(0);
  const [fname, setfname] = useState('');
  const [lname, setlname] = useState('');
  const [phno, setphno] = useState();
  const dispatch = useDispatch();
  const { countryCode, mobileNo } = location?.state;
  const [loader, setLoading] = React.useState(false);
  const [stateData, setStateData] = useState([]);
  const [referralCode, setReferralCode] = useState('');
  const [country, setCountryData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [blank, setblank] = useState([]);
  const [examName, setexamName] = useState('');
  const [examData, setexamData] = useState([]);
  const [subExamName, setsubExamName] = useState('');
  const [SubExamData, setSubExamData] = useState([]);

  const [error, setError] = useState({
    firstName: '',
    lastName: '',
    course: '',
    state: ''
  });
  const [value, setValue] = useState({
    firstName: '',
    lastName: ''
  });
  const [course, setCourse] = React.useState({
    _id: 0,
    name: '',
    shortName: '',
    courseExams: []
  });
  const [state, setState] = React.useState({
    _id: 0,
    name: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const homepage = (e) => {
    window.location.href = '/';
  };
  const navigatelink = (e) => {
    navigate('/SignIn', { state: { IsclassRoom: false } });
  };

  function allCharacter(inputtxt) {
    var regex = /^[a-zA-Z ]{1,30}$/;
    if (inputtxt.match(regex)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input characters only'));
      return false;
    }
  }

  const handleFirstInputText = (e) => {
    //setfname(e);
    if (validateFullName(e)) setfname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleReferInputText = (e) => {
    setReferralCode(e);
  };

  const handleLastInputLnameText = (e) => {
    // setlname(e);
    if (validateFullName(e)) setlname(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };

  const handleLastInputPhoneNo = (e) => {
    setlname(e);
  };

  useEffect(() => {
    // dispatch(
    //   getCourse((dataCourse) => {
    //     setCourseData(dataCourse);
    //   })
    // );

    dispatch(
      getExamList((dataCourse) => {
        setexamData(dataCourse);
      })
    );

    dispatch(
      getState((dataState) => {
        setStateData(dataState);
      })
    );
    dispatch(
      getCountry((datacountry) => {
        setCountryData(datacountry);
      })
    );
  }, []);

  const checkDisable = () => {
    if (fname === '') {
      setError((prevState) => ({
        ...prevState,
        firstName: 'pleaseEnterFirstName'
      }));
      return true;
    }
    if (lname === '') {
      setError((prevState) => ({
        ...prevState,
        lastName: 'pleaseEnterLastName'
      }));

      return true;
    }
    if (parentCourseVal.name.length == 0) {
      setError((prevState) => ({
        ...prevState,
        course: 'pleaseSelectCourse'
      }));
      return true;
    }
    if (countryCode.callingCode === '+91' && statename.name.length == 0) {
      setError((prevState) => ({
        ...prevState,
        state: 'pleaseSelectState'
      }));
      return true;
    }
    // if (error.firstName !== '' || error.lastName !== '' || error.course !== '' || error.state !== '' )
    // return true;
    return false;
  };

  const handleStateName = (value) => {
    setstatename(value);
    dispatch(updateMoreValues({ localState: value }));
  };
  const onPressButton = () => {
    //const { firstName, lastName } = value
    if (!checkDisable() && allCharacter(fname) && allCharacter(lname)) {
      setLoading(true);
      const payload = {
        firstName: fname,
        lastName: lname,
        stateId: statename._id,
        baseCourseId: parentCourseVal._id,
        countryId: countryCode._id,
        mobileNo: mobileNo,
        examTypeId: examName,
      };
      if (subExamName) {
        payload['subExamTypeId'] = subExamName?._id;
      }
      if (referralCode) {
        payload['referredFrom'] = referralCode
      }
      dispatch(
        registerUser(
          payload,
          (response) => {
            setLoading(false);
            if (response?.statusCode === service.status_code.success) {
              const { userData } = response?.data;
              const accessToken = response?.data.jwtToken.access.token;
              const {
                baseCourseId,
                stateId,
                examTypeId,
                examTypeName,
                subExamTypeId,
                subExamTypeName
              } = userData;
              dispatch(
                updateMoreValues({
                  examTypeId: examTypeId,
                  examTypeName: examTypeName
                })
              );
              if (subExamTypeId) {
                dispatch(
                  updateMoreValues({
                    childrenId: subExamTypeId,
                    childrenName: subExamTypeName
                  })
                );
              }
              dispatch(showSuccessSnackbar(response?.msg));
              const newArray = stateData;
              newArray.splice(0, 0, { _id: '', name: 'All India' });
              userData['countryCode'] = countryCode?.callingCode;
              dispatch(updateMoreValues(userData));
              dispatch(
                updateMoreValues({
                  stateData: newArray,
                  ACCESS_TOKEN_NEW: accessToken
                })
              );
              service.setAuthorizationToken(accessToken);
              dispatch(
                getState(
                  (dataState) => {
                    const newArray = dataState;
                    newArray.splice(0, 0, { _id: '', name: 'All India' });
                    dispatch(updateMoreValues({ stateData: newArray }));
                    const index = newArray.findIndex((item) => item._id === stateId);
                    dispatch(updateMoreValues({ stateName: newArray[index].name }));
                  },
                  () => {}
                )
              );
              dispatch(
                examType(
                  { baseCourseId, stateId },
                  (examTypeResponse) => {
                    dispatch(
                      updateMoreValues({
                        examType: examTypeResponse.data.examTypes,
                        talkToExpert: examTypeResponse.data.expertDetails
                      })
                    );
                    // if (examTypeResponse.data.examTypes.length > 0) {
                    //   dispatch(
                    //     updateMoreValues({
                    //       examTypeId: examTypeResponse.data.examTypes[0]._id,
                    //       examTypeName: examTypeResponse.data.examTypes[0].name
                    //     })
                    //   );
                    //   if (examTypeResponse.data.examTypes[0].childrenId) {
                    //     dispatch(
                    //       updateMoreValues({
                    //         childrenName: examTypeResponse.data.examTypes[0].childrenId[0].name,
                    //         childrenId: examTypeResponse.data.examTypes[0].childrenId[0]._id
                    //       })
                    //     );
                    //   }
                    // }
                  },
                  () => {}
                )
              );
              sessionStorage.setItem('ACCESS_TOKEN_NEW', accessToken);
              navigate('/DashboardMenu/Dashboard');
            } else {
              dispatch(showSuccessSnackbar('wentWrong'));
            }
          },
          (error) => {
            setLoading(false);
            if (error) dispatch(showErrorSnackbar(error.msg));
            else dispatch(showErrorSnackbar('wentWrong'));
          }
        )
      );
    } else {
      dispatch(showErrorSnackbar('Please fill above details'));
    }
  };

  useEffect(() => {
    if (
      fname.length != 0 &&
      lname.length != 0 &&
      parentCourseVal._id != undefined &&
      statename._id != undefined
    ) {
      setblank(false);
    } else {
      setblank(true);
    }
  }, [fname, lname, parentCourseVal, statename]);

  const onChangeExam = (val) => {
    setexamName(val?._id);
    if (val?.baseCourseId.length > 0) {
      setCourseData(val?.baseCourseId);
    }
  };
  return (
    <div>
      <div className="d-block d-lg-none loginHeader text-start">
        <img
          src={LocalImages.logo}
          alt="IFAS Logo"
          className="logo cursor"
          onClick={homepage}
          style={{ width: '120px' }}
        />
      </div>
      <div className="row authDiv">
        <div className="d-none d-lg-block col-lg-7 leftSidediv">
          <div className="loginHeader ">
            <img
              src={LocalImages.logo}
              alt="IFAS Logo"
              className="logo cursor"
              onClick={homepage}
              style={{ width: '120px' }}
            />
          </div>
          <div className="imgDiv">
            <img
              src={LocalImages.createAccountimg}
              alt="signIn"
              className="leftImg img-fluid w-75"
            />
          </div>
        </div>
        <div className="col-lg-5 pt-5 rightDivPad position-relative">
          <h3 className="text-center colorblue fw-bold boldfont">Create Your Account</h3>
          <p className="text-center">
            Registration for{' '}
            <span className="colorblue">
              {countryCode.callingCode}-{mobileNo}{' '}
            </span>
          </p>
          <div className="justify-content-center d-flex">
            <p className="text-center me-2">Want to Change</p>
            <span
              className="colorblue text-decoration-underline"
              onClick={navigatelink}
              style={{ cursor: 'pointer' }}>
              <NavLink>Mobile Number</NavLink>
            </span>
          </div>

          <div className="mobileField mt-5">
            <form onSubmit={handleSubmit}>
              <div className="d-flex">
                <div className="me-2">
                  <label className="mb-2">First Name</label>
                  <InputField
                    type="text"
                    id="fname"
                    placeholder="Enter First Name"
                    value={fname}
                    setInputText={handleFirstInputText}
                    maxLength="50"
                  />
                </div>
                <div>
                  <label className="mb-2">Last Name</label>
                  <InputField
                    type="text"
                    id="fname"
                    placeholder="Enter Last Name"
                    value={lname}
                    setInputText={handleLastInputLnameText}
                    maxLength="50"
                  />
                </div>
              </div>
              <div className="mt-3">
                <label className="mb-2 ">Exam Name</label>
                <CustomDropdown
                  setInputText={(value) => onChangeExam(value)}
                  value={examName}
                  lableName="Select Exam Name"
                  options={examData}
                />
              </div>
              {/* {SubExamData && SubExamData?.length>0?
              <div className="mt-3">
              <label className="mb-2 ">Sub Exam Name</label>
              <CustomDropdown
                setInputText={(value) => setsubExamName(value)}
                value={subExamName}
                lableName="Select Sub Exam Name"
                options={SubExamData}
              />
            </div>
            :null
              } */}

              <div className="mt-3">
                <label className="mb-2 ">Subject Name</label>
                <CustomDropdown
                  setInputText={(value) => setparentCourseVal(value)}
                  value={parentCourseVal}
                  lableName="Select Subject Name"
                  options={courseData}
                />
              </div>

              {countryCode.callingCode === '+91' ? (
                <div className="mt-3">
                  <label className="mb-2">State Name</label>
                  <CustomDropdown
                    setInputText={(value) => {
                      handleStateName(value);
                    }}
                    value={statename}
                    lableName="Select State Name"
                    options={stateData}
                  />
                </div>
              ) : (
                ''
              )}
              <div className="mt-3">
                <label className="mb-2">Referral Code</label>
                <InputField
                  type="text"
                  id="rc"
                  placeholder="Enter Referral Code"
                  value={referralCode}
                  setInputText={handleReferInputText}
                  maxLength="50"
                />
              </div>
              <div className="mt-5 text-center">
                <Submitbtn name="Register" disabled={blank} onPress={onPressButton} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAccount;
