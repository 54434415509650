import React from 'react';
import { useSelector } from 'react-redux';
import '../Assets/CSS/bussinessCard.css';
import { LocalImages } from '../Constant';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WebIcon from '@mui/icons-material/Web';
import logo from '../Assets/Images/Home/logo.png';
import InstagramIcon from '@mui/icons-material/Instagram';
  function DigambarSir() {
  return (
    <div className='bussinessCardDiv'>
      <div className='bussinessCard'>
        <div>
        <img
            src={logo}
            alt="IFAS Logo"
            className="bcLogo"
          />
        </div>
        <div>
          <img src={LocalImages.bussinessCardDJsir} alt='Digambar Jagtap' className='bussinesscardImg'/>
        </div>
        <div className='px-4 mt-3'>
          <h3>Digambar Jagtap</h3>
          <h6 className='fw-normal'>Chief Operating Officer</h6>
          <h6 className='fst-italic fw-normal colorblue'>IFAS Edutech PVT Ltd</h6>
        </div>
        <div className='bssubtext'>India's No. 1 Edtech Company for State PSC, Post Graduate & PhD Entrance Examination</div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><MailIcon style={{color:"#ffffff"}}/></span>
            <span><a href="mailto:dj@ifasonline.com" style={{color:"#353535"}}>dj@ifasonline.com</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><CallIcon style={{color:"#ffffff"}}/></span>
            <span><a href="callto:+917588943583" style={{color:"#353535"}}>+91 75889 43583</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><LinkedInIcon style={{color:"#ffffff"}}/></span>
            <span><a href="https://www.linkedin.com/in/digambar-jagtap-b13a5911a" target='_blank' rel="noreferrer" style={{color:"#353535"}}>Digambar Jagtap</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><FacebookIcon style={{color:"#ffffff"}}/></span>
            <span><a href="https://www.facebook.com/digambarjagtap09" target='_blank' rel="noreferrer" style={{color:"#353535"}}>IFAS DJ</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><InstagramIcon style={{color:"#ffffff"}}/></span>
            <span><a href="https://instagram.com/the_dj_sir?igshid=MzRlODBiNWFlZA==" target='_blank' rel="noreferrer" style={{color:"#353535"}}>the_dj_sir</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><WebIcon style={{color:"#ffffff"}}/></span>
            <span><a href="https://ifasonline.com/" target='_blank' rel="noreferrer" style={{color:"#353535"}}>ifasonline.com/  </a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><WhatsAppIcon style={{color:"#ffffff"}}/></span>
            <span><a href="https://api.whatsapp.com/send?phone=75889 43583" target='_blank'rel="noreferrer" style={{color:"#353535"}}>75889 43583</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><YouTubeIcon style={{color:"#ffffff"}}/></span>
            <span><a href="https://www.youtube.com/@IFASONLINE" target='_blank' rel="noreferrer" style={{color:"#353535"}}>IFASONLINE</a></span>
        </div>
        <div className='d-flex  bslinkcard'>
            <span><span className='bcicon'><LocationOnIcon style={{color:"#ffffff"}}/></span></span>
            <span><a href="#" style={{color:"#353535"}}>1st floor, ISH Infotech, Phase 1, Hinjewadi Rajiv Gandhi Infotech Park, Hinjewadi, Pimpri-Chinchwad, Maharashtra 411057</a></span>
        </div>
      </div>
    </div>
  );
};

export default DigambarSir;
