import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import live from '../Assets/Images/Learn/livesession.json';
import { LocalImages } from '../Constant';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import {
  getBanner,
  getLiveLecturesForFree,
  startEndLiveStatus,
  updateHomeValues
} from '../Redux/Actions/homeAction';
import { updateMoreValues } from '../Redux/Actions/AuthAction';
import BbbVideo from './bbbVideo';
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import LectureCard from '../Component/lectureCard';
import MoreBtn from '../Component/moreBtn';
import PaidUser from './paidUser';
import BannerCard from '../Module/bannerCard';

function Learn() {
  const {
    examTypeName,
    examTypeId,
    baseCourseId,
    childrenId,
    liveVideoData,
    userId,
    upcoming,
    hasActivePaidPlan,
    banner
  } = useSelector((state) => ({
    examTypeName: state?.AuthReducer?.examTypeName,
    baseCourseId: state?.AuthReducer?.baseCourseId,
    examTypeId: state?.AuthReducer?.examTypeId,
    childrenId: state?.AuthReducer?.childrenId,
    liveVideoData: state?.AuthReducer?.liveVideoData,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
    userId: state?.AuthReducer?._id,
    upcoming: state?.HomeReducer?.upcoming,
    banner: state?.HomeReducer?.banner
  }));
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [openVideo, setopenVideo] = useState(false);
  const [resultBanner, setResultBanner] = useState([]);

  const handleBbbVideoClose = () => {
    setopenVideo(false);
  };

  const handleBbbVideoClick = () => {
    setopenVideo(true);
  };

  const upcomingArray = [];
  for (let i = 0; i < 3; i++) {
    upcomingArray.push(upcoming[i]);
  }

  const allClassroute = () => {
    navigate(hasActivePaidPlan ? '/PaidUser/LiveClass' : '/DashboardMenu/LiveClass', {
      state: { upcoming: upcoming }
    });
  };

  const LectureScheduleRoute = () => {
    if (examTypeId) {
      if (!liveVideoData?.isRTMPEnvironment && liveVideoData?.status === 2) {
        handleBbbVideoClick();
      } else if (liveVideoData?.isRTMPEnvironment && liveVideoData?.status === 2) {
        dispatch(
          startEndLiveStatus({
            userId: userId,
            liveId: liveVideoData.liveId,
            liveType: 1,
            isLive: true
          })
        );
        window.open(
          '/MediaPlayer',
          '_blank',
          'width=' + screen.availWidth + ',height=' + screen.availHeight
        );
      } else {
        if (
          moment.utc(liveVideoData?.sessionTime, 'hh:mm:ss').format('LT') <
          moment(today).format('LT')
        )
          setOpen(true);
        else
          navigate(
            hasActivePaidPlan
              ? '/PaidUser/LectureScheduleFree'
              : '/DashboardMenu/LectureScheduleFree',
            { state: { courseId: '' } }
          );
      }
    }
  };

  const onlineTestRoute = () => {
    if (examTypeId){
      navigate(hasActivePaidPlan ? '/PaidUser/FreeOnlineTest' : '/DashboardMenu/OnlineTestFree')
    }
  };
  const lectureRoute = () => {
    if (examTypeId) navigate(hasActivePaidPlan ? '/PaidUser/Lectures' : '/DashboardMenu/Lectures');
  };
  const allAboutRoute = () => {
    if (examTypeId)
      navigate(hasActivePaidPlan ? '/PaidUser/FreeAllAbout' : '/DashboardMenu/AllAbout');
  };

  const today = new Date();

  useEffect(() => {
    const requestData = {
      baseCourseId,
      examTypeId,
      date: moment(today).format('YYYY-MM-DD'),
      subExamTypeId: childrenId
    };
    if (childrenId === '') delete requestData['subExamTypeId'];
    dispatch(
      getLiveLecturesForFree(
        requestData,
        (data) => {
          if (data === null) dispatch(updateMoreValues({ liveVideoData: {} }));
          else dispatch(updateMoreValues({ liveVideoData: { ...data, liveType: 3 } }));
        },
        () => {}
      )
    );

    if (banner?.length > 0) {
      let temp = banner?.filter((item) => item?.bannerTypeId?.type.toUpperCase() === 'RESULTS');
      setResultBanner(temp);
    }
  }, [examTypeId, childrenId]);

  const onBannerClick = (item, index) => {
    if (item?.bannerTypeId?.type.toUpperCase() === 'RESULTS') {
      navigate('/PaidUser/Results');
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: live,
    renderer: 'svg'
  };
  return (
    <div className="rightPanel">
      <div>
        <h3>Learn</h3>
      </div>
      <div className="mt-4">
        <div className="row d-flex justify-content-between width75">
          <div
            className="col-sm-5 row boxshadow rounded p-2 p-md-4 mb-4 align-items-center cursor bgwhite"
            onClick={LectureScheduleRoute}>
            {liveVideoData?.status === 2 ? (
              <div className="col-9">
                <p className="fw-bold mb-2">Live Session</p>
                <p className="mb-0 colorblue font12">Join session now</p>
              </div>
            ) : liveVideoData?.status === 1 ? (
              <div className="col-9">
                <p className="fw-bold mb-2">Live Session</p>
                <p className="mb-0 colorblue font12">
                  {moment.utc(liveVideoData?.sessionTime, 'hh:mm:ss').format('LT') <
                  moment(new Date()).format('LT')
                    ? `Live session starting soon`
                    : `Live Start at ${liveVideoData?.fromDate} ${moment
                        .utc(liveVideoData?.sessionTime, 'hh:mm:ss')
                        .format('LT')}`}
                </p>
              </div>
            ) : (
              <div className="col-9">
                <p className="fw-bold mb-2">Live Session</p>
                <p className="mb-0 colorblue font12">no live session available</p>
              </div>
            )}
            <div className="col-3">
              <Lottie options={defaultOptions} height={60} width={60} />
            </div>
          </div>
          <div
            className="col-12 col-sm-5 row boxshadow rounded p-2 p-md-4  mb-4 align-items-center cursor bgwhite "
            onClick={lectureRoute}>
            <div className="col-9">
              <p className="fw-bold mb-2 active">Lecture</p>
            </div>
            <div className="col-3">
              <img src={LocalImages.lecture} alt="Lecture" />
            </div>
          </div>
          <div
            className="col-12 col-sm-5 row boxshadow rounded p-2 p-md-4 mb-4 align-items-center cursor bgwhite"
            onClick={onlineTestRoute}>
            <div className="col-9">
              <p className="fw-bold mb-2">Online Test</p>
            </div>
            <div className="col-3">
              <img src={LocalImages.onlineTest} alt="onlineTest" />
            </div>
          </div>
          <div
            className="col-12 col-sm-5 row boxshadow rounded p-2 p-md-4  mb-4 align-items-center cursor bgwhite"
            onClick={allAboutRoute}>
            <div className="col-9">
              <p className="fw-bold mb-2">All About {examTypeName}</p>
            </div>
            <div className="col-3">
              <img src={LocalImages.allAbout} alt="allAbout" />
            </div>
          </div>
          {resultBanner?.map((item, index) => {
            return (
              <div className="boxshadow rounded bannerTilesBox bgwhite cursor px-0">
                <div className="d-flex justify-content-between">
                  <div className="bannerInnerDiv" onClick={() => onBannerClick(item, index)}>
                    <img
                      className="bannerImg"
                      src={item?.file}
                      //src={LocalImages?.dummyBaner}
                      alt="Course Logo"
                    />
                  </div>
                </div>
              </div>
            );
          })}

          {/* <BannerCard /> */}
        </div>
      </div>

      {upcoming != '' ? (
        <div className="boxshadow liveclassdiv bgwhite">
          <div className="customcontainerlearn py-4">
            <div className="d-flex justify-content-between">
              <h3 className="fw-bold  ms-4 boldfont">Live Classes</h3>
              <MoreBtn gotoLink={allClassroute} />
            </div>
            <div className="mt-4">
              <div className="row">
                {upcomingArray &&
                  upcomingArray.map((c, index) =>
                    c !== undefined ? <LectureCard key={index.toString()} data={c} /> : null
                  )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <BbbVideo open={openVideo} handleClose={handleBbbVideoClose} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="modeldiv p-md-4 py-md-4 talktoexpertModel">
            <div className="m-2 insttxtDiv bgwhite">
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon className="colorblue cursor" onClick={() => setOpen(false)} />
              </div>
              <div className="col justify-content-center  py-5 ">
                <Lottie options={defaultOptions} height={100} width={100}></Lottie>
                <p className="text-center">
                  <b>Please wait for a while , lecture will start soon</b>
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Learn;
