import React, { useState, useEffect } from 'react';
import '../App.css';
import { LocalImages } from '../Constant';
import AddNewAddress from '../Module/addNewAddress';
import Lottie from 'react-lottie';
import NoAddress from '../Assets/Images/JSON/no_address.json';
import EditAddress from '../Module/editAddress';
import { useDispatch, useSelector } from 'react-redux';
import { getAddressList, setDefaultRemoveAddress } from '../Redux/Actions/homeAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';
function MyAddress() {
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState('');
  const { addressList, address } = useSelector((state) => ({
    addressList: state?.HomeReducer?.addressList,
    address: state?.HomeReducer?.address
  }));

  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };
  const [edit, edited] = useState(false);
  const editAddress = (id) => {
    edited(true);
    setEditId(id);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: NoAddress,
    renderer: 'svg'
  };

  const handleRemove = (e, id) => {
    const isRemove = true;
    dispatch(
      setDefaultRemoveAddress(
        isRemove,
        {
          addressId: id
        },
        () => {
          AddressRefresh();
        },
        () => {}
      )
    );
  };

  const AddressRefresh = () => {
    dispatch(
      getAddressList(
        () => {},
        () => {}
      )
    );
  };

  const handleIsDefault = (id) => {
    dispatch(
      setDefaultRemoveAddress(
        false,
        {
          addressId: id
        },
        () => {},
        () => {}
      )
    );
  };

  useEffect(() => {
    AddressRefresh();
  }, []);

  return (
    <div className="rightPanel">
      <div className="row">
        <div className="col">
          <h3>Addresses</h3>
        </div>
        {addressList.length !== 0 ? (
          <div className="col d-flex justify-content-end py-1">
            <button
              type="button"
              className="btn btn-primary py-1 font12 bgwhite me-md-5"
              onClick={handleOpen}>
              Add New Address
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="row Addressleftside py-2">
        {addressList?.map((c,index) => {
          return (
            <div key={index.toString()} className="col-md-6 col-xl-5 pe-xl-5 pt-1 text-break addressouterbox">
              <div className="boxshadow p-3 py-2 rounded mt-3 bgwhite addressinnerBox " >
                <div className='editdeletediv'>
                <div className="d-flex justify-content-between">
                  <div className="col-7">
                    {c?.isDefault ? (
                      <div className="colorblue mb-2 fw-bold d-flex">
                        <form>
                          <LocationOnIcon
                            style={{ width: '15px' }}
                            className="colorblue cursor"
                            value={c._id}
                            defaultChecked={
                              address?._id === c._id ? 'checked colorblue' : undefined
                            }
                          />
                        </form>
                        <span className="ms-3">{c.label}</span>
                      </div>
                    ) : (
                      <div className=" text-secondary mb-2 fw-bold d-flex">
                        <form>
                          <LocationOnIcon
                            style={{ width: '15px' }}
                            className=" text-secondary cursor"
                            value={c._id}
                            defaultChecked={address?._id === c._id ? 'checked ' : undefined}
                          />
                        </form>
                        <span className="ms-3">{c.label}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-4">
                    {c?.isDefault ? (
                      <button
                        className="btn btn-outline-light font12 px-2 cursor float-end"
                        style={{ color: 'var(--blue)' }}
                        disabled>
                        Default Address
                      </button>
                    ) : (
                      <button
                        className="btn text-secondary btn-outline-light font12 px-2 cursor float-end"
                        onClick={() => handleIsDefault(c?._id)}>
                        Make It Default
                      </button>
                    )}
                  </div>
                </div>
                <div className="mb-2 font12  ">
                  {/* <p className="mb-0 fw-bold">{firstName} {lastName}</p> */}
                  <p className="mb-0 fw-bold">{c.fullName}</p>
                  <p className="mb-0">{c?.line1},</p>
                  <p className="mb-0">{c?.line2}</p>
                  <p className="mb-0">{c?.landmark} </p>
                  <p className="mb-0">
                    {c?.city?.name}, {c?.pincode}
                  </p>
                  {c?.alternatePhoneNumber?<p className="mb-0">Phone Number: +91- {c?.alternatePhoneNumber}</p>:""}
                </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <div
                    className="border d-inline font12 px-2 py-1 mt-2 rounded cursor"
                    onClick={() => editAddress(c._id)}>
                    <img src={LocalImages.edit} alt="Edit" style={{ width: '13px' }} />
                    <span className="ms-2 rounded">Edit</span>
                  </div>
                  <div
                    className="redtxt px-4 py-0 rounded d-flex justify-content-center align-items-center font12 cursor"
                    onClick={(e) => handleRemove(e, c?._id)}>
                    <img src={LocalImages.deleteimg} alt="Delete" className="me-2 cursor" />
                    Remove
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {addressList?.length === 0 ? (
          <div className="row mt-5 bgwhite">
            <div className="col justify-content-center shadow-sm pt-2 pb-5">
              <Lottie options={defaultOptions} height={130} width={200}></Lottie>
              <p className="text-center">
                <b>No Address Found</b>
              </p>
              <p className="text-center">Please add new address</p>
              <div className="d-grid d-md-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary py-2 px-5 font12 me-md-1"
                  onClick={handleOpen}>
                  Add New Address
                </button>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {open ? <AddNewAddress open={setOpen} /> : null}
      {edit ? <EditAddress edit={edited} open={setOpen} editId={editId} /> : null}
    </div>
  );
}

export default MyAddress;
