import React, { useEffect, useState } from 'react';
import '../App.css';
import Header from '../Module/header';
import RecommendedCard from '../Module/recommendedCard';
import CartCard from '../Module/cartCard';
import CouponCard from '../Module/couponCard';
import CourseCard from '../Module/courseCard';
import {
  addToCart,
  getCartList,
  removeToCart,
  getAddOnList,
  getRecentlyCartList
} from '../Redux/Actions/homeAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import CartEmpty from '../Assets/Images/JSON/cart_empty.json';

function MyCart() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CartEmpty,
    renderer: 'svg'
  };
  const addressroute = () => {
    navigate('/Address');
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var price = 0;

  const [showInputCoupon, setShowInputCoupon] = useState(false);
  const [cartID, setCartID] = useState('');
  const { addon, cartListDetails, recentView, hasActivePaidPlan } = useSelector((state) => ({
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
    addon: state?.HomeReducer?.addon,
    recentView: state?.HomeReducer?.recentView,
    cartListDetails: state?.HomeReducer?.cartListDetails
  }));

  useEffect(() => {
    dispatch(
      getCartList((data) => {
        if (data.length > 0) dispatch(getAddOnList());
        else dispatch(getRecentlyCartList());
      }, false)
    );
  }, []);

  const setOnRemove = (id) => {
    //dispatch(removeToCart({ cartId: id }));
    //window.location.reload();

    dispatch(removeToCart({ cartId: id }));
  };

  for (let i = 0; i < cartListDetails?.length; i++) {
    if (cartListDetails[i]?.discPer) price = price + cartListDetails[i]?.discountedPrice;
    else price = price + cartListDetails[i]?.price;
  }

  const handleAddOns = (data) => {
    const postData = {
      sectionType: data.sectionType,
      courseId: data?.courseId,
      packageSectionId: data?.packageSectionId,
      subscriptionPlanId: data?.subscriptionPlanId
    };
    if (data?.sessions?.length > 0) {
      postData['sessionId'] = data?.sessions[0]?._id;
    }
    dispatch(
      addToCart(
        {
          package: [postData]
        },
        () => {
          dispatch(
            getCartList(
              () => {},
              () => {}
            ),
            true
          );
          dispatch(getAddOnList());
        },
        () => {}
      )
    );
  };

  const handleRecentView = (data) => {
    const postData = {
      sectionType: data.sectionType,
      courseId: data?.courseId,
      packageSectionId: data?.packageSectionId,
      subscriptionPlanId: data?.subscriptionPlanId
    };
    if (data?.sessions?.length > 0) {
      postData['sessionId'] = data?.sessions[0]?._id;
    }
    dispatch(
      addToCart(
        {
          package: [postData]
        },
        () => {
          dispatch(
            getCartList(
              () => {},
              () => {}
            ),
            true
          );

          dispatch(getRecentlyCartList());
        },
        () => {}
      )
    );
  };

  const handleAddMore = () => {
    if (hasActivePaidPlan) navigate('/PaidUser/OnlineCourse');
    else navigate('/DashboardMenu/OnlineCourse');
  };
  return (
    <div>
      <Header />
      <div className="py-4 backimg px-2">
        <div className="customcontainer">
          <h3 className="fw-normal">My Cart</h3>
          {cartListDetails?.length > 0 ? (
            <div className="row">
              {cartListDetails?.length > 0 ? (
                <div className="col-md-6 checkoutHeight pe-xl-5 mt-3 order-2 order-md-1 padbottom-mob40">
                  {addon && addon?.length != 0 ? (
                    <p className="font-12 fw-bold sticky-top bgwhite">Recommended Add-on</p>
                  ) : (
                    ''
                  )}

                  {addon &&
                    addon?.map((c, index) => (
                      <RecommendedCard data={c} handleAddOns={handleAddOns} key={index} />
                    ))}
                </div>
              ) : null}
              <div className="col-md-6  order-1 order-md-2">
                <div className="mb-3 checkoutHeight p-md-3">
                  {cartListDetails?.length !== 0
                    ? cartListDetails?.map((c, index) => (
                        <CartCard
                          key={index}
                          data={c}
                          setOnRemove={setOnRemove}
                          setShowInputCoupon={setShowInputCoupon}
                          setCartID={setCartID}
                        />
                      ))
                    : ''}
                  {cartListDetails?.length !== 0 ? (
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-primary px-4"
                        style={{ fontSize: '12px' }}
                        onClick={handleAddMore}>
                        Add More
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {cartListDetails?.length !== 0 ? (
                  <CouponCard
                    btnname="Checkout"
                    setOnClick={addressroute}
                    hideInput={showInputCoupon}
                    Price={price}
                    itemCount={cartListDetails?.length}
                    cartID={cartID}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="boxshadow rounded customcontainer bgwhite w-78 mt-2">
                <div className="p-5 pb-0 pt-3 text-center">
                  <Lottie
                    options={defaultOptions}
                    height={130}
                    width={200}
                    style={{ cursor: 'default' }}></Lottie>
                  <p className="text-center font15">
                    <b>Your Cart bag is empty</b>
                  </p>
                  <p className="text-center font12 mb-0">
                    Explore our ever-growing selection of courses{' '}
                  </p>
                  <p className="text-center font12 ">and exciting new offers</p>
                  <button type="button" className="btn btn-primary" onClick={handleAddMore}>
                    Browse Courses
                  </button>
                </div>

                <div className="tab-content" id="pills-tabContent">
                  <div className="row ms-md-5">
                    {recentView.length > 0 ? (
                      <p className="font-12 fw-bold mt-5 colorblue">Recently Viewed</p>
                    ) : (
                      ''
                    )}
                    {recentView &&
                      recentView?.map((c, index) => (
                        <div
                          key={index.toString()}
                          className="col-sm-5 col-lg-4 col-xl-3 mx-2 mx-lg-3 mb-3">
                          <CourseCard
                            data={c}
                            handleRecentView={handleRecentView}
                            path={c?.bannerImage}
                            course={c?.name}
                            batch={c?.startDate}
                            courseType={c?.type}
                            courseID={c?._id}
                            baseCourseId={c?.baseCourseId}
                            examTypeId={c?.examTypeId}
                            subExamTypeId={c?.subExamTypeId}
                            sectionType={c?.sectionType}
                            courseDuration={c?.duration}
                            testCount={c?.testCount}
                            timeType={c?.timeType}
                            bookCount={c?.bookCount}
                            language={c?.language.icon}
                            classType={c?.classType}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyCart;
