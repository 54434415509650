import React from 'react';
function Submitbtn(props) {
  return (
    <button
      type="submit"
      className={
        props.disabled
          ? 'btn btn-secondary mb-3 submitbtn rounded w-100 py-2'
          : 'btn btn-primary mb-3 submitbtn rounded w-100 py-2'
      }
      disabled={props.disabled}
      onClick={props.onPress}>
      {props.name}
    </button>
  );
}

export default Submitbtn;
