import React, { useState, useEffect } from 'react';
import '../App.css';
import Header from '../Module/header';
import Lottie from 'react-lottie';
import Nonotification from '../Assets/Images/JSON/no-notification.json';
import { useNavigate } from 'react-router-dom';
import { getNotificationList, getNotificationUnread } from '../Redux/Actions/homeAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { updateHomeValues } from '../Redux/Actions/homeAction';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import NotificationPopup from '../Module/notificationPopup';

function Notification(id) {
  const [data, setData] = useState([]);
  const [notificationData, setNotificationData] = useState()

  const dispatch = useDispatch();
  const [open, setopen] = useState(false);
  const [dataCard, setDataCard] = useState({})
  const { paidTiles, hasActivePaidPlan } = useSelector((state) => ({
    paidTiles: state?.HomeReducer?.paidTiles,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan
  }));

  const tabs = hasActivePaidPlan ? [
    { title: 'Class Schedule', name: 'classSchedule', id: 1 },
    { title: 'Test Series', name: 'testSeries', id: 2 },
    { title: 'Others', name: 'others', id: 3 }]
    : [{ title: 'New', name: 'free', id: 4 }]
    ;
  const [badgeData, setBadgeData] = useState({
    classSchedule: 0,
    others: 0,
    testSeries: 0,
    free: 0,
  });
  const [selectedIndex, setSelectedIndex] = useState(hasActivePaidPlan ? {
    id: 1,
    title: 'Class Schedule',
    name: 'classSchedule'
  } : {
    title: 'Free', name: 'free', id: 4
  });
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Nonotification,
    renderer: 'svg'
  };

  const handleClose = () => {
    setopen(false);
  };

  useEffect(() => {
    handleClickNew(tabs[0]);
  }, []);

  const onCardpress = (dataCard) => {
    setNotificationData(dataCard)
    if (dataCard.notificationType === 3 || dataCard.notificationType === 4) {
      setopen(true);
    } else {
      if (hasActivePaidPlan === true) {
        const index = paidTiles.findIndex((item) => item.courseId === dataCard.courseId);
        if (index > -1) {
          setDataCard(paidTiles[index])
          setopen(true);
        } else
          dispatch(
            showErrorSnackbar(
              'Your subscription has been expired. You are not able to access this.'
            )
          );
      } else
        dispatch(
          showErrorSnackbar('Your subscription has been expired. You are not able to access this.')
        );
    }
  };

  const handleClickNew = (c) => {
    setSelectedIndex(c);
    setData([]);
    dispatch(
      getNotificationList(
        c.id,
        (data) => {
          const groups = data[c.name]?.reduce((groups, key) => {
            const notificationDate = moment(key.notificationDate, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format('DD MMMM YYYY');
            if (!groups[notificationDate]) {
              groups[notificationDate] = [];
            }
            groups[notificationDate].push(key);
            return groups;
          }, {});
          if (groups) {
            let tempArr = [];

            Object?.keys(groups)?.map((notificationDate) => {
              tempArr.push(groups[notificationDate]);
              setData(tempArr);
              setNotificationData(tempArr[0])
            });
          }
          dispatch(getNotificationUnread());
          setBadgeData(data.unreadCount);
        },
        () => {
          dispatch(getNotificationUnread());
        }
      )
    );
  };

  return (
    <div>
      <Header />
      <div className="backimg privacy">
        <div className="customcontainer py-5 px-4">
          <div className="">
            <div className="">
              <h3 className="me-5 mb-3">Notification</h3>
              <div className="align-items-center  border-2 mb-4">
                {hasActivePaidPlan && <ul className="nav nav-pills p-2 px-3 boxshadow bgwhite">
                  {tabs?.map((c) => (
                    <li className="nav-item">
                      <a
                        className={`btn ${c.id === selectedIndex.id ? 'btn-primary' : 'bg-light'
                          } btn-sm position-relative me-3 text-capitalize`}
                        href="#"
                        role="button"
                        onClick={() => handleClickNew(c)}>
                        {' '}
                        {c.title}
                        {c.id === selectedIndex.id ? (
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                            {badgeData[c.name] > 0 ? badgeData[c.name] : ''}
                          </span>
                        ) : (
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
                            {badgeData[c.name] > 0 ? badgeData[c.name] : ''}
                          </span>
                        )}
                      </a>
                    </li>
                  ))}
                </ul>}
              </div>
            </div>

            <div className="bgwhite boxshadow p-2 notificationShow">
              {data.length !== 0 ? (
                data.map((item) => {
                  const notificationDateNew = moment(item[0]?.notificationDate, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format('DD MMMM YYYY');
                  return (
                    <div className="mb-2">
                      {moment().diff(notificationDateNew, 'days') == 0 ? (
                        <p className="font-12 m-2 text-secondary">
                          <b>TODAY</b>
                        </p>
                      ) : moment().diff(notificationDateNew, 'days') == 1 ? (
                        <p className="font-12 m-2 text-secondary">
                          <b>YESTERDAY</b>
                        </p>
                      ) : (
                        <p className="font-12 m-2 text-secondary">
                          <b>{moment(notificationDateNew).format('DD MMMM YYYY')}</b>
                        </p>
                      )}

                      {item?.map((c) => (
                        <div
                          className=" row align-items-center border-bottom border-2 p-1 cursor"
                          onClick={() => onCardpress(c)}>
                          <div className="col-2 col-sm-1 justify-centent-end">
                            <img src={c.icon} alt="icon" className="notiicon" />
                          </div>
                          <div className="col-7 col-sm-8 ">
                            <p className=" successcolor font15 m-0 ellipsisNotificationTitle">{c.title}</p>
                            {/* <p className=" font-12 m-0">
                         {c.notificationType}
                      </p> */}

                            <p className=" font-12 m-0 ellipsisNotification text-break">{c.description}</p>

                          </div>
                          <div className="col-3">
                            <p className="font10 text-secondary">{`${moment(c.notificationDate, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format('h:mm a')}`}</p>
                          </div>
                        </div>
                      ))}
                      <NotificationPopup
                        open={open}
                        handleClose={handleClose}
                        title={notificationData?.title}
                        dataCard={dataCard}
                        icon={notificationData?.icon}
                        id={notificationData?.notificationType}
                        description={notificationData?.description}
                        link={notificationData?.link ? notificationData?.link : ''}
                      />
                    </div>
                  )
                })
              ) : (
                <div className="px-2">
                  <Lottie options={defaultOptions} height={100} width={100}></Lottie>
                  <p className="text-center font15">
                    <b>No New Notification</b>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
