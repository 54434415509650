import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { getAddressList, setDefaultRemoveAddress, updateHomeValues } from '../Redux/Actions/homeAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function ChangeAddress(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  const { addressList, address } = useSelector((state) => ({
    addressList: state?.HomeReducer?.addressList,
    address: state?.HomeReducer?.address
  }));

  const handleClose = () => {
    setOpen(false);
    props.open(false);
  };

  const AddressRefresh = () => {
    dispatch(
      getAddressList(
        () => {},
        () => {}
      )
    );
  };

  const handleIsDefault = (id) => {
    setOpen(false);
    dispatch(
      setDefaultRemoveAddress(
        false,
        {
          addressId: id
        },
        () => {},
        () => {}
      )
    );
  };

  const handleClick = (singleAddress) =>{
    dispatch(updateHomeValues({'address': singleAddress}))
    props.handleClose()
  }
  useEffect(() => {
    AddressRefresh();
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={props.open}>
        <div className="Changeadresspopup">
          <h6>Select Address</h6>
          <div className="row">
            {addressList?.map((c, index) => {
              return (
                <div key={index.toString()} className="col-md-6 col-xl-6 pe-xl-6 pt-1 text-break addressouterbox ">
                  <div onClick={()=>{handleClick(c)}}
                    className={
                      props?.addressId===c?._id
                        ? 'boxshadow p-3 py-2 rounded mt-3 bgwhite border border-primary cursor addressinnerBox'
                        : 'boxshadow p-3 py-2 rounded mt-3 bgwhite cursor addressinnerBox'
                    }>
                    <div className="d-flex justify-content-between ">
                      <div className="col-7 ">
                        {c?.isDefault ? (
                          <div className="colorblue mb-2 fw-bold d-flex">
                            <form>
                              <LocationOnIcon
                                style={{ width: '15px' }}
                                className="colorblue cursor"
                                value={c._id}
                                defaultChecked={
                                  address?._id === c._id ? 'checked colorblue' : undefined
                                }
                              />
                            </form>
                            <span className="ms-3">{c.label}</span>
                          </div>
                        ) : (
                          <div className=" text-secondary mb-2 fw-bold d-flex">
                            <form>
                              <LocationOnIcon
                                style={{ width: '15px' }}
                                className=" text-secondary cursor"
                                value={c._id}
                                defaultChecked={address?._id === c._id ? 'checked ' : undefined}
                              />
                            </form>
                            <span className="ms-3">{c.label}</span>
                          </div>
                        )}
                      </div>
                      <div className="col-4">
                        {c?.isDefault ? (
                          <button
                            className="btn btn-outline-light font12 px-2 cursor float-end"
                            style={{ color: 'var(--blue)' }}
                            disabled>
                            Default Address
                          </button>
                        ) : (
                          <button
                            className="btn text-secondary btn-outline-light font12 px-2 cursor float-end"
                            onClick={() => handleIsDefault(c?._id)}>
                            Make It Default
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="mb-2 font12">
                      {/* <p className="mb-0 fw-bold">{firstName} {lastName}</p> */}
                      <p className="mb-0 fw-bold">{c.fullName}</p>
                      <p className="mb-0">{c?.line1},</p>
                      <p className="mb-0">{c?.line2}</p>
                      <p className="mb-0">{c?.landmark} </p>
                      <p className="mb-0">
                        {c?.city?.name}, {c?.pincode}
                      </p>
                      {c?.alternatePhoneNumber?<p className="mb-0">Phone Number: +91- {c?.alternatePhoneNumber}</p>:""}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default ChangeAddress;
