import React from 'react';

function ButtonPrimary(props) {
  return (
    <button
      type="button"
      className="btn btn-primary submitbtn rounded w-100 px-2 px-sm-4 py-1 font12 "
      onClick={props.gotoLink}
      disabled={props.disabled ? true : false}
    >
      {props.name}
    </button>
  );
}

export default ButtonPrimary;
