import endpoint from '../../Constant/endpoint';
import services from '../../Constant/service';
import { SET, RESET_STORE, LOADER } from '../../Types/authTypes';
import { updateHomeMoreValues } from './homeAction';
import { showErrorSnackbar, showSuccessSnackbar } from './snackbarAction';

export const setInitialState = () => async (dispatch) => {
  dispatch({
    type: RESET_STORE
  });
};

export const updateMoreValues = (values) => (dispatch) => {
  dispatch({
    type: SET,
    payload: values
  });
};

export const setParentCourse = (key, value) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      [key]: value
    }
  });
};
export const examType = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.exam.examType,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) {
        dispatch(updateMoreValues({ examTypeDataLocal: res?.data?.examTypes }));
        callback(res);
      } else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getCountry = (callback, errorCallback) => async (dispatch, getState) => {
  const { countryList } = getState().AuthReducer;
  if (countryList?.length > 0) callback(countryList);
  else {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.auth.country,
      '',
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.status === services.status_code.success) {
          dispatch({
            type: SET,
            payload: {
              countryList: res?.data?.data
            }
          });
          callback(res?.data?.data);
        } else {
          dispatch({
            type: SET,
            payload: {
              countryList: [
                {
                  _id: '625fd32730b905c976c61d55',
                  name: 'INDIA',
                  callingCode: '+91',
                  flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAMAAABpA6zvAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAqZQTFRF53MA5HIC43ID53MB9XcA+3oA9ngA53QB83cA6XMCpV8oX0hPR0RkSUVlXkdPpF8o53EA428B9ncArmIkL0OAG02vZo3TfJ3agqLcZYzTJFSzLkJ/6X0R6X0S5XsS+IQOl2E+BDqjgJ7X5eny5enz5uv05er05OjyjancCD6llmE+/fXu+/Ps///zvMPUCDefqbre8fP32ODwyNPpwMzmwc3mxNDo1N3u8fL3v8znEj+kucHS/////v//NVuwf5bK9/n7vcrkma3WgZnMf5fLlKnUusjjzdfr+Pn7iZ/OOmCz/f7///7+/Pz9tcPgGUWh4efz2eDwvMrkf5nMXH2+aojDa4jEW3y9gJnMuMbi6u/3Jk+ms8Hf+/z9aofDXn2+9ff70Nrsm6/XXHy+jKPRxNDnxdHol6vVWXq8kqfTz9ns9vj7co3Ga4fD/P3+UHK5dZDI8fT5w8/ngpvNaIbDxM/nvMnku8jkcIzGfZfLws7m8/b6iaDQU3W6dI/H8vT6aIbCu8nkucfjydTpepXK9Pb6UnS67vL4ztfrmKzWWnu9kKbTmK3WW3u9kqjTy9Xq8PP5cY3GbIjD/v/+GEWh4ujz1t7ugZrNbInEfJbL7fH4JlCns8LgOVuxfpbKzNbqlqrUfpbLe5TKytTqj6XRPWCz/v7/8fju7vXt/v/zssXUCTigs8Hi8fT309zuxtHo0Nns7vL2wMznFECkr8LT///0QJkUQJoUPpcVSKIRKHVADDqki6DY4+ny4ujy4Oby4ejyl6ndEj+mJ3Q/LpAAL5AALI4BNZgAJHomD0iAJk6xd47Uip7bjqHceI7VLlW0LY4BM5IEM5MEMZEFNZcAMpMDIXUqDFZQDE9lDlBmDFVQMpIDNpgAOJsANpkAOZsAMZEGMpEFwJ5XlQAAAAFiS0dEPKdqYc8AAAAJcEhZcwAAAEgAAABIAEbJaz4AAAGtSURBVDjLY2AY1oCRiRGICCtjZmFlY2NlYcZQyo4CGDk4ubh5ePn4OTkYUWUYBJCBoJCwiKiYuISklLSwkCCKFIMMAsjKySsoKimrqKqpq2toasnLySJJMmgjgI6unr6BoZGxiamZuYWllbWuDpIkgw0c2NrY2tk7mDk6Obu4url7eHp5A4XggMEHDnxt/PwDAoOCQ0LDwiMi3aOiY2x8EbJIJsbaxMUnJCYlp6SmpWdkZmXn5AKFECYimHk2+QWFRcUlpWXlxhWVVdU1tUAhHArr6k0jGooam5orWkxaa9pwKIy1yY1v7+js6k4t6unt658wcRKK1ZPhINbGb8rUaeXTw0NndM1sMp81e45NLEIWJXhs5s5zmF+2YOGivsjFDkuWLkMJnuUIsGLlqtVr1q5bXxZUumHjps1btq5AkmTYhgDbd+zctXvP3n1T9x/Ye/DQ4Z07tiNJMhxBAkePHT9x8tTpM2fPnT954viFo8hyDBeRwKVLl69cvXb9xs1b125fuXzpErIcw21UcPn2nbv37t2/A2SgAnSFQKUPHj58cBlDGFMhDjAUFAIALMfjyKVz+egAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTMtMTAtMDdUMTM6MTQ6MzQrMDI6MDDj9ijFAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDEzLTEwLTA3VDEzOjE0OjM0KzAyOjAwkquQeQAAAABJRU5ErkJggg=='
                }
              ]
            }
          });
          callback([
            {
              _id: '625fd32730b905c976c61d55',
              name: 'INDIA',
              callingCode: '+91',
              flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAMAAABpA6zvAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAqZQTFRF53MA5HIC43ID53MB9XcA+3oA9ngA53QB83cA6XMCpV8oX0hPR0RkSUVlXkdPpF8o53EA428B9ncArmIkL0OAG02vZo3TfJ3agqLcZYzTJFSzLkJ/6X0R6X0S5XsS+IQOl2E+BDqjgJ7X5eny5enz5uv05er05OjyjancCD6llmE+/fXu+/Ps///zvMPUCDefqbre8fP32ODwyNPpwMzmwc3mxNDo1N3u8fL3v8znEj+kucHS/////v//NVuwf5bK9/n7vcrkma3WgZnMf5fLlKnUusjjzdfr+Pn7iZ/OOmCz/f7///7+/Pz9tcPgGUWh4efz2eDwvMrkf5nMXH2+aojDa4jEW3y9gJnMuMbi6u/3Jk+ms8Hf+/z9aofDXn2+9ff70Nrsm6/XXHy+jKPRxNDnxdHol6vVWXq8kqfTz9ns9vj7co3Ga4fD/P3+UHK5dZDI8fT5w8/ngpvNaIbDxM/nvMnku8jkcIzGfZfLws7m8/b6iaDQU3W6dI/H8vT6aIbCu8nkucfjydTpepXK9Pb6UnS67vL4ztfrmKzWWnu9kKbTmK3WW3u9kqjTy9Xq8PP5cY3GbIjD/v/+GEWh4ujz1t7ugZrNbInEfJbL7fH4JlCns8LgOVuxfpbKzNbqlqrUfpbLe5TKytTqj6XRPWCz/v7/8fju7vXt/v/zssXUCTigs8Hi8fT309zuxtHo0Nns7vL2wMznFECkr8LT///0QJkUQJoUPpcVSKIRKHVADDqki6DY4+ny4ujy4Oby4ejyl6ndEj+mJ3Q/LpAAL5AALI4BNZgAJHomD0iAJk6xd47Uip7bjqHceI7VLlW0LY4BM5IEM5MEMZEFNZcAMpMDIXUqDFZQDE9lDlBmDFVQMpIDNpgAOJsANpkAOZsAMZEGMpEFwJ5XlQAAAAFiS0dEPKdqYc8AAAAJcEhZcwAAAEgAAABIAEbJaz4AAAGtSURBVDjLY2AY1oCRiRGICCtjZmFlY2NlYcZQyo4CGDk4ubh5ePn4OTkYUWUYBJCBoJCwiKiYuISklLSwkCCKFIMMAsjKySsoKimrqKqpq2toasnLySJJMmgjgI6unr6BoZGxiamZuYWllbWuDpIkgw0c2NrY2tk7mDk6Obu4url7eHp5A4XggMEHDnxt/PwDAoOCQ0LDwiMi3aOiY2x8EbJIJsbaxMUnJCYlp6SmpWdkZmXn5AKFECYimHk2+QWFRcUlpWXlxhWVVdU1tUAhHArr6k0jGooam5orWkxaa9pwKIy1yY1v7+js6k4t6unt658wcRKK1ZPhINbGb8rUaeXTw0NndM1sMp81e45NLEIWJXhs5s5zmF+2YOGivsjFDkuWLkMJnuUIsGLlqtVr1q5bXxZUumHjps1btq5AkmTYhgDbd+zctXvP3n1T9x/Ye/DQ4Z07tiNJMhxBAkePHT9x8tTpM2fPnT954viFo8hyDBeRwKVLl69cvXb9xs1b125fuXzpErIcw21UcPn2nbv37t2/A2SgAnSFQKUPHj58cBlDGFMhDjAUFAIALMfjyKVz+egAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTMtMTAtMDdUMTM6MTQ6MzQrMDI6MDDj9ijFAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDEzLTEwLTA3VDEzOjE0OjM0KzAyOjAwkquQeQAAAABJRU5ErkJggg=='
            }
          ]);
        }
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        dispatch({
          type: SET,
          payload: {
            countryList: [
              {
                _id: '625fd32730b905c976c61d55',
                name: 'INDIA',
                callingCode: '+91',
                flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAMAAABpA6zvAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAqZQTFRF53MA5HIC43ID53MB9XcA+3oA9ngA53QB83cA6XMCpV8oX0hPR0RkSUVlXkdPpF8o53EA428B9ncArmIkL0OAG02vZo3TfJ3agqLcZYzTJFSzLkJ/6X0R6X0S5XsS+IQOl2E+BDqjgJ7X5eny5enz5uv05er05OjyjancCD6llmE+/fXu+/Ps///zvMPUCDefqbre8fP32ODwyNPpwMzmwc3mxNDo1N3u8fL3v8znEj+kucHS/////v//NVuwf5bK9/n7vcrkma3WgZnMf5fLlKnUusjjzdfr+Pn7iZ/OOmCz/f7///7+/Pz9tcPgGUWh4efz2eDwvMrkf5nMXH2+aojDa4jEW3y9gJnMuMbi6u/3Jk+ms8Hf+/z9aofDXn2+9ff70Nrsm6/XXHy+jKPRxNDnxdHol6vVWXq8kqfTz9ns9vj7co3Ga4fD/P3+UHK5dZDI8fT5w8/ngpvNaIbDxM/nvMnku8jkcIzGfZfLws7m8/b6iaDQU3W6dI/H8vT6aIbCu8nkucfjydTpepXK9Pb6UnS67vL4ztfrmKzWWnu9kKbTmK3WW3u9kqjTy9Xq8PP5cY3GbIjD/v/+GEWh4ujz1t7ugZrNbInEfJbL7fH4JlCns8LgOVuxfpbKzNbqlqrUfpbLe5TKytTqj6XRPWCz/v7/8fju7vXt/v/zssXUCTigs8Hi8fT309zuxtHo0Nns7vL2wMznFECkr8LT///0QJkUQJoUPpcVSKIRKHVADDqki6DY4+ny4ujy4Oby4ejyl6ndEj+mJ3Q/LpAAL5AALI4BNZgAJHomD0iAJk6xd47Uip7bjqHceI7VLlW0LY4BM5IEM5MEMZEFNZcAMpMDIXUqDFZQDE9lDlBmDFVQMpIDNpgAOJsANpkAOZsAMZEGMpEFwJ5XlQAAAAFiS0dEPKdqYc8AAAAJcEhZcwAAAEgAAABIAEbJaz4AAAGtSURBVDjLY2AY1oCRiRGICCtjZmFlY2NlYcZQyo4CGDk4ubh5ePn4OTkYUWUYBJCBoJCwiKiYuISklLSwkCCKFIMMAsjKySsoKimrqKqpq2toasnLySJJMmgjgI6unr6BoZGxiamZuYWllbWuDpIkgw0c2NrY2tk7mDk6Obu4url7eHp5A4XggMEHDnxt/PwDAoOCQ0LDwiMi3aOiY2x8EbJIJsbaxMUnJCYlp6SmpWdkZmXn5AKFECYimHk2+QWFRcUlpWXlxhWVVdU1tUAhHArr6k0jGooam5orWkxaa9pwKIy1yY1v7+js6k4t6unt658wcRKK1ZPhINbGb8rUaeXTw0NndM1sMp81e45NLEIWJXhs5s5zmF+2YOGivsjFDkuWLkMJnuUIsGLlqtVr1q5bXxZUumHjps1btq5AkmTYhgDbd+zctXvP3n1T9x/Ye/DQ4Z07tiNJMhxBAkePHT9x8tTpM2fPnT954viFo8hyDBeRwKVLl69cvXb9xs1b125fuXzpErIcw21UcPn2nbv37t2/A2SgAnSFQKUPHj58cBlDGFMhDjAUFAIALMfjyKVz+egAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTMtMTAtMDdUMTM6MTQ6MzQrMDI6MDDj9ijFAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDEzLTEwLTA3VDEzOjE0OjM0KzAyOjAwkquQeQAAAABJRU5ErkJggg=='
              }
            ]
          }
        });
        callback([
          {
            _id: '625fd32730b905c976c61d55',
            name: 'INDIA',
            callingCode: '+91',
            flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAMAAABpA6zvAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAqZQTFRF53MA5HIC43ID53MB9XcA+3oA9ngA53QB83cA6XMCpV8oX0hPR0RkSUVlXkdPpF8o53EA428B9ncArmIkL0OAG02vZo3TfJ3agqLcZYzTJFSzLkJ/6X0R6X0S5XsS+IQOl2E+BDqjgJ7X5eny5enz5uv05er05OjyjancCD6llmE+/fXu+/Ps///zvMPUCDefqbre8fP32ODwyNPpwMzmwc3mxNDo1N3u8fL3v8znEj+kucHS/////v//NVuwf5bK9/n7vcrkma3WgZnMf5fLlKnUusjjzdfr+Pn7iZ/OOmCz/f7///7+/Pz9tcPgGUWh4efz2eDwvMrkf5nMXH2+aojDa4jEW3y9gJnMuMbi6u/3Jk+ms8Hf+/z9aofDXn2+9ff70Nrsm6/XXHy+jKPRxNDnxdHol6vVWXq8kqfTz9ns9vj7co3Ga4fD/P3+UHK5dZDI8fT5w8/ngpvNaIbDxM/nvMnku8jkcIzGfZfLws7m8/b6iaDQU3W6dI/H8vT6aIbCu8nkucfjydTpepXK9Pb6UnS67vL4ztfrmKzWWnu9kKbTmK3WW3u9kqjTy9Xq8PP5cY3GbIjD/v/+GEWh4ujz1t7ugZrNbInEfJbL7fH4JlCns8LgOVuxfpbKzNbqlqrUfpbLe5TKytTqj6XRPWCz/v7/8fju7vXt/v/zssXUCTigs8Hi8fT309zuxtHo0Nns7vL2wMznFECkr8LT///0QJkUQJoUPpcVSKIRKHVADDqki6DY4+ny4ujy4Oby4ejyl6ndEj+mJ3Q/LpAAL5AALI4BNZgAJHomD0iAJk6xd47Uip7bjqHceI7VLlW0LY4BM5IEM5MEMZEFNZcAMpMDIXUqDFZQDE9lDlBmDFVQMpIDNpgAOJsANpkAOZsAMZEGMpEFwJ5XlQAAAAFiS0dEPKdqYc8AAAAJcEhZcwAAAEgAAABIAEbJaz4AAAGtSURBVDjLY2AY1oCRiRGICCtjZmFlY2NlYcZQyo4CGDk4ubh5ePn4OTkYUWUYBJCBoJCwiKiYuISklLSwkCCKFIMMAsjKySsoKimrqKqpq2toasnLySJJMmgjgI6unr6BoZGxiamZuYWllbWuDpIkgw0c2NrY2tk7mDk6Obu4url7eHp5A4XggMEHDnxt/PwDAoOCQ0LDwiMi3aOiY2x8EbJIJsbaxMUnJCYlp6SmpWdkZmXn5AKFECYimHk2+QWFRcUlpWXlxhWVVdU1tUAhHArr6k0jGooam5orWkxaa9pwKIy1yY1v7+js6k4t6unt658wcRKK1ZPhINbGb8rUaeXTw0NndM1sMp81e45NLEIWJXhs5s5zmF+2YOGivsjFDkuWLkMJnuUIsGLlqtVr1q5bXxZUumHjps1btq5AkmTYhgDbd+zctXvP3n1T9x/Ye/DQ4Z07tiNJMhxBAkePHT9x8tTpM2fPnT954viFo8hyDBeRwKVLl69cvXb9xs1b125fuXzpErIcw21UcPn2nbv37t2/A2SgAnSFQKUPHj58cBlDGFMhDjAUFAIALMfjyKVz+egAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTMtMTAtMDdUMTM6MTQ6MzQrMDI6MDDj9ijFAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDEzLTEwLTA3VDEzOjE0OjM0KzAyOjAwkquQeQAAAABJRU5ErkJggg=='
          }
        ]);
      }
    );
  }
};

export const getCourse = (callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));

  services.getApiCall(
    endpoint.auth.getCourse,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));

      if (res.status === services.status_code.success) callback(res?.data?.data);
      else {
        errorCallback();
        dispatch(showErrorSnackbar('wentWrong'));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback();
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getParentCourse = (callback) => async (dispatch, getState) => {
  const { parentCourse } = getState().AuthReducer;
  if (parentCourse?.length > 0) {
    callback(parentCourse);
  } else {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.course.getParentCourse,
      '',
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res.data.statusCode === services.status_code.success) {
          dispatch(setParentCourse('parentCourse', res.data.data));
          callback(res.data.data);
        } else if (res.statusCode != services.status_code.success) {
        }
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
      }
    );
  }
};

export const getBatch = () => async (dispatch) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.home.getPaidCourseList,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.data.statusCode === services.status_code.success) {
        dispatch(setParentCourse('batchType', res.data.data));
      } else if (res.statusCode != services.status_code.success) {
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const getState = (callback, errorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.auth.getState,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));

      if (res.status === services.status_code.success) callback(res?.data?.data);
      else {
        errorCallback();
        dispatch(showErrorSnackbar('wentWrong'));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback();
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getCity = (id, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.auth.getCity,
    id,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));

      if (res.status === services.status_code.success) callback(res?.data?.data);
      else {
        ErrorCallback(res);
        dispatch(showErrorSnackbar('wentWrong'));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const sendOTP = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.sendOtp,
    { ...data, deviceType: 'web' },
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.statusCode === services.status_code.success) {
        callback(res);
      } else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const verifyOTP = (data, callback, ErrorCallback) => async (dispatch) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.verifyOtp,
    { ...data, deviceType: 'web' },
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      // if (res?.data) callback(res);
      // else ErrorCallback(res);
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(updateMoreValues({ hasActivePaidPlan: res?.data?.hasActivePaidPlan }));
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        ErrorCallback(res);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const verifyOTPUser = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.verifyUser,
    { ...data, deviceType: 'web' },
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) callback(res);
      else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const register = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.register,
    { ...data, deviceType: 'web' },
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) callback(res);
      else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const loginUser =
  (data, callback, alreadyCallback, ErrorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.auth.loginUser,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res?.data) {
          if (res?.data?.isVerified) {
            alreadyCallback(res?.data);
          } else if (res?.msgCode === '508') {
            ErrorCallback(res);
          } else {
            callback(res);
          }
        } else ErrorCallback(res);
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        ErrorCallback(error.data);
      }
    );
  };

export const getSetting = (callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getRequest(
    endpoint.setting.getSetting,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(
        updateMoreValues({
          display: res?.data?.data?.display,
          notification: res?.data?.data?.notification,
          video: res?.data?.data?.video,
          settingLoader: false
        })
      );

      callback();
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback();
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const setSetting = (id, data, callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postRequest(
    `${endpoint.setting.updateSetting}${id}`,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(
        getSetting(() => {
          callback();
        })
      );
    },
    () => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      callback();
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const Logout = () => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.logout,
    {},
    async (res) => {
      if (res.statusCode === services.status_code.success) {
        await dispatch(updateMoreValues({ ACCESS_TOKEN_NEW: '' }));
        setTimeout(() => {
          dispatch(showSuccessSnackbar(res?.msg));
          dispatch(updateHomeMoreValues({ isLoader: false }));
          window.location.href = '/';
        }, 3000);
      } else {
        await dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
    }
  );
};

export const getBranch = (callback, errorCallback) => async (dispatch, getState) => {
  const { classBatchData } = getState().AuthReducer;
  if (classBatchData?.length > 0) callback(classBatchData);
  else {
    dispatch(updateHomeMoreValues({ isLoader: true }));

    services.getApiCall(
      endpoint.auth.getBranch,
      '',
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));

        if (res.status === services.status_code.success) {
          dispatch(
            updateMoreValues({
              classBatchData: res?.data?.data
            })
          );
          callback(res?.data?.data);
        } else {
          errorCallback();
          dispatch(showErrorSnackbar('wentWrong'));
        }
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        errorCallback();
        dispatch(showErrorSnackbar('wentWrong'));
      }
    );
  }
};

export const examTypeClassroom = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.exam.examTypeClassroom,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) callback(res?.data);
      else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getBatchDate = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.auth.getBatchDate,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) callback(res?.data?.data);
      else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const classroomRegister = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.classroomRegister,
    { ...data, deviceType: 'web' },
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services?.status_code?.success) {
        // dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar(error?.data?.msg));
      ErrorCallback(error);
    }
  );
};

export const classroomBaseCourseList = (callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.auth.classroomBaseCourseList,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) callback(res?.data?.data);
      else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const classroomBranchList =
  (data, callback, ErrorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.auth.classroomBranchList,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else ErrorCallback(res);
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };

export const classroomList = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.classroomList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) {
        callback(res?.data);
      } else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getClassroomBaseCourseExam =
  (data, callback, ErrorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.exam.getClassroomBaseCourseExam,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res?.data);
        } else ErrorCallback(res);
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };

export const getBatchListFilter = (data, callback, errorCallback) => async (dispatch) => {
  services.postApiCall(
    endpoint.exam.getBatchListFilter,
    data,
    (res) => {
      if (res?.statusCode === services?.status_code?.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      errorCallback(error);
    }
  );
};
export const patchclassroom = (data, callback, errorCallback) => async (dispatch) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.patchApiCall(
    endpoint.auth.patchclassroom,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));

      if (res?.data?.statusCode === services?.status_code?.success) {
        // dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback(error);
    }
  );
};

export const reopensupport = (data, id, callback, errorCallback) => async (dispatch) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.patchApiCall(
    endpoint.chat.reopensupport + id,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));

      if (res?.data?.statusCode === services?.status_code?.success) {
        // dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      errorCallback(error);
    }
  );
};
export const deleteUser = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.setting.deleteUser,
    {},
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res) {
        callback(res);
      } else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getSubjectPredictor = (callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCallWithoutToken(
    endpoint.home.getSubjectPredictor,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) callback(res?.data?.data);
      else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getCategoriesPredictor = (callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCallWithoutToken(
    endpoint.home.getCategoriesPredictor,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) callback(res?.data?.data);
      else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getPredictRank = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.home.getPredictRank,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res) {
        callback(res);
      } else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getExamList = (callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  // const { examListNew } = getState().AuthReducer;
  // if (examListNew?.length > 0) {
  //   dispatch(updateHomeMoreValues({ isLoader: false }));
  //   callback(examListNew);
  // } else
  services.getApiCall(
    endpoint.exam.getExamList,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        dispatch(setParentCourse('examListNew', res?.data?.data));
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar('wentWrong'));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getExamListStudyMaterial = (callback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.exam.getExamListStudyMaterial,
    '',
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar('wentWrong'));
      }
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      dispatch(showErrorSnackbar('wentWrong'));
    }
  );
};

export const getSubExamList = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.exam.getSubExamList,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) {
        dispatch(updateMoreValues({ examTypeDataLocal: res?.data?.examTypes }));
        callback(res);
      } else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getSubExamStudyMaterialList =
  (data, callback, ErrorCallback) => async (dispatch, getState) => {
    dispatch(updateHomeMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.exam.getSubExamStudyMaterialList,
      data,
      (res) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        if (res?.data) {
          dispatch(updateMoreValues({ examTypeDataLocal: res?.data?.examTypes }));
          callback(res);
        } else ErrorCallback(res);
      },
      (error) => {
        dispatch(updateHomeMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };

export const registerUser = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.registerUser,
    { ...data, deviceType: 'web' },
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.data) callback(res);
      else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getId = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.getId,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) callback(res.data);
      else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getName = (data, callback, ErrorCallback) => async (dispatch, getState) => {
  dispatch(updateHomeMoreValues({ isLoader: true }));
  services.postApiCall(
    endpoint.auth.getName,
    data,
    (res) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      if (res?.statusCode === services.status_code.success) callback(res.data);
      else ErrorCallback(res);
    },
    (error) => {
      dispatch(updateHomeMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};
