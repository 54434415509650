import React, { useState, useEffect } from 'react';
import '../App.css';
import VideoLecture from '../Component/videoLecture';
import { useDispatch, useSelector } from 'react-redux';
import { getFreeLectureByTeacher } from '../Redux/Actions/homeAction';
import { useLocation, useNavigate } from 'react-router-dom';
function TeacherWiselectures() {
  const location = useLocation();
  const navigate = useNavigate();
  const { teachers, subjectId, topic } = location.state?.item;
  const { hasActivePaidPlan } = useSelector((state) => ({
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan
  }));

  const onpressvideo = (id) => {
    navigate(hasActivePaidPlan ? '/PaidUser/AllLectureVideo' : '/DashboardMenu/AllLectureVideo', {
      state: { topicId: id, subjectId }
    });
  };
  return (
    <div className="rightPanel">
      <div>
        <h3 className="px-2">Lectures</h3>
      </div>
      <div className="mt-4 lectureheight p-2 width75">
        {topic.map((c, index) => (
          <VideoLecture
            key={index}
            title={c.topicId.name}
            lectcount={c.topicVideosCount}
            onpress={() => {
              onpressvideo(c.topicId._id);
            }}
          />
        )
        )}
      </div>
    </div>
  );
}

export default TeacherWiselectures;
