import React, { useState, useEffect } from 'react';
import '../Assets/CSS/home.css';
import Footer from '../Component/footer';
import ClassroomInPocket from '../Component/classroomInPocket';
import { LocalImages } from '../Constant';
import Lottie from 'react-lottie';
import Expert from '../Assets/Images/JSON/expert.json';
import TalktoExpert from '../Module/talktoExpert';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import data from '../Assets/DummyData/course.json';
import Header from '../Module/header';
import HeaderHome from '../Module/headerHome';
import { getBaseCourse } from '../Redux/Actions/homeAction';
import { getId } from '../Redux/Actions/AuthAction';
import SEODetails from '../Module/metaTitle';

const AboutCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { seoExamName } = useParams();
  const [open, setopen] = useState(false);
  const [subExamSeoName, setSubExamSeoName] = useState('');
  const [examName, setExamName] = useState('');
  const [courseexamData, setcourseexamData] = useState('');
  const [baseCourse, setbaseCourse] = useState([]);
  const [seo, setSeoData] = useState();
  const { ACCESS_TOKEN_NEW } = useSelector((state) => ({
    ACCESS_TOKEN_NEW: state?.AuthReducer?.ACCESS_TOKEN_NEW
  }));
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Expert,
    renderer: 'svg'
  };

  useEffect(() => {
    const data = { seoName: seoExamName };
    dispatch(
      getId(data, (res) => {
        const { examTypeId, examTypeName, subExamTypeSeoName, subExamTypeId, examTypeSeo } = res;
        setSeoData(examTypeSeo);
        setSubExamSeoName(subExamTypeSeoName ? subExamTypeSeoName : '');
        setExamName(examTypeName);
        dispatch(
          getBaseCourse(
            subExamTypeId ? subExamTypeId : examTypeId,
            (res) => {
              setbaseCourse(res[0]?.baseCourseId);
            },
            () => {}
          )
        );
      })
    );
  }, [seoExamName]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (examName?.toUpperCase() === 'CSIR NET') setcourseexamData(data['csir_net']);
    else if (examName?.toUpperCase() === 'GATE') setcourseexamData(data['gate']);
    else if (examName?.toUpperCase() === 'UGC NET') setcourseexamData(data['ugc_net']);
    else if (examName?.toUpperCase() === 'IIT-JAM') setcourseexamData(data['iit_jam']);
    else if (examName?.toUpperCase() === 'CUET') setcourseexamData(data['cuet']);
    else if (examName?.toUpperCase() === 'MSC ENTRANCE') setcourseexamData(data['MSC']);
    else if (examName?.toUpperCase() === 'PSC TEACHING') setcourseexamData(data['psc_teaching']);
    else if (examName?.toUpperCase() === 'MPPSC') setcourseexamData(data['mppsc_teaching']);
    else if (examName?.toUpperCase() === 'RPSC') setcourseexamData(data['rppsc_teaching']);
    else if (examName?.toUpperCase() === 'BARC') setcourseexamData(data['barc']);
    else if (examName?.toUpperCase() === 'TIFR') setcourseexamData(data['tifr']);
    else if (examName?.toUpperCase() === 'APTITUDE NET,GATE,SET')
      setcourseexamData(data['general_aptitude']);
    else if (examName?.toUpperCase() === 'GAT B') setcourseexamData(data['gatb']);
    else if (examName?.toUpperCase() === 'JEST') setcourseexamData(data['jest']);
    else if (examName?.toUpperCase() === 'UPHESC') setcourseexamData(data['uphesc']);
    else if (examName?.toUpperCase() === 'CSIR NET APTITUDE')
      setcourseexamData(data['csir_net_Apti']);
    else if (examName?.toUpperCase() === 'GATE APTITUDE') setcourseexamData(data['gate_apti']);
    else if (examName?.toUpperCase() === 'UGC NET APTITUDE')
      setcourseexamData(data['ugc_net_apti']);
    else if (examName?.toUpperCase() === 'SET') setcourseexamData(data['set_apti']);
    else if (examName?.toUpperCase() === 'ICMR') setcourseexamData(data['icmr']);
    else if (examName?.toUpperCase() === 'DBT') setcourseexamData(data['dbt']);
    else if (examName?.toUpperCase() === 'GOA PSC') setcourseexamData(data['goa-psc']);
    else if (examName?.toUpperCase() === 'HPSC') setcourseexamData(data['hpsc']);
    else if (examName?.toUpperCase() === 'JKPSC') setcourseexamData(data['jkpsc']);
  }, [examName]);

  const coursePage = (name) => {
    navigate(
      '/' + `${subExamSeoName ? subExamSeoName : seoExamName}` + '/' + name + '-online-coaching'
    );
  };
  const handleClose = () => setopen(false);

  const handleClick = () => setopen(true);

  const toClassRoom = (val) => {
    const xamName = examName.split(' ').join('');
    const baseCourseVal = val.split(' ').join('');
    navigate('/' + xamName + '/' + baseCourseVal);
  };

  return (
    <div>
      {ACCESS_TOKEN_NEW === undefined || ACCESS_TOKEN_NEW === '' || ACCESS_TOKEN_NEW === null ? (
        <HeaderHome />
      ) : (
        <Header />
      )}
      <SEODetails title={seo?.metaTitle} description={seo?.metaDescrition} />
      {courseexamData?.ismultiple === false ? (
        <div
          className="app-achiev common-pad pt-2 pb-2 wow fadeIn text-center bg"
          data-wow-duration="2s"
          data-wow-delay="0s">
          <p className="text-center nameheading mb-3">{courseexamData?.name} TOPPERS</p>

          <div className="row justify-content-center content-topper mt-3 col-lg-10 mx-auto">
            {courseexamData?.toppers?.map((c, indexMain) => {
              return (
                <div
                  key={indexMain}
                  className="  col-sm-2 rounded wow fadeIn mb-3"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s">
                  <img className="img-fluid" src={LocalImages[c?.path]} alt={c.name} />
                  <span className="d-block mt-2">{c.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className="app-achiev common-pad pt-2 pb-2 wow fadeIn text-center bg"
          data-wow-duration="2s"
          data-wow-delay="0s">
          {courseexamData?.examlist?.map((exam, index) => {
            return (
              <>
                <p className="text-center nameheading mb-3">{exam?.examName}</p>
                <div className="row justify-content-center content-topper mt-3 col-lg-10 mx-auto">
                  {exam?.toppers?.map((c, indexMain) => {
                    return (
                      <div
                        key={indexMain}
                        className="  col-sm-2 rounded wow fadeIn mb-3"
                        data-wow-duration="2s"
                        data-wow-delay="0.3s">
                        <img className="img-fluid" src={LocalImages[c?.path]} alt={c.name} />
                        <span className="d-block mt-2">{c.name}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      )}
      <div
        className="app-achiev common-pad pt-4 pb-2 wow fadeIn text-center"
        data-wow-duration="2s"
        data-wow-delay="0s">
        <p className="text-center mb-2 colorblue coursenametitle fw-bold">{examName} Courses</p>
        <p className="subjectsubtext mb-3">
          The Most Comprehensive Packages Created By The Best Teachers Of India
        </p>
        <div className="content-topper mt-5 col-lg-12 mx-0 d-flex justify-content-center flex-wrap">
          {baseCourse?.map((c) => {
            return (
              <div
                key={c._id}
                className="basecourseBox rounded wow fadeIn mb-3 boxshadow cursor"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
                onClick={() => coursePage(c.seoName)}>
                <span className="d-block ">{c.name}</span>
              </div>
            );
          })}
        </div>
      </div>
      {examName === 'CSIR NET' ? (
        <div
          className="app-achiev common-pad pt-4 pb-2 wow fadeIn text-center"
          data-wow-duration="2s"
          data-wow-delay="0s">
          <p className="text-center mb-2 colorblue coursenametitle fw-bold">
            {courseexamData?.name} Classroom Courses
          </p>
          <p className="subjectsubtext mb-3">
            The Most Comprehensive Packages Created By The Best Teachers Of India
          </p>
          <div className="content-topper mt-5 col-lg-12 mx-0 d-flex justify-content-center flex-wrap">
            <div
              className="basecourseBox rounded wow fadeIn mb-3 boxshadow cursor"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
              onClick={() => toClassRoom('Life Science')}>
              <span className="d-block ">Life Science</span>
            </div>
            <div
              className="basecourseBox rounded wow fadeIn mb-3 boxshadow cursor"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
              onClick={() => toClassRoom('Chemical Science')}>
              <span className="d-block ">Chemical Science</span>
            </div>
            <div
              className="basecourseBox rounded wow fadeIn mb-3 boxshadow cursor"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
              onClick={() => toClassRoom('Physical Science')}>
              <span className="d-block ">Physical Science</span>
            </div>
            <div
              className="basecourseBox rounded wow fadeIn mb-3 boxshadow cursor"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
              onClick={() => toClassRoom('Mathematical Science')}>
              <span className="d-block ">Mathematical Science</span>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="row common-pad pt-4">
        {courseexamData?.faq !== undefined ? (
          <p className="text-center mb-2 colorblue coursenametitle fw-bold">
            Some Frequently Asked Questions
          </p>
        ) : (
          ''
        )}
        <div
          className="accordion accordion-flush row d-flex justify-content-left"
          id="accordionFlushExample">
          {courseexamData?.faq?.map((c, index) => {
            return (
              <div key={index} className="accordion-item noborder col-md-12 ">
                <div className="boxshadow" style={{ margin: '6px' }}>
                  <h2 className="accordion-header" id={'heading' + index}>
                    <button
                      className={`accordion-button  ${index === 0 ? '' : 'collapsed'}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={'#collapse' + index}
                      aria-expanded={index === 0 ? 'true' : 'false'}
                      aria-controls={'collapse' + index}>
                      {c?.title}
                    </button>
                  </h2>
                  <div
                    id={'collapse' + index}
                    className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                    aria-labelledby={'heading' + index}
                    data-bs-parent={'#accordionExample'}>
                    <div className="accordion-body">{c?.text}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ClassroomInPocket />
      <Footer />
      <div
        className="position-fixed backcolor rounded-circle cursor"
        style={{ right: '20px', bottom: '30px' }}>
        <a className="d-block d-md-none talkexpertbtn" href="tel:+919172266888">
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </a>
        <span className="d-none d-md-block" onClick={handleClick}>
          <Lottie options={defaultOptions} height={70} width={70}></Lottie>
        </span>
      </div>
      <TalktoExpert open={open} handleClose={handleClose} />
    </div>
  );
};

export default AboutCourse;
