import React from 'react';
import '../App.css';
import LectureCard from '../Component/lectureCard';
import { useLocation } from 'react-router-dom';

function LiveClass() {
  const location = useLocation();
  const upcoming = location.state.upcoming;

  return (
    <div>
      <div className="rightPanel">
        <div className="customcontainer py-4">
          <div>
            <h2 className="ms-4">Live Classes</h2>
            <h5 className="text-secondary ms-4">Watch free classes from top educators</h5>
          </div>
          <div className="mt-2 ms-0">
            <div className="row">
              {upcoming.map((c) => {
                return <LectureCard data={c} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveClass;
