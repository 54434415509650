import React, { useEffect, useState } from 'react';
import '../App.css';
import earn from '../Assets/Images/refer&earn/earn.png';
import enrolled from '../Assets/Images/refer&earn/enrolled.svg';
import { useDispatch } from 'react-redux';
import { getReferCash, getRefferer } from '../Redux/Actions/homeAction';
import moment from 'moment';

function MyReferral() {
    const dispatch = useDispatch();
    const [refAmt, setRefAmt] = useState({
        totalCashbackCredit: 0,
        totalCashbackEarned: 0,
        totalCashbackPending: 0
    })
    const [refData, setRefData] = useState([])
    const referralInfo = [
        {
            icon: enrolled,
            name: 'Chetan Dhumak',
            mobileNumber: '+91-8645254697',
            inviteOn: '₹ 14656',
            status: "Not Signed up",
            Reward: "₹ 11956",
            RewardStatus: "Credited"
        },
        {
            icon: enrolled,
            name: 'Chetan Dhumak',
            mobileNumber: '+91-8645254697',
            inviteOn: '₹ 14656',
            status: "Not Signed up",
            Reward: "₹ 11956",
            RewardStatus: "Credited"
        },
        {
            icon: enrolled,
            name: 'Chetan Dhumak',
            mobileNumber: '+91-8645254697',
            inviteOn: '₹ 14656',
            status: "Not Signed up",
            Reward: "₹ 11956",
            RewardStatus: "Credited"
        },
    ]

    const get_referal_amount = () => {
        dispatch(getReferCash('', async(resp) => {
            if (resp?.length > 0) {
                setRefAmt(resp[0]);
            }
        }))
    }
    const get_reffer_details = (e) => {
        const id = e ? e.currentTarget.id : "pills-referral-entries-tab";
        let requestBody = {}
        if(id === "pills-referral-entries-tab"){
            requestBody.registrationStatus = 1
        }else if(id === "pills-sign-ups-tab"){
            requestBody.registrationStatus = 2
        }else if(id === "pills-enrolled-users-tab"){
            requestBody.registrationStatus = 3
        }else{}
        dispatch(
            getRefferer(requestBody, async (response) => {
                await setRefData(response);
            })
        );
    };

    console.log('the refer detail', refData)

    useEffect(()=>{
        get_referal_amount();
        get_reffer_details();
    },[])

    return (
        <section className='ref-earn'>
            <div className="rightPanel">
                <div className="row mb-5">
                    <div className='col-lg-10 earn-cashback mb-5'>
                        <div className='row'>
                            <div className='col-lg-4 mb-3'>
                                <div className="card Cashback">
                                    <div className='card-body'>
                                        <div className='d-flex'>
                                            <img src={earn} alt="earning" width="35" />
                                            <p className='text-center mx-auto'>Earned Cashback <span className='d-block colorblue'>&#8377; {refAmt?.totalCashbackEarned}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 mb-3'>
                                <div className="card Credited">
                                    <div className='card-body'>
                                        <div className='d-flex'>
                                            <img src={earn} alt="earning" width="35" />
                                            <p className='text-center mx-auto'>Total Cashback Credited <span className='d-block colorblue'>&#8377; {refAmt?.totalCashbackCredit}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 mb-3'>
                                <div className="card Pending">
                                    <div className='card-body'>
                                        <div className='d-flex'>
                                            <img src={earn} alt="earning" width="35" />
                                            <p className='text-center mx-auto'>Total Cashback Pending <span className='d-block colorblue'>&#8377; {refAmt?.totalCashbackPending}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row my-referral_tab'>
                    <div className='col-lg-10'>
                        <div className='heading-holder mb-3'>
                            <h3 className='invite-label'>My Referrals</h3>
                        </div>
                        <div className='referrals-tab'>
                            <div className='d-lg-flex'>
                                <ul class="nav nav-pills mb-2" id="pills-tab" role="tablist">
                                    <li class="nav-item mb-2" role="presentation">
                                        <button onClick={get_reffer_details} class="nav-link active" id="pills-referral-entries-tab" data-bs-toggle="pill" data-bs-target="#pills-referral-entries" type="button" role="tab" aria-controls="pills-referral-entries" aria-selected="true">Referral Entries</button>
                                    </li>
                                    <li class="nav-item mb-2 mx-lg-2" role="presentation">
                                        <button onClick={get_reffer_details} class="nav-link" id="pills-sign-ups-tab" data-bs-toggle="pill" data-bs-target="#pills-sign-ups" type="button" role="tab" aria-controls="pills-sign-ups" aria-selected="false">Successful Sign-ups</button>
                                    </li>
                                    <li class="nav-item mb-2" role="presentation">
                                        <button onClick={get_reffer_details} class="nav-link" id="pills-enrolled-users-tab" data-bs-toggle="pill" data-bs-target="#pills-enrolled-users" type="button" role="tab" aria-controls="pills-enrolled-users" aria-selected="false">Enrolled Users</button>
                                    </li>
                                </ul>
                                <button type='button' className='btn add-reffreal-btn ms-lg-5 mb-3'>Add Referral</button>
                            </div>

                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-referral-entries" role="tabpanel" aria-labelledby="pills-referral-entries-tab" tabindex="0">
                                    <div className='row'>
                                        {refData.map((c) => {
                                            return (
                                                <div className='col-lg-4 mb-4'>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <p>Mobile Number : <span>{c.referredContactNumber}</span></p>
                                                            <p>Invite on <span>{moment(c.referDate).format("DD-MMM-YYYY")}</span></p>
                                                            <p>current Status : <span className='text-primary'>{c.registrationStatus ? 'Not Sign-up' : ''}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-sign-ups" role="tabpanel" aria-labelledby="pills-sign-ups-tab" tabindex="0">
                                    <div className='row'>
                                        {refData.map((c) => {
                                            return (
                                                <div className='col-lg-4'>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <p className='mb-3'><img src={c.icon} alt={c.name} className='mx-4' /> <span>{`${c?.referredUserId?.firstName} ${c?.referredUserId?.lastName}`}</span></p>
                                                            <p>Mobile Number : <span>{c.referredContactNumber}</span></p>
                                                            <p>Invite on <span>{moment(c.referDate).format("DD-MMM-YYYY")}</span></p>
                                                            <p>current Status : <span className='text-primary'>{c.registrationStatus ? 'Successfull Signup' : ''}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-enrolled-users" role="tabpanel" aria-labelledby="pills-enrolled-users-tab" tabindex="0">
                                    <div className='row'>
                                        {refData.map((c) => {
                                            return (
                                                <div className='col-lg-4'>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <p className='mb-3'><img src={c.icon} alt={c.name} className='mx-4' /> <span>{`${c?.referredUserId?.firstName} ${c?.referredUserId?.lastName}`}</span></p>
                                                            <p>Mobile Number : <span>{c.referredContactNumber}</span></p>
                                                            <p>Invite on <span>{moment(c.referDate).format("DD-MMM-YYYY")}</span></p>
                                                            <p>current Status : <span className='text-primary'>{c.registrationStatus ? 'Enrolled' : ''}</span></p>
                                                            <p>Reward Amount : <span className='text-primary'>{c.referralAmount}</span></p>
                                                            <p>Reward Status : <span className='text-success'>{c.isPaid ? 'Paid' : 'Pending'}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default MyReferral;