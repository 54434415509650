import '../App.css';
import React, { Suspense } from 'react';
import Sidebar from '../Module/sidebar';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './dashboard';
import OnlineCourse from './onlineCourse';
import TeacherWiselectures from './teacherWiselectures';
import RecordedLectures from './recordedLectures';
import AllLectureVideo from './allLectureVideo';
import OnlineTest from './onlineTest';
import OnlineTestFree from './onlineTestFree';
import SubjectTest from './subjectTest';
import OrderHistory from './orderHistory';
import Header from '../Module/header';
import MyAddress from './myAddress';
import PaidDashboard from './paidDashboard';
import { PaidSidebarData } from '../Module/paidSidebarData';
import MyLearning from './myLearning';
import MyLectures from './myLectures';
import LectureSubject from './lectureSubject';
import Topics from './topics';
import Help from './help';
import Chat from './chat';
import LectureSchedule from './lectureSchedule';
import CourseSupport from './courseSupport';
import AddcourseSupport from './addcourseSupport';
import TechnicalSupport from './technicalSupport';
import AddTechnicalSupport from './addTechnicalSupport';
import TechnicalSupportChat from './technicalSupportChat';
import MyProfile from './myProfile';
import OrderDetails from './orderDetails';
import Lectures from './lectures';
import FreeLectures from './freeLectures';
import AllAbout from './allAbout';
import LiveClass from './liveClass';
import Learn from './learn';
import LectureScheduleFree from './lectureScheduleFree';
import ClassroomCoachingDetails from './classroomCoachingDetails';
import Results from './Results';
import TopicListFree from './topicListFree';
import ReferEarn from './referEarn';
import MyReferral from './myReferral';
import SectionWiseTest from './sectionWiseTest';
import CourseAnalysis from './courseAnalysis';
import TestAnalysis from './testAnalysis';
import FixFooter from '../Component/fixFooter';

function PaidUser(Props) {
  return (
    <div>
      <Header />
      <div className="d-flex backimg">
        <Sidebar data={PaidSidebarData} />

        {
          <Routes>
            <Route path="/" element={<PaidDashboard />} />

            <Route path="PaidHome" element={<Dashboard />} />
            <Route path="PaidDashboard" element={<PaidDashboard />} />
            <Route path="OnlineCourse" element={<OnlineCourse />} />
            <Route path="MyLearning/*" element={<MyLearning />} />
            <Route path="MyLectures" element={<MyLectures />} />
            <Route path="CourseAnalysis" element={<CourseAnalysis/>} />
            <Route path="TestAnalysis" element={<TestAnalysis/>} />
            <Route path="AllAbout" element={<AllAbout />} />
            <Route path="FreeAllAbout" element={<AllAbout />} />
            <Route path="TeacherWiselectures" element={<TeacherWiselectures />} />
            <Route path="RecordedLectures" element={<RecordedLectures />} />
            <Route path="Lectures" element={<Lectures />} />
            <Route path="learn" element={<Learn />} />
            <Route path="results" element={<Results />} />
            <Route path="LectureScheduleFree" element={<LectureScheduleFree />} />
            <Route path="TeacherWiselectures" element={<TeacherWiselectures />} />
            <Route path="AllLectureVideo" element={<AllLectureVideo />} />
            <Route path="OnlineTest" element={<OnlineTest />} />
            <Route path="FreeOnlineTest" element={<OnlineTestFree />}/>
            <Route path="SectionWiseTest" element={<SectionWiseTest/>}/>
            <Route path="SubjectTest" element={<SubjectTest />}/>
            <Route path="FreeSubjectTest" element={<SubjectTest />}/>
            <Route path="FreeTopicList" element={<TopicListFree />}/>
            <Route path="OrderHistory" element={<OrderHistory />}/>
            <Route path="ReferEarn" element={<ReferEarn />} />
            <Route path=":ReferEarn/:MyReferral/" element={<MyReferral/>} />
            <Route path="MyAddress" element={<MyAddress />} />
            <Route path="LectureSubject" element={<LectureSubject />} />
            <Route path="Topics" element={<Topics />} />
            <Route path="Help" element={<Help />} />
            <Route path="Chat" element={<Chat />} />
            <Route path="LectureSchedule" element={<LectureSchedule />} />
            <Route path="CourseSupport" element={<CourseSupport />} />
            <Route path="AddcourseSupport" element={<AddcourseSupport />} />
            <Route path="TechnicalSupport" element={<TechnicalSupport />} />
            <Route path="AddTechnicalSupport" element={<AddTechnicalSupport />} />
            <Route path="SupportChat" element={<TechnicalSupportChat />} />
            <Route path="MyProfile" element={<MyProfile />} />
            <Route path="OrderDetails" element={<OrderDetails />} />
            <Route path="FreeLectures" element={<FreeLectures />} />
            <Route path="LiveClass" element={<LiveClass />} />
            <Route path="ClassroomCoachingDetails" element={<ClassroomCoachingDetails />} />
          </Routes>
          
        }
     
      </div>
    </div>
  );
}

export default PaidUser;
