export default {
  // Auth
  Splash: 'Splash',
  Tutorial: 'Tutorial',
  SignIn: 'SignIn',
  OTP: 'OTP',
  CreateAccount: 'CreateAccount',
  LoginUser: 'LoginUser',
  SignInUser: 'SignInUser',

  ExamStack: 'ExamStack',
  StartTest: 'Start Test',
  TestInstrctions: 'Test Instructions',
  ExamInstruction: 'ExamInstruction',
  AdmitCard: 'AdmitCard',
  Exam: 'Test',
  ExamStatus: 'ExamStatus',
  SubmitReview: 'ReviewExam',
  ThankYou: 'ThankYou',
  Analysis: 'Analysis',
  DetailedResultsScreen: 'DetailedResultsScreen',
  DetailResult: 'DetailResult',
  //TESTS
  TestList: 'TestList',

  // Paid
  Home: 'Home',
  Course: 'Course',
  MyLearning: 'MyLearning',
  Analytic: 'Analytic',
  Chat: 'Chat',
  Policy: 'Policy',
  Recorded: 'Recorded',
  PaidUser: 'PaidUser',
  // Free
  BottomStackFree: 'BottomStackFree',
  ViewMoreVideos: 'ViewMoreVideos',
  YoutubeFree: 'YoutubeFree',
  SeperateSprint: 'SeperateSprint',
  Setting: 'setting',
  WebViewScreen: 'WebViewScreen',
  DisplaySetting: 'DisplaySetting',
  AboutExam: 'AboutExam',
  CompleteSyllabus: 'Complete Syllabus',
  SyllabusDetails: 'Syllabus Details',
  ExamPattern: 'Exam Pattern',
  PreviousExamPaper: 'Previous Exam Paper',
  AllAboutExam: 'All About Exam',

  // Common
  CourseDetail: 'CourseDetail',
  Cart: 'Cart',
  MyAccount: 'MyAccount',
  AddressList: 'AddessList',
  AddAddress: 'AddAddress',
  EditProfile: 'EditProfile',
  Dummy: 'Dummy',
  Learn: 'learn',
  CartPage: 'cartPage',
  PDFScreen: 'PDFScreen',
  TechnicalSupport: 'TechnicalSupport',
  AddNewChat: 'AddNewChat',
  DashboardMenu: 'DashboardMenu',
  SubjectTest: 'SubjectTest',
  FreeSubjectTest: 'FreeSubjectTest',
  OnlineTest: 'OnlineTest',
  FreeTopicList: 'FreeTopicList',
};
